import React from 'react'

export default function NoteIcon() {
    return (
        <svg
            width="26"
            height="21"
            viewBox="0 0 26 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.928571 0H25.0714L26 0.928571V19.5L25.0714 20.4286H0.928571L0 19.5V0.928571L0.928571 0ZM1.85714 1.85714V18.5714H24.1429V1.85714H1.85714ZM5.57143 5.57143H20.4286V7.42857H5.57143V5.57143ZM16.7143 9.28572H5.57143V11.1429H16.7143V9.28572ZM5.57143 13H13V14.8571H5.57143V13Z"
                fill="white"
            />
        </svg>
    )
}
