import PropTypes from 'prop-types'
import React from 'react'
import { ThemeProvider } from 'styled-components'

import { themeContext } from './nivo/theming/context'
import globalStyle from './nivo/theming/GlobalStyle'
import theme from './nivo/theming/theme'

export default function Layout({ children }) {
    const currentTheme = theme['light']

    const GlobalStyle = globalStyle(false)

    return (
        <themeContext.Provider value={currentTheme}>
            <ThemeProvider theme={currentTheme}>
                <div>
                    <GlobalStyle />
                    {children}
                </div>
            </ThemeProvider>
        </themeContext.Provider>
    )
}

Layout.propTypes = {
    children: PropTypes.node,
}
