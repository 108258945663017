import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const DASHBOARD_QUERY = `
    query FetchTableInfo($type: Int!) {
        fetchTable(type: $type) {
            data
        }
    }
`

export default function useFetchDashboard(options) {
    return useQuery(gql(DASHBOARD_QUERY), {
        displayName: 'fetchDashboard',
        fetchPolicy: 'cache-and-network',
        ...options,
    })
}
