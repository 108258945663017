import makeStyles from '@material-ui/styles/makeStyles'
import { styles } from '@nerus/styles/components/editor'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

const useStyles = makeStyles(styles)

export default function EditorFormEditHeader({ columns, onRemove }) {
    const classes = useStyles()

    const cols = useMemo(() => {
        const total = columns.reduce((a, b) => {
            return a + b.size
        }, 0)

        columns.map(col => {
            col.style = { width: `${(col.size / total) * 100}%` }
        })

        return columns
    }, [columns])

    return (
        <thead>
            <tr>
                {cols.map(col => {
                    return (
                        <th
                            className={clsx({
                                [classes.tableHeader]: true,
                            })}
                            key={'th' + col.label}
                            style={col.style}
                        >
                            {col.label}
                        </th>
                    )
                })}
                {onRemove ? (
                    <th
                        className={clsx({
                            [classes.tableHeader]: true,
                        })}
                        key={'th-actions'}
                    />
                ) : null}
            </tr>
        </thead>
    )
}

EditorFormEditHeader.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            size: PropTypes.number,
        })
    ),
    onRemove: PropTypes.func,
}
