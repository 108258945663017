/* global Promise */
import config from '@nerus/config'
import keycodes, {
    CTRL_ALT_MAPPING,
    CTRL_MAPPING,
    functionKeys,
    navigationKeys,
} from '@nerus/framework/common/Keycodes'
import { EventEmitter } from 'events'
import { createContext } from 'react'

import { sendBuffer } from '../../Eac/EacActions'
import {
    checkNullOrUndefined,
    formComponents,
    getActiveComponent,
    getFieldList,
} from '../../Eac/EacReducer'
import { getActiveField } from '../../Eac/reducers/Formulario'

const debug = require('debug')('nerus:ws:manager')

const defaultKeysToSendWithoutValue = [
    ...functionKeys,
    ...navigationKeys,
    ...Object.values(CTRL_MAPPING),
    ...Object.values(CTRL_ALT_MAPPING),
]
const sendWithoutValue = [
    'REC_PROMPT',
    'REC_BIT_SN',
    'REC_BIT',
    'REC_SN',
    'REC_SN_GLOBAL',
    'REC_BIT_SN_WITH_HELP',
    'REC_SKIP',
    'REC_BIT_MENU',
    'REC_ONE_CHOICE',
]

class WebsocketManager extends EventEmitter {
    static CLOSED = 0
    static CONNECTING = 10
    static CONNECTED = 20
    static WAITING = 30
    static PROCESSING = 40
    static DEAD = 50

    static MAX_RETRIES = 3
    static MAX_MESSAGE_TIMEOUT = config.WS.WS_MESSAGE_TIMEOUT
    static MAX_PAYLOAD_TIMEOUT = 15

    _connection = null
    _endpoint = null

    _retries = 0

    _msgs = []

    __timer = null
    __value = ''

    state = WebsocketManager.CLOSED

    alive = false
    waiting = false
    ignore = false

    redux = null
    perfil = null

    constructor(props = {}) {
        const { ...options } = props

        super(options)

        this._triggerChangeState(WebsocketManager.CLOSED)

        this.on('STATE_CHANGE', this.onStateChange)

        this.on('shutdown', this.onShutdown)
    }

    onShutdown = () => {
        // do clean up
        this._connection = null
        this._retries = 0
    }

    onStateChange = ({ state }) => {
        this.state = state
        this.waiting = false

        debug('STATE_CHANGE: ', state)

        switch (state) {
            case WebsocketManager.CLOSED: {
                debug('[WS] Conexão encerrada.')
                break
            }
            case WebsocketManager.CONNECTING: {
                debug('[WS] Conectando...')
                break
            }
            case WebsocketManager.CONNECTED: {
                debug('[WS] Conectado.')
                this.waiting = false
                break
            }
            case WebsocketManager.WAITING: {
                debug('[WS] Aguardando...')
                this.waiting = true
                break
            }
            case WebsocketManager.PROCESSING: {
                debug('[WS] Processando...')
                break
            }
        }
    }

    _triggerChangeState = state => {
        if (this.state !== state) {
            this.emit('STATE_CHANGE', { state })
        }
    }

    reconnect() {
        return this.connect(this._endpoint)
    }

    cleanup(sending) {
        if (sending?.data?.ctrlKey !== undefined) {
            delete sending.data.ctrlKey
        }

        if (sending?.data?.altKey !== undefined) {
            delete sending.data.altKey
        }

        if (sending?.data?.componentId !== undefined) {
            delete sending.data.componentId
        }

        if (sending?.data?.fieldId !== undefined) {
            delete sending.data.fieldId
        }

        if (sending?.data?.originalKey !== undefined) {
            delete sending.data.originalKey
        }

        if (sending?.modifiers !== undefined) {
            delete sending.modifiers
        }

        return sending
    }

    sending(message, session_id, activeComponent, activeField) {
        let changedType = false
        let { data, type, modifiers } = message
        if (!type && typeof data === 'number') {
            type = 'key'
        }

        if (!type) {
            type = 'sendBuffer'
        }

        let sending = {
            type,
            data,
            session_id,
            modifiers,
        }

        // Se o componente deve obedecer um formato de envio, usamos ele
        // Usado do RelatorioDialog, ListaSelecao e etc
        if (
            activeComponent?.payload?.ws?.retType &&
            (type === 'sendBuffer' || type === 'key')
        ) {
            changedType = true
            sending.type = activeComponent.payload.ws.retType
            if (sending.type === 'sendEdit') {
                if (typeof sending.data === 'string') {
                    sending.data = {
                        // passamos a string como key
                        key: sending.data,
                        // value: sending.data,
                        // key: -1, // keycodes.ENTER_KEY,
                    }
                } else if (typeof sending.data === 'number') {
                    sending.data = {
                        key: sending.data,
                    }
                }
            }
        }

        // Se o componente ativo tem algum dado para enviar, adicionamos ele ao envio
        if (activeComponent?.payload?.ws?.data) {
            // só podemos usar o que vem do componente se tivermos realmente alterado o type
            if (changedType && sending.type !== 'sendEdit') {
                // Pegamos o objeto montado no componente e removemos qualquer posição
                // vazia para evitar envios indesejados, ficamos sem a habilidade de remover
                // campos dos envios.
                let toAdd = Object.keys(activeComponent.payload.ws.data)
                    .filter(
                        key =>
                            !checkNullOrUndefined(
                                activeComponent.payload.ws.data[key]
                            )
                    )
                    .reduce((acc, key) => {
                        return {
                            ...acc,
                            [key]: activeComponent.payload.ws.data[key],
                        }
                    }, {})

                if (typeof sending.data !== 'object') {
                    toAdd.key = sending.data
                } else {
                    toAdd = {
                        ...sending.data,
                        ...toAdd,
                    }
                }
                sending.data = toAdd
            }
        }

        // Trata casos que apertamos CTRL e CTRL+ALT
        if (sending?.data?.ctrlKey) {
            if (sending?.data?.altKey) {
                sending.data.key = CTRL_ALT_MAPPING[sending.data.key]
            } else {
                sending.data.key = CTRL_MAPPING[sending.data.key]
            }
        }

        const key = this.getKey(sending.data)
        // Caso especial do REC_MENU que envia texto sem estar em um campo ativo
        if (
            activeField.typeRec === 'REC_MENU' &&
            navigationKeys.indexOf(key) === -1 &&
            sending.type.indexOf('DLL') === -1
        ) {
            sending.data = {
                value: String.fromCharCode(key),
                key: keycodes.ENTER_KEY,
            }
        }

        if (sending.type === 'sendEdit' && typeof sending.data !== 'object') {
            sending.data = { key: sending.data }
        }

        return sending
    }

    connect(endpoint) {
        /**
         * Adiciona um método para enviar dados ao websocket
         * permitindo disparar alguns eventos durante o envio
         * das mensagens pro WS
         */
        if (this._connection) {
            debug('WS já conectado.')
            return this
        }

        if (endpoint) {
            this._endpoint =
                endpoint.indexOf('://') > -1 ? endpoint : `ws://${endpoint}`
        }

        if (typeof WebSocket !== 'undefined') {
            this._triggerChangeState(WebsocketManager.CONNECTING)

            const that = this

            return new Promise(fulfill => {
                const ws = new WebSocket(this._endpoint)
                const _send = ws.send

                ws.onopen = event => {
                    debug('onopen', event)

                    fulfill(ws)

                    this._triggerChangeState(WebsocketManager.CONNECTED)
                    this.emit('connected', { ws })

                    if (ws.limboTimer) {
                        clearInterval(ws.limboTimer)
                    }
                    // TODO: Potentially dangerous
                    ws.limboTimer = setInterval(() => {
                        this.sendFromQueue()
                    }, 50)
                }

                ws.onmessage = event => {
                    const triggerChange = this._triggerChangeState.bind(this)
                    debug('onmessage', event)

                    this.emit('RAW_MESSAGE', event)

                    if (that.__timer) {
                        clearTimeout(that.__timer)
                        that.__timer = null
                    }

                    if (this.state !== WebsocketManager.CONNECTED) {
                        clearTimeout(that._waitingTimer)
                        that._waitingTimer = setTimeout(() => {
                            triggerChange(WebsocketManager.CONNECTED)
                        }, 25)
                    }

                    try {
                        let json = JSON.parse(event.data)
                        let { type, data, ...otherProps } = json

                        if (
                            (type.substr(0, 5) === 'Fecha' ||
                                type.substr(-3) === 'End' ||
                                type.substr(0, 5) === 'Close') &&
                            type !== 'LoginEnd' &&
                            type !== 'FechaPainelO2'
                        ) {
                            data = data = {
                                component: type
                                    .replace('Fecha', '')
                                    .replace('End', '')
                                    .replace('Close', ''),
                            }

                            if (data.component === 'Hint') {
                                data.component = 'HintWeb'
                            }

                            type = 'ComponentClose'
                        }

                        if (type === 'DLL') {
                            this.ignore = true
                        }

                        let is = {
                            menuNormal:
                                type === 'Menu' &&
                                otherProps?.typeMenu === 'normal',
                            fltEditor: type === 'Editor' && data?.flt,
                        }

                        // limpa a fila caso seja um menu do tipo normal (flutuante, tipico em listagens)
                        if (is.menuNormal || is.fltEditor) {
                            // console.debug('queue emptied on receive', is)
                            this._msgs = []
                        }

                        const countListeners = this.listenerCount(type)

                        if (!countListeners) {
                            alert(
                                'Ainda não há implementação do componente ' +
                                    type +
                                    '.'
                            )

                            this._connection.send(
                                sendBuffer(keycodes.ESCAPE_KEY, 'sendBuffer')
                            )

                            json = null
                            type = null
                            otherProps = null

                            return
                        }

                        let args = {
                            data:
                                typeof data !== 'string'
                                    ? Object.assign(
                                          data || {},
                                          otherProps || {}
                                      )
                                    : data,
                        }

                        this.emit(type, args)

                        if (type === 'shutdown') {
                            this.shutdown = true
                        }

                        json = null
                        type = null
                        args = null
                        otherProps = null
                    } catch (e) {
                        // tratar falha no protocolo
                        console.error(e)
                        this.waiting = false
                    }

                    this.sendFromQueue()
                }

                ws.onclose = err => {
                    debug('onclose', err)

                    this._triggerChangeState(WebsocketManager.CLOSED)

                    // cancel timer
                    clearInterval(ws.limboTimer)

                    if (err) {
                        this.emit('ERROR', err)
                    }

                    ws.onopen = null
                    ws.onmessage = null
                    ws.onclose = null
                    ws.send = _send
                    this._connection = null

                    if (
                        this._retries < WebsocketManager.MAX_RETRIES &&
                        !err.wasClean &&
                        !this.shutdown
                    ) {
                        this.connect(endpoint)
                    }
                }

                ws.send = function(body) {
                    let parsedBody = body,
                        payload = body
                    if (typeof parsedBody === 'object') {
                        parsedBody = that.cleanup(parsedBody)
                        parsedBody = JSON.stringify(body)
                    } else {
                        payload = JSON.parse(body)
                    }

                    if (that.ignore) {
                        if (payload.type !== 'DLLRet') {
                            parsedBody = null
                            payload = null
                            return
                        } else {
                            that.ignore = false
                        }
                    }

                    if (!that.__timer) {
                        if (that.__timer) {
                            clearTimeout(that.__timer)
                        }
                        that.__timer = null
                        const triggerChange = that._triggerChangeState.bind(
                            that
                        )
                        that.__timer = setTimeout(() => {
                            triggerChange(WebsocketManager.DEAD)
                        }, WebsocketManager.MAX_MESSAGE_TIMEOUT * 1000)
                    }

                    that._triggerChangeState(WebsocketManager.WAITING)

                    payload = null

                    return _send.call(this, parsedBody)
                }
            }).then(ws => {
                this._connection = ws
                return this
            })
        }

        return false
    }

    hasMessage() {
        return this._msgs?.length > 0
    }

    getKey(data) {
        return data?.key ? data.key : data
    }

    processMessage(message, isQueue = true) {
        let state = this.redux.store.getState()
        let session_id = state.eac?.session?.session_id
        let activeComponent = getActiveComponent(state)
        let fieldList = getFieldList(activeComponent)
        let activeField = getActiveField(fieldList || [])

        if (!message || !session_id) {
            return null
        }

        let sending = this.sending(
            message,
            session_id,
            activeComponent,
            activeField
        )

        let keysToSendWithoutValue = [...defaultKeysToSendWithoutValue]
        if (activeField?.bt) {
            activeField.bt.forEach(bt => keysToSendWithoutValue.push(bt.key))
        }

        let key = this.getKey(sending.data)
        let is = {
            navigationKey: navigationKeys.indexOf(key) > -1 || key === -1,
            keyWithoutValue: keysToSendWithoutValue.indexOf(key) > -1,
            sendWithoutValue:
                sendWithoutValue.indexOf(activeField?.typeRec) > -1,
        }

        if (
            !is.navigationKey &&
            !is.sendWithoutValue &&
            !is.keyWithoutValue &&
            activeField?.enabled &&
            sending.type === 'sendEdit' &&
            sending.data?.componentId === undefined &&
            !sending.modifiers?.repeat
        ) {
            if (
                key === 44 ||
                key === 46 ||
                (key >= 48 && key <= 58) ||
                (key >= 65 && key <= 90) ||
                (key >= 97 && key <= 121)
            ) {
                this.__value += String.fromCharCode(key)
            }

            state = null
            session_id = null
            activeComponent = null
            fieldList = null
            activeField = null
            sending = null
            keysToSendWithoutValue = null
            key = null
            is = null

            return -1
        }

        if (this.__value && is.navigationKey) {
            sending.data = {
                value: this.__value,
                x: activeField.x,
                y: activeField.y,
            }
            this.__value = ''
        }

        if (
            (sending.value === '' || sending.value === undefined) &&
            this._msgs?.length > 0 &&
            is.navigationKey &&
            isQueue
        ) {
            return -1
        }

        state = null
        session_id = null
        activeComponent = null
        fieldList = null
        activeField = null
        keysToSendWithoutValue = null
        key = null
        is = null
        return sending
    }

    fetchNextMessage() {
        if (!this.hasMessage()) {
            return null
        }

        const payload = this._msgs.shift()
        const elapsed = new Date().getTime() - payload.date
        if (elapsed > WebsocketManager.MAX_PAYLOAD_TIMEOUT * 1000) {
            console.warn(
                `pacote ignorado após ${WebsocketManager.MAX_PAYLOAD_TIMEOUT}s, `,
                payload,
                this,
                elapsed
            )
            return null
        }

        return payload.message
    }

    sendFromQueue() {
        if (!this._connection) {
            return null
        }

        if (this.hasMessage()) {
            if (!this.waiting) {
                let message = this.fetchNextMessage()

                message = this.processMessage(message)
                if (message === -1 || !message) {
                    message = null
                    return null
                }

                this._connection.send(message)
                message = null
            } else {
                this.__value = ''
            }
        }
    }

    send(message) {
        if (this._connection) {
            let state = this.redux.store.getState()
            let activeComponent = getActiveComponent(state)
            let payload = {
                message,
                date: new Date().getTime(),
            }
            let is = {
                form: formComponents.indexOf(activeComponent?.name) > -1,
                menu:
                    activeComponent?.name === 'Menu' &&
                    activeComponent?.payload?.typeMenu === 'lateral',
                menuNormal:
                    activeComponent?.name === 'Menu' &&
                    activeComponent?.payload?.typeMenu === 'normal',
                fltEditor:
                    activeComponent?.name === 'Editor' &&
                    activeComponent?.payload?.flt,
            }

            let shouldQueue = this.waiting || this._msgs?.length > 0
            if (is.form || is.menu) {
                shouldQueue = true
            }

            // limpa a fila caso seja um menu do tipo normal (flutuante, tipico em listagens)
            if (is.menuNormal || is.fltEditor) {
                shouldQueue = true
                this._msgs = []
            }

            if (shouldQueue && !message?.modifiers?.repeat) {
                this._msgs.push(payload)
                this.sendFromQueue()
            } else {
                payload.message = this.processMessage(payload.message, false)
                this._connection.send(payload.message)
            }

            is = null
            state = null
            payload = null
            activeComponent = null
        }
    }

    close() {
        if (this._connection) {
            this.emit('closing')
            this._triggerChangeState(WebsocketManager.CLOSED)
            this._connection.close()
            this._connection = null
            this.emit('closed')
        }
    }
}

export const wsManager = createContext(null)

wsManager.current = new WebsocketManager()

export default WebsocketManager
