import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import IconButton from './IconButton'
import SliderButton from './SliderButton'

export default class ButtonActions extends Component {
    static propTypes = {
        data: PropTypes.array,
        classes: PropTypes.object.isRequired,
        maxButtonsSlider: PropTypes.number,
        showMore: PropTypes.bool.isRequired,
        toggleShowMore: PropTypes.func.isRequired,
        isEditorFormEdit: PropTypes.bool,
        active: PropTypes.bool,
        hasIcons: PropTypes.bool,
        activeValue: PropTypes.object,
    }

    static defaultProps = {
        active: true,
    }

    render() {
        const {
            active,
            activeValue,
            data,
            classes,
            maxButtonsSlider,
            showMore,
            toggleShowMore,
            hasIcons = false,
        } = this.props

        if (!data || !data.length) {
            return null
        }

        return (
            <div
                key="grid-buttons"
                className={clsx({
                    [classes.buttons]: true,
                    [classes.buttonsShowMore]: showMore,
                    [classes.fewButtons]:
                        data?.length < maxButtonsSlider || showMore,
                    [classes.lackIcons]: !hasIcons,
                })}
            >
                <SliderButton
                    data={data}
                    classes={classes}
                    showMore={showMore}
                    maxButtonsSlider={maxButtonsSlider}
                    active={active}
                    activeValue={activeValue}
                />

                {data?.length > maxButtonsSlider ? (
                    <IconButton
                        disabled={!active}
                        id="showMoreButton"
                        className={clsx(classes.showMoreButton, {
                            [classes.lackIconsShowMore]: !hasIcons,
                            [classes.iconShowMore]: showMore,
                        })}
                        tooltipPosition="top-end"
                        tooltip={showMore ? 'Esconder todos' : 'Mostrar todos'}
                        onClick={toggleShowMore}
                    />
                ) : null}
            </div>
        )
    }
}
