export const styles = theme => ({
    root: { overflow: 'visible' },
    listRoot: {
        paddingBottom: 0,
        height: `calc(100vh - ${theme.sizing.topBar}px)`,
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            height: `calc(100vh - ${theme.sizing.topBarSmall}px)`,
        },
    },
    listRootTypes: {
        padding: 0,
        height: 'auto',
        maxHeight: '80vh',
    },
    listRootSecondary: {
        paddingTop: theme.spacing(2),
    },
    listRootSecondaryClosed: {
        overflow: 'hidden',
        height: '100%',
    },
    menuRoot: {
        width: `${theme.sizing.sidebar}px`,
        marginTop: theme.sizing.topBar,
        overflow: 'visible',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            width: `${theme.sizing.sidebarSmall}px`,
            marginTop: theme.sizing.topBarSmall,
        },
    },
    menuBackdrop: {
        backgroundColor: 'rgba(0,0,0,0.15) !important',
    },
    menuRootRight: {
        left: 'auto',
        right: 0,
        position: 'fixed',
        bottom: 0,
        height: 'auto',
    },
    menuRootTypes: {
        paddingTop: 4,
        paddingBottom: 4,
        borderBottom: '1px solid #ccc',
    },
    menuClosed: {
        height: `${theme.spacing(1)}px`,
    },
    menuPrincipalClosed: {
        width: `${theme.sizing.sidebarPrincipalClosed}px`,
    },
    close: {
        top: '25px',
        zIndex: 1,
        color: theme.palette.contrastText.main,
        width: '20px',
        height: '30px',
        right: '-20px',
        display: 'table',
        lineHeight: '30px',
        cursor: 'pointer',
        position: 'absolute',
        fontSize: '14px',
        margin: '0 !important',
        borderRadius: '0 50% 50% 0',
        backgroundColor: theme.palette.primary.main,
        transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
        '& svg': {
            fontSize: '16px',
            verticalAlign: 'middle',
        },
    },
    closeSecondary: {
        top: '-5px',
        transform: 'rotate(90deg) translateY(-50%)',
        right: '50%',
    },
    closed: {
        '& svg': {
            transform: 'rotate(180deg)',
        },
    },
    menuBox: {
        margin: 16,
        marginTop: theme.sizing.topBar + 16 + 'px',
        width: `${theme.sizing.sidebar - 16}px`,
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.sizing.topBarSmall + 16 + 'px',
            width: `${theme.sizing.sidebarSmall - 16}px`,
        },
    },
    listRootBox: {
        height: 'calc(100vh - 88px)',
        padding: 0,
    },
    listItemBox: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    },
    backgroudCurrentChoiceMenu: {
        backgroundColor: 'rgba(35, 34, 34, 0.15)',
    },
    belowActive: {
        zIndex: '1500 !important',
    },
})
