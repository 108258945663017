import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Tooltip from '@material-ui/core/Tooltip'
import ReturnIcon from '@nerus/framework/icons/Return'
import { styles } from '@nerus/styles/components/topBar/backButton'
import PropTypes from 'prop-types'
import React from 'react'

const useStylesNerus = makeStyles(styles)

function BackButton({ onClick }) {
    const classes = useStylesNerus()
    return (
        <Tooltip title={'ESC-Voltar'}>
            <IconButton className={classes.root} onClick={onClick}>
                <ReturnIcon />
            </IconButton>
        </Tooltip>
    )
}

export default BackButton

BackButton.propTypes = {
    classes: PropTypes.object,
    onClick: PropTypes.func,
}
