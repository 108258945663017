import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/relatorioDialogo'
import { connect } from 'react-redux'

import { withWS } from '../../Business/Websocket/Context'
import { getComponentById } from '../../Eac/EacReducer'
import BaseRelatorioDialog from './BaseRelatorioDialog'

/**
 * Componente RelatorioDialog
 */
export class RelatorioDialog extends BaseRelatorioDialog {
    // Retrieve data from store as props
    static mapStateToProps = (state, ownProps) => {
        const data = getComponentById(ownProps.id, state).payload
        return {
            data,
        }
    }

    static baseOpts = {
        skip: true,
    }

    // Renderiza o wrapper
    render() {
        return this.renderDialog(this.state.forms)
    }
}

export default connect(RelatorioDialog.mapStateToProps)(
    withStyles(styles)(withWS(RelatorioDialog))
)
