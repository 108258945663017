import { updatePart } from '../EacReducer'
import Interface from './Interface'

export default class Menu extends Interface {
    static before({ id, state, action, subreducers }) {
        let { title, typeMenu, strList, enabled } = action.payload

        if (!title) {
            action.payload.title = ''
        }

        if (!typeMenu) {
            action.payload.typeMenu = 'lateral'
        }

        typeMenu = action.payload.typeMenu = typeMenu.trim().toLowerCase()
        title = action.payload.title = title.trim()

        if (strList) {
            action.payload.strList = strList
                .filter(menu => {
                    return menu.trim() !== ''
                })
                .map(menu => {
                    return menu.trim()
                })
        }

        if (typeMenu === 'guias' && action.payload && action.payload.content) {
            action.payload.hasTripleId = true

            const content =
                action.payload.content?.atualiza || action.payload.content

            // Monta o payload conforme um componente normal funcionaria
            const payload = content.data
            payload.ws = {
                retType: content.retType,
                data: {},
                form: [],
            }
            payload.flt = false

            const { data, ...rest } = content
            const receivingComponent = {
                type: !action.payload.content?.atualiza
                    ? 'ADD_COMPONENT'
                    : 'UPDATE_COMPONENT',
                name: content.type,
                id: content.id,
                payload: {
                    ...rest,
                    ...data,
                    enabled,
                },
            }

            state.components[content.id] = updatePart(
                receivingComponent,
                content.type === 'Formulario'
                    ? state.components[content.id] || receivingComponent
                    : receivingComponent
            )

            if (action.payload.content?.atualiza) {
                const { retType, type, id, ...data } =
                    state.components[content.id].payload
                action.payload.content = {
                    id,
                    type,
                    retType,
                    data,
                }
            }

            const Reducer = subreducers[content.type]
            if (Reducer) {
                Reducer.before({
                    id: content.id,
                    state,
                    action: state.components[content.id],
                    subreducers,
                })
            } else {
                console.error(
                    `O componente ${content.type} não é válido em uma Guia`
                )
            }
        }

        if (typeMenu !== 'lateral' && typeMenu !== 'box') {
            // apenas o menu lateral realiza as tasks abaixo
            return { id, state, action }
        }

        if (title === 'Menu Principal') {
            state.menusLifo = []
            state.componentsLifo = []

            state.lastFixedComponentId = null
            state.componentsFixedLifo = []
            state.components = {}
            state.components[id] = { ...action }
        }

        const index = state.menusLifo.indexOf(id)
        // se não encontramos um menu com essa ID, apenas inserimos
        if (index < 0) {
            state.menusLifo.push(id)
        } else {
            // removemos todos os menus a frente do menu recebido
            const removed = state.menusLifo.splice(index + 1)
            removed.forEach(id => {
                delete state.components[id]
            })
        }

        /**
         * sempre que recebemos um Menu do tipo Lateral
         * não temos componentes abertos, então
         * fechamos todos removendo da LIFO
         * Caso seja um Box, verificamos se o ultimo componente
         * ativo não é o PDV
         */
        if (
            !state.lastActiveComponent ||
            state.lastActiveComponent.name !== 'EditorFormEditPdv'
        ) {
            let pdvId = null
            state.componentsLifo.forEach(id => {
                if (
                    state.components[id] &&
                    state.components[id].name !== 'EditorFormEditPdv'
                ) {
                    if (!state.components[id].payload.dontClean) {
                        delete state.components[id]
                    }
                } else {
                    pdvId = id
                }
            })
            state.componentsLifo = state.componentsLifo
                .map(id => (state.components[id] ? id : false))
                .filter(Boolean)
            if (pdvId) {
                state.componentsLifo.push(pdvId)
            }

            state.componentsFixedLifo = []
            state.lastFixedComponentId = null
        }

        return { id, state, action, stop: true }
    }

    static buildDefaultsDeepSource(payload = {}) {
        payload.content?.data?.editor?.data?.clrs?.forEach(clr => {
            delete clr.lns
        })

        delete payload.content?.data?.form?.data?.list

        delete payload.content?.data?.cols
        delete payload.content?.data?.rows
        delete payload.content?.data?.list
        delete payload.content?.data?.btIcons

        delete payload.content?.data?.editor?.data?.cols
        delete payload.content?.data?.editor?.data?.rows
        delete payload.content?.data?.editor?.data?.btIcons

        return payload
    }
}
