/* eslint-disable no-useless-escape */
import Badge from '@material-ui/core/Badge'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Chip from '@material-ui/core/Chip'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import NotificationsIcon from '@material-ui/icons/Notifications'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import makeStyles from '@material-ui/styles/makeStyles'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useDispatch } from 'react-redux'

import { setWsStop } from '../../../../App/AppActions'
import useFetchNews from './useFetchNews'
import useReadNews from './useReadNews'

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.common.white,
    },
    badge: {
        top: theme.spacing(1.25),
        right: theme.spacing(1),
        background: theme.palette.common.white,
        color: theme.palette.primary.main,
    },
    drawer: {
        flexShrink: 0,
    },
    drawerPaper: {
        width: 400,
        border: 'none',
        backgroundColor: theme.palette.bodyColor,
        boxShadow:
            '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    headingBox: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },
    headingConfig: {
        padding: theme.spacing(0.5, 0, 0.5, 2),
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        alignItems: 'center',
        display: 'flex',
        '& span': {
            flex: 1,
            alignItems: 'center',
            display: 'flex',
        },
        '& svg:first-child': {
            marginRight: 8,
        },
    },
    headingClose: {
        float: 'right',
        color: theme.palette.common.white,
    },
    content: {
        backgroundColor: theme.palette.bodyColor,
        padding: theme.spacing(2),
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    cardTitle: {
        fontSize: 14,
        color: '#000000',
    },
    cardBody: {
        fontSize: 12,
        color: '#333333',
    },
    cardMeta: {
        textAlign: 'right',
        color: '#666666',
        fontSize: 11,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    cardMedia: {
        minHeight: 250,
    },
    cardHeader: {
        display: 'flex',
    },
    cardUnread: {
        fontSize: 12,
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
    cardUrgent: {
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
}))

function New({ data }) {
    const classes = useStyles()
    const openNewWindow = useCallback(
        link => () => {
            window.open(link, '_blank')
        },
        []
    )
    return (
        <Card className={classes.card} onClick={openNewWindow(data.permalink)}>
            <CardActionArea>
                <CardContent className={classes.cardHeader}>
                    {data.unread ? (
                        <Chip label="Novo" className={classes.cardUnread} />
                    ) : null}
                    {data.tags.indexOf('urgente') > -1 ? (
                        <Chip label="Urgente" className={classes.cardUrgent} />
                    ) : null}
                    <Typography
                        className={classes.cardMeta}
                        gutterBottom
                        variant="caption"
                        component="p"
                    >
                        {moment(data.date).format(`DD/MM/YYYY \à\\s HH:mm`)}
                    </Typography>
                </CardContent>
                <CardContent>
                    <Typography
                        className={classes.cardTitle}
                        gutterBottom
                        variant="h5"
                        component="h2"
                    >
                        {data.title}
                    </Typography>
                    <Typography
                        className={classes.cardBody}
                        variant="body2"
                        color="textSecondary"
                        component="div"
                        dangerouslySetInnerHTML={{ __html: data.content }}
                    />
                </CardContent>
                {data.image && data.image !== '0' ? (
                    <CardMedia
                        className={classes.cardMedia}
                        image={data.image}
                        title={data.title}
                    />
                ) : null}
            </CardActionArea>
        </Card>
    )
}

New.propTypes = {
    data: PropTypes.any,
}

export default function News({ userNo: user_id }) {
    const classes = useStyles()
    const [show, setShow] = useState(false)
    const showRef = useRef(show)
    const dispatch = useDispatch()

    const [save] = useReadNews()
    const { data: news } = useFetchNews({
        variables: { data: { user_id } },
        skip: !user_id,
    })

    const hasUrgentUnread = useMemo(
        () =>
            news?.news?.filter?.(
                n => n.tags?.indexOf?.('urgente') > -1 && n.unread
            )?.length > 0,
        [news]
    )

    const unreads = useMemo(() => news?.news?.filter?.(n => n.unread)?.length, [
        news,
    ])

    const showDrawer = useCallback(
        (state = true) => () => {
            setShow(state)
            const ids = news?.news?.filter(n => n.unread)?.map(n => n.id)
            if (!state && ids?.length) {
                save({
                    variables: {
                        data: {
                            user_id,
                            news: ids,
                        },
                    },
                })
            }
        },
        [setShow, news, user_id]
    )

    useEffect(() => {
        if (!show && hasUrgentUnread && unreads > 0) {
            setShow(hasUrgentUnread)
        }
    }, [hasUrgentUnread])

    useEffect(() => {
        if (show !== showRef.current) {
            setTimeout(() => {
                dispatch(setWsStop())
            }, 500)
        }
        showRef.current = show
    }, [show])

    return (
        <Fragment>
            <Badge
                badgeContent={unreads}
                classes={{ badge: classes.badge }}
                overlap="rectangular"
            >
                <IconButton className={classes.root} onClick={showDrawer(true)}>
                    {unreads > 0 ? (
                        <NotificationsActiveIcon />
                    ) : (
                        <NotificationsIcon />
                    )}
                </IconButton>
            </Badge>

            <Drawer
                className={classes.drawer}
                anchor="right"
                open={show}
                onClose={showDrawer(false)}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.headingBox}>
                    <Typography className={classes.headingConfig}>
                        <span>Central de Notícias</span>
                        <IconButton
                            className={classes.headingClose}
                            onClick={showDrawer(false)}
                        >
                            <Close />
                        </IconButton>
                    </Typography>
                </div>

                <div className={classes.content}>
                    {news?.news?.map?.(n => (
                        <New key={n.id} data={n} />
                    ))}
                </div>
            </Drawer>
        </Fragment>
    )
}

News.propTypes = {
    userNo: PropTypes.any,
}
