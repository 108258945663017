import withStyles from '@material-ui/core/styles/withStyles'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import { styles } from '@nerus/styles/components/breadcrumb'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { getIsPdv } from '../../../App/AppReducer'
import { getBreadcrumb } from '../../Eac/EacReducer'

export class Breadcrumb extends EnhancedComponent {
    static propTypes = {
        data: PropTypes.array.isRequired,
        classes: PropTypes.object.isRequired,
    }

    /**
     * Recupera o dados para renderizar o breadcrumb
     */
    static mapStateToProps = state => {
        return {
            data: getBreadcrumb(state),
            isPdv: getIsPdv(state),
        }
    }

    shouldComponentUpdate(nextProps) {
        return this.checkProps(['isPdv', 'data'], nextProps)
    }

    render() {
        const { data, classes, isPdv } = this.props

        if (!data) {
            return null
        }

        const { hasError } = this.state

        if (hasError) {
            return this.renderError()
        }

        return (
            <div className={classes.root}>
                <ul className={classes.list}>
                    {!isPdv ? (
                        data.map((bread, i) => {
                            return (
                                <li
                                    key={`bread-${i}-${bread}`}
                                    className={clsx({
                                        [classes.listItem]: true,
                                        [classes.listFirstItem]: i === 0,
                                        [classes.listOtherItems]: i !== 0,
                                    })}
                                >
                                    {bread}
                                </li>
                            )
                        })
                    ) : (
                        <li
                            className={clsx(
                                classes.listItem,
                                classes.listFirstItem
                            )}
                        >
                            Ponto de Venda
                        </li>
                    )}
                </ul>
            </div>
        )
    }
}

export default connect(Breadcrumb.mapStateToProps)(
    withStyles(styles)(Breadcrumb)
)
