/**
 * Constantes que definem as ações utilizadas pela aplicação
 */
export const WS_CONNECT = 'WS_CONNECT'
export const WS_DISCONNECT = 'WS_DISCONNECT'
export const WS_DIALOGOPROGRESSAO = 'WS_DIALOGOPROGRESSAO'
export const WS_MESSAGEWINDOW = 'WS_MESSAGEWINDOW'
export const WS_LOGIN = 'WS_LOGIN'
export const WS_LOGIN_END = 'WS_LOGIN_END'
export const WS_NERUSINFO = 'WS_NERUSINFO'
export const RESET = 'RESET'
export const SET_FORMULARIO = 'SET_FORMULARIO'
export const REMOVE_COMPONENT = 'REMOVE_COMPONENT'
export const ADD_COMPONENT = 'ADD_COMPONENT'
export const UPDATE_COMPONENT = 'UPDATE_COMPONENT'
export const UPDATE_WS_DATA = 'UPDATE_WS_DATA'
export const RESET_ELEMENT_INDEX = 'RESET_ELEMENT_INDEX'
export const NEXT_ELEMENT_INDEX = 'NEXT_ELEMENT_INDEX'
export const PREVIOUS_ELEMENT_INDEX = 'PREVIOUS_ELEMENT_INDEX'
export const LOCK_MOUSE_CLICK = 'LOCK_MOUSE_CLICK'
export const TOGGLE_LEGEND = 'TOGGLE_LEGEND'
export const TOO_MUCH_RETRIES = 'TOO_MUCH_RETRIES'
export const ALREADY_CONNECTED = 'ALREADY_CONNECTED'
export const DLL = 'DLL'
export const CLEAR_DLL = 'CLEAR_DLL'
export const FINGERPRINT = 'FINGERPRINT'
export const CLEAR_FINGERPRINT = 'CLEAR_FINGERPRINT'
export const O2_OPEN = 'O2_OPEN'
export const O2_CLOSE = 'O2_CLOSE'

/**
 * Abre ou Fecha o O2
 */
export function o2(open = true) {
    return {
        type: open ? O2_OPEN : O2_CLOSE,
        payload: {},
    }
}

/**
 * Reseta o indice do elemento em foco
 * utilizado nas Dialogos
 */
export function resetElementIndex(index = 0) {
    return {
        type: RESET_ELEMENT_INDEX,
        payload: {
            index,
        },
    }
}

/**
 * Vai para o próximo indice para
 * exibir foco no próximo elemento
 */
export function nextElementIndex() {
    return {
        type: NEXT_ELEMENT_INDEX,
        payload: {},
    }
}

export function previousElementIndex() {
    return {
        type: PREVIOUS_ELEMENT_INDEX,
        payload: {},
    }
}

/**
 * Reseta os componentes carregados para o
 * estado inicial, ou seja, sem componentes
 */
export function resetComponents(doLogout = false) {
    return {
        type: RESET,
        payload: {
            isLogout: doLogout,
        },
    }
}

/**
 * Adiciona um componente recebido do WS
 * Essa ação é usada pra todo componente recebido
 * com excessão da DialogoProgressao e MessageWindow que para ficar
 * acima do 'Carregando' e para termos mais controle sobre
 * o seu fechamento, usamos outra ação para inserir e fechar
 */
export function addComponent(name, payload) {
    return {
        type: ADD_COMPONENT,
        name,
        payload,
    }
}

/**
 * Adiciona um componente recebido do WS
 * Essa ação é usada pra todo componente recebido
 * com excessão da DialogoProgressao e MessageWindow que para ficar
 * acima do 'Carregando' e para termos mais controle sobre
 * o seu fechamento, usamos outra ação para inserir e fechar
 */
export function updateComponent(name, payload) {
    return {
        type: UPDATE_COMPONENT,
        name,
        payload,
    }
}

/**
 * Adiciona um componente recebido do WS
 * Essa ação é usada pra todo componente recebido
 * com excessão da DialogoProgressao e MessageWindow que para ficar
 * acima do 'Carregando' e para termos mais controle sobre
 * o seu fechamento, usamos outra ação para inserir e fechar
 */
export function updateWsData(id, payload) {
    return {
        type: UPDATE_WS_DATA,
        id,
        payload,
    }
}

/**
 * Remove o último componente do `type` aberto
 */
export function removeComponent(type = 'Dialogo', id = null) {
    return {
        type: REMOVE_COMPONENT,
        payload: { id, type },
    }
}

/**
 * Ação que notifica a aplicação de uma conexão bem sucedida
 * ao WS, permite também especificar quais componentes já
 * devem estar carregados quando conectar.
 * Util para recuperar a sessão.
 */
export function connect(loadedComponents) {
    return {
        type: WS_CONNECT,
        payload: {
            ...loadedComponents,
        },
    }
}

/**
 * Ação que notifica a a aplicação que houve a conexão
 * ao WS foi encerrada.
 * Ressaltando, que não necessariamente a conexão foi
 * encerrada corretamente
 */
export function disconnect() {
    return {
        type: WS_DISCONNECT,
        payload: {},
    }
}

/**
 * Ação que define que já houveram mais
 * de 3 tentativas de conexão
 */
export function tooMuchRetries() {
    return {
        type: TOO_MUCH_RETRIES,
        payload: {},
    }
}

/**
 * Ação que define que já há uma conexão
 * ativa e exibe tela informativa
 */
export function alreadyConnected() {
    return {
        type: ALREADY_CONNECTED,
        payload: {},
    }
}

/**
 * Ação que recebe o evento "Login" do WS
 * Atualmente recebemos um vetor vazio, mas,
 * no futuro podemos receber outras informações
 * como a aplicação estar em Manutenção ou não
 */
export function login(data) {
    return {
        type: WS_LOGIN,
        payload: {
            ...data,
        },
    }
}

/**
 * Ação que recebe o evento "LoginEnd" do WS
 * Altera a aplicação para o estado logado
 */
export function loginEnd() {
    return {
        type: WS_LOGIN_END,
        payload: null,
    }
}

/**
 * Ação que recebe o evento "NerusInfo" do WS
 * Possui os dados do usuário, empresa e nerus/saci
 */
export function nerusInfo(data) {
    return {
        type: WS_NERUSINFO,
        payload: {
            ...data,
        },
    }
}

/**
 * Ação que recebe o componente "DialogoProgressao" do WS
 * Mantivemos ele separado para melhorar o layout e simplificar
 * o formato de adicionar e remover o componente
 */
export function dialogoProgressao(data) {
    return {
        type: WS_DIALOGOPROGRESSAO,
        payload: {
            ...data,
        },
    }
}

/**
 * Ação que recebe o componente "MessageWindow" do WS
 * Mantivemos ele separado para melhorar o layout e simplificar
 * o formato de adicionar e remover o componente
 */
export function messageWindow(data) {
    return {
        type: WS_MESSAGEWINDOW,
        payload: {
            ...data,
        },
    }
}

/**
 * Seta o Formulario ativo na aplicação
 * @TODO: podemos substituir isso pelo ADD_COMPONENTS
 * mantendo track do formulário ativo
 */
export function setFormulario(data) {
    return {
        type: SET_FORMULARIO,
        payload: {
            ...data,
        },
    }
}

/**
 * Essa ação trava o click do mouse
 * @TODO: acho que ela pode ficar diretamente ligada ao websocketIsAlive
 */
export function lockMouseClick(data) {
    return {
        type: LOCK_MOUSE_CLICK,
        payload: {
            data,
        },
    }
}

/**
 * Exibe ou esconde a janela de legendas
 */
export function toggleLegend() {
    return {
        type: TOGGLE_LEGEND,
        payload: {},
    }
}

/**
 * Ação que realiza o envio do buffer através do método
 * "sendBuffer" do WS, caso seja enviado um numero (que)
 * utilizamos o método "key" do WS
 *
 * Método usado para normalizar o envio para o WS
 */
export function sendBuffer(data, type = 'key', modifiers = {}) {
    return {
        type,
        data,
        modifiers,
    }
}

/**
 * Ação que realiza a recepção das mensagens do WS para o DllService
 */
export function dll(data) {
    return { type: DLL, payload: { data } }
}

/**
 * Ação que realiza a limpeza das mensagens do WS para o DllService
 */
export function clearDll() {
    return { type: CLEAR_DLL }
}

/**
 * Ação que realiza a recepção das mensagens do WS para o DllService
 */
export function fingerprint(data) {
    return { type: FINGERPRINT, payload: { data } }
}

/**
 * Ação que realiza a limpeza das mensagens do WS para o DllService
 */
export function clearFingerprint() {
    return { type: CLEAR_FINGERPRINT }
}
