import React from 'react'

export function format(value, format) {
    let formatted = ''
    let where = value.length - 1
    let need = format.length - 1
    for (let i = format.length - 1; i > -1; i--) {
        let chr = format[i]

        need--

        switch (chr) {
            case '#': {
                chr = value[where]
                where--
                break
            }
            case '?': {
                let tmp = format.slice(0, need).split('#').length - 1
                if (where >= tmp) {
                    chr = value[where]
                    where--
                } else {
                    chr = ''
                }
                break
            }
        }
        formatted = `${chr}${formatted}`
    }

    return formatted
}

export function preprocessMessageOutput(msg) {
    if (!msg || !msg.replace) {
        return msg
    }

    return msg
        .replace('Pressione qualquer tecla p/ continuar...', '')
        .replace(/\n/g, '<br />')
        .replace(/Pressione qualquer tecla para continuar\.\.\./g, '')
        .replace(/`a/g, 'à')
        .replace(/a'/g, 'á')
        .replace(/e'/g, 'é')
}

/**
 * Essa função faz o tratamento de todos os formatos de teclas de atalho
 * É usado em todos os locais que precisamos exibir tecla
 */
export function formatTooltip(rawTip, atalho, underlined = false) {
    let tip = rawTip
    let legendClass = 'highlight'
    if (underlined) {
        legendClass += ' underlined'
    }

    if (tip === '(l)egenda') {
        // sem atalho
        return [
            <span key="legend-highlight" className={legendClass}>
                ?
            </span>,
            ' - Legenda de Cores',
        ]
    }

    if (tip === undefined) {
        return
    }

    if (!tip.split) {
        tip = tip.pop()
    }

    const parts = tip.split('-')
    const str = []
    let matched = false
    if (parts && parts.length > 1) {
        str.push(
            <span key={'tip-' + tip + '-1'} className={legendClass}>
                {parts[0].replace(/[()]/g, '')}
            </span>
        )
        str.push(' | ')
        str.push(parts[1].substr(0, 1).toUpperCase() + parts[1].substr(1))
        matched = true
    } else {
        // var matches = tip.match(/([a-zA-Z]*)\((.+)\)([a-zA-Z]*)/);
        let matches = tip.match(/(.*)(\s{1})([a-zA-Z]{1})(\s{1})(.*)/)
        if (matches) {
            if (matches[1]) {
                str.push(matches[1])
                str.push(
                    <span key={'tip-' + tip + '-2'} className={legendClass}>
                        {matches[3].trim()}
                    </span>
                )
            } else {
                str.push(
                    <span key={'tip-' + tip + '-3'} className={legendClass}>
                        {matches[3].trim().toUpperCase()}
                    </span>
                )
            }

            str.push(matches[5])
            matched = true
        } else {
            matches = tip.match(/([a-zA-Z]*)\((.+)\)([a-zA-Z]*)/)
            if (matches) {
                if (matches[1]) {
                    str.push(matches[1])
                    str.push(
                        <span key={'tip-' + tip + '-2'} className={legendClass}>
                            {matches[2].trim()}
                        </span>
                    )
                } else {
                    str.push(
                        <span key={'tip-' + tip + '-3'} className={legendClass}>
                            {matches[2].trim().toUpperCase()}
                        </span>
                    )
                }
                matched = true
                str.push(matches[3])
            } else {
                str.push(tip)
            }
        }

        if (!matched && atalho && str.length === 1) {
            let regex = new RegExp(atalho)
            let strReplaced = str[0].replace(
                regex,
                `<span class="${legendClass}">${atalho}</span>`
            )

            if (strReplaced === str[0]) {
                regex = new RegExp(atalho.toLowerCase())
                strReplaced = str[0].replace(
                    regex,
                    `<span class="${legendClass}">${atalho}</span>`
                )
            }

            return (
                <span
                    dangerouslySetInnerHTML={{
                        __html: strReplaced,
                    }}
                />
            )
        }
    }

    return str
}
