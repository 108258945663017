import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

// TODO: Mover para styles global e criar palette com cores Nérus
const styles = () => ({
    root: {
        backgroundColor: '#00A7CE',
    },
    cell: {
        padding: 6,
        fontSize: 12,
        textAlign: 'right',
        color: '#ffffff',
    },
})

class Totalizador extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        types: PropTypes.array,
    }

    constructor(props) {
        super(props)
    }

    renderColumn = rendered => (object, i) => {
        const { data, types, activeColumnSearch, classes } = this.props

        let colSpan = 1
        let hasTot = data.filter(tot => tot.colName === object.name)

        if (hasTot && hasTot?.length === data?.length) {
            colSpan = types.length - 1 // minus the first one
        } else {
            for (let j = i + 1; j < types.length; j++) {
                const hasTotAfter = data.filter(
                    tot => tot.colName === types[j].name
                )
                if (!hasTotAfter || !hasTotAfter.length) {
                    colSpan++
                } else {
                    break
                }
            }
        }

        if (
            (i !== 0 && (!hasTot || !hasTot?.length)) ||
            rendered.indexOf(object.name) > -1
        ) {
            if (rendered.indexOf(object.name) > -1) {
                hasTot = false
            } else {
                return null
            }
        }

        rendered.push(object.name)

        return (
            <th
                className={clsx({
                    'in-search': activeColumnSearch === i,
                    [classes.cell]: true,
                })}
                colSpan={colSpan}
                key={'th' + i}
            >
                {hasTot && hasTot[0] ? hasTot[0].value : ' '}
            </th>
        )
    }

    render() {
        const { data, types, classes } = this.props

        if (!data || !data.length || !types) {
            return null
        }

        const rendered = []

        return (
            <tfoot className={classes.root}>
                <tr>{types.map(this.renderColumn(rendered))}</tr>
            </tfoot>
        )
    }
}

Totalizador.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    columnsType: PropTypes.array,
    activeColumnSearch: PropTypes.number,
}

export default withStyles(styles)(Totalizador)
