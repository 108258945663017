import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import ActionClose from '@material-ui/icons/Close'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import keycodes from '@nerus/framework/common/Keycodes'
import { styles } from '@nerus/styles/components/flutuante'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import Draggable from 'react-draggable'
import { connect } from 'react-redux'

import { withWS } from '../../Business/Websocket/Context'
import { sendBuffer } from '../../Eac/EacActions'
import { getActiveComponent } from '../../Eac/EacReducer'

const debug = require('debug')('nerus:baseFlutuante')

export class BaseFlutuante extends EnhancedComponent {
    static propTypes = {
        dispatch: PropTypes.func,
        title: PropTypes.string,
        children: PropTypes.any.isRequired,
        actions: PropTypes.node,
        data: PropTypes.object,
        activeComponent: PropTypes.object,
        centered: PropTypes.bool,
        handleClose: PropTypes.func,
        classes: PropTypes.object,
        nospace: PropTypes.bool,
        noheader: PropTypes.bool,
        autosize: PropTypes.bool,
        size: PropTypes.string,
        contentRef: PropTypes.any,
        ws: PropTypes.object.isRequired,
        onKeydown: PropTypes.func,
    }

    firstTabStop = null
    lastTabStop = null

    state = {
        centerPosition: { x: 0, y: 0 },
        width: 0,
    }

    handleClick = event => {
        event.stopPropagation()
        debug('keydown', event)
        const componentsToDispatch = [
            'ListaSelecao',
            'RelatorioDialog',
            'DanfeDialog',
        ]

        if (
            this.props.activeComponent &&
            componentsToDispatch.indexOf(this.props.activeComponent.name) >
                -1 &&
            this.props.ws &&
            typeof document !== 'undefined'
        ) {
            let newEvent = new Event('keydown')
            newEvent.key = 27
            newEvent.keyCode = 27
            newEvent.which = 27
            newEvent.key = 'Esc'
            document.dispatchEvent(newEvent)
            return
        }

        const { ws } = this.props
        ws.send(
            sendBuffer(
                event.nerusEvent ? event.nerusEvent : keycodes.ESCAPE_KEY
            )
        )
    }

    onStart = () => {
        this.setState({
            activeDrags: 1,
        })
    }

    onStop = (e, data) => {
        this.setState({
            activeDrags: 0,
            centerPosition: data,
        })
    }

    componentDidMount() {
        const dialog = this.createRef('dialogFlex')

        if (this.props.centered) {
            const timerRef = this.createRef('timer')
            clearTimeout(timerRef.current)
            timerRef.current = setTimeout(() => {
                if (dialog.current) {
                    const windowHeight = window.innerHeight
                    const dialogHeight = dialog.current.offsetHeight
                    this.setState({
                        centerPosition: {
                            x: 0,
                            y: (windowHeight - dialogHeight) / 2,
                        },
                    })
                }
            }, 0)
        }

        if (dialog?.current) {
            const focusableElementsString =
                'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]'
            let focusableElements = dialog.current.querySelectorAll(
                focusableElementsString
            )
            // Convert NodeList to Array
            focusableElements = Array.prototype.slice.call(focusableElements)

            this.firstTabStop = focusableElements[0]
            this.lastTabStop = focusableElements[focusableElements.length - 1]

            dialog.current.addEventListener('keydown', this._onKeyDown)
        }

        document.body.addEventListener('keydown', this._onKeyDown)

        this.componentDidUpdate(this.props)
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        document.body.removeEventListener('keydown', this._onKeyDown)

        const dialog = this.createRef('dialogFlex')
        if (dialog.current) {
            dialog.current.removeEventListener('keydown', this._onKeyDown)
        }
    }

    componentDidUpdate(prevProps) {
        const ref = this.getRef('dialogFlex')
        if (
            ref &&
            this.state.width !== ref.scrollWidth &&
            ref.scrollWidth > this.state.width &&
            ref.scrollWidth > 0 &&
            this.props !== prevProps
        ) {
            this.setState({
                width: ref.scrollWidth,
            })
        }
    }

    _onKeyDown = e => {
        const { showCalculator } = this.props

        if (this.props.onKeydown) {
            debug('keydown: provided event', e)
            this.props.onKeydown(e)
        }

        if (e.defaultPrevented) {
            return
        }

        // Check for TAB key press
        if (e.keyCode === keycodes.TAB_KEY) {
            // SHIFT + TAB
            if (e.shiftKey) {
                if (document.activeElement === this.firstTabStop) {
                    e.preventDefault()
                    this.lastTabStop.focus()
                }

                // TAB
            } else {
                if (document.activeElement === this.lastTabStop) {
                    e.preventDefault()
                    this.firstTabStop.focus()
                }
            }
        }

        // ESCAPE
        if (e.keyCode === 27 && !showCalculator) {
            e.stopPropagation()
            e.stopImmediatePropagation && e.stopImmediatePropagation()
            this.onClose(e)
        }
    }

    onClose = event => {
        const handleClose = this.props.handleClose
            ? this.props.handleClose
            : this.handleClick
        handleClose(event)
    }

    render() {
        const dragHandlers = {
            onStart: this.onStart,
            onMouseDown: this.onStart,
            onStop: this.onStop,
        }
        const { centerPosition, width } = this.state
        const {
            title,
            children,
            centered,
            classes,
            actions,
            size,
            nospace,
            noheader,
            autosize,
            contentRef,
            className,
        } = this.props

        const typographyVariant = 'h3 > span'

        return (
            <div className={classes.root} ref={contentRef}>
                <Draggable
                    cancel={typographyVariant}
                    position={centered ? centerPosition : null}
                    handle={`.${classes.header}`}
                    {...dragHandlers}
                >
                    <div
                        className={clsx(classes.container, {
                            [classes.containerMedium]: size === 'medium',
                            [classes.containerBig]: size === 'big',
                            [classes.containerFullscreen]:
                                size === 'fullscreen',
                            [className]: className,
                        })}
                        style={
                            autosize === undefined || autosize
                                ? { minWidth: width }
                                : {}
                        }
                        ref={this.createRef('dialogFlex')}
                    >
                        {noheader ? (
                            <IconButton
                                className={clsx({
                                    [classes.headerClose]: true,
                                    [classes.headerlessButton]: true,
                                })}
                                onClick={this.onClose}
                                onTouchStart={this.onClose}
                            >
                                <ActionClose color="inherit" />
                            </IconButton>
                        ) : (
                            <div className={classes.header}>
                                {title ? (
                                    <Typography
                                        variant={'h3'}
                                        className={classes.title}
                                    >
                                        <span>{title}</span>
                                    </Typography>
                                ) : null}
                                <IconButton
                                    className={classes.headerClose}
                                    onClick={this.onClose}
                                    onTouchStart={this.onClose}
                                >
                                    <ActionClose color="inherit" />
                                </IconButton>
                            </div>
                        )}

                        <div
                            className={clsx(classes.content, {
                                [classes.spaced]: !nospace,
                            })}
                        >
                            {children}
                        </div>

                        {actions ? (
                            <div className={classes.actions}>{actions}</div>
                        ) : (
                            ''
                        )}
                    </div>
                </Draggable>
            </div>
        )
    }
}

export default connect(state => {
    return {
        activeComponent: getActiveComponent(state),
        showCalculator: state.app.showCalculator,
    }
})(withStyles(styles)(withWS(BaseFlutuante)))
