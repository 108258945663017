import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import * as PropTypes from 'prop-types'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'

import Pictures from '../Pictures'
import Previewer from '../Previewer'
import withGetPicture from '../withGetPicture'
import Field from './Field'
import Title from './Title'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        padding: theme.spacing(1, 0.5),
        backgroundColor: theme.palette.common.white,
        boxShadow:
            '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12)',
    },
    columns: {
        display: 'flex',
    },
    previewer: {
        maxWidth: '100% !important',
        minWidth: '100% !important',
        minHeight: 'inherit',
        maxHeight: '536px',
        overflow: 'hidden',
        '& img': {
            maxWidth: '100% !important',
        },
    },
    slider: {
        maxWidth: '100%',
    },
    sliderItem: {
        maxWidth: '17.5%',
        height: '50px',
        '@media(min-height: 767px)': {
            height: '100px',
        },
    },
    noproduct: {
        display: 'flex',
        flex: 1,
        height: 'calc(100% - 21px)',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 50,
    },
}))

const PdvPreviewer = ({ title, product, getPicture }) => {
    const classes = useStyles()
    const [activeFoto, setActiveFoto] = useState(null)

    const { /* loading, error, */ findPicture: fotos } = getPicture || {}

    const foto = activeFoto ? activeFoto : fotos ? fotos[0] : null
    const url = foto?.urlImagem
        ? foto.urlImagem
        : foto?.foto
        ? `data:image/jpg;base64,${foto.foto}`
        : null

    const onFotoClick = useCallback(foto => {
        return () => {
            setActiveFoto(foto)
        }
    }, [])

    useEffect(() => {
        setActiveFoto(null)
    }, [product])

    return (
        <Box className={classes.root}>
            <Title label={title} />

            {!product ? (
                <Box className={classes.noproduct}>
                    <Typography variant="h5">
                        Entre com um produto para mais informações
                    </Typography>
                </Box>
            ) : (
                <Fragment>
                    {product ? (
                        <Box className={classes.columns}>
                            <Field
                                label="Descrição"
                                value={product.name}
                                full
                            />
                            <Field
                                label="Preço Unitário"
                                value={
                                    <NumberFormat
                                        value={product.price}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        prefix={'R$ '}
                                    />
                                }
                                right
                            />
                        </Box>
                    ) : (
                        ''
                    )}

                    {fotos?.length > 0 ? (
                        <Field
                            label="Fotos"
                            flex
                            value={
                                <Fragment>
                                    <Previewer
                                        autoHeight
                                        className={classes.previewer}
                                        url={url}
                                    />
                                    <Pictures
                                        classes={{
                                            slider: classes.slider,
                                            sliderItem: classes.sliderItem,
                                        }}
                                        data={fotos || []}
                                        active={foto}
                                        onClick={onFotoClick}
                                        onDrag={null}
                                    />
                                </Fragment>
                            }
                        />
                    ) : null}
                </Fragment>
            )}
        </Box>
    )
}

PdvPreviewer.propTypes = {
    title: PropTypes.string,
    getPicture: PropTypes.object,
    product: PropTypes.object,
    prdno: PropTypes.string,
}

export default withGetPicture(PdvPreviewer)
