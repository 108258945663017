import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function PersonIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 512.000000 512.000000">
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="inherit"
                stroke="none"
            >
                <path d="M2330 5106 c-537 -78 -923 -395 -1089 -894 -73 -220 -94 -406 -89 -817 l3 -300 33 -67 c39 -80 113 -150 183 -175 42 -15 49 -22 49 -44 0 -15 7 -66 15 -115 59 -345 270 -645 568 -809 61 -34 67 -40 67 -69 0 -92 -66 -218 -143 -273 -69 -50 -114 -64 -241 -74 -226 -17 -371 -72 -535 -202 -128 -102 -246 -288 -293 -462 -19 -72 -22 -112 -26 -412 l-4 -333 30 -30 c36 -35 78 -39 113 -11 l24 19 6 334 c7 372 10 394 79 533 72 146 173 246 325 320 98 48 209 75 304 75 122 0 241 42 339 120 l51 41 93 -46 c121 -59 227 -85 348 -87 141 -1 199 9 232 42 36 36 37 82 4 115 -24 24 -26 24 -163 18 -110 -4 -151 -2 -203 11 -75 19 -210 82 -210 98 0 6 7 34 15 62 8 28 17 64 20 80 5 23 10 27 28 22 162 -45 413 -46 580 -3 195 52 376 156 522 302 145 146 249 324 302 522 l27 98 4 472 c4 527 3 534 -64 671 -43 88 -137 192 -217 240 -30 18 -88 45 -129 60 l-73 27 -655 0 -655 0 -73 -27 c-188 -68 -318 -200 -388 -393 -16 -43 -19 -94 -24 -390 l-5 -340 -37 36 c-58 56 -61 82 -55 444 4 263 9 341 26 436 53 302 165 526 352 703 179 171 403 272 674 305 220 27 530 -8 740 -82 l40 -14 -46 -22 c-62 -29 -82 -72 -58 -125 15 -33 45 -43 164 -56 313 -35 556 -159 715 -364 l43 -57 -30 -42 c-42 -58 -76 -129 -104 -212 -22 -67 -23 -84 -27 -447 l-3 -378 25 -25 c37 -37 86 -34 120 6 l26 31 0 333 c0 204 4 355 11 387 15 74 70 183 123 248 61 73 61 102 0 193 -131 196 -363 368 -589 438 -59 19 -74 27 -69 39 16 36 15 71 -1 96 -39 59 -345 167 -595 209 -132 22 -410 28 -530 11z m864 -1121 c153 -32 269 -141 317 -296 17 -55 19 -97 19 -474 0 -443 -6 -510 -53 -650 -35 -102 -78 -182 -148 -275 -138 -183 -347 -314 -577 -362 -126 -26 -317 -21 -437 12 -283 77 -512 269 -636 535 -79 169 -82 193 -86 698 -4 393 -2 455 12 505 46 158 165 274 317 307 95 20 1174 21 1272 0z" />
                <path d="M2977 1660 c-57 -45 -37 -110 63 -210 94 -94 232 -150 371 -150 95 0 206 -27 304 -75 149 -73 255 -176 325 -320 69 -139 72 -161 79 -533 l6 -334 24 -19 c32 -26 74 -24 108 5 l28 24 -1 339 c0 388 -5 420 -89 590 -146 297 -455 492 -777 493 -76 0 -166 30 -228 75 -27 20 -50 40 -50 44 0 4 -17 26 -38 49 -45 48 -83 55 -125 22z" />
                <path d="M3130 427 c-53 -27 -62 -96 -18 -137 20 -18 35 -20 185 -20 149 0 165 2 183 20 43 43 34 110 -20 137 -34 17 -296 17 -330 0z" />
            </g>
        </SvgIcon>
    )
}
