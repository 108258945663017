import Typography from '@material-ui/core/Typography'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import keycodes from '@nerus/framework/common/Keycodes'
import StyledButton from '@nerus/framework/styled/Button'
import StyledToggle from '@nerus/framework/styled/Toggle'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import React from 'react'

import { formatTooltip } from '../../Business/Keyboard'
import { sendBuffer, updateWsData } from '../../Eac/EacActions'

export default class BaseListaSelecao extends EnhancedComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        id: PropTypes.number.isRequired,
        data: PropTypes.object.isRequired,
        ws: PropTypes.object.isRequired,
        active: PropTypes.bool.isRequired,
    }

    state = { selectedOption: null, oldSelectedOption: null }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({
                selectedOption:
                    this.state.selectedOption || this.props.data?.option,
            })

            if (this.props.active) {
                this.componentDidMount()
            } else {
                this.componentWillUnmount()
            }
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onKeydown)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeydown)
    }

    updateList = item => () => {
        const {
            id,
            data: { ws },
        } = this.props
        const { selectedOption: option } = this.state

        const value = [...ws.data.value]

        if (item) value[item.cnt] = !value[item.cnt] === true ? 1 : 0

        this.props.dispatch(updateWsData(id, { value, option }))
    }

    onDivClick = item => () => {
        /*
        const { selectedOption } = this.state
        if (item?.cnt == selectedOption) {
            this.setState({
                selectedOption: null,
                oldSelectedOption: selectedOption,
            })
        } else {
            */
        this.setState({ selectedOption: item.cnt, oldSelectedOption: null })
        // }
    }

    onButtonClick = b => () => {
        const {
            data: { ws },
        } = this.props
        const { selectedOption: option } = this.state

        this.props.ws.send(
            sendBuffer(
                {
                    value: ws.data.value,
                    option,
                    key: b.key,
                },
                'ListaSelecaoRetorno'
            )
        )
    }

    onKeydown = event => {
        event.stopPropagation()
        event.stopImmediatePropagation()

        const { selectedOption } = this.state
        const {
            data: { ws = {}, data },
        } = this.props

        const updateListWithoutItem = this.updateList()

        if (event.keyCode === keycodes.DOWN_ARROW_KEY) {
            if (selectedOption + 1 < ws.data.value.length) {
                this.setState({
                    selectedOption: selectedOption + 1,
                })

                updateListWithoutItem()
            }
        } else if (event.keyCode === keycodes.UP_ARROW_KEY) {
            // Se diferente de 0(primeira posição) e for do tipo número
            if (selectedOption !== 0 && typeof selectedOption === 'number') {
                this.setState({
                    selectedOption: selectedOption - 1,
                })

                updateListWithoutItem()
            }
        } else if (event.keyCode === keycodes.ENTER_KEY) {
            const item = data[selectedOption]
            this.updateList(item)()
        }

        /*
        else if (event.keyCode === keycodes.SPACE_KEY) {
            if (isNaN(selectedOption) || isNull(selectedOption)) {
                this.setState({
                    selectedOption: oldSelectedOption,
                    oldSelectedOption: null,
                })
            } else {
                this.setState({
                    selectedOption: null,
                    oldSelectedOption: selectedOption,
                })
            }
        }
         */
    }

    render() {
        const { selectedOption } = this.state
        const {
            data: { title = 'OPÇÃO', subtitle = 'Acesso', data, bt, ws },
            classes,
        } = this.props

        const {
            data: { value: values },
        } = ws

        let column = []
        let columns = []

        let cnt = 0
        let itensPerColumn = 0

        const filteredList = data.filter(val => {
            const { title } = val
            val.cnt = cnt
            cnt++
            return !(title[0] === '_')
        })

        if (cnt % 2 === 0 && filteredList?.length && filteredList.length < 13) {
            itensPerColumn = filteredList.length / 2
        }

        if (cnt % 3 === 0 && filteredList?.length && filteredList.length > 12) {
            itensPerColumn = filteredList.length / 3
        }

        if (!itensPerColumn && filteredList?.length) {
            itensPerColumn = Math.round(filteredList?.length / 2)
        }

        filteredList.forEach(val => {
            column.push(val)
            if (column.length === itensPerColumn) {
                columns.push(column)
                column = []
            }
        })

        if (column.length) {
            columns.push(column)
            column = []
        }

        return (
            <div className={classes.root} ref={this.createRef('el')}>
                <div className={classes.columns}>
                    {columns.map((column, c) => {
                        return (
                            <div className={classes.column} key={`column-${c}`}>
                                <div className={classes.titles}>
                                    <div className={classes.title}>
                                        <Typography variant={'h3'}>
                                            {title
                                                .toLowerCase()
                                                .replace('coes', 'ções')}
                                        </Typography>
                                    </div>
                                    <div className={classes.titleSecondary}>
                                        <Typography variant={'h3'}>
                                            {subtitle}
                                        </Typography>
                                    </div>
                                </div>

                                <div className={classes.body}>
                                    {column.map(item => {
                                        const { enabled, title } = item

                                        if (title[0] === '_') {
                                            return null
                                        }

                                        return (
                                            <div
                                                className={clsx({
                                                    [classes.toggleWrapper]: true,
                                                    [classes.toggleDisabled]: !enabled,
                                                    [classes.selectedLabel]:
                                                        selectedOption ===
                                                        item.cnt,
                                                })}
                                                key={`field-${item.cnt}`}
                                            >
                                                <div
                                                    className={clsx({
                                                        [classes.label]: true,
                                                    })}
                                                    onClick={this.onDivClick(
                                                        item
                                                    )}
                                                    dangerouslySetInnerHTML={{
                                                        __html: title,
                                                    }}
                                                />
                                                <div className={classes.toggle}>
                                                    <StyledToggle
                                                        orientation="horizontal"
                                                        onChange={this.updateList(
                                                            item
                                                        )}
                                                        checked={Boolean(
                                                            values[item.cnt]
                                                        )}
                                                        disabled={
                                                            title[0] === '_' &&
                                                            title[1] === '_'
                                                        }
                                                        tabIndex={
                                                            this.state.index
                                                        }
                                                        size="medium"
                                                        isSelecao
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className={classes.actions}>
                    {bt.map(b => (
                        <StyledButton
                            onClick={this.onButtonClick(b)}
                            key={`${b.text}`}
                        >
                            {formatTooltip(b.text)}
                        </StyledButton>
                    ))}
                </div>
            </div>
        )
    }
}
