import PropTypes from 'prop-types'
import React from 'react'

import usePreventClickOnDoubleClick from '../../../hooks/usePreventClickOnDoubleClick'

function TrComponent(props) {
    const [onClick, onDoubleClick] = usePreventClickOnDoubleClick(
        props.onClick,
        props.onDoubleClick
    )

    return (
        <tr
            onFocus={props.onFocus}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            className={props.className}
            onDragOver={props.onDragOver}
            key={'tr-' + props.index}
        >
            {props.children}
        </tr>
    )
}

const Tr = React.memo(TrComponent)

TrComponent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onFocus: PropTypes.func,
    onDragOver: PropTypes.func,
    index: PropTypes.number,
}

export default Tr
