import ApolloClient from 'apollo-boost'
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'node-fetch'

const cache = new InMemoryCache({
    dataIdFromObject: object => {
        switch (object.__typename) {
            case 'Picture':
                return `Picture:${object.prdno ||
                    object.custno}-${object.prdseq ||
                    object.seqnoAuto}-${object.seqno ||
                    object.seqnoAuto}-${object.urlImagem || ''}`
            case 'GradePicture':
                return `GradePicture:${object.title}`
            default:
                return defaultDataIdFromObject(object)
        }
    },
})

const client = new ApolloClient({
    uri:
        typeof window !== 'undefined'
            ? `${window.location.protocol}//${window.location.host}/graphql`
            : 'http://localhost:' + process.env.PORT + '/graphql',
    fetch: fetch,
    cache,
})

export default client
