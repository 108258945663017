import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'

export const UPDATE_REPORT = gql`
    mutation UpdateReport(
        $id: Int!
        $title: String
        $info: String
        $queries: [StatementInput]
        $type: Int!
        $dashboard_id: Int!
        $size: Int
        $height: Int
        $colors: String
        $interceptions: String
        $axes: String
        $image: String
    ) {
        updateReport(
            id: $id
            title: $title
            info: $info
            queries: $queries
            type: $type
            dashboard_id: $dashboard_id
            size: $size
            height: $height
            colors: $colors
            interceptions: $interceptions
            axes: $axes
            image: $image
        ) {
            id
            title
            info
            type
        }
    }
`

export default Component => {
    return graphql(UPDATE_REPORT, { name: 'updateReport' })(Component)
}
