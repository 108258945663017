import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@nerus/framework/styled/Button'
import React, { useCallback, useRef, useState } from 'react'

const useStyles = makeStyles(() => {
    return {
        inputHidden: {
            display: 'none',
        },
        imgPreview: {
            width: '100%',
        },
    }
})

// eslint-disable-next-line react/prop-types
const NerusInputUpload = ({ name, value, onChange }) => {
    const ref = useRef()
    const classes = useStyles()
    const [file, setFile] = useState(value)

    const handleOpen = useCallback(() => {
        if (ref.current) ref.current.click()
    })

    const handleOnChange = useCallback(
        event => {
            const reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.onloadend = () => {
                setFile(reader.result)
                onChange && onChange({ [name]: reader.result })
            }
        },
        [onChange]
    )

    const url = file || null // ? `data:image/jpg;base64,${file}` :
    return (
        <div>
            <Button
                lbl="Selecionar imagem..."
                dialog
                color="warn"
                active
                onClick={handleOpen}
            />

            {url ? <img className={classes.imgPreview} src={url} /> : null}

            <input
                type="file"
                ref={ref}
                onChange={handleOnChange}
                accept={'.jpg'}
                className={classes.inputHidden}
            />
        </div>
    )
}

export default NerusInputUpload
