/**
 * "Organiza" o formulário, permitindo alterar
 * o elemento de onde começamos a realizar a inserção
 * no formulário
 */
import Form from './Form'

/**
 * "Organiza" o formulário, permitindo alterar
 * o elemento de onde começamos a realizar a inserção
 * no formulário
 */
export const organize = (data, startFrom = 0) => {
    let form = new Form(null)
    let forms = [form]

    for (let i = startFrom; i < data.length; i++) {
        const element = data[i]
        element.index = i
        const row = form.getRowForElement(element)

        if (row && form.hasTab && !row.tab) {
            form = new Form(form)
            forms.push(form)
            form.tabDepth++
            i--
            continue
        }

        form.add(element, i === data.length - 1)
    }

    forms.forEach(form => form.postProcess())

    if (forms[0].columns === 1) {
        forms.forEach(form => form.doOneColumn())
    }

    forms.forEach((form, i) => (forms[i] = form.toObject()))

    return forms
}

/**
 * Retorna o campo ativo do formulário
 * @param forms
 * @param index Int
 */
export const getActiveElement = (forms, index) => {
    let activeField = {}

    forms.forEach(form => {
        if (form.hasActiveElement) {
            Object.keys(form.rows).forEach(r => {
                let row = form.rows[r]
                if (row.hasActiveElement) {
                    row.elements.forEach(element => {
                        if (
                            (element.enabled && index === undefined) ||
                            (index !== undefined && element.index === index)
                        ) {
                            activeField = element
                            return false
                        }
                    })
                }
            })
        }
    })

    return activeField
}
