import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import makeStyles from '@material-ui/styles/makeStyles'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
    root: {
        marginRight: theme.spacing(1),
        flex: 1,
        overflow: 'auto',
        maxHeight: 530,
        [`@media (min-height:768px)`]: {
            maxHeight: 630,
        },
    },
    list: {},
    listItemActive: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    listItemText: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
    },
    noPic: {
        color: '#000',
        textAlign: 'right',
        flex: 1,
        alignItems: 'center',
        margin: '0 !important',
    },
}))

const renderItem = ({ active, onClick, classes }) => {
    const RenderItem = data => {
        let itemPrimaryDescription = data.rangeGrade2
            ? `${data.title} - ${data.rangeGrade2}`
            : data.title

        if (data.description) {
            itemPrimaryDescription += ` - ${data.description}`
        }

        return (
            <ListItem
                button
                key={data.title}
                className={clsx(classes.button, {
                    [classes.listItemActive]: active === data.title,
                })}
                onClick={onClick && onClick(data)}
            >
                <ListItemText
                    className={classes.listItemText}
                    primary={itemPrimaryDescription}
                    secondary={`${data.count === 0 ? '*' : ''}`}
                    secondaryTypographyProps={{ className: classes.noPic }}
                />
            </ListItem>
        )
    }

    RenderItem.displayName = 'RenderItem'

    return RenderItem
}

renderItem.displayName = 'GradeItem'

const gradeGenerica = {
    title: 'FOTOS GENÉRICAS',
}

function Grade({ data = [], active, onClick }) {
    const classes = useStyles()

    return (
        <List
            component="nav"
            aria-label="grades de produtos"
            className={classes.root}
        >
            <ListItem
                button
                key={gradeGenerica.title}
                className={clsx(classes.button, {
                    [classes.listItemActive]: active === gradeGenerica.title,
                })}
                onClick={onClick && onClick(gradeGenerica)}
            >
                <ListItemText primary={`FOTOS GENÉRICAS`} />
            </ListItem>

            {data.map(renderItem({ active, onClick, classes }))}
        </List>
    )
}

Grade.displayName = 'Grade'

export default Grade

Grade.propTypes = {
    data: PropTypes.array,
    active: PropTypes.string,
    onClick: PropTypes.func,
}
