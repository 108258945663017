import { makeStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { useO2 } from '@nerus/framework/hooks/useO2'
import StyledButton from '@nerus/framework/styled/Button'
import { styles } from '@nerus/styles/login'
import { styles as stylesO2 } from '@nerus/styles/loginO2'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import localStorage from '../../../../util/localStorage'
import getCurrentBin from '../../../../util/getCurrentBin'
import Slider from './Login/Slider'
import SliderO2 from './Login/SliderO2'

export function AlreadyConnected() {
    const { isO2 } = useO2(true)
    const bin = getCurrentBin()

    const getSession = session_id => {
        return localStorage.get(session_id, null, true) || null
    }

    const updateSession = (session_id, callback) => {
        if (!callback) {
            throw Error(
                'Você deve passar uma função para alterar valores da sessão'
            )
        }

        let storedSession = localStorage.get(session_id, null)
        if (storedSession) {
            storedSession = {
                ...storedSession,
                ...callback(storedSession),
                lastUse: new Date().toISOString(),
            }
            localStorage.set(session_id, storedSession)
        }
        return storedSession
    }

    const reconnectToCurrentSession = useCallback(() => {
        const storedSessions = localStorage.getKeys().map(sessId => {
            return getSession(sessId)
        })

        const sessionToReconnect = storedSessions.filter(
            session =>
                session &&
                session.type === 'reconnect' &&
                session.in_use === true &&
                session.bin === bin
        )

        if (sessionToReconnect.length === 1) {
            updateSession(sessionToReconnect[0]?.session_id, () => ({
                in_use: false,
                type: 'reconnect',
            }))
        }

        window.location.reload(false)
    }, [bin])

    const getConnectionStatus = useCallback(() => {
        const storedSessions = localStorage.getKeys().map(sessId => {
            return localStorage.get(sessId, null, true) || null
        })

        const timeFromDisconnect = 12 * 60 * 60 * 1000 // milliseconds
        const timeNow = new Date()

        return storedSessions
            .filter(
                session =>
                    session &&
                    session.type === 'reconnect' &&
                    session.in_use === true &&
                    session.bin === bin
            )
            .map(session => {
                const lastUseTime = session.lastUse
                    ? new Date(session.lastUse)
                    : null

                if (
                    lastUseTime &&
                    timeNow - timeFromDisconnect >= lastUseTime
                ) {
                    return 'connectionExpired'
                } else return 'alreadyConnected'
            })
    }, [bin])

    const connectionStatus = getConnectionStatus() || ['alreadyConnected']

    const classes = makeStyles(isO2 ? stylesO2 : styles)()

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.fontConthrax]: true,
            })}
        >
            <div
                className={clsx({
                    [classes.content]: true,
                })}
            >
                {!isO2 ? <Slider /> : <SliderO2 classes={classes} />}
                <div className={classes.formWrapper}>
                    <div className={classes.callingWrapper}>
                        <h2 className={classes.caller}>Ops!</h2>
                        {connectionStatus[0] === 'alreadyConnected' && (
                            <h3 className={classes.welcomeText}>
                                Você já possui uma sessão iniciada para este
                                binário,
                                <br /> verifique suas janelas abertas ou inicie
                                uma nova sessão em aba anônima.
                            </h3>
                        )}
                        {connectionStatus[0] === 'connectionExpired' && (
                            <h3 className={classes.welcomeText}>
                                Você possui uma sessão expirada para este
                                binário,
                                <br /> deseja se reconectar?
                            </h3>
                        )}

                        <StyledButton
                            secondary
                            size={'medium'}
                            lbl={'Reconectar'}
                            className={classes.submit}
                            onClick={reconnectToCurrentSession}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

AlreadyConnected.propTypes = {
    classes: PropTypes.object,
}

export default withStyles(styles)(AlreadyConnected)
