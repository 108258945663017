import { TextFieldShort } from './Short'

/**
 * Componente TextFieldDouble
 *
 * Rec: REC_DOUBLE
 *
 * Renderiza um campo de texto que tem formatação de casas
 * decimais
 */
export class TextFieldNegativableShort extends TextFieldShort {
    allowNegative = true
    fixedDecimalScale = false
    useFormat = true
}

export default TextFieldNegativableShort
