import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { cloneDeep } from 'lodash/lang'
import { useCallback, useMemo } from 'react'

import { DASHBOARDS_QUERY } from './useFetchDashboards'

export const SAVE_DASHBOARD_MUTATION = `
    mutation SaveDashboard($dashboard: DashboardInput!) {
        saveDashboard(dashboard: $dashboard) {
            id
            title
            library
            refresh
            refresh_measure_type
            users {
                no
                name
            }
            groups {
                no
                name
            }
            menu {
                id
                title
            }
            components {
                id
                height
                size
                order
                title
                info
                type
                colors
                axes
                image
                interceptions
                queries {
                    id
                    query
                }
            }
            filters {
                id
                key
                type
                desc
                default
                prec
                keepprec
            }
        }
    }
`

export default function useSaveDashboard(dashboard, options) {
    const [save] = useMutation(gql(SAVE_DASHBOARD_MUTATION), {
        displayName: 'saveDashboard',
        refetchQueries: () => [{ query: DASHBOARDS_QUERY }],
        ...options,
    })

    const useDashboard = useMemo(() => {
        const returnDashboard = cloneDeep(dashboard)
        const isLibraryMode = returnDashboard.library === 1

        delete returnDashboard.__typename

        if (returnDashboard.menu) {
            delete returnDashboard.menu.__typename
        }

        if (isLibraryMode) {
            delete returnDashboard.library.__typename
            delete returnDashboard.id
            returnDashboard.library = 0
        }

        returnDashboard.users = returnDashboard.users.map(user => ({
            no: user.no,
        }))
        returnDashboard.groups = returnDashboard.groups.map(group => ({
            no: group.no,
        }))
        returnDashboard.filters = (returnDashboard.filters || []).map(
            filter => {
                delete filter.__typename
                filter.id =
                    String(filter.id).indexOf('-') === -1 &&
                    parseInt(filter.id) > 0 &&
                    !isLibraryMode
                        ? filter.id
                        : null
                filter.type =
                    typeof filter.type === 'object'
                        ? filter.type.type
                        : filter.type
                filter.prec = filter.prec ? Number(filter.prec) : 0
                filter.keepprec = filter.keepprec ? 1 : 0
                return filter
            }
        )
        returnDashboard.components = returnDashboard.components.map(
            (component, order) => {
                const newComp = {
                    ...component,
                    id:
                        String(component.id).indexOf('-') === -1 &&
                        parseInt(component.id) > 0 &&
                        !isLibraryMode
                            ? component.id
                            : null,
                    report_id: !isLibraryMode ? component.report_id : null,
                    height: parseInt(component.height),
                    size: parseInt(component.size),
                    queries: component.queries.map(query => {
                        delete query.__typename

                        if (isLibraryMode) delete query.id

                        return query
                    }),
                    axes: component.axes
                        ? JSON.stringify(component.axes)
                        : null,
                    colors: component.colors
                        ? JSON.stringify(component.colors)
                        : null,
                    custom: component.custom
                        ? JSON.stringify(component.custom)
                        : null,
                    order: component.order || order,
                    interceptions: component.interceptions
                        ? JSON.stringify(component.interceptions)
                        : null,
                }

                delete newComp.__typename
                delete newComp.dashboard_id

                return newComp
            }
        )

        return returnDashboard
    }, [dashboard])

    const saveDashboard = useCallback(() => {
        return save({
            variables: {
                dashboard: useDashboard,
            },
        })
    }, [save, useDashboard])

    return {
        saveDashboard,
    }
}
