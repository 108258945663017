import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function BlankDocumentIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 90 90">
            <g>
                <path d="M53.4375 6.1875L72.5625 25.875L73.125 28.125V39.375H67.5V33.75H45V11.25H16.875V73.125H39.375V78.75H14.0625L11.25 75.9375V8.4375L14.0625 5.625H51.75L53.4375 6.1875ZM50.625 11.25V28.125H66.9375L50.625 11.25ZM73.125 90H67.5V73.125H50.625V67.5H67.5V50.625H73.125V67.5H90V73.125H73.125V90Z" />
            </g>
        </SvgIcon>
    )
}
