/* eslint-disable react/jsx-no-bind */
import { useQuery } from '@apollo/react-hooks'
import {
    Box,
    Checkbox,
    IconButton,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import keycodes from '@nerus/framework/common/Keycodes'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import { useO2 } from '@nerus/framework/hooks/useO2'
import Button from '@nerus/framework/styled/Button'
import { Input } from '@nerus/framework/styled/Input'
import StyledSelect from '@nerus/framework/styled/Select'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isAdmin } from '../../App/AppActions'
import { GET_ME } from '../../NerusWeb/Components/TopBar/UserMenu'
import { getNerusInfo } from '../../NerusWeb/Eac/EacReducer'
import NerusITLayout from '../components/common/Layout'
import RemoveRecord from '../Dashboard/Dialogs/RemoveRecord'
import { enoticeStatusLog } from './constants'
import useFetchEnotices from './hooks/useFetchEnotices'
import useFetchEnoticesLog from './hooks/useFetchEnoticesLog'
import useRemoveEnoticeLog from './hooks/useRemoveEnoticeLog'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
    },
    container: {
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    filterActions: {
        display: 'flex',
    },
    inputs: {
        width: '70%',
        display: 'flex',
        gap: 8,
        alignItems: 'end',
    },
    selects: {
        '&:after': {
            transform: 'initial',
        },
    },
    buttons: {
        display: 'flex',
        width: '30%',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    table: {
        display: 'flex',
        marginTop: 16,
    },
    header: {
        border: 'none',
        borderBottom: '2px solid',
        borderBottomColor: '#A52A2A',
    },
    colum: {
        textAlign: 'left',
        fontWeight: 'bold',
    },
    columTitle: {
        maxWidth: 100,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    columSend: {
        maxWidth: 50,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    columStatus: {
        maxWidth: 300,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    iconTrash: {
        cursor: 'pointer',
    },
    disabledColorDanger: {
        color: '#ffffff',
        cursor: 'not-allowed',
        opacity: 0.5,
        backgroundColor: 'rgb(183, 84, 84)',
    },
}))

/**
 * Componente EnoticeLog
 */

function applyFilter(filter, data) {
    let dataFilter = data

    if (
        filter.logno === '' &&
        filter.dateJob === '' &&
        filter.status === 0 &&
        filter.enotno === ''
    )
        return data
    if (filter.logno) {
        dataFilter = dataFilter.filter(row => row.logno == filter.logno)
    }
    if (filter.enotno) {
        dataFilter = dataFilter.filter(row => row.enotno == filter.enotno)
    }
    if (filter.status) {
        dataFilter = dataFilter.filter(row => row.status == filter.status)
    }
    if (filter.dateJob !== '') {
        //Formatação  Input para DD/MM/YYYY
        const dateJobFilterFormatted = filter.dateJob
            .split('-')
            .reverse()
            .join('/')

        dataFilter = dataFilter.filter(row => {
            // pega o timestamp  e formata em data no formato DD/MM/YYYY
            const dateJobFormatted = new Date(Number(row.dateJob))
                .toLocaleDateString('pt-BR')
                .split('-')
                .reverse()
                .join('/')

            return dateJobFormatted == dateJobFilterFormatted
        })
    }

    return dataFilter
}

function EnoticeLog({ history, match: { params } }) {
    const { enotno = '' } = params

    const classes = useStyles()
    const dispatch = useDispatch()

    const { loadingEnotices, data: dataEnotices } = useFetchEnotices()
    const { loading, data } = useFetchEnoticesLog()

    const nerusInfo = useSelector(getNerusInfo)
    const admin = useSelector(state => state.app.isAdmin)

    const { data: { me = null } = {} } = useQuery(GET_ME, {
        variables: { usrno: nerusInfo?.usrno },
        skip: !nerusInfo || !nerusInfo.usrno,
    })

    useEffect(() => {
        if (me?.isAdmin) {
            dispatch(isAdmin())
        }
    }, [me, dispatch])

    const [filter, setFilter] = useState({
        logno: '',
        dateJob: '',
        status: 0,
        enotno,
    })
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(8)

    const { isO2 } = useO2(true)

    const {
        showRemoveDialog,
        onShowRemoveDialog,
        onRemoveEnoticeLog,
        onCloseRemove,
    } = useRemoveEnoticeLog()

    useKeyboardTrap(
        event => {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                if (showRemoveDialog) {
                    onCloseRemove()
                } else {
                    history.push(isO2 ? '/bin/o2' : '/enotice')
                }
            }
        },
        [history, onCloseRemove, showRemoveDialog]
    )

    const handleChange = useCallback(
        (_event, page) => {
            setPage(page)
        },
        [setPage]
    )
    const handlePageSize = useCallback(event => {
        setPageSize(event.target.value)
    }, [])

    const labelDisplay = useCallback(
        ({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`,
        []
    )

    const enoticesLogs = useMemo(() => {
        return applyFilter(filter, data?.allEnoticeLogs?.data || [])
    }, [filter, data])

    const enoticeStatusLogSelect = useMemo(
        () =>
            enoticeStatusLog.map((object, i) => {
                return (
                    <MenuItem
                        key={i}
                        value={i}
                        style={{ paddingTop: 0 }}
                        data-testid={`optionStatus-${i}`}
                    >
                        {
                            // TODO: Criar componente de highlight
                            <span
                                className={clsx('highlight')}
                                // TODO: Criar styles via withStyles
                                style={{
                                    minHeight: 'unset',
                                    lineHeight: 'inherit',
                                    marginTop: -4,
                                    marginRight: 8,
                                }}
                            >
                                {object}
                            </span>
                        }
                    </MenuItem>
                )
            }),
        []
    )

    const enotices = useMemo(() => {
        return dataEnotices?.allEnotice?.data || []
    }, [dataEnotices])

    const handleChangeFilter = useCallback(
        event => {
            const value = event.target.value
            const inputId = event.target.id
            const input =
                inputId == 0 ? 'logno' : inputId == 1 ? 'dateJob' : 'status'

            setFilter({ ...filter, [input]: value })
        },
        [filter]
    )

    const [checkedBoxes, setCheckedBoxes] = useState([])
    const [toggleAllCheckboxes, setToggleAllCheckboxes] = useState(false)
    const [pageLogNumbers, setPageLogNumbers] = useState([])

    const handleChangeCheckbox = useCallback(
        logno => {
            checkedBoxes.find(element => element === logno)
                ? setCheckedBoxes(current =>
                      current.filter(element => element !== logno)
                  )
                : setCheckedBoxes(current => [...current, logno])
        },
        [checkedBoxes, setCheckedBoxes]
    )

    const handleChangeAllCheckboxes = useCallback(() => {
        toggleAllCheckboxes
            ? setCheckedBoxes([])
            : setCheckedBoxes(pageLogNumbers)
        setToggleAllCheckboxes(current => !current)
    }, [
        pageLogNumbers,
        toggleAllCheckboxes,
        setCheckedBoxes,
        setToggleAllCheckboxes,
    ])

    const clearStates = useCallback(() => {
        setToggleAllCheckboxes(false)
        setCheckedBoxes([])
    }, [setToggleAllCheckboxes, setCheckedBoxes])

    useEffect(() => {
        const startIndex = page * pageSize
        const endIndex = Math.min(startIndex + pageSize, enoticesLogs.length)
        const pageEnoticeLogs = enoticesLogs.slice(startIndex, endIndex)
        const pageLogNumbers = pageEnoticeLogs.map(enoticeLog => {
            return enoticeLog.logno
        })

        setPageLogNumbers(pageLogNumbers)
        clearStates()
    }, [page, pageSize, enoticesLogs, clearStates])

    const enoticesListLog = useMemo(() => {
        const startIndex = page * pageSize
        const endIndex = Math.min(startIndex + pageSize, enoticesLogs.length)
        return enoticesLogs.slice(startIndex, endIndex).map(enoticeLog => {
            const [date, time] = new Date(Number(enoticeLog.dateJob))
                .toISOString()
                .replace(/.000Z/gim, '')
                .split('T')

            const formatedTime = `${date
                .split('-')
                .reverse()
                .join('/')} ${time}`

            const enotice =
                enotices.find(({ enotno }) => enotno == enoticeLog.enotno) || {}

            return (
                <TableRow
                    key={enoticeLog.logno}
                    data-testid={`logno-${enoticeLog.logno}`}
                >
                    <TableCell className={classes.colum}>
                        <Checkbox
                            checked={
                                checkedBoxes.find(
                                    element => element === enoticeLog.logno
                                )
                                    ? true
                                    : false
                            }
                            onChange={() =>
                                handleChangeCheckbox(enoticeLog.logno)
                            }
                        />
                    </TableCell>
                    <TableCell>{formatedTime}</TableCell>
                    <TableCell>{enoticeLog.logno}</TableCell>
                    <TableCell className={classes.columTitle}>
                        {enotice.title}
                    </TableCell>
                    <TableCell className={classes.columSend}>
                        {enotice?.send}
                    </TableCell>
                    <TableCell>{enotice?.subject}</TableCell>
                    <TableCell className={classes.columStatus}>
                        {enoticeStatusLog[enoticeLog.status]}
                    </TableCell>
                    <TableCell>
                        <Tooltip title={'Remover'}>
                            <span>
                                <IconButton
                                    id={'delet'}
                                    data-testid={`delet-${enoticeLog.logno}`}
                                    className={classes.iconTrash}
                                    onClick={event => {
                                        event.stopPropagation()
                                        event.preventDefault()

                                        onShowRemoveDialog([enoticeLog.logno])
                                    }}
                                >
                                    <Delete />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            )
        })
    }, [
        page,
        pageSize,
        enoticesLogs,
        enotices,
        classes.colum,
        classes.columTitle,
        classes.columSend,
        classes.columStatus,
        classes.iconTrash,
        onShowRemoveDialog,
        handleChangeCheckbox,
        checkedBoxes,
    ])

    const resetFilter = useCallback(() => {
        setFilter({ logno: '', dateJob: '', status: 0, enotno })
    }, [enotno])

    return admin ? (
        <NerusITLayout>
            <div className={classes.root}>
                <Box className={classes.container}>
                    <Typography variant="h4" style={{ paddingBottom: 16 }}>
                        Logs
                    </Typography>

                    <Box className={classes.content}>
                        <Box className={classes.filterActions}>
                            <div className={classes.inputs}>
                                <Input
                                    id={'codigo'}
                                    testid="inputCodigo"
                                    value={filter.logno}
                                    index={0}
                                    disabled={false}
                                    onChange={handleChangeFilter}
                                    type="number"
                                    componentId={0}
                                    isTitle={false}
                                    attrib={12}
                                    label={'Código'}
                                    orientation={'horizontal'}
                                    InputProps={{
                                        id: '0',
                                        name: 'inputCodigo',
                                    }}
                                    InputLabelProps={{}}
                                ></Input>

                                <Input
                                    id={'data'}
                                    testid="inputDate"
                                    index={1}
                                    disabled={false}
                                    onChange={handleChangeFilter}
                                    type="date"
                                    value={filter.dateJob}
                                    componentId={1}
                                    isTitle={false}
                                    attrib={12}
                                    label={'Data'}
                                    orientation={'horizontal'}
                                    InputProps={{ id: '1', name: 'inputDate' }}
                                    InputLabelProps={{}}
                                />

                                <StyledSelect
                                    testid="inputStatus"
                                    className={classes.selects}
                                    InputLabelProps={{
                                        style: {
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            paddingLeft: '20px',
                                        },
                                    }}
                                    InputProps={{
                                        id: '2',
                                        name: 'inputStatus',
                                        style: {
                                            minWidth: 240,
                                        },
                                    }}
                                    MenuProps={{
                                        disableAutoFocus: true,
                                        disableRestoreFocus: true,
                                    }}
                                    label={'Status'}
                                    disabled={false}
                                    value={filter.status}
                                    index={2}
                                    componentId={2}
                                    orientation={'horizontal'}
                                    onChange={handleChangeFilter}
                                    dispatchToWs={false}
                                >
                                    {enoticeStatusLogSelect}
                                </StyledSelect>
                            </div>
                            <div className={classes.buttons}>
                                <Button
                                    dialog
                                    lbl={'Remover Selecionados'}
                                    color="danger"
                                    onClick={event => {
                                        event.stopPropagation()
                                        event.preventDefault()

                                        if (checkedBoxes.length)
                                            onShowRemoveDialog(checkedBoxes)
                                    }}
                                    className={clsx({
                                        [classes.disabledColorDanger]: !checkedBoxes.length,
                                    })}
                                ></Button>{' '}
                                <Button
                                    dialog
                                    lbl={'Listar Todos'}
                                    color="danger"
                                    onClick={() => {
                                        resetFilter()
                                    }}
                                ></Button>{' '}
                                <Button
                                    data-testid="Retornar"
                                    dialog
                                    color="danger"
                                    lbl={'Retornar'}
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                ></Button>{' '}
                            </div>
                        </Box>

                        <div className={classes.table}>
                            <TableContainer component={Paper}>
                                <Table aria-label="table">
                                    <TableHead className={classes.header}>
                                        <TableRow className={classes.colum}>
                                            <TableCell
                                                width="50px"
                                                className={classes.colum}
                                            >
                                                <Checkbox
                                                    checked={
                                                        toggleAllCheckboxes
                                                    }
                                                    onChange={() => {
                                                        handleChangeAllCheckboxes()
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                width="200px"
                                                className={classes.colum}
                                            >
                                                Data/Hora
                                            </TableCell>
                                            <TableCell
                                                width="150px"
                                                className={classes.colum}
                                            >
                                                Código
                                            </TableCell>
                                            <TableCell
                                                width="300px"
                                                className={classes.colum}
                                            >
                                                Título
                                            </TableCell>
                                            <TableCell
                                                width="380px"
                                                className={classes.colum}
                                            >
                                                Para
                                            </TableCell>
                                            <TableCell
                                                width="400px"
                                                className={classes.colum}
                                            >
                                                Assunto
                                            </TableCell>
                                            <TableCell
                                                width="300px"
                                                className={classes.colum}
                                            >
                                                Status
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.rows}>
                                        {loading || loadingEnotices ? (
                                            <TableRow>
                                                <TableCell width="200px"></TableCell>
                                                <TableCell width="150px"></TableCell>
                                                <TableCell width="300px">
                                                    Carregando...
                                                </TableCell>
                                                <TableCell width="380px"></TableCell>
                                                <TableCell width="400px"></TableCell>
                                                <TableCell width="300px"></TableCell>
                                            </TableRow>
                                        ) : enoticesLogs.length &&
                                          enotices.length ? (
                                            enoticesListLog
                                        ) : (
                                            <TableRow>
                                                <TableCell width="200px"></TableCell>
                                                <TableCell width="150px"></TableCell>
                                                <TableCell width="300px">
                                                    Nenhum Log encontrado
                                                </TableCell>
                                                <TableCell width="380px"></TableCell>
                                                <TableCell width="400px"></TableCell>
                                                <TableCell width="300px"></TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>

                                <TablePagination
                                    component="div"
                                    count={enoticesLogs.length}
                                    onPageChange={handleChange}
                                    page={page}
                                    rowsPerPageOptions={[8, 12, 16, 24, 32, 40]}
                                    rowsPerPage={pageSize}
                                    labelRowsPerPage="Registros por página:"
                                    nextIconButtonText="Próxima Página"
                                    backIconButtonText="Página Anterior"
                                    labelDisplayedRows={labelDisplay}
                                    onRowsPerPageChange={handlePageSize}
                                />
                            </TableContainer>
                        </div>

                        {showRemoveDialog ? (
                            checkedBoxes.length > 0 ? (
                                <RemoveRecord
                                    onSubmit={onRemoveEnoticeLog}
                                    onClose={onCloseRemove}
                                    message="Deseja realmente retirar estes registros?"
                                />
                            ) : (
                                <RemoveRecord
                                    onSubmit={onRemoveEnoticeLog}
                                    onClose={onCloseRemove}
                                />
                            )
                        ) : null}
                    </Box>
                </Box>
            </div>
        </NerusITLayout>
    ) : (
        <>Sem permissão</>
    )
}
EnoticeLog.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        goBack: PropTypes.func,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            enotno: PropTypes.string,
        }),
    }),
}

export default EnoticeLog
