import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'

export const GET_REPORTS = gql`
    query GetDashboardReport($id: Int) {
        getDashboardReport(id: $id) {
            data
        }
    }
`

export default Component => {
    return graphql(GET_REPORTS, {
        name: 'getDashboardReport',
        options: props => {
            return {
                fetchPolicy: 'network-only',
                variables: {
                    id: parseInt(props.configReportId),
                },
            }
        },
        skip: props => {
            return !props.configReportId
        },
    })(Component)
}
