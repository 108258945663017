import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/listaSelecao'
import { connect } from 'react-redux'

import { withWS } from '../../Business/Websocket/Context'
import { getComponentById, isActiveComponent } from '../../Eac/EacReducer'
import BaseListaSelecao from './BaseListaSelecao'

export class ListaSelecao extends BaseListaSelecao {
    static baseOpts = {
        title: 'Informe a Opção',
        size: 'medium',
    }
}

function mapStateToProps(state, ownProps) {
    const active = isActiveComponent(ownProps?.id, state)

    return {
        active,
        isLocked: state.app.isLocked,
        session_id: state.eac?.session?.session_id || null,
        data: getComponentById(ownProps.id, state).payload,
    }
}

export default connect(mapStateToProps)(
    withStyles(styles)(withWS(ListaSelecao))
)
