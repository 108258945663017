import { Tooltip } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useActiveFieldNavigation from '@nerus/framework/hooks/useActiveFieldNavigation'
import { styles } from '@nerus/styles/components/button'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { formatTooltip } from '../../modules/NerusWeb/Business/Keyboard'

const useStyles = makeStyles(styles)

function Button(
    {
        lbl,
        children,
        active,
        tab,
        primary,
        secondary,
        dialog,
        isFocused,
        size = 'default',
        variant = 'default',
        border = 'default',
        color = 'none',
        tooltip = '',
        useNavigationKeyboard = true,
        ...props
    },
    ref
) {
    const classes = useStyles()
    const sizeClass = size
        ? `size${size[0].toUpperCase() + size.substr(1).toLowerCase()}`
        : false
    const variantClass = variant
        ? `variant${variant[0].toUpperCase() + variant.substr(1).toLowerCase()}`
        : false
    const colorClass = color
        ? `color${color[0].toUpperCase() + color.substr(1).toLowerCase()}`
        : false
    const disabledColorClass = color
        ? `disabledColor${color[0].toUpperCase() +
              color.substr(1).toLowerCase()}`
        : false
    const borderClass = border
        ? `border${border[0].toUpperCase() + border.substr(1).toLowerCase()}`
        : false

    useActiveFieldNavigation(ref, props, useNavigationKeyboard)

    let btn = (
        <MuiButton
            ref={ref}
            classes={{
                root: clsx({
                    [classes.root]: true,
                    [classes.active]: active && tab !== 'new',
                    [classes.tab]: tab === true,
                    [classes.tabNew]: tab === 'new',
                    [classes.tabNewActive]: tab === 'new' && active,
                    [classes.dialog]: dialog || primary || secondary,
                    [classes.dialogHover]: dialog && !primary && isFocused,
                    [classes.disabled]: props.disabled,
                    [classes.primary]: primary,
                    [classes.primaryHover]: primary && isFocused,
                    [classes.disabledPrimary]: primary && props.disabled,
                    [classes.secondary]: secondary,
                    [classes.secondaryHover]: secondary && isFocused,
                    [classes[borderClass]]: borderClass && classes[borderClass],
                    [classes[sizeClass]]: sizeClass && classes[sizeClass],
                    [classes[variantClass]]:
                        variantClass && classes[variantClass],
                    [classes[colorClass]]: colorClass && classes[colorClass],
                    [classes[disabledColorClass]]:
                        props.disabled && disabledColorClass && !tab,
                }),
                focusVisible: clsx({
                    [classes.primaryHover]: primary,
                    [classes.secondaryHover]: secondary,
                    [classes.dialogHover]: dialog && !primary && !secondary,
                }),
            }}
            disableFocusRipple
            {...props}
        >
            {lbl || children}
        </MuiButton>
    )

    const tooltipProps = {
        title: tooltip ? formatTooltip(tooltip) : null,
        placement: 'bottom',
    }

    if (tooltip?.length && !props.disabled) {
        const span = <span>{btn}</span>
        return (
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                {...tooltipProps}
            >
                {span}
            </Tooltip>
        )
    }

    return btn
}

export default React.forwardRef(Button)

Button.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    isFocused: PropTypes.bool,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    dialog: PropTypes.bool,
    active: PropTypes.bool,
    size: PropTypes.string,
    lbl: PropTypes.string,
    tab: PropTypes.bool,
    border: PropTypes.oneOf(['default', 'none']),
    tooltip: PropTypes.string,
    useNavigationKeyboard: PropTypes.bool,
    variant: PropTypes.oneOf(['default', 'rounded', 'roundedTab']),
    color: PropTypes.oneOf(['none', 'default', 'info', 'success']),
}
