import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function CoinsIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0,19.212)">
                <path d="m 993,495 q 7,-19 7,-35 0,-67 -82.5,-114 Q 835,299 719,299 q -95,0 -166,31 -31,36 -90,63 -25,31 -25,67 0,20 7,35 21,-54 97.5,-89.5 Q 619,370 719,370 818,370 894.5,405.5 971,441 993,495 Z m 0,-102 q 7,-21 7,-36 0,-67 -82.5,-114 Q 835,196 719,196 q -77,0 -141,22 5,18 5,34 0,20 -7,37 66,-22 143,-22 99,0 175.5,35.5 Q 971,338 993,393 Z m 0,-103 q 7,-19 7,-35 0,-67 -82.5,-114 Q 835,94 719,94 q -77,0 -143,22 7,18 7,41 0,16 -4,29 66,-21 140,-21 99,0 175.5,35.5 Q 971,236 993,290 Z m 0,-102 q 7,-21 7,-36 Q 1000,85 917.5,38 835,-9 719,-9 q -79,0 -143,23 7,18 7,40 0,17 -4,29 66,-21 140,-21 99,0 175.5,35.5 Q 971,133 993,188 Z M 280,67 q 99,0 176,35.5 77,35.5 98,89.5 7,-15 7,-35 Q 561,90 479,43 397,-4 280,-4 164,-4 82,43 0,90 0,157 0,176 6,192 28,138 104.5,102.5 181,67 280,67 Z m 0,-103 Q 380,-36 456.5,-0.5 533,35 554,90 q 7,-17 7,-36 0,-67 -82,-114 -82,-47 -199,-47 -116,0 -198,47 Q 0,-13 0,54 0,72 6,90 28,35 104.5,-0.5 181,-36 280,-36 Z m 0,442 q 117,0 199,-45 82,-45 82,-109 0,-64 -82,-109 Q 397,98 280,98 164,98 82,143 0,188 0,252 q 0,64 82,109 82,45 198,45 z m 720,149 Q 1000,491 917.5,446 835,401 719,401 q -117,0 -199,45 -82,45 -82,109 0,64 82,108.5 82,44.5 199,44.5 116,0 198.5,-44.5 Q 1000,619 1000,555 Z" />
            </g>
        </SvgIcon>
    )
}
