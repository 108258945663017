import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const ENOTICELOG_QUERY = gql`
    query allEnoticeLogs {
        allEnoticeLogs {
            pages
            page
            total
            error
            message
            data {
                enotno
                logno
                send
                dateJob
                dateSend
                userno
                status
                remarks
            }
        }
    }
`

export default function useFetchEnoticesLog(options) {
    return useQuery(ENOTICELOG_QUERY, {
        displayName: 'fetchEnoticesLog',
        fetchPolicy: 'cache-and-network',
        ...options,
    })
}
