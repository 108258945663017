import { KeyboardDatePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import React from 'react'

import useAutoFocus from '../hooks/useAutoFocus'
import useClickToTrackCaret from '../hooks/useClickToTrackCaret'
import useDispatchToWS from '../hooks/useDispatchToWS'
import useOverwrite from '../hooks/useOverwrite'
import usePreventTyping from '../hooks/usePreventTyping'
import { hookStyles } from './Input'
import Label from './Label'

function StyledKeyboardDatePickerComponent(
    {
        InputProps = {},
        InputLabelProps = {},
        InputAdornmentProps = {},
        beforeSend,
        fieldType,
        inputWidth,
        labelWidth,
        componentId,
        label,
        dispatch, // eslint-disable-line
        overwrite = false,
        dispatchToWs = false,
        autoFocus = true,
        autoFocusOnBlur = true,
        ...props
    },
    ref
) {
    const { orientation = 'horizontal' } = props
    const classes = hookStyles[orientation]()

    const hookProps = {
        ...props,
        beforeSend,
        fieldType,
        componentId,
    }

    useAutoFocus(ref, hookProps, autoFocus, autoFocusOnBlur)
    useClickToTrackCaret(ref, hookProps)
    useOverwrite(ref, hookProps, overwrite)
    usePreventTyping(ref, hookProps)
    useDispatchToWS(ref, hookProps, dispatchToWs)

    return (
        <KeyboardDatePicker
            autoOk
            variant="inline"
            invalidDateMessage="Data inv&aacute;lida"
            maxDateMessage="Data inv&aacute;lida"
            minDateMessage="Data inv&aacute;lida"
            maskChar=" "
            classes={{
                root: classes.root,
            }}
            inputProps={{
                ref,
            }}
            InputProps={{
                ...InputProps,
                style: {
                    ...(InputProps.style || {}),
                    minWidth: inputWidth,
                    width: inputWidth,
                },
                classes: {
                    // root: classes.inputRoot,
                    // input: classes.input,
                    // formControl: classes.formControl,
                    root: clsx(classes.inputRoot, {
                        [classes.disabledBorderWrapper]: props.disabled,
                    }),
                    input: clsx({
                        [classes.input]: true,
                        [classes.disabled]: props.disabled,
                        [classes.enabled]: !props.disabled,
                    }),
                    inputMultiline: classes.inputMultiline,
                    formControl: clsx({
                        [classes.formControl]: true,
                    }),
                },
                disableUnderline:
                    InputProps.disableUnderline === undefined
                        ? true
                        : InputProps.disableUnderline,
            }}
            InputLabelProps={{
                ...InputLabelProps,
                style: {
                    ...(InputLabelProps.style || {}),
                    minWidth: labelWidth,
                },
                shrink: true,
                classes: {
                    root: classes.labelRoot,
                },
            }}
            InputAdornmentProps={{
                ...InputAdornmentProps,
                classes: {
                    root: classes.dateTimeAdornment,
                },
            }}
            KeyboardButtonProps={{
                className: classes.buttomDateTimeAdornment,
            }}
            {...props}
            label={label && label.trim() ? <Label text={label.trim()} /> : null}
            error={false}
            helperText={null}
        />
    )
}

export const StyledKeyboardDatePicker = React.forwardRef(
    StyledKeyboardDatePickerComponent
)

StyledKeyboardDatePickerComponent.propTypes = {
    InputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    InputAdornmentProps: PropTypes.object,
    beforeSend: PropTypes.func,
    dispatch: PropTypes.func,
    fieldType: PropTypes.object,
    inputWidth: PropTypes.number,
    labelWidth: PropTypes.number,
    componentId: PropTypes.number,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    label: PropTypes.string,
    disabled: PropTypes.bool,
    overwrite: PropTypes.bool,
    dispatchToWs: PropTypes.bool,
    autoFocus: PropTypes.bool,
    autoFocusOnBlur: PropTypes.bool,
}
