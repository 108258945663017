export const styles = theme => ({
    root: {
        backgroundColor: theme.palette.common.black,
    },
    opKeys: {},
    inputKeys: {},
    digitKeys: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap-reverse',
    },
    funcKeys: {
        display: 'flex',
    },
    display: {
        color: theme.palette.common.white,
        fontSize: '4em',
        overflow: 'hidden',
        textAlign: 'right',
        lineHeight: '100px',
    },
    keypad: {
        width: 280,
        height: 'auto',
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '10px',
    },
    scalingText: {
        lineHeight: 1,
        padding: '20px 20px 0',
        transformOrigin: 'right',
    },
    button: {
        padding: 0,
        margin: 5,
        border: 'none',
        outline: 'none',
        display: 'block',
        cursor: 'pointer',
        background: 'none',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        userSelect: 'none',
        fontFamily: 'inherit',
        width: 60,
        height: 60,
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: '2.5em',
        lineHeight: '65px',
        textAlign: 'center',
        borderRadius: '50%',
        '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    },
    margin: {
        '& + &': {
            marginTop: 10,
        },
    },
    buttonRed: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRight: 0,
    },
    buttonGray: {
        backgroundColor: '#a6a6a6',
        color: theme.palette.common.black,
        fontSize: '1.5em',
    },
    zeroKey: {
        width: 130,
        textAlign: 'left',
        paddingLeft: 32,
        borderRadius: 50,
    },
    dotKey: {
        paddingTop: '1em',
        fontSize: '0.75em',
    },
})
