import Box from '@material-ui/core/Box'
import IconButton from '@nerus/framework/components/Button/IconButton'
import PaymentIcon from '@nerus/framework/icons/Payment'
import PersonIcon from '@nerus/framework/icons/Person'
import Text from '@nerus/framework/styled/Text'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import DashboardRender from '../../../../NerusIT/Dashboard/DashboardRender'

const Header = ({
    cliName,
    cliDoc,
    cliNo,
    paymName,
    paymNo,
    ordno,
    pdvno,
    dt,
    lj,
    onClientDetailClick,
    onPaymentClick,
    classes,
    active,
    typeOrd = 'Pedido',
}) => (
    <Fragment>
        <Box className={classes.headerRoot}>
            <Box flex={1}>
                <Text bold>{typeOrd || 'Pedido'}:</Text> <Text>{ordno}</Text>
            </Box>
            <Box flex={2}>
                <DashboardRender
                    active={active}
                    asModal
                    menu={{ title: 'Ponto de Venda' }}
                />
                <Text bold>Método de Pagamento:</Text>
                <Text>
                    {paymName
                        ? `${paymName}${paymNo ? ` (${paymNo})` : ''}`
                        : ''}
                </Text>
                <IconButton
                    size={'icon'}
                    variant={paymName ? 'success' : 'warn'}
                    onClick={onPaymentClick}
                    disabled={!active}
                    tooltip={
                        paymName
                            ? 'F3 - Alterar método de pagamento'
                            : 'F3 - Informar método de pagamento'
                    }
                >
                    <PaymentIcon />
                </IconButton>
            </Box>
            <Box flex={1} className={classes.spacedRight}>
                <Text bold>Cliente:</Text>
                <Text>
                    {cliName
                        ? `${cliName}${
                              cliDoc || cliNo ? ` (${cliDoc || cliNo})` : ''
                          }`
                        : ''}
                </Text>
                <IconButton
                    size={'icon'}
                    variant={'info'}
                    onClick={onClientDetailClick}
                    tooltip={
                        cliName
                            ? 'F4 - Informações/Alterar cliente'
                            : 'F4 - Informar cliente'
                    }
                    disabled={!active}
                >
                    <PersonIcon />
                </IconButton>
            </Box>
            <Box className={classes.details}>
                <Box className={classes.noWrap}>
                    <Text size={'small'} bold>
                        Data:
                    </Text>
                    <Text size={'small'}>{dt || ''}</Text>
                </Box>
                <Box className={classes.noWrap}>
                    <Text size={'small'} bold>
                        Loja:
                    </Text>
                    <Text size={'small'}>{lj || ''}</Text>
                </Box>
                <Box>
                    <Text bold size={'small'}>
                        PDV:
                    </Text>
                    <Text size={'small'}>{pdvno || ''}</Text>
                </Box>
            </Box>
        </Box>
    </Fragment>
)

Header.propTypes = {
    cliName: PropTypes.string,
    cliDoc: PropTypes.number,
    cliNo: PropTypes.number,
    paymName: PropTypes.string,
    paymNo: PropTypes.number,
    ordno: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pdvno: PropTypes.number,
    typeOrd: PropTypes.string,
    lj: PropTypes.number,
    dt: PropTypes.string,
    classes: PropTypes.object,
    onClientDetailClick: PropTypes.func.isRequired,
    onPaymentClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
}

export default Header
