import cloneDeep from 'lodash/cloneDeep'

import { isRec } from '../../../util/rec'

/**
 * Classe para geração da estrutura do formulário
 * Mantem uma lista de elementos e define classe a ser usada
 * na estrutura do formulário
 */
export default class Row {
    form = null
    tab = false
    columnTitle = false
    isLast = false
    hasGeneralButton = false
    hasActiveElement = false
    columns = 0
    rowIndex = 0
    elements = []

    constructor(y, form) {
        this.y = y
        this.form = form
        this.rowIndex = Object.keys(form.rows)
    }

    /**
     * Retorna apenas a estrutura em forma de objeto
     */
    toObject = () => {
        return {
            tab: this.tab,
            columnTitle: this.columnTitle,
            isLast: this.isLast,
            hasGeneralButton: this.hasGeneralButton,
            hasActiveElement: this.hasActiveElement,
            columns: this.columns,
            rowIndex: this.rowIndex,
            elements: cloneDeep(this.elements),
        }
    }

    /**
     * Insere um elemento no Formulário
     */
    push = (element, isLast = false) => {
        let previousForm = this.form.relatedForm
        let previousRow = null
        if (previousForm) {
            const kRows = Object.keys(previousForm.rows)
            previousRow = previousForm.rows[kRows.pop()]
        }

        element.isLast = isLast

        /**
         * Se temos um elemento ativo, passamos isso para o Formulário
         */
        if (element.enabled) {
            this.hasActiveElement = true
            this.form.hasActiveElement = true
        }

        /**
         * Se estamos inserindo algo diferente de um botão, especificamos
         * que a linha não é uma aba e removemos a classe de uma linha especifica
         * para linha de botões (abas)
         */
        if (!isRec(element, 'REC_PROMPT')) {
            this.columns++

            // Trata aba no inicio do formulário
            if (previousRow && previousRow.tab) {
                if (!previousRow.hasGeneralButton) {
                    // clona o primeiro botão que recebemos
                    const generalBtn = JSON.parse(
                        JSON.stringify(previousRow.elements[0])
                    )
                    generalBtn.lbl = 'Geral'
                    generalBtn.enabled = false
                    generalBtn.x = element.x
                    generalBtn.y = element.y
                    generalBtn.fieldId = element.fieldId
                    generalBtn.componentId = element.componentId
                    let hasGeral = previousRow.elements.filter(
                        el => el.lbl === 'Geral'
                    )
                    if (!hasGeral?.length) {
                        previousRow.elements.push(generalBtn)
                    }

                    previousRow.hasGeneralButton = true
                    this.form.generalBtn = generalBtn // cria uma referencia para simplificar
                    this.form.generalBtn.active = !previousRow.hasActiveElement
                } else {
                    if (this.form.generalBtn && !this.form.generalBtn.active) {
                        this.form.generalBtn.active = !previousRow.hasActiveElement
                    }
                }
            }
        } else {
            if (isLast) {
                // clona o primeiro botão que recebemos
                const generalBtn = JSON.parse(JSON.stringify(element))
                generalBtn.lbl = 'Geral'
                generalBtn.enabled = false
                generalBtn.x = element.x
                generalBtn.y = element.y
                generalBtn.fieldId = element.fieldId
                generalBtn.componentId = element.componentId
                let hasGeral = this.elements.filter(el => el.lbl === 'Geral')
                if (!hasGeral?.length) {
                    this.elements.push(generalBtn)
                }

                this.hasGeneralButton = true
                this.isLast = true
                this.tab = true
            }
        }

        this.elements.push(element)
    }
}
