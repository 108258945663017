import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import config from '@nerus/config'
import keycodes from '@nerus/framework/common/Keycodes'
import IconButton from '@nerus/framework/components/Editor/Estrutura/IconButton'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import { useO2 } from '@nerus/framework/hooks/useO2'
import Button from '@nerus/framework/styled/Button'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import NerusITLayout from '../components/common/Layout'
import Actions from './Actions'
import ComponentHandler from './Actions/ComponentHandler'
import FilterConfigHandler from './Actions/FilterConfigHandler'
import FilterHandler from './Actions/FilterHandler'
import GroupsSelector from './Actions/GroupsSelector'
import MenuSelector from './Actions/MenuSelector'
import RefreshSelector from './Actions/RefreshSelector'
import UserSelector from './Actions/UserSelector'
import { defaultAxes, defaultColors } from './Components/constants'
import DroppableContent from './Components/DroppableContent'
import DashboardItem from './DashboardRender/DashboardItem'
import GenericConfirmation from './Dialogs/GenericConfirmation'
import GenericMessage from './Dialogs/GenericMessage'
import RemoveRecord from './Dialogs/RemoveRecord'
import useFetchDashboard from './hooks/useFetchDashboard'
import useSaveDashboard from './hooks/useSaveDashboard'
import { orderByOrderAndTitle } from '../../../util/orderBy'

const useStyles = makeStyles(theme => ({
    content: {
        overflowX: 'hidden',
        overflowY: 'auto',
        display: 'flex',
        height: '100%',
        flex: 1,
    },
    container: {
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
    },
    input: {
        color: theme.palette.primary.main,
        fontSize: '24px !important',
    },
    inputWrapper: {
        marginRight: 8,
        marginLeft: 8,
        flex: 1,
    },
    icon: {
        marginRight: 8,
    },
    text: {
        cursor: 'default',
    },
    separator: {
        backgroundColor: 'rgba(0,0,0,0.25)',
        margin: '0 8px',
        height: '100%',
        maxWidth: 1,
        flex: 1,
    },
    inputUnderline: {
        '&::before': {
            border: 'none !important',
        },
    },
    // modals
    list: {
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        margin: 0,
    },
    modalActions: {
        marginBottom: 8,
        marginTop: 16,
    },
}))

function DashboardEdit({ match: { params }, location: { search } }) {
    const queryParameters = new URLSearchParams(search)
    const library_id = queryParameters.get('library_id')

    const id =
        params.dashboard_id !== 'new'
            ? parseInt(params.dashboard_id)
            : library_id
            ? parseInt(library_id)
            : null

    const history = useHistory()

    const hasChanges = useRef(false)
    const [shouldRemove, setShouldRemove] = useState(0)
    const [activeFilters, setActiveFilters] = useState({})
    const [editing, setEditing] = useState(null)
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)
    const [showSaveConfirmation, setShowSaveConfirmation] = useState(false)
    const [showModal, setShowModal] = useState('')
    const { isO2 } = useO2(true)

    const user_id = useSelector(state => state.eac.NerusInfo?.usrno)

    const { loading, data } = useFetchDashboard({
        variables: { id, user_id, library: library_id ? 1 : 0 },
        skip: id == null || !user_id,
    })

    const fetchedDashboard = useMemo(() => {
        return (
            data?.findDashboard || {
                components: [],
                groups: [],
                filters: [],
                users: [],
                title: '',
                menu: {},
                library: 0,
            }
        )
    }, [data])

    const onKeyDown = useCallback(
        event => {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                event.stopImmediatePropagation()

                if (event.defaultPrevented) {
                    return
                }

                if (showModal) {
                    setShowModal('')
                } else if (shouldRemove) {
                    setShouldRemove(0)
                } else if (hasChanges.current) {
                    setShowCancelConfirmation(true)
                } else {
                    history.push(`${isO2 ? '/bin/o2' : ''}/dashboard`)
                }
            }
        },
        [
            setShowCancelConfirmation,
            setShouldRemove,
            setShowModal,
            shouldRemove,
            hasChanges,
            setEditing,
            showModal,
            editing,
            history,
        ]
    )

    useKeyboardTrap(onKeyDown)

    const [dashboard, setDashboard] = useState(fetchedDashboard)

    useEffect(() => {
        hasChanges.current = false
        setDashboard(fetchedDashboard)
    }, [fetchedDashboard])

    const { saveDashboard } = useSaveDashboard(dashboard)

    const components = useMemo(() => {
        return (dashboard?.components || [])
            .sort(orderByOrderAndTitle)
            .map((component, order) => {
                return {
                    ...(component || {}),
                    order: order,
                    axes: component?.axes ? component.axes : defaultAxes,
                    colors: component?.colors
                        ? component.colors
                        : defaultColors,
                    custom: component?.custom ? component.custom : {},
                }
            })
    }, [dashboard?.components])

    const classes = useStyles()

    const hideSave = useCallback(() => {
        setShowSaveConfirmation(false)
    }, [setShowSaveConfirmation])

    const hideModal = useCallback(() => {
        setShowModal('')
    }, [setShowModal])

    const onCloseHandler = useCallback(() => {
        setEditing(null)
    }, [setEditing])

    const onUpdateField = useCallback(
        field => value => {
            hasChanges.current = true

            if (field === 'filters') {
                setDashboard(dashboard => {
                    return {
                        ...dashboard,
                        [field]: value,
                    }
                })
                return
            }

            setDashboard(dashboard => ({
                ...dashboard,
                [field]: value,
            }))
        },
        [setDashboard]
    )

    const doCancel = useCallback(() => {
        history.push(`${isO2 ? '/bin/o2' : ''}/dashboard`)
    }, [history])

    const onCancel = useCallback(() => {
        if (hasChanges.current) {
            setShowCancelConfirmation(true)
        } else {
            doCancel()
        }
    }, [setShowCancelConfirmation, doCancel])

    const hideCancel = useCallback(() => {
        setShowCancelConfirmation(false)
    }, [setShowCancelConfirmation])

    const handleTitleChange = useCallback(
        event => {
            const value = event.target.value
            setDashboard(dashboard => ({
                ...dashboard,
                title: value,
            }))
            hasChanges.current = true
        },
        [setDashboard]
    )

    const doSave = useCallback(() => {
        saveDashboard()
            .then(() => {
                history.push(`${isO2 ? '/bin/o2' : ''}/dashboard`)
            })
            .catch(err => {
                try {
                    setShowModal(
                        err.networkError?.result?.errors
                            ? err.networkError.result.errors[0].message
                            : err.graphQLErrors[0].message
                    )
                } catch (_) {
                    setShowModal(err.message)
                }
            })
    }, [saveDashboard, history, setShowModal])

    const onSave = useCallback(() => {
        if (!dashboard.title) {
            setShowModal('A descrição é obrigatória.')
        } else if (
            !dashboard.menu ||
            (!dashboard.users?.length && !dashboard.groups?.length)
        ) {
            setShowSaveConfirmation(true)
        } else {
            doSave()
        }
    }, [setShowSaveConfirmation, doSave])

    const handleConfig = useCallback(
        item => {
            return function handleConfigInner() {
                setEditing(item)
            }
        },
        [setEditing]
    )

    const doRemove = useCallback(() => {
        const components = dashboard.components?.length
            ? dashboard.components.filter(curr => curr.id !== shouldRemove)
            : []

        setDashboard({
            ...dashboard,
            components,
        })

        hasChanges.current = true
        setShouldRemove(0)
    }, [setDashboard, dashboard, shouldRemove])

    const cancelRemove = useCallback(() => {
        return setShouldRemove(0)
    }, [setShouldRemove])

    const handleRemove = useCallback(
        item => {
            return function handleRemoveInner() {
                return setShouldRemove(item.id)
            }
        },
        [setShouldRemove]
    )

    const [titleDisabled, setTitleDisabled] = useState(id !== null)

    const handleTitleClick = useCallback(() => {
        setTitleDisabled(false)
    }, [titleDisabled, setTitleDisabled])

    const handleTitleDismissClick = useCallback(() => {
        setTitleDisabled(true)
    }, [titleDisabled, setTitleDisabled])

    if (!dashboard) {
        return null
    }

    return (
        <DndProvider
            backend={TouchBackend}
            options={{ enableMouseEvents: true }}
        >
            <NerusITLayout onBack={onCancel}>
                <Actions>
                    <ClickAwayListener onClickAway={handleTitleDismissClick}>
                        <TextField
                            autoFocus={id === null}
                            className={classes.inputWrapper}
                            disabled={Boolean(titleDisabled && dashboard.title)}
                            onClick={handleTitleClick}
                            onChange={handleTitleChange}
                            value={dashboard.title}
                            placeholder="Informe a descrição"
                            InputProps={{
                                classes: {
                                    underline: classes.inputUnderline,
                                    input: classes.input,
                                },
                            }}
                        />
                    </ClickAwayListener>

                    <FilterHandler
                        filters={dashboard.filters}
                        activeFilters={activeFilters}
                        onSave={setActiveFilters}
                    />

                    <FilterConfigHandler
                        editing={dashboard}
                        onSave={onUpdateField('filters')}
                    />

                    <MenuSelector
                        dashboard={dashboard}
                        onSave={onUpdateField('menu')}
                    />

                    <UserSelector
                        dashboard={dashboard}
                        onSave={onUpdateField('users')}
                    />

                    <GroupsSelector
                        dashboard={dashboard}
                        onSave={onUpdateField('groups')}
                    />

                    <div className={classes.separator} />

                    <RefreshSelector
                        dashboard={dashboard}
                        onSaveRefresh={onUpdateField('refresh')}
                        onSaveRefreshMeasure={onUpdateField(
                            'refresh_measure_type'
                        )}
                    />

                    {config.dashboard.export && dashboard.library ? (
                        <React.Fragment>
                            <div className={classes.separator} />
                            <a
                                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                    JSON.stringify(dashboard)
                                )}`}
                                download={`${dashboard.id}-${dashboard.title}.json`}
                            >
                                <IconButton
                                    size="small"
                                    tooltip="Exportar Dashboard"
                                />
                            </a>
                        </React.Fragment>
                    ) : null}

                    <div className={classes.separator} />

                    <Button
                        dialog
                        color="danger"
                        lbl="Cancelar"
                        onClick={onCancel}
                    />
                    <Button dialog primary lbl="Confirmar" onClick={onSave} />
                </Actions>

                <DroppableContent className={classes.content}>
                    {loading ? (
                        <Typography variant="h6">
                            Buscando registros...
                        </Typography>
                    ) : (
                        <Grid container className={classes.list} spacing={2}>
                            {components
                                ? components.map(item => (
                                      <DashboardItem
                                          key={item.id}
                                          {...item}
                                          activeFilters={dashboard.filters.reduce(
                                              (acc, cur) => ({
                                                  ...acc,
                                                  [cur.key]: {
                                                      ...cur,
                                                      value:
                                                          activeFilters?.[
                                                              cur.key
                                                          ]?.value,
                                                  },
                                              }),
                                              {}
                                          )}
                                          user_id={user_id}
                                          setDashboard={setDashboard}
                                          previewer={false}
                                          onConfig={handleConfig(item)}
                                          onRemove={handleRemove(item)}
                                      />
                                  ))
                                : ''}

                            <Grid item>
                                <ComponentHandler
                                    dashboard={dashboard}
                                    editing={editing}
                                    onClose={onCloseHandler}
                                    onSave={onUpdateField('components')}
                                />
                            </Grid>
                        </Grid>
                    )}
                </DroppableContent>

                {shouldRemove ? (
                    <RemoveRecord onSubmit={doRemove} onClose={cancelRemove} />
                ) : null}

                {showCancelConfirmation ? (
                    <GenericConfirmation
                        onSubmit={doCancel}
                        onClose={hideCancel}
                        message={
                            !dashboard.id && !dashboard.components?.length
                                ? 'Este dashboard não foi salvo, deseja realmente cancelar e remover os dados cadastrados?'
                                : 'Você poderá perder qualquer alteração realizada, confirma?'
                        }
                    />
                ) : null}
                {showSaveConfirmation ? (
                    <GenericConfirmation
                        onSubmit={doSave}
                        onClose={hideSave}
                        message={
                            dashboard.users?.length || dashboard.groups?.length
                                ? 'Você não selecionou menu, este dashboard não será exibido, confirma?'
                                : dashboard.menu?.id
                                ? 'Você não selecionou usuários ou grupos, este dashboard não será exibido, confirma?'
                                : 'Você não selecionou menu, usuários ou grupos, este dashboard não será exibido, confirma?'
                        }
                    />
                ) : null}
                {showModal ? (
                    <GenericMessage onClose={hideModal} message={showModal} />
                ) : null}
            </NerusITLayout>
        </DndProvider>
    )
}

export default DashboardEdit

DashboardEdit.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            dashboard_id: PropTypes.string.isRequired,
        }),
        query: PropTypes.shape({
            library_id: PropTypes.string,
        }),
    }),
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
}
