import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import { preprocessMessageOutput } from '@nerus/framework/common/Formatter'
import keycodes from '@nerus/framework/common/Keycodes'
import StyledButton from '@nerus/framework/components/Button'
import PropTypes from 'prop-types'
import React from 'react'

import {
    removeComponent,
    resetComponents,
    resetElementIndex,
    sendBuffer,
} from '../../Eac/EacActions'
import BaseFlutuante from '../Flutuante/BaseFlutuante'
import GenericStatusBar from '../GenericStatusBar'

const debug = require('debug')('nerus:baseDialogo')

/**
 * Base da Dialogo
 */
export default class BaseDialogo extends EnhancedComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        data: PropTypes.object,
        activeElementIndex: PropTypes.number.isRequired,
        isLogged: PropTypes.bool,
        ws: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)
        this.state.title = 'Nérus - Mensagem:'
        this.state.actions = this.renderButtons()
    }

    removeDialog = () => {
        this.props.dispatch(removeComponent())
    }

    onKeydown = event => {
        const index = `button${this.props.activeElementIndex}`
        debug('keydown', event)

        if (event.keyCode === keycodes.ENTER_KEY) {
            event.preventDefault()
            event.stopPropagation()
            event.stopImmediatePropagation && event.stopImmediatePropagation()
            // run the active action
            if (this.getRef(index)) {
                this.getRef(index).click()
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.timer) {
            /**
             * utilizado para dar focus no elemento correto
             * precisamos dar o timeout por conta da forma como o browser
             * funciona senão ele remove o focus do campo
             */
            clearTimeout(this.timer)
            this.timer = null
            this.timer = setTimeout(this.onUpdateComponent, 50)
        }

        if (
            prevProps?.data &&
            this.props?.data &&
            prevProps.activeElementIndex !== this.props.activeElementIndex
        ) {
            this.setState({
                actions: this.renderButtons(),
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.checkProps(
                ['optType', 'strList', 'shtct', 'activeElementIndex'],
                nextProps
            ) || this.state.actions !== nextState.actions
        )
    }

    onUpdateComponent = () => {
        const index = `button${this.props.activeElementIndex}`
        clearTimeout(this.timer)
        this.timer = null
        if (this.getRef(index)) {
            this.getRef(index).focus()
        } else {
            if (this.props?.data?.strList?.length) {
                this.props.dispatch(
                    resetElementIndex(
                        this.props.activeElementIndex - 1 < 0
                            ? this.props.data.strList.length - 1
                            : 0
                    )
                )
            }
        }
    }

    sendKey = sendKey => () => this.props.ws.send(sendBuffer(sendKey))

    dispatchKey = key => this.props.ws.send(sendBuffer(key))

    doConnect = () => this.props.ws.reconnect()

    doReset = () => this.props.dispatch(resetComponents())

    isFocused = i => {
        return this.props.activeElementIndex === parseInt(i)
    }

    onClose = e => {
        const {
            isLogged,
            data: { dontClean },
            ws,
        } = this.props

        if (e.detail || (!e.detail && !e.isTrusted)) {
            if (!isLogged || (isLogged && dontClean)) {
                this.removeDialog()
            }
            if (!dontClean) {
                ws.send(sendBuffer(keycodes.ENTER_KEY))
            }
        }
    }

    renderButtons = () => {
        if (!this.props.data || !this.props.data.shtct) {
            return null
        }

        const { optType, strList, shtct } = this.props.data
        switch (optType) {
            case 1:
            case 0: {
                return (
                    <div>
                        {strList.map((object, buttonNum) => {
                            let sendKey = shtct[buttonNum]
                            const objectArr = object.split('')
                            return (
                                <StyledButton
                                    tabIndex={buttonNum + 1}
                                    ref={this.createRef(`button${buttonNum}`)}
                                    key={buttonNum}
                                    dialog
                                    primary={this.isFocused(buttonNum)}
                                    isFocused={this.isFocused(buttonNum)}
                                    onClick={this.sendKey(sendKey)}
                                >
                                    {objectArr.map((character, i) => {
                                        return character === sendKey ? (
                                            <span key={i} className="highlight">
                                                {character}
                                            </span>
                                        ) : (
                                            character
                                        )
                                    })}
                                </StyledButton>
                            )
                        })}
                    </div>
                )
            }
            case -1: {
                return (
                    <StyledButton
                        tabIndex={1}
                        ref={this.createRef(`button0`)}
                        onClick={this.onClose}
                        isFocused={this.isFocused(0)}
                        dialog
                        primary
                    >
                        Fechar
                    </StyledButton>
                )
            }
            /* Anormalidade, apos 1 minuto sem receber resposta do servidor */
            case -100: {
                return (
                    <div>
                        <StyledButton
                            tabIndex={1}
                            ref={this.createRef(`button0`)}
                            onClick={this.doConnect}
                            isFocused={this.isFocused(0)}
                            primary
                            dialog
                        >
                            Sim
                        </StyledButton>
                        <StyledButton
                            tabIndex={2}
                            ref={this.createRef(`button1`)}
                            onClick={this.removeDialog}
                            isFocused={this.isFocused(1)}
                            dialog
                        >
                            Nao
                        </StyledButton>
                    </div>
                )
            }
            /* Sem conexão de rede */
            case -101: {
                return
            }
            /* Recuperar Sessão ativa*/
            case -102: {
                return (
                    <div>
                        <StyledButton
                            tabIndex={1}
                            ref={this.createRef(`button0`)}
                            isFocused={this.isFocused(0)}
                            onClick={this.doConnect}
                            primary
                            dialog
                        >
                            <span className="highlight">S</span>
                            im
                        </StyledButton>
                        <StyledButton
                            tabIndex={2}
                            ref={this.createRef(`button1`)}
                            isFocused={this.isFocused(1)}
                            onClick={this.doReset}
                            dialog
                        >
                            <span className="highlight">N</span>
                            ão
                        </StyledButton>
                    </div>
                )
            }
        }
    }

    render() {
        const { hasError } = this.state
        const { data } = this.props

        if (!data) {
            return null
        }

        if (hasError) {
            return this.renderError()
        }

        let __html = preprocessMessageOutput(this.props.data.msg)
        let __msgShow = preprocessMessageOutput(this.props.data.msgShow)

        if (!__html && !data.strList?.length) {
            if (data.optType == -1) {
                /* Ticket 100086
                    Criamos um componente genérico para disparar qualquer tecla ao WS
                    permitindo com que o usuário siga o fluxo de qualquer processo quando recebe um dialog nulo, vazio e sem botoes
                */
                return <GenericStatusBar dispatchKey={this.dispatchKey} />
            }

            return null
        }

        // @TODO: Estudar uma forma de passar as actions via Redux
        return (
            <BaseFlutuante
                title={this.state.title}
                actions={this.state.actions}
                onKeydown={this.onKeydown}
            >
                {__msgShow ? (
                    <p>
                        <strong
                            dangerouslySetInnerHTML={{
                                __html: __msgShow,
                            }}
                        />
                    </p>
                ) : (
                    ''
                )}
                <p dangerouslySetInnerHTML={{ __html }} />
            </BaseFlutuante>
        )
    }
}
