export const styles = theme => ({
    root: {
        paddingTop: 0,
        paddingBottom: 0,
        position: 'fixed',
        top: 0,
        minHeight: `${theme.sizing.topBar}px`,
        [theme.breakpoints.down('md')]: {
            minHeight: `${theme.sizing.topBarSmall}px`,
        },
    },
    toolbar: {
        minHeight: `${theme.sizing.topBar}px`,
        [theme.breakpoints.down('md')]: {
            minHeight: `${theme.sizing.topBarSmall}px`,
        },
    },
    flexGrow: { flexGrow: 1 },
})
