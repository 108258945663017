import TextFieldShort from './Short'

/**
 * Componente TextFieldDouble
 *
 * Rec: REC_DOUBLE
 *
 * Renderiza um campo de texto que tem formatação de casas
 * decimais
 */
export default class TextFieldNegativableShort extends TextFieldShort {
    allowNegative = true
}
