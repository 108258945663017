import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0.5),
    },
    full: {
        flex: 1,
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    value: {
        textTransform: 'uppercase',
    },
    rightAlign: {
        textAlign: 'right',
    },
}))

const Field = ({
    label = 'Descrição',
    value = '',
    right = false,
    full = false,
    flex = false,
}) => {
    const classes = useStyles()
    return (
        <Box
            className={clsx(classes.root, {
                [classes.full]: full || flex,
                [classes.flex]: flex,
            })}
        >
            <Typography component="div" className={classes.label}>
                {label}
            </Typography>
            <Typography
                component="div"
                className={clsx(classes.value, {
                    [classes.rightAlign]: right,
                    [classes.full]: flex,
                    [classes.flex]: flex,
                })}
            >
                {value}
            </Typography>
        </Box>
    )
}

Field.propTypes = {
    label: PropTypes.string,
    value: PropTypes.node,
    right: PropTypes.bool,
    full: PropTypes.bool,
    flex: PropTypes.bool,
}

export default Field
