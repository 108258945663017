import cancellablePromise from '../util/cancellablePromise'

export const noop = () => {}

export const delay = n =>
    // eslint-disable-next-line no-undef
    new Promise(resolve => {
        let timer = setTimeout(() => {
            resolve()
            clearTimeout(timer)
            timer = null
        }, n)
    })

export { cancellablePromise }
