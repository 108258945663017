import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import keycodes from '@nerus/framework/common/Keycodes'
import clsx from 'clsx'
import throttle from 'lodash.throttle'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import React from 'react'

import { sendBuffer } from '../../Eac/EacActions'
import Body from './Estrutura/Body'
import ButtonActions from './Estrutura/ButtonActions'
import Header from './Estrutura/Header'
import IconActions from './Estrutura/IconActions'
import Legend from './Estrutura/Legend'
import Pagination from './Estrutura/Pagination'
import Search from './Estrutura/Search'
import Totalizador from './Estrutura/Totalizador'

export default class BaseEditor extends EnhancedComponent {
    static propTypes = {
        rows: PropTypes.array,
        columns: PropTypes.array,
        columnsType: PropTypes.array,
        customClasses: PropTypes.object,
        buttons: PropTypes.array,
        icons: PropTypes.array,
        activeRow: PropTypes.number,
        title: PropTypes.string,
        search: PropTypes.string,
        searchLabel: PropTypes.string,
        dispatch: PropTypes.func.isRequired,
        onRowClick: PropTypes.func,
        onRowDoubleClick: PropTypes.func,
        onScroll: PropTypes.func,
        mouseLock: PropTypes.bool,
        floating: PropTypes.bool,
        isGuia: PropTypes.bool,
        isFixo: PropTypes.bool,
        isPdv: PropTypes.bool,
        isEditorDuplo: PropTypes.bool,
        isEditorForm: PropTypes.bool,
        isEditorFormEdit: PropTypes.bool,
        actions: PropTypes.bool,
        classes: PropTypes.object.isRequired,
        children: PropTypes.node,
        draggable: PropTypes.bool,
        enabled: PropTypes.bool,
        onDrag: PropTypes.func,
        ws: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)
        const { enabled = true, isGuia = false } = props
        this.state = {
            id:  enabled || isGuia ? props.id : null,
            icons: enabled || isGuia ? props.icons : [],
            buttons: enabled || isGuia ? props.buttons : [],
            showMore: false,
            shadow: '',
            maxButtonsSlider: 7,
        }
        this.activeValue = props.activeValue || this.createRef('activeValue')
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.isEditorDuplo ||
            nextProps.isEditorDuplo ||
            this.props.isEditorFormEdit ||
            nextProps.isEditorFormEdit ||
            this.props.isEditorForm ||
            nextProps.isEditorForm ||
            ((this.props.active ||
                nextProps.active ||
                this.props.enabled ||
                nextProps.enabled) &&
                (this.checkProps(
                    [
                        'id',
                        'cols',
                        'rows',
                        'title',
                        'active',
                        'edtLin',
                        'search',
                        'enabled',
                        'buttons',
                        'columns',
                        'gridTot',
                        'children',
                        'activeRow',
                        'searchLabel',
                        'showLegend',
                    ],
                    nextProps
                ) ||
                    nextState.showMore !== this.state.showMore ||
                    nextState.icons !== this.state.icons ||
                    nextState.buttons !== this.state.buttons)) ||
            false // se nada der certo, vamos proibir o update
        )
    }

    componentDidUpdate(prevProps) {
        const {
            enabled = true,
            isNew = false,
            isGuia = false,
            isEditorFormEdit = false,
            search,
        } = this.props

        const enabledChanged = this.props.enabled !== prevProps.enabled

        if (enabled || isGuia || enabledChanged) {
            const update = {}
            if (
                enabledChanged ||
                (this.props.buttons !== prevProps.buttons && this.props.buttons)
            ) {
                if (!isNew && !isEditorFormEdit) {
                    if (search) {
                        update.buttons = this.props.buttons
                    } else {
                        const buttons = this.state.buttons || []

                        this.props.buttons?.forEach(bt => {
                            const btIsInsideButtons = buttons.find(
                                button => button.key == bt.key
                            )
                            if (!btIsInsideButtons) {
                                buttons.push(bt)
                            }
                        })
                        update.buttons = buttons
                    }
                } else {
                    if (
                        !isEqual(this.props.buttons, this.state.buttons) ||
                        enabledChanged
                    ) {
                        update.buttons = this.props.buttons
                    }
                }
            }

            if (this.props.icons !== prevProps.icons && this.props.icons) {
                const isSameEditor = this.props.id == this.state.id

                if (!isNew && !isEditorFormEdit && isSameEditor) {
                    if (search) {
                        update.icons = this.props.icons
                    } else {
                        const icons = this.state.icons || []

                        this.props.icons?.forEach(ic => {
                            const icIsInsideicons = icons.find(
                                icon => icon.key == ic.key
                            )
                            if (!icIsInsideicons) {
                                icons.push(ic)
                            }
                        })
                        update.icons = icons
                    }
                } else {
                    if (!isEqual(this.props.icons, this.state.icons)) {
                        update.icons = this.props.icons
                    }
                }
            }

            if (update.buttons || update.icons) {
                this.setState(update)
            }
        } else if (isNew) {
            if (this.state.buttons.length > 0 || this.state.icons.length > 0) {
                this.setState({
                    icons: [],
                    buttons: [],
                })
            }
        }
    }

    componentDidMount() {
        this.setState({
            isMount: true,
        })
    }

    onDoubleClick = rowNumber => {
        if (this.props.isEditorFormEdit) {
            return
        }

        const { onRowDoubleClick, mdQuery, ws } = this.props

        if (onRowDoubleClick) {
            return onRowDoubleClick(rowNumber)
        }

        if (mdQuery) {
            ws.send(sendBuffer(keycodes.ENTER_KEY))
        }
        ws.send(sendBuffer(rowNumber, 'sendRowEditorFixo'))
        ws.send(sendBuffer(keycodes.ENTER_KEY))
        clearTimeout(this.dblClickTimer)
        this.dblClickTimer = setTimeout(() => {
            if (window.getSelection) {
                window.getSelection().removeAllRanges()
            } else if (document.selection) {
                document.selection.empty()
            }
        }, 0)
    }

    onClick = rowNumber => {
        if (this.props.isEditorFormEdit) {
            return
        }

        const { onRowClick, mdQuery, ws } = this.props

        if (onRowClick) {
            return onRowClick(rowNumber)
        }

        if (mdQuery) {
            ws.send(sendBuffer(keycodes.ENTER_KEY))
        }
        ws.send(sendBuffer(rowNumber, 'sendRowEditorFixo'))
    }

    setActiveRow = index => {
        this.setState({ activeRow: index })
    }

    toggleMore = () => {
        this.setState(state => {
            return { showMore: !state.showMore }
        })
    }

    mouseWheelThrottle = throttle(e => {
        const isDown = e.deltaY > 0
        this.props.ws.send(
            sendBuffer(isDown ? keycodes.DOWN_ARROW_KEY : keycodes.UP_ARROW_KEY)
        )
    }, 50)

    mouseWheel = e => {
        e.persist()

        const { onScroll } = this.props
        if (onScroll) {
            return onScroll(e)
        }

        this.mouseWheelThrottle(e)
    }

    render() {
        const {
            isEditorFormEdit,
            isEditorForm,
            columnsType,
            gridTot,
            classes,
            isFixo,
            isGuia,
            isPdv,
            isEditorDuplo,
            children,
            actions = true,
            draggable = false,
            onDrag,
            active,
            rows,
            columns,
            title,
            showLegend,
            legend,
            floating,
            searchLabel,
            mdQuery,
            search,
            activeRow,
            isNew,
            editorType,
            colFix = 0,
            enabled = true,
        } = this.props

        const customClasses = {
            root: '',
            editor: '',
            header: '',
            ...(this.props.customClasses || {}),
        }

        const { icons, buttons, showMore, maxButtonsSlider } = this.state
        const showTitle =
            this.props.showTitle === undefined
                ? !this.props.floating
                : this.props.showTitle
        let activeLabel = searchLabel
            ? searchLabel.replace(/:/g, '').trim()
            : null

        let activeColumnSearch =
            columns && columns.indexOf(activeLabel) > -1
                ? columns.indexOf(activeLabel)
                : null

        let trStyle

        let fixedData
        let scrollableData = [...rows]
        let fixedHeader = [...columns]
        let scrollableHeader = [...columns]

        let fixedActiveColumnSearch
        let scrollableActiveColumnSearch

        if (colFix) {
            fixedData = rows.map(row => row.filter(({}, i) => i < colFix))
            scrollableData = rows.map(row => row.filter(({}, i) => i >= colFix))

            fixedHeader.splice(colFix, columns.length)

            scrollableHeader.splice(0, colFix)

            fixedActiveColumnSearch =
                fixedHeader && fixedHeader.indexOf(activeLabel) > -1
                    ? fixedHeader.indexOf(activeLabel)
                    : null

            scrollableActiveColumnSearch =
                scrollableHeader && scrollableHeader.indexOf(activeLabel) > -1
                    ? scrollableHeader.indexOf(activeLabel)
                    : null

            trStyle = {
                height: 33,
            }
        }

        return (
            <div
                key="grid"
                className={clsx({
                    [classes.root]: true,
                    [classes.fullHeight]: !isEditorForm,
                    [classes.activeLegend]: showLegend,
                    [customClasses.root]: customClasses.root,
                })}
            >
                {showLegend ? (
                    <Legend
                        iconRef={this.getRef('icons')}
                        legend={legend}
                        flt={
                            (floating || isEditorForm) &&
                            !isEditorDuplo &&
                            !isGuia
                        }
                        editorType={editorType}
                    />
                ) : null}

                {(showTitle && title) || (isNew && searchLabel) ? (
                    <div
                        key="grid-header"
                        className={clsx({
                            [classes.header]: true,
                            [classes.headerFlt]: floating || isGuia,
                            [customClasses.header]: customClasses.header,
                        })}
                    >
                        <h1
                            key="grid-title"
                            className={clsx(classes.title, {
                                [classes.titleGuia]: isGuia,
                                [classes.titleEditorForm]:
                                    isEditorForm || isEditorFormEdit,
                                [customClasses.title]: customClasses.title,
                            })}
                        >
                            {showTitle && title ? title.toLowerCase() : null}
                        </h1>
                        {searchLabel !== null ? (
                            <Search
                                mdQuery={mdQuery}
                                placeholder={searchLabel}
                                value={search}
                                className={clsx({
                                    [classes.searchFieldFlt]: floating,
                                })}
                            />
                        ) : null}
                    </div>
                ) : null}

                <div
                    key="gridtable"
                    className={clsx(classes.editor, {
                        [classes.bordered]: isFixo,
                        [customClasses.editor]: customClasses.editor,
                    })}
                >
                    {colFix ? (
                        <div
                            key="grid-table"
                            className={clsx(classes.content, {
                                [classes.contentFlt]:
                                    (floating || isEditorForm) && !isGuia,
                                [customClasses.content]: customClasses.content,
                            })}
                            style={{
                                display: 'flex',
                                overflowY: floating ? 'hidden' : 'scroll',
                            }}
                        >
                            <div>
                                <table
                                    key="table-element"
                                    className={clsx(classes.table, {
                                        [customClasses.table]:
                                            customClasses.table,
                                    })}
                                    style={{
                                        height: isEditorFormEdit
                                            ? '100%'
                                            : 'auto',
                                        overflowY: 'hidden',
                                    }}
                                >
                                    <Header
                                        activeColumnSearch={
                                            fixedActiveColumnSearch
                                        }
                                        types={columnsType}
                                        floating={floating}
                                        classes={classes}
                                        data={fixedHeader}
                                        draggable={draggable}
                                        isGuia={isGuia}
                                    />

                                    <Body
                                        // define tamanhos fixos para não distorcer o layout (ticket 98447)
                                        trStyle={
                                            isEditorFormEdit ? trStyle : {}
                                        }
                                        columns={colFix}
                                        data={fixedData}
                                        active={false}
                                        enabled={enabled}
                                        classes={classes}
                                        legend={legend}
                                        types={columnsType}
                                        onClick={this.onClick}
                                        onDoubleClick={this.onDoubleClick}
                                        onWheel={this.mouseWheel}
                                        onFocus={this.setActiveRow}
                                        mouseLock={this.props.mouseLock}
                                        activeValue={this.activeValue}
                                        activeRow={mdQuery ? null : activeRow}
                                        activeColumnSearch={
                                            mdQuery
                                                ? fixedActiveColumnSearch
                                                : null
                                        }
                                    />
                                </table>
                            </div>

                            <div
                                style={{
                                    overflowX: floating ? 'overlay' : 'initial',
                                    overflowY: floating ? 'hidden' : 'initial',
                                    width: '100%',
                                }}
                            >
                                <table
                                    key="table-element"
                                    className={clsx(classes.table, {
                                        [customClasses.table]:
                                            customClasses.table,
                                    })}
                                >
                                    <Header
                                        activeColumnSearch={
                                            scrollableActiveColumnSearch
                                        }
                                        types={columnsType}
                                        floating={floating}
                                        classes={classes}
                                        data={scrollableHeader || []}
                                        draggable={draggable}
                                        isGuia={isGuia}
                                    />

                                    <Body
                                        trStyle={
                                            isEditorFormEdit ? trStyle : {}
                                        }
                                        columns={scrollableHeader?.length ?? 0}
                                        data={scrollableData}
                                        dispatch={this.props.dispatch}
                                        active={active}
                                        enabled={enabled}
                                        isPdv={isPdv}
                                        draggable={draggable}
                                        onDrag={onDrag}
                                        legend={legend}
                                        classes={classes}
                                        types={columnsType}
                                        onClick={this.onClick}
                                        onDoubleClick={this.onDoubleClick}
                                        onWheel={this.mouseWheel}
                                        onFocus={this.setActiveRow}
                                        mouseLock={this.props.mouseLock}
                                        activeValue={this.activeValue}
                                        activeRow={mdQuery ? null : activeRow}
                                        activeColumnSearch={
                                            mdQuery
                                                ? scrollableActiveColumnSearch
                                                : null
                                        }
                                    />
                                    {gridTot ? (
                                        <Totalizador
                                            activeColumnSearch={
                                                fixedActiveColumnSearch ||
                                                scrollableActiveColumnSearch
                                            }
                                            data={gridTot}
                                            types={columnsType}
                                        />
                                    ) : null}
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div
                            key="grid-table"
                            className={clsx(classes.content, {
                                [classes.contentFlt]:
                                    (floating || isEditorForm) && !isGuia,
                                [customClasses.content]: customClasses.content,
                            })}
                        >
                            <table
                                key="table-element"
                                className={clsx(classes.table, {
                                    [customClasses.table]: customClasses.table,
                                })}
                            >
                                <Header
                                    activeColumnSearch={activeColumnSearch}
                                    types={columnsType}
                                    floating={floating}
                                    classes={classes}
                                    data={columns || []}
                                    draggable={draggable}
                                    isGuia={isGuia}
                                />

                                <Body
                                    columns={columns?.length ?? 0}
                                    data={rows}
                                    dispatch={this.props.dispatch}
                                    active={active}
                                    enabled={enabled}
                                    isPdv={isPdv}
                                    draggable={draggable}
                                    onDrag={onDrag}
                                    legend={legend}
                                    classes={classes}
                                    types={columnsType}
                                    onClick={this.onClick}
                                    onDoubleClick={this.onDoubleClick}
                                    onWheel={this.mouseWheel}
                                    onFocus={this.setActiveRow}
                                    mouseLock={this.props.mouseLock}
                                    activeValue={this.activeValue}
                                    activeRow={mdQuery ? null : activeRow}
                                    activeColumnSearch={
                                        mdQuery ? activeColumnSearch : null
                                    }
                                />

                                {gridTot ? (
                                    <Totalizador
                                        activeColumnSearch={activeColumnSearch}
                                        data={gridTot}
                                        types={columnsType}
                                    />
                                ) : null}
                            </table>
                        </div>
                    )}

                    {children}

                    {actions ? (
                        <div
                            key="grid-actions"
                            ref={this.createRef('buttonsHolder')}
                            className={clsx(classes.actions, {
                                [classes.emptyActionButtonsEditorFormEdit]: isEditorFormEdit,
                                [customClasses.actions]: customClasses.actions,
                            })}
                        >
                            {showMore ? (
                                <Pagination classes={classes} active={active} />
                            ) : null}
                            {buttons?.length > 0 ? (
                                <ButtonActions
                                    data={!isNew && !active ? [] : buttons}
                                    maxButtonsSlider={maxButtonsSlider}
                                    activeValue={this.activeValue}
                                    showMore={showMore}
                                    hasIcons={icons?.length > 0}
                                    classes={classes}
                                    toggleShowMore={this.toggleMore}
                                    active={active}
                                />
                            ) : null}
                            {icons?.length > 0 ? (
                                <IconActions
                                    innerRef={this.createRef('icons')}
                                    isNew={isNew}
                                    mdQuery={mdQuery}
                                    data={!isNew && !active ? [] : icons}
                                    classes={classes}
                                    showMore={showMore}
                                    active={active}
                                    activeValue={this.activeValue}
                                />
                            ) : null}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        )
    }
}
