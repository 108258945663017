import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function AnalyticIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0,19.2)">
                <path d="m 41,-158 h 959 v -42 H 0 V 800 h 41 z m 419,32 h -42 v 349 l 42,42 z m 110,0 h -42 v 460 l 42,42 z m -220,0 h -42 v 374 l 42,-28 z m 440,0 h -42 v 504 l 42,40 z m -152,0 v 500 l 34,-30 8,-7 v -463 z m -398,0 h -42 v 321 l 42,41 z m 661,0 h -42 v 610 l 42,41 z M 385,234 272,311 73,119 v 58 l 195,187 112,-76 218,220 94,-83 225,217 h -83 v 42 H 989 V 529 h -42 v 85 L 693,368 600,450 Z M 130,-126 H 88 V 90 l 42,40 z" />
            </g>
        </SvgIcon>
    )
}
