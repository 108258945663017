import keycodes from '@nerus/framework/common/Keycodes'
import IconButton from '@nerus/framework/components/Button/IconButton'
import CalculatorIcon from '@nerus/framework/icons/Calculator'
import INerusIcon from '@nerus/framework/icons/INerus'
import ReportIcon from '@nerus/framework/icons/Report'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { toggleCalculator } from '../../../App/AppActions'
import { withWS } from '../../Business/Websocket/Context'
import { sendBuffer } from '../../Eac/EacActions'

class IconActions extends Component {
    static dispatch = 'MenuTitleActions'

    static propTypes = {
        hasActiveComponent: PropTypes.bool,
        isMenuPrincipal: PropTypes.bool,
        isO2: PropTypes.bool,
        dispatch: PropTypes.func,
        classes: PropTypes.object.isRequired,
        ws: PropTypes.object.isRequired,
    }

    onCalculatorClick = () => this.props.dispatch(toggleCalculator())
    onEmailClick = () =>
        this.props.ws.send(sendBuffer(keycodes.CTRL_E, 'sendButton'))
    onReportClick = () =>
        this.props.ws.send(sendBuffer(keycodes.CTRL_A, 'sendButton'))

    render() {
        const { classes, isO2, hasActiveComponent, isMenuPrincipal } =
            this.props

        return (
            <div className={classes.icons}>
                <IconButton
                    onClick={this.onCalculatorClick}
                    tooltip={'F5-Calculadora'}
                    size={!isO2 ? 'small' : undefined}
                    className={!isO2 ? classes.icon : classes.iconWhite}
                >
                    <CalculatorIcon />
                </IconButton>

                <IconButton
                    onClick={this.onEmailClick}
                    tooltip={'CTRL+E-Email'}
                    size={!isO2 ? 'small' : undefined}
                    className={!isO2 ? classes.icon : classes.iconWhite}
                >
                    <INerusIcon />
                </IconButton>

                {!isMenuPrincipal ? (
                    <IconButton
                        onClick={this.onReportClick}
                        tooltip={'CTRL+A-Relatório'}
                        size={!isO2 ? 'small' : undefined}
                        className={!isO2 ? classes.icon : classes.iconWhite}
                        disabled={isO2 && !hasActiveComponent}
                    >
                        <ReportIcon />
                    </IconButton>
                ) : null}
            </div>
        )
    }
}

export default connect()(withWS(IconActions))
