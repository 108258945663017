import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function BarStackedIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 200 200">
            <g
                transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                fill="inherit"
                stroke="none"
            >
                <path d="M1060 1140 l0 -760 180 0 180 0 0 760 0 760 -180 0 -180 0 0 -760z" />
                <path d="M580 1160 l0 -260 180 0 180 0 0 260 0 260 -180 0 -180 0 0 -260z" />
                <path d="M1560 850 l0 -270 170 0 170 0 0 270 0 270 -170 0 -170 0 0 -270z" />
                <path d="M100 700 l0 -220 170 0 170 0 0 220 0 220 -170 0 -170 0 0 -220z" />
                <path d="M580 460 l0 -360 180 0 180 0 0 360 0 360 -180 0 -180 0 0 -360z" />
                <path d="M1560 300 l0 -200 170 0 170 0 0 200 0 200 -170 0 -170 0 0 -200z" />
                <path d="M100 250 l0 -150 170 0 170 0 0 150 0 150 -170 0 -170 0 0 -150z" />
                <path d="M1060 200 l0 -100 180 0 180 0 0 100 0 100 -180 0 -180 0 0 -100z" />
            </g>
        </SvgIcon>
    )
}
