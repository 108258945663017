const bigPriceStyles = theme => ({
    bigPrice: {
        fontSize: 96,
        color: theme.palette.orange.main + ' !important',
        fontWeight: 'bold',
        display: 'block',
        textAlign: 'right',
        marginTop: 16,
        marginBottom: 16,
        marginRight: 96,
        border: 'none',
    },
    bigPriceLabel: {
        display: 'none !important',
    },
    bigPriceFormControl: {
        height: 'auto',
    },
})

export const styles = {
    vertical: theme => ({
        root: {
            marginRight: 8,
            outline: 'none',
        },
        helperText: {
            margin: 0,
            bottom: 5,
            right: 5,
            fontSize: 9,
            position: 'absolute',
            cursor: 'default',
        },
        popoverRoot: {
            position: 'inherit',
        },
        selectRoot: {
            fontSize: 14,
            outline: 'none',
            [theme.breakpoints.down('lg')]: {
                fontSize: 13,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 12,
            },
        },
        inputRoot: {
            fontSize: '14px',
            height: 24,
            outline: 'none',
            [theme.breakpoints.down('lg')]: {
                fontSize: 13,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 12,
            },
        },
        input: {
            padding: '2px 0 3px',
        },
        inputMultiline: {
            paddingBottom: 15,
        },
        shrink: {
            transform: 'translate(0, 1.5px)',
        },
        disableLines: {
            borderBottom: 'none !important',
        },
        textareaRoot: {
            // flexGrow: 1,
            display: 'flex',
            margin: 0,
            height: 'auto !important',
            outline: 'none',
        },
        textareaFormControl: {
            flexGrow: 1,
            border: '1px solid #ddd',
            padding: 0,
            height: 'auto !important',
        },
        textareaLabel: {
            alignSelf: 'flex-start',
        },
        doubleLineLabel: {
            margin: '10px 0',
            [theme.breakpoints.down('lg')]: {
                margin: '6px 0',
            },
        },
        doubleLineSwitch: {
            margin: '7px 0',
            '& span': {
                lineHeight: '13px',
            },
        },
        overwrite: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            fontSize: '13px',
            [theme.breakpoints.down('lg')]: {
                fontSize: 12,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 11,
            },
        },
        inputOverwrite: {
            width: '33px',
        },
        dateTimeAdornment: {
            marginLeft: '0px',
        },
        buttomDateTimeAdornment: {
            padding: '0px !important',
            '& svg': {
                transform: 'scale(0.75)',
            },
        },
        activeThumb: {
            color: theme.palette.primary.main,
        },
        ...bigPriceStyles(theme),
    }),
    horizontal: theme => ({
        root: {
            marginRight: 8,
            flexDirection: 'row',
            verticalAlign: 'middle',
            justifyContent: 'center',
            alignItems: 'center',
            outline: 'none',
        },
        helperText: {
            margin: 0,
            bottom: 5,
            right: 5,
            fontSize: 9,
            position: 'absolute',
            cursor: 'default',
        },
        textareaRoot: {
            // flexGrow: 1,
            display: 'flex',
            margin: 0,
            height: 'auto !important',
            outline: 'none',
        },
        textareaFormControl: {
            flexGrow: 1,
            border: '1px solid #ddd',
            padding: 0,
            height: 'auto !important',
        },
        textareaLabel: {
            alignSelf: 'flex-start',
        },
        doubleLineLabel: {
            margin: '10px 0',
            [theme.breakpoints.down('lg')]: {
                margin: '6px 0',
            },
        },
        inputRoot: {
            fontSize: '14px',
            outline: 'none',
            height: 24,
            [theme.breakpoints.down('lg')]: {
                fontSize: 13,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 12,
            },
        },
        formControl: {
            marginTop: '0 !important',
        },
        input: {
            borderRadius: 0,
            padding: '2px 0 3px',
            borderBottom: '1px solid rgba(0,0,0,0.42)',
        },
        disabled: {
            borderBottom: '1px solid rgba(0,0,0,0.42)',
        },
        disabledBorderWrapper: {
            borderBottom: '1px solid transparent',
        },
        disableLines: {
            borderBottom: 'none !important',
        },
        enabled: {
            borderBottomColor: 'transparent',
            boxShadow: '0px 2px 0px 0px' + theme.palette.primary.main,
        },
        inputMultiline: {
            padding: `6px 6px 15px`,
        },
        shrink: {
            transform: 'translate(0, 1.5px)',
        },
        switchRoot: {
            margin: '0 0 0 -4px',
            position: 'relative',
            outline: 'none',
        },
        labelRootSelecao: {
            right: 0,
            position: 'absolute',
            top: 10,
            outline: 'none',
        },
        selectFormControl: {
            marginTop: 0,
            'label + &': {
                marginTop: 0,
            },
        },
        popoverRoot: {
            position: 'inherit !important',
        },
        selectRoot: {
            padding: 0,
            paddingRight: 24,
            fontSize: 14,
            outline: 'none',
            [theme.breakpoints.down('lg')]: {
                fontSize: 13,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 12,
            },
        },
        labelSwitch: {
            justifyContent: 'flex-end !important',
        },
        doubleLineSwitch: {
            margin: '7px 0',
            '& span': {
                lineHeight: '13px',
            },
        },
        labelRoot: {
            letterSpacing: '-0.4px',
            fontSize: '14px',
            position: 'relative',
            display: 'inline-flex !important',
            transform: 'none',
            marginLeft: '0  !important',
            marginRight: theme.spacing(1) + 'px !important',
            flexWrap: 'wrap',
            outline: 'none',
            [theme.breakpoints.down('lg')]: {
                fontSize: 13,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 12,
            },
        },
        labelBold: {
            fontWeight: 'bold',
        },
        adornmentOverwrite: {
            position: 'absolute',
            right: '-20px',
            bottom: '7px',
        },
        overwrite: {
            fontSize: '15px',
            [theme.breakpoints.down('lg')]: {
                fontSize: 13,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 12,
            },
        },
        dateTimeAdornment: {
            marginLeft: '0px',
        },
        buttomDateTimeAdornment: {
            padding: '0px !important',
            '& svg': {
                transform: 'scale(0.75)',
            },
        },
        activeThumb: {
            color: theme.palette.primary.main,
        },
        ...bigPriceStyles(theme),
    }),
}
