export default theme => ({
    actionsRight: {
        textAlign: 'right',
        padding: theme.spacing(2),
        boxShadow:
            '0 -1px 3px 0 rgb(0 0 0 / 6%), 0 -1px 1px 0 rgb(0 0 0 / 3%), 0 -2px 1px -1px rgb(0 0 0 / 2%)',
    },
    formBox: {
        minWidth: '95% !important',
    },
    fieldBox: {
        width: '50%',
    },
    previewerBox: {
        padding: 10,
    },
    titleInput: {
        fontSize: 12,
    },
    drawer: {
        width: 400,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 400,
        border: 'none',
        boxShadow:
            '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    headingBox: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },
    headingConfig: {
        padding: 17,
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    headingClose: {
        cursor: 'pointer',
        float: 'right',
    },
    queriesButton: {
        width: '100%',
    },
    headingSummary: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    detailsTipo: {
        paddingTop: 15,
    },
    flex: {
        display: 'flex',
    },
    detailsBiblioteca: {
        padding: 0,
    },
    boxTipo: {
        width: '100%',
        height: 100,
        textAlign: 'center',
        background: '#F5F5F5',
        borderRadius: 10,
        fontSize: 12,
        cursor: 'pointer',
        transform: 'scale(1)',
        transition: '300ms ease-in-out all',
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow:
                '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        },
    },
    boxIcon: {
        fontSize: 30,
        margin: 25,
        marginBottom: 5,
    },
    queryTitle: {
        color: '#7b151c',
        paddingBottom: 20,
        marginBottom: 10,
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
})
