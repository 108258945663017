import 'draft-js/dist/Draft.css'

import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Code from '@material-ui/icons/Code'
import AlignCenter from '@material-ui/icons/FormatAlignCenter'
import AlignJustify from '@material-ui/icons/FormatAlignJustify'
import AlignLeft from '@material-ui/icons/FormatAlignLeft'
import AlignRight from '@material-ui/icons/FormatAlignRight'
import FormatBold from '@material-ui/icons/FormatBold'
import FormatItalic from '@material-ui/icons/FormatItalic'
import FormatListBulleted from '@material-ui/icons/FormatListBulleted'
import FormatListNumbered from '@material-ui/icons/FormatListNumbered'
import FormatQuote from '@material-ui/icons/FormatQuote'
import FormatUnderline from '@material-ui/icons/FormatUnderlined'
import FormatTitle from '@material-ui/icons/Title'
import makeStyles from '@material-ui/styles/makeStyles'
import {
    CTRL_ALT_MAPPING,
    CTRL_MAPPING,
    keycodes,
} from '@nerus/framework/common/Keycodes'
import useWS from '@nerus/framework/hooks/useWS'
import clsx from 'clsx'
import Draft, {
    Editor,
    EditorBlock,
    EditorState,
    getDefaultKeyBinding,
    RichUtils,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import htmlentities from 'htmlentities'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { sendBuffer } from '../../modules/NerusWeb/Eac/EacActions'

const icons = {
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    FormatBold,
    FormatItalic,
    FormatUnderline,
    FormatQuote,
    FormatListBulleted,
    FormatListNumbered,
    Code,
    FormatTitle,
}

const useStyles = makeStyles(
    theme => ({
        root: {
            width: '100%',
            background: theme.palette.common.white,
            fontFamily: 'Frutiger, sans-serif',
            fontSize: 14,
            padding: 0,
            borderBottom: `2px solid transparent`,
        },
        rootActive: {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        actions: {
            display: 'flex',
            padding: theme.spacing(1),
            borderBottom: '1px solid #ddd',
        },
        editor: {
            padding: theme.spacing(1, 1, 2),
            maxWidth: '100%',
            '& .DraftEditor-root': {
                display: 'flex',
                maxWidth: '100%',
                flex: 1,
            },
            '& .DraftEditor-editorContainer': {
                display: 'flex',
                maxWidth: '100%',
                flex: 1,
            },
            '& .public-DraftEditor-content': {
                overflow: 'auto',
                display: 'flex',
                maxWidth: '100%',
                flex: 1,
                '& > div': {
                    maxWidth: '100%',
                    flex: 1,
                    '& > div.alignLeft': {
                        textAlign: 'left',
                        display: 'flex',
                        justifyContent: 'flex-start',
                    },
                    '& > div.alignRight': {
                        textAlign: 'right',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    },
                    '& > div.alignCenter': {
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    },
                    '& > div.alignJustify': {
                        textAlign: 'justify',
                        display: 'flex',
                        justifyContent: 'center',
                    },
                },
            },
            '& .public-DraftStyleDefault-ltr': {
                top: '8px',
            },
        },
        hidePlaceholder: {
            '& .public-DraftEditorPlaceholder-root': {
                display: 'none',
            },
        },
        controlDivisor: {
            margin: theme.spacing(0, 1),
        },
        styleButton: {
            margin: '0 1px',
            '& > span': {
                display: 'inline-flex',
            },
            '& > span > svg': {
                fontSize: 18,
            },
        },
        styleButtonActive: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.common.white,
            },
        },
        alignLeft: {
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'flex-start',
        },
        alignRight: {
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        alignCenter: {
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
        alignJustify: {
            textAlign: 'justify',
            display: 'flex',
            justifyContent: 'center',
        },
    }),
    { name: 'RichText' }
)

const styleMap = {}

const INLINE_STYLES = [
    { label: 'FormatBold', tooltip: 'Negrito', style: 'BOLD' },
    { label: 'FormatItalic', tooltip: 'Itálico', style: 'ITALIC' },
    { label: 'FormatUnderline', tooltip: 'Sublinhado', style: 'UNDERLINE' },
]

const BLOCK_TYPES = [
    { label: 'H1', tooltip: 'Titulo 1', style: 'header-one' },
    { label: 'H2', tooltip: 'Titulo 2', style: 'header-two' },
    { label: 'H3', tooltip: 'Titulo 3', style: 'header-three' },
    { label: 'FormatQuote', tooltip: 'Citação', style: 'blockquote' },
    {
        label: 'FormatListBulleted',
        tooltip: 'Lista',
        style: 'unordered-list-item',
    },
    {
        label: 'FormatListNumbered',
        tooltip: 'Lista Ordenada',
        style: 'ordered-list-item',
    },
    { label: 'Code', tooltip: 'Bloco de Código', style: 'code-block' },
]

const BLOCK_ALIGN = [
    { label: 'AlignLeft', tooltip: 'Alinhado à Esquerda', style: 'align-left' },
    { label: 'AlignCenter', tooltip: 'Centralizado', style: 'align-center' },
    {
        label: 'AlignRight',
        tooltip: 'Alinhado à Direita',
        style: 'align-right',
    },
    { label: 'AlignJustify', tooltip: 'Justificado', style: 'align-justify' },
]

function getBlockStyle(block) {
    switch (block?.getType?.()) {
        case 'blockquote':
            return 'RichEditor-blockquote'
        case 'align-right':
            return 'alignRight'
        case 'align-left':
            return 'alignLeft'
        case 'align-center':
            return 'alignCenter'
        case 'align-justify':
            return 'alignJustify'
    }
}

const StyleHOC = props => {
    const { style, children } = props
    return React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { style })
        }
        return child
    })
}

/**
 * Meio hack, mas, basicamente renderiza um Block sem o wrap
 * com a classe de RTL que causa problemas com o alinhamento
 */
const RawTextRenderer = props => {
    const newblock = <EditorBlock {...props} />
    return newblock.type.prototype._renderChildren.apply(newblock)
}

/**
 * Custom Renderer para permitir usar inline styles para o alinhamento
 * basicamente cria um render que renderiza o 'customBlockRenderMap' diretamente
 * sem nenhum wrapper
 */
function NerusBlockRenderer(contentBlock) {
    const type = contentBlock?.getType?.() || ''
    if (type.substr(0, 6) === 'align-') {
        return {
            component: RawTextRenderer,
            editable: true,
        }
    }
}

const customBlockRenderMap = Immutable.Map({
    'align-center': {
        element: 'div',
        wrapper: (
            <StyleHOC
                style={{
                    textAlign: 'center',
                }}
            />
        ),
    },
    'align-left': {
        element: 'div',
        wrapper: (
            <StyleHOC
                style={{
                    textAlign: 'left',
                }}
            />
        ),
    },
    'align-right': {
        element: 'div',
        wrapper: (
            <StyleHOC
                style={{
                    textAlign: 'right',
                }}
            />
        ),
    },
    'align-justify': {
        element: 'div',
        wrapper: (
            <StyleHOC
                style={{
                    textAlign: 'justify',
                }}
            />
        ),
    },
})

const blockRenderMap = Draft.DefaultDraftBlockRenderMap.merge(
    customBlockRenderMap
)

function StyleButton({ onToggle, active, tooltip, label, style, disabled }) {
    const classes = useStyles()

    let className = classes.styleButton
    if (active) {
        className += ` ${classes.styleButtonActive}`
    }

    const onMouseDown = useCallback(
        e => {
            e.preventDefault()
            onToggle(style)
        },
        [onToggle]
    )

    let useLabel = label
    let Icon = icons[label]
    if (Icon) {
        useLabel = <Icon />
    }

    const option = (
        <span>
            <IconButton
                className={className}
                size="small"
                disabled={disabled}
                onMouseDown={onMouseDown}
            >
                {useLabel}
            </IconButton>
        </span>
    )

    return tooltip ? <Tooltip title={tooltip}>{option}</Tooltip> : option
}

StyleButton.propTypes = {
    onToggle: PropTypes.func.isRequired,
    active: PropTypes.bool,
    tooltip: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.any,
    disabled: PropTypes.bool,
}

function BlockStyleControls({ controls, editorState, onToggle, disabled }) {
    const selection = editorState?.getSelection?.()
    const block = editorState
        ?.getCurrentContent?.()
        ?.getBlockForKey?.(selection?.getStartKey?.())
    let blockType = block?.getType?.() || null

    return (
        <div className="RichEditor-controls">
            {(controls || []).map(type => (
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    disabled={disabled}
                    label={type.label}
                    tooltip={type.tooltip}
                    onToggle={onToggle}
                    options={type.options}
                    style={type.style}
                />
            ))}
        </div>
    )
}

BlockStyleControls.propTypes = {
    controls: PropTypes.array.isRequired,
    onToggle: PropTypes.func.isRequired,
    editorState: PropTypes.any,
    disabled: PropTypes.bool,
}

function InlineStyleControls({ controls, editorState, onToggle, disabled }) {
    let currentStyle
    try {
        currentStyle = editorState?.getCurrentInlineStyle?.()
    } finally {
        // nothing
    }

    return (
        <div className="RichEditor-controls">
            {(controls || []).map(type => (
                <StyleButton
                    key={type.label}
                    active={currentStyle ? currentStyle.has(type.style) : false}
                    disabled={disabled}
                    label={type.label}
                    tooltip={type.tooltip}
                    onToggle={onToggle}
                    options={type.options}
                    style={type.style}
                />
            ))}
        </div>
    )
}

InlineStyleControls.propTypes = {
    controls: PropTypes.array.isRequired,
    onToggle: PropTypes.func.isRequired,
    editorState: PropTypes.any,
    disabled: PropTypes.bool,
}

function RichText(props) {
    const { disabled, rows, value, inputRef, frameworkprops } = props

    const { manualfocus = false, minRows = 1 } = frameworkprops

    const editor = useRef(null)
    const classes = useStyles()
    const ws = useWS()

    const defaultValue = useMemo(() => {
        if (value) {
            const options = {
                customBlockFn: ({ style }) => {
                    const { textAlign = '' } = style
                    if (textAlign) {
                        return { type: `align-${textAlign}` }
                    }
                    return null
                },
            }

            // Enotice
            if (manualfocus) {
                const decodedValue = value.replace(/\n/g, '')

                const contentState = stateFromHTML(decodedValue, options)

                if (contentState) {
                    return EditorState.createWithContent(contentState)
                }
            } else {
                const decodedValue = htmlentities.decode(
                    value.replace(/\n/g, '')
                )

                const contentState = stateFromHTML(decodedValue, options)
                if (contentState)
                    return EditorState.createWithContent(contentState)
            }
        }
        return EditorState.createEmpty()
    }, [manualfocus, value])

    const [editorState, setEditorState] = useState(defaultValue)

    useEffect(() => {
        if (global.isJest) return
        setEditorState(defaultValue)
    }, [defaultValue])

    const focus = useCallback(() => {
        if (!disabled) {
            editor.current.focus()
            setEditorState(() => {
                editor.current.editor.scrollTop =
                    editor.current.editor.scrollHeight
                return EditorState.moveFocusToEnd(
                    editor.current?._latestEditorState
                )
            })
        } else {
            editor.current.editor.scrollTop = 0
        }
    }, [setEditorState, disabled])

    useEffect(() => {
        if (global.isJest) return
        if (!disabled) focus()

        if (!manualfocus) editor.current.editor.onblur = focus

        return () => (editor.current.editor.onblur = null)
    }, [editor, inputRef, disabled, focus])

    const handleKeyCommand = useCallback(
        (command, editorState) => {
            const newState = RichUtils.handleKeyCommand(editorState, command)

            if (newState) {
                setEditorState(newState)
                return 'handled'
            }
            return 'not-handled'
        },
        [editorState, setEditorState]
    )

    const mapKeyToEditorCommand = useCallback(
        e => {
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation()

            if (!manualfocus) {
                const modifiedKey =
                    e.altKey && e.ctrlKey
                        ? CTRL_ALT_MAPPING[e.keyCode]
                        : CTRL_MAPPING[e.keyCode]
                if (
                    ((e.altKey && e.ctrlKey && CTRL_ALT_MAPPING[e.keyCode]) ||
                        (e.ctrlKey && CTRL_MAPPING[e.keyCode])) &&
                    (props.frameworkprops.bt || []).find(
                        b => b.key === modifiedKey
                    )
                ) {
                    ws.current.send(
                        sendBuffer(
                            {
                                key: modifiedKey,
                                value: editor.current.editor.value || '\n',
                                ...(props?.frameworkprops?.position || {}),
                                componentId: props?.frameworkprops?.componentId,
                            },
                            'sendEdit'
                        )
                    )
                    return null
                }
            }

            switch (e.keyCode) {
                case keycodes.ESCAPE_KEY:
                    ws.current.send(
                        sendBuffer(
                            {
                                key: e.keyCode,
                                value: props.value || '\n',
                                ...(props?.frameworkprops?.position || {}),
                                componentId: props?.frameworkprops?.componentId,
                            },
                            'sendEdit'
                        )
                    )
                    return null
                case keycodes.ENTER_KEY: {
                    if (e.shiftKey) {
                        return 'split-block'
                    }

                    // Enotice
                    if (!manualfocus) {
                        ws.current.send(
                            sendBuffer(
                                {
                                    key: e.keyCode,
                                    value: editor.current.editor.value || '\n',
                                    ...(props?.frameworkprops?.position || {}),
                                    componentId:
                                        props?.frameworkprops?.componentId,
                                },
                                'sendEdit'
                            )
                        )
                        return null
                    }

                    break
                }
                case keycodes.UP_ARROW_KEY: {
                    // Enotice
                    if (!manualfocus) {
                        const selectionState = editorState.getSelection()
                        if (
                            selectionState &&
                            editorState.getCurrentContent().getBlockMap()
                        ) {
                            const isFirstBlock =
                                editorState
                                    .getCurrentContent()
                                    .getBlockMap()
                                    .first()
                                    .getKey() === selectionState.getFocusKey()
                            if (
                                isFirstBlock &&
                                window.getSelection().anchorOffset === 0
                            ) {
                                ws.current.send(
                                    sendBuffer(
                                        {
                                            key: e.keyCode,
                                            value: props.value || '\n',
                                            ...(props?.frameworkprops
                                                ?.position || {}),
                                            componentId:
                                                props?.frameworkprops
                                                    ?.componentId,
                                        },
                                        'sendEdit'
                                    )
                                )
                            }
                        }
                        return null
                    }
                    break
                }
                case keycodes.DOWN_ARROW_KEY: {
                    // Enotice
                    if (!manualfocus) {
                        const selectionState = editorState.getSelection()
                        if (
                            selectionState &&
                            editorState.getCurrentContent().getBlockMap()
                        ) {
                            const isLastBlock =
                                editorState
                                    .getCurrentContent()
                                    .getBlockMap()
                                    .last()
                                    .getKey() === selectionState.getFocusKey()
                            const length = editorState
                                .getCurrentContent()
                                .getLastBlock()
                                .getLength()
                            if (
                                isLastBlock &&
                                (length ===
                                    window.getSelection().anchorOffset ||
                                    length ===
                                        editorState.getSelection().toJS()
                                            .anchorOffset)
                            ) {
                                ws.current.send(
                                    sendBuffer(
                                        {
                                            key: e.keyCode,
                                            value: editor.current.editor.value,
                                            ...(props?.frameworkprops
                                                ?.position || {}),
                                            componentId:
                                                props?.frameworkprops
                                                    ?.componentId,
                                        },
                                        'sendEdit'
                                    )
                                )
                            }
                        }
                        return null
                    }
                    break
                }
                case keycodes.TAB_KEY: {
                    const newEditorState = RichUtils.onTab(
                        e,
                        editorState,
                        4 /* maxDepth */
                    )
                    if (newEditorState !== editorState) {
                        setEditorState(newEditorState)
                    }
                    return null
                }
            }
            return getDefaultKeyBinding(e)
        },
        [editorState, setEditorState]
    )

    let className = classes.editor
    const onToggleBlockType = useCallback(
        blockType => {
            try {
                setEditorState(editorState =>
                    RichUtils.toggleBlockType(editorState, blockType)
                )
            } catch (e) {
                // nothing
            }
        },
        [setEditorState]
    )

    const onToggleInlineStyle = useCallback(
        inlineStyle => {
            try {
                setEditorState(editorState =>
                    RichUtils.toggleInlineStyle(editorState, inlineStyle)
                )
            } catch (e) {
                // nothing
            }
        },
        [setEditorState]
    )

    const onChange = useCallback(
        newEditorState => {
            setEditorState(newEditorState)

            const contentState = newEditorState.getCurrentContent()

            const options = {
                blockRenderers: {
                    'align-center': block => {
                        return `<p style="text-align:center;">${block.getText()}</p>`
                    },
                    'align-left': block => {
                        return `<p style="text-align:left;">${block.getText()}</p>`
                    },
                    'align-right': block => {
                        return `<p style="text-align:right;">${block.getText()}</p>`
                    },
                    'align-justify': block => {
                        return `<p style="text-align:justify;">${block.getText()}</p>`
                    },
                },
                blockStyleFn: block => {
                    const textAlign = block.getData().get('textAlign')
                    if (textAlign) {
                        return {
                            style: { textAlign },
                        }
                    }
                    return null
                },
            }

            let html = stateToHTML(contentState, options)

            if (manualfocus) {
                // Enotice

                editor.current.editor.value = html
                    ?.replace?.(/&amp;/g, '&')
                    // ?.replace?.(/&nbsp;/g, ' ')
                    ?.replace?.(/&gt;/g, '>')
                    ?.replace?.(/&lt;/g, '<')
            } else {
                const htmlEncoded = htmlentities.encode(html)

                editor.current.editor.value = htmlEncoded
                    ?.replace?.(/&nbsp;/g, ' ')
                    ?.replace?.(/&quot;/g, '"')
                    ?.replace?.(/&amp;/g, '&')
                    ?.replace?.(/&gt;/g, '>')
                    ?.replace?.(/&lt;/g, '<')
            }

            editor.current.editor.value = editor.current.editor.value
                .match(
                    new RegExp(
                        '.{1,' + (props.frameworkprops.lsz - 1) + '}',
                        'g'
                    )
                )
                .join('\n')

            props?.onChange?.({
                target: { value: editor.current.editor.value },
            })
        },
        [setEditorState]
    )

    return (
        <div
            className={clsx(classes.root, { [classes.rootActive]: !disabled })}
        >
            <div className={classes.actions}>
                <BlockStyleControls
                    controls={BLOCK_ALIGN}
                    disabled={disabled}
                    editorState={editorState}
                    onToggle={onToggleBlockType}
                />

                <Divider
                    className={classes.controlDivisor}
                    orientation="vertical"
                    flexItem
                />

                <InlineStyleControls
                    controls={INLINE_STYLES}
                    disabled={disabled}
                    editorState={editorState}
                    onToggle={onToggleInlineStyle}
                />

                <Divider
                    className={classes.controlDivisor}
                    orientation="vertical"
                    flexItem
                />

                <BlockStyleControls
                    controls={BLOCK_TYPES}
                    disabled={disabled}
                    editorState={editorState}
                    onToggle={onToggleBlockType}
                />
            </div>
            <div
                className={className}
                style={{
                    minHeight: 27 * (rows || minRows),
                    maxHeight: 27 * (rows || minRows),
                    display: 'flex',
                }}
            >
                <Editor
                    editorKey="richTextEditor"
                    blockRenderMap={blockRenderMap}
                    blockRendererFn={NerusBlockRenderer}
                    blockStyleFn={getBlockStyle}
                    customStyleMap={styleMap}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    keyBindingFn={mapKeyToEditorCommand}
                    onChange={onChange}
                    readOnly={disabled}
                    ref={editor}
                    spellCheck={true}
                    data-testid={props['data-testid']}
                />
            </div>
        </div>
    )
}

RichText.propTypes = {
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    value: PropTypes.string,
    inputRef: PropTypes.func,
    onChange: PropTypes.func,
    frameworkprops: PropTypes.any,
    'data-testid': PropTypes.string,
}

export default RichText
