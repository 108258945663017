export const styles = theme => ({
    root: {
        fontSize: theme.spacing(2),
        lineHeight: 1,
        margin: `0 ${theme.spacing(0.5)}px 0 0`,
        [theme.breakpoints.down('lg')]: {
            fontSize: theme.spacing(1.75),
        },
        [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(1.5),
        },
    },
    small: {
        fontSize: theme.spacing(1.5),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(1.25),
        },
    },
    bold: {
        fontWeight: 'bold',
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
    info: {
        color: theme.palette.blue.main,
    },
    warn: {
        color: theme.palette.orange.main,
    },
    success: {
        color: theme.palette.primaryAction.main,
    },
    description: {
        opacity: 0.4,
    },
})
