import StyledToggle from '@nerus/framework/styled/Toggle'
import * as PropTypes from 'prop-types'
import React from 'react'

import { sendBuffer } from '../../../../Eac/EacActions'
import AbstractTextField from './AbstractTextField'

export class Toogle extends AbstractTextField {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        value: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
        widths: PropTypes.object,
        column: PropTypes.number,
        ws: PropTypes.object.isRequired,
    }

    processValueToToggle = value => {
        return value === '1' || value === 'S' || value === true
    }

    processValueToWs = value => {
        return value ? 'S' : 'N'
    }

    constructor(props) {
        super(props)
        this.state = {
            beforeSend: texto => {
                return this.processValueToWs(texto)
            },
        }
    }

    handleChange = (event, value) => {
        this.setState(
            {
                value,
            },
            () => {
                this.props.ws.send(
                    sendBuffer(
                        {
                            x: this.props.x,
                            y: this.props.y,
                            key: this.processValueToWs(this.state.value),
                        },
                        'sendEdit'
                    )
                )
            }
        )
    }

    render() {
        const { labelWidth, value, lbl, enabled, index } = this.props
        return (
            <StyledToggle
                label={lbl}
                labelWidth={labelWidth}
                ref={this.createRef('input')}
                value={true}
                checked={this.processValueToToggle(value)}
                onChange={this.handleChange}
                disabled={!enabled}
                id={'field-' + index}
                tabIndex={index}
            />
        )
    }
}

export default Toogle
