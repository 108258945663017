import withStyles from '@material-ui/core/styles/withStyles'
import keycodes from '@nerus/framework/common/Keycodes'
import StyledButton from '@nerus/framework/styled/Button'
import Label from '@nerus/framework/styled/Label'
import { styles } from '@nerus/styles/components/formulario'
import clsx from 'clsx'
import toCamelCase from 'lodash/camelCase'
import PropTypes from 'prop-types'
import React from 'react'

import { sendBuffer } from '../../../../Eac/EacActions'
import AbstractTextField from './AbstractTextField'

/**
 * Componente TextFieldPrompt
 *
 * Rec: REC_PROMPT
 *
 * Renderizamos um botão, quando agrupado, viram abas
 */
export class TextFieldPrompt extends AbstractTextField {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        ws: PropTypes.object.isRequired,
    }

    static withoutWrapper = true

    handleClick = e => {
        e.stopPropagation()
        e.preventDefault()

        if (e.detail === 1) {
            this.props.ws.send(
                sendBuffer({
                    x: this.props.x,
                    y: this.props.y,
                    key: keycodes.ENTER_KEY,
                })
            )
        }
    }

    render() {
        const { lbl, active, enabled, index, classes, onClick } = this.props
        const opts = {
            className: active ? classes.buttonTab : '',
            tab: 'new',
            variant: active ? 'roundedTab' : 'default',
            disabled: !enabled,
            id: `${index}-${toCamelCase(lbl)}`,
            onClick: this.handleClick,
            active: enabled || active,
        }

        return (
            <span
                className={clsx(classes.buttonWrapper, {
                    active: enabled || active,
                })}
                onClick={onClick}
            >
                <StyledButton {...opts}>
                    <Label text={lbl?.trim?.() || lbl} noDots />
                </StyledButton>
            </span>
        )
    }
}

export default withStyles(styles)(TextFieldPrompt)
