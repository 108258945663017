import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'

const GET_PICTURES = `
    findPicture(prdno: $prdno, fkno: $fkno, custno: $custno, asrmano: $asrmano, prdSerie: $prdSerie, grade: $grade, table: $table, type: $type, showDefault: $showDefault, pictureTablesMap: $pictureTablesMap) {
        seqno
        seqnoAuto
        prdno
        fkno
        custno
        asrmano
        prdSerie
        grade
        prdseq
        foto
        urlImagem
    }
`

const GET_GRADES = `
    findGradePicture(prdno: $prdno, fkno: $fkno, table: $table, pictureTablesMap: $pictureTablesMap) {
        gradeno
        title
        rangeGrade2
        count
        description
    }
`

export const GET_PICTURE = gql`
    query GetPicture($prdno: String, $fkno: Int, $custno: Int, $asrmano: Int, $prdSerie: String, $grade: String, $table: String, $type: Boolean, $showDefault: Boolean, $pictureTablesMap: Any) {
        ${GET_PICTURES}
        ${GET_GRADES}
    }
`

export default Component => {
    return graphql(GET_PICTURE, {
        name: 'getPicture',
        fetchPolicy: 'cache-and-network',
        skip: props =>
            (props.prdno === undefined || props.prdno === '') &&
            (props.fkno === undefined || props.fkno === '') &&
            (props.custno === undefined || props.custno === '') &&
            !props.pictureTablesMap,
    })(Component)
}
