/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
import { useQuery } from '@apollo/react-hooks'
import { Box, Collapse, IconButton, Link, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/styles'
import keycodes from '@nerus/framework/common/Keycodes'
import BaseFlutuante from '@nerus/framework/components/Base/Flutuante'
import DialogoProgressao from '@nerus/framework/components/DialogoProgressao'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import { useO2 } from '@nerus/framework/hooks/useO2'
import Button from '@nerus/framework/styled/Button'
import { Input } from '@nerus/framework/styled/Input'
import Label from '@nerus/framework/styled/Label'
import StyledToggle from '@nerus/framework/styled/Toggle'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import {
    buildEmailTemplate,
    buildSQLQueryTemplate,
    convertDecToBin,
} from '../../../util'
import { isAdmin } from '../../App/AppActions'
import StringMultilineField from '../../NerusWeb/Components/Formulario/Estrutura/Recs/StringMultiline'
import { GET_ME } from '../../NerusWeb/Components/TopBar/UserMenu'
import { getNerusInfo } from '../../NerusWeb/Eac/EacReducer'
import NerusITLayout from '../components/common/Layout'
import GenericMessage from '../Dashboard/Dialogs/GenericMessage'
import {
    daysOfMonth,
    daysOfWeek,
    enoticeOperator,
    enoticeSendCondition,
    monthsOfYear,
    newEnotice,
} from './constants'
import useCreateEnotice from './hooks/useCreateEnotice'
import useFetchEnotice from './hooks/useFetchEnotice'
import useRawQuery from './hooks/useRawQuery'
import useUpdateEnotice from './hooks/useUpdateEnotice'
// import { hydrate, render } from 'react-dom'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
    },
    container: {
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
    },
    content: {
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    generalBox: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: 8,
        justifyItems: 'flex-start',
        paddingBottom: 16,
    },
    valueBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 8,
    },
    optionsBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 8,
    },
    rootToggle: {
        display: 'flex',
    },
    toggleBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxHeight: '320px',
        minWidth: '30%',
    },
    toggleBoxDaysMonth: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxHeight: '320px',
        minWidth: '40%',
    },
    headerToggleBox: {
        display: 'flex',
    },
    bodyToggleBox: {
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
    },
    bodyMonthToggleBox: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 8,
    },
    expand: {
        padding: 0,
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapse: {
        background: 'white',
        border: '1px solid #b11e27',
    },
    collapseHeader: {
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        cursor: 'pointer',
        padding: 16,
    },
    collapseBody: {
        padding: '0px 32px',
        transition: 'padding 1s',
    },
    collapseBodyOpen: {
        padding: '0px 32px 32px',
    },
    collapseHeaderTitle: {
        margin: '0px 0px 0px 8px',
    },
    inputHidden: {
        opacity: 0,
        height: 0,
        overflow: 'hidden',
        transition: 'opacity .5s ease-out',
    },
    inputShow: {
        opacity: 1,
        height: 'auto',
        transition: 'opacity .5s ease-in',
    },
    linkButtonBox: {
        display: 'flex',
        gap: 8,
        justifyContent: 'flex-end',
        width: '100%',
    },
    linkBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 8,
        width: '100%',
    },
    link: {
        color: 'rgb(124, 21, 28)',
    },
    label: {
        color: 'rgb(124, 21, 28)',
        fontWeight: 'bold',
        fontSize: '14px',
        width: '88px',
    },
}))

const Toggle = ({ status, onChange, ...props }) => {
    const classes = useStyles()

    return (
        <StyledToggle
            className={classes.toggle}
            InputProps={{}}
            autoFocus={true}
            disabled={false}
            orientation={'horizontal'}
            checked={Boolean(status)}
            onChange={() => onChange()}
            {...props}
        />
    )
}

Toggle.propTypes = {
    status: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}

const TimeInput = ({ disabled, onChange, value }) => {
    let mask = '12:34'
    let formatChars = {
        '1': '[0-2]',
        '2': '[0-9]',
        '3': '[0-5]',
        '4': '[0-9]',
    }

    const beforeMaskedValueChange = newState => {
        let { value } = newState

        // Conditional mask for the 2nd digit base on the first digit
        if (value.startsWith('2')) formatChars['2'] = '[0-3]'
        // To block 24, 25, etc.
        else formatChars['2'] = '[0-9]' // To allow 05, 12, etc.

        return { value, selection: newState.selection }
    }

    return (
        <InputMask
            mask={mask}
            value={value}
            maskChar={''}
            placeholder="HH:mm"
            disabled={disabled}
            testid="inputHorario"
            onChange={onChange}
            formatChars={formatChars}
            beforeMaskedValueChange={beforeMaskedValueChange}
        >
            {inputProps => (
                <Input
                    {...inputProps}
                    name={'horario'}
                    componentId={1}
                    isTitle={false}
                    attrib={12}
                    label={'Horário'}
                    labelWidth={64}
                    inputWidth={256}
                    orientation={'horizontal'}
                    InputProps={{
                        id: 'horario',
                    }}
                    InputLabelProps={{}}
                />
            )}
        </InputMask>
    )
}

TimeInput.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
}

/**
 * Componente EnoticeCreate
 */

function EnoticeCreate({ match: { params } }) {
    const { enotno } = params

    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()

    const nerusInfo = useSelector(getNerusInfo)
    const userno = useSelector(state => state.eac.NerusInfo?.usrno)
    const admin = useSelector(state => state.app.isAdmin)

    // const uploadBtn = createRef()
    const [showModal, setShowModal] = useState('')
    const [showSQLModal, setShowSQLModal] = useState('')
    const [SQLModal, setSQLModal] = useState('')
    const [showSQLResultModal, setShowSQLResultModal] = useState('')
    const [showEmailPreviewModal, setShowEmailPreviewModal] = useState('')
    const [enoticeMessage, setEnoticeMessage] = useState('')
    const [expandedGeneral, setExpandedGeneral] = useState(Boolean(enotno))
    const [expandedOptions, setExpandedOptions] = useState(false)
    const [expandedValues, setExpandedValues] = useState(false)
    const [showCcInput, setShowCcInput] = useState(Boolean(enotno))
    const [showCcoInput, setShowCcoInput] = useState(Boolean(enotno))
    const [SQLResultModal, setSQLResultModal] = useState('')
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState(
        new Array(daysOfWeek.length).fill(0)
    )
    const [selectedMonthsOfYear, setSelectedMonthsOfYear] = useState(
        new Array(monthsOfYear.length).fill(0)
    )
    const [selectedDaysOfMonth, setSelectedDaysOfMonth] = useState(
        new Array(daysOfMonth.length).fill(0)
    )
    const [selectedEnoticeOperator, setSelectedEnoticeOperator] = useState(0)
    const [
        selectedEnoticeSendCondition,
        setSelectedEnoticeSendCondition,
    ] = useState(0)
    const [enoticeValue, setEnoticeValue] = useState('')

    const [execSqlType, setExecSqlType] = useState(true) // true means exec sql button, false means exec email preview

    const { loading, data } = useFetchEnotice({
        variables: {
            enotno: Number(enotno),
        },
        skip: !enotno,
    })
    const fetchedEnotice = useMemo(
        () => data?.findEnoticeById?.data || { ...newEnotice, userno },
        [data?.findEnoticeById?.data, userno]
    )

    const [enotice, setEnotice] = useState(fetchedEnotice)

    const { isO2 } = useO2(true)

    const { createEnotice } = useCreateEnotice(enotice)
    const [updateEnotice] = useUpdateEnotice(enotice)

    const [
        execQuery,
        { loading: loadingRawQuery, data: dataRawQuery },
    ] = useRawQuery()
    const { data: { me = null } = {} } = useQuery(GET_ME, {
        variables: { usrno: nerusInfo?.usrno },
        skip: !nerusInfo || !nerusInfo.usrno,
    })

    const handleEnoticeChange = useCallback(event => {
        const { value, name } = event.target

        setEnotice(e => ({
            ...e,
            [name]: value,
        }))
    }, [])

    useEffect(() => {
        if (me?.isAdmin) {
            dispatch(isAdmin())
        }
    }, [me, dispatch])

    useEffect(() => {
        Reflect.deleteProperty(fetchedEnotice, '__typename')
        Reflect.deleteProperty(fetchedEnotice, 'i1')
        Reflect.deleteProperty(fetchedEnotice, 'i2')
        Reflect.deleteProperty(fetchedEnotice, 'i3')

        setEnoticeMessage(fetchedEnotice.message)

        setEnotice(fetchedEnotice)

        if (fetchedEnotice.agendaDia) {
            setSelectedDaysOfWeek([
                ...convertDecToBin(fetchedEnotice.agendaDia, daysOfWeek.length)
                    .split('')
                    .map(Number),
            ])
        }

        if (fetchedEnotice.agendaMes) {
            setSelectedMonthsOfYear([
                ...convertDecToBin(
                    fetchedEnotice.agendaMes,
                    monthsOfYear.length
                )
                    .split('')
                    .map(Number),
            ])
        }

        if (fetchedEnotice.agendaDiaDoMes) {
            setSelectedDaysOfMonth([
                ...convertDecToBin(
                    fetchedEnotice.agendaDiaDoMes,
                    daysOfMonth.length
                )
                    .split('')
                    .map(Number),
            ])
        }

        fetchedEnotice.operator &&
            setSelectedEnoticeOperator(fetchedEnotice.operator)

        fetchedEnotice.envio &&
            setSelectedEnoticeSendCondition(fetchedEnotice.envio)
    }, [fetchedEnotice])

    const queryErrorFormatter = (error, message) => {
        let errorMessage = message
        if (error && message) {
            message === '(intermediate value) is not iterable'
                ? (errorMessage = 'Consulta (query) estava vazia!')
                : null

            message.split('-')[1]
                ? (errorMessage = message.split('-')[1])
                : null

            return [{ Erro: `${errorMessage}` }]
        }
        return [{ Erro: 'Ocorreu um erro.' }]
    }

    const handleExpandClick = useCallback((state, setState) => {
        setState(!state)
    }, [])

    const doSave = useCallback(async () => {
        try {
            const response = await createEnotice()

            if (!response?.data?.createEnotice?.error) {
                history.push(`${isO2 ? '/bin/o2' : ''}/enotice`)
            } else {
                throw response?.data?.createEnotice
            }
        } catch (error) {
            setShowModal(
                error.networkError?.result?.errors
                    ? error.networkError.result.errors[0].message
                    : error.message || error?.graphQLErrors[0].message
            )
        }
    }, [createEnotice, history, isO2])

    const doUpdate = useCallback(async () => {
        try {
            await updateEnotice({
                variables: {
                    enotice,
                },
            })

            history.push(`${isO2 ? '/bin/o2' : ''}/enotice`)
        } catch (error) {
            setShowModal(
                error.networkError?.result?.errors
                    ? error.networkError.result.errors[0].message
                    : error.graphQLErrors[0].message || error.message
            )
        }
    }, [enotice, history, isO2, updateEnotice])

    const onSave = useCallback(() => {
        if (!enotice.title) {
            setShowModal('O título do enotice é obrigatório.')
        } else {
            if (enotice.enotno) {
                doUpdate()
            } else {
                doSave()
            }
        }
    }, [doSave, doUpdate, enotice.enotno, enotice.title])

    const onKeyDown = useCallback(
        event => {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                event.stopImmediatePropagation()

                if (event.defaultPrevented) {
                    return
                }

                if (showModal) {
                    setShowModal('')
                } else if (showSQLModal) {
                    setShowSQLModal('')
                } else if (showSQLResultModal) {
                    setShowSQLResultModal('')
                } else if (showEmailPreviewModal) {
                    setShowEmailPreviewModal('')
                } else {
                    history.push(`${isO2 ? '/bin/o2' : ''}/enotice`)
                }
            }
        },
        [
            showModal,
            showSQLModal,
            showSQLResultModal,
            showEmailPreviewModal,
            history,
            isO2,
        ]
    )

    useKeyboardTrap(onKeyDown)

    /*
    const onUploadClick = () => {
        uploadBtn.current.click()
    }
    

    const doUpload = e => {
        if (!e.target.files?.length) {
            return
        }

        e.persist()

        const files = [...Array.from(e.target.files)]
        this.setState({ loading: true }, () => {
            // eslint-disable-next-line no-undef
            let prom = Promise.resolve()
            files.forEach(selectedFile => {
                const promise = () =>
                    // eslint-disable-next-line no-undef
                    new Promise(resolve => {
                        const reader = new FileReader()
                        reader.readAsDataURL(selectedFile)
                        reader.onloadend = () => {
                            // transformar arquivos em base64??

                            // let keys = {}

                            return this.props
                                .createPicture({
                                    variables,
                                })
                                .then(response =>
                                    resolve(response.data.createPicture)
                                )
                                .catch(() => {
                                    return null
                                })
                        }
                    })

                prom = prom.then(promise)
            })
        })
    }
    */

    const hideModal = useCallback(setState => {
        setState('')
    }, [])

    const execSql = useCallback(
        sql => {
            execQuery({ variables: { sql } })
            setExecSqlType(true)
        },
        [execQuery]
    )

    const execEmailPreview = useCallback(() => {
        execQuery({ variables: { sql: enotice.value1 } })
        setExecSqlType(false)
    }, [execQuery, enotice.value1])

    useEffect(() => {
        if (dataRawQuery && !loadingRawQuery) {
            const {
                rawQueryEnotice: { data = [], error, message },
            } = dataRawQuery

            if (execSqlType) {
                setSQLResultModal(data || queryErrorFormatter(error, message))
                setShowSQLResultModal(true)
            } else {
                const [firstColumn] = data
                const [propToRemove] = Object.keys(firstColumn)
                const dataFormatted = data.map(value => {
                    Reflect.deleteProperty(value, propToRemove)

                    return { ...value }
                })

                setEnoticeValue(dataFormatted)
                setShowEmailPreviewModal(true)
            }
        }
    }, [dataRawQuery, loadingRawQuery])

    /*
        Generic function
        selected -> atual state
        setSelected -> state handle
        name -> input/attr name
    */
    const handleChangeToggle = useCallback(
        (selected, setSelected, name, index) => {
            let newState

            if (index != undefined) {
                // marca ou desmarca um
                newState = [
                    ...selected.map((curr, i) =>
                        Number(i == index ? !curr : curr)
                    ),
                ]
            } else {
                // marca ou desmarca todos
                const checkeds = selected.filter(Boolean).length
                const isAllChecked =
                    checkeds > 0 && checkeds === selected.length

                newState = new Array(selected.length).fill(
                    Number(!isAllChecked)
                )
            }

            setSelected(newState)

            handleEnoticeChange({
                target: {
                    name,
                    value: parseInt(newState.join(''), 2),
                },
            })
        },
        [handleEnoticeChange]
    )

    const buildToggles = useCallback(
        (toggles, label, state, setState, labelWidth = 64) =>
            toggles.map(({ id, name }) => (
                <Toggle
                    testid={`${label}-${id}`}
                    key={id}
                    value={id}
                    status={Boolean(+state[id])}
                    onChange={() =>
                        handleChangeToggle(state, setState, label, id)
                    }
                    labelPlacement={'end'}
                    label={name}
                    labelWidth={labelWidth}
                />
            )),
        [handleChangeToggle]
    )

    const weekToggles = useMemo(
        () =>
            buildToggles(
                daysOfWeek,
                'agendaDia',
                selectedDaysOfWeek,
                setSelectedDaysOfWeek,
                64 // labelWidth
            ),
        [selectedDaysOfWeek, buildToggles]
    )

    const monthToggles = useMemo(
        () =>
            buildToggles(
                monthsOfYear,
                'agendaMes',
                selectedMonthsOfYear,
                setSelectedMonthsOfYear,
                64 // labelWidth
            ),
        [selectedMonthsOfYear, buildToggles]
    )

    const dayMonthToggles = useMemo(
        () =>
            buildToggles(
                daysOfMonth,
                'agendaDiaDoMes',
                selectedDaysOfMonth,
                setSelectedDaysOfMonth,
                48 // labelWidth
            ),
        [selectedDaysOfMonth, buildToggles]
    )

    const handleChangeTag = useCallback(
        (setState, name, value) => {
            setState(value)

            handleEnoticeChange({
                target: {
                    name,
                    value,
                },
            })
        },
        [handleEnoticeChange]
    )

    const buildTags = useCallback(
        (tags, label, state, setState) =>
            tags.map(({ id, name }) => (
                <Button
                    key={id}
                    data-testid={`${label}-${id}`}
                    variant={'rounded'}
                    lbl={name}
                    dialog
                    color={state === id ? 'success' : 'none'}
                    style={
                        state === id
                            ? {
                                  backgroundColor: 'rgb(124, 21, 28)',
                              }
                            : undefined
                    }
                    onClick={() => {
                        if (state !== id) {
                            handleChangeTag(setState, label, id)
                        }
                    }}
                />
            )),
        [handleChangeTag]
    )

    const buildTagBox = useCallback(
        (tagType, label) => (
            <Box
                className={classes.valueBox}
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Label text={label} className={classes.label} />
                {tagType}
            </Box>
        ),
        [classes.label, classes.valueBox]
    )

    const enoticeOperatorTags = useMemo(
        () =>
            buildTags(
                enoticeOperator,
                'operator',
                selectedEnoticeOperator,
                setSelectedEnoticeOperator
            ),
        [selectedEnoticeOperator, buildTags]
    )

    const enoticeSendConditionTags = useMemo(
        () =>
            buildTags(
                enoticeSendCondition,
                'envio',
                selectedEnoticeSendCondition,
                setSelectedEnoticeSendCondition
            ),
        [selectedEnoticeSendCondition, buildTags]
    )

    return admin ? (
        <NerusITLayout onBack={history.goBack}>
            {loading ? (
                <>
                    <BaseFlutuante size="small" title={'Editar Enotice'}>
                        <DialogoProgressao
                            data={{
                                msg1: '',
                                msg2: 'Carregando informações do enotice...',
                            }}
                        />
                    </BaseFlutuante>
                </>
            ) : null}

            <div className={classes.root}>
                <Box className={classes.container}>
                    <Box className={classes.content}>
                        <Box className={classes.collapse}>
                            <Box
                                className={classes.collapseHeader}
                                onClick={() => {
                                    handleExpandClick(
                                        expandedGeneral,
                                        setExpandedGeneral
                                    )
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    className={classes.collapseHeaderTitle}
                                >
                                    Configurações gerais
                                </Typography>
                                <IconButton
                                    id={'configGerais'}
                                    data-testid="generalConfig"
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expandedGeneral,
                                    })}
                                    onClick={() => {
                                        handleExpandClick(
                                            expandedGeneral,
                                            setExpandedGeneral
                                        )
                                    }}
                                    aria-expanded={expandedGeneral}
                                    aria-label="Configurações gerais"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </Box>

                            <Box
                                className={clsx(classes.collapseBody, {
                                    [classes.collapseBodyOpen]: expandedGeneral,
                                })}
                            >
                                <Collapse in={expandedGeneral} timeout="auto">
                                    <Box className={classes.generalBox}>
                                        <Input
                                            testid="inputTitle"
                                            name={'title'}
                                            componentId={1}
                                            isTitle={false}
                                            attrib={12}
                                            label={'Título'}
                                            labelWidth={64}
                                            inputWidth={512}
                                            orientation={'horizontal'}
                                            InputProps={{
                                                id: 'titulo',
                                            }}
                                            InputLabelProps={{}}
                                            value={enotice.title}
                                            onChange={handleEnoticeChange}
                                        />

                                        <Toggle
                                            status={Boolean(
                                                enotice.inibeEmptyEnotice
                                            )}
                                            onChange={() => {
                                                setEnotice(e => ({
                                                    ...e,
                                                    inibeEmptyEnotice: Number(
                                                        !e.inibeEmptyEnotice
                                                    ),
                                                }))
                                            }}
                                            testid="toggleEmptyEnotice"
                                            label="Inibe envio de e-notice vazio"
                                            labelPlacement={'end'}
                                        />

                                        <TimeInput
                                            disabled={Boolean(!enotice.agenda)}
                                            onChange={handleEnoticeChange}
                                            value={
                                                enotice.agenda
                                                    ? enotice.horario
                                                    : ''
                                            }
                                        />

                                        <Toggle
                                            testid="toggleAgenda"
                                            id={'toggleAgenda'}
                                            status={Boolean(enotice.agenda)}
                                            onChange={() => {
                                                setEnotice(e => ({
                                                    ...e,
                                                    agenda: Number(!e.agenda),
                                                    value2: '',
                                                    grupo: 0,
                                                }))
                                            }}
                                            labelPlacement={'end'}
                                            label="E-notice com hora agendada"
                                        />
                                    </Box>

                                    <Box className={classes.rootToggle}>
                                        <Box className={classes.toggleBox}>
                                            <Box
                                                className={
                                                    classes.headerToggleBox
                                                }
                                            >
                                                <Toggle
                                                    id={'toggleAllDaysOfWeek'}
                                                    testid="toggleAllDaysOfWeek"
                                                    status={
                                                        selectedDaysOfWeek.filter(
                                                            Number
                                                        ).length ===
                                                        daysOfWeek.length
                                                    }
                                                    onChange={() => {
                                                        handleChangeToggle(
                                                            selectedDaysOfWeek,
                                                            setSelectedDaysOfWeek,
                                                            'agendaDia'
                                                        )
                                                    }}
                                                    labelPlacement={'end'}
                                                    label={'Dias da semana'}
                                                    labelWidth={128}
                                                />
                                            </Box>
                                            <Box
                                                className={
                                                    classes.bodyToggleBox
                                                }
                                            >
                                                {weekToggles}
                                            </Box>
                                        </Box>

                                        <Box className={classes.toggleBox}>
                                            <Box
                                                className={
                                                    classes.headerToggleBox
                                                }
                                            >
                                                <Toggle
                                                    id={'toggleAllMonthsOfYear'}
                                                    testid="toggleAllMonthsOfYear"
                                                    status={
                                                        selectedMonthsOfYear.filter(
                                                            Number
                                                        ).length ===
                                                        monthsOfYear.length
                                                    }
                                                    onChange={() => {
                                                        handleChangeToggle(
                                                            selectedMonthsOfYear,
                                                            setSelectedMonthsOfYear,
                                                            'agendaMes'
                                                        )
                                                    }}
                                                    labelPlacement={'end'}
                                                    label={'Meses do ano'}
                                                    labelWidth={128}
                                                />
                                            </Box>
                                            <Box
                                                className={
                                                    classes.bodyToggleBox
                                                }
                                            >
                                                {monthToggles}
                                            </Box>
                                        </Box>

                                        <Box
                                            className={
                                                classes.toggleBoxDaysMonth
                                            }
                                        >
                                            <Box
                                                className={
                                                    classes.headerToggleBox
                                                }
                                            >
                                                <Toggle
                                                    id={'toggleAllDaysOfMonth'}
                                                    testid="toggleAllDaysOfMonth"
                                                    status={
                                                        selectedDaysOfMonth.filter(
                                                            Number
                                                        ).length ===
                                                        daysOfMonth.length
                                                    }
                                                    onChange={() => {
                                                        handleChangeToggle(
                                                            selectedDaysOfMonth,
                                                            setSelectedDaysOfMonth,
                                                            'agendaDiaDoMes'
                                                        )
                                                    }}
                                                    labelPlacement={'end'}
                                                    label={'Dias do mês'}
                                                    labelWidth={128}
                                                />
                                            </Box>

                                            <Box
                                                className={
                                                    classes.bodyMonthToggleBox
                                                }
                                            >
                                                {dayMonthToggles}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Collapse>
                            </Box>
                        </Box>

                        <Box className={classes.collapse}>
                            <Box
                                className={classes.collapseHeader}
                                onClick={() => {
                                    handleExpandClick(
                                        expandedValues,
                                        setExpandedValues
                                    )
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    className={classes.collapseHeaderTitle}
                                >
                                    Configurações de valores
                                </Typography>
                                <IconButton
                                    id={'valuesConfig'}
                                    data-testid="valuesConfig"
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expandedValues,
                                    })}
                                    onClick={() => {
                                        handleExpandClick(
                                            expandedValues,
                                            setExpandedValues
                                        )
                                    }}
                                    aria-expanded={expandedValues}
                                    aria-label="Configurações de valores"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </Box>

                            <Box
                                className={clsx(classes.collapseBody, {
                                    [classes.collapseBodyOpen]: expandedValues,
                                })}
                            >
                                <Collapse in={expandedValues} timeout="auto">
                                    <Box className={classes.valueBox}>
                                        <Input
                                            testid="inputValue1"
                                            style={{
                                                width: '100%',
                                            }}
                                            inputStyle={{
                                                height: enotice.agenda
                                                    ? 450
                                                    : 250,
                                            }}
                                            componentId={1}
                                            attrib={12}
                                            label={
                                                enotice.agenda
                                                    ? 'Comando SQL'
                                                    : 'Valor 1'
                                            }
                                            labelWidth={88}
                                            inputWidth={'100%'}
                                            orientation={'horizontal'}
                                            InputProps={{
                                                id: 'inputValue1',
                                            }}
                                            InputLabelProps={{
                                                inputcomponent: 'textarea',
                                            }}
                                            name={'value1'}
                                            onChange={handleEnoticeChange}
                                            value={enotice.value1}
                                            multiline
                                            className="input-form"
                                            minRows={30}
                                            helperText="* Para pular uma linha, use <SHIFT> + <ENTER>"
                                            clickToTrackCaret={true}
                                            overwrite={false}
                                        />

                                        <Button
                                            style={{ alignSelf: 'flex-end' }}
                                            data-testid="buttonExecSql1"
                                            variant={'rounded'}
                                            lbl={'Executar SQL'}
                                            dialog
                                            onClick={() =>
                                                execSql(enotice.value1)
                                            }
                                        />

                                        {enotice.agenda ? (
                                            <Toggle
                                                id={'toggleAgendaOcorrencia'}
                                                testid="toggleAgendaOcorrencia"
                                                status={Boolean(enotice.grupo)}
                                                label={
                                                    'Enviar e-notice para cada ocorrência encontrada'
                                                }
                                                onChange={() => {
                                                    setEnotice(e => ({
                                                        ...e,
                                                        grupo: Number(!e.grupo),
                                                    }))
                                                }}
                                                labelPlacement={'end'}
                                                labelWidth={128}
                                            />
                                        ) : (
                                            <>
                                                {buildTagBox(
                                                    enoticeOperatorTags,
                                                    'Comparador'
                                                )}
                                                <Input
                                                    testid="inputValue2"
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    inputStyle={{
                                                        height: 250,
                                                    }}
                                                    componentId={1}
                                                    attrib={12}
                                                    label={'Valor 2'}
                                                    labelWidth={88}
                                                    inputWidth={'100%'}
                                                    orientation={'horizontal'}
                                                    InputProps={{
                                                        id: 'inputValue2',
                                                        style: {},
                                                    }}
                                                    InputLabelProps={{
                                                        inputcomponent:
                                                            'textarea',
                                                    }}
                                                    name={'value2'}
                                                    onChange={
                                                        handleEnoticeChange
                                                    }
                                                    value={enotice.value2}
                                                    multiline
                                                    className="input-form"
                                                    minRows={30}
                                                    helperText="* Para pular uma linha, use <SHIFT> + <ENTER>"
                                                    clickToTrackCaret={true}
                                                    overwrite={false}
                                                />
                                                <Button
                                                    style={{
                                                        alignSelf: 'flex-end',
                                                    }}
                                                    data-testid="buttonExecSql2"
                                                    variant={'rounded'}
                                                    lbl={'Executar SQL'}
                                                    dialog
                                                    onClick={() =>
                                                        execSql(enotice.value2)
                                                    }
                                                />
                                                {buildTagBox(
                                                    enoticeSendConditionTags,
                                                    'Enviar'
                                                )}
                                            </>
                                        )}
                                    </Box>
                                </Collapse>
                            </Box>
                        </Box>

                        <Box className={classes.collapse}>
                            <Box
                                className={classes.collapseHeader}
                                onClick={() => {
                                    handleExpandClick(
                                        expandedOptions,
                                        setExpandedOptions
                                    )
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    className={classes.collapseHeaderTitle}
                                >
                                    Configurações de opções
                                </Typography>
                                <IconButton
                                    id={'optionsConfig'}
                                    data-testid="optionsConfig"
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expandedOptions,
                                    })}
                                    onClick={() => {
                                        handleExpandClick(
                                            expandedOptions,
                                            setExpandedOptions
                                        )
                                    }}
                                    aria-expanded={expandedOptions}
                                    aria-label="Configurações de opções"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </Box>
                            <Box
                                className={clsx(classes.collapseBody, {
                                    [classes.collapseBodyOpen]: expandedOptions,
                                })}
                            >
                                <Collapse in={expandedOptions} timeout="auto">
                                    <Box className={classes.optionsBox}>
                                        <Input
                                            testid="inputSend"
                                            style={{
                                                width: '100%',
                                            }}
                                            componentId={1}
                                            isTitle={false}
                                            attrib={12}
                                            label={'Para'}
                                            labelWidth={64}
                                            inputWidth={'100%'}
                                            orientation={'horizontal'}
                                            helperText={
                                                '* Insira os e-mails separando os por vírgula'
                                            }
                                            InputProps={{
                                                id: 'para',
                                            }}
                                            InputLabelProps={{}}
                                            value={
                                                enotice.grupo
                                                    ? ''
                                                    : enotice.send
                                            }
                                            onChange={event => {
                                                const { value } = event.target

                                                const toSql = Number(
                                                    /sql:/gim.test(value)
                                                )
                                                const send = value

                                                setEnotice(e => ({
                                                    ...e,
                                                    toSql,
                                                    send,
                                                }))
                                            }}
                                            name={'send'}
                                            disabled={Boolean(enotice.grupo)}
                                        />
                                        <Box className={classes.linkButtonBox}>
                                            <Link
                                                data-testid="buttonCc"
                                                onClick={() => {
                                                    setShowCcInput(!showCcInput)
                                                }}
                                                component="button"
                                                underline={'always'}
                                                className={clsx(
                                                    classes.link,
                                                    {
                                                        [classes.inputShow]: !showCcInput,
                                                    },
                                                    {
                                                        [classes.inputHidden]: showCcInput,
                                                    }
                                                )}
                                            >
                                                {'Inserir CC'}
                                            </Link>
                                            <Link
                                                data-testid="buttonCco"
                                                onClick={() => {
                                                    setShowCcoInput(
                                                        !showCcoInput
                                                    )
                                                }}
                                                component="button"
                                                underline={'always'}
                                                className={clsx(
                                                    classes.link,
                                                    {
                                                        [classes.inputShow]: !showCcoInput,
                                                    },
                                                    {
                                                        [classes.inputHidden]: showCcoInput,
                                                    }
                                                )}
                                            >
                                                {'Inserir CCO'}
                                            </Link>
                                            {/*
                                            <Button
                                                variant={'rounded'}
                                                lbl={'Anexar Arquivos'}
                                                dialog
                                                onClick={onUploadClick}
                                            /> 
                                            

                                            <Fragment>
                                                <input
                                                    type="file"
                                                    multiple
                                                    accept="*"
                                                    ref={uploadBtn}
                                                    onChange={doUpload}
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                />
                                            </Fragment>
                                            */}

                                            <Button
                                                variant={'rounded'}
                                                lbl={'SQL'}
                                                dialog
                                                onClick={() => {
                                                    setShowSQLModal(
                                                        enotice.send || ' '
                                                    )
                                                    setSQLModal(enotice.send)
                                                }}
                                                disabled={Boolean(
                                                    enotice.grupo
                                                )}
                                            />
                                        </Box>
                                        {showCcInput || showCcoInput ? (
                                            <Box className={classes.linkBox}>
                                                {showCcInput ? (
                                                    <Input
                                                        testid="inputCc"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        componentId={1}
                                                        isTitle={false}
                                                        attrib={12}
                                                        label={'CC'}
                                                        labelWidth={64}
                                                        inputWidth={'100%'}
                                                        orientation={
                                                            'horizontal'
                                                        }
                                                        InputProps={{}}
                                                        InputLabelProps={{}}
                                                        value={enotice.cc}
                                                        className={
                                                            showCcInput
                                                                ? classes.inputShow
                                                                : classes.inputHidden
                                                        }
                                                        onChange={
                                                            handleEnoticeChange
                                                        }
                                                        name={'cc'}
                                                    />
                                                ) : null}

                                                {showCcoInput ? (
                                                    <Input
                                                        testid="inputCco"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        componentId={1}
                                                        isTitle={false}
                                                        attrib={12}
                                                        label={'CCO'}
                                                        labelWidth={64}
                                                        inputWidth={'100%'}
                                                        orientation={
                                                            'horizontal'
                                                        }
                                                        InputProps={{}}
                                                        InputLabelProps={{}}
                                                        value={enotice.bcc}
                                                        className={
                                                            showCcoInput
                                                                ? classes.inputShow
                                                                : classes.inputHidden
                                                        }
                                                        onChange={
                                                            handleEnoticeChange
                                                        }
                                                        name={'bcc'}
                                                    />
                                                ) : null}
                                            </Box>
                                        ) : null}
                                        <Input
                                            testid="inputSubject"
                                            style={{
                                                width: '100%',
                                            }}
                                            componentId={1}
                                            isTitle={false}
                                            attrib={12}
                                            label={'Assunto'}
                                            labelWidth={64}
                                            inputWidth={'100%'}
                                            orientation={'horizontal'}
                                            InputProps={{
                                                id: 'Assunto',
                                            }}
                                            InputLabelProps={{}}
                                            value={enotice.subject}
                                            onChange={handleEnoticeChange}
                                            name={'subject'}
                                        />
                                        <StringMultilineField
                                            id={'inputMessage'}
                                            testid="inputMessage"
                                            ln={256}
                                            lns={15}
                                            lsz={40}
                                            attrib={12}
                                            sz={10240}
                                            bt={[]}
                                            enabled={true}
                                            orientation={'horizontal'}
                                            lbl={'Mensagem'}
                                            tabIndex={1}
                                            clickToTrackCaret={false}
                                            overwrite={false}
                                            value={enoticeMessage}
                                            onChange={message => {
                                                setEnotice(e => ({
                                                    ...e,
                                                    message,
                                                }))
                                            }}
                                            disabled={false}
                                            componentId={1}
                                            labelWidth={64}
                                            inputWidth={'100%'}
                                            minRows={16}
                                            name={'message'}
                                            dispatch={() => {}}
                                            typeRec={'REC_STR_SCROLL'}
                                            manualfocus={true}
                                            style={{
                                                width: '100%',
                                                paddingTop: 16,
                                            }}
                                        />
                                    </Box>
                                    <div
                                        style={{
                                            textAlign: 'end',
                                            marginTop: '7px',
                                        }}
                                    >
                                        <Button
                                            data-testid="buttonEmailPreview"
                                            variant={'rounded'}
                                            lbl={'Visualizar email'}
                                            dialog
                                            onClick={() => execEmailPreview()}
                                        />
                                    </div>
                                </Collapse>
                            </Box>
                        </Box>

                        <div
                            style={{
                                textAlign: 'end',
                            }}
                        >
                            <Button
                                id={'cancelar'}
                                dialog
                                color="danger"
                                onClick={() => {
                                    history.goBack()
                                }}
                                data-testid="buttonCancelar"
                            >
                                Cancelar
                            </Button>
                            <Button
                                id={'confirmar'}
                                dialog
                                primary
                                disabled={false}
                                onClick={() => {
                                    onSave()
                                }}
                                data-testid="buttonConfirmar"
                            >
                                Confirmar
                            </Button>
                        </div>
                    </Box>
                </Box>
            </div>

            {showSQLResultModal ? (
                <BaseFlutuante
                    title={'Visualização de SQL'}
                    onClose={() => hideModal(setShowSQLResultModal)}
                >
                    <Box
                        className={classes.valueBox}
                        data-testid={'ExecSqlModal'}
                        style={{
                            width: '100%',
                            overflow: 'scroll',
                        }}
                        dangerouslySetInnerHTML={{
                            __html: buildSQLQueryTemplate(SQLResultModal),
                        }}
                    ></Box>
                </BaseFlutuante>
            ) : null}

            {showEmailPreviewModal ? (
                <BaseFlutuante
                    title={'Visualização de Email'}
                    onClose={() => hideModal(setShowEmailPreviewModal)}
                >
                    <Box
                        className={classes.valueBox}
                        style={{
                            width: '100%',
                            overflow: 'scroll',
                        }}
                        dangerouslySetInnerHTML={{
                            __html: buildEmailTemplate(
                                enotice.message,
                                enoticeValue
                            ),
                        }}
                    ></Box>
                </BaseFlutuante>
            ) : null}

            {showModal ? (
                <GenericMessage
                    onClose={() => hideModal(setShowModal)}
                    message={showModal}
                />
            ) : null}

            {showSQLModal ? (
                <BaseFlutuante
                    title={'Edição de SQL'}
                    onClose={() => hideModal(setShowSQLModal)}
                >
                    <Box>
                        <Typography variant="h6">
                            Digite sua consulta:
                        </Typography>

                        <Box className={classes.valueBox}>
                            <Input
                                style={{
                                    width: '100%',
                                }}
                                componentId={2}
                                attrib={12}
                                label={''}
                                labelWidth={64}
                                inputWidth={'100%'}
                                orientation={'horizontal'}
                                InputProps={{}}
                                InputLabelProps={{
                                    inputcomponent: 'textarea',
                                }}
                                value={SQLModal}
                                multiline
                                className="input-form"
                                minRows={20}
                                helperText="* Para pular uma linha, use <SHIFT> + <ENTER>"
                                clickToTrackCaret={true}
                                overwrite={false}
                                onChange={event => {
                                    const { value } = event.target
                                    setSQLModal(value)
                                }}
                            />
                        </Box>

                        {/*
                        <Box>
                              <Typography variant="h6">
                        Ajuda para montagem selects
                    </Typography> 
                            <Link
                                onClick={() => {
                                    ;<h1></h1>
                                }}
                                component="button"
                                underline={'always'}
                            >
                                {'Testar Consulta'}
                            </Link>
                            
                        </Box>
                        */}

                        <div
                            style={{
                                textAlign: 'end',
                            }}
                        >
                            <Button
                                dialog
                                color="danger"
                                onClick={() => {
                                    hideModal(setShowSQLModal)
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                dialog
                                primary
                                disabled={false}
                                onClick={() => {
                                    const toSql = Number(
                                        /sql:/gim.test(SQLModal)
                                    )
                                    const send = toSql ? SQLModal : enotice.send

                                    setEnotice(e => ({
                                        ...e,
                                        toSql,
                                        send,
                                    }))

                                    hideModal(setShowSQLModal)
                                }}
                            >
                                Confirmar
                            </Button>
                        </div>
                    </Box>
                </BaseFlutuante>
            ) : null}
        </NerusITLayout>
    ) : (
        <>Sem permissão</>
    )
}

EnoticeCreate.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            enotno: PropTypes.string,
        }),
    }),
}

export default EnoticeCreate
