import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/hint'
import PropTypes from 'prop-types'
import React from 'react'
import Draggable from 'react-draggable'

import EnhancedComponent from '../../common/EnhancedComponent'

class Hint extends EnhancedComponent {
    static propTypes = {
        hint: PropTypes.object,
        classes: PropTypes.object,
    }

    static baseOpts = {
        skip: true,
    }

    state = {}

    componentDidUpdate() {
        const activeElement = document.activeElement
            ? document.activeElement
            : false
        const hint = this.getRef('hint')

        if (
            hint &&
            activeElement &&
            ['input', 'select', 'button', 'li'].indexOf(
                activeElement.tagName.toLowerCase()
            ) > -1
        ) {
            const rect = activeElement.getBoundingClientRect()
            const top = rect.top - hint.offsetHeight - 8
            let left = rect.left
            if (left + hint.offsetWidth > window.innerWidth) {
                left = left - hint.offsetWidth + rect.width
            }

            if (this.state.left !== left && this.state.top !== top) {
                this.setState({ left, top, opacity: 1 })
            }
        } else {
            if (this.state.opacity !== 1) {
                this.setState({ opacity: 1 })
            }
        }
    }

    render() {
        const { left, top, opacity } = this.state
        const { classes, text } = this.props

        const dragHandlers = {
            onStart: this.onStart,
            onMouseDown: this.onStart,
            onStop: this.onStop,
        }

        return (
            <Draggable {...dragHandlers}>
                <div
                    ref={this.createRef('hint')}
                    className={classes.root}
                    style={{ left, top, opacity }}
                >
                    <div
                        className="content"
                        dangerouslySetInnerHTML={{
                            __html: text.replace(/\n/g, '<br />'),
                        }}
                    />

                    <div className={classes.handle} />
                </div>
            </Draggable>
        )
    }
}

export default withStyles(styles)(Hint)
