import AWS from 'aws-sdk'

import config from '../config'

const s3 = new AWS.S3()

const S3Helper = {
    delete: Key => {
        const params = {
            Bucket: config.files.aws.bucket,
            Key: Key,
        }
        return s3.deleteObject(params).promise()
    },
    upload: (Key, Body, ContentType = 'image/jpg') => {
        const params = {
            Bucket: config.files.aws.bucket,
            Key,
            Body,
            ContentType,
            CacheControl: 'max-age=172800',
            ACL: 'public-read',
        }

        return s3
            .upload(params)
            .promise()
            .then(value => {
                return value.Location
            })
            .catch(e => {
                console.error(`Falha no upload para o S3`, e)
                return null
            })
    },
}

export default S3Helper
