import InputAdornment from '@material-ui/core/InputAdornment'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import TextFieldIcon from '@material-ui/icons/TextFields'
import useActiveFieldNavigation from '@nerus/framework/hooks/useActiveFieldNavigation'
import usePreventDispatchToWS from '@nerus/framework/hooks/usePreventDispatchToWS'
import { styles } from '@nerus/styles/components/formulario/input'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import React from 'react'

import useAutoFocus from '../hooks/useAutoFocus'
import useClickToTrackCaret from '../hooks/useClickToTrackCaret'
import useDispatchToWS from '../hooks/useDispatchToWS'
import useOverwrite from '../hooks/useOverwrite'
import usePreventTyping from '../hooks/usePreventTyping'
import Label from './Label'

export const hookStyles = {
    vertical: makeStyles(styles.vertical),
    horizontal: makeStyles(styles.horizontal),
}

function InputComponent(props, propRef) {
    const {
        testid,
        InputProps = {},
        InputLabelProps = {},
        orientation = 'horizontal',
        maxLength = undefined,
        inputWidth,
        labelWidth,
        label,
        attrib,
        isTitle,
        onKeyDown, // eslint-disable-line
        // variáveis que não queremos que apareça no DOM
        componentId, // eslint-disable-line
        beforeSend, // eslint-disable-line
        fieldType, // eslint-disable-line
        dispatch, // eslint-disable-line
        // Hook switchers
        forceOverwrite = false,
        overwrite = false,
        overwriteOnFirst = false,
        dispatchToWs = false,
        autoFocus = true, // eslint-disable-line
        clickToTrackCaret = true, // eslint-disable-line
        preventTyping = true, // eslint-disable-line
        preventDispatchToWS = false, // eslint-disable-line
        autoFocusOnBlur = true, // eslint-disable-line
        name = undefined,
        inputStyle = {},
        ...otherProps
    } = props

    let ref = propRef
    if (typeof ref === 'function') {
        ref = propRef()
    }

    const classes = hookStyles[orientation]()
    useActiveFieldNavigation(ref, props, preventDispatchToWS)
    useAutoFocus(ref, props, autoFocus, autoFocusOnBlur)
    usePreventDispatchToWS(ref, props, preventDispatchToWS && !dispatchToWs)
    useClickToTrackCaret(ref, props, clickToTrackCaret)
    useDispatchToWS(ref, props, dispatchToWs && !preventDispatchToWS)
    const isOverwrite = useOverwrite(
        ref,
        { ...props, overwriteOnFirst, forceOverwrite },
        overwrite
    )
    usePreventTyping(ref, props, preventTyping)
    const isBigPrice = attrib === 11
    return (
        <TextField
            {...otherProps}
            classes={{
                root: clsx({
                    [classes.root]: true,
                    [classes.textareaRoot]: otherProps.multiline === true,
                }),
            }}
            inputProps={{
                ref,
                maxLength,
                size: maxLength,
                autoComplete: 'off',
                name,
                onKeyDown,
                frameworkprops: props,
                'data-testid': testid,
                style: inputStyle,
            }}
            InputProps={{
                ...InputProps,
                style: {
                    ...(InputProps.style || {}),
                    width: !isBigPrice ? inputWidth : undefined,
                },
                classes: {
                    root: clsx(classes.inputRoot, {
                        [classes.disabledBorderWrapper]:
                            props.disabled && !otherProps.multiline,
                    }),
                    input: clsx({
                        [classes.input]: true,
                        [classes.inputOverwrite]:
                            (isOverwrite || forceOverwrite) && !props.disabled,
                        [classes.disabled]: props.disabled,
                        [classes.enabled]: !props.disabled,
                        [classes.disableLines]: InputProps.disableUnderline,
                        [classes.bigPrice]: isBigPrice,
                    }),
                    inputMultiline: classes.inputMultiline,
                    formControl: clsx({
                        [classes.formControl]: true,
                        [classes.textareaFormControl]:
                            otherProps.multiline === true,
                        [classes.bigPriceFormControl]: isBigPrice,
                    }),
                },
                disableUnderline: true,
                endAdornment:
                    (isOverwrite || forceOverwrite) && !props.disabled ? (
                        <InputAdornment
                            position="start"
                            className={classes.adornmentOverwrite}
                        >
                            <TextFieldIcon className={classes.overwrite} />
                        </InputAdornment>
                    ) : (
                        InputProps.endAdornment
                    ),
            }}
            FormHelperTextProps={{
                component: 'span',
                classes: {
                    root: classes.helperText,
                },
            }}
            InputLabelProps={{
                ...InputLabelProps,
                style: {
                    // eslint-disable-next-line react/prop-types
                    ...(InputLabelProps.style || {}),
                    minWidth: labelWidth,
                },
                shrink: true,
                classes: {
                    root: clsx({
                        [classes.labelRoot]: true,
                        [classes.textareaLabel]: otherProps.multiline === true,
                        [classes.labelBold]: attrib && !isTitle,
                        [classes.doubleLineLabel]:
                            label.search && label.search('<br />') > 0,
                        [classes.bigPriceLabel]: isBigPrice,
                    }),
                },
            }}
            label={
                label ? (
                    label.trim ? (
                        <Label text={label.trim()} />
                    ) : (
                        label
                    )
                ) : null
            }
            autoComplete="off"
            disabled={isBigPrice || props.disabled}
        />
    )
}

export const Input = React.forwardRef(InputComponent)

InputComponent.propTypes = {
    InputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    beforeSend: PropTypes.func,
    fieldType: PropTypes.object,
    inputWidth: PropTypes.number,
    labelWidth: PropTypes.number,
    componentId: PropTypes.number,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    label: PropTypes.string,
    maxLength: PropTypes.number,
    attrib: PropTypes.number,
    isTitle: PropTypes.bool,
    dispatchToWs: PropTypes.bool,
    autoFocus: PropTypes.bool,
    autoFocusOnBlur: PropTypes.bool,
    overwrite: PropTypes.bool,
    overwriteOnFirst: PropTypes.bool,
    forceOverwrite: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    manualfocus: PropTypes.bool,
    testid: PropTypes.string,
}
