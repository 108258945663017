import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function LockIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0.012,19.2)">
                <path d="M 761,327 H 881 V -200 H 118 v 527 h 120 v 219 q 0,105 76.5,179.5 Q 391,800 499,800 608,800 684.5,725.5 761,651 761,546 Z M 323,546 V 327 h 352 v 219 q 0,71 -51.5,121 Q 572,717 499,717 426,717 374.5,667 323,617 323,546 Z M 543,48 q 38,23 38,67 0,33 -24,56.5 Q 533,195 499,195 465,195 441,171.5 417,148 417,115 417,72 456,48 V -67 h 87 z" />
            </g>
        </SvgIcon>
    )
}
