import CaseChange from './CaseChange'

class Alphanumeric extends CaseChange {
    restrictedRegex = /[^0-9a-zA-Z./-:@_]/g

    formatter(text) {
        const restricted = text.replace(this.restrictedRegex, '')
        return super.formatter(restricted)
    }

    hasModification(text) {
        return this.restrictedRegex.test(text)
    }
}

export default Alphanumeric
