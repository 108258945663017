import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export const getOptionValue = option =>
    typeof option === 'object' && option.value !== undefined
        ? option.value
        : option

export const getOptionLabel = option =>
    typeof option === 'object' && option.lbl !== undefined ? option.lbl : option

export const generateId = (option, i) => `${i}-${getOptionValue(option)}`

export class OptionItem extends Component {
    static propTypes = {
        options: PropTypes.array,
        selected: PropTypes.bool,
        onSelect: PropTypes.func,
        className: PropTypes.string,
        classNameActive: PropTypes.string,
        data: PropTypes.any,
    }

    constructor(props) {
        super(props)
        this.state = {
            selected: props.selected,
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextState.selected !== this.state.selected ||
            nextProps.selected !== this.props.selected
        )
    }

    onSelectItem = opt => () => {
        const { onSelect } = this.props
        this.setState(
            {
                selected: !this.state.selected,
            },
            () => {
                onSelect && onSelect(opt)
            }
        )
    }

    onClick = opt => e => {
        e.preventDefault()
        e.stopPropagation()

        const { onSelect } = this.props
        onSelect && onSelect(opt)
    }

    render() {
        const { data, selected, className, classNameActive } = this.props

        const opts = {
            className,
            onClick: this.onClick(data),
        }

        if (
            (selected === undefined && this.state.selected) ||
            (selected !== undefined && selected)
        ) {
            opts.className = clsx(className, classNameActive)
        }

        return (
            <li {...opts}>
                <span>{getOptionLabel(data)}</span>
                <IconButton onClick={opts.onClick}>
                    <AddIcon />
                </IconButton>
            </li>
        )
    }
}

export default OptionItem
