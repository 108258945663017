import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/dialogo'
import { connect } from 'react-redux'

import { withWS } from '../../Business/Websocket/Context'
import {
    getActiveComponent,
    getActiveElementIndex,
    getComponentById,
    getLastActiveComponent,
} from '../../Eac/EacReducer'
import BaseDialogo from './BaseDialogo'

export class Dialogo extends BaseDialogo {
    static baseOpts = {
        skip: true,
    }

    /**
     * Recupera os dados necessários para renderizar
     * a Dialogo e realizar suas ações
     */
    static mapStateToProps = (state, ownProps) => {
        const data = getComponentById(ownProps.id, state).payload
        return {
            data,
            activeElementIndex: getActiveElementIndex(state),
            lastActiveComponent: getLastActiveComponent(state),
            activeComponent: getActiveComponent(state),
            isLogged: state.eac.isLogged,
        }
    }

    render() {
        return BaseDialogo.prototype.render.call(this)
    }
}

export default withWS(
    connect(Dialogo.mapStateToProps)(withStyles(styles)(Dialogo))
)
