import { noop } from '@nerus/framework/util'
import { useCallback, useEffect } from 'react'

import keycodes, { functionKeys, navigationKeys } from '../common/Keycodes'

const debug = require('debug')('nerus:usePreventTyping')

const richTextEditorTypes = [
    'REC_STR_LINES',
    'REC_STR_LINES_BOX',
    'REC_STR_SCROLL',
    'REC_STR_SCROLL_BOX',
    'REC_STR_LINES_UPPER',
]
export default function usePreventTyping(ref, props, enabled = true) {
    const { disabled, onKeyDown, fieldType } = props

    const preventEvent = useCallback(
        e => {
            if (!enabled) {
                debug(`Hook is not enabled`)
                return
            }

            const isRichText =
                props.attrib === 12 &&
                richTextEditorTypes.includes(props.typeRec)
            if (isRichText && enabled && !disabled) {
                e.stopPropagation()
                return
            }

            if (
                navigationKeys.indexOf(e.keyCode) === -1 &&
                functionKeys.indexOf(e.keyCode) === -1 &&
                (!e.ctrlKey || !e.altKey)
            ) {
                // não vamos prevenir se for LEFT_ARROW ou RIGHT_ARROW
                // apenas se for DQTTY
                if (
                    !(
                        fieldType &&
                        fieldType.typeRec === 'REC_DQTTY' &&
                        [
                            keycodes.LEFT_ARROW_KEY,
                            keycodes.RIGHT_ARROW_KEY,
                        ].indexOf(e.keyCode) > -1
                    )
                ) {
                    debug('preventing', e.keyCode)
                    const isRichTextBackspace =
                        e.keyCode === 8 && props.attrib === 12
                    if (!isRichTextBackspace) {
                        e.stopPropagation()
                    }
                }
            } else {
                debug('not preventing', e.keyCode)
            }

            if (!e.persist) {
                e.persist = noop
            }

            onKeyDown && onKeyDown(e)
        },
        [onKeyDown, fieldType]
    )

    useEffect(() => {
        if (ref?.current) {
            if (!disabled) {
                ref.current.addEventListener('keydown', preventEvent)
            }

            return () => {
                if (ref.current) {
                    ref.current.removeEventListener('keydown', preventEvent)
                }
            }
        }
    }, [disabled, onKeyDown, fieldType])
    return ref
}
