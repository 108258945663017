import { default as MuiIconButton } from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ActionPlus from '@material-ui/icons/Add'
import ActionAdd from '@material-ui/icons/AddCircleOutline'
import ActionList from '@material-ui/icons/Assignment'
import CheckRounded from '@material-ui/icons/CheckRounded'
import ActionDelete from '@material-ui/icons/Delete'
import Description from '@material-ui/icons/Description'
import ActionEdit from '@material-ui/icons/Edit'
import ActionFastForward from '@material-ui/icons/FastForward'
import ActionFastRewind from '@material-ui/icons/FastRewind'
import ActionOthers from '@material-ui/icons/Forward'
import ActionLegend from '@material-ui/icons/HelpOutlined'
import ActionPhoto from '@material-ui/icons/InsertPhoto'
import MenuRounded from '@material-ui/icons/MenuRounded'
import ActionMore from '@material-ui/icons/MoreVert'
import People from '@material-ui/icons/People'
import ActionPlay from '@material-ui/icons/PlayArrow'
import ActionMinus from '@material-ui/icons/Remove'
import ActionOrder from '@material-ui/icons/Reorder'
import ActionSkipNext from '@material-ui/icons/SkipNext'
import ActionSkipPrevious from '@material-ui/icons/SkipPrevious'
import ActionView from '@material-ui/icons/Visibility'
import ActionAccess from '@material-ui/icons/VpnKey'
import ActionDefault from '@material-ui/icons/Warning'
import DashboardExportIcon from '@nerus/framework/icons/DashboardExport'
import Filter from '@nerus/framework/icons/Filter'
import FilterConfig from '@nerus/framework/icons/FilterConfig'
import UserIcon from '@nerus/framework/icons/User'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'

import { formatTooltip } from '../../../common/Formatter'
import GlassIcon from '../../../icons/Glass'

class IconButton extends Component {
    static propTypes = {
        tooltip: PropTypes.string.isRequired,
        dispatch: PropTypes.func,
        iconColor: PropTypes.string,
        onClick: PropTypes.func,
        className: PropTypes.string,
        size: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            styles: {
                smallIcon: {
                    width: 24,
                    height: 24,
                },
                // small: {
                //     width: 48,
                //     height: 48,
                //     padding: 8,
                // },
            },
        }
    }

    icon = str => {
        switch (str) {
            case 'Inserir':
            case '(I)nserir': {
                return ActionAdd
            }
            case 'Listar':
            case '(L)istar': {
                return ActionList
            }
            case 'Modificar':
            case '(M)odificar': {
                return ActionEdit
            }
            case 'Posicionar':
            case '(P)osicionar': {
                return GlassIcon
            }
            case 'Ordenar':
            case '(O)rdenar': {
                return ActionOrder
            }
            case 'Retirar':
            case '(R)etirar': {
                return ActionDelete
            }
            case 'Legenda':
            case '(l)egenda': {
                return ActionLegend
            }
            case 'Foto':
            case '(C)TRL+F-foto': {
                return ActionPhoto
            }
            case 'Acessos':
            case '(A)cessos': {
                return ActionAccess
            }
            case 'Visualizar':
            case '(V)isualizar': {
                return ActionView
            }
            case 'Componentes':
            case '(C)omponentes':
            case 'Relatórios':
            case '(R)elatórios': {
                return Description
            }
            case 'Outros':
            case 'o(U)tros': {
                return ActionOthers
            }
            case 'Esconder todos': {
                return ActionMinus
            }
            case 'Mostrar todos': {
                return ActionPlus
            }
            case 'Primeiro': {
                return ActionSkipPrevious
            }
            case 'PgUp': {
                return ActionFastRewind
            }
            case 'Anterior': {
                return { icon: ActionPlay, className: 'invert' }
            }
            case 'Próximo': {
                return ActionPlay
            }
            case 'PgDn': {
                return ActionFastForward
            }
            case 'Último': {
                return ActionSkipNext
            }
            case 'Usuários': {
                return UserIcon
            }
            case 'Exportar Dashboard': {
                return DashboardExportIcon
            }
            case 'Grupos': {
                return People
            }
            case 'Menu': {
                return MenuRounded
            }
            case 'Selecionar': {
                return CheckRounded
            }
            case 'Configurar Filtros': {
                return FilterConfig
            }
            case 'Filtros': {
                return Filter
            }
            case 'Selecione': {
                return ActionMore
            }
        }

        return ActionDefault
    }

    render() {
        let Ico = this.icon(this.props.tooltip, this.props.dispatch)
        const props = {
            onClick: this.props.onClick,
            style: this.state.styles.small,
            className: '',
        }

        if (this.props.size) {
            props.size = this.props.size
        }

        const iconColor = this.props.iconColor
            ? this.props.iconColor
            : 'inherit'

        const IconProps = {
            color: iconColor,
        }

        if (typeof Ico == 'object' && Ico.icon) {
            props.className = Ico.className
            if (Ico.action) {
                props.onClick = Ico.action
            }
            if (Ico.ref) {
                props.ref = Ico.ref
            }
            Ico = Ico.icon
        }

        if (this.props.className) {
            props.className += ' ' + this.props.className
        }

        return (
            <Tooltip
                title={formatTooltip(this.props.tooltip)}
                placement={'top'}
            >
                <MuiIconButton {...props}>
                    <Ico {...IconProps} />
                </MuiIconButton>
            </Tooltip>
        )
    }
}

export default IconButton
