import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { cloneDeep } from 'lodash/lang'
import { useCallback, useMemo } from 'react'

export const CREATE_ENOTICE_MUTATION = gql`
    mutation createEnotice($enotice: EnoticeInput!) {
        createEnotice(enotice: $enotice) {
            error
            message
            data {
                enotno
            }
        }
    }
`

export default function useCreateEnotice(enotice, options) {
    const [save] = useMutation(CREATE_ENOTICE_MUTATION, {
        displayName: 'createEnotice',
        ...options,
    })

    const useEnotice = useMemo(() => {
        const returnEnotice = cloneDeep(enotice)

        return returnEnotice
    }, [enotice])

    const createEnotice = useCallback(() => {
        return save({
            variables: {
                enotice: useEnotice,
            },
        })
    }, [save, useEnotice])

    return {
        createEnotice,
    }
}
