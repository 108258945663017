import TextFieldInterface from './TextFieldInterface'

class Numeric extends TextFieldInterface {
    formatter(text) {
        let format = text.trim().replace(/[^0-9.-]/g, '')

        const dots = format.match(/\./g)
        if (dots && dots.length > 1) {
            format = format.replace('.', '')
        }

        return format
    }

    shouldJump(text) {
        return this.jump && text.length === this.mask.sz
    }
}

export default Numeric
