export const styles = theme => ({
    root: {
        display: 'block',
        position: 'absolute',
        zIndex: 9999999,
    },
    container: {
        background: theme.palette.common.white,
        '&:not(:focus-within)': {
            backgroundColor: 'rgb(255, 255, 254)',
            transition: 'background-color 0.01s',
        },
        borderRadius: 2,
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.dark,
        padding: '5px 10px',
        lineHeight: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    title: {
        margin: 0,
        color: theme.palette.contrastText.main,
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: 1.5,
        paddingRight: theme.spacing(1),
        textTransform: 'uppercase',
        flexGrow: 1,
        textAlign: 'left',
    },
    content: {
        fontSize: theme.spacing(2),
        padding: '5px 10px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100% - 28px)',
        // overflow: 'auto',
        '& p': {
            margin: '0 0 10px',
        },
        '& ul.padded': {
            margin: 0,
            padding: '8px 24px',
        },
    },
})
