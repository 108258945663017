import { Button } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import { Menu } from '@material-ui/icons'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import keycodes from '@nerus/framework/common/Keycodes'
import { styles } from '@nerus/styles/components/topBar'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { toggleAbout } from '../../../App/AppActions'
import { withWS } from '../../Business/Websocket/Context'
import Breadcrumb from '../../Components/Breadcrumb'
import { sendBuffer } from '../../Eac/EacActions'
import { checkIsMenuPrincipal, getNerusInfo } from '../../Eac/EacReducer'
import PdvService from '../EditorFormEditPdv/PdvService'
import BackButton from './BackButton'
import Title from './Title'
import UserMenu from './UserMenu'

export class TopBar extends EnhancedComponent {
    static propTypes = {
        showSidebar: PropTypes.bool,
        dispatch: PropTypes.func,
        onBack: PropTypes.func,
        nerusInfo: PropTypes.object,
        componentsLifo: PropTypes.array,
        isMenuPrincipal: PropTypes.bool,
        isPdv: PropTypes.bool,
        isO2: PropTypes.bool,
        isAdmin: PropTypes.bool,
        classes: PropTypes.object,
        ws: PropTypes.object.isRequired,
        match: PropTypes.object,
    }

    state = {}

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.checkProps(
                [
                    'isMenuPrincipal',
                    'nerusInfo',
                    'isPdv',
                    'isAdmin',
                    'onBack',
                    'isO2',
                    'componentsLifo',
                ],
                nextProps
            ) || this.state.anchorEl !== nextState.anchorEl
        )
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.close)
    }

    componentDidMount() {
        document.addEventListener('click', this.close)
    }

    onClickUserMenu = event => {
        this.setState({ anchorEl: event.currentTarget })
    }

    onClickUserMenuClose = () => {
        this.setState({ anchorEl: null })
    }

    handleBack = () => {
        const parts = window.location.pathname.substring(1).split('/')
        if (window.location.pathname === '/library') {
            this.props.history.push('/dashboard')
        } else if (parts[0] && parts[0] !== 'bin') {
            parts.pop()
            this.props.history.push('/' + parts.join('/'))
        } else if (
            parts[0] &&
            parts[0] === 'bin' &&
            window.location.pathname.indexOf('/dashboard') > -1
        ) {
            parts.pop()
            this.props.history.push('/' + parts.join('/'))
        } else {
            this.props.ws.send(sendBuffer(keycodes.ESCAPE_KEY))
        }
    }

    handleAbout = () => {
        this.props.dispatch(toggleAbout())
        this.onClickUserMenuClose()
    }

    handleExit = () => {
        this.props.ws.send(sendBuffer(keycodes.ESCAPE_KEY))
        this.onClickUserMenuClose()
    }

    openMenu = () => {
        this.props.ws.send(sendBuffer('Z'))
    }

    render() {
        const { anchorEl } = this.state
        const {
            classes,
            dispatch,
            isMenuPrincipal,
            nerusInfo,
            match: { params },
            onBack,
            isO2,
            componentsLifo,
            isAdmin,
        } = this.props

        return (
            <AppBar
                className={classes.root}
                position={'relative'}
                elevation={0}
            >
                <Toolbar className={classes.toolbar} disableGutters>
                    {isO2 && isAdmin && !componentsLifo.length ? (
                        <Button
                            onClick={this.openMenu}
                            style={{
                                color: `#fff`,
                                backgroundColor: `#0088a8`,
                                borderRadius: 0,
                                height: 76,
                            }}
                        >
                            <Menu />
                        </Button>
                    ) : null}
                    <Title
                        isMenuPrincipal={isMenuPrincipal}
                        dispatch={dispatch}
                        isO2={isO2}
                    />
                    <BackButton onClick={onBack || this.handleBack} />
                    <div className={classes.flexGrow}>
                        <Breadcrumb />
                    </div>
                    {params?.binario !== 'o2' ? <PdvService /> : null}
                    <UserMenu
                        onUserMenu={this.onClickUserMenu}
                        onUserMenuClose={this.onClickUserMenuClose}
                        onAbout={this.handleAbout}
                        onExit={this.handleExit}
                        anchorEl={anchorEl}
                        nerusInfo={nerusInfo}
                        isMenuPrincipal={isMenuPrincipal}
                        isO2={isO2}
                    />
                </Toolbar>
            </AppBar>
        )
    }
}

function mapStateToProps(state) {
    return {
        isAdmin: state.app.isAdmin,
        showSidebar: state.app.showSidebar,
        nerusInfo: getNerusInfo(state),
        isMenuPrincipal: checkIsMenuPrincipal(state),
        componentsLifo: state.eac.componentsLifo,
    }
}

export default connect(mapStateToProps)(
    withRouter(withStyles(styles)(withWS(TopBar)))
)
