import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const READNEWS_MUTATION = `
    mutation ReadNews($data: NewsInput!) {
        readNews(data: $data) {
            id
            title
            content
            author
            date
            permalink
            image
            unread
            tags
        }
    }
`

export default function useReadNews(options) {
    return useMutation(gql(READNEWS_MUTATION), {
        displayName: 'readNews',
        ...options,
    })
}
