export const styles = theme => ({
    root: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        overflow: 'hidden',
        backgroundColor: theme.palette.common.black,
    },
    fullbanner: {
        position: 'absolute',
        zIndex: -1,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
    },
    content: {
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
    },
    formWrapper: {
        padding: 80,
        maxWidth: 466,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        backdropFilter: `blur(4px)`,
        background: `rgba(255, 255, 255, 0.60)`,
    },
    callingWrapper: {
        textAlign: 'center',
        '& svg': {
            marginBottom: 36,
        },
        '& p': {
            marginTop: 0,
            marginBottom: 16,
        },
    },
    caller: {
        fontSize: '32px',
        maxWidth: '245px',
        lineHeight: 1.3,
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        marginTop: '20px',
        marginBottom: '10px',
    },
    welcomeText: {
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '20px',
        marginBottom: '10px',
    },
    logo: {
        height: '18vh',
        display: 'block',
        maxWidth: '100%',
    },
    form: {},
    formInput: {
        borderRadius: 4,
        background: theme.palette.common.white,
        marginBottom: 16,
        '& > div': {
            marginTop: 0,
            '& > input': {
                marginLeft: 24,
                marginRight: 24,
                paddingBottom: 12,
                paddingTop: 20,
            },
            '&::before, &::after': {
                content: 'unset',
            },
        },
    },
    labelFocused: {}, // para conseguir sobrepor facilmente o focus color
    labelShrink: {
        transform: `translate(0, 6px) scale(0.75) !important`,
    },
    labelColor: {
        color: `#A1A6AE`,
        left: 24,
        right: 24,
        transform: 'translate(0, 20px) scale(1)',
        '&$labelFocused': {
            color: `#A1A6AE`,
        },
    },
    errorMessage: {
        textAlign: 'center',
        padding: 0,
        color: theme.palette.primary.main,
        fontSize: '12px',
    },
    actions: {
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'center',
        textAlign: 'center',
        '& > button': {
            marginBottom: 16,
            padding: 16,
            '& span': {
                fontSize: 14,
                textTransform: 'none',
                letterSpacing: '0.14px',
            },
        },
    },
    actionsLeft: {
        flexGrow: 1,
        '& button': {
            width: '100%',
        },
    },
    submit: {
        marginTop: 40,
    },
    footer: {
        position: `absolute`,
        textAlign: `center`,
        bottom: '20%',
        fontFamily: 'Frutiger, sans-serif',
        fontSize: 12,
        letterSpacing: '0.14px',
        lineHeight: `12px`,
        color: '#909090',
        '& a': {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            textDecoration: 'none',
        },
    },
})
