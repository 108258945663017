import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Portal from '@material-ui/core/Portal'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import keycodes from '@nerus/framework/common/Keycodes'
import Flutuante from '@nerus/framework/components/Base/Flutuante'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import DashboardIcon from '@nerus/framework/icons/Dashboard'
import PropTypes from 'prop-types'
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useSelector } from 'react-redux'

import { getNerusInfo } from '../../NerusWeb/Eac/EacReducer'
import Actions from './Actions'
import Search from './Actions/Search'
import DashboardCard from './DashboardCard'
import DashboardRender from './DashboardRender'
import useFetchDashboards from './hooks/useFetchDashboards'

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.common.white,
    },
    dialogo: {
        backgroundColor: '#E8E8E8',
        '&:not(:focus-within)': {
            backgroundColor: '#E8E8E8',
        },
        '& > div:last-child': {
            paddingTop: 16,
        },
    },
    content: {
        overflowX: 'hidden',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        flex: 1,
    },
    container: {
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        margin: 0,
    },
}))

function applyFilter(filter, data) {
    if (!filter) return data

    return data.filter(
        row => row.title.toLowerCase().indexOf(filter.toLowerCase()) > -1
    )
}

function DashboardShortcut({ history }) {
    const [filter, setFilter] = useState('')
    const [show, setShow] = useState(false)
    const [view, setView] = useState(null)
    const [page, setPage] = useState(0)
    const nerusInfo = useSelector(getNerusInfo)
    const [pageSize, setPageSize] = useState(16)
    const [doQuery, { loading, data }] = useFetchDashboards({
        lazy: true,
        variables: {
            panel: true,
            user_id: nerusInfo?.usrno,
            library: 0,
        },
        skip: () => {
            return !nerusInfo?.usrno
        },
    })

    useEffect(() => {
        doQuery()
    }, [nerusInfo?.usrno])

    useKeyboardTrap(
        event => {
            /* Revertido para o ticket 91458
            if (show) {
                event.stopPropagation()
            }
            */
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                if (view) {
                    setView(null)
                } else if (show) {
                    setShow(false)
                }
            }
        },
        { capture: true }
    )

    const classes = useStyles()

    const handleToggle = useCallback(() => {
        if (view) {
            setView(null)
        } else if (show) {
            setShow(false)
        } else {
            setShow(true)
        }
    }, [setShow, setView, show, view])

    const handleChange = useCallback(
        (_event, page) => {
            setPage(page)
        },
        [setPage]
    )

    const handlePageSize = useCallback(
        event => {
            setPageSize(event.target.value)
        },
        [setPage]
    )

    const labelDisplay = useCallback(
        ({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`,
        []
    )

    const dashboards = useMemo(() => {
        return applyFilter(filter, data?.allDashboard || [])
    }, [filter, data])

    const dashboardsList = useMemo(() => {
        const startIndex = page * pageSize
        const endIndex = Math.min(startIndex + pageSize, dashboards.length)
        return dashboards.slice(startIndex, endIndex).map(dashboard => {
            return (
                <Grid key={dashboard.id} item md={4} lg={3} xl={3}>
                    <DashboardCard
                        history={history}
                        // eslint-disable-next-line react/jsx-no-bind, prettier/prettier
                        onClick={() => {
                            setView(dashboard.id)
                        }}
                        {...dashboard}
                    />
                </Grid>
            )
        })
    }, [dashboards, page, pageSize])

    return (
        <Fragment>
            <IconButton className={classes.root} onClick={handleToggle}>
                <DashboardIcon />
            </IconButton>

            {show ? (
                <Portal container={document.getElementById('#root')}>
                    <Flutuante
                        title="Dashboards"
                        onClose={handleToggle}
                        className={classes.dialogo}
                        size="fullscreen"
                    >
                        {!view ? (
                            <Actions>
                                <Search onChange={setFilter} />
                            </Actions>
                        ) : null}

                        <div className={classes.content}>
                            {view ? (
                                <DashboardRender
                                    dashboard_id={view}
                                    handleToggle={handleToggle}
                                />
                            ) : loading ? (
                                <Typography variant="h6">
                                    Buscando registros...
                                </Typography>
                            ) : dashboards.length ? (
                                <Grid
                                    className={classes.container}
                                    container
                                    spacing={2}
                                >
                                    {dashboardsList}
                                </Grid>
                            ) : filter ? (
                                <Typography variant="h4">
                                    Não encontramos nenhum registro com essa
                                    busca...
                                </Typography>
                            ) : (
                                <Typography variant="h4">
                                    Não encontramos nenhum registro...
                                </Typography>
                            )}
                        </div>

                        <TablePagination
                            component="div"
                            count={dashboards.length}
                            onPageChange={handleChange}
                            page={page}
                            rowsPerPageOptions={[8, 12, 16, 24, 32, 40]}
                            rowsPerPage={pageSize}
                            labelRowsPerPage="Registros por página:"
                            nextIconButtonText="Próxima Página"
                            backIconButtonText="Página Anterior"
                            labelDisplayedRows={labelDisplay}
                            onRowsPerPageChange={handlePageSize}
                        />
                    </Flutuante>
                </Portal>
            ) : null}
        </Fragment>
    )
}

export default DashboardShortcut

DashboardShortcut.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
}
