import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'

export const FaceBiometry = gql`
    mutation FaceBiometry($type: String!, $data: FaceBiometryInput!) {
        faceBiometry(type: $type, data: $data) {
            result
            faceBiometry {
                id
                state
                state_id
            }
        }
    }
`

export default Component => {
    return graphql(FaceBiometry, { name: 'faceBiometry' })(Component)
}
