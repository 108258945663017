import { IconButton, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import ConfigIcon from '@material-ui/icons/SettingsApplications'
import useWS from '@nerus/framework/hooks/useWS'
import transformColor from '@nerus/framework/util/transformColor'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { sendBuffer } from '../../../NerusWeb/Eac/EacActions'
import { TYPE_SEPARATOR } from '../../Dashboard/old/Reports/Configurar/Configurar'

const useStyles = makeStyles(theme => ({
    paperBox: {
        padding: 20,
        position: 'relative',
    },
    handle: {
        width: 4,
        right: -2,
        height: 4,
        bottom: 0,
        zIndex: 10,
        display: 'block',
        position: 'absolute',
        transform: 'rotate(-45deg)',
        cursor: 'nwse-resize !important',
        borderLeft: '4px solid transparent',
        borderRight: '4px solid transparent',
        borderTop: '4px solid rgb(0 122 90)',
    },
    configButton: {
        display: 'flex',
    },
    graph: {
        display: 'flex',
        flex: 1,
        height: '100%',
        width: '100%',
        padding: theme.spacing(2, 0),
    },
    others: {
        height: '100%',
    },
    separator: {
        background: 'none',
        boxShadow: 'none',
        borderBottom: '2px solid rgba(0,0,0,0.3)',
        paddingBottom: 0,
        marginBottom: 16,
        borderRadius: 0,
        '& span': {
            fontSize: `1em`,
            fontWeight: 'bold',
        },
    },
    separatorInvisible: {
        padding: 0,
        margin: 0,
        background: 'none',
        boxShadow: 'none',
        height: 'auto !important',
        '& > div': {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0 !important',
        },
    },
    gridSeparatorInvisible: {
        padding: '0 !important',
    },
    isActive: {},
    isActiveFullRight: {
        maxWidth: '99%',
        paddingRight: '8px !important',
    },
    isActiveLeft: {
        borderLeft: '3px solid rgba(0, 0, 0, 0.5)',
        paddingLeft: 24,
    },
    isActiveRight: {
        borderRight: '3px solid rgba(0, 0, 0, 0.5)',
        paddingRight: 24,
    },
    isDragging: {
        opacity: 0.5,
    },
    header: {
        fontSize: `0.8571428571428571em`,
        textTransform: 'uppercase',
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
    },
    headerHide: {
        justifyContent: 'flex-end',
        marginBottom: '-28px',
        marginRight: '24px',
        position: 'relative',
        zIndex: 1,
    },
    headerO2: {
        textTransform: 'initial',
        color: '#333333',
        fontSize: `1.2857142857142858em`,
        fontWeight: 800,
    },
    allowClick: {
        cursor: 'pointer',
        '& *': {
            cursor: 'pointer',
        },
    },
    graphO2: {
        '& table th': {
            fontSize: '0.8571428571428571em',
            fontWeight: 400,
            lineHeight: '1.3333333333333333em',
            color: '#A1A6AE',
            padding: '0.6666666666666666em 1.5em',
            height: 'auto',
            boxShadow: 'none',
        },
        '& table td': {
            fontSize: '0.8571428571428571em',
            fontWeight: 400,
            lineHeight: '1.3333333333333333em',
            color: '#333333',
            padding: '0.6666666666666666em 1.5em',
            height: 'auto',
            borderTop: '1px solid #EEEEEE',
            fontFamily: 'Frutiger',
        },
        '& table td::before': {
            background: 'none',
        },
        // '& > div > div[class]:first-child': {
        //     display: 'none',
        // },
    },
}))

function DashboardBox(props) {
    const {
        onConfig,
        onRemove,
        colors,
        type,
        height = 2,
        children,
        size,
        title,
        setDashboard,
        hideBox = false,
        isPainelO2 = false,
        invisible = false,
    } = props
    const ref = useRef(null)
    const [dir, setDir] = useState(null)
    const classes = useStyles()
    const ws = useWS()
    const useHeight = useMemo(() => {
        let useHeight = height
        if (useHeight > 12 || useHeight < 0) {
            useHeight = 12
        }
        if (type === TYPE_SEPARATOR) {
            useHeight = 1
        }
        return useHeight
    }, [type, height])

    let isDragging, drag, drop, isActive, dragHandle

    if (setDashboard) {
        if (type !== TYPE_SEPARATOR) {
            const withDragHandle = useDrag(
                {
                    type: 'handle',
                    item() {
                        document.body.style.cursor = 'nw-resize'
                        return {
                            dragging: true,
                            setDashboard,
                            currentItem: props,
                        }
                    },
                    end: () => {
                        document.body.style.cursor = ''
                    },
                    canDrag() {
                        return true
                    },
                    isDragging() {
                        return true
                    },
                },
                [props, setDashboard]
            )

            dragHandle = withDragHandle[1]
            useEffect(() => {
                withDragHandle[2](getEmptyImage(), {
                    captureDraggingState: true,
                })
            }, [withDragHandle])
        }

        const withDrag = useDrag(
            {
                type: 'component',
                options: {
                    dropEffect: 'move',
                },
                item: () => {
                    return props
                },
                collect: monitor => ({
                    isDragging: monitor.isDragging(),
                }),
            },
            [props]
        )

        isDragging = withDrag[0].isDragging
        drag = withDrag[1]

        const withDrop = useDrop(
            {
                accept: ['component'],
                hover(item, monitor) {
                    const isActive =
                        monitor.canDrop() && monitor.isOver({ shallow: true })

                    // Determine rectangle on screen
                    const hoverBoundingRect = ref.current?.getBoundingClientRect()
                    const clientOffset = monitor.getClientOffset()

                    if (
                        item?.id === props?.id ||
                        !isActive ||
                        !hoverBoundingRect ||
                        !clientOffset
                    ) {
                        setDir(null)
                        return
                    }

                    const hoverMiddleX =
                        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
                    const hoverClientX = clientOffset.x - hoverBoundingRect.left

                    if (hoverClientX > hoverMiddleX) {
                        setDir('right')
                    } else if (
                        hoverClientX > 0 &&
                        hoverClientX < hoverMiddleX
                    ) {
                        setDir('left')
                    }
                },
                collect(monitor) {
                    return {
                        handlerId: monitor.getHandlerId(),
                        isActive:
                            monitor.canDrop() &&
                            monitor.isOver({ shallow: true }),
                    }
                },
                drop(item) {
                    const target = props
                    const source = item
                    setDir(null)
                    setDashboard(dashboard => {
                        const newDashboard = {
                            ...dashboard,
                            components: [...dashboard.components],
                        }

                        const elm = newDashboard.components.splice(
                            source.order,
                            1
                        )[0]
                        newDashboard.components.splice(
                            dir === 'left'
                                ? target.order - 1 < 0
                                    ? 0
                                    : target.order - 1
                                : target.order,
                            0,
                            elm
                        )

                        // corrige a ordenação
                        newDashboard.components.forEach((c, order) => {
                            if (c) {
                                c.order = order
                            }
                        })

                        newDashboard.components = newDashboard.components.filter(
                            Boolean
                        )

                        return newDashboard
                    })
                },
            },
            [setDashboard, props, ref, dir, setDir]
        )

        isActive = withDrop[0].isActive
        drop = withDrop[1]
    }

    useEffect(() => {
        if (!isActive) {
            setDir(null)
        }
    }, [setDir, isActive])

    if (drag && drop) {
        useLayoutEffect(() => {
            drag(drop(ref))
            return () => {
                drag(null)
                drop(null)
            }
        }, [drag, drop, ref])
    }

    const triggerKey = useCallback(() => {
        switch (title) {
            // case 'Devoluções x Vendas no dia':
            case 'Integração de produtos':
                ws.current.send(sendBuffer('d'))
                break
            case 'Contas a receber x conciliado':
                ws.current.send(sendBuffer('r'))
                break
            // case 'Marketplaces':
            case 'Top vendas':
            // case 'Vendas por estado':
            // case 'Atualização da fila de produtos':
                // TODO: Exibir dashboards disponíveis
                console.debug('// TODO: Exibir dashboards disponíveis')
                ws.current.send(sendBuffer('i'))
                break
        }
    }, [])

    const comp = (
        <React.Fragment>
            <div
                className={clsx(classes.header, {
                    [classes.headerHide]: hideBox && onConfig,
                    [classes.allowClick]: isPainelO2,
                })}
                onClick={isPainelO2 ? triggerKey : undefined}
            >
                {!hideBox && !invisible && (
                    <div
                        style={{
                            flex: 1,
                            color: colors
                                ? transformColor(colors.title)
                                : 'rgba(0,0,0,0.87)',
                        }}
                        className={clsx({
                            [classes.headerO2]: isPainelO2,
                        })}
                    >
                        {title}
                    </div>
                )}

                {onConfig || onRemove ? (
                    <div className={classes.configButton}>
                        {onConfig ? (
                            <Tooltip title="Configurar">
                                <IconButton
                                    style={{ padding: 0 }}
                                    onClick={onConfig}
                                >
                                    <ConfigIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                        {onRemove ? (
                            <Tooltip title="Remover">
                                <IconButton
                                    style={{ padding: 0 }}
                                    onClick={onRemove}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </div>
                ) : null}
            </div>

            {!hideBox ? (
                <div
                    className={clsx(classes.graph, {
                        [classes.graphO2]: isPainelO2,
                    })}
                >
                    {children}
                </div>
            ) : (
                children
            )}

            {dragHandle ? (
                <span ref={dragHandle} className={classes.handle} />
            ) : null}
        </React.Fragment>
    )

    const calculatedHeight = (useHeight * 64) / 14 + 'em'

    return (
        <Grid
            item
            xs={size}
            className={clsx({
                [classes.isActive]: isActive,
                [classes.isActiveLeft]: dir === 'left',
                [classes.isActiveRight]: dir === 'right',
                [classes.isActiveFullRight]: dir === 'right' && size === 12,
                [classes.gridSeparatorInvisible]:
                    type === TYPE_SEPARATOR && invisible,
            })}
        >
            {hideBox ? (
                comp
            ) : (
                <Paper
                    ref={ref}
                    className={clsx(classes.paperBox, {
                        [classes.others]: type !== TYPE_SEPARATOR,
                        [classes.separator]:
                            type === TYPE_SEPARATOR && !invisible,
                        [classes.separatorInvisible]:
                            type === TYPE_SEPARATOR && invisible,
                        [classes.isDragging]: isDragging,
                    })}
                    style={{
                        backgroundColor: colors
                            ? transformColor(colors.background)
                            : 'rgb(255, 255, 255)',
                        borderBottomColor: colors
                            ? transformColor(colors.separator)
                            : 'rgba(0,0,0,0.87)',
                        height: calculatedHeight,
                    }}
                >
                    {comp}
                </Paper>
            )}
        </Grid>
    )
}

DashboardBox.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    order: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    colors: PropTypes.object,
    config: PropTypes.bool,
    hideBox: PropTypes.bool,
    isPainelO2: PropTypes.bool,
    invisible: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    height: PropTypes.number,
    onConfig: PropTypes.func,
    onRemove: PropTypes.func,
    setDashboard: PropTypes.func,
    dashboard: PropTypes.object,
}

export default DashboardBox
