import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function GlassIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.02397244,0,0,-0.02397244,0.01378,19.06754)">
                <path d="M 999,61 Q 1009,13 955.5,-33 902,-79 816,-97 730,-115 662.5,-94 595,-73 585,-26 v 1 1 l -9,77 Q 542,29 500,29 458,29 424,53 l -9,-77 v -1 -1 Q 405,-73 337.5,-94 270,-115 184,-97 98,-79 44.5,-33 -9,13 1,61 h 1 l 123,407 q 2,8 9.5,12.5 7.5,4.5 15.5,2.5 h 1 l 59,194 q 3,15 43.5,17.5 40.5,2.5 94.5,-9 54,-11.5 90,-30 Q 474,637 471,622 L 446,421 h 2 q 8,-2 13,-9 5,-7 4,-16 L 451,282 q 24,10 49,10 25,0 49,-10 l -14,114 q -1,9 4,16 5,7 13,9 h 2 l -25,201 q -3,15 33,33.5 36,18.5 90,30 54,11.5 94.5,9 Q 787,692 790,677 l 59,-194 h 1 q 8,2 15.5,-2.5 Q 873,476 875,468 L 998,61 Z M 196,-40 q 26,-5 61,-6 35,-1 66,7 31,8 34,25 6,24 -43.5,52 Q 264,66 220,75 194,80 159,81 124,82 93,74 62,66 58,49 57,39 70.5,23 84,7 117.5,-12 151,-31 196,-40 Z m 304,166 q 14,0 24,10 10,10 10,24 0,14 -10,24 -10,10 -24,10 -14,0 -24,-10 -10,-10 -10,-24 0,-14 10,-24 10,-10 24,-10 z M 804,-40 q 45,9 78.5,28 33.5,19 47,35 13.5,16 12.5,26 -4,17 -35,25 -31,8 -66,7 Q 806,80 780,75 736,66 686.5,38 637,10 643,-14 q 3,-17 34,-25 31,-8 66,-7 35,1 61,6 z" />
            </g>
        </SvgIcon>
    )
}
