import * as PropTypes from 'prop-types'

import { TextFieldDate } from './Date'

/**
 * Componente TextFieldDateMonthYear
 *
 * Rec: REC_DATE_YM
 *
 * Renderiza um campo de texto que tem formatação de data
 * com datepicker
 */
export class TextFieldDateMonthYear extends TextFieldDate {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    }

    views = ['year', 'month']
    format = 'YYYYMM'
    displayFormat = 'MM/YYYY'
    separator = '/'
    sendFormat = 'MMYYYY'
    zeroedValue = this.sendFormat.replace(/./g, '0')
}

export default TextFieldDateMonthYear
