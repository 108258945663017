import { Grid } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { styles } from '@nerus/styles/components/painelo2/boxes'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(styles, { name: 'PainelO2-Box' })

export default function Box({
    bevel,
    className,
    children,
    title,
    color,
    icon,
    value,
    details,
    onClick,
    productCicle = false,
    fullfillment = false,
    pickingAndPacking = false,
}) {
    const classes = useStyles()
    return (
        <div
            onClick={onClick}
            className={clsx(classes.root, {
                [classes.bevel]: bevel,
                [classes.colorBlue]: color === 'blue',
                [classes.colorGreen]: color === 'green',
                [classes.colorBlue800]: color === 'blue-800',
                [classes.colorBlue700]: color === 'blue-700',
                [classes.colorBlue600]: color === 'blue-600',
                [classes.colorBlue500]: color === 'blue-500',
                [classes.colorBlue400]: color === 'blue-400',
                [classes.colorBlue300]: color === 'blue-300',
                [classes.colorBlue200]: color === 'blue-200',
                [classes.colorBlue100]: color === 'blue-100',
                [classes.colorDarkGreen]: color === 'dark-green',
                [classes.colorPurple]: color === 'purple',
            })}
        >
            {title && (
                <h3
                    className={clsx(classes.title, {
                        [classes.bevelTitle]: bevel,
                    })}
                >
                    {title}
                </h3>
            )}

            <div
                className={clsx(classes.content, {
                    [className]: !!className,
                    [classes.alignItems]: fullfillment,
                    [classes.maxHeight]: pickingAndPacking || productCicle,
                })}
            >
                {children ? (
                    children
                ) : (
                    <Grid container>
                        {icon ? (
                            <Grid item xs={4} className={classes.icon}>
                                {icon}
                            </Grid>
                        ) : null}
                        <Grid
                            item
                            xs={icon ? 8 : 12}
                            className={clsx(classes.value, {
                                [classes.valueOnly]: !icon,
                            })}
                        >
                            {value}
                        </Grid>
                    </Grid>
                )}
            </div>

            {details ? (
                <Grid container className={classes.details}>
                    <Grid xs={9} item className={classes.info}>
                        {details.title}
                    </Grid>
                    <Grid
                        xs={3}
                        item
                        className={clsx(classes.info, classes.right)}
                    >
                        {details.value}
                    </Grid>
                </Grid>
            ) : null}
        </div>
    )
}

Box.propTypes = {
    bevel: PropTypes.any,
    className: PropTypes.any,
    children: PropTypes.any,
    title: PropTypes.any,
    footer: PropTypes.any,
    icon: PropTypes.any,
    value: PropTypes.any,
    details: PropTypes.any,
    color: PropTypes.any,
    onClick: PropTypes.any,
    fullfillment: PropTypes.bool,
    pickingAndPacking: PropTypes.bool,
    productCicle: PropTypes.bool,
}
