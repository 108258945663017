import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import StyledButton from '@nerus/framework/styled/Button'
import Label from '@nerus/framework/styled/Label'
import { styles } from '@nerus/styles/components/menu/guias'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { withWS } from '../../../../Business/Websocket/Context'
import { sendBuffer } from '../../../../Eac/EacActions'
import Components from '../../../index'

export class Guias extends EnhancedComponent {
    static defaultProps = {
        menu: {},
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        menu: PropTypes.object.isRequired,
        ws: PropTypes.object.isRequired,
    }

    static mapStateToProps = () => {
        return {}
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.checkProps(['menu'], nextProps) ||
            this.props.content !== nextProps.content
        )
    }

    toggleTab = key => () => {
        const {
            menu: { active, enabled, content },
        } = this.props

        // 96472 - Se a aba enviada ja estiver ativa, não envia para o WS
        const isPicture = content.type === 'Foto'
        const isEditor = content.type === 'Editor'
        const isEditorForm = content.type === 'EditorForm'

        if (
            key != active &&
            (!enabled || isPicture || isEditor || isEditorForm)
        ) {
            this.props.ws.send(sendBuffer(key, 'sendBuffer'))
        }
    }

    render() {
        const {
            menu: { strList, shtct, active, content, enabled, title = '' },
            classes,
        } = this.props

        const isPicture = content.type === 'Foto'
        const isEditor = content.type === 'Editor'
        const isEditorForm = content.type === 'EditorForm'

        let Component = null
        if (content?.type) {
            Component = Components[content.type]
        }

        let useTitle = title
        if (content?.data?.editor?.data?.title) {
            useTitle = `${useTitle} - ${content.data.editor.data.title}`
        }
        if (content?.data?.title) {
            useTitle = `${useTitle} - ${content.data.title}`
        }

        return (
            <div className={classes.root}>
                {useTitle ? (
                    <Typography variant={'h3'} className={classes.title}>
                        {useTitle}
                    </Typography>
                ) : null}
                <div
                    className={clsx({
                        [classes.tab]: true,
                    })}
                >
                    <Box
                        className={clsx(
                            {
                                [classes.disabled]: enabled,
                            },
                            classes.buttonWrapper
                        )}
                    >
                        {strList.map((option, index) => (
                            <span
                                key={option}
                                className={clsx(classes.buttonWrapper, {
                                    active: index === active,
                                })}
                            >
                                <StyledButton
                                    lbl={<Label text={option} noShortcut />}
                                    active={index === active}
                                    variant={
                                        index === active
                                            ? 'roundedTab'
                                            : 'default'
                                    }
                                    disabled={
                                        enabled &&
                                        !isPicture &&
                                        !isEditor &&
                                        !isEditorForm
                                    }
                                    className={clsx(classes.buttonTab)}
                                    onClick={this.toggleTab(shtct[index])}
                                    tab="new"
                                />
                            </span>
                        ))}
                    </Box>
                </div>
                <div
                    className={clsx({
                        [classes.tabContent]: true,
                    })}
                >
                    {Component ? (
                        <Box
                            className={clsx({
                                [classes.flex]: true,
                                [classes.disabled]: enabled === false,
                            })}
                        >
                            <Component
                                isGuia
                                nospace
                                flt={false}
                                {...content}
                                showTitle={false}
                                active
                            />
                        </Box>
                    ) : null}
                </div>
            </div>
        )
    }
}

export default connect(Guias.mapStateToProps)(withStyles(styles)(withWS(Guias)))
