import config from '@nerus/config'
import keycodes from '@nerus/framework/common/Keycodes'

import { sendBuffer } from '../EacActions'
import Menu from './Menu'

export function handleKeyboard(ws) {
    return (dispatch, o, data) => {
        const { cpfSacado, idBanco, codigoBarras } = data
        const fileName = `${cpfSacado.replace(
            /\D/g,
            ''
        )}_${idBanco}_${codigoBarras}`

        if (o.keyCode === keycodes.ESCAPE_KEY) {
            ws.send(
                sendBuffer({ key: o.keyCode, file: '' }, 'BoletoDialogRetorno')
            )
            return
        }

        if (data.shtct && !data.shtct.includes(o.key.toUpperCase())) return

        switch (o.key?.toUpperCase()) {
            case 'I': {
                ws.send(
                    sendBuffer({
                        key: o.keyCode,
                        file: `${config.files.basePath}/print/boleto_${fileName}.html`,
                    })
                )
                return
            }
            case 'P': {
                if (o.ctrlKey) {
                    const ctnWindow = document.getElementById('boleto')
                        .contentWindow
                    ctnWindow.focus()
                    ctnWindow.print()
                }
                return
            }
        }

        // ws.send(sendBuffer(o.key))
    }
}

export default class BoletoDialog extends Menu {
    static before({ state, action }) {
        action.payload.disableKeyboard = true
        action.payload.keyboardActions = handleKeyboard

        return { state, action }
    }
}
