import { alpha, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import BaseFlutuante from '@nerus/framework/components/Base/Flutuante'
import Button from '@nerus/framework/styled/Button'
import { cloneDeep } from 'apollo-utilities'
import clsx from 'clsx'
import throttle from 'lodash.throttle'
import merge from 'lodash/merge'
import * as PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import deepDiff from '../../../../../util/deepDiff'
import {
    TYPE_GRAPH_BAR,
    TYPE_GRAPH_BAR_GROUPED,
    TYPE_GRAPH_BAR_HOR,
    TYPE_GRAPH_BAR_STACKED,
    TYPE_GRAPH_LINE,
    TYPE_GRAPH_PIZZA,
} from '../../Components/constants'
import { initialProperties as barDefaults } from './defaults/bar'
import { initialProperties as barGroupedDefaults } from './defaults/bar-grouped'
import { initialProperties as barHorDefaults } from './defaults/bar-hor'
import { initialProperties as barStackedDefaults } from './defaults/bar-stacked'
import { initialProperties as lineDefaults } from './defaults/line'
import { initialProperties as pieDefaults } from './defaults/pie'
import Layout from './Layout'
import ComponentSettings from './nivo/components/ComponentSettings'
import { groups as barProps } from './props/bar'
import { groups as lineProps } from './props/line'
import { groups as pieProps } from './props/pie'
import SampleGraph from './SampleGraph'

const styles = theme => ({
    root: {
        flex: 1,
        height: '100%',
    },
    input: {
        height: 'inherit',
        flex: 1,
        maxHeight: '100%',
        minHeight: '100%',
        fontFamily:
            '"monospace", "Monaco", "Menlo", "Andale Mono", "Lucida Sans Typewriter", "Droid Sans Mono", "Deja Vu Sans Mono", "Courier New", "Courier"',
        whiteSpace: 'pre',
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: alpha('#000000', 0.15),
            '&:hover': {
                backgroundColor: alpha('#000000', 0.15),
            },
        },
        '&::-webkit-scrollbar': {
            width: 8,
            height: 8,
            cursor: 'pointer',
        },
    },
    background: {
        height: '100%',
        backgroundColor: theme.palette.common.white + ' !important',
        border: '1px solid ' + theme.palette.primary.dark,
        padding: theme.spacing(1),
        overflow: 'auto',
        '&:hover': {
            backgroundColor: theme.palette.common.white + ' !important',
        },
    },
    rootContainer: {
        display: 'flex',
        flex: 1,
    },
    flexContainer: {
        display: 'flex',
        overflow: 'auto',
    },
    actions: {
        paddingTop: theme.spacing(1),
        '& button:last-child': {
            margin: 0,
        },
    },
    flex: {
        flex: 1,
    },
    queryMenu: {
        minWidth: 200,
    },
    right: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
        display: 'flex',
    },
    blockHeight: {
        flex: 1,
        minHeight: '100%',
    },
    graphColumn: {
        borderLeft: '2px solid rgba(0,0,0,0.05)',
        paddingLeft: theme.spacing(2),
        '& > div': {
            maxHeight: 500,
        },
    },
})

class GraphConfig extends EnhancedComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        initialCustom: PropTypes.object,
        component: PropTypes.object,
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        let groups = barProps,
            defaults
        switch (props.component?.type) {
            case TYPE_GRAPH_BAR:
                defaults = barDefaults
                break
            case TYPE_GRAPH_BAR_HOR:
                defaults = barHorDefaults
                break
            case TYPE_GRAPH_BAR_GROUPED:
                defaults = barGroupedDefaults
                break
            case TYPE_GRAPH_BAR_STACKED:
                defaults = barStackedDefaults
                break
            case TYPE_GRAPH_PIZZA:
                groups = pieProps
                defaults = pieDefaults
                break
            case TYPE_GRAPH_LINE:
                groups = lineProps
                defaults = lineDefaults
        }

        defaults = cloneDeep(defaults)

        this.state = {
            defaults,
            override: merge(
                defaults,
                props.initialCustom ? cloneDeep(props.initialCustom) : {}
            ),
            groups,
        }
    }

    handleClose = () => {
        this.props.onClose?.()
    }

    handleSave = () => {
        this.props.onSave?.(deepDiff(this.state.defaults, this.state.override))
        this.setState({
            override: merge(
                cloneDeep(this.state.defaults),
                this.props.initialCustom || {}
            ),
        })
    }

    onChangeThrottled = throttle(value => {
        const override = merge(
            cloneDeep(this.props.initialCustom || {}),
            this.state.override,
            value
        )

        ;['axisTop', 'axisBottom', 'axisLeft', 'axisRight'].forEach(axe => {
            if (!override[axe] || !override[axe].enable) {
                override[axe] = null
            } else {
                if (!override[axe].tickSize) override[axe].tickSize = 0
                if (!override[axe].tickPadding) override[axe].tickPadding = 0
                if (!override[axe].tickRotation) override[axe].tickRotation = 0
                if (!override[axe].legendOffset) override[axe].legendOffset = 0
            }
        })

        if (override?.legends) {
            override.legends.forEach(legend => {
                if (!legend.anchor) legend.anchor = 'bottom-right'
                if (!legend.direction) legend.direction = 'column'
                if (!legend.translateX) legend.translateX = 0
                if (!legend.translateY) legend.translateY = 0
                if (!legend.itemWidth) legend.itemWidth = 100
                if (!legend.itemHeight) legend.itemHeight = 20
                if (!legend.itemsSpacing) legend.itemsSpacing = 2
                if (!legend.symbolSize) legend.symbolSize = 20
                if (!legend.itemDirection)
                    legend.itemDirection = 'left-to-right'
            })
        }

        if (isNaN(override.maxValue)) {
            override.maxValue = 'auto'
        }

        if (isNaN(override.minValue)) {
            override.minValue = 'auto'
        }

        this.setState({
            override,
        })
    }, 250)

    onChange = this.onChangeThrottled

    render() {
        const {
            props: { classes, component },
            state: { groups, override },
            handleClose,
            handleSave,
        } = this

        return (
            <Layout>
                <BaseFlutuante
                    title="Personalizar componente"
                    size="fullscreen"
                    onClose={handleClose}
                    scroll
                >
                    <Box
                        className={clsx(
                            classes.rootContainer,
                            classes.flexContainer
                        )}
                    >
                        <Box className={classes.flex}>
                            <Grid
                                container
                                spacing={1}
                                className={classes.blockHeight}
                            >
                                <Grid item xs={6}>
                                    <Typography variant="h3">
                                        Configurações de Personalização:
                                    </Typography>

                                    <Box className="GraphConfig-global">
                                        {groups && (
                                            <ComponentSettings
                                                component="Bar"
                                                flavors={['svg', 'canvas']}
                                                currentFlavor={'svg'}
                                                settings={override}
                                                onChange={this.onChange}
                                                groups={groups}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className={classes.graphColumn}
                                >
                                    <Typography variant="h3">
                                        Exemplo do Gráfico:
                                    </Typography>

                                    <SampleGraph
                                        settings={override}
                                        type={component?.type}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    <Box
                        className={clsx(classes.flexContainer, classes.actions)}
                    >
                        <Box className={classes.flex} />

                        <Box className={classes.right}>
                            <Button
                                lbl={'Cancelar'}
                                dialog
                                color="danger"
                                onClick={handleClose}
                            />
                            <Button
                                lbl={'Salvar'}
                                primary
                                dialog
                                onClick={handleSave}
                            />
                        </Box>
                    </Box>
                </BaseFlutuante>
            </Layout>
        )
    }
}

export default connect()(withStyles(styles)(GraphConfig))
