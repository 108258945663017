import moment from 'moment/moment'
import * as PropTypes from 'prop-types'
import React from 'react'

import keycodes from '../../../../common/Keycodes'
import { StyledKeyboardDatePicker } from '../../../../styled/DatePicker'
import AbstractTextField from './AbstractTextField'

/**
 * Componente TextFieldDate
 *
 * Rec: REC_DATE
 *
 * Renderiza um campo de texto que tem formatação de data
 * com datepicker
 */
export default class TextFieldDate extends AbstractTextField {
    static propTypes = {
        componentId: PropTypes.number,
        onClosePicker: PropTypes.func,
        onOpenPicker: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
    }

    views = ['year', 'month', 'date']
    format = 'YYYYMMDD'
    displayFormat = 'DD/MM/YYYY'
    sendFormat = 'DDMMYYYY'
    zeroedValue = this.sendFormat.replace(/./g, '0')

    constructor(props) {
        super(props)
        this.state = {
            value: props.value || '',
            isPickerOpen: false,
            steps: 0,
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.enabled !== nextProps.enabled ||
            this.props.value !== nextProps.value ||
            this.state.isPickerOpen !== nextState.isPickerOpen ||
            this.state.value !== nextState.value
        )
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps)

        const {
            props: { enabled, onClosePicker },
            state: { isPickerOpen },
        } = this

        if (!enabled && isPickerOpen) {
            this.setState({ isPickerOpen: false, steps: 0 })
            // dispatch(datePickerClose())
            onClosePicker && onClosePicker()
        }
    }

    toggleState = (isPickerOpen, callback) => {
        this.setState({ isPickerOpen }, callback)
        const input = this.getRef('input')
        if (!isPickerOpen && input && input.current) {
            input.current.focus()
        }
    }

    onDateChange = date => {
        if (date && date.format) {
            this.handleInputChange(
                {
                    target: {
                        value:
                            date && date.isValid()
                                ? date.format(this.format)
                                : date,
                    },
                },
                () => {
                    if (date.isValid()) {
                        this.triggerKeyCode(keycodes.ENTER_KEY)
                    }
                }
            )
        }
    }

    beforeSend = (value, event) => {
        if (event.keyCode === keycodes.ESCAPE_KEY && this.state.isPickerOpen) {
            event.stopImmediatePropagation && event.stopImmediatePropagation()
            event.stopPropagation()
        }

        const {
            format,
            sendFormat,
            zeroedValue,
            state: { value: actualValue },
            props: { onClosePicker },
        } = this

        if (value) {
            const sendValue = value.toString().replace(/\//g, '')
            const date = moment(actualValue, format)
            if (actualValue || sendValue) {
                if (date.isValid()) {
                    // this.props.dispatch(datePickerClose())
                    onClosePicker && onClosePicker()
                    return date.format(sendFormat)
                }
                return sendValue
            }
        }
        return zeroedValue
    }

    onOpen = () => {
        const { onOpenPicker } = this.props
        this.toggleState(true, () => {
            // this.props.dispatch(datePickerOpen())
            onOpenPicker && onOpenPicker()
        })
    }

    onClose = () => {
        const { onClosePicker } = this.props
        this.toggleState(false, () => {
            setTimeout(() => {
                // this.props.dispatch(datePickerClose())
                onClosePicker && onClosePicker()
            }, 100)
        })
    }

    onKeyDown = e => {
        e.preventDefault()
        e.stopPropagation()
        e.stopImmediatePropagation && e.stopImmediatePropagation()
    }

    render() {
        let {
            state: { value, isPickerOpen },
            props: {
                lbl,
                enabled,
                orientation = 'horizontal',
                x,
                y,
                inputWidth,
                labelWidth,
                componentId,
                InputProps,
                onBlur,
                onFocus,
            },
        } = this

        const fieldType = this.getFieldType()

        if (value === this.zeroedValue) {
            value = null
        }

        if (value && !(value instanceof moment)) {
            value = moment(value, this.format)
        }

        return (
            <StyledKeyboardDatePicker
                autoFocusOnBlur={false}
                orientation={orientation}
                value={value}
                fieldType={fieldType}
                disabled={!enabled}
                open={isPickerOpen}
                componentId={componentId}
                InputProps={InputProps}
                onOpen={this.onOpen}
                onClose={this.onClose}
                position={{ x, y }}
                format={this.displayFormat}
                views={this.views}
                onChange={this.onDateChange}
                onAccept={this.onDateChange}
                onFocus={onFocus}
                onBlur={onBlur}
                beforeSend={this.beforeSend}
                ref={this.createRef('input')}
                InputAdornmentProps={{
                    position: 'end',
                    onKeyDown: this.onKeyDown,
                }}
                inputWidth={inputWidth}
                labelWidth={labelWidth}
                label={lbl}
            />
        )
    }
}
