import makeStyles from '@material-ui/core/styles/makeStyles'
import useWS from '@nerus/framework/hooks/useWS'
import { styles } from '@nerus/styles/components/painelo2/pickingAndPacking'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { sendBuffer } from '../../../NerusWeb/Eac/EacActions'
import Box from './Box'
import BoxIcon from './Icones/Box'
import NoteIcon from './Icones/Note'
import PendenciaIcon from './Icones/Pendencia'
import ShoppingCartIcon from './Icones/ShoppingCart'

const useStyles = makeStyles(styles, { name: 'PainelO2-PickingAndPacking' })

export default function PickingAndPacking({ data }) {
    const classes = useStyles()
    const ws = useWS()
    const dispatchKey = useCallback(
        key => () => {
            ws.current.send(sendBuffer(key))
        },
        [ws]
    )

    const [
        { data: pedidos },
        { data: etiquetas },
        { data: separacao },
        { data: pendentes },
        { data: flexPendentes },
    ] = data.length
        ? data
        : [{ data: [] }, { data: [] }, { data: [] }, { data: [] }, { data: [] }]

    return (
        <Box title="Picking & Packing" bevel className={classes.root}>
            <Box
                title="PEDIDOS DO DIA"
                color="blue"
                icon={<ShoppingCartIcon />}
                value={pedidos[0]}
                details={{
                    title: 'Total pedidos do mês',
                    value: pedidos[1],
                }}
                onClick={dispatchKey('i')}
                pickingAndPacking={true}
            />

            <Box
                title="ETIQUETAS NÃO EMITIDAS DO DIA"
                color="green"
                icon={<NoteIcon />}
                value={etiquetas[0]}
                details={{
                    title: 'Etiquetas emitidas',
                    value: etiquetas[1],
                }}
                onClick={dispatchKey('a')}
                pickingAndPacking={true}
            />

            <Box
                title="EM SEPARAÇÃO"
                color="purple"
                icon={<BoxIcon />}
                value={separacao[0]}
                details={{
                    title: 'Pedidos prontos',
                    value: separacao[1],
                }}
                onClick={dispatchKey('c')}
                pickingAndPacking={true}
            />

            <Box
                title="PENDENTES DO DIA"
                color="blue-400"
                icon={<PendenciaIcon />}
                value={pendentes[0]}
                details={{
                    title: 'Total pendentes',
                    value: pendentes[1],
                }}
                onClick={dispatchKey('a')}
                pickingAndPacking={true}
            />

            <Box
                title="EM TRÂNSITO DO DIA"
                color="dark-green"
                icon={<PendenciaIcon fill="#008180" />}
                value={flexPendentes[0]}
                details={{
                    title: 'Total em trânsito',
                    value: flexPendentes[1],
                }}
                onClick={dispatchKey('b')}
                pickingAndPacking={true}
            />
        </Box>
    )
}

PickingAndPacking.propTypes = {
    data: PropTypes.any,
}
