import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiIconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import BaseFixa from '@nerus/framework/components/Base/Fixa'
import BaseFlutuante from '@nerus/framework/components/Base/Flutuante'
import IconButton from '@nerus/framework/components/Button/IconButton'
import DashboardIcon from '@nerus/framework/icons/Dashboard'
import * as PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { orderByOrderAndTitle } from '../../../../util/orderBy'
import { setWsStop } from '../../../App/AppActions'
import { getMenu, getNerusInfo } from '../../../NerusWeb/Eac/EacReducer'
import FilterHandler from '../Actions/FilterHandler'
import withQueryReport from '../old/Reports/graphql/withQueryReport'
import DashboardItem from './DashboardItem'
import withDashboardRender from './graphql/withDashboardRender'
import RefreshButton from './RefreshButton'

class DashboardRender extends Component {
    static propTypes = {
        binario: PropTypes.object,
        dashboardRender: PropTypes.object,
        nerusInfo: PropTypes.object,
        menu: PropTypes.object,
        asModal: PropTypes.bool,
        active: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        showBase: PropTypes.bool,
        showSidebar: PropTypes.bool,
        handleToggle: PropTypes.func,
        isPainelO2: PropTypes.bool,
    }

    static mapStateToProps = (state, ownProps) => {
        return {
            nerusInfo: getNerusInfo(state),
            menu: getMenu(state) || ownProps.menu,
            showSidebar: state.app.showSidebar,
        }
    }

    state = {
        show: false,
        loaded: false,
    }

    componentDidUpdate() {
        const data = this.props.dashboardRender?.dashboardRender?.data
        if (data?.[0] && !this.state.loaded) {
            this.setState({
                loaded: true,
            })
        }
    }

    doShow = lock => () => {
        this.props.dispatch?.(setWsStop(lock))
        this.setState(state => ({ show: !state.show }))
    }

    onSaveFilters = activeFilters => {
        const { dashboardRender, menu, nerusInfo } = this.props
        dashboardRender.refetch({
            menu: menu?.title,
            binario: nerusInfo.name ? nerusInfo.name : 'nerusweb',
            user_id: nerusInfo.usrno ? String(nerusInfo.usrno) : null,
            activeFilters,
        })
    }

    renderDashboard = dashboard_map => {
        const { dashboardRender, handleToggle, isPainelO2 = false } = this.props

        const filters = Object.values(
            dashboardRender?.dashboardRender?.activeFilters || {}
        )

        return (
            <Fragment>
                {dashboard_map?.[0]?.refresh ||
                filters.length ||
                handleToggle ? (
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        pb={1}
                        pt={1.5}
                    >
                        {filters.length ? (
                            <FilterHandler
                                filters={filters}
                                activeFilters={
                                    dashboardRender?.dashboardRender
                                        ?.activeFilters
                                }
                                showChips
                                ignoreChanges
                                onSave={this.onSaveFilters}
                            />
                        ) : null}

                        {handleToggle ? (
                            <MuiIconButton onClick={handleToggle}>
                                <ArrowBack />
                            </MuiIconButton>
                        ) : null}

                        {dashboard_map?.[0]?.refresh ? (
                            <RefreshButton
                                onRefresh={dashboardRender.refetch}
                                every={dashboard_map[0].refresh}
                                loading={dashboardRender?.loading}
                            />
                        ) : null}
                    </Box>
                ) : null}

                <Grid container spacing={2} style={{ overflow: 'auto' }}>
                    {dashboard_map
                        ? dashboard_map.sort(orderByOrderAndTitle).map(item => {
                              return (
                                  <DashboardItem
                                      key={item.id}
                                      {...item}
                                      queryReport={{
                                          queryReport: {
                                              data: item.data,
                                          },
                                      }}
                                      previewer={false}
                                      config={false}
                                      isPainelO2={isPainelO2}
                                  />
                              )
                          })
                        : ''}
                </Grid>
            </Fragment>
        )
    }

    render() {
        const {
            dashboardRender,
            active = true,
            asModal = false,
            showBase = true,
        } = this.props
        const { show = false, loaded = false } = this.state
        let dashboard_map = []

        if (dashboardRender?.loading && !asModal && !loaded) {
            return 'Carregando dashboard...'
        }

        if (dashboardRender?.dashboardRender?.data) {
            dashboard_map = dashboardRender.dashboardRender.data
        }

        if (asModal) {
            return (
                <Fragment>
                    <IconButton
                        className="dashboard"
                        size="icon"
                        variant="default"
                        onClick={this.doShow(true)}
                        disabled={dashboard_map.length === 0}
                        skipTooltip={dashboard_map.length === 0}
                        tooltip={'Exibir dashboard'}
                    >
                        <DashboardIcon />
                    </IconButton>{' '}
                    {show && active ? (
                        <BaseFlutuante
                            title="Dashboard"
                            size="fullscreen"
                            onClose={this.doShow(false)}
                            className="pdv-report-content"
                        >
                            {this.renderDashboard(dashboard_map)}
                        </BaseFlutuante>
                    ) : null}
                </Fragment>
            )
        }

        const dashboard = this.renderDashboard(dashboard_map)
        if (showBase) {
            return <BaseFixa>{dashboard}</BaseFixa>
        }

        return dashboard
    }
}

export default connect(DashboardRender.mapStateToProps)(
    withDashboardRender(withQueryReport(DashboardRender))
)
