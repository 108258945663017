import withStyles from '@material-ui/core/styles/withStyles'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import BaseFixa from '@nerus/framework/components/Base/Fixa'
import { styles } from '@nerus/styles/layout'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import DashboardRender from '../../../NerusIT/Dashboard/DashboardRender'
import KeyboardHandle from '../../Business/Keyboard'
import { WebsocketContext, withWS } from '../../Business/Websocket/Context'
import Components from '../../Components'
import About from '../../Components/About'
import Calculator from '../../Components/Calculator'
import EditorFormEditPdv from '../../Components/EditorFormEditPdv'
import Fingerprint from '../../Components/Fingerprint'
import BaseFlutuante from '../../Components/Flutuante/BaseFlutuante'
import PainelO2 from '../../Components/PainelO2'
import TopBar from '../../Components/TopBar'
import {
    checkIsMenuPrincipal,
    getActiveComponent,
    getDialogoProgressao,
    getMessageWindow,
    getLastActiveComponent,
    getLastFixedComponentId,
    getMenu,
    getNerusInfo,
    hasComponentOfType,
} from '../EacReducer'
import AlreadyConnected from './AlreadyConnected'
import Login from './Login'
import Logout from './Logout'
import TooMuchRetries from './TooMuchRetries'
import wsDisconnect from '../../../../util/wsDisconnect'

// ...
export class Index extends EnhancedComponent {
    static contextType = WebsocketContext

    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        app: PropTypes.object,
        menusLifo: PropTypes.array,
        components: PropTypes.object,
        lastActiveComponent: PropTypes.object,
        isMenuPrincipal: PropTypes.bool,
        showSidebar: PropTypes.bool,
        hideMenu: PropTypes.bool,
        isLogged: PropTypes.bool,
        showAbout: PropTypes.bool,
        showCalculator: PropTypes.bool,
        dialogoProgressao: PropTypes.object,
        messageWindow: PropTypes.object,
        messageWindowParentId: PropTypes.number,
        isLogin: PropTypes.bool,
        componentsLifo: PropTypes.array,
        componentsFixedLifo: PropTypes.array,
        lastFixedComponentId: PropTypes.number,
        hasMenu: PropTypes.bool,
        login: PropTypes.any,
        isPdv: PropTypes.bool,
        hasPdv: PropTypes.bool,
        hasFingerprint: PropTypes.bool,
    }

    static mapStateToProps = state => {
        return {
            // componentes globais
            showAbout: state.app.showAbout,
            showCalculator: state.app.showCalculator,
            showSidebar: state.app.showSidebar,

            // alguns estados da aplicação
            isLocked: state.app.isLocked,
            isLoading: state.app.isLoading,
            isAlive: state.eac.isAlive,
            isConnected: state.eac.connected,
            isLogin: state.eac.isLogin,
            isLogged: state.eac.isLogged,
            isLogout: state.eac.isLogout,
            isAlreadyConnected: state.eac.isAlreadyConnected,
            isTooMuchRetries: state.eac.isTooMuchRetries,
            isPdv: state.app.isPdv,
            isO2: state.eac.painelO2,

            // Lista de componentes, ordem e componente ativo
            activeComponent: getActiveComponent(state),
            components: state.eac.components,
            componentsLifo: state.eac.componentsLifo,
            componentsFixedLifo: state.eac.componentsFixedLifo,
            hasPdv: hasComponentOfType('EditorFormEditPdv', state),
            hasMenu: hasComponentOfType('Menu', state),
            hasFingerprint: state.eac.fingerprint !== null,
            dialogoProgressao: getDialogoProgressao(state),
            messageWindow: getMessageWindow(state),
            messageWindowParentId: state.eac.MessageWindowParentId,
            menu: getMenu(state),
            isMenuPrincipal: checkIsMenuPrincipal(state),
            nerusInfo: getNerusInfo(state),
            menusLifo: state.eac.menusLifo,
            lastActiveComponent: getLastActiveComponent(state),
            lastFixedComponentId: getLastFixedComponentId(state),

            /**
             * Se há um componente Dialogo ou Formulario, devemos
             * esconder o Menu
             */
            hideMenu:
                hasComponentOfType('Dialogo', state) ||
                hasComponentOfType('Formulario', state),
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            ws: null,
        }
    }

    componentDidMount() {
        window.addEventListener('storage', this.handleStorage)
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.handleStorage)
    }

    // Fecha a conexão de uma sessão cujo binario é pvNFCE caso ela receba in_use false
    handleStorage = event => {
        wsDisconnect(event, this)
    }

    renderComponents = () => {
        const {
            ws,
            componentsLifo,
            components,
            isPdv,
            showCalculator,
            lastFixedComponentId,
        } = this.props

        return componentsLifo.map(id => {
            const component = components[id]
            if (!component) {
                return
            }

            const opts = {
                key: `${component.name}-${id}`,
                id,
                ws,
                data: component.payload,
            }

            let Comp = Components[component.name]
            if (Comp) {
                const baseOpts = {
                    ...(Comp.baseOpts || {}),
                    key: `base-${component.name}-${id}`,
                    showCalculator,
                }

                /**
                 * Se estamos no Pdv devemos subir 2 espacamentos para ocultar
                 * informacoes que acabam ficando como lixo
                 */
                if (
                    isPdv &&
                    component.name !== 'EditorFormEditPdv' &&
                    opts.data.flt === false
                ) {
                    baseOpts.adjustTop = true
                }

                const Base =
                    opts.data.flt === true || opts.data.flt === undefined
                        ? BaseFlutuante
                        : BaseFixa

                if (
                    Base === BaseFixa &&
                    lastFixedComponentId &&
                    lastFixedComponentId !== id
                ) {
                    return
                }
                /**
                 * Se o title e' verdadeiro, buscamos nos locais comuns
                 * data.title e o defaultTItle (esse comportamento e' legado)
                 * Se o title e' uma funcao usamos o retorno dessa funcao para
                 * definir o titulo
                 */
                if (baseOpts.title === true) {
                    baseOpts.title = null
                    if (baseOpts.defaultTitle) {
                        baseOpts.title = baseOpts.defaultTitle
                    }
                    if (opts.data.title) {
                        baseOpts.title = opts.data.title
                    }
                }
                if (typeof baseOpts.title === 'function') {
                    baseOpts.title = baseOpts.title(opts.data)
                }
                if (baseOpts.title) {
                    // @TODO: Unificar propriedade
                    opts.data.hideTitle = true
                    opts.data.showTitle = false
                }
                if (typeof baseOpts.size === 'function') {
                    baseOpts.size = baseOpts.size(opts.data)
                }
                const Component = <Comp {...opts} />
                if (baseOpts.skip) {
                    return Component
                }
                return <Base {...baseOpts}>{Component}</Base>
            }

            return null
        })
    }

    render() {
        const {
            isLogged,
            showAbout,
            showCalculator,
            dialogoProgressao,
            messageWindow,
            isLogin,
            isLogout,
            isTooMuchRetries,
            isAlreadyConnected,
            isPdv,
            hasPdv,
            componentsLifo,
            isO2,
            hasFingerprint,
        } = this.props

        const { hasError } = this.state

        if (hasError) {
            return this.renderError()
        }

        const { renderComponents } = this
        if (isAlreadyConnected || isTooMuchRetries || isLogout || !isLogged) {
            return (
                <KeyboardHandle
                    disabled={!componentsLifo?.length}
                    ws={this.props.ws}
                >
                    {isTooMuchRetries && <TooMuchRetries ws={this.props.ws} />}
                    {isAlreadyConnected && (
                        <AlreadyConnected ws={this.props.ws} />
                    )}
                    {isLogout && !isTooMuchRetries && !isAlreadyConnected && (
                        <Logout ws={this.props.ws} />
                    )}
                    {!isLogged &&
                        !isLogout &&
                        !isTooMuchRetries &&
                        !isAlreadyConnected && (
                            <Fragment>
                                {renderComponents(true)}
                                <Login ws={this.props.ws} />
                            </Fragment>
                        )}
                </KeyboardHandle>
            )
        }

        const component = renderComponents()
        return (
            <KeyboardHandle disabled={isLogin} ws={this.props.ws}>
                <Fragment>
                    {showAbout && <About />}
                    {showCalculator && <Calculator />}
                    {dialogoProgressao && <Components.DialogoProgressao />}
                    {hasFingerprint && <Fingerprint />}

                    {!isLogin ? (
                        <Fragment>
                            {!isLogin ? <TopBar isO2={isO2} /> : null}

                            <Components.Menu />

                            <Components.Content>
                                {// TODO: Mover pra abordagem que evita erros do NerusIT no NerusWeb
                                window.NERUS_CONFIG.dashboard.use && !isO2 ? (
                                    <Switch>
                                        <Route
                                            path="/"
                                            exact={true}
                                            component={DashboardRender}
                                        />
                                    </Switch>
                                ) : null}

                                {isO2 ? <PainelO2 /> : null}

                                {!hasPdv && isPdv ? (
                                    <BaseFixa>
                                        <EditorFormEditPdv
                                            isFixo={true}
                                            active={false}
                                        />
                                    </BaseFixa>
                                ) : (
                                    ''
                                )}

                                {component}
                            </Components.Content>

                            {messageWindow && <Components.MessageWindow />}
                        </Fragment>
                    ) : null}
                </Fragment>
            </KeyboardHandle>
        )
    }
}

const ConnectedIndex = connect(Index.mapStateToProps)(
    withStyles(styles)(withWS(Index))
)

export default ConnectedIndex
