import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import makeStyles from '@material-ui/styles/makeStyles'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 300,
    },
    label: {
        top: '-15px !important',
    },
    inputRoot: {
        marginTop: '0 !important',
    },
    svg: {
        verticalAlign: 'middle',
    },
}))

export default function Search({ onChange }) {
    const classes = useStyles()
    const [search, setSearch] = useState('')

    const label = useMemo(() => {
        return (
            <React.Fragment>
                <SearchIcon className={classes.svg} /> Digite para iniciar a
                busca...
            </React.Fragment>
        )
    }, [classes])

    const InputLabelProps = useMemo(() => {
        return {
            classes: {
                root: classes.label,
            },
        }
    }, [classes])

    const InputProps = useMemo(() => {
        return {
            classes: {
                root: classes.inputRoot,
            },
        }
    }, [classes])

    const handleChange = useCallback(
        event => {
            const value = event.target.value
            onChange && onChange(value)
            setSearch(value)
        },
        [onChange]
    )

    return (
        <TextField
            autoFocus
            margin="none"
            className={classes.root}
            label={label}
            value={search}
            onChange={handleChange}
            type="search"
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
        />
    )
}

Search.propTypes = {
    onChange: PropTypes.func.isRequired,
}
