import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'

export const SAVE_PICTURE = gql`
    mutation SavePicture(
        $prdno: String
        $fkno: Int
        $custno: Int
        $asrmano: Int
        $prdSerie: String
        $grade: String
        $table: String
        $foto: String!
        $pictureTablesMap: Any
    ) {
        createPicture(
            prdno: $prdno
            fkno: $fkno
            custno: $custno
            asrmano: $asrmano
            prdSerie: $prdSerie
            grade: $grade
            table: $table
            foto: $foto
            pictureTablesMap: $pictureTablesMap
        ) {
            seqnoAuto
            seqno
            fkno
            prdno
            custno
            asrmano
            prdSerie
            foto
            urlImagem
        }
    }
`

export default Component => {
    return graphql(SAVE_PICTURE, {
        name: 'createPicture',
        fetchPolicy: 'cache-and-network',
    })(Component)
}
