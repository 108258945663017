import { withStyles } from '@material-ui/core/styles'
import { colorSchemes } from '@nivo/colors'
import * as echarts from 'echarts'
import * as PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import getFormat from './common/getFormat'

// import { initialProperties as initialBarProps } from '../Dashboard/Actions/GraphConfig/defaults/bar'
// import { initialProperties as initialLineProps } from '../Dashboard/Actions/GraphConfig/defaults/line'
// import GraphTooltip from '../Dashboard/Components/GraphTooltip'
// import getFormat from './common/getFormat'
// import getGraphProps from './getGraphProps'

const styles = {
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
}

function GraficoBarraLinha({ axes, classes, data, ...rest }) {
    const ref = useRef()
    const instance = useRef()
    const [bar, line] = data

    const formatter = function(args) {
        const { axisDimension, axisIndex, value } = args
        if (axisDimension === 'y') {
            return getFormat(axes?.[axisIndex === 0 ? 'x' : 'y']?.format)(
                value.toFixed(2)
            )
        }
        return value
    }

    useEffect(() => {
        const onResize = () => {
            instance.current && instance.current.resize()
        }
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    })

    useEffect(() => {
        instance.current && instance.current.resize()
    }, [instance.current, rest])

    useEffect(() => {
        if (ref.current) {
            if (!instance.current) {
                instance.current = echarts.init(ref.current)
            }

            if (!bar || !line || !bar.data || !line.data) {
                // ignora
            } else {
                instance.current.setOption({
                    color: colorSchemes.NerusWeb,
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999',
                            },
                        },
                        formatter: params => {
                            return `
                                <div style="margin: 0px 0 0;line-height:1;">
                                    <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">
                                        ${params[0].axisValue}
                                    </div>
                                    <div style="margin: 10px 0 0;line-height:1;">
                                        <div style="margin: 0px 0 0;line-height:1;">
                                            <div style="margin: 0px 0 0;line-height:1;">
                                                ${params[0].marker}
                                                <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">
                                                    ${params[0].seriesName}
                                                </span>
                                                <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">
                                                    ${getFormat(
                                                        axes?.x?.format
                                                    )(params[0].value)}
                                                </span>
                                                <div style="clear:both"></div>
                                            </div>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div style="margin: 10px 0 0;line-height:1;">
                                            <div style="margin: 0px 0 0;line-height:1;">
                                                ${params[1].marker}
                                                <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">
                                                    ${params[1].seriesName}
                                                </span>
                                                <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">
                                                    ${getFormat(
                                                        axes?.y?.format
                                                    )(params[1].value)}
                                                </span>
                                                <div style="clear:both"></div>
                                            </div>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div style="clear:both"></div>
                                    </div>
                                    <div style="clear:both"></div>
                                </div>
                            `
                        },
                    },
                    toolbox: {
                        feature: {
                            dataView: { show: false, readOnly: false },
                            magicType: { show: false, type: ['line', 'bar'] },
                            restore: { show: false },
                            saveAsImage: { show: false },
                        },
                    },
                    legend: {
                        availableNames: [bar.legends[1], line.legends[1]],
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: bar.data.map(r => r.x),
                            axisPointer: {
                                type: 'line',
                                label: {
                                    formatter,
                                },
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: bar.legends[1],
                            axisLabel: {
                                formatter: function(value) {
                                    return getFormat(axes?.x?.format)(value)
                                },
                            },
                            axisPointer: {
                                label: {
                                    formatter,
                                },
                            },
                        },
                        {
                            type: 'value',
                            name: line.legends[1],
                            position: 'right',
                            axisLabel: {
                                formatter: function(value) {
                                    return getFormat(axes?.y?.format)(value)
                                },
                            },
                            axisPointer: {
                                label: {
                                    formatter,
                                },
                            },
                        },
                    ],
                    series: [
                        {
                            name: bar.legends[1],
                            type: 'bar',
                            data: bar.data.map(r => r.y),
                        },
                        {
                            name: line.legends[1],
                            type: 'line',
                            yAxisIndex: 1,
                            data: line.data.map(r => r.y),
                        },
                    ],
                })
            }
        }

        return () => {}
    }, [ref, bar, line])

    return <div ref={ref} className={classes.root} />
}

GraficoBarraLinha.propTypes = {
    classes: PropTypes.any,
    data: PropTypes.array,
    axes: PropTypes.any,
}

export default withStyles(styles)(GraficoBarraLinha)
