import useWS from '@nerus/framework/hooks/useWS'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import getNowDate from '../../../../util/getNowDate'
import { sendBuffer } from '../../../NerusWeb/Eac/EacActions'
import Box from './Box'
import Status from './Status'

export default function Fulfillment({ data }) {
    const ws = useWS()
    const dispatchKey = useCallback(
        key => () => {
            ws.current.send(sendBuffer(key))
        },
        [ws]
    )

    const status = ['sucesso']
    const atualizacao = getNowDate()

    const [
        { data: remessa },
        { data: retorno },
        { data: venda },
        { data: devolucao },
        { data: cancelados },
        { data: cancelados_entrada },
        { data: cte },
        { data: inutilizacao },
        // { data: atualizacao = now },
        // { data: status = 'sucesso' },
    ] = data?.length
        ? data
        : [
              { data: [] },
              { data: [] },
              { data: [] },
              { data: [] },
              { data: [] },
              { data: [] },
              { data: [] },
              { data: [] },
              //  { data: 'Carregando...' },
              //  { data: 'erro' },
          ]

    return (
        <Box
            title={[
                'Operações Fulfillment',
                <Status key="status" status={status[0]} />,
                <div
                    key="update"
                    style={{
                        textAlign: 'right',
                        float: 'right',
                        fontWeight: 'normal',
                        color: '#B2B7BD',
                        fontSize: 10,
                    }}
                >
                    Última atualização:{' '}
                    {
                        atualizacao //21/08/2021 - 19:32
                    }
                </div>,
            ]}
            bevel
        >
            <Box
                title="NF REMESSA"
                color="blue-800"
                value={remessa[0]}
                onClick={dispatchKey('j')}
                fullfillment={true}
            />
            <Box
                title="NF RETORNO"
                color="blue-700"
                value={retorno[0]}
                onClick={dispatchKey('k')}
                fullfillment={true}
            />
            <Box
                title="NF VENDA"
                color="blue-600"
                value={venda[0]}
                onClick={dispatchKey('j')}
                fullfillment={true}
            />
            <Box
                title="NF DEVOLUÇÃO"
                color="blue-500"
                value={devolucao[0]}
                onClick={dispatchKey('k')}
                fullfillment={true}
            />
            <Box
                title="NF CANCELADA SAÍDA"
                color="blue-400"
                value={cancelados[0]}
                onClick={dispatchKey('j')}
                fullfillment={true}
            />
            <Box
                title="NF CANCELADA ENTRADA"
                color="blue-300"
                value={cancelados_entrada[0]}
                onClick={dispatchKey('k')}
                fullfillment={true}
            />
            <Box
                title="CTEs"
                color="blue-200"
                value={cte[0]}
                onClick={dispatchKey('k')}
                fullfillment={true}
            />
            <Box
                title="INUTILIZAÇÃO"
                color="blue-100"
                value={inutilizacao[0]}
                fullfillment={true}
            />
        </Box>
    )
}

Fulfillment.propTypes = {
    data: PropTypes.any,
}
