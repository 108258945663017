import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import * as PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: `0 -1px 0 ${theme.palette.primary.main} inset`,
        fontSize: 14,
        padding: '2px 0',
        fontWeight: 'bold',
    },
}))

const Title = ({ label = 'Último Produto' }) => {
    const classes = useStyles()
    return <Typography className={classes.root}>{label}</Typography>
}

Title.propTypes = {
    label: PropTypes.string,
}

export default Title
