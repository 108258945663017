import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/formulario/skip'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import Label from '../../../../styled/Label'
import AbstractTextField from './AbstractTextField'

/**
 * Componente TextFieldSkip
 *
 * Rec: REC_SKIP
 *
 * Se for um header, renderizamos como um
 * titulo no formulário
 */
export class TextFieldSkip extends AbstractTextField {
    static propTypes = {
        lbl: PropTypes.string.isRequired,
        isTitle: PropTypes.bool,
        labelWidth: PropTypes.number,
        actions: PropTypes.any,
        tabbedText: PropTypes.any,
        row: PropTypes.any,
        inline: PropTypes.bool,
    }

    static withoutWrapper = true

    render() {
        const {
            classes,
            header,
            lbl,
            enabled,
            isTitle,
            labelWidth,
            attrib,
            inline,
        } = this.props

        return (
            <div
                className={clsx({
                    [classes.root]: isTitle && attrib === 0,
                    [classes.titleRoot]: isTitle && attrib > 0,
                    [classes.disabled]: !enabled,
                    [classes.input]: !isTitle,
                    [classes.inline]: inline,
                })}
            >
                <Label
                    text={lbl}
                    header={header}
                    style={{ minWidth: labelWidth }}
                    className={clsx({
                        // [classes.label]: !header,
                        [classes.title]: isTitle && attrib > 0,
                        [classes.enabled]: !isTitle && enabled,
                    })}
                />
            </div>
        )
    }
}

export default withStyles(styles)(TextFieldSkip)
