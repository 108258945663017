import makeStyles from '@material-ui/styles/makeStyles'
import EditorFormEditBody from '@nerus/framework/components/EditorFormEdit/Estrutura/Body'
import EditorFormEditHeader from '@nerus/framework/components/EditorFormEdit/Estrutura/Header'
import { styles } from '@nerus/styles/components/editor'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(styles)

export default function EditorFormEdit(props) {
    const classes = useStyles()

    const {
        columns,
        onDoubleClick,
        onRemove,
        onInsert,
        active = true,
        rows = [],
    } = props

    return (
        <div
            key="grid"
            className={clsx({
                [classes.root]: true,
            })}
        >
            <div key="gridtable" className={clsx(classes.editor, {})}>
                <div
                    key="grid-table"
                    className={clsx(classes.content, classes.contentFlt, {})}
                >
                    <table
                        key="table-element"
                        className={clsx(classes.table, {})}
                    >
                        <EditorFormEditHeader
                            columns={columns}
                            onRemove={onRemove}
                        />

                        <EditorFormEditBody
                            onRemove={onRemove}
                            columns={columns}
                            active={active}
                            onInsert={onInsert}
                            onDoubleClick={onDoubleClick}
                            rows={rows}
                        />
                    </table>
                </div>
            </div>
        </div>
    )
}

EditorFormEdit.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            size: PropTypes.number,
        })
    ),
    rows: PropTypes.arrayOf(PropTypes.any),
    active: PropTypes.bool,
    onDoubleClick: PropTypes.func,
    onInsert: PropTypes.func,
    onRemove: PropTypes.func,
}
