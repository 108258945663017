import PageTest from '../App/pages/Test'
import EacIndex from './Eac/pages/Index'

export const routes = [
    {
        path: '/',
        exact: true,
        component: EacIndex,
    },
    {
        path: '/bin/:binario',
        exact: true,
        component: EacIndex,
    },
    {
        path: '/config/:binario',
        exact: true,
        component: EacIndex,
    },
    {
        path: '/test',
        exact: true,
        component: PageTest,
    },
]

export default routes

// For hot reloading of react components
// Webpack Hot Module Replacement API
if (module.hot) {
    module.hot.accept()
}
