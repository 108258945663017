import makeStyles from '@material-ui/core/styles/makeStyles'
import { styles } from '@nerus/styles/components/painelo2/conexao-status'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(styles, { name: 'PainelO2-Status' })

export default function Status({ status, details }) {
    const classes = useStyles()

    return (
        <span
            className={clsx(classes.colorBall, {
                [classes.colorConnected]: status === 'sucesso',
                [classes.colorWarning]: status === 'alerta',
                [classes.colorError]: status === 'erro',
                [classes.noMargin]: details,
            })}
        />
    )
}

Status.propTypes = {
    status: PropTypes.string,
    details: PropTypes.bool,
}
