import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const ENOTICE_QUERY = gql`
    query findEnoticeById($enotno: Int!) {
        findEnoticeById(enotno: $enotno) {
            error
            message
            data {
                enotno
                userno
                title
                operator
                value1
                value2
                sql
                send
                subject
                message
                time
                ativo
                envio
                agenda
                agendaMes
                agendaDia
                agendaDiaDoMes
                horario
                cc
                bcc
                sender
                ccSql
                toSql
                grupo
                attach
                envioSms
                emailHtml
                folderno
                operadora
                operadoraurl
                inibeEmptyEnotice
                i1
                i2
                i3
            }
        }
    }
`

export default function useFetchEnotice(options) {
    return useQuery(ENOTICE_QUERY, {
        displayName: 'fetchEnotice',
        fetchPolicy: 'cache-and-network',
        ...options,
    })
}
