import keycodes from '@nerus/framework/common/Keycodes'
import BaseFlutuante from '@nerus/framework/components/Base/Flutuante'
import BoxSeletor from '@nerus/framework/components/BoxSeletor'
import Formulario from '@nerus/framework/components/Formulario'
import Hint from '@nerus/framework/components/Hint'
import Button from '@nerus/framework/styled/Button'
import cloneDeep from 'lodash/cloneDeep'
import * as PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'

import { sendBuffer } from '../../Eac/EacActions'

const descriptionFields = [
    {
        type: 'TextFieldWeb',
        lbl: 'No. máximo de campos:',
        name: 'maxflds',
        value: '0',
        id: 1,
        typeRec: 'REC_STRING',
        typeRecMod: 'd',
        sz: 40,
        y: 0,
        x: 0,
    },
    {
        type: 'TextFieldWeb',
        lbl: 'No. de Campos:',
        name: 'nbflds',
        value: '0',
        id: 2,
        typeRec: 'REC_STRING',
        typeRecMod: 'd',
        sz: 40,
        y: 0,
        x: 1,
    },
    {
        type: 'TextFieldWeb',
        lbl: 'Tamanho:',
        name: 'sz',
        value: '0',
        id: 3,
        typeRec: 'REC_STRING',
        typeRecMod: 'd',
        sz: 86,
        y: 2,
        x: 0,
    },
    {
        type: 'TextFieldWeb',
        lbl: 'Espaço:',
        name: 'space',
        value: '0',
        id: 4,
        typeRec: 'REC_STRING',
        typeRecMod: 'd',
        sz: 86,
        y: 2,
        x: 1,
    },
    {
        type: 'TextFieldWeb',
        lbl: 'Tamanho total:',
        name: 'totalsz',
        value: '107',
        id: 5,
        typeRec: 'REC_STRING',
        typeRecMod: 'd',
        sz: 86,
        y: 2,
        x: 2,
    },
]

const formFieldsEdit = [
    {
        type: 'TextFieldWeb',
        lbl: 'Descrição Original',
        name: 'name',
        value: '',
        enabled: 0,
        id: 1,
        typeRec: 'REC_STRING',
        typeRecMod: 'a',
        sz: 60,
        y: 0,
        x: 0,
    },
    {
        type: 'TextFieldWeb',
        lbl: 'Descrição',
        name: 'nameEdit',
        value: '',
        enabled: 1,
        id: 2,
        typeRec: 'REC_STRING',
        typeRecMod: 'a',
        sz: 60,
        y: 1,
        x: 0,
    },
    {
        type: 'TextFieldWeb',
        lbl: 'Tamanho',
        name: 'sz',
        value: '',
        enabled: 1,
        id: 3,
        typeRec: 'REC_SHORT',
        typeRecMod: 'd',
        sz: 2,
        y: 2,
        x: 0,
    },
    {
        type: 'TextFieldWeb',
        lbl: 'Espaço',
        name: 'space',
        value: '',
        enabled: 1,
        id: 4,
        typeRec: 'REC_STRING',
        typeRecMod: 'a',
        sz: 75,
        y: 3,
        x: 0,
    },
    {
        type: 'TextFieldWeb',
        lbl: 'Configuração Inicial',
        name: 'pre',
        value: '',
        enabled: 1,
        id: 5,
        typeRec: 'REC_STRING',
        typeRecMod: 'a',
        sz: 75,
        y: 4,
        x: 0,
    },
    {
        type: 'TextFieldWeb',
        lbl: 'Configuração Final',
        name: 'post',
        value: '',
        enabled: 1,
        id: 6,
        typeRec: 'REC_STRING',
        typeRecMod: 'a',
        sz: 75,
        y: 5,
        x: 0,
    },
    {
        type: 'TextFieldWeb',
        lbl: 'Formato de Impressão',
        name: 'fmt',
        value: '',
        enabled: 1,
        id: 7,
        typeRec: 'REC_MENU',
        typeRecMod: 'a',
        options: {},
        sz: 75,
        y: 6,
        x: 0,
    },
]

const formFields = [
    {
        type: 'TextFieldWeb',
        lbl: 'Título',
        name: 'title',
        value: '',
        enabled: 1,
        id: 1,
        typeRec: 'REC_STRING',
        typeRecMod: 'a',
        sz: 60,
        y: 0,
        x: 0,
    },
    {
        type: 'TextFieldWeb',
        lbl: 'Sub-Título',
        name: 'subtitle',
        value: '',
        enabled: 1,
        id: 2,
        typeRec: 'REC_STRING',
        typeRecMod: 'a',
        sz: 60,
        y: 1,
        x: 0,
    },
    {
        type: 'TextFieldWeb',
        lbl: 'Colunas',
        name: 'cols',
        value: '',
        enabled: 1,
        id: 3,
        typeRec: 'REC_SHORT',
        typeRecMod: 'd',
        sz: 3,
        y: 2,
        x: 0,
    },
]

/**
 * Classe base do RelatorioDialog
 */
export default class BaseRelatorioDialogGerador extends Component {
    static propTypes = {
        data: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
        ws: PropTypes.object.isRequired,
    }

    static baseOpts = {
        skip: true,
    }

    fields = 0

    constructor(props) {
        super(props)

        const fields = [...props.data.printFields].reduce(
            (fields, field, currentIndex) => {
                const index = `${currentIndex}-${field?.name?.trim()}`
                let fmtTypes = undefined
                props.data.repFields.forEach(f => {
                    if (f.name?.trim() === field?.name?.trim()) {
                        fmtTypes = f.fmtTypes
                        return true
                    }
                })

                return {
                    ...fields,
                    [index]: {
                        id: index,
                        ...field,
                        fmtTypes,
                    },
                }
            },
            {}
        )

        this.state = {
            selected: Object.values(fields),
            fields,
            editField: null,
            report: {
                title: props.data.title.trim(),
                subtitle: props.data.subtitle.trim(),
                cols: props.data.cols,
            },
        }

        this.formFields = cloneDeep(formFields)
        this.formFields[0].value = props.data.title.trim()
        this.formFields[1].value = props.data.subtitle.trim()
        this.formFields[2].value = props.data.cols

        this.descriptionFields = cloneDeep(descriptionFields)

        this.updateDescription()
    }

    updateDescription = fields => {
        const data = this.props.data

        const printFields = Object.values(fields || this.state.fields)

        const newDesc = cloneDeep(this.descriptionFields)
        // nr max de campos
        newDesc[0].value = data.repFields?.length || 0
        // nr de campos
        newDesc[1].value = printFields?.length || 0
        // length size
        newDesc[2].value = printFields.reduce((acc, next) => {
            return acc + parseInt(next.sz)
        }, 0)
        // total space
        newDesc[3].value = printFields.reduce((acc, next) => {
            return acc + parseInt(next.space)
        }, 0)
        // total size
        newDesc[4].value = newDesc[2].value + newDesc[3].value
        this.descriptionFields = newDesc
    }

    onChangeFields = selected => {
        const fields = {}

        selected.forEach((sel, index) => {
            sel.id = `${index}-${sel?.name?.trim()}`

            fields[sel.id] = sel

            if (!fields[sel.id]['nameEdit']) {
                fields[sel.id]['name'] = sel.value
                fields[sel.id]['nameEdit'] = sel.value
                fields[sel.id]['sumField'] = false
                fields[sel.id]['sumTotal'] = false
            }
        })

        // Trecho antigo do código antes do ticket 98407
        // selected.forEach((sel, index) => {
        //     let id = sel.id
        //     if (!id) {
        //         id = `${Object.keys(fields).length}-${sel.name}`
        //         sel.id = id
        //     }
        //     if (!fields[id]) {
        //         fields[id] = selected[index]
        //         if (!fields[id]['nameEdit']) {
        //             fields[id]['name'] = fields[id].value
        //             fields[id]['nameEdit'] = fields[id].value
        //             fields[id]['sumField'] = false
        //             fields[id]['sumTotal'] = false
        //         }
        //     }
        // })

        const activeIds = Object.keys(fields)
        const receivedIds = selected.map(sel => sel.id)

        activeIds.forEach(id => {
            if (receivedIds.indexOf(id) < 0) {
                delete fields[id]
            }
        })

        this.setState(
            () => {
                this.updateDescription(fields)

                return {
                    selected,
                    fields,
                }
            },
            () => {
                setTimeout(() => {
                    this.forceUpdate()
                }, 0)
            }
        )
    }

    onSave = () => {
        this.props.ws.send(
            sendBuffer(
                {
                    key: 13,
                    ...this.state.report,
                    printFields: Object.values(this.state.fields).map(field => {
                        delete field.id
                        delete field.value
                        delete field.lbl
                        delete field.fmtTypes
                        return {
                            ...field,
                            sz: parseInt(field.sz),
                            fmt:
                                field.fmt >= 0
                                    ? parseInt(field.fmt)
                                    : field.fmt,
                            space: parseInt(field.space),
                        }
                    }),
                },
                'RelatorioDialogGeradorRet'
            )
        )
    }

    onSubmitEdit = (editField, stateFields) => values => {
        const fields = cloneDeep(stateFields)
        const id = editField.id
        const newValues = fields[id]

        Object.keys(values).forEach(name => (newValues[name] = values[name]))

        fields[id] = newValues
        this.setState(
            {
                editField: null,
                hint: null,
                fields,
            },
            () => {
                this.updateDescription()
            }
        )
    }

    onCloseEdit = () => {
        this.setState({
            editField: null,
            hint: null,
        })
    }

    onClose = () => {
        this.props.ws.send(
            sendBuffer(
                {
                    key: keycodes.ESCAPE_KEY,
                },
                'RelatorioDialogGeradorRet'
            )
        )
    }

    onChangeMainForm = report =>
        this.setState({
            report: {
                ...report,
                cols: parseInt(report.cols),
            },
        })

    onFocusShowHint = activeField => {
        if (activeField === 4 || activeField === 5) {
            this.setState(
                {
                    hint: { ...this.props.data.hint.data },
                },
                () => {
                    this.forceUpdate()
                }
            )
        }
    }

    onBlurHideHint = () => {
        if (this.state.hint) {
            this.setState({ hint: null })
        }
    }

    onDoubleClickEditor = selected => (value, row) => {
        formFieldsEdit.forEach(field => {
            if (field.name === 'fmt') {
                field.options = selected[row].fmtTypes
            }
        })
        this.setState({
            editField: selected[row],
            hint: null,
        })
    }

    render() {
        let { selected, editField, fields } = this.state
        const { classes, data } = this.props
        const { repFields } = data

        const options = repFields.map(field => ({
            ...field,
            lbl: field.name,
            value: field.name,
        }))

        let formFieldsEditValues = formFieldsEdit.map(field => {
            if (editField && editField[field.name]) {
                field.value = editField[field.name]
            }
            return field
        })

        return (
            <Fragment>
                <BaseFlutuante
                    title={'Gerador de Relatórios'}
                    actions={null}
                    onClose={this.onClose}
                >
                    <div className={classes.root}>
                        <Formulario
                            leftLabel
                            hideSubmit
                            onChange={this.onChangeMainForm}
                            list={this.formFields}
                            active={!editField}
                        />

                        <div className={classes.content}>
                            <BoxSeletor
                                title={'Campos'}
                                width={250}
                                allowDuplicated
                                options={options}
                                onChange={this.onChangeFields}
                                onRowDoubleClick={this.onDoubleClickEditor(
                                    selected
                                )}
                                value={Object.values(fields)}
                                cols={['Descrição', 'Tamanho', 'Espaço']}
                                colsType={[
                                    { sz: 25, name: 'nameEdit' },
                                    { sz: 5, name: 'sz' },
                                    { sz: 5, name: 'space' },
                                ]}
                                actions={[
                                    {
                                        tooltip: 'Modificar',
                                        onClick: (row, index) => {
                                            formFieldsEditValues.forEach(
                                                field => {
                                                    if (field.name === 'fmt') {
                                                        field.options =
                                                            selected[
                                                                index
                                                            ].fmtTypes
                                                    }
                                                }
                                            )
                                            this.setState({
                                                editField: selected[index],
                                            })
                                        },
                                    },
                                    {
                                        tooltip: 'Retirar',
                                        onClick: (_row, index, remove) => {
                                            remove(selected[index])
                                        },
                                    },
                                ]}
                                descriptionFields={this.descriptionFields}
                            />
                        </div>

                        <div className={classes.actions}>
                            <Button
                                lbl="Salvar"
                                primary
                                dialog
                                onClick={this.onSave}
                            />
                            <Button
                                lbl="Fechar"
                                dialog
                                onClick={this.onClose}
                            />
                        </div>
                    </div>
                </BaseFlutuante>
                {editField ? (
                    <BaseFlutuante
                        title="Configurar Campo"
                        onClose={this.onCloseEdit}
                        className={this.state.wrapperClass}
                        headClassName="head"
                    >
                        <Formulario
                            leftLabel
                            onSubmit={this.onSubmitEdit(
                                editField,
                                this.state.fields
                            )}
                            onFocus={this.onFocusShowHint}
                            onBlur={this.onBlurHideHint}
                            onClose={this.onCloseEdit}
                            list={formFieldsEditValues}
                        />
                    </BaseFlutuante>
                ) : null}

                {this.state.hint ? (
                    <Hint {...this.state.hint} show={true} />
                ) : null}
            </Fragment>
        )
    }
}
