/*
 * This file is part of the nivo project.
 *
 * (c) 2016 Raphaël Benitte
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import PropTypes from 'prop-types'
import React from 'react'

import Control from './Control'
import PropertyHeader from './PropertyHeader'
import { Help } from './styled'

const PropertyDocumentation = ({ id, property, flavors, currentFlavor }) => {
    return (
        <Control
            id={id}
            description={property.description}
            flavors={flavors}
            currentFlavor={currentFlavor}
            supportedFlavors={property.flavors}
        >
            <PropertyHeader {...property} />
            <Help>{property.help}</Help>
        </Control>
    )
}

PropertyDocumentation.propTypes = {
    id: PropTypes.string.isRequired,
    property: PropTypes.object.isRequired,
    flavors: PropTypes.arrayOf(
        PropTypes.oneOf(['svg', 'html', 'canvas', 'api'])
    ).isRequired,
    currentFlavor: PropTypes.oneOf(['svg', 'html', 'canvas', 'api']).isRequired,
}

export default PropertyDocumentation
