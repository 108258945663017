import { withStyles } from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import BarChartIcon from '@material-ui/icons/BarChart'
import Close from '@material-ui/icons/Close'
import GridIcon from '@material-ui/icons/GridOn'
import ImageIcon from '@material-ui/icons/Image'
import SeparatorIcon from '@material-ui/icons/LineStyle'
import PieChartIcon from '@material-ui/icons/PieChart'
import TimelineIcon from '@material-ui/icons/Timeline'
import TitleIcon from '@material-ui/icons/Title'
import Dialogo from '@nerus/framework/components/Dialogo/index.jsx'
import Button from '@nerus/framework/styled/Button'
import * as PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import LockComponent from '../../../../components/common/LockComponent'
import Axes from '../../../Actions/Axes'
import NerusColorPicker from '../../../Actions/NerusColorPicker'
import NerusInputUpload from '../../../Actions/NerusInputUpload'
import Queries from '../../../Actions/Queries'
import withGetDashboardReport from '../graphql/withGetDashboardReport'
import withQueryReport from '../graphql/withQueryReport'
import withUpdateReports from '../graphql/withUpdateReports'
import styles from '../styles'

export const TYPE_SEPARATOR = 1
export const TYPE_IMAGE = 2
export const TYPE_TITLE_BLOCK = 3
export const TYPE_GRAPH_BAR_AND_LINE = 4
export const TYPE_GRAPH_BAR = 5
export const TYPE_GRAPH_LINE = 6
export const TYPE_GRAPH_PIZZA = 8
export const TYPE_GRID = 9

export const getIconByType = type => {
    let Icon = null

    switch (type) {
        case TYPE_SEPARATOR:
            Icon = SeparatorIcon
            break
        case TYPE_TITLE_BLOCK:
            Icon = TitleIcon
            break
        case TYPE_GRID:
            Icon = GridIcon
            break
        case TYPE_IMAGE:
            Icon = ImageIcon
            break
        case TYPE_GRAPH_BAR_AND_LINE:
            Icon = BarChartIcon
            break
        case TYPE_GRAPH_BAR:
            Icon = BarChartIcon
            break
        case TYPE_GRAPH_LINE:
            Icon = TimelineIcon
            break
        case TYPE_GRAPH_PIZZA:
            Icon = PieChartIcon
            break
    }

    return Icon
}

export const getColorsByType = type => {
    const colors = { color: '', backgroundColor: '' }

    switch (type) {
        case TYPE_SEPARATOR:
            colors.color = '#000000'
            colors.backgroundColor = '#e6e6e6'
            break
        case TYPE_TITLE_BLOCK:
            colors.color = '#ffffff'
            colors.backgroundColor = '#98c1c3'
            break
        case TYPE_GRID:
            colors.color = '#ffffff'
            colors.backgroundColor = '#b298c3'
            break
        case TYPE_IMAGE:
            colors.color = '#ffffff'
            colors.backgroundColor = '#c3a698'
            break
        case TYPE_GRAPH_BAR_AND_LINE:
            colors.color = '#ffffff'
            colors.backgroundColor = '#c3bc98'
            break
        case TYPE_GRAPH_BAR:
            colors.color = '#ffffff'
            colors.backgroundColor = '#b2c398'
            break
        case TYPE_GRAPH_LINE:
            colors.color = '#ffffff'
            colors.backgroundColor = '#98a3c3'
            break
        case TYPE_GRAPH_PIZZA:
            colors.color = '#ffffff'
            colors.backgroundColor = '#c39898'
            break
    }

    return colors
}

export const getTypeTitle = type => {
    switch (type) {
        case TYPE_SEPARATOR:
            return 'Separador'
        case TYPE_IMAGE:
            return 'Imagem'
        case TYPE_TITLE_BLOCK:
            return 'Bloco Título'
        case TYPE_GRAPH_BAR_AND_LINE:
            return 'Gráfico Barra e Linha'
        case TYPE_GRAPH_BAR:
            return 'Gráfico Barra'
        case TYPE_GRAPH_LINE:
            return 'Gráfico Linha'
        case TYPE_GRAPH_PIZZA:
            return 'Gráfico Pizza'
        case TYPE_GRID:
            return 'Grid'
    }

    return 'Não Reconhecido'
}

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 0,
            marginBottom: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const rgba = (r, g, b, a = 1) => ({ r, g, b, a })

const defaultColors = {
    title: rgba(0, 0, 0, 0.87),
    footer: rgba(170, 170, 170),
    value: rgba(0, 0, 0, 0.87),
    separator: rgba(0, 0, 0, 0.3),
}

const defaultAxes = {
    x: {
        title: '',
        color: rgba(232, 193, 160),
    },
    y: {
        title: '',
        color: rgba(232, 193, 160),
    },
}

const defaultState = {
    title: '',
    info: '',
    query: '',
    type: '',
    size: '',
    height: '',
    dashboard_id: null,
    query_data: '',
    query_error: false,
    dialog: null,
    image: '',
    colors: { ...defaultColors },
    axes: { ...defaultAxes },
    queries: [],
}

class Configurar extends LockComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        handleClose: PropTypes.func,
        refresh: PropTypes.func,
        configReportId: PropTypes.string,
        visible: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        this.state = { ...defaultState }
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        this.resetState()
    }

    resetState = (overwrite = {}) => {
        this.setState({
            ...defaultState,
            ...overwrite,
        })
    }

    handleDialog = () => {
        const dialog = this.state.dialog
        this.setState(
            {
                dialog: null,
            },
            () => {
                if (dialog?.title && dialog?.title !== 'Erro') {
                    this.props.handleClose()
                }
            }
        )
    }

    handleUpdateInput = updateValue => {
        this.setState({ ...this.state, ...updateValue })
    }

    componentDidUpdate(prevProps) {
        const prevReport = prevProps.getDashboardReport
        const report = this.props.getDashboardReport
        const [PrevFirstDashboardReport] = prevReport?.getDashboardReport || []
        const [FirstDashboardReport] = report?.getDashboardReport || []

        if (
            (report?.loading && !prevReport?.loading) ||
            (!this.props.visible && prevProps.visible)
        ) {
            this.resetState()
        } else if (
            (!PrevFirstDashboardReport && FirstDashboardReport) ||
            (PrevFirstDashboardReport &&
                FirstDashboardReport &&
                PrevFirstDashboardReport?.data?.id !==
                    FirstDashboardReport?.data?.id)
        ) {
            const { data } = FirstDashboardReport
            this.setState({
                title: data.title,
                info: data.info,
                type: data.type,
                size: data.size,
                height: data.height,
                dashboard_id: data.dashboard_id,
                image: data.image,
                colors: data.colors ? JSON.parse(data.colors) : defaultColors,
                axes: data.axes ? JSON.parse(data.axes) : defaultAxes,
                queries: data.queries.length ? data.queries : [{ query: '' }],
            })
        }
    }

    handleUpdateReport = () => {
        if (
            !this.state.title ||
            !this.state.type ||
            !this.state.size ||
            !this.state.height
        ) {
            this.setState({
                dialog: {
                    title: 'Erro',
                    message: 'Verifique se todos os campos estão preenchidos.',
                },
            })
            return
        }

        const variables = {
            id: this.props.configReportId,
            title: this.state.title,
            info: this.state.info,
            type: parseInt(this.state.type),
            size: parseInt(this.state.size),
            height: parseInt(this.state.height),
            dashboard_id: parseInt(this.state.dashboard_id),
            queries: this.state.queries,
            colors: JSON.stringify(this.state.colors),
            axes: JSON.stringify(this.state.axes),
            image: this.state.image,
        }

        if (this.state.type === TYPE_SEPARATOR) {
            variables.queries = []
            variables.info = ''
            variables.image = ''
        } else if (this.state.type === TYPE_IMAGE) {
            variables.queries = []
        } else if (
            !this.state.queries ||
            !this.state.queries.length ||
            !this.state.queries[0].query
        ) {
            this.setState({
                ...this.state,
                dialog: {
                    title: 'Erro',
                    message: 'Ao menos uma query é obrigatória.',
                },
            })
            return
        }

        this.props
            .updateReport({
                variables,
            })
            .then(() => {
                this.props.refresh && this.props.refresh()
                this.props.getDashboardReport.refetch()

                this.resetState({
                    dialog: {
                        title: 'Salvo',
                        message: 'Componente salvo com sucesso!',
                    },
                })
            })
            .catch(() => {
                this.setState({
                    ...this.state,
                    dialog: {
                        title: 'Erro',
                        message:
                            'Houve um problema para salvar seu componente! Entre em contato com o suporte.',
                    },
                })
            })
    }

    handleOpenAxes = () => {
        this.setState({ axesOpen: true })
    }

    handleCloseAxes = axes => {
        const update = { axesOpen: false }
        if (axes) {
            update.axes = axes
        }
        this.setState(update)
    }

    handleOpenQueries = () => {
        this.setState({ queriesOpen: true })
    }

    handleCloseQuery = queries => {
        const update = { queriesOpen: false }
        if (queries) {
            update.queries = queries
        }
        this.setState(update)
    }

    getReport = () => {
        return this.props.getDashboardReport?.getDashboardReport
            ? this.props.getDashboardReport?.getDashboardReport[0]
            : null
    }

    handleUpdate = e =>
        this.handleUpdateInput({
            [e.target.name]: e.target.value,
        })

    handleColor = (name, color) => {
        const colors = { ...this.state.colors }
        colors[name] = color
        this.setState({
            colors,
        })
    }

    render() {
        const {
            props: { classes, visible, handleClose },
        } = this

        const report = this.getReport()
        const type = report?.data?.type
        const typeTitle = getTypeTitle(type)

        return (
            <Fragment>
                <Drawer
                    className={classes.drawer}
                    open={visible}
                    variant="persistent"
                    anchor="right"
                    elevation={2}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Box className={classes.headingBox}>
                        <Typography className={classes.headingConfig}>
                            Configurar Componente
                            <Close
                                className={classes.headingClose}
                                onClick={handleClose}
                            />
                        </Typography>
                    </Box>

                    <Accordion expanded={true}>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            className={classes.headingSummary}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                {typeTitle}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.detailsTipo}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.flex}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Título"
                                            name="title"
                                            value={this.state.title}
                                            className={classes.titleInput}
                                            onChange={this.handleUpdate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>

                                    <NerusColorPicker
                                        name="title"
                                        value={this.state.colors.title}
                                        onChange={this.handleColor}
                                    />
                                </Grid>
                                {type !== TYPE_SEPARATOR ? (
                                    <Fragment>
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.flex}
                                        >
                                            <FormControl fullWidth>
                                                <TextField
                                                    label="Rodapé"
                                                    name="info"
                                                    value={this.state.info}
                                                    className={
                                                        classes.titleInput
                                                    }
                                                    onChange={this.handleUpdate}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </FormControl>
                                            <NerusColorPicker
                                                name="footer"
                                                value={this.state.colors.footer}
                                                onChange={this.handleColor}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    label="Largura (em colunas: 1 - 12)"
                                                    name="size"
                                                    value={this.state.size}
                                                    className={
                                                        classes.titleInput
                                                    }
                                                    onChange={this.handleUpdate}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    label="Altura"
                                                    name="height"
                                                    value={this.state.height}
                                                    className={
                                                        classes.titleInput
                                                    }
                                                    onChange={this.handleUpdate}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Fragment>
                                ) : (
                                    <Grid item xs={12} className={classes.flex}>
                                        <TextField
                                            label="Cor da Separação"
                                            className={classes.titleInput}
                                            disabled
                                            fullWidth
                                        />
                                        <NerusColorPicker
                                            name="separator"
                                            value={this.state.colors.separator}
                                            onChange={this.handleColor}
                                        />
                                    </Grid>
                                )}
                                {type === TYPE_TITLE_BLOCK ? (
                                    <Grid item xs={12} className={classes.flex}>
                                        <TextField
                                            label="Cor do Valor"
                                            className={classes.titleInput}
                                            disabled
                                            fullWidth
                                        />
                                        <NerusColorPicker
                                            name="value"
                                            value={this.state.colors.value}
                                            onChange={this.handleColor}
                                        />
                                    </Grid>
                                ) : type !== TYPE_GRAPH_PIZZA &&
                                  type !== TYPE_SEPARATOR &&
                                  type !== TYPE_IMAGE ? (
                                    <Grid item xs={12} className={classes.flex}>
                                        <Button
                                            default
                                            variant={'rounded'}
                                            lbl={`Eixos...`}
                                            dialog
                                            onClick={this.handleOpenAxes}
                                            className={classes.queriesButton}
                                        />
                                    </Grid>
                                ) : null}
                                {type !== TYPE_SEPARATOR &&
                                type !== TYPE_IMAGE ? (
                                    <Grid item xs={12}>
                                        <Button
                                            default
                                            variant={'rounded'}
                                            lbl={`SELECTs (${this.state.queries
                                                ?.length || 0})...`}
                                            dialog
                                            onClick={this.handleOpenQueries}
                                            className={classes.queriesButton}
                                        />
                                    </Grid>
                                ) : null}
                                {type === TYPE_IMAGE ? (
                                    <Grid item xs={12}>
                                        <NerusInputUpload
                                            name="image"
                                            value={this.state.image}
                                            onChange={this.handleUpdateInput}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Box className={classes.actionsRight}>
                        <Button
                            lbl={'Cancelar'}
                            dialog
                            color="danger"
                            onClick={handleClose}
                        />
                        <Button
                            lbl={'Salvar'}
                            primary
                            dialog
                            onClick={this.handleUpdateReport}
                        />
                    </Box>
                </Drawer>

                {this.state.queriesOpen ? (
                    <Queries
                        initialData={this.state.queries}
                        minQueries={type === TYPE_GRAPH_BAR_AND_LINE ? 2 : 1}
                        maxQueries={type === TYPE_GRAPH_BAR_AND_LINE ? 2 : 1}
                        onClose={this.handleCloseQuery}
                        onSave={this.handleCloseQuery}
                    />
                ) : null}

                {this.state.axesOpen ? (
                    <Axes
                        initialData={this.state.axes}
                        onClose={this.handleCloseAxes}
                        onSave={this.handleCloseAxes}
                    />
                ) : null}

                {this.state.dialog ? (
                    <Dialogo
                        optType={-1}
                        msg={this.state.dialog.message}
                        onClose={this.handleDialog}
                        title={this.state.dialog.title}
                        open
                    />
                ) : null}
            </Fragment>
        )
    }
}

export default connect()(
    withStyles(styles)(
        withGetDashboardReport(withQueryReport(withUpdateReports(Configurar)))
    )
)
