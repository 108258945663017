import ActionPrev from '@material-ui/icons/KeyboardArrowLeft'
import ActionNext from '@material-ui/icons/KeyboardArrowRight'
import StyledButton from '@nerus/framework/styled/Button'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick/lib'

import { formatTooltip } from '../../../Business/Keyboard'
import { withWS } from '../../../Business/Websocket/Context'
import { sendBuffer } from '../../../Eac/EacActions'

class Icon extends Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        onClick: PropTypes.func,
    }

    render() {
        return React.cloneElement(this.props.children, {
            className: this.props.className,
            onClick: this.props.onClick,
        })
    }
}

class SliderWithBtn extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        data: PropTypes.array.isRequired,
        maxButtonsSlider: PropTypes.number,
        showMore: PropTypes.bool,
        classes: PropTypes.object,
        active: PropTypes.bool,
        activeValue: PropTypes.object,
        ws: PropTypes.object.isRequired,
    }

    static defaultProps = {
        active: true,
    }

    onClickButton = object => () => {
        const { active, activeValue, ws } = this.props
        if (!active) {
            return null
        }

        ws.send(
            sendBuffer(
                activeValue?.current
                    ? {
                          key: object.key,
                          value: activeValue.current,
                      }
                    : object.key
            )
        )
        ;(document.activeElement || { blur: () => {} }).blur()
    }

    buttonMount = buttons => {
        const { active } = this.props
        return buttons
            ? buttons.map((object, i) => {
                  return (
                      <StyledButton
                          disabled={!active}
                          key={'btn-' + i}
                          type="button"
                          onClick={this.onClickButton(object)}
                      >
                          {formatTooltip(object.text)}
                      </StyledButton>
                  )
              })
            : []
    }

    edge(index, count) {
        if (index + 1 === count) {
            this.setState({ shadow: 'no-shadow' })
        } else {
            this.setState({ shadow: '' })
        }
    }

    onAfterChange = index => this.edge(index, this.props.data?.length)

    render() {
        const { classes, maxButtonsSlider, showMore, data = [] } = this.props
        if (data?.length < maxButtonsSlider || showMore) {
            return this.buttonMount(data)
        }

        let settings = {
            dots: true,
            speed: 500,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll:
                maxButtonsSlider < data?.length
                    ? maxButtonsSlider
                    : maxButtonsSlider - 1,
            variableWidth: true,
            prevArrow: (
                <Icon>
                    <ActionPrev color={'action'} />
                </Icon>
            ),
            nextArrow: (
                <Icon>
                    <ActionNext color={'action'} />
                </Icon>
            ),
            className: classes.slider,
        }

        return (
            <Slider {...settings} afterChange={this.onAfterChange}>
                {this.buttonMount(data)}
            </Slider>
        )
    }
}

export default connect()(withWS(SliderWithBtn))
