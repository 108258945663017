import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function BarGroupedIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                    fill="inherit"
                    d="M2 12h2v9H2v-9zm3 2h2v7H5v-7zm11-6h2v13h-2V8zm3 2h2v11h-2V10zM9 2h2v19H9V2zm3 2h2v17h-2V4z"
                />
            </g>
        </SvgIcon>
    )
}
