import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function StockIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0,19.212)">
                <path d="m 247,34 q 35,0 64,-20.5 29,-20.5 42,-54.5 15,-44 -5,-86 -20,-42 -64,-57 -17,-7 -37,-7 -36,0 -65,20.5 -29,20.5 -41,54.5 -16,44 4,86 20,42 64,58 19,6 38,6 z m 72,-147 q 15,31 3,61 -8,25 -28.5,39.5 Q 273,2 247,2 232,2 220,-2 188,-13 174,-43.5 160,-74 171,-105 q 8,-24 29,-39 21,-15 47,-15 13,0 26,5 32,11 46,41 z M 500,-10 406,259 813,402 907,134 Z M 577,353 396,289 296,573 477,636 Z M 989,129 1000,99 461,-91 314,331 191,287 269,64 q -14,2 -22,2 -2,0 -6,-0.5 Q 237,65 235,65 L 151,307 303,361 166,752 10,697 0,727 186,792 481,-50 Z" />
            </g>
        </SvgIcon>
    )
}
