import withStyles from '@material-ui/core/styles/withStyles'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import { styles } from '@nerus/styles/layout'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { checkIsMenuPrincipal, getMenu } from '../../Eac/EacReducer'

export class Content extends EnhancedComponent {
    static propTypes = {
        menu: PropTypes.object,
        classes: PropTypes.object.isRequired,
        showSidebar: PropTypes.bool.isRequired,
        isMenuPrincipal: PropTypes.bool.isRequired,
        location: PropTypes.object,
        nerusInfo: PropTypes.object,
    }

    /**
     * Recupera o dados para renderizar o breadcrumb
     */
    static mapStateToProps = state => {
        return {
            // componentes globais
            showSidebar: state.app.showSidebar,

            // Lista de componentes, ordem e componente ativo
            menu: getMenu(state),
            isMenuPrincipal: checkIsMenuPrincipal(state) || false,
        }
    }

    render() {
        const {
            classes,
            children,
            showSidebar,
            isMenuPrincipal,
            menu,
        } = this.props

        const { hasError } = this.state

        if (hasError) {
            return this.renderError()
        }

        return (
            <div
                className={clsx({
                    [classes.root]: true,
                    [classes.open]: showSidebar,
                    [classes.closed]: !showSidebar,
                    [classes.closedPrincipal]: !showSidebar && isMenuPrincipal,
                    [classes.right]: menu && menu.right,
                })}
            >
                {children}
            </div>
        )
    }
}

export default connect(Content.mapStateToProps)(
    withRouter(withStyles(styles)(Content))
)
