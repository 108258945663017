import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/boxSelector'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import OptionItem, { generateId } from './OptionItem'

export class OptionBox extends Component {
    static propTypes = {
        options: PropTypes.array,
        selected: PropTypes.array,
        onSelectItem: PropTypes.func,
        multiselect: PropTypes.bool,
        width: PropTypes.number,
        height: PropTypes.number,
        classes: PropTypes.object.isRequired,
    }

    static defaultProps = {
        multiselect: false,
        width: 400,
        height: 430,
    }

    constructor(props) {
        super(props)
        this.state = {
            selected: props.selected || [],
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selected !== prevProps.selected) {
            this.setState({
                selected: this.props.selected,
            })
        }
    }

    getItemIndex = opt => {
        return this.state.selected.indexOf(opt)
    }

    isSelectedItem = opt => {
        return this.state.selected.filter(o => opt.id === o.id).length > 0
    }

    onSelectItem = opt => {
        const { multiselect } = this.props
        let selected = this.state.selected
        const indexOpt = this.getItemIndex(opt)
        let ret = false
        if (indexOpt > -1) {
            selected.splice(indexOpt, 1)
        } else {
            if (multiselect) {
                selected.push(opt)
                ret = true
            } else {
                selected = [opt]
            }
        }

        this.setState({ selected }, () => {
            this.props.onSelectItem && this.props.onSelectItem(opt, selected)
        })

        return ret
    }

    render() {
        const { options, width, height, classes } = this.props
        return (
            <ul className={classes.list} style={{ width, height }}>
                {options.map((option, i) => {
                    let opt = {
                        id: generateId(option, i),
                    }
                    if (typeof option === 'object') {
                        opt = {
                            ...opt,
                            ...option,
                        }
                    } else {
                        opt.lbl = option
                        opt.value = option
                    }

                    const opts = {
                        id: opt.id,
                        data: opt,
                        className: classes.listItem,
                        classNameActive: classes.listItemActive,
                        onSelect: this.onSelectItem,
                        selected: this.isSelectedItem(opt),
                    }
                    return (
                        <OptionItem
                            key={`opt-${i}-${opt.id.replace(/ /g, '-')}`}
                            {...opts}
                        />
                    )
                })}
            </ul>
        )
    }
}

export default withStyles(styles)(OptionBox)
