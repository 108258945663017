import { useCallback, useEffect } from 'react'

import keycodes from '../common/Keycodes'

const debug = require('debug')('nerus:usePreventDispatchToWs')

export default function usePreventDispatchToWS(ref, props, enabled = true) {
    const preventDispatchToWS = useCallback(event => {
        if (!enabled) {
            debug(`Hook preventDispatchToWS is not enabled`)
            return
        }

        const freeKeys = [
            keycodes.TAB_KEY,
            keycodes.UP_ARROW_KEY,
            keycodes.DOWN_ARROW_KEY,
            keycodes.ESCAPE_KEY,
        ]

        if (freeKeys.indexOf(event.keyCode) === -1) {
            event.stopPropagation && event.stopPropagation()
            event.stopImmediatePropagation && event.stopImmediatePropagation()
            debug('Event prevented', event)
        }
    }, [])
    useEffect(() => {
        if (!props.disabled && ref?.current) {
            ref.current.addEventListener('keydown', preventDispatchToWS)
        }

        return () => {
            if (ref?.current) {
                ref.current.removeEventListener('keydown', preventDispatchToWS)
            }
        }
    }, [props.disabled, enabled])
    return ref
}
