import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'

import { sendBuffer } from '../../modules/NerusWeb/Eac/EacActions'
import keycodes, { navigationKeys, PLUS_MAPPING } from '../common/Keycodes'
import usePrevious from '../hooks/usePrevious'
import { noop } from '../util'

const debug = require('debug')('nerus:useOverwrite')

// Teclas em que prevenimos o overwrite (evitar apertar uma tecla e letras desaparecerem)
const keysPreventOverwrite = [
    keycodes.LEFT_ARROW_KEY,
    keycodes.RIGHT_ARROW_KEY,
    keycodes.DOT_KEY,
    keycodes.COMMA_KEY,
]

// RECS que não devem fazer overwrite em algum momento (ponto, virgula ou outros caracteres)
export const RECS = [
    'REC_LONG',
    'REC_SHORT',
    'REC_F_LONG',
    'REC_F_SHORT',
    'REC_MONEY',
    'REC_DOUBLE',
    'REC_PERC',
    'REC_DQTTY',
    'REC_QTTY',
    'REC_QTTD',
    'REC_SHORT_WITH_HELP',
    'REC_DATE',
    'REC_YM',
    'REC_DATEYY',
    'REC_DATE_UTC',
    'REC_TIME',
]

// Helper de verificação para pular overwrite
export const skipOverwrite = typeRec => RECS.indexOf(typeRec) === -1

/**
 * @param ref
 * @param value
 * @param disabled
 * @param onChange
 * @param fieldType
 * @param defaultValue
 * @param disableOverwrite - desabilita o hook
 * @param overwriteOnFirst - limpa o campo na primeira tecla
 * @param forceOverwrite - força o modo de overwrite
 * @param overwriteOnValidate - limpa se passar nessa função de validação
 * @param format - formato usado no overwrite
 * @param enabled - hook está ativo
 * @returns {boolean}
 */
export default function useOverwrite(
    ref,
    {
        fieldType,
        value,
        disableOverwrite = false,
        disabled,
        onChange,
        overwriteOnFirst = false,
        forceOverwrite = false,
        overwriteOnValidate = keyCode =>
            keyCode === 189 ||
            keyCode === 109 ||
            (keyCode >= 48 && keyCode <= 57) ||
            (keyCode >= 96 && keyCode <= 105),
        format,
        btns,
        ws,
    },
    defaultValue = false,
    enabled = true
) {
    const [overwrite = true, setOverwrite] = useState(defaultValue)
    const [shouldOverwriteOnFirst, setShouldOverwriteOnFirst] =
        useState(overwriteOnFirst)
    const timerRef = useRef()
    // Get the previous value (was passed into hook on last render)
    const prevDisabled = usePrevious(disabled)

    const toggleOverwrite = useCallback(
        e => {
            if (!enabled) {
                debug(`Hook is not enabled`)
                return
            }

            if (overwriteOnFirst) {
                if (
                    keysPreventOverwrite.indexOf(e.keyCode) > -1 &&
                    shouldOverwriteOnFirst
                ) {
                    setShouldOverwriteOnFirst(false)
                } else if (
                    shouldOverwriteOnFirst &&
                    overwriteOnValidate &&
                    overwriteOnValidate(e.keyCode)
                ) {
                    setShouldOverwriteOnFirst(false)
                    e.target.value = ''
                    e.persist = noop
                    onChange && onChange(e)
                    e.stopImmediatePropagation && e.stopImmediatePropagation()
                    return
                }
            }

            if (e.keyCode === keycodes.INSERT_KEY) {
                setOverwrite(!overwrite)
                debug(`${!overwrite ? 'Enabled' : 'Disabled'}`)
                return
            }

            if (e.ctrlKey && !e.altKey) {
                if (e.keyCode === keycodes.Y_KEY) {
                    e.preventDefault()
                    e.stopImmediatePropagation && e.stopImmediatePropagation()
                    e.target.value = ''
                    e.persist = noop
                    onChange && onChange(e)
                }
                if (e.keyCode === keycodes.U_KEY) {
                    const { target } = e
                    let processedValue = value
                    if (moment.isMoment(value) && format) {
                        processedValue = processedValue.format(format)
                    }
                    e.preventDefault()
                    e.stopImmediatePropagation && e.stopImmediatePropagation()
                    e.target.value = processedValue
                    e.persist = noop
                    onChange && onChange(e)
                    // move caret to first column
                    if (timerRef.current) {
                        clearTimeout(timerRef.current)
                        timerRef.current = null
                    }

                    timerRef.current = setTimeout(() => {
                        target.selectionStart = target.selectionEnd = 0
                    }, 5)
                }
            }

            if (forceOverwrite && e.keyCode === keycodes.BACKSPACE_KEY) {
                setOverwrite(false)
                debug(`${forceOverwrite ? 'Enabled' : 'Disabled'}`)
                return
            }

            // funcao que verifica se os bts possuem o '+' como atalho
            if (PLUS_MAPPING[e.keyCode] && btns?.find(bt => bt.key === 43)) {
                e.preventDefault()
                e.stopImmediatePropagation && e.stopImmediatePropagation()
                e.persist = noop
                onChange && onChange(e)

                ws.send(
                    sendBuffer(
                        {
                            key: PLUS_MAPPING[e.keyCode],
                            value: e.target.value,
                        },
                        'sendEdit'
                    )
                )
            }
        },
        [
            forceOverwrite,
            shouldOverwriteOnFirst,
            enabled,
            overwrite,
            timerRef.current,
        ]
    )

    useEffect(() => {
        if (
            !shouldOverwriteOnFirst &&
            !disabled &&
            prevDisabled &&
            !forceOverwrite
        ) {
            setShouldOverwriteOnFirst(true)
        }

        if (ref?.current && !disabled) {
            ref.current.addEventListener('keydown', toggleOverwrite)

            return () => {
                ref.current.removeEventListener('keydown', toggleOverwrite)
            }
        }
    }, [
        overwrite,
        disabled,
        shouldOverwriteOnFirst,
        enabled,
        defaultValue,
        forceOverwrite,
    ])

    const doOverwrite = useCallback(
        e => {
            const { target } = e
            let { selectionStart } = target
            let { value } = target

            if (!enabled) {
                debug(`Hook is not enabled`)
                return
            }

            if (
                navigationKeys.indexOf(e.keyCode) === -1 &&
                (forceOverwrite ||
                    (fieldType &&
                        overwrite &&
                        !disableOverwrite &&
                        navigationKeys.indexOf(e.keyCode) === -1 &&
                        target.selectionStart === target.selectionEnd))
            ) {
                const hasFormatter = fieldType.hasModification(e.key)
                debug(`hasFormatter: ${hasFormatter ? 'yes' : 'no'}`)

                if (
                    e.keyCode !== keycodes.DOT_KEY &&
                    target.value[target.selectionStart] === '.' &&
                    !skipOverwrite(fieldType.typeRec)
                ) {
                    debug(
                        `Found a dot, preventing overwrite REC(${fieldType.typeRec}`
                    )
                    return
                }

                if (
                    e.keyCode !== 191 &&
                    (target.value[target.selectionStart] === '/' ||
                        target.value[target.selectionStart] === ':')
                ) {
                    target.selectionStart = selectionStart = selectionStart + 1
                }

                if (!hasFormatter) {
                    target.value =
                        value.substr(0, selectionStart) +
                        value.substr(selectionStart + 1)
                }

                debug(`Value overwritten: ${value}`)
                debug(`Setting caret to position: ${selectionStart}`)
                target.selectionStart = target.selectionEnd = selectionStart
            }
        },
        [overwrite, enabled, forceOverwrite, fieldType, disableOverwrite]
    )

    useEffect(() => {
        if (!disabled && ref?.current) {
            ref.current.addEventListener('keypress', doOverwrite)
        }
        return () => {
            if (ref?.current) {
                ref.current.removeEventListener('keypress', doOverwrite)
            }
        }
    }, [
        overwrite,
        disableOverwrite,
        disabled,
        shouldOverwriteOnFirst,
        enabled,
        forceOverwrite,
    ])

    return overwrite
}
