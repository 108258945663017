import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/danfe'
import { connect } from 'react-redux'

import { withWS } from '../../Business/Websocket/Context'
import { getComponentById, isActiveComponent } from '../../Eac/EacReducer'
import BaseDanfeDialog from './DanfeDialog'

/**
 * Componente RelatorioDialog
 */
export class DanfeDialog extends BaseDanfeDialog {
    // Retrieve data from store as props
    static mapStateToProps = (state, ownProps) => {
        const data = getComponentById(ownProps.id, state).payload
        return {
            data,
            active:
                ownProps.active ||
                (ownProps.enabled === undefined
                    ? isActiveComponent(ownProps.id, state)
                    : ownProps.enabled),
        }
    }

    static baseOpts = {
        skip: true,
        title: args => {
            const { title, numero } = args
            return title || `Opções de Impressão: ${numero}`
        },
    }

    // Renderiza o wrapper
    render() {
        return this.renderDialog(this.state.forms)
    }
}

export default connect(DanfeDialog.mapStateToProps)(
    withStyles(styles)(withWS(DanfeDialog))
)
