export const styles = () => ({
    root: {
        flex: '1 1 20%',
        cursor: 'pointer',
        '& *': {
            cursor: 'pointer',
        },
        '& div': {
            justifyContent: 'center',
            alignContent: 'center',
        },
        '& h3': {
            fontSize: 16,
        },
    },
    gray: {
        color: '#A1A6AE',
    },
})
