import cloneDeep from 'lodash/cloneDeep'

import Interface from './Interface'

export const getActiveField = list => {
    let activeField =
        (list &&
            list.filter(element => {
                return !!element.enabled
            })) ||
        []
    return activeField[0] || {}
}

export default class Formulario extends Interface {
    static before({ id, state, action }) {
        const { payload } = action
        action.id = id

        const activeField = getActiveField(payload.list)

        if (!action.payload.ws) {
            action.payload.ws = {}
        }

        if (activeField) {
            action.payload.ws.data = {
                x: activeField.x,
                y: activeField.y,
                key: null,
            }
        } else {
            action.payload.ws.data = {
                x: null,
                y: null,
                key: null,
                hasActive: false,
            }
        }

        action.payload.ws.form = cloneDeep(payload.list)

        return { state, action }
    }
}
