import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { styles } from '@nerus/styles/components/text'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(styles)

const Text = ({
    children,
    bold,
    noWrap,
    size,
    className,
    color,
    variant,
    component = 'span',
}) => {
    const classes = useStyles()
    return (
        <Typography
            className={clsx({
                [classes.root]: true,
                [classes.bold]: bold,
                [classes.noWrap]: noWrap,
                [classes[size]]: size && classes[size],
                [className]: className,
                [classes[color]]: color && classes[color],
                [classes[variant]]: variant && classes[variant],
            })}
            component={component || 'span'}
        >
            {children}
        </Typography>
    )
}

Text.propTypes = {
    children: PropTypes.node,
    bold: PropTypes.bool,
    noWrap: PropTypes.bool,
    className: PropTypes.string,
    component: PropTypes.string,
    size: PropTypes.oneOf(['small']),
    color: PropTypes.oneOf(['info', 'warn', 'success']),
    variant: PropTypes.oneOf(['description']),
}

export default Text
