import * as Sentry from '@sentry/browser'
import React, { Component } from 'react'

export default class EnhancedComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
        }
    }

    // Deriva novo estado com o erro
    static getDerivedStateFromError(error) {
        return { hasError: true, error }
    }

    checkProps = (props, nextProps) => {
        return props.reduce(
            (c, v) => c || this.props[v] !== nextProps[v],
            false
        )
    }

    // TODO: Aqui é interessante aplicarmos um Sentry
    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo)
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo)
            const eventId = Sentry.captureException(error)
            this.setState({ eventId })
        })
    }

    componentWillUnmount() {
        Object.keys(this).forEach(k => {
            if (k.indexOf('ref_') > -1 && this[k]?.current) {
                delete this[k]
            }
        })

        if (this.handleKeyboard) {
            document.removeEventListener('keydown', this.handleKeyboard)
        }
    }

    componentDidMount() {
        if (this.handleKeyboard) {
            document.addEventListener('keydown', this.handleKeyboard)
        }
    }

    createRef = (name, defaultValue) => {
        const refName = `ref_${name}`
        if (!this[refName]) {
            this[refName] = React.createRef()
            this[refName].current =
                defaultValue === undefined ? null : defaultValue
        }
        return this[refName]
    }

    getRef = name => {
        return this[`ref_${name}`] ? this[`ref_${name}`].current : null
    }

    renderError() {
        if (this.state.hasError) {
            return (
                <div
                    style={{
                        padding: 10,
                        background: '#ffffff',
                        color: '#ff0000',
                    }}
                >
                    <h4>Ops! Houve um problema.</h4>
                    <pre>
                        {this.state.error.message}
                        {this.state.error.stack}
                    </pre>
                </div>
            )
        }
    }
}
