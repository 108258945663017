import withStyles from '@material-ui/core/styles/withStyles'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import CloseIcon from '@nerus/framework/icons/Close'
import { styles } from '@nerus/styles/components/genericStatusBar'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

export class GenericStatusBar extends EnhancedComponent {
    static mapStateToProps = (state, ownProps) => {
        return {
            data: ownProps.data,
            dispatchKey: ownProps.dispatchKey,
        }
    }

    static propTypes = {
        data: PropTypes.object,
        dispatchKey: PropTypes.func,
    }

    static baseOpts = {
        skip: true,
    }

    componentDidMount() {
        // const statusBar = this.getRef('statusBar')

        document.body.addEventListener('keydown', this._onKeyDown)
    }

    componentWillUnmount() {
        document.body.removeEventListener('keydown', this._onKeyDown)
    }

    _onKeyDown = e => {
        e.stopPropagation()
        e.stopImmediatePropagation && e.stopImmediatePropagation()
        this.props.dispatchKey(e.keyCode)
    }

    render() {
        const {
            data = { msg: 'Pressione qualquer tecla para continuar' },
            classes,
        } = this.props

        const { hasError } = this.state

        if (hasError) {
            return this.renderError()
        }

        const { msg } = data

        return (
            <div className={classes.root} ref={this.createRef('statusBar')}>
                <div className={clsx(classes.text, classes.textBig)}>
                    {msg} <CloseIcon />
                </div>
            </div>
        )
    }
}

export default connect(GenericStatusBar.mapStateToProps)(
    withStyles(styles)(GenericStatusBar)
)
