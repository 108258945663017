import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function FilterIcon(props) {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 416.000000 512.000000"
            style={{
                width: 24,
                height: 24,
            }}
        >
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="inherit"
                stroke="none"
            >
                <path d="M42 4726 l-37 -34 -3 -293 c-2 -212 0 -301 9 -322 7 -16 351 -372 765 -791 l753 -761 1 -1038 c0 -943 2 -1041 16 -1067 21 -35 64 -60 103 -60 46 0 929 446 956 483 20 28 20 43 23 859 l2 831 743 749 c408 412 750 763 760 781 15 28 17 64 17 324 l0 293 -34 38 -34 37 -2001 3 -2001 2 -38 -34z m3868 -331 l0 -125 -1832 2 -1833 3 -3 109 c-1 61 0 116 2 123 4 11 333 13 1836 13 l1830 0 0 -125z m-376 -597 c-665 -671 -1115 -1131 -1131 -1155 -17 -25 -18 -84 -23 -847 l-5 -819 -303 -148 -302 -148 0 962 c-1 751 -3 967 -13 985 -7 12 -314 329 -682 704 -369 374 -671 685 -673 690 -2 4 754 8 1680 8 l1683 0 -231 -232z" />
            </g>
        </SvgIcon>
    )
}
