export const initialProperties = {
    keys: ['y'],
    indexBy: 'x',
    margin: {
        top: 20,
        right: 20,
        bottom: 80,
        left: 80,
    },
    lineWidth: 2,
    colors: { scheme: 'NerusWeb' },
    enableArea: false,
    areaBaselineValue: 0,
    areaOpacity: 0.2,
    areaBlendMode: 'normal',
    enablePoints: true,
    xScale: { type: 'point' },
    yScale: {
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
    },
    curve: 'linear',
    axisTop: null,
    axisRight: null,
    axisBottom: {
        // x
        enable: true,
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        legend: '',
        legendOffset: 60,
        legendPosition: 'middle',
    },
    axisLeft: {
        // y
        enable: true,
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendOffset: -75,
        legendPosition: 'middle',
    },
    pointSize: 10,
    pointColor: { theme: 'background' },
    pointBorderWidth: 2,
    pointBorderColor: { from: 'serieColor' },
    pointLabel: 'y',
    pointLabelYOffset: -12,
    useMesh: true,

    enableGridX: false,
    enableGridY: true,

    enableLabel: true,
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor: '#ffffff',

    showLegend: false,
    legends: [
        {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemOpacity: 1,
                    },
                },
            ],
        },
    ],

    isInteractive: true,
    animate: true,
    motionConfig: 'default',
    motionStiffness: 90,
    motionDamping: 15,
}
