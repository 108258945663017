import { StyledKeyboardTimePicker } from '@nerus/framework/styled/TimePicker'
import moment from 'moment/moment'
import * as PropTypes from 'prop-types'
import React from 'react'

import { TextFieldDate } from './Date'

/**
 * Componente TextFieldTime
 *
 * Rec: REC_TIME
 *
 * Renderiza um campo de texto que tem formatação de data
 * com datepicker
 */
export class TextFieldTime extends TextFieldDate {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    }

    views = ['hours', 'minutes', 'seconds']
    format = 'HHmmss'
    displayFormat = 'HH:mm:ss'
    separator = ':'
    sendFormat = 'HHmmss'
    zeroedValue = this.sendFormat.replace(/./g, '0')

    onDateChange = (date, callback) => {
        if (date && date.format) {
            this.handleInputChange(
                {
                    target: {
                        value:
                            date && date.isValid()
                                ? date.format(
                                      this.props.value.indexOf(':') > -1
                                          ? this.displayFormat
                                          : this.format
                                  )
                                : date,
                    },
                },
                () => {
                    const { steps } = this.state
                    if (steps === 2) {
                        this.setState(
                            { steps: 0 },
                            typeof callback === 'function'
                                ? callback
                                : undefined
                        )
                    } else {
                        this.setState({ steps: steps + 1 })
                    }
                }
            )
        }
    }

    render() {
        let { value, isPickerOpen } = this.state
        const {
            lbl,
            enabled,
            orientation = 'horizontal',
            inputWidth,
            labelWidth,
            InputProps,
            componentId,
            x,
            y,
            dispatch,
        } = this.props

        const fieldType = this.getFieldType()
        if (value === this.zeroedValue) {
            value = null
        }

        if (value && !(value instanceof moment)) {
            value = moment(
                value,
                this.props.value.indexOf(':') > -1
                    ? this.displayFormat
                    : this.format
            )
        }

        return (
            <StyledKeyboardTimePicker
                orientation={orientation}
                dispatch={dispatch}
                value={value}
                componentId={componentId}
                InputProps={InputProps}
                fieldType={fieldType}
                disabled={!enabled}
                open={isPickerOpen}
                onOpen={this.onOpen}
                onClose={this.onClose}
                position={{ x, y }}
                format={this.displayFormat}
                views={this.views}
                onChange={this.onDateChange}
                onAccept={this.onDateAccept}
                onKeyDown={this.onCaretChange}
                beforeSend={this.beforeSend}
                ref={this.createRef('input')}
                InputAdornmentProps={{
                    position: 'end',
                    onKeyDown: this.onKeyDown,
                }}
                inputWidth={inputWidth}
                labelWidth={labelWidth}
                label={lbl}
                dispatchToWs={true}
                overwrite={true}
            />
        )
    }
}

export default TextFieldTime
