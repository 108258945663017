import CharField from './Char'
import DateField from './Date'
import DateMonthYearField from './DateMonthYear'
import DoubleField from './Double'
import MoneyField from './Money'
import TextFieldNegativableShort from './NegativableShort'
import PercField from './Percentage'
import TextFieldPopupMenu from './PopupMenu'
import PromptField from './Prompt'
import ShortField from './Short'
import SkipField from './Skip'
import StringField from './String'
import StringMultilineField from './StringMultiline'
import TimeField from './Time'
import ToggleField from './Toggle'

export const recsPopup = [
    'REC_POPMENU',
    'REC_MENU',
    'REC_ONE_CHOICE',
    'REC_BIT_MENU',
]

export const recsToggle = [
    'REC_BIT',
    'REC_SN',
    'REC_SN_GLOBAL',
    'REC_BIT_SN_WITH_HELP',
    'REC_BIT_SN',
]

export const recsMultiLines = [
    'REC_STR_LINES',
    'REC_STR_LINES_BOX',
    'REC_STR_SCROLL',
    'REC_STR_SCROLL_BOX',
    'REC_STR_LINES_UPPER',
]

export const recToClass = {
    // ! Os TODO's especificam que precisamos
    // ! especificar o OCTAL para a opção

    // * Edição Ponto Flutuante
    REC_F_SHORT: TextFieldNegativableShort,
    REC_MONEY: MoneyField,
    REC_DOUBLE: DoubleField,
    REC_PERC: PercField,
    REC_F_LONG: DoubleField,
    REC_DQTTY: TextFieldNegativableShort,
    REC_QTTD: TextFieldNegativableShort,
    REC_QTTY: TextFieldNegativableShort,

    // * Edição de Texto
    REC_STRING: StringField,
    REC_CHAR: CharField,
    REC_STR_POP: StringField,
    REC_STR_POP2: StringField,
    REC_STR_NT: StringField,

    // * Edição de texto Especial
    REC_PSWD: StringField,
    REC_PRDNO: StringField,

    // * Edição de texto com várias linhas
    REC_STR_LINES: StringMultilineField,
    REC_STR_LINES_BOX: StringMultilineField,
    REC_STR_SCROLL: StringMultilineField,
    REC_STR_SCROLL_BOX: StringMultilineField,
    REC_STR_LINES_UPPER: StringMultilineField,

    // * Menu de Opções
    REC_POPMENU: TextFieldPopupMenu,
    REC_MENU: TextFieldPopupMenu,
    REC_ONE_CHOICE: TextFieldPopupMenu,
    REC_BIT_MENU: TextFieldPopupMenu,
    REC_BIT: ToggleField,
    REC_SN: ToggleField,
    REC_SN_GLOBAL: ToggleField,
    REC_BIT_SN_WITH_HELP: ToggleField,
    REC_BIT_SN: ToggleField,

    // * Edição de Data
    REC_DATE: DateField,
    REC_YM: DateMonthYearField,
    REC_DATEYY: DateField,
    REC_DATE_UTC: DateField,
    REC_TIME: TimeField,

    // * Edição Inteiro
    REC_SHORT: ShortField,
    REC_SHORT_WITH_HELP: ShortField,
    REC_LONG: ShortField,

    // * Controle especiais
    REC_PROMPT: PromptField,
    REC_SKIP: SkipField,

    // * Tabelas Especiais
    // TODO: Revisar com o TEAM C
    // TODO: REC_DUPTOC
    // TODO: REC_STORE
    // TODO: REC_PRD
    // TODO: REC_INDX
    // TODO: REC_CUSTP
    // TODO: REC_VEND
    // TODO: REC_BANK
    // TODO: REC_CL
    // TODO: REC_ACC
    // TODO: REC_EMP
    // TODO: REC_ARQF
    // TODO: REC_TYPENO        (REC_SHORT)
    // TODO: REC_FXATP         (REC_SHORT)
    // TODO: REC_PAYM          (REC_SHORT)
    // TODO: REC_STORE_L
    // TODO: REC_USER          (REC_SHORT)
    // TODO: REC_CORP          (REC_SHORT)

    // * Array
    // TODO: REC_SHORT_ARRAY
    // TODO: REC_LONG_ARRAY
}
