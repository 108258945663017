import { useCallback, useEffect } from 'react'

import keycodes, { navigationKeys } from '../common/Keycodes'

const debug = require('debug')('nerus:useDispatchToWs')

export default function useDispatchToWS(ref, props, enabled) {
    const { fieldType, beforeSend, componentId, position } = props
    const dispatchToWS = useCallback(
        event => {
            const value = event.target.value
            let keyCode = event.keyCode
            const msg = {
                componentId: componentId,
                key: keyCode,
                value: beforeSend ? beforeSend(value, event) : value,
                ...position,
            }

            if (!enabled) {
                debug(`Hook is not enabled`)
                return
            }

            // ele é chamado em casos especificos quando
            // o campo já está completamente populado
            if (
                fieldType &&
                fieldType.shouldJump(value || '') &&
                navigationKeys.indexOf(keyCode) === -1
            ) {
                msg.key = keycodes.ENTER_KEY
                debug(
                    'dispatch got transformed from ' + keyCode + ' to ENTER_KEY'
                )
            }

            event.nerusEvent = msg
            debug(event.nerusEvent)
        },
        [props]
    )

    useEffect(() => {
        if (!props.disabled && ref?.current) {
            ref.current.addEventListener('keydown', dispatchToWS)
        }
        return () => {
            if (ref?.current) {
                ref.current.removeEventListener('keydown', dispatchToWS)
            }
        }
    }, [props.disabled, enabled])
    return ref
}
