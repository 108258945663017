import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/formulario'
import React from 'react'

import BaseFormulario from './BaseFormulario'

/**
 * Componente Formulario
 */
export class Formulario extends BaseFormulario {
    // Renderiza o wrapper
    render() {
        return (
            <div className={this.state.wrapperClass}>
                {this.renderForm(this.state.forms)}
            </div>
        )
    }
}

export default withStyles(styles)(Formulario)
