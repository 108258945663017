import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/relatorioDialogoGerador'
import { connect } from 'react-redux'

import { withWS } from '../../Business/Websocket/Context'
import { getComponentById } from '../../Eac/EacReducer'
import BaseRelatorioDialogGerador from './BaseRelatorioDialogGerador'

/**
 * Componente RelatorioDialog
 */
export class RelatorioDialogGerador extends BaseRelatorioDialogGerador {
    // Retrieve data from store as props
    static mapStateToProps = (state, ownProps) => {
        const data = getComponentById(ownProps.id, state).payload
        return {
            data,
        }
    }
}

export default connect(RelatorioDialogGerador.mapStateToProps)(
    withStyles(styles)(withWS(RelatorioDialogGerador))
)
