export const styles = theme => ({
    root: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        overflow: 'hidden',
        backgroundColor: theme.palette.common.white,
    },
    fontConthrax: {
        fontFamily: `'Conthrax', sans-serif`,
    },
    content: {
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
    },
    banner: {
        background: theme.palette.disabledColor,
        flex: '0 0 55%',
        maxWidth: '55%',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    formWrapper: {
        paddingLeft: '30px',
        paddingRight: '30px',
        flex: '0 0 100%',
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
            flex: '0 0 45%',
            maxWidth: '45%',
        },
    },
    callingWrapper: {},
    caller: {
        fontSize: '32px',
        maxWidth: '245px',
        lineHeight: 1.3,
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        marginTop: '20px',
        marginBottom: '10px',
    },
    welcomeText: {
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '20px',
        marginBottom: '10px',
    },
    logo: {
        height: '18vh',
        display: 'block',
        maxWidth: '100%',
    },
    form: {
        // width: '90%',
        margin: '30px auto 0',
    },
    formInput: {
        marginBottom: theme.spacing(1),
    },
    labelFocused: {}, // para conseguir sobrepor facilmente o focus color
    labelColor: {
        color: theme.palette.loginLabelColor,
        '&$labelFocused': {
            color: theme.palette.loginLabelColor,
        },
    },
    errorMessage: {
        textAlign: 'center',
        padding: 0,
        color: theme.palette.primary.main,
        fontSize: '12px',
    },
    actions: {
        display: 'flex',
    },
    actionsLeft: {
        flexGrow: 1,
    },
    submit: {
        marginTop: 40,
    },
})
