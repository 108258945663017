import * as PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'

import { addComponent, alreadyConnected } from '../../Eac/EacActions'
import { getSessionsToReconnect, updateSession } from '../../Eac/EacReducer'

export default class SessionHandler extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        session: PropTypes.object,
        match: PropTypes.object,
        isLogged: PropTypes.bool,
        // TODO: Definir shape necessária
        wsManager: PropTypes.object,
    }

    onWindowUnload = event => {
        const { session, isLogged } = this.props

        // Ignoramos se no fechamento da janela não temos uma sessão ativa
        if (session?.session_id) {
            const { session_id } = session
            // ao sair definimos que a sessão pode ser reutilizada
            updateSession(session_id, () => ({
                in_use: false,
                type: 'reconnect',
                lastUse: new Date().toISOString(),
                loggedIn: isLogged,
            }))
        }

        if (isLogged) {
            event.returnValue = ''

            return ''
        }
    }

    doSessionRecovery = () => {
        const { session, dispatch } = this.props

        // usa uma sessão passada via URL
        const qs = queryString.parse(window.location.search)
        if (!session || !session?.session_id) {
            dispatch(alreadyConnected())
            return null
        }

        const { session_id } = session
        const sessionsToReconnect = getSessionsToReconnect(
            session.bin || 'nerusweb'
        )
        if (sessionsToReconnect?.length > 0) {
            setTimeout(() => {
                sessionsToReconnect
                    .filter(
                        sess =>
                            sess?.session_id !== session_id &&
                            sess?.bin === session.bin &&
                            !qs?.session_id
                    )
                    .forEach(session => {
                        setTimeout(() => {
                            window.open(
                                (session.bin === 'nerusweb'
                                    ? ''
                                    : '/bin/' + session.bin.split('_')[1]) +
                                    '?session_id=' +
                                    session.session_id,
                                '_blank'
                            )
                        }, 200)
                    })
            }, 750)
        }
    }

    startIsAliveTimer = () => {
        const { wsManager, dispatch } = this.props
        clearTimeout(this.timerConnectionAlive)

        /**
         * Dispara evento que aguarda resposta do
         * websocket para checar se conexao esta
         * funcionando e exibir dialogo
         */
        if (wsManager) {
            this.timerConnectionAlive = setTimeout(function() {
                if (!wsManager.alive) {
                    // TODO: Trocar essa addComponent por uma dialogo de sistema
                    dispatch(
                        addComponent('Dialogo', {
                            msg:
                                'Detectamos uma anormalidade na rede, deseja reconectar?',
                            optType: -100,
                        })
                    )
                }
                clearTimeout(this.timerConnectionAlive)
            }, 60000)
        }
    }

    componentDidMount() {
        window.onbeforeunload = this.onWindowUnload
        window.addEventListener('beforeunload', this.onWindowUnload)
        this.doSessionRecovery()
    }
}
