import { svgDefaultProps } from '@nivo/bar'
import moment from 'moment'

import {
    axesProperties,
    defsProperties,
    getLegendsProps,
    groupProperties,
    motionProperties,
    themeProperty,
} from '../nivo/utils/componentProperties'

const props = [
    {
        key: 'valueScale',
        type: 'object',
        group: 'Base',
        help: `value scale configuration.`,
        defaultValue: svgDefaultProps.valueScale,
        controlType: 'object',
        controlOptions: {
            props: [
                {
                    key: 'type',
                    help: `Scale type.`,
                    type: 'string',
                    controlType: 'choices',
                    controlOptions: {
                        disabled: true,
                        choices: ['linear', 'symlog'].map(v => ({
                            label: v,
                            value: v,
                        })),
                    },
                },
            ],
        },
    },
    {
        key: 'indexScale',
        type: 'object',
        group: 'Base',
        help: `index scale configuration.`,
        defaultValue: svgDefaultProps.indexScale,
        controlType: 'object',
        controlOptions: {
            props: [
                {
                    key: 'type',
                    help: `Scale type.`,
                    type: 'string',
                    controlType: 'choices',
                    controlOptions: {
                        disabled: true,
                        choices: ['band'].map(v => ({
                            label: v,
                            value: v,
                        })),
                    },
                },
                {
                    key: 'round',
                    help: 'Toggle index scale (for bar width) rounding.',
                    type: 'boolean',
                    controlType: 'switch',
                },
            ],
        },
    },
    {
        key: 'reverse',
        help:
            'Reverse bars, starts on top instead of bottom for vertical layout and right instead of left for horizontal one.',
        type: 'boolean',
        required: false,
        defaultValue: svgDefaultProps.reverse,
        controlType: 'switch',
        group: 'Base',
    },
    {
        key: 'minValue',
        help: 'Minimum value.',
        description: `
            Minimum value, if 'auto',
            will use min value from the provided data.
        `,
        required: false,
        defaultValue: svgDefaultProps.minValue,
        type: `number | 'auto'`,
        controlType: 'switchableRange',
        group: 'Base',
        controlOptions: {
            disabledValue: 'auto',
            defaultValue: -10000,
            min: -1000000,
            max: 0,
        },
    },
    {
        key: 'maxValue',
        help: 'Maximum value.',
        description: `
            Maximum value, if 'auto',
            will use max value from the provided data.
        `,
        required: false,
        defaultValue: svgDefaultProps.maxValue,
        type: `number | 'auto'`,
        controlType: 'switchableRange',
        group: 'Base',
        controlOptions: {
            disabledValue: 'auto',
            defaultValue: 10000,
            min: 0,
            max: 1000000,
        },
    },
    {
        key: 'padding',
        help: 'Padding between each bar (ratio).',
        type: 'number',
        required: false,
        defaultValue: svgDefaultProps.padding,
        controlType: 'range',
        group: 'Base',
        controlOptions: {
            min: 0,
            max: 0.9,
            step: 0.05,
        },
    },
    {
        key: 'innerPadding',
        help: 'Padding between grouped/stacked bars.',
        type: 'number',
        required: false,
        defaultValue: svgDefaultProps.innerPadding,
        controlType: 'range',
        group: 'Base',
        controlOptions: {
            unit: 'px',
            min: 0,
            max: 10,
        },
    },
    {
        key: 'margin',
        help: 'Chart margin.',
        type: 'object',
        required: false,
        controlType: 'margin',
        group: 'Base',
    },
    themeProperty,
    {
        key: 'colors',
        help: 'Defines color range.',
        type: 'string | Function | string[]',
        required: false,
        defaultValue: { scheme: 'NerusWeb' },
        controlType: 'ordinalColors',
        group: 'Style',
    },
    {
        key: 'colorBy',
        type: `'id' | 'indexValue'`,
        help: 'Property used to determine node color.',
        description: `
            Property to use to determine node color.
        `,
        required: false,
        defaultValue: svgDefaultProps.colorBy,
        controlType: 'choices',
        group: 'Style',
        controlOptions: {
            choices: [
                {
                    label: 'id',
                    value: 'id',
                },
                {
                    label: 'indexValue',
                    value: 'indexValue',
                },
            ],
        },
    },
    {
        key: 'borderRadius',
        help: 'Rectangle border radius.',
        type: 'number',
        required: false,
        defaultValue: svgDefaultProps.borderRadius,
        controlType: 'range',
        group: 'Style',
        controlOptions: {
            unit: 'px',
            min: 0,
            max: 36,
        },
    },
    {
        key: 'borderWidth',
        help: 'Width of bar border.',
        type: 'number',
        required: false,
        defaultValue: svgDefaultProps.borderWidth,
        controlType: 'lineWidth',
        group: 'Style',
    },
    {
        key: 'borderColor',
        help: 'Method to compute border color.',
        description: `
            how to compute border color,
            [see dedicated documentation](self:/guides/colors).
        `,
        type: 'string | object | Function',
        required: false,
        defaultValue: svgDefaultProps.borderColor,
        controlType: 'inheritedColor',
        group: 'Style',
    },
    ...defsProperties('Style', ['svg']),
    {
        key: 'enableLabel',
        help: 'Enable/disable labels.',
        type: 'boolean',
        required: false,
        defaultValue: svgDefaultProps.enableLabel,
        controlType: 'switch',
        group: 'Labels',
    },
    {
        key: 'labelSkipWidth',
        help:
            'Skip label if bar width is lower than provided value, ignored if 0.',
        type: 'number',
        required: false,
        defaultValue: svgDefaultProps.labelSkipWidth,
        controlType: 'range',
        group: 'Labels',
        controlOptions: {
            unit: 'px',
            min: 0,
            max: 36,
        },
    },
    {
        key: 'labelSkipHeight',
        help:
            'Skip label if bar height is lower than provided value, ignored if 0.',
        type: 'number',
        required: false,
        defaultValue: svgDefaultProps.labelSkipHeight,
        controlType: 'range',
        group: 'Labels',
        controlOptions: {
            unit: 'px',
            min: 0,
            max: 36,
        },
    },
    {
        key: 'labelTextColor',
        help: 'Defines how to compute label text color.',
        type: 'string | object | Function',
        required: false,
        defaultValue: '#ffffff',
        controlType: 'inheritedColor',
        group: 'Labels',
    },
    {
        key: 'enableGridX',
        group: 'Grid & Axes',
        help: 'Enable/disable x grid.',
        type: 'boolean',
        required: false,
        defaultValue: svgDefaultProps.enableGridX,
        controlType: 'switch',
    },
    {
        key: 'enableGridY',
        group: 'Grid & Axes',
        help: 'Enable/disable y grid.',
        type: 'boolean',
        required: false,
        defaultValue: svgDefaultProps.enableGridY,
        controlType: 'switch',
    },
    ...axesProperties(),
    {
        key: 'isInteractive',
        flavors: ['svg', 'canvas'],
        help: 'Enable/disable interactivity.',
        type: 'boolean',
        required: false,
        defaultValue: svgDefaultProps.isInteractive,
        controlType: 'switch',
        group: 'Interactivity',
    },
    {
        key: 'showLegend',
        flavors: ['svg', 'canvas'],
        help: 'Enable/disable legend.',
        type: 'boolean',
        required: true,
        defaultValue: true,
        controlType: 'switch',
        group: 'Legends',
    },
    {
        key: 'legends',
        flavors: ['svg', 'canvas'],
        type: 'object[]',
        help: `Optional chart's legends.`,
        group: 'Legends',
        controlType: 'array',
        controlOptions: {
            props: getLegendsProps(['svg']),
            shouldCreate: false,
            addLabel: 'add legend',
            shouldRemove: false,
            getItemTitle: (index, legend) =>
                `legend[${index}]: ${legend.anchor}, ${legend.direction}`,
            svgDefaultProps: {
                dataFrom: 'keys',
                anchor: 'top-left',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: 0,
                itemWidth: 100,
                itemHeight: 20,
                itemsSpacing: 0,
                symbolSize: 20,
                itemDirection: 'left-to-right',
            },
        },
    },
    ...motionProperties(['svg'], svgDefaultProps, 'react-spring'),
]

export const groups = groupProperties(props)

export const sampleData = []
for (let i = 0; i < 20; i++) {
    sampleData.push({
        x: moment()
            .subtract(i, 'days')
            .format('DD/MM/YYYY'),
        y: i * 2000,
    })
}

export const sampleDataGrouped = []
for (let i = 0; i < 12; i++) {
    sampleDataGrouped.push({
        'Coluna 1': moment()
            .subtract(i, 'months')
            .format('MM/YYYY'),
        'Coluna 2': i * 500,
        'Coluna 3': i * 750,
    })
}
