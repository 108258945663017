/* eslint-disable react/jsx-no-bind */
/*
 * This file is part of the nivo project.
 *
 * (c) 2016 Raphaël Benitte
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import ReactMarkdown from 'react-markdown'

const linkRenderer = ({ children }) => {
    // href
    // let uri = href
    return children
    /*(
        <a href={uri} target="_blank" rel="noopener noreferrer">
            {}
        </a>
    )*/
}

const Markdown = memo(({ source }) => {
    return (
        <ReactMarkdown
            transformLinkUri={u => u}
            components={{
                link: linkRenderer,
            }}
        >
            {source}
        </ReactMarkdown>
    )
})

Markdown.displayName = 'Markdown'
Markdown.propTypes = {
    source: PropTypes.string.isRequired,
}

export default Markdown
