import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function ChartIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0,19.2)">
                <path d="M 541,799 Q 648,790 740.5,738.5 833,687 897,603 L 541,332 Z m 459,-499 q 0,-89 -34,-180 L 578,308 921,569 q 79,-122 79,-269 z M 500,300 949,82 Q 887,-45 766,-122.5 645,-200 500,-200 364,-200 249,-133 134,-66 67,49.5 0,165 0,300 q 0,136 67,251 67,115 182,182 115,67 251,67 z" />
            </g>
        </SvgIcon>
    )
}
