import { alpha, darken } from '@material-ui/core/styles'

export const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        position: 'relative',
        zIndex: 1,
    },
    activeLegend: {
        zIndex: 2,
    },
    fullHeight: {
        height: '100%',
    },
    header: {
        display: 'flex',
        marginTop: 5,
        marginBottom: 11,
    },
    headerFlt: {
        marginBottom: 0,
        paddingLeft: theme.spacing(2),
    },
    searchFieldFlt: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    title: {
        margin: 0,
        flexGrow: 1,
        bottom: '-3px',
        fontSize: 20,
        fontWeight: 'bold',
        position: 'relative',
        display: 'inline-block',
        textTransform: 'capitalize',
        lineHeight: `${theme.spacing(4)}px`,
        [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(2),
        },
    },
    titleGuia: {
        fontSize: theme.spacing(2),
        background: '#f4f4f4',
        marginLeft: -1 * theme.spacing(2),
        padding: '0 6px',
        marginTop: -1 * theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(1.5),
        },
    },
    titleEditorForm: {
        paddingLeft: theme.spacing(0.75),
        paddingRight: theme.spacing(0.75),
    },
    editor: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
    },
    bordered: {
        boxShadow: `
            0 1px 3px 0 rgba(0, 0, 0, 0.2),
            0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12)
        `,
    },
    content: {
        overflowY: 'auto',
        flex: '1 1 1px',
    },
    contentFlt: {
        flex: 1,
    },
    actions: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        minHeight: 54,
        boxShadow: `
            0 -1px 3px 0 rgba(0, 0, 0, 0.06),
            0 -1px 1px 0 rgba(0, 0, 0, 0.03),
            0 -2px 1px -1px rgba(0, 0, 0, 0.02)
        `,
    },
    buttons: {
        width: '70%',
        flex: '1 1 70%',
        position: 'relative',
        padding: '12px 45px 18px 35px',
        '& .slick-slide': {
            marginRight: '2px',
        },
        [theme.breakpoints.down('md')]: {
            flex: '1 1 65%',
            width: '65%',
        },
    },
    fewButtons: {
        padding: `${theme.spacing(1)}px`,
        '& button': {
            marginBottom: 5,
        },
    },
    table: {
        width: '100%',
        maxWidth: '100%',
        borderCollapse: 'collapse',
    },
    tableHeader: {
        top: '0',
        zIndex: 1,
        padding: '3px 6px',
        height: 30,
        fontSize: 14,
        fontWeight: '700',
        textAlign: 'left',
        position: 'sticky',
        paddingBottom: '0',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        backgroundColor: theme.palette.common.white,
        boxShadow: `0 -1px 0 ${theme.palette.primary.main} inset`,
        [theme.breakpoints.down('md')]: {
            height: 24,
            fontSize: 12,
        },
    },
    tableHeaderGuia: {
        height: 28,
        paddingTop: 0,
    },
    tableInSearch: {
        backgroundColor: alpha(theme.palette.orange.main, 0.75),
    },
    tableRowInSearch: {
        backgroundColor: alpha(theme.palette.orange.main, 0.35),
    },
    tableRowColored: {
        '& td:before': {
            opacity: 1,
            backgroundColor: 'rgba(35, 34, 34, 0.075)',
        },
    },
    tableRow: {
        height: 24,
        '& td:before': {
            top: 0,
            left: 0,
            right: 0,
            bottom: -1,
            opacity: 1,
            content: '" "',
            position: 'absolute',
        },
        [theme.breakpoints.down('md')]: {
            height: 16,
        },
    },
    tableRowHoverNormal: {
        cursor: 'default',
        '&:hover td:before': {
            backgroundColor: 'rgba(35, 34, 34, 0.2)',
            opacity: 1,
        },
    },
    tableRowHover: {
        cursor: 'pointer',
        '&:hover td:before': {
            backgroundColor: 'rgba(35, 34, 34, 0.2)',
            opacity: 1,
        },
    },
    tableRowActive: {
        '& td:before': {
            backgroundColor: 'rgba(35, 34, 34, 0.39)',
            opacity: 1,
        },
    },
    tableBody: {
        fontSize: 12,
        position: 'relative',
        whiteSpace: 'nowrap',
        padding: '3px 6px 0',
        verticalAlign: 'middle',
        borderTop: '1px solid #ddd',
        [theme.breakpoints.down('md')]: {
            fontSize: 11,
        },
        '& .inputFake': {
            height: 14, // Altura maior estava cortando a exibição das tabelas do editorFormEdit
            fontSize: 14,
            lineHeight: '20px',
            marginRight: 8,
            marginBottom: 0,
            [theme.breakpoints.down('md')]: {
                fontSize: 12,
                lineHeight: '23px',
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: 13,
                lineHeight: '24px',
            },
        },
    },
    tableBodyViewOnly: {
        whiteSpace: 'pre-wrap',
        fontFamily: '"Fira Code"',
        fontWeight: 400,
    },
    showMoreButton: {
        right: 20,
        bottom: 19,
        padding: '0 !important',
        width: '22px !important',
        height: '22px !important',
        borderRadius: '50% 2px 2px 50%',
        transition: '0.5s ease all',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.blue.main,
        '& > span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 0,
            height: 0,
        },
        '& > span > span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&:hover': {
            backgroundColor: darken(theme.palette.blue.main, 0.2),
        },
        '& svg': {
            width: '20px',
            height: '20px',
        },
        [theme.breakpoints.down('md')]: {
            width: '20px !important',
            height: '20px !important',
            right: 18,
            bottom: 18,
            '& svg': {
                width: 18,
                height: 18,
            },
        },
    },
    emptyActionButtonsEditorFormEdit: {
        minHeight: 45,
    },
    iconShowMore: {
        top: -48,
        transform: 'rotate(-90deg) translateY(-50%)',
        left: '50%',
        '& > span': {
            transform: 'rotate(90deg)',
            marginTop: -3,
        },
    },
    buttonsShowMore: {
        flex: '1 1 100%',
        order: 3,
        width: '100%',
        '& button': {
            marginBottom: '2px',
        },
    },
    slider: {
        '& .slick-arrow': {
            padding: '0 !important',
            width: '22px !important',
            height: '22px !important',
            borderRadius: '50%',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: darken(theme.palette.primary.main, 0.2),
            },
            [theme.breakpoints.down('md')]: {
                width: '20px !important',
                height: '20px !important',
            },
        },
        '& .slick-prev': {
            left: '-25px',
            borderRadius: '50% 2px 2px 50%',
        },
        '& .slick-next': {
            right: '-47px',
            borderRadius: '0px 50% 50% 0px',
        },
        '& .slick-disabled': {
            opacity: 0.35,
            cursor: 'not-allowed',
            background: theme.palette.common.black,
            '&:hover': {
                background: theme.palette.common.black,
            },
        },
    },
    iconsRoot: {
        flex: '1 1 30%',
        textAlign: 'right',
        lineHeight: `${theme.spacing(4.5)}px`,
        paddingRight: theme.spacing(1),
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            lineHeight: 'initial',
            textAlign: 'right',
            paddingRight: 8,
        },
    },
    iconButton: {
        width: '36px !important',
        height: '36px !important',
        padding: '4px !important',
        [theme.breakpoints.down('md')]: {
            width: '32px !important',
            height: '32px !important',
            '& svg': {
                fontSize: '1.2rem',
            },
        },
    },
    pagination: {
        whiteSpace: 'nowrap',
    },
    rowActions: {
        padding: '9px 6px',
        '& > button': {
            width: 20,
            height: 20,
            background: theme.palette.blue.main,
            color: theme.palette.common.white,
            padding: '0px !important',
            marginRight: '4px !important',
            '&:hover': {
                background: darken(theme.palette.blue.main, 0.2),
            },
            '& > span > svg': {
                width: 14,
                height: 14,
            },
        },
    },
    draggable: {
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing',
        },
        '& svg': {
            fontSize: 16,
        },
    },
    lackIcons: {
        paddingRight: 60,
    },
    lackIconsShowMore: {
        right: 35,
    },

    // framework/components/EditorFormEdit
    icon: {
        '& svg': {
            fontSize: 14,
            color: theme.palette.primary.main,
        },
    },
    inputInsertEdit: {
        fontSize: 12,
    },
})
