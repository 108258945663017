import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function PdfIcon(props) {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M7.1,15.4c0-0.4-0.3-0.6-0.8-0.6c-0.2,0-0.3,0-0.4,0v1.3c0.1,0,0.2,0,0.3,0C6.7,16.1,7.1,15.9,7.1,15.4z" />
                <path d="M10.1,14.8c-0.2,0-0.4,0-0.4,0v2.8c0.1,0,0.2,0,0.3,0c0.9,0,1.5-0.5,1.5-1.5C11.5,15.3,10.9,14.8,10.1,14.8z" />
                <path
                    d="M15.7,0H6.1C4.7,0,3.5,1.1,3.5,2.6V12H3.3c-0.6,0-1,0.5-1,1v6.2c0,0.6,0.5,1,1,1h0.2v1.1c0,1.4,1.1,2.6,2.6,2.6h13.2
		c1.4,0,2.6-1.1,2.6-2.6V6L15.7,0z M4.9,14.2c0.3-0.1,0.7-0.1,1.3-0.1c0.6,0,1,0.1,1.3,0.3C7.9,14.6,8,15,8,15.4
		c0,0.4-0.1,0.8-0.4,1c-0.3,0.3-0.8,0.5-1.4,0.5c-0.1,0-0.2,0-0.3,0v1.5h-1V14.2z M19.2,22.4H6.1c-0.5,0-1-0.4-1-1v-1.1h12.3
		c0.6,0,1-0.5,1-1V13c0-0.6-0.5-1-1-1H5.1V2.6c0-0.5,0.4-1,1-1l9.1,0v3.3c0,1,0.8,1.8,1.8,1.8l3.3,0l0,14.8
		C20.2,22,19.8,22.4,19.2,22.4z M8.7,18.4v-4.3c0.4-0.1,0.8-0.1,1.3-0.1c0.8,0,1.4,0.1,1.8,0.5c0.4,0.3,0.7,0.9,0.7,1.6
		c0,0.8-0.3,1.4-0.7,1.8c-0.5,0.4-1.1,0.6-2,0.6C9.3,18.5,8.9,18.4,8.7,18.4z M15.7,15.9v0.8h-1.6v1.7h-1v-4.3h2.6v0.8h-1.7v1H15.7z
		"
                />
            </g>
        </SvgIcon>
    )
}
