import makeStyles from '@material-ui/core/styles/makeStyles'
import * as PropTypes from 'prop-types'
import React from 'react'

import withGetPicture from './withGetPicture'

const useStyles = makeStyles(() => ({
    imgPreview: {
        maxHeight: 16,
    },
    previewer: {
        position: 'absolute',
        left: 24,
        top: 24,
        maxWidth: 536,
        maxHeight: 536,
        border: '1px solid #ddd',
        boxShadow: '1px 0px 3px -2px #000',
        overflow: 'hidden',
        background: '#ffffff',
        borderRadius: 4,
        zIndex: 1,
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0,
        transition: '0.5s ease opacity',
        '& img': {
            maxWidth: 536,
            maxHeight: 536,
        },
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        border: '1px solid #ddd',
        '&:hover > div': {
            display: 'flex',
            opacity: 1,
        },
    },
}))

const PreviewerSmall = ({ getPicture = {} }) => {
    const classes = useStyles()
    const { findPicture: fotos } = getPicture || {}

    if (!fotos || !fotos.length) {
        return null
    }

    const foto = fotos[0]
    const url = foto?.urlImagem
        ? foto.urlImagem
        : `data:image/jpg;base64,${fotos[0].foto}`

    return (
        <div className={classes.root}>
            <img className={classes.imgPreview} height={16} src={url} />

            <div className={classes.previewer}>
                <img src={url} />
            </div>
        </div>
    )
}

PreviewerSmall.propTypes = {
    url: PropTypes.string,
    className: PropTypes.string,
    getPicture: PropTypes.object,
}

export default withGetPicture(PreviewerSmall)
