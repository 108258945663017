import * as PropTypes from 'prop-types'
import React from 'react'
import CurrencyInput from 'react-number-format'

import { Input } from '../../../../styled/Input'
import AbstractTextField from './AbstractTextField'

export const NumberFormat = props => {
    const {
        inputRef,
        onChange,
        precision,
        allowNegative,
        maxLength,
        fixedDecimalScale = false,
        ...other
    } = props

    function onValueChange(values) {
        onChange({
            target: {
                value: values.floatValue,
            },
        })
    }

    const formattedRecs = ['REC_MONEY', 'REC_F_LONG']

    const isFormattable =
        formattedRecs.indexOf(props.fieldType ? props.fieldType.typeRec : '') >
        -1

    return (
        <CurrencyInput
            {...other}
            customInput={Input}
            thousandSeparator={isFormattable ? '.' : ''}
            decimalSeparator={isFormattable ? ',' : '.'}
            allowedDecimalSeparators={['.', ',']}
            maxLength={maxLength}
            getInputRef={inputRef}
            decimalScale={precision}
            fixedDecimalScale={fixedDecimalScale}
            allowEmptyFormatting={false}
            defaultValue={''}
            allowNegative={allowNegative}
            onValueChange={onValueChange}
        />
    )
}

NumberFormat.propTypes = {
    precision: PropTypes.any,
    allowNegative: PropTypes.any,
    maxLength: PropTypes.any,
    inputRef: PropTypes.any,
    onChange: PropTypes.func,
    fixedDecimalScale: PropTypes.bool,
    fieldType: PropTypes.object,
}

/**
 * Componente TextFieldShort
 *
 * Rec: REC_SHORT
 *
 * Renderiza um campo de texto que tem formatação de casas
 * decimais
 */
export default class TextFieldShort extends AbstractTextField {
    fixedDecimalScale = false
    allowNegative = false

    constructor(props) {
        super(props)
        this.state = {
            value: parseFloat(props.value) || '',
        }
    }

    componentDidUpdate(prevProps) {
        const { value, enabled } = this.props
        if (prevProps.value !== value || prevProps.enabled !== enabled) {
            this.setState({
                value: parseFloat(value) || '',
            })
        }
    }

    beforeSend = value => {
        if (!value) {
            return '0'
        }
        return value.toString().replace(/,/g, '.')
    }

    render() {
        const { value } = this.state

        const {
            enabled,
            index,
            prec,
            lbl,
            sz,
            x,
            y,
            ...otherProps
        } = this.props

        const fieldType = this.getFieldType()
        const type = fieldType ? fieldType.type : 'text'

        return (
            <NumberFormat
                {...otherProps}
                autoComplete="off"
                orientation="horizontal"
                type={type}
                label={lbl}
                index={index}
                tabIndex={index}
                inputRef={this.createRef('input')}
                disabled={!enabled}
                onChange={this.handleInputChange}
                value={value}
                beforeSend={this.beforeSend}
                fieldType={fieldType}
                position={{ x, y }}
                maxLength={sz}
                precision={prec}
                autoFocusOnBlur={false}
                fixedDecimalScale={this.fixedDecimalScale}
                allowNegative={this.allowNegative}
                overwriteOnFirst={true}
                preventDispatchToWS={true}
            />
        )
    }
}
