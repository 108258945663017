import withStyles from '@material-ui/core/styles/withStyles'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import { styles } from '@nerus/styles/components/hint'
import PropTypes from 'prop-types'
import React from 'react'
import Draggable from 'react-draggable'
import { connect } from 'react-redux'

import { getActiveComponent, getComponentById } from '../../Eac/EacReducer'

class Hint extends EnhancedComponent {
    static propTypes = {
        hint: PropTypes.object,
        classes: PropTypes.object,
    }

    static mapStateToProps(state, ownProps) {
        return {
            hint: getComponentById(ownProps.id, state).payload,
            activeComponent: getActiveComponent(state),
        }
    }

    static baseOpts = {
        skip: true,
    }

    constructor(props) {
        super(props)
        this.state.opacity = 0
    }

    onStart = () => {
        this.setState({ activeDrags: 1 })
    }

    onStop = (e, data) => {
        this.setState({ activeDrags: 0, centerPosition: data })
    }

    componentDidMount() {
        this.componentDidUpdate()
    }

    componentDidUpdate() {
        clearTimeout(this.updateTimeout)
        this.updateTimeout = setTimeout(() => {
            let activeElement = document.activeElement || false
            const elements = ['input', 'select', 'button', 'li']
            if (
                activeElement?.tagName?.toLowerCase() === 'body' ||
                activeElement.length === 0
            ) {
                activeElement = document.querySelectorAll(
                    elements.join(':focus,') + ':focus'
                )

                if (activeElement.length > 0) {
                    activeElement = activeElement[0]
                }
            }

            if (
                activeElement?.tagName?.toLowerCase() !== 'body' ||
                activeElement.length != 0
                // && this.props.hint.id > this.props.activeComponent.payload.id //-> TICKET 99052
            ) {
                const hint = this.getRef('hint')
                if (
                    hint &&
                    activeElement
                    // && elements.indexOf(activeElement?.tagName?.toLowerCase()) > -1 //-> TICKET 99052
                ) {
                    const rect = activeElement?.getBoundingClientRect?.() || {
                        top: 90,
                        left: 50,
                        width: 0,
                    }
                    const top = rect.top - hint.offsetHeight - 8
                    let left = rect.left
                    if (left + hint.offsetWidth > window.innerWidth) {
                        left = left - hint.offsetWidth + rect.width
                    }

                    if (this.state.left !== left && this.state.top !== top) {
                        this.setState({ left, top, opacity: 1 })
                    }
                } else {
                    if (this.state.display !== 'block') {
                        this.setState({ opacity: 1 })
                    }
                }
            }
        }, 350)
    }

    componentWillUnmount() {
        clearTimeout(this.updateTimeout)
    }

    state = {}

    render() {
        const { left, top, opacity } = this.state
        const { classes, hint } = this.props

        const dragHandlers = {
            onStart: this.onStart,
            onMouseDown: this.onStart,
            onStop: this.onStop,
        }

        return (
            <Draggable {...dragHandlers}>
                <div
                    ref={this.createRef('hint')}
                    className={classes.root}
                    style={{ left, top, opacity }}
                >
                    <div
                        className="content"
                        dangerouslySetInnerHTML={{
                            __html: hint.text.replace(/\n/g, '<br />'),
                        }}
                    />

                    <div className={classes.handle} />
                </div>
            </Draggable>
        )
    }
}

export default connect(Hint.mapStateToProps)(withStyles(styles)(Hint))
