export const styles = theme => ({
    root: {
        width: theme.sizing.sidebar,
        display: 'flex',
        boxShadow: '8px 0px 3px -4px rgba(0,0,0,0.12)',
        padding: `0px 0px 0px ${theme.spacing(2)}px`,
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            width: `${theme.sizing.sidebarSmall}px`,
        },
    },
    rootO2: {
        display: 'flex',
        padding: `14px 26px`,
        alignItems: 'center',
        marginRight: theme.spacing(2),
        borderRight: `1px solid rgba(255, 255, 255, 0.2)`,
        minWidth: theme.sizing.sidebar,
        [theme.breakpoints.down('md')]: {
            width: `${theme.sizing.sidebarSmall}px`,
        },
        '& h3': {
            margin: 0,
            fontSize: 22,
            fontWeight: 900,
            cursor: 'default',
            lineHeight: `12px`,
            letterSpacing: '0.14px',
        },
    },
    logo: {
        marginRight: 21,
    },
    icons: {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: `0 ${theme.spacing(1) / 2}px ${theme.spacing(1) / 2}px`,
    },
    icon: {
        color: 'rgba(255,255,255,0.8)',
        width: '28px !important',
        height: '28px !important',
        padding: '2px !important',
        '& svg': {
            maxWidth: 20,
            maxHeight: 20,
        },
    },
})
