import keycodes, {
    CTRL_ALT_MAPPING,
    CTRL_MAPPING,
    functionKeys,
    isPreventedKey,
    NUMPAD_MAPPING,
    persistents,
} from '@nerus/framework/common/Keycodes'
import throttle from 'lodash.throttle'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import getSelectionText from '../../../util/getSelectionText'
import { toggleCalculator } from '../../App/AppActions'
import {
    nextElementIndex,
    previousElementIndex,
    sendBuffer,
    toggleLegend,
} from '../Eac/EacActions'
import {
    createGetComponentById,
    formComponents,
    getActiveComponent,
    isLegendShow,
} from '../Eac/EacReducer'
import { withWS } from './Websocket/Context'

const debug = require('debug')('nerus:keyboard')

/**
 * Essa função faz o tratamento de todos os formatos de teclas de atalho
 * É usado em todos os locais que precisamos exibir tecla
 */
export function formatTooltip(rawTip, atalho, underlined = false) {
    let tip = rawTip
    let legendClass = 'highlight'

    if (underlined) {
        legendClass += ' underlined'
    }

    if (typeof rawTip !== 'string') {
        return rawTip
    }

    if (tip === '(l)egenda') {
        // sem atalho
        return [
            <span key="legend-highlight" className={legendClass}>
                ?
            </span>,
            ' - Legenda de Cores',
        ]
    }

    if (tip === undefined) {
        return
    }

    if (!tip.split) {
        tip = tip.pop()
    }

    const parts = tip.split('-')

    // Se vier teclas cujo o atalho é "-"
    const isMinusTip = tip[0] === '-'
    if (isMinusTip) {
        parts.shift()
        parts[0] = '-'
    }

    const str = []
    let matched = false
    if (parts?.length > 1 && !tip.match(/(\s)[A-Z](\s)/)) {
        str.push(
            <span key={'tip-' + tip + '-1'} className={legendClass}>
                {parts[0].replace(/[()]/g, '')}
            </span>
        )

        const rest = parts.slice(1).join('-')
        if (rest?.trim?.()) {
            str.push(' | ')
            str.push(rest.substr(0, 1) + rest.substr(1))
        }
        matched = true
    } else {
        // var matches = tip.match(/([a-zA-Z]*)\((.+)\)([a-zA-Z]*)/);
        let matches = tip.match(/(.*)(\s)([A-Z])(\s)(.*)/)
        if (matches) {
            if (matches[1]) {
                str.push(matches[1])
                str.push(
                    <span key={'tip-' + tip + '-2'} className={legendClass}>
                        {matches[3].trim()}
                    </span>
                )
            } else {
                str.push(
                    <span key={'tip-' + tip + '-3'} className={legendClass}>
                        {matches[3].trim()}
                    </span>
                )
            }

            str.push(matches[5])
            matched = true
        } else {
            matches = tip.match(/([a-zA-Z]*)\((.+)\)([a-zA-Z]*)/)
            if (matches) {
                if (matches[1]) {
                    str.push(matches[1])
                    str.push(
                        <span key={'tip-' + tip + '-2'} className={legendClass}>
                            {matches[2].trim()}
                        </span>
                    )
                } else {
                    str.push(
                        <span key={'tip-' + tip + '-3'} className={legendClass}>
                            {matches[2].trim()}
                        </span>
                    )
                }
                matched = true
                str.push(matches[3])
            } else {
                str.push(tip)
            }
        }

        if (!matched && atalho && str?.length === 1) {
            let regex = new RegExp(atalho)
            let strReplaced = str[0].replace(
                regex,
                `<span class="${legendClass}">${atalho}</span>`
            )

            if (strReplaced === str[0]) {
                regex = new RegExp(atalho.toLowerCase())
                strReplaced = str[0].replace(
                    regex,
                    `<span class="${legendClass}">${atalho}</span>`
                )
            }

            return (
                <span
                    dangerouslySetInnerHTML={{
                        __html: strReplaced,
                    }}
                />
            )
        }
    }

    return str
}

/**
 * É um componente que é responsável por ouvir o teclado
 * E dispachar pro WS caso necessário ou executar algo
 * na aplicação, como a calculadora
 */
export class KeyboardHandle extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        datepickerOpen: PropTypes.bool,
        showCalculator: PropTypes.bool,
        disabled: PropTypes.bool,
        isLocked: PropTypes.bool,
        wsStop: PropTypes.bool,
        activeComponent: PropTypes.object,
        getComponentById: PropTypes.func,
        isLegend: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        formBuffer: PropTypes.array.isRequired,
        ws: PropTypes.object.isRequired,
    }

    static mapStateToProps = state => ({
        isLocked: state.app.isLocked,
        wsStop: state.app.wsStop,
        datepickerOpen: state.app.datepickerOpen,
        showCalculator: state.app.showCalculator,
        activeComponent: getActiveComponent(state),
        getComponentById: createGetComponentById(state),
        isLegend: isLegendShow(state),
        formBuffer: state.eac.formBuffer,
    })

    throtledSend = throttle(
        dispatchEvent => this.props.ws.send(dispatchEvent),
        25,
        { leading: true }
    )

    _handleKeyDown = (event => {
        let keyCode = event.keyCode

        // fromCharCode traduz para o caracter errado 3/4 em vez de .
        keyCode =
            keyCode === keycodes.NUMPAD_SUBTRACT
                ? keycodes.MINUS
                : keyCode === keycodes.NUMPAD_PLUS
                ? keycodes.PLUS
                : keyCode
        let key = keyCode === 190 ? '.' : String.fromCharCode(keyCode)
        let isLocked = this.props.isLocked
        const isDatepickerOpen = this.props.datepickerOpen
        let isFunctionKey = functionKeys.indexOf(keyCode) > -1
        let isTabKey = keyCode === keycodes.TAB_KEY
        let isLeftArr = keyCode === keycodes.LEFT_ARROW_KEY
        let isRightArr = keyCode === keycodes.RIGHT_ARROW_KEY
        let isEscKey = keyCode === keycodes.ESCAPE_KEY
        let isEnterKey = keyCode === keycodes.ENTER_KEY
        let isUpArr = keyCode === keycodes.UP_ARROW_KEY
        let isDownArr = keyCode === keycodes.DOWN_ARROW_KEY

        const activeComponent = this.props.activeComponent

        const isTypeMenuGuias =
            activeComponent?.name === 'Menu' &&
            activeComponent?.payload?.typeMenu === 'guias' &&
            activeComponent?.payload?.content

        const { ws } = this.props

        // Se temos alguma seleção de texto, vamos deixar os atalhos do keyboard
        // funcionarem normalmente
        if (getSelectionText() !== '') {
            debug.extend('selectionText')('Drop first by selectionText')
            return
        }

        debug.extend('keydown')(event)
        //Se desativado
        if (this.props.isLegend && keyCode === keycodes.ESCAPE_KEY) {
            this.props.dispatch(toggleLegend())
            return
        }

        if (
            this.props.wsStop ||
            this.props.disabled ||
            keyCode === keycodes.SHIFT_KEY ||
            keyCode === keycodes.CTRL_KEY ||
            keyCode === keycodes.ALT_KEY ||
            keyCode === keycodes.CAPS_LOCK_KEY ||
            this.props.isLegend ||
            isDatepickerOpen
        ) {
            return
        }

        // Legenda
        if (event.shiftKey && keyCode === keycodes.QUESTION_MARK_KEY) {
            this.props.dispatch(toggleLegend())
            return
        }

        //Calculadora
        if (
            keyCode === keycodes.F5_KEY ||
            (this.props.showCalculator && keyCode === keycodes.ESCAPE_KEY)
        ) {
            this.props.dispatch(toggleCalculator())
            event.preventDefault()
            return
        } else if (this.props.showCalculator) {
            return
        }

        if (activeComponent && activeComponent.payload.disableKeyboard) {
            let { keyboardActions } = activeComponent.payload
            if (keyboardActions) {
                let proxy = keyboardActions(ws)
                proxy(this.props.dispatch, event, activeComponent.payload)
                proxy = null
            }

            keyboardActions = null

            return
        }

        if (
            keyCode &&
            (functionKeys.indexOf(keyCode) > -1 ||
                event.ctrlKey ||
                event.altKey)
        ) {
            event.preventDefault()
            event.stopImmediatePropagation && event.stopImmediatePropagation()
        }

        /**
         * Para essas teclas, prevenimos o comportamento padrão
         * assim, evitamos alguns comportamentos do browser
         *
         * Essas teclas ainda são enviadas para o WS
         */
        if (isFunctionKey || isTabKey || isDownArr || isUpArr) {
            event.preventDefault()
        }

        /**
         * Levando em conta se devemos usar o key ou o keyCode
         * disparado pelo teclado
         */
        let buffer = !isFunctionKey && isPreventedKey(keyCode) ? key : keyCode
        let component = activeComponent
        let type = 'sendBuffer'

        if (keyCode >= 96 && keyCode <= 105) {
            buffer = keyCode - 48
        }

        if (!isLocked && activeComponent) {
            switch (activeComponent.name) {
                case 'RelatorioDialogGerador': {
                    if (isTabKey) {
                        event.preventDefault()
                        event.stopImmediatePropagation &&
                            event.stopImmediatePropagation()
                        return
                    }
                    break
                }
                // Tratamento de tecla para os atalhos das Dialogos
                case 'Dialogo':
                case 'FileSend': {
                    if (isTabKey || isRightArr) {
                        this.props.dispatch(nextElementIndex())
                        return
                    }
                    if (isLeftArr) {
                        this.props.dispatch(previousElementIndex())
                        return
                    }

                    isLocked = false
                    if (activeComponent.name === 'Dialogo') {
                        if (!event.shiftKey && keyCode >= 65 && keyCode <= 90) {
                            keyCode += 32
                            key = String.fromCharCode(keyCode)
                        }

                        if (isEscKey) {
                            keyCode = 110
                            key = 'n'
                        } else if (isEnterKey) {
                            // caso o usuário aperte enter, enviamos a tecla que está ativa, então
                            // deixamos o componente tomar conta disso
                            isLocked = true
                            keyCode = 0
                        }
                    }

                    break
                }
            }
        }

        /**
         * Se a aplicação não está travada ou a tecla é uma tecla persistente
         * envia para o WS
         */
        if (
            !isLocked ||
            (isLocked && formComponents.indexOf(activeComponent?.name) > -1) ||
            persistents.indexOf(keyCode) !== -1
        ) {
            if (typeof buffer !== 'object') {
                if (event.ctrlKey) {
                    if (event.altKey) {
                        buffer = CTRL_ALT_MAPPING[keyCode]
                    } else {
                        buffer = CTRL_MAPPING[keyCode]
                    }
                }
            }

            if (isTypeMenuGuias) {
                component = {
                    name: activeComponent.payload.content.type,
                    payload: {
                        ...activeComponent.payload.content,
                        ...(activeComponent.payload.content?.data || {}),
                    },
                }

                if (
                    component.name === 'Formulario' &&
                    keyCode > -1 &&
                    !event.nerusEvent
                ) {
                    event.nerusEvent = component.payload.ws.data
                    event.nerusEvent.key = keyCode
                }
            }

            if (event.nerusEvent) {
                buffer = event.nerusEvent || {}
                buffer.ctrlKey = event.ctrlKey
                buffer.altKey = event.altKey
                buffer.key = isTypeMenuGuias
                    ? NUMPAD_MAPPING[keyCode] ?? keyCode
                    : keyCode
                /*
                 * adicionamos a tecla original apertada (não é muito confiável)
                 * apenas para ajudar na validação dos valores apertados
                 */
                buffer.originalKey = key
                if (
                    activeComponent?.payload?.hasTripleId &&
                    buffer.componentId
                ) {
                    const { getComponentById } = this.props
                    component = getComponentById(buffer.componentId)
                }
            }

            if (component?.payload?.ws) {
                type = component.payload.ws.retType || 'sendEdit'
            }
            /*
             * Se for uma mensagem que devemos travar a aplicação para que a
             * comunicação ocorra nos momentos corretos
             */
            const shouldLock =
                type === 'sendEdit' ||
                formComponents.indexOf(component?.name) > -1
            const dispatchEvent = sendBuffer(buffer, type, {
                shouldLock,
                shouldFormat: event.nerusEvent === undefined,
                repeat: event.repeat,
            })

            if (!event.repeat) {
                ws.send(dispatchEvent)
            } else {
                this.throtledSend(dispatchEvent)
            }
        }
    }).bind(this)

    /**
     * Quando vamos desmontar esse componente, ou seja, voltamos pra tela de Login
     * removemos o bind do keydown
     */
    componentWillUnmount() {
        if (typeof document !== 'undefined') {
            document.removeEventListener('keydown', this._handleKeyDown)
        }
    }

    /**
     * Quando vamos montar esse componente, temos que capturar o teclado
     */
    componentDidMount() {
        if (typeof document !== 'undefined') {
            document.addEventListener('keydown', this._handleKeyDown)
        }
    }

    render() {
        return this.props.children
    }
}

export default withWS(connect(KeyboardHandle.mapStateToProps)(KeyboardHandle))
