import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import keycodes from '@nerus/framework/common/Keycodes'
import useWS from '@nerus/framework/hooks/useWS'
import React, { Fragment, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { sendBuffer } from '../../Eac/EacActions'
import BaseFlutuante from '../Flutuante/BaseFlutuante'

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        '& h2': {
            paddingLeft: 16,
        },
        '& strong': {
            color: theme.palette.primary.main,
        },
    },
}))

/**
 * Componente Fingerprint
 */
const Fingerprint = () => {
    const classes = useStyles()
    const fingerprint = useSelector(state => state.eac.fingerprint)
    const ws = useWS()

    const onClose = useCallback(() => {
        ws.current.send(sendBuffer(keycodes.ESCAPE_KEY))
    }, [ws])

    return (
        <BaseFlutuante
            handleClose={onClose}
            title={`Biometria: ${fingerprint.data.nome}`}
        >
            <div className={classes.wrapper}>
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="64"
                    height="64"
                    viewBox="0 0 1000 1000"
                >
                    <g>
                        <path d="M493.7,168.7c77.4,0.2,143.7,22.5,202.6,65.5c34.9,25.5,63.3,57.6,86.7,94.1c7.6,11.8,6.1,22-3.9,28.5c-13.1,8.6-23.5,3.2-30.1-7.2c-13.8-21.7-29.7-41.5-48.4-59.3c-39.9-37.9-86.7-62.8-140.3-74.9c-16.8-3.8-33.9-5.3-51.2-6.1c-35.9-1.7-70.9,2.6-105,13.9c-47.6,15.7-88.5,42.2-122,79.4c-14.6,16.3-27,34.6-40.3,52.1c-16.4,21.6-33.8,42.3-51.5,62.9c-37,43.3-84.9,70.6-135,94.8c-6.9,3.3-13.8,7.7-22.1,8.3c-10.6,0.7-20-4.4-22.5-13.1c-2.7-9.4,2-20.6,11.4-24.8c20.3-9.2,40.3-18.8,59.6-30c22.1-12.8,42.4-27.9,61.7-44.6c27-23.4,49.3-50.7,68.8-80.5c29.6-45.3,67.7-82.3,113.4-110.8c34.6-21.6,72.6-35.6,113.2-42.3C458.3,171.2,477.8,168.4,493.7,168.7z" />
                        <path d="M906.4,476.5c0.6,70.8-14.2,131.9-37.7,191.3c-18.7,47.2-42.8,91.3-70.9,133.4c-14.5,21.7-30.7,42.1-47.2,62.3c-6.6,8-18.4,11.5-26.8,4.1c-10.1-8.8-10.7-19.2-2.3-29.3c26.9-32.3,51.3-66.3,71.8-103.1c22.4-40.3,41.9-81.9,55.1-126.2c8.1-27.1,13.6-54.6,17.5-82.7c2-14.7,2.9-29.6,2.8-44c-0.3-43.7-7-86.6-23.8-127.6c-20.7-50.4-52.5-92.7-92.6-128.8c-32.5-29.2-68.8-53.1-108.9-71c-15.5-6.9-18.1-23.7-5.8-33.1c6.4-4.9,13.5-4.9,20.8-1.9c34.7,14.2,66,34,95.4,56.9c37.5,29.3,70.3,63.2,95.8,103.5c25.6,40.6,43.4,84.3,51.1,132C904.4,435.8,907.2,459.2,906.4,476.5z" />
                        <path d="M500.4,402c44.1-0.9,91.4,37,97.2,82.5c3.3,25.9-2.7,48.9-14.9,71.1c-17.9,32.5-42.9,59.4-68.4,85.9c-17.2,17.8-33.6,36.4-50.7,54.3c-9,9.5-19.9,10-28.6,1.4c-7.2-7-9.2-19.3,0.7-29.6c11.4-11.9,23.5-23.2,34.9-35.1c27.5-28.7,55.2-57.2,78-89.9c12.2-17.6,20.5-37,13.5-59.1c-7.8-24.9-25-41.3-50.3-45.9c-23.4-4.2-43.2,3.8-59.1,23.3c-13.9,17.1-28.3,33.8-42.2,50.9c-21.9,26.6-43.9,53.1-66,79.6c-28.1,33.7-61.2,61.3-99.6,83c-38.2,21.5-77,41.7-117.5,58.4c-10.8,4.5-22.1,7.8-33.3,11c-9,2.5-19.9-2.8-21.5-9.6c-2.6-11.2,3.6-22.1,14-25c61.4-17.3,118.6-43.6,168.7-83.3c33.4-26.5,63.8-56.4,90.5-89.9c26.6-33.4,53.2-66.8,80.4-99.7C441.3,418.2,471.2,400.9,500.4,402z" />
                        <path d="M495.8,285.2c-52,0.8-97.9,17.5-138.7,50.4c-26.1,21-42.7,48.6-60.1,76.1c-15.2,24.1-34.6,44.4-56.4,62.6c-8.4,6.9-16.7,13.9-25.1,20.8c-6.6,5.5-18.3,5.8-25,0.7c-6.9-5.3-9.8-11.8-6.1-20.1c3-6.6,8.6-11,14.3-15.3c27.7-21,53.1-44.6,72.4-73.7c9.3-14,15.9-29.7,25.2-43.6c19.6-29.3,46.6-50.4,77.2-67.2c45.1-24.8,93.8-33,144.4-28.9c40,3.3,77.5,16,111.8,37.4c35,21.8,63.8,50,85.3,85.2c18.3,30,30.5,62.1,35.9,97.2c4.2,26.9,3.9,53.4-0.3,80.1c-1.5,9.5-7.2,15.3-15.2,18c-7.8,2.7-14.8-0.5-20.7-6.8c-4.4-4.6-3.6-9.8-2.7-14.5c10.1-52.3,2.5-102.1-24.2-147.7c-28.8-49.4-71.3-83.1-126.3-100.5C540.3,288.3,518.4,284.8,495.8,285.2z" />
                        <path d="M989.9,500.6c0.2,92.9-21.8,173.7-64.4,248.8c-15,26.5-32.4,51.7-53.1,74.4c-5.2,5.7-9.8,11.9-15.5,17c-8.2,7.3-19.3,7.7-28,1.5c-6.6-4.7-10.7-18-7.3-26c1.5-3.7,3.3-7.5,6.3-10.6c30.8-31.1,54.4-67.3,73.4-106.5c18.9-39,31.6-79.9,38.2-122.9c4.8-30.9,7.3-62,5.3-93.1c-3.1-50.3-13.6-99.2-32.8-146.1c-13.4-32.8-30.3-63.5-50.9-92.3c-6.2-8.7-4.2-23,3.6-29.8c9.2-8,21.2-7.6,30,1.4c11.2,11.5,18.7,25.8,26.7,39.4c19.1,32.3,34.4,66.4,45.5,102.4c9.1,29.5,15.8,59.3,19.2,90C988.4,467.8,990.4,487.3,989.9,500.6z" />
                        <path d="M676.9,507.3c1.4,44.4-16.1,80.9-40.5,114.8c-30.5,42.5-64.9,81.9-102.6,118.1c-26.5,25.5-55.3,48.3-84.2,71.1c-32.9,26.1-66.3,51.4-101.7,74.1c-22.2,14.2-45,27.4-68.7,39c-9.5,4.6-18.6,2.2-23.3-6c-4.7-8.2-2.3-19.1,6.2-24.3c10.8-6.6,22.2-12.3,33.3-18.5c33.1-18.4,64.1-39.9,94.2-62.9c33.8-25.8,66.6-52.7,97.7-81.8c31.1-29.1,61.4-58.8,89.1-91.1c15.2-17.7,30.3-35.3,41.8-55.7c15.7-27.9,24.8-56.9,20.5-90.1c-4.7-37-19.8-67.4-46.7-92.5c-10.8-10-23.1-17.9-36.3-24.5c-6.5-3.2-10.9-8.1-10.7-15.6c0.2-6.9,4.2-12.7,10.1-14.9c7.6-2.9,15.8-1.4,23.2,2.3c50.9,25.4,83.2,65.4,94.8,121.5c1.3,6.2,3.1,12.4,3.7,18.6C677.4,495.4,676.9,502,676.9,507.3z" />
                        <path d="M832.6,502.3c-1,58.5-13.7,114-42.8,165.3c-17.2,30.2-37.2,58.3-59.7,84.8c-18.3,21.6-37.8,42-56.8,62.9c-23.7,26.1-48.7,50.9-74.1,75.2c-34.9,33.3-70.7,65.8-109.7,94.5c-8.5,6.3-19.1,5.3-25.6-2.1c-6.9-7.9-6.4-17.7,1.1-25.8c12.5-13.4,28.3-22.9,41.7-35.4c11.2-10.4,23.6-19.5,35-29.7c34.7-31.1,67.3-64.3,99.5-97.9c22-23,41.8-48.2,62.1-72.8c19.3-23.3,37.7-47.2,53.2-73.2c17.2-28.9,28.4-60,33.2-93.4c1.9-13,3.5-25.9,4-39c1.3-32.6-3.6-64.3-12.7-95.4c-3.4-11.8,1.3-21,13.4-25.3c9.7-3.5,20.9,3.1,24.3,14.3c5.8,19.5,10.3,39.1,12.1,59.5C831.9,479.8,832.1,491,832.6,502.3z" />
                        <path d="M579,133.8c-16-2.4-34.9-5.4-54.1-6.5c-14.8-0.9-29.2-1.2-44,0.1c-17.2,1.5-34.2,3.3-51.3,6c-26.5,4.3-51.8,12.2-76.4,22.3c-36.7,15.1-69.7,36.2-100,62.1c-28.3,24.2-49.8,53.7-71.3,83.6c-17.8,24.8-38.1,47.2-61.2,67.3c-19.3,16.8-38.4,33.5-60.6,46.5c-5.5,3.2-11.1,6.2-16.8,9c-7.9,4-18.8,1-23.2-6.3c-5-8.5-2.5-17.9,6.2-24c16.2-11.2,32.8-21.8,48.3-34c32.1-25.4,59.9-54.4,81.5-89.7c27.6-45.1,65.8-80.5,108.5-111.2c29.4-21.1,61.4-37.1,95.2-49.2c25-8.9,51-14.8,77.3-19.3c20-3.4,40.3-4.7,60.1-4.6c30,0.2,60.5,1.9,89.8,10.7c11.9,3.6,17.4,11,15.8,20.6C601.2,128.4,594.2,133.8,579,133.8z" />
                        <path d="M490.7,11.4c54.5-1.2,103.4,7.6,151.7,22.4c54.3,16.6,103.6,42.8,149.2,76.1c18.6,13.6,36,28.9,52,45.7c8.5,8.9,8.7,21.3,0.4,29.9c-9.6,9.9-21.6,10.8-31,1.6c-39.8-38.4-84.3-69.8-134.9-92.5c-31.1-13.9-63.3-23.9-96.6-30.8c-39.3-8.2-79.2-9.7-118.9-7.3c-38.7,2.3-76.8,9.6-113.3,23.4c-10.8,4.1-22.4-0.7-27.7-11.8c-4.9-10.3-1.1-22.7,8.8-27.9c19.3-10.3,40.6-14.3,61.5-18.7C425.6,14.3,459.9,9.5,490.7,11.4z" />
                        <path d="M486,328.3c4.1,0.5,8.2-1,12.3,1c7.8,3.8,12.5,10,12.1,18.5c-0.4,9.3-8.9,17.3-18.7,17.2c-37.1-0.7-64.9,18.4-91.8,40.9c-40.5,33.8-70.8,76.1-101.8,118c-17.8,24.1-39.9,43.8-64.7,60.7c-52.2,35.5-108,63.9-167.8,84.1c-16.9,5.7-31.2-5.1-27.7-20.9c2.3-10.4,10.8-15.5,20.4-18.2c55.4-15.7,107.3-39.2,153.8-73c31-22.5,56.6-50.7,78.3-82.4c21.5-31.4,45.2-61.2,72.8-87.5c28.3-26.9,59.8-48.4,99-55.6C470,329.4,477.8,326.7,486,328.3z" />
                        <path d="M355.5,967.4c-8.7,0.3-12.2-6-13.9-12.3c-2.1-7.9,2.1-14.5,8.6-18.6c15.8-10,31.8-19.7,47.2-30.4c28.3-19.5,55.4-40.5,81.9-62.4c23.8-19.6,47-39.9,68.6-61.7c50.4-50.9,97-105.1,135.3-166c3.9-6.2,7.3-12.6,11-19c6.1-10.7,13.1-12.7,24.6-6.9c11.2,5.7,15.2,15.1,10.7,25.1c-12.2,27.2-29.5,51.1-47.8,74.3c-35.2,44.6-75.5,84.5-116.3,123.8c-52.2,50.3-107.7,96.6-171,132.9c-10.5,6-20.6,12.5-31,18.6C361,966.3,358.5,968.2,355.5,967.4z" />
                        <path d="M523.3,496.1c1.8,10.8-5.9,17.6-11.2,24.6c-26.8,35.2-53.8,70.3-83.2,103.6c-29.3,33.1-63.4,60.3-98.8,86.2c-19,13.9-38.8,26.4-59.9,36.7c-8.2,4-16.8,4.6-24.7-0.6c-13.9-9.1-9.2-28.1,5.7-34.5c19-8.2,36.9-18.3,53.9-30.1c18.8-13.1,36.5-27.6,54-42.6c44.3-38.1,78.3-84.8,112.4-131.5c6.4-8.7,12.4-17.8,20.6-25.2c6.2-5.6,13-6.8,20.3-3.4C519.4,482.6,525,487.3,523.3,496.1z" />
                        <path d="M301.5,83.5c-1.5,10.3-3.9,17.1-11.6,21.3c-25.2,13.5-49.5,28.3-72.3,45.7c-30.3,23.1-56.3,50-78.9,80.5c-16.1,21.7-29.4,45.1-43,68.3c-9.7,16.5-23.8,17.2-35.7,3.4c-7.7-8.8-4.4-18-0.1-26.5c11.9-23.5,26.2-45.7,41.7-66.9c16.6-22.7,34.7-44.5,55.5-63.6c29.6-27.1,60.9-52.1,96.4-71.3c8-4.3,14.9-10.9,25-10.7C290.1,64,301.4,74.8,301.5,83.5z" />
                        <path d="M177.6,855.7c-0.7-11.5,5.5-17.2,14.7-20.7c20.8-7.9,40.5-18.4,60.2-28.6c42-21.8,82.2-46.6,119.7-75.5c2.3-1.8,4.1-4.1,6.2-6.1c7.8-7.6,17.1-8.1,23.8-1.5c6.4,6.4,6.1,17.6-0.8,25.1c-19.3,21.2-42.4,37.8-65.9,53.8c-36.3,24.6-75.1,44.8-114.5,63.9c-6.2,3-12.7,5.4-19.6,7C188.2,876.1,177.6,868.4,177.6,855.7z" />
                        <path d="M708.1,891.3c1.8,4.7-2.1,8.3-4.7,11.4c-22.3,26.6-47.1,50.7-75,71.3c-13.5,10-27.7,4.8-32.2-10.5c-2.5-8.3,1.8-15.4,6.9-19.5c19.2-15.2,35.8-32.8,52.3-50.8c5.8-6.3,12.5-11.9,19.3-17.3c7-5.5,14.8-4.7,22.6-1.4C704.3,877.6,708.5,882.6,708.1,891.3z" />
                        <path d="M16.3,574.2c-0.5-11.8,5.5-17.2,14.3-20.8c13.8-5.6,28.1-9.7,41.7-15.9c15.8-7.2,31.8-14.2,48.4-19.5c12-3.8,21.2,0,26,10.6c3.9,8.7,1.6,16.2-6.6,21.1c-31,18.4-64.1,31.8-98.6,41.7C27.1,595.6,16.2,587.2,16.3,574.2z" />
                        <path d="M111.4,784.5c0.2-6.9,5.9-13.8,13.9-16.1c21.7-6.1,41.6-16.6,63.1-23c11-3.3,19.3,1.1,24.5,13.3c3.5,8.3,0,15.2-9.5,20.9c-21.4,12.7-43.7,22.6-68.4,26.7C122.2,808.4,111.8,800,111.4,784.5z" />
                    </g>
                </svg>
                <Typography variant={'h2'}>
                    {fingerprint.data.type === 'get' ? (
                        <Fragment>
                            Colha a biometria do{' '}
                            <strong>{fingerprint.data.nome}</strong>.
                            <br />
                            Continue a operação na janela do dispositivo.
                        </Fragment>
                    ) : (
                        <Fragment>
                            Verifique a biometria do{' '}
                            <strong>{fingerprint.data.nome}</strong>.
                            <br />
                            Continue a operação na janela do dispositivo.
                        </Fragment>
                    )}
                </Typography>
            </div>
        </BaseFlutuante>
    )
}

export default Fingerprint
