import { useCallback, useEffect } from 'react'

import keycodes from '../common/Keycodes'

const debug = require('debug')('nerus:useActiveFieldNavigation')

export default function useActiveFieldNavigation(ref, props, enabled = true) {
    const tabIndex = props.tabIndex
    const updateActiveField = props.updateActiveField
    const disabled = props.disabled

    const activeFieldNavigation = useCallback(
        event => {
            const input = event.target
            const form = input ? input.form : ''

            if (!enabled) {
                debug(`Hook activeFieldNavigation is not enabled`)
                return
            }

            if (form) {
                const elements = form.querySelectorAll(
                    'input, select, textarea, button'
                )
                const currentIndex = tabIndex || 0
                if (
                    event.keyCode === keycodes.UP_ARROW_KEY ||
                    (event.keyCode === keycodes.TAB_KEY && event.shiftKey)
                ) {
                    event.preventDefault()
                    const nextIndex = currentIndex - 1
                    if (elements.item(nextIndex)) {
                        if (updateActiveField) {
                            updateActiveField(nextIndex, true, true)
                        }
                        elements.item(nextIndex).focus()
                    }
                } else if (
                    event.keyCode === keycodes.ENTER_KEY ||
                    event.keyCode === keycodes.DOWN_ARROW_KEY ||
                    (event.keyCode === keycodes.TAB_KEY && !event.shiftKey)
                ) {
                    event.preventDefault()
                    const nextIndex = currentIndex + 1
                    let item = elements.item(nextIndex)
                    if (item) {
                        if (updateActiveField) {
                            updateActiveField(nextIndex, true, false)
                        }
                        if (item.type && item.type.toLowerCase() === 'hidden') {
                            item.parentElement.click()
                        } else {
                            item.focus()
                        }
                    } else {
                        if (updateActiveField) {
                            updateActiveField(nextIndex, true, false)
                        }
                        input.blur()
                    }
                }
            }
        },
        [enabled, updateActiveField, tabIndex]
    )

    useEffect(() => {
        if (ref?.current && !disabled && enabled) {
            ref.current.addEventListener('keydown', activeFieldNavigation)
            return () => {
                if (ref?.current) {
                    ref.current.removeEventListener(
                        'keydown',
                        activeFieldNavigation
                    )
                }
            }
        }
    }, [disabled, enabled])

    return ref
}
