import keycodes from '@nerus/framework/common/Keycodes'

import { addComponent, sendBuffer } from '../EacActions'
import Menu from './Menu'

export function handleKeyboard(ws) {
    return (dispatch, o, data) => {
        const { localFileName } = data

        if (o.keyCode === keycodes.ESCAPE_KEY) {
            ws.send(sendBuffer(o.keyCode))
            return
        }

        if (
            data.shtct &&
            o.key?.toUpperCase?.() !== 'L' &&
            !data.shtct.includes(o.key.toUpperCase())
        )
            return

        switch (o.key?.toUpperCase()) {
            case 'P': {
                window.open(
                    `${window.location.protocol}//${window.location.host}/webprint/load${localFileName}.pdf`,
                    '_blank',
                    '',
                    true
                )
                return
            }
            case 'L': {
                const el = document.getElementById('print-pdfl')
                if (el) el.click()
                return
            }
            case 'X': {
                document.getElementById('file-form').submit()
                return
            }
            case 'C': {
                dispatch(addComponent('FilePreviewer', { data }))
                return
            }
        }

        ws.send(sendBuffer(o.key))
    }
}

export default class RelatorioDialog extends Menu {
    static before({ state, action }) {
        action.payload.opt = action.payload.opt.map((o, i) => {
            let opt = o
            if (opt.toLowerCase() === 'i.saci') {
                opt = 'E - i.Nérus'
            }

            opt = opt.substr(0, 1).toUpperCase() + opt.substr(1)

            return {
                opt,
                key: action.payload.shtct[i],
            }
        })

        action.payload.disableKeyboard = true
        action.payload.keyboardActions = handleKeyboard

        return { state, action }
    }
}
