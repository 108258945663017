import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function CloseIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.02425448,0,0,-0.02425448,0.01212701,19.403584)">
                <path d="m 688,800 q 46,0 76,-31 31,-32 30.5,-75.5 Q 794,650 763,619 L 450,313 764,-9 Q 795,-41 794.5,-84.5 794,-128 763,-159 731,-190 687.5,-189.5 644,-189 613,-157 L 224,241 q -30,30 -30,75 0,43 32,75 l 388,379 q 32,30 74,30 z" />
            </g>
        </SvgIcon>
    )
}
