import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { getComponentById } from '../../Eac/EacReducer'
import Editor from '../Editor'
import Formulario from '../Formulario'

export class FormularioGrid extends Component {
    static propTypes = {
        formularioId: PropTypes.string.isRequired,
        editorId: PropTypes.string.isRequired,
    }

    static mapStateToProps(state, ownProps) {
        const FormularioGrid = getComponentById(ownProps.id, state).payload
        return {
            formularioId: FormularioGrid.Formulario.id,
            editorId: FormularioGrid.Editor.id,
        }
    }

    static baseOpts = {
        title: 'Nérus',
    }

    render() {
        return (
            <Fragment>
                <Formulario id={this.props.formularioId} />
                <Editor id={this.props.editorId} />
            </Fragment>
        )
    }
}

export default connect(FormularioGrid.mapStateToProps)(FormularioGrid)
