/* eslint-disable react/prop-types */
import { withStyles } from '@material-ui/core/styles'
import { colorSchemes } from '@nivo/colors'
import * as echarts from 'echarts'
import * as PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import { initialProperties } from '../Dashboard/Actions/GraphConfig/defaults/pie'
import GraphTooltip from '../Dashboard/Components/GraphTooltip'
import getGraphProps from './getGraphProps'

const styles = {
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
}

const formatterWithPrec = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true,
})

function GraficoPizza({
    classes,
    axes,
    data,
    custom = {},
    title,
    isPainelO2,
    ...rest
}) {
    const ref = useRef()
    const instance = useRef()

    function tooltip(props) {
        const { datum } = props

        if (!datum) return null

        const { color, value, label } = datum

        return (
            <GraphTooltip
                color={color}
                value={value}
                label={label}
                format={axes?.y?.format}
            />
        )
    }

    useEffect(() => {
        instance.current && instance.current.resize()
    }, [instance.current, rest])

    useEffect(() => {
        const onResize = () => {
            instance.current && instance.current.resize()
        }
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    })

    useEffect(() => {
        if (ref.current) {
            if (!instance.current) {
                instance.current = echarts.init(ref.current)
            }

            const graphProps = getGraphProps(
                initialProperties,
                axes,
                data,
                tooltip,
                custom
            )

            if (isPainelO2) {
                if (title === 'Marketplaces') {
                    const colors = data.map(({ color }) => color)

                    graphProps.colors = colors
                    graphProps.margin.top = 10
                    graphProps.margin.bottom = 10
                    graphProps.margin.left = 10
                    graphProps.innerRadius = 0.4
                    graphProps.padAngle = 0
                    graphProps.cornerRadius = 0
                    graphProps.sortByValue = true
                    graphProps.enableArcLabels = false
                    graphProps.legends[0].anchor = 'bottom-right'
                    graphProps.legends[0].symbolSize = 16
                    graphProps.legends[0].itemHeight = 30
                    graphProps.legends[0].itemsSpacing = 10
                    graphProps.legends[0].itemTextColor = '#000000'
                    graphProps.label = {
                        show: false,
                        position: 'center',
                    }
                }
                if (title === 'Integração de produtos') {
                    const rich = {
                        value: {
                            color: '#000000',
                            fontSize: 16,
                            fontWeight: 'bold',
                            padding: 0,
                            align: 'left',
                        },
                    }

                    graphProps.colors = ['#00A7CE', '#00415A']
                    graphProps.margin.top = 10
                    graphProps.margin.bottom = 10
                    graphProps.margin.left = 200
                    graphProps.margin.right = 10
                    graphProps.innerRadius = 0.7
                    graphProps.padAngle = 0
                    graphProps.cornerRadius = 0
                    graphProps.sortByValue = true
                    graphProps.enableArcLabels = false
                    graphProps.legends[0].anchor = 'left'
                    graphProps.legends[0].symbolSize = 16
                    graphProps.legends[0].itemsSpacing = 15
                    graphProps.legends[0].itemHeight = 30
                    graphProps.legends[0].translateX = -160
                    graphProps.legends[0].translateY = -20
                    graphProps.legends[0].textStyle = {
                        rich,
                        color: '#A1A6AE',
                    }
                    graphProps.legends[0].formatter = function(name) {
                        const val = data
                            .map(d => {
                                if (String(d.name) === name)
                                    return formatterWithPrec.format(d.value)
                                return null
                            })
                            .filter(Boolean)
                        return `{value|${val[0] || 0}} ${name}`
                    }
                }
            }

            if (!graphProps.arcLabel && graphProps.labelFormat) {
                graphProps.arcLabel = graphProps.labelFormat
                delete graphProps.labelFormat
            }

            let radius = '100%'
            if (graphProps.innerRadius !== undefined) {
                radius = graphProps.innerRadius * 100 + '%'
            }
            let legend = graphProps.legends[0]
            if (graphProps.showLegend) {
                legend.show = true
                legend.type = 'plain'
                legend.itemWidth = legend.itemWidth / 2
                legend.itemHeight = legend.itemHeight / 2
                legend.icon = 'circle'

                switch (legend.direction) {
                    case 'column':
                        legend.orient = 'vertical'
                        break
                    case 'row':
                        legend.orient = 'horizontal'
                        break
                }
                delete legend.direction

                switch (legend.anchor) {
                    case 'left':
                        legend.left = 0
                        legend.right = 'auto'
                        legend.top = 'middle'
                        legend.bottom = 'auto'
                        break
                    case 'right':
                        legend.left = 'auto'
                        legend.right = 0
                        legend.top = 'middle'
                        legend.bottom = 'auto'
                        break
                    case 'top':
                        legend.left = 'middle'
                        legend.right = 'auto'
                        legend.top = 0
                        legend.bottom = 'auto'
                        break
                    case 'center':
                        legend.left = 'middle'
                        legend.right = 'auto'
                        legend.top = 'middle'
                        legend.bottom = 'auto'
                        break
                    case 'bottom':
                        legend.left = 'middle'
                        legend.right = 'auto'
                        legend.top = 'auto'
                        legend.bottom = 0
                        break
                    case 'top-left':
                        legend.left = 0
                        legend.right = 'auto'
                        legend.top = 0
                        legend.bottom = 'auto'
                        break
                    case 'top-right':
                        legend.left = 'auto'
                        legend.right = 0
                        legend.top = 0
                        legend.bottom = 'auto'
                        break
                    case 'bottom-left':
                        legend.left = 0
                        legend.right = 'auto'
                        legend.top = 'auto'
                        legend.bottom = 0
                        break
                    case 'bottom-right':
                        legend.left = 'auto'
                        legend.right = 0
                        legend.top = 'auto'
                        legend.bottom = 0
                        break
                }
            } else {
                legend = {
                    show: false,
                }
            }

            if (!graphProps.itemStyle) graphProps.itemStyle = {}

            if (graphProps.borderWidth !== undefined) {
                graphProps.itemStyle.borderWidth = graphProps.borderWidth
                graphProps.itemStyle.borderColor =
                    graphProps.borderColor || '#ffffff'
                delete graphProps.borderColor
                delete graphProps.borderWidth
            }

            if (graphProps.cornerRadius !== undefined) {
                graphProps.itemStyle.borderRadius = graphProps.cornerRadius
                delete graphProps.cornerRadius
            }

            const labelFormatter =
                graphProps.arcLabel === 'value'
                    ? '{c}'
                    : graphProps.arcLabel === 'name'
                    ? '{b}'
                    : '{d}'

            graphProps.label = {
                show:
                    graphProps.enableArcLinkLabels ||
                    graphProps.enableArcLabels,
                position: graphProps.enableArcLinkLabels ? 'outer' : 'inner',
                edgeDistance: '25%',
                formatter:
                    typeof graphProps.arcLabel !== 'function'
                        ? labelFormatter
                        : axes?.x?.format === 'money' ||
                          axes?.y?.format === 'money'
                        ? function(d) {
                              return `R$ ${graphProps.arcLabel(d?.value)}`
                          }
                        : '{d}',
            }

            // if it is Integração de Produtos, append to series
            if (title === 'Integração de produtos') {
                graphProps.label = {
                    color: '#000',
                    fontSize: '1.15em',
                    fontWeight: 'bold',
                    position: 'center',
                    formatter: () => {
                        if (graphProps.data?.length > 1) {
                            // if (graphProps.data?.length > 0) {
                            return (
                                Math.ceil(
                                    ((graphProps.data[0].value || 0) /
                                        (graphProps.data[1].value || 1)) *
                                        100
                                ) + '%'
                            )
                        }

                        return '0%'
                    },
                }
            }

            const series = [
                {
                    ...graphProps,
                    ...(graphProps.margin || {}),
                    radius: [radius, '90%'],
                    type: 'pie',
                },
            ]

            instance.current.setOption({
                color: graphProps.colors.scheme
                    ? colorSchemes[graphProps.colors.scheme || 'NerusWeb']
                    : graphProps.colors,
                tooltip: {
                    trigger: 'item',
                    valueFormatter: value => {
                        return typeof graphProps.arcLabel === 'function' &&
                            (axes?.x?.format === 'money' ||
                                axes?.y?.format === 'money')
                            ? 'R$ ' + graphProps.arcLabel(value)
                            : value
                    },
                },
                legend,
                series,
            })
        }

        return () => {}
    }, [ref, data, custom, axes, title])

    return <div ref={ref} className={classes.root} />
}

GraficoPizza.propTypes = {
    classes: PropTypes.any,
    data: PropTypes.array,
    axes: PropTypes.any,
}

export default withStyles(styles)(GraficoPizza)
