import { withStyles } from '@material-ui/core/styles'
import { colorSchemes } from '@nivo/colors'
import * as echarts from 'echarts'
import * as PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

const styles = {
    root: {
        width: '100%',
        height: '100%',
        minWidth: '100%',
        minHeight: '100%',
        position: 'relative',
    },
}

function Gauge({ axes, classes, data, ...rest }) {
    const ref = useRef()
    const instance = useRef()

    useEffect(() => {
        const onResize = () => {
            instance.current && instance.current.resize()
        }
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [instance.current])

    useEffect(() => {
        instance.current && instance.current.resize()
    }, [instance.current, rest])

    useEffect(() => {
        if (ref.current) {
            if (!instance.current) {
                instance.current = echarts.init(ref.current)
            }
            // Por padrão object.keys e object.values retornam o segundo valor, alternando a ordem das propriedades na exibição do dashboard
            //Link sugerido: https://stackoverflow.com/questions/3186770/chrome-re-ordering-object-keys-if-numerics-is-that-normal-expected
            const dados = data[0]?.dados ? data[0].dados[0] : {}
            const keys = Object.keys(dados)
            const values = Object.values(dados)
            const medium =
                values[0] > 0 && values[1] > 0 ? values[0] / values[1] : 0
            const max = values[1]

            instance.current.setOption({
                color: colorSchemes.NerusWeb,
                series: [
                    {
                        name: 'Devoluções x Vendas no dia',
                        type: 'gauge',
                        radius: '100%',
                        min: 0,
                        max,
                        center: ['50%', '60%'],
                        startAngle: 180,
                        endAngle: 0,
                        progress: {
                            show: true,
                            width: 18,
                            roundCap: true,
                            overlap: true,
                        },
                        pointer: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                width: 18,
                                shadowColor: 'rgba(0, 0, 0, 1)',
                                shadowBlur: 2,
                            },
                            roundCap: true,
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        anchor: {
                            show: false,
                        },
                        data: [
                            {
                                value: isNaN(medium)
                                    ? 0
                                    : (medium * 100).toFixed(2),
                                name: 'Valor do Meio',
                                itemStyle: {
                                    color: '#000',
                                    opacity: 0,
                                },
                                detail: {
                                    valueAnimation: true,
                                    width: '80%',
                                    height: 32,
                                    offsetCenter: [0, '-25%'],
                                    fontSize: `1.5em`,
                                    borderRadius: 50,
                                    padding: 8,
                                    fontWeight: 'bolder',
                                    formatter: '{value}%',
                                    color: '#ffffff',
                                    backgroundColor: '#00A7CE',
                                },
                                title: {
                                    show: false,
                                },
                            },
                            {
                                value: values[0],
                                name: keys[0],
                                itemStyle: {
                                    color: '#D8004E',
                                },
                                detail: {
                                    offsetCenter: ['-90%', '40%'],
                                    color: '#D8004E',
                                    fontSize: `2em`,
                                    marginBottom: '2px',
                                    formatter: values => {
                                        if (axes.x.format == 'money') {
                                            return values
                                                .toLocaleString('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                })
                                                .substr(3)
                                        } else if (axes.x.format == null) {
                                            return values
                                        }
                                    },
                                },
                                title: {
                                    offsetCenter: ['-90%', '60%'],
                                    color: '#A1A6AE',
                                    fontSize: 12,
                                },
                            },
                            {
                                value: values[1],
                                name: keys[1],
                                itemStyle: {
                                    color: 'rgba(0,0,0,0)',
                                },
                                detail: {
                                    offsetCenter: ['90%', '40%'],
                                    color: '#333333',
                                    marginBottom: '2px',
                                    fontSize: `2em`,
                                    formatter: values => {
                                        if (axes.y.format == 'money') {
                                            return values
                                                .toLocaleString('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                })
                                                .substr(3)
                                        } else if (axes.y.format == null) {
                                            return values
                                        }
                                    },
                                },
                                title: {
                                    offsetCenter: ['90%', '60%'],
                                    color: '#A1A6AE',
                                    fontSize: 12,
                                },
                            },
                        ],
                    },
                ],
            })

            instance.current.resize()
        }

        return () => {}
    }, [ref, data])

    return <div ref={ref} className={classes.root} />
}

Gauge.propTypes = {
    classes: PropTypes.any,
    data: PropTypes.array,
    axes: PropTypes.any,
}

export default withStyles(styles)(Gauge)
