export function format(value, format) {
    let formatted = ''
    let where = value.length - 1
    let need = format.length - 1
    for (let i = format.length - 1; i > -1; i--) {
        let chr = format[i]

        need--

        switch (chr) {
            case '#': {
                chr = value[where]
                where--
                break
            }
            case '?': {
                let tmp = format.slice(0, need).split('#').length - 1
                if (where >= tmp) {
                    chr = value[where]
                    where--
                } else {
                    chr = ''
                }
                break
            }
        }
        formatted = `${chr}${formatted}`
    }

    return formatted
}

export function preprocessMessageOutput(msg, noShortcut = false) {
    let processed = msg
    if (processed) {
        processed = processed.replace(
            'Pressione qualquer tecla p/ continuar...',
            ''
        )
        processed = processed.replace(/\n/g, '<br />')
        processed = processed.replace(
            /Pressione qualquer tecla para continuar\.\.\./g,
            ''
        )
        processed = processed.replace(/`a/g, 'à')
        processed = processed.replace(/a'/g, 'á')
        processed = processed.replace(/e'/g, 'é')
    }

    if (noShortcut) {
        const firstDot = processed.indexOf('.')
        processed = processed.substring(firstDot + 1).trim()
    }

    return processed
}

export function normalizeStringFilename(str) {
    return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, '_')
        .replace(/ç/g, 'c')
        .replace(/[^\w\sç.]/g, '_')
}
