import TextFieldPerc from './Percentage'

/**
 * Componente TextFieldMoney
 *
 * Rec: REC_MONEY
 *
 * Renderiza um campo de texto que tem formatação de casas
 * decimais
 */
export default class TextFieldMoney extends TextFieldPerc {
    beforeSend = value => {
        if (!value) {
            return '0'
        }
        return value
            .toString()
            .replace(/\./g, '')
            .replace(/,/g, '.')
    }
}
