import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Row from './Row'

export default class Body extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        classes: PropTypes.object.isRequired,
        enabled: PropTypes.bool.isRequired,
        legend: PropTypes.array,
        dispatch: PropTypes.func,
        onFocus: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        onDoubleClick: PropTypes.func,
        onWheel: PropTypes.func.isRequired,
        types: PropTypes.array.isRequired,
        activeRow: PropTypes.number,
        activeColumnSearch: PropTypes.number,
        activeValue: PropTypes.object,
        columns: PropTypes.number,
        active: PropTypes.bool,
        mouseLock: PropTypes.bool,
        isPdv: PropTypes.bool,
        draggable: PropTypes.bool,
        onDrag: PropTypes.func,
    }

    onFocus = (object, i) => () => this.props.onFocus.call(object, i)
    onClick = (object, i) => () => {
        const { onClick } = this.props
        onClick.call(object, i)
    }
    onDoubleClick = (object, i) => () => {
        const { onDoubleClick } = this.props
        onDoubleClick && onDoubleClick.call(object, i)
    }

    dragged = null

    onDragStart = (e, index) => {
        this.dragged = index
        e.dataTransfer.effectAllowed = 'move'
        e.dataTransfer.setData('text/html', e.target.parentNode)
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20)
    }

    onDragEnd = () => {
        this.dragged = null
    }

    onDragOver = overIndex => {
        const { onDrag } = this.props
        return (
            onDrag &&
            onDrag(this.dragged === overIndex, this.dragged, overIndex)
        )
    }

    render() {
        const {
            types,
            data,
            legend,
            classes,
            onWheel,
            activeRow,
            activeColumnSearch,
            activeValue,
            active,
            columns,
            isPdv,
            draggable,
            onDrag,
            enabled,
            dispatch,
            trStyle = {},
        } = this.props

        if (!data || !data.length) {
            return null
        }

        return (
            <tbody onWheel={onWheel}>
                {data.map((object, i) => {
                    return (
                        <Row
                            key={'Row-' + i}
                            ref={'row-' + i}
                            legend={legend || []}
                            index={i}
                            data={object}
                            active={active}
                            enabled={enabled}
                            dispatch={dispatch}
                            isPdv={isPdv}
                            classes={classes}
                            columns={columns}
                            types={types}
                            draggable={draggable}
                            onDrag={onDrag}
                            onDragStart={this.onDragStart}
                            onDragOver={this.onDragOver}
                            onDragEnd={this.onDragEnd}
                            activeColumnSearch={activeColumnSearch}
                            activeValue={activeValue}
                            onFocus={this.onFocus(object, i)}
                            onClick={this.onClick(object, i)}
                            onDoubleClick={this.onDoubleClick(object, i)}
                            className={
                                activeRow === i ? classes.tableRowActive : ''
                            }
                            style={trStyle}
                        />
                    )
                })}
            </tbody>
        )
    }
}
