import PropTypes from 'prop-types'

import { TextFieldNegativableShort } from './NegativableShort'

/**
 * Componente TextFieldDouble
 *
 * Rec: REC_DQTTY
 * Rec: REC_QTTY
 * Rec: REC_QTTD
 *
 * Renderiza um campo de texto que tem formatação de casas
 * decimais
 */
export class TextFieldQty extends TextFieldNegativableShort {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    }

    fixedDecimalScale = false
    allowNegative = true
    useFormat = true

    beforeSend = value => {
        if (!value) {
            return '0'
        }

        // Sem .toString() para conseguirmos ver se em algum momento
        // um valor float/int chega aqui (o que está incorreto)
        return value.replace(/\./g, '').replace(/,/g, '.')
    }
}

export default TextFieldQty
