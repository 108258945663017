import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function DollarIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0,19.2)">
                <path d="M 750,130 Q 750,79 730.5,38.5 711,-2 679,-26.5 647,-51 612.5,-64.5 578,-78 543,-82 V -200 H 456 V -78 Q 291,-53 257,102 l 84,18 Q 361,28 456,9 v 260 l -62,18 q -50,13 -97,60 -47,47 -47,124 0,51 19.5,91.5 19.5,40.5 51,65 31.5,24.5 66,38 Q 421,679 456,683 v 117 h 86 V 679 Q 682,657 727,545 l -79,-33 q -27,64 -106,80 V 333 l 33,-10 Q 750,273 750,130 Z M 418,369 456,358 V 596 Q 411,589 373.5,559.5 336,530 336,471 336,396 418,369 Z M 542,5 q 46,7 83.5,36.5 Q 663,71 663,130 q 0,35 -22.5,63 -22.5,28 -90.5,48 l -8,2 z" />
            </g>
        </SvgIcon>
    )
}
