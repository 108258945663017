import withStyles from '@material-ui/core/styles/withStyles'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import { styles } from '@nerus/styles/components/editor/duplo'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { getComponentById, isActiveComponent } from '../../Eac/EacReducer'
import Editor from '../Editor'

export class EditorDuplo extends EnhancedComponent {
    static mapStateToProps = (state, ownProps) => {
        const EditorDuplo = getComponentById(ownProps.id, state).payload
        const active = isActiveComponent(ownProps.id, state)

        return {
            active,
            flt: EditorDuplo.flt,
            editor1: EditorDuplo.editor1,
            editor2: EditorDuplo.editor2,
        }
    }

    static propTypes = {
        active: PropTypes.number.isRequired,
        classes: PropTypes.object.isRequired,
        editor1: PropTypes.object.isRequired,
        editor2: PropTypes.object.isRequired,
        flt: PropTypes.bool.isRequired,
        isGuia: PropTypes.bool,
        isFixo: PropTypes.bool,
    }

    static baseOpts = {
        title: true,
        nospace: true,
        size: ({ editor1, editor2 }) => {
            return editor1?.data?.cols?.length > 5 ||
                editor2?.data?.cols?.length > 5
                ? 'big'
                : 'medium'
        },
    }

    render() {
        const { active, classes, editor1, editor2, isFixo, isGuia } = this.props

        return (
            <div className={classes.root}>
                <Editor
                    enabled={active === 1}
                    active={active === 1}
                    isGuia={isGuia}
                    isFixo={isFixo}
                    isEditorDuplo
                    id={editor1.id}
                    customClasses={{}}
                />
                <Editor
                    enabled={active === 2}
                    active={active === 2}
                    isGuia={isGuia}
                    isFixo={isFixo}
                    isEditorDuplo
                    id={editor2.id}
                    customClasses={{
                        root:
                            editor1?.data?.rows?.length >
                            editor2?.data?.rows?.length
                                ? classes.secondaryRoot
                                : '',
                    }}
                />
            </div>
        )
    }
}

export default connect(EditorDuplo.mapStateToProps)(
    withStyles(styles)(EditorDuplo)
)
