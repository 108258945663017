import { CircularProgress, Tooltip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import SyncRounded from '@material-ui/icons/SyncRounded'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { toggleSidebar } from '../../../App/AppActions'
import withQueryReport from '../old/Reports/graphql/withQueryReport'
import withDashboardRender from './graphql/withDashboardRender'

class RefreshButton extends Component {
    static propTypes = {
        every: PropTypes.number.isRequired,
        onRefresh: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        showSidebar: PropTypes.bool,
    }

    static mapStateToProps = state => {
        return {
            showSidebar: state.app.showSidebar,
        }
    }

    state = {
        toRefresh: 1,
    }

    refreshTimer = null
    componentDidUpdate() {
        this.componentDidMount()
    }

    componentDidMount() {
        const { every, loading, showSidebar, dispatch, onRefresh } = this.props

        if (every > 0 && !this.refreshTimer && !loading) {
            this.refreshTimer = setInterval(() => {
                if (showSidebar) {
                    if (!this.hideSidebar) {
                        dispatch(toggleSidebar())
                        this.hideSidebar = true
                    }
                }
                if (this.state.toRefresh === 0) {
                    onRefresh()
                    this.setState({
                        toRefresh: every,
                    })
                } else {
                    this.setState(state => ({
                        toRefresh: state.toRefresh - 1,
                    }))
                }
            }, 1000)
        } else if (this.refreshTimer && loading) {
            clearInterval(this.refreshTimer)
            this.refreshTimer = null
        }
    }

    componentWillUnmount() {
        if (this.refreshTimer) clearInterval(this.refreshTimer)
        this.refreshTimer = null
    }

    render() {
        const { every, loading } = this.props
        const { toRefresh } = this.state
        let tooltip = ''
        let refreshClick = undefined
        const styles = { color: '#999999' }

        if (loading || toRefresh === 0) {
            styles.animation = 'rotate 2s ease-in-out infinite'
            tooltip = 'Atualizando...'
        } else {
            tooltip = `Irá atualizar em ${toRefresh} segundos...`
            if (every - toRefresh > 5) {
                refreshClick = () => {
                    this.setState({
                        toRefresh: 0,
                    })
                }
                styles.color = '#5e9a2f'
                styles.cursor = 'pointer'
            }
        }

        return (
            <Box position="relative" display="inline-flex" color="#5e9a2f">
                <CircularProgress
                    variant="determinate"
                    color="inherit"
                    value={loading ? 100 : 100 - (toRefresh * 100) / every}
                />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Tooltip title={tooltip}>
                        <SyncRounded onClick={refreshClick} style={styles} />
                    </Tooltip>
                </Box>
            </Box>
        )
    }
}

export default connect(RefreshButton.mapStateToProps)(
    withDashboardRender(withQueryReport(RefreshButton))
)
