import withStyles from '@material-ui/core/styles/withStyles'
import { Input } from '@nerus/framework/styled/Input'
import clsx from 'clsx'
import React from 'react'
import { connect } from 'react-redux'

import AbstractTextField from '../AbstractTextField'

const styles = () => ({
    inputSelect: {
        minHeight: 'unset',
        lineHeight: 'inherit',
        marginBottom: 10,
        width: '100%',
        position: 'sticky',
        top: '0',
        padding: '8px 16px',
        outline: 0,
        zIndex: 5,
    },
    removeSpaces: {
        width: 'auto',
        marginLeft: -16,
        marginRight: -21,
    },
})

/**
 * Componente SelectInput
 */
export class SelectInput extends AbstractTextField {
    render() {
        const {
            classes,
            label,
            value,
            inputRef,
            onChange,
            onKeyDown,
            enabled = true,
            removeSpaces = false,
        } = this.props

        return (
            <Input
                ref={inputRef}
                label={label || 'Digite a Opção:'}
                fieldType="text"
                className={clsx(classes.inputSelect, {
                    [classes.removeSpaces]: removeSpaces,
                })}
                disabled={!enabled}
                dispatchToWs={false}
                autoFocus={true}
                overwriteOnFirst={true}
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={value}
            />
        )
    }
}

export default connect()(withStyles(styles)(SelectInput))
