/**
 * Importa as ações usadas
 */

import { createHash, randomBytes } from 'crypto'
import cloneDeep from 'lodash/cloneDeep'
import defaultsDeep from 'lodash/defaultsDeep'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import keysIn from 'lodash/keysIn'
import moment from 'moment'
import queryString from 'query-string'

import localStorage from '../../../util/localStorage'
import {
    ADD_COMPONENT,
    ALREADY_CONNECTED,
    CLEAR_DLL,
    CLEAR_FINGERPRINT,
    DLL,
    FINGERPRINT,
    LOCK_MOUSE_CLICK,
    NEXT_ELEMENT_INDEX,
    O2_CLOSE,
    O2_OPEN,
    PREVIOUS_ELEMENT_INDEX,
    REMOVE_COMPONENT,
    RESET,
    RESET_ELEMENT_INDEX,
    SET_FORMULARIO,
    TOGGLE_LEGEND,
    TOO_MUCH_RETRIES,
    UPDATE_COMPONENT,
    UPDATE_WS_DATA,
    WS_CONNECT,
    WS_DIALOGOPROGRESSAO,
    WS_DISCONNECT,
    WS_LOGIN,
    WS_LOGIN_END,
    WS_MESSAGEWINDOW,
    WS_NERUSINFO,
} from './EacActions'
import subreducers from './reducers'

// Função apenas atualiza o componente, não remove nada
export const updatePart = (update, value) => {
    if (update == undefined) return value
    else if (value == undefined && update !== undefined) return update

    if (isArray(value) && isArray(update)) {
        // se ambos os vetores possuem id's:
        // significa que estamos fazendo um update for identificação
        if (value?.[0]?.id && update?.[0]?.id) {
            return value.map(pos => {
                const hasUpdate = update.filter(f => f.id === pos.id)

                return hasUpdate.length ? Object.assign(pos, hasUpdate[0]) : pos
            })
        }

        if (update?.length > 0) {
            // caso contrário, significa que estamos recebendo novos dados, idealmente, vamos sobrepor o vetor atual
            return update.map((el, index) => {
                return updatePart(el, value[index])
            })
        }
    }

    if (typeof update === 'string') {
        return update
    }

    return keysIn(update).reduce((updatedComponent, key) => {
        if (!isArray(update[key]) && !isObject(update[key])) {
            updatedComponent[key] =
                update[key] === undefined ? value[key] : update[key]
        } else {
            updatedComponent[key] = updatePart(update[key], value[key])
        }
        return updatedComponent
    }, value)
}

export const checkNullOrUndefined = val => val === undefined || val === null

/**
 * Gera um hash a partir de uma action
 *
 * @param action object objeto da action enviada pro redux
 * @return string hash único do objeto
 */
export function makeHash(action) {
    let string = `${action.name}-${action.payload.id}`

    // Adiciona o filename de um RelatorioDialog
    if (action.payload.fn) {
        string += `-${action.payload.fn.trim()}`
    }

    // Adiciona o type de um RelatorioDialog
    if (action.payload.type && typeof action.payload.type === 'string') {
        string += `-${action.payload.type.trim()}`
    }

    // Adiciona o titulo de um Editor
    if (action.payload.title) {
        string += `-${action.payload.title.trim()}`
    }

    // Adiciona a mensagem, se houver.
    if (action.payload.msg) {
        string += `-${action.payload.msg.trim()}`
    }

    // Adiciona os atalhos de um Dialogo
    if (action.payload.optType) {
        string += `-${action.payload.optType}`
    }

    // Adiciona os atalhos de um Menu
    if (action.payload.shtct) {
        action.payload.shtct.forEach(c => {
            string = `${string}-${c.trim()}`
        })
    }

    // Adiciona os options de um MenuFlutuante
    if (action.payload.options) {
        action.payload.options.forEach(c => {
            try {
                string = `${string}-${c.trim()}`
            } catch (e) {
                // do nothing
            }
        })
    }

    // Adiciona os labels de um Formulario
    if (action.payload.list) {
        action.payload.list.forEach(c => {
            try {
                if (c.label) {
                    string = `${string}-${c.label}`
                }
            } catch (e) {
                // do nothing
            }
        })

        // significa que só temos LabelWeb
        if (!string) {
            action.payload.list.forEach(c => {
                try {
                    if (c.texto) {
                        string = `${string}-${c.texto}`
                    }
                } catch (e) {
                    // do nothing
                }
            })
        }
    }

    // Usa a string da primeira linha de uma DialogoProgressao
    if (action.payload.msg1) {
        string = `${string}-${action.payload.msg1}`
    }

    // Usa a string da segunda linha de uma DialogoProgressao
    if (action.payload.msg2) {
        string = `${string}-${action.payload.msg2}`
    }

    // Gera um hash unico com a string formada para identificar um componente
    return createHash('sha1').update(string).digest('base64')
}

/**
 * Precarrega a sessão que está no storage ou inicializa uma nova sessão
 *
 * @returns {*|Error|LoDashExplicitWrapper<any>|ExpChain<any>|null}
 */
export const preloadSession = () => {
    let storedSessions = localStorage
        .getKeys()
        .filter(key => key.indexOf('nerus_') > -1)

    if (storedSessions && typeof window !== 'undefined') {
        const parts = window.location.pathname.substring(1).split('/') || []
        const module = parts.shift()
        let session_id, session
        let bin = module === 'bin' ? `nerus_${parts.shift()}` : 'nerusweb'
        let config = module === 'config'

        if (config) bin = `nerus_${parts.shift()}`

        // // efetuamos limpeza das sessões vencidas
        storedSessions.forEach(sessionId => {
            const sess = getSession(sessionId)
            const expires = sess?.nerusInfo?.sessionTime || 28800
            const lastUse = moment(sess?.lastUse, moment.ISO_8601)
            const diff = moment().diff(lastUse, 'seconds')

            if (diff > expires || !sess || !sess.loggedIn) {
                removeSession(sessionId)
            }
        })

        // usa uma sessão passada via URL
        const params = queryString.parse(window.location.search)
        if (params?.session_id) {
            session_id = params.session_id
            session = getSession(params.session_id)
        }

        // se não temos sessão, busca por uma sessão não utilizada para abrir
        if (!session) {
            const unusedSessions = storedSessions.filter(sessId => {
                const session = getSession(sessId)
                return !session?.in_use && session?.bin === bin
            })

            if (unusedSessions.length) {
                session = localStorage.get(unusedSessions.shift(), null, true)
                session_id = session ? session.session_id : null
            }
        }

        // estamos em um binário especifico
        if (
            bin !== 'nerusweb' &&
            bin !== 'nerus_pv' &&
            bin !== 'nerus_o2' &&
            !session &&
            !config
        ) {
            const hasSession = storedSessions.filter(sessId => {
                const session = getSession(sessId)
                return session?.bin === bin
            })

            if (hasSession && hasSession.length) {
                return null
            }
        }

        // por fim, criamos uma sessão nova caso não exista nenhuma para usar
        if (!session) {
            session_id = randomBytes(24).toString('hex')
            session = {
                session_id,
                type: 'normal',
                in_use: true,
                bin,
                config,
                createdAt: new Date().toISOString(),
                lastUse: new Date().toISOString(),
                nerusInfo: {},
            }
        }

        if (params.p && session) {
            session.perfil = params.p
        }

        localStorage.session_id = session_id
        localStorage.set(session_id, session)

        return session
    }

    return null
}

/**
 * Atualiza a sessão com session_id através de uma função de callback
 * @param session_id
 * @param callback
 * @returns Array[Session]
 */
export const updateSession = (session_id, callback) => {
    // Altera sessão para em uso, agora que logamos
    if (!callback) {
        throw Error(
            'Você deve passar uma função para alterar valores da sessão'
        )
    }

    let storedSession = localStorage.get(session_id, null)
    if (storedSession) {
        storedSession = {
            ...storedSession,
            ...callback(storedSession),
            lastUse: new Date().toISOString(),
        }
        localStorage.set(session_id, storedSession)
    }
    return storedSession
}

/**
 * Remove uma sessão do nosso storage e remove todos os componentes relacionados
 * do cache
 * @param session_id
 * @returns Array[Session]
 */
export const removeSession = session_id => {
    localStorage.getKeys().forEach(key => {
        if (key.indexOf(session_id) > -1) {
            localStorage.remove(key, true)
        }
    })
    return localStorage.getKeys()
}

/**
 * Recupera uma sessão através do ID dela
 * @param session_id
 * @returns {*}
 */
export const getSession = session_id => {
    return localStorage.get(session_id, null, true) || null
}

/**
 * Retorna lista de sessões que devem reconectar
 * @returns Array[Session]
 */
export const getSessionsToReconnect = bin => {
    const storedSessions = localStorage.getKeys().map(sessId => {
        return getSession(sessId)
    })

    return storedSessions
        .filter(
            session =>
                session &&
                session.type === 'reconnect' &&
                session.in_use === false &&
                session.bin === bin
        )
        .map(session => {
            updateSession(session.id, sess => {
                sess.in_use = true
                return sess
            })
            return session
        })
}

// Initial State
const initialState = {
    // Componentes tratados individualmente
    DialogoProgressao: null,
    MessageWindow: null,
    MessageWindowParentId: null,

    // Dados do Nerus e Usuário
    NerusInfo: null,
    session: preloadSession(),

    // Estado da conexão do WS
    connected: false,
    isLogin: false,
    isLogged: false,
    isLogout: false,
    isTooMuchRetries: false,
    isAlreadyConnected: false,
    mouseLock: false,

    // Buffers
    currentBuffer: [],
    formBuffer: [],
    oldBuffer: null,
    rowEditorBuffer: null,
    loginBuffer: null,

    // Elemento ativo
    activeElementIndex: 0,

    // Fila de comandos para DLL
    dll: [],
    fingerprint: null,

    // Fila de componentes e menus
    menusLifo: [],
    componentsLifo: [],
    components: {},
    lastActiveComponent: null,
    lastFixedComponentId: null,
    componentsFixedLifo: [],

    // o2
    painelO2: false,

    // Legenda
    showLegend: false,
}

const editorTypes = ['Editor', 'EditorFormEdit', 'EditorDuplo', 'EditorForm']
export const formComponents = [
    'Formulario',
    'EditorFormEdit',
    'EditorFormEditPdv',
]

export const getFieldList = activeComponent => {
    let fieldList = []
    switch (activeComponent?.name) {
        case 'Formulario': {
            fieldList = activeComponent?.payload?.list || []
            break
        }
        case 'EditorFormEditPdv':
        case 'EditorFormEdit': {
            fieldList = activeComponent?.payload?.form?.data?.list || []
            break
        }
    }
    return fieldList
}

const EacReducer = (state = initialState, currentAction, store) => {
    let action = currentAction

    switch (action.type) {
        case WS_CONNECT: {
            return {
                // Mantem o estado atual
                ...state,
                // Adiciona os componentes pré-carregados
                ...action.payload,
                // Altera para o estado "Conectado"
                connected: true,
                mouseLock: false,
                isLogout: false,
            }
        }

        case WS_DISCONNECT: {
            return {
                // Mantem o estado atual
                ...state,
                // Altera para o estado "Desconectado"
                connected: false,
                isLogin: false,
                fingerprint: null,
            }
        }

        case TOO_MUCH_RETRIES: {
            return {
                ...state,
                connected: false,
                isLogin: false,
                isLogout: false,
                isTooMuchRetries: true,
            }
        }

        case ALREADY_CONNECTED: {
            return {
                ...state,
                connected: false,
                isLogin: false,
                isLogout: false,
                isAlreadyConnected: true,
            }
        }

        case RESET: {
            removeSession(state.session?.session_id)

            return {
                // Recupera o estado inicial da aplicação
                ...initialState,
                isLogin: true,
                isLogout: action.payload.isLogout,
                isLogged: false,
                fingerprint: null,
                connected: state.connected,
                session: preloadSession(),
            }
        }

        case TOGGLE_LEGEND: {
            const activeComponent = getActiveComponent(state)
            const newState = {
                ...state,
                showLegend: !state.showLegend,
            }

            let cid = activeComponent.payload.id
            if (editorTypes.indexOf(activeComponent.name) > -1) {
                if (activeComponent.name !== 'Editor') {
                    cid = activeComponent.payload.editor
                        ? activeComponent.payload.editor.id
                        : activeComponent.payload[
                              `editor${activeComponent.payload.active}`
                          ].id
                }
                newState.components[cid] = cloneDeep(newState.components[cid])
                newState.components[cid].payload.showLegend =
                    newState.showLegend
            }

            if (
                activeComponent.name === 'Menu' &&
                activeComponent.payload?.typeMenu === 'guias'
            ) {
                cid = newState.components[cid].payload.content.data.editor.id
                newState.components[cid].payload.showLegend =
                    newState.showLegend
            }

            return newState
        }

        case RESET_ELEMENT_INDEX: {
            return {
                // Mantem o estado atual
                ...state,
                // Altera o indice do elemento ativo para 0
                activeElementIndex: action.payload.index || 0,
            }
        }

        case NEXT_ELEMENT_INDEX: {
            return {
                ...state,
                /**
                 * Incrementa o indice de componentes
                 * Nesse momento não validamos se existe ou não
                 * elemento pois não temos conhecimento sobre
                 * o formato do componente
                 */
                activeElementIndex: state.activeElementIndex + 1,
            }
        }

        case PREVIOUS_ELEMENT_INDEX: {
            return {
                ...state,
                /**
                 * Decrementa o indice de componentes
                 * Nesse momento não validamos se existe ou não
                 * elemento pois não temos conhecimento sobre
                 * o formato do componente
                 */
                activeElementIndex: state.activeElementIndex - 1,
            }
        }

        case WS_NERUSINFO: {
            if (action.payload.isPdv) {
                state.menusLifo.forEach(id => delete state.components[id])
                state.components = cloneDeep(state.components)
                state.menusLifo = []
            }

            return {
                ...state,
                NerusInfo: { ...action.payload },
            }
        }

        case DLL: {
            return {
                ...state,
                dll: [...state.dll, action.payload],
            }
        }

        case CLEAR_DLL: {
            return {
                ...state,
                dll: [],
            }
        }

        case FINGERPRINT: {
            return {
                ...state,
                fingerprint: action.payload,
            }
        }

        case CLEAR_FINGERPRINT: {
            return {
                ...state,
                fingerprint: null,
            }
        }

        case WS_LOGIN: {
            const componentsId = Object.keys(state.components)

            let persistentComponents = {}
            let persistentComponentsLifo = []
            let persistentComponentsFixedLifo = []

            componentsId.forEach(component => {
                if (state.components[component].payload.dontClean) {
                    persistentComponents = cloneDeep(persistentComponents)
                    persistentComponents[component] = cloneDeep(
                        state.components[component]
                    )

                    persistentComponentsLifo = cloneDeep(
                        persistentComponentsLifo
                    )

                    persistentComponentsLifo.push(component)

                    if (state.components[component].payload.flt === false) {
                        persistentComponentsFixedLifo = cloneDeep(
                            persistentComponentsFixedLifo
                        )
                        persistentComponentsFixedLifo.push(component)
                    }
                }
            })

            return {
                ...state,
                isLogin: true,
                isLogged: false,
                DialogoProgressao: null,
                MessageWindow: null,
                MessageWindowParentId: null,
                menusLifo: [],
                componentsLifo: persistentComponentsLifo,
                componentsFixedLifo: persistentComponentsFixedLifo,
                components: persistentComponents,
            }
        }

        case WS_LOGIN_END: {
            // Altera sessão para em uso, agora que logamos
            if (state.session) {
                const { session_id } = state.session
                updateSession(session_id, session => ({
                    type: 'reconnect',
                    in_use: true,
                    lastUse: new Date().toISOString(),
                    startedAt: !session.startedAt
                        ? new Date().toISOString()
                        : session.startedAt,
                }))

                localStorage.session_id = session_id
            }

            return {
                ...state,
                isLogged: true,
                isLogout: false,
                isLogin: false,
            }
        }

        case REMOVE_COMPONENT: {
            const type = action.payload.type || 'Dialogo'

            const rid = action.payload.id || false
            const obj = cloneDeep(state)
            const components = cloneDeep(obj.components)
            const newLifo = []
            const lifo = cloneDeep(obj.componentsLifo).reverse()

            if (type === 'DialogoProgressao') {
                obj.DialogoProgressao = null
            } else if (type === 'MessageWindow') {
                obj.MessageWindow = null
                obj.MessageWindowParentId = null
            } else {
                lifo.forEach(componentId => {
                    const component = components[componentId]
                    if (component) {
                        if (component.name === type) {
                            if (rid) {
                                if (componentId === rid) {
                                    // 90173 - permite cachear componentes removidos
                                    // delete components[componentId]
                                    return true
                                }
                            } else {
                                // 90173 - permite cachear componentes removidos
                                // delete components[componentId]
                                return true
                            }
                        }
                        newLifo.push(componentId)
                    }
                })

                obj.components = components
                obj.componentsLifo = newLifo.reverse()
            }

            return obj
        }

        case UPDATE_WS_DATA: {
            const obj = cloneDeep(state)
            const component = obj.components[action.id]
            component.payload.ws = cloneDeep(component.payload.ws)
            component.payload.ws.data = action.payload
            obj.components[action.id] = component

            return obj
        }

        case UPDATE_COMPONENT:
        case ADD_COMPONENT: {
            // Pega o estado atual, a lista de componentes atuais
            let obj = cloneDeep(state)

            obj.showLegend = false
            obj.connected = true
            obj.fingerprint = null
            /**
             * Tratamento no dados para cada componente
             */
            const reducerHelper = subreducers[action.name]
                ? subreducers[action.name]
                : subreducers.Interface

            let id = null
            if (action.type === ADD_COMPONENT) {
                // Gera um hash unico para o componente
                id = action.payload.id ? action.payload.id : makeHash(action)

                if (action.payload.flt === undefined) {
                    action.payload.flt = true
                }

                // Se não temos menusLifo ele deve virar um vetor vazio
                if (obj.menusLifo == null) {
                    obj.menusLifo = []
                }

                if (obj.componentsLifo == null) {
                    obj.componentsLifo = []
                }

                if (obj.componentsFixedLifo == null) {
                    obj.componentsFixedLifo = []
                }

                // Insere o componente ou atualiza ele
                // prevalesce como padrão os valores recebidos anteriormente
                if (
                    obj.components[id]?.payload &&
                    (editorTypes.includes(action?.name) ||
                        obj.components[id]?.payload?.typeMenu === 'guias')
                ) {
                    action.payload = defaultsDeep(
                        action.payload,
                        reducerHelper.buildDefaultsDeepSource(
                            obj.components[id].payload
                        )
                    )
                }

                obj.components[id] = cloneDeep(action)

                /* 
                    Verifica se o MessageWindow tem um componente "Pai", se nao tiver
                    o componente atual se torna o "Pai", caso contrario ele é removido e o "Pai" anulado
                */
                const isParentComponent =
                    obj.componentsLifo?.length &&
                    obj.componentsLifo[obj.componentsLifo.length - 1] == id &&
                    id == obj.MessageWindowParentId

                if (obj.MessageWindow && !obj.MessageWindowParentId) {
                    obj.MessageWindowParentId = id
                } else if (
                    !isParentComponent &&
                    !obj.MessageWindow?.persistent
                ) {
                    obj.MessageWindow = null
                    obj.MessageWindowParentId = null
                }
            } else if (action.type === UPDATE_COMPONENT) {
                id = action?.payload?.id ?? null
                if (!id) {
                    console.error('Não podemos atualizar um componente sem ID.')
                    return obj
                }

                const component = obj.components[id] || {}
                if (!keysIn(component).length) {
                    console.error(
                        'Não podemos atualizar um componente que ainda não foi realmente criado.'
                    )
                    return obj
                }

                /* 
                    Verifica se o MessageWindow tem um componente "Pai" e se o componente a ser atualizado é familiar,
                    se nao for ele será removido
                */

                if (
                    obj.MessageWindowParentId &&
                    id !== obj.MessageWindowParentId &&
                    !obj.MessageWindow?.persistent
                ) {
                    obj.MessageWindow = null
                    obj.MessageWindowParentId = null
                }

                obj.components[id] = updatePart(action, component)
            }
            action = obj.components[id]

            let ret = reducerHelper['before']({
                id,
                state: obj,
                action,
                subreducers,
                store,
            })

            obj = ret.state

            if (ret.stop) {
                return obj
            }

            // Atualiza o último componente ativo
            if (obj.components[id].name !== 'HintWeb') {
                obj.lastActiveComponent = cloneDeep(obj.components[id])
            }

            /**
             * Aplica a mesma lógica do menu aqui, se existe removemos componentes
             * e se não existe, somente adiciona o componente na LIFO
             * TODO: Essa rotina começou a repetir, transformar isso em função
             */
            const index = obj.componentsLifo.indexOf(id)

            const isNewElement = index < 0
            const lastElementId =
                obj.componentsLifo[obj.componentsLifo.length - 1]

            if (isNewElement) {
                if (
                    obj.components[lastElementId] &&
                    obj.components[lastElementId].name === 'Dialogo'
                ) {
                    // 90173 - permite cachear componentes removidos
                    // delete obj.components[lastElementId]
                    obj.componentsLifo.pop()
                }
            }

            let resortComponent
            if (
                obj.components[lastElementId]?.name === 'HintWeb' &&
                lastElementId > id
            ) {
                resortComponent = obj.componentsLifo.pop()
            }

            if (isNewElement) {
                if (action.payload.flt === false) {
                    obj.componentsFixedLifo.push(id)
                }

                obj.componentsLifo.push(id)
                obj.activeElementIndex = 0
            } else {
                const removed = obj.componentsLifo.splice(index + 1)
                removed.forEach(id => {
                    const comp = obj.components[id]
                    if (
                        comp.name !== 'HintWeb' &&
                        comp.name !== 'EditorFormEditPdv'
                    ) {
                        // obj.lastActiveComponent = { ...comp }
                        // 90173 - permite cachear componentes removidos
                        // delete obj.components[id]

                        const indexFixedLifo =
                            obj.componentsFixedLifo.indexOf(id)
                        if (indexFixedLifo > -1) {
                            obj.componentsFixedLifo.splice(indexFixedLifo, 1)
                        }
                    } else {
                        if (obj.componentsLifo.indexOf(id) === -1) {
                            obj.componentsLifo.push(id)

                            // Adiciono no array de componentes fixos somente se nao for flutuante
                            if (comp.payload.flt === false) {
                                obj.componentsFixedLifo.push(id)
                            }
                        }
                    }
                })

                obj.lastFixedComponentId =
                    obj.componentsFixedLifo && obj.componentsFixedLifo.length
                        ? obj.componentsFixedLifo[
                              obj.componentsFixedLifo.length - 1
                          ]
                        : null
            }

            if (resortComponent) {
                obj.componentsLifo.push(resortComponent)
            }

            // Ação pós limpeza (melhor ponto para verificação do Editor)
            let retafter = reducerHelper['after']({
                id,
                state: obj,
                action,
                subreducers,
                store,
            })

            obj = retafter.state

            /*
             * Quando estamos inserindo um elemento nao flutuante, retiramos da frente
             * tudo que for flutuante (incluindo Formulario)
             * Validamos se existem outros elementos na fila para evitar remocao indesejada
             */
            if (action.payload.flt === false && obj.componentsLifo.length > 1) {
                const ids = cloneDeep(obj.componentsLifo).reverse()
                ids.forEach(id => {
                    if (
                        obj.components[id] &&
                        obj.components[id].name !== 'EditorFormEditPdv' &&
                        (obj.components[id].payload.flt ||
                            obj.components[id].name === 'Formulario' ||
                            obj.components[id].payload.typeMenu === 'normal')
                    ) {
                        // 90173 - permite cachear componentes removidos
                        // delete obj.components[id]
                        obj.componentsLifo.splice(
                            obj.componentsLifo.indexOf(id),
                            1
                        )
                    }
                })
            }

            // Remove um menu que tem um ID maior que o componente sendo inserido
            // Removemos apenas um item para evitar quebra no fluxo
            // Esse caso pode ser visto no PDV (Perfil: P, F4/F2/Enter)
            if (obj.menusLifo.length) {
                const lastActiveMenuId = obj.menusLifo[obj.menusLifo.length - 1]
                if (lastActiveMenuId && lastActiveMenuId > id) {
                    obj.menusLifo.splice(obj.menusLifo.length - 1, 1)
                    // 90173 - permite cachear componentes removidos
                    // delete obj.components[lastActiveMenuId]
                }
            }

            return obj
        }

        case SET_FORMULARIO: {
            return {
                ...state,
                Formulario: action.payload,
            }
        }

        case LOCK_MOUSE_CLICK: {
            return {
                ...state,
                mouseLock: action.payload.data,
            }
        }

        case WS_DIALOGOPROGRESSAO: {
            return {
                ...state,
                DialogoProgressao: action.payload,
            }
        }

        case WS_MESSAGEWINDOW: {
            return {
                ...state,
                MessageWindow: action.payload,
            }
        }

        case O2_OPEN: {
            return {
                ...state,
                components: {},
                componentsLifo: [],
                componentsFixedLifo: [],
                menusLifo: [],
                painelO2: true,
            }
        }

        case O2_CLOSE: {
            return {
                ...state,
                painelO2: false,
            }
        }

        default: {
            return state
        }
    }
}

// Export selectors
export const getMenu = (props, id = null) => {
    try {
        if (id) {
            const menu = getComponentById(id, props)
            return menu && menu.payload ? menu.payload : null
        }

        if (props.eac.menusLifo.length > 0) {
            const lastIndex =
                props.eac.menusLifo[props.eac.menusLifo.length - 1]
            return props.eac.components[lastIndex].payload
        }
    } catch (e) {
        // do nothing
    }

    return null
}

/**
 * Verifica se o Menu que está aberto é o Menu Principal ou naõ
 */
export const checkIsMenuPrincipal = props => {
    const menu = getMenu(props)
    return menu?.title === 'Menu Principal'
}

/**
 * Retorna o componente DialogoProgressao
 */
export const getDialogoProgressao = props => {
    return props.eac.DialogoProgressao
}

/**
 * Retorna o componente MessageWindow
 */
export const getMessageWindow = props => {
    return props.eac.MessageWindow
}

/**
 * Retorna os dados do NerusInfo
 */
export const getNerusInfo = props => {
    return props.eac.NerusInfo
}

/**
 * Retorna o estado do Login
 */
export const getLogin = props => {
    return props.eac.Login
}

/**
 * Método que retorna o buffer atual e antigo.
 * Esse buffer é utilizado no sendBuffer / key
 */
export const getBuffer = props => {
    return {
        last: props.eac.oldBuffer ? props.eac.oldBuffer : null,
        current: props.eac.currentBuffer ? props.eac.currentBuffer : null,
    }
}

/**
 * Método que retorna o buffer atual e antigo.
 * Esse buffer é utilizado no sendEdit
 */
export const getFormBuffer = props => {
    return {
        // last: props.eac.oldBuffer ? props.eac.oldBuffer : null,
        current: props.eac.formBuffer ? props.eac.formBuffer : null,
    }
}

/**
 * Método que retorna o buffer do Editor
 */
export const getRowEditorBuffer = props => {
    return props.eac.rowEditorBuffer
}

/**
 * Método que retorna um componente identificado por ID
 */
export const getComponentById = (id, props) => {
    return props.eac.components[id]
        ? props.eac.components[id]
        : { payload: null }
}

/**
 * Método que retorna os componentes
 * de um tipo especifico
 */
export const getComponentsOfType = (type, props) => {
    switch (type) {
        case 'Menu': {
            return props.eac.menusLifo
        }
        case 'DialogoProgressao': {
            return props.eac.DialogoProgressao
        }
        case 'MessageWindow': {
            return props.eac.MessageWindow
        }
    }

    try {
        return (
            props.eac.componentsLifo.filter(id => {
                const component = props.eac.components[id]
                return component.name === type
            }) || []
        )
    } catch (e) {
        return []
    }
}

/**
 * Método que retorna se existe um
 * componente ativo de um tipo especifico
 * @return boolean
 */
export const hasComponentOfType = (type, props) => {
    try {
        return getComponentsOfType(type, props).length > 0
    } catch (e) {
        return false
    }
}

/**
 * Esse método é usado para fazer correção dos titulos,
 * mantendo uma letra maiscula e o resto minusculo
 * @return string
 */
export const sanitizeTitle = title => {
    let sanitizedTitle = title.trim().toLowerCase()
    return sanitizedTitle
        ? `${sanitizedTitle[0].toUpperCase()}${sanitizedTitle.substr(1)}`
        : ''
}

/**
 * Retorna os titulos para o breadcrumb
 */
export const getBreadcrumb = props => {
    let bread = []

    // percorre os menus abertos e adiciona o que não for menu principal
    props.eac.menusLifo.forEach(id => {
        const menu = props.eac.components[id]
        if (menu) {
            const titulo = menu.payload.title.trim()
            if (titulo && titulo !== 'Menu Principal') {
                bread.push(sanitizeTitle(titulo))
            }
        }
    })

    // por ultimo adiciona qualquer componente que tenha titulo
    props.eac.componentsLifo.forEach(id => {
        const component = props.eac.components[id]
        if (
            component &&
            component.payload.title &&
            component.name !== 'SeletorOpcoes' &&
            component.name !== 'HintWeb'
        ) {
            bread.push(sanitizeTitle(component.payload.title))
        }
    })

    if (bread.length > 3) {
        bread = bread.slice(bread.length - 3)
        bread.unshift('...')
    }

    return bread
}

/**
 * Valida se o elemento especificado no ID é o elemento ativo atualmente sem considerar os menus
 */
export const isActiveComponent = (componentId, props) => {
    if (props.app.showCalculator || props.eac.DialogoProgressao) {
        return false
    }

    const { componentsLifo, menusLifo, components } = props.eac
    const componentsLifoFiltered = getFilteredLifo(
        cloneDeep(componentsLifo),
        components
    )

    const lastId = componentsLifoFiltered.pop()
    const lastMenuId = [...menusLifo].pop()

    return (
        componentId === lastId ||
        (componentId === lastMenuId && lastId < lastMenuId)
    )
}

export const isLegendShow = props => {
    return props.eac.showLegend
}

export const getFilteredLifo = (componentsLifo, components) =>
    componentsLifo
        .map(id => {
            const component = components[id]
            if (component && component.name !== 'HintWeb') {
                return id
            }
            return null
        })
        .filter(val => val !== null)

/**
 * Retorna o componente ativo atualmente, considerando os menus
 */
export const getActiveComponent = props => {
    const { componentsLifo, menusLifo, components } = props.eac
        ? props.eac
        : props

    const componentsLifoFiltered = getFilteredLifo(componentsLifo, components)

    const lastComponentId =
        componentsLifoFiltered && componentsLifoFiltered.length
            ? componentsLifoFiltered[componentsLifoFiltered.length - 1]
            : null

    const lastMenuId =
        menusLifo && menusLifo.length ? menusLifo[menusLifo.length - 1] : null

    return (
        (lastComponentId && lastMenuId
            ? lastMenuId > lastComponentId
                ? components[lastMenuId]
                : components[lastComponentId]
            : lastComponentId
            ? components[lastComponentId]
            : components[lastMenuId]) || null
    )
}

export const createGetComponentById = props => {
    const { components } = props.eac ? props.eac : props
    return id => (components[id] ? components[id] : { payload: null })
}

/**
 * Retorna o elemento ativo do componente
 */
export const getActiveElementIndex = props => {
    return props.eac.activeElementIndex
}

/**
 * Retorna o ultimo componente ativo
 */
export const getLastActiveComponent = props => {
    const lastActiveComponent = props.eac.lastActiveComponent
    const menus = props.eac.menusLifo
    return lastActiveComponent
        ? lastActiveComponent
        : menus && menus.length
        ? props.eac.components[menus[menus.length - 1]]
        : null
}

/**
 * Retorna o ID do ultimo componente fixo
 */
export const getLastFixedComponentId = props => {
    const componentsFixedLifo = props.eac.componentsFixedLifo
    return componentsFixedLifo && componentsFixedLifo.length
        ? componentsFixedLifo[componentsFixedLifo.length - 1]
        : null
}

// Export Reducer
export default EacReducer
