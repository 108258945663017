import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'
import AddIcon from '@material-ui/icons/Add'
import ArrowLeftIcon from '@material-ui/icons/ArrowBackIos'
import ArrowRightIcon from '@material-ui/icons/ArrowForwardIos'
import SimulationIcon from '@material-ui/icons/BlurOn'
import Filter9Plus from '@material-ui/icons/Filter9Plus'
import HelpOutline from '@material-ui/icons/HelpOutline'
import SearchIcon from '@material-ui/icons/Search'
import config from '@nerus/config'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import keycodes from '@nerus/framework/common/Keycodes'
import Flutuante from '@nerus/framework/components/Base/Flutuante'
import MoneyIcon from '@nerus/framework/icons/Money'
import Button from '@nerus/framework/styled/Button'
import Text from '@nerus/framework/styled/Text'
import { noop } from '@nerus/framework/util'
import { styles } from '@nerus/styles/components/pdv'
import clsx from 'clsx'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import ReactMarkdown from 'react-markdown'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'

import PdvPreviewer from '../../../NerusIT/Picture/PdvPreviewer'
import PreviewerSmall from '../../../NerusIT/Picture/PreviewerSmall'
import { withWS } from '../../Business/Websocket/Context'
import { sendBuffer } from '../../Eac/EacActions'
import {
    getActiveComponent,
    getComponentById,
    getNerusInfo,
    hasComponentOfType,
    isActiveComponent,
} from '../../Eac/EacReducer'
import { getActiveField } from '../../Eac/reducers/Formulario'
import Editor from '../Editor'
import { processRows } from '../EditorFormEdit'
import Header from './Estrutura/Header'

const helpText = `
- **F2:** pesquisa pela Descrição do Produto;
- **F3:** informa o Método de Pagamento;
- **F4:** informa o Cliente;
- **F5:** acessa a Calculadora;
- **F6:**
  - reeditar produto; OU
  - alterar a opção de presente;
- **F7:** informa Descontos;
- **F8:** mostra o estoque do Produto para todas as Lojas configuradas;
- **F9:** finaliza a Venda, desde que exista pelo menos 1 produto;
- **Ctrl-alt-A:** aciona o QuerySQL;
- **Ctrl-alt-B:**
  - processa o Controle de Estacionamento; OU
  - informa produto via Balança Eletrônica (supermercados); OU
  - posiciona no Produto dentro da lista;
- **Ctrl-alt-C:**
  - informa o Ambiente para o qual o produto será inserido (Sala, Cozinha, etc - usado em lojas de Decoração e Material de Construção); OU
  - informa dados para a Recarga de Celular;
- **Ctrl-alt-D:** mostra tela de Análise de Crédito (habilitado somente para alguns clientes);
- **Ctrl-alt-E:** aciono o sistema de mensagens internas do Nérus;
- **Ctrl-alt-F:** posiciona no Produto dentro da lista de produtos já informados, se ele existir;
- **Ctrl-alt-G:** informa os produtos a partir de uma Matriz de Grade;
- **Ctrl-alt-L:** chama a Precificação do Produto;
- **Ctrl-alt-N:** registra um Produto Não-Atendidos (produto que o cliente procura mas que não tem estoque ou não está no mix da loja);
- **Ctrl-alt-O:** informa a Simulação de Pagamentos;
- **Ctrl-alt-P:** pesquisa um Produto pelo Código de Barras, Referência do Fabricante ou Autor/Editora;
- **Ctrl-alt-R:** informa eventuais Razões de Não-Compra de um Pedido, se habilitado;
- **Ctrl-alt-S:** exibe a simulação de uma Nota Fiscal, com todos os impostos incluídos;
- **Ctrl-alt-T:** processa a Troca de Produtos;
- **Ctrl-alt-V:** informa Vendedores Adicionais no Pedido;
- **Ctrl-alt-X:** acessa a Lista de Presentes, se habilitada;
- **Ctrl-alt-Z:** mostra as Informações Técnicas do Produto;

### OBSERVAÇÃO:
> Nem todas as teclas estão disponíveis em todas as situações, pois dependem de configurações e da instalação de recursos específicos.
`

export class EditorFormEditPdv extends EnhancedComponent {
    static mapStateToProps = (state, ownProps) => {
        const EditorFormEditPdv = getComponentById(ownProps.id, state).payload
        const activeComponent = getActiveComponent(state)
        const active = isActiveComponent(ownProps.id, state)
        const rows =
            EditorFormEditPdv?.form && EditorFormEditPdv?.editor
                ? processRows(
                      EditorFormEditPdv.form.data.list,
                      EditorFormEditPdv.editor.data.cols
                  )
                : undefined

        if (rows) {
            rows.forEach((row, index) => {
                const enabled = row.filter(el => el.enabled === 1)
                if (enabled?.length) {
                    EditorFormEditPdv.editor.data.edtLin = index
                    return false
                }
            })
        }

        return {
            wsStop: state.app.wsStop,
            showSidebar: state.app.showSidebar,
            nerusInfo: getNerusInfo(state),
            pdv: EditorFormEditPdv,
            cols: EditorFormEditPdv?.editor?.data?.cols,
            rows,
            active:
                (active || activeComponent?.name === 'HintWeb') &&
                !hasComponentOfType('Menu', state) &&
                !state.app.showSidebar,
        }
    }

    static propTypes = {
        pdv: PropTypes.shape({
            editor: PropTypes.object,
            dt: PropTypes.string,
            lj: PropTypes.number,
            ordno: PropTypes.number,
            mult: PropTypes.number,
            pdvno: PropTypes.number,
            estoq: PropTypes.number,
            cliNo: PropTypes.number,
            cliDoc: PropTypes.string,
            cliName: PropTypes.string,
            tot: PropTypes.number,
            troca: PropTypes.number,
            subtot: PropTypes.number,
            desc: PropTypes.number,
            paymNo: PropTypes.number,
            paymName: PropTypes.string,
            tabPreco: PropTypes.string,
            qtdItens: PropTypes.number, // remover
        }),
        wsStop: PropTypes.bool,
        active: PropTypes.bool,
        isFixo: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        classes: PropTypes.object,
        ws: PropTypes.object.isRequired,
        rows: PropTypes.array,
        nerusInfo: PropTypes.any,
    }

    state = {
        help: false,
    }

    dispatchKey = key => this.props.ws.send(sendBuffer(key, 'sendBuffer'))

    doClientDetail = () => this.dispatchKey(keycodes.F4_KEY)

    doDescSearch = () => this.dispatchKey(keycodes.F2_KEY)

    doPayment = () => this.dispatchKey(keycodes.F3_KEY)

    doDiscount = () => this.dispatchKey(keycodes.F7_KEY)

    doSimulation = () => this.dispatchKey(keycodes.CTRL_ALT_O)

    doStock = () => this.dispatchKey(keycodes.F8_KEY)

    doSearch = () => this.dispatchKey(keycodes.CTRL_F)

    doBack = () => this.dispatchKey(keycodes.ESCAPE_KEY)

    doForward = () => this.dispatchKey(keycodes.F9_KEY)

    toggleHelp = () => this.setState(({ help }) => ({ help: !help }))

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.checkProps(
                [
                    'active',
                    'wsStop',
                    'showSidebar',
                    'cliName',
                    'ordno',
                    'qtdItens',
                    'paymName',
                    'tabPreco',
                    'tot',
                    'infoList',
                ],
                nextProps
            ) ||
            !isEqual(nextProps.rows, this.props.rows) ||
            this.state.help !== nextState.help
        )
    }

    render() {
        const { pdv = {}, active, classes, nerusInfo, wsStop } = this.props
        const { help } = this.state

        const {
            id,
            editor = {},
            form,
            cliName,
            cliDoc,
            cliNo,
            ordno,
            mult,
            tot,
            troca,
            subtot,
            lj,
            dt,
            qtdItens,
            paymNo,
            paymName,
            estoq,
            pdvno,
            tabPreco,
            typeOrd = 'Pedido',
            infoList,
        } = pdv || {}

        let { desc, ship } = pdv || {}

        let orderNumber = ordno
        if (!orderNumber) {
            orderNumber = ''
        }

        let cols = editor?.data?.cols
        if (!cols || cols?.length < 1) {
            cols = [
                'Produto',
                'Descricao',
                'Grade',
                'Quantidade',
                'Preco Unitario',
                'Subtotal',
            ]
        } else {
            cols = [...(editor?.data?.cols || [])]
        }

        let title = undefined
        const edtLin = editor?.data?.edtLin

        const isProdutoDescAtivo =
            form?.data?.list &&
            form.data.list[0]?.bt &&
            form.data.list[0].bt.filter(bt => bt.key === keycodes.F2_KEY)

        const activeElement = getActiveField(form?.data?.list || [])
        const activeValue = this.createRef('activeValue', activeElement.value)
        let rows = processRows(
            form?.data?.list || [],
            editor?.data?.cols || [],
            id,
            activeValue
        )

        let activeProduct = infoList?.length
            ? infoList[edtLin]
                ? infoList[edtLin]
                : infoList[edtLin - 1]
                ? infoList[edtLin - 1]
                : {}
            : {}

        let allowPhotos = true
        const { pdvPhotos, binPhotos } = config
        if (binPhotos !== 'all') {
            const bins = binPhotos
                .split(',')
                .map(value => value && value.trim())
            const { name: bin } = nerusInfo
            if (bins.indexOf(bin) === -1) {
                allowPhotos = false
            }
        }

        if (cols && allowPhotos && (pdvPhotos === 2 || pdvPhotos === 3)) {
            if (cols && cols[1] !== ' ') {
                const [cod, ...head] = cols
                cols = [cod, ' ', ...head]
            }

            rows = rows.map((row, index) => {
                const [cod, ...restrow] = row
                return [
                    cod,
                    {
                        ...cod,
                        enabled: 0,
                        value: `cod: ${cod.value}`,
                        prdno:
                            infoList.length && infoList[index]
                                ? infoList[index].prdno
                                : undefined,
                        grade:
                            infoList.length && infoList[index]
                                ? infoList[index].grade
                                : undefined,
                        showDefault: true,
                        table: 'prdpicture', // TODO: Provavelmente isso dará algum problema no futuro, WS precisa enviar essa informação
                        component: PreviewerSmall,
                        forceComponent: true,
                        inputWidth: 1,
                        width: 24,
                    },
                    ...restrow,
                ]
            })
        }

        const hasPreviewer = allowPhotos && (pdvPhotos === 1 || pdvPhotos === 3)
        return (
            <Box
                className={clsx({
                    // [classes.inactive]: !active,
                    [classes.root]: true,
                })}
            >
                <Header
                    ordno={orderNumber}
                    paymNo={paymNo}
                    paymName={paymName}
                    cliDoc={cliDoc}
                    cliName={cliName}
                    cliNo={cliNo}
                    dt={dt}
                    lj={lj}
                    pdvno={pdvno}
                    classes={classes}
                    onClientDetailClick={this.doClientDetail}
                    onPaymentClick={this.doPayment}
                    onGraphClick={this.doGraph}
                    active={active}
                    typeOrd={typeOrd}
                />

                {help ? (
                    <Flutuante
                        title="Ajuda"
                        size="medium"
                        scroll
                        onClose={this.toggleHelp}
                    >
                        <div className={classes.markdown}>
                            <ReactMarkdown>{helpText}</ReactMarkdown>
                        </div>
                    </Flutuante>
                ) : null}

                <Box className={classes.actions}>
                    <Box flex={1}>
                        <Button
                            variant={'round'}
                            color={'info'}
                            onClick={this.doSearch}
                            disabled={!active}
                            tooltip={'CTRL+F-'}
                        >
                            <SearchIcon className={classes.spacedIcon} /> Buscar
                            Produto no Pedido
                        </Button>
                        <Button
                            tooltip={'F2-'}
                            variant={'round'}
                            color={'success'}
                            onClick={this.doDescSearch}
                            disabled={Boolean(!active && isProdutoDescAtivo)}
                        >
                            <AddIcon className={classes.spacedIcon} /> Pesquisa
                            Descrição
                        </Button>
                        <Button
                            tooltip={'F7-'}
                            variant={'round'}
                            color={'danger'}
                            onClick={this.doDiscount}
                            disabled={!active}
                        >
                            <MoneyIcon className={classes.spacedIcon} />{' '}
                            Desconto
                        </Button>
                        <Button
                            tooltip={'CTRL+ALT+O-'}
                            variant={'round'}
                            color={'purple'}
                            onClick={this.doSimulation}
                            disabled={!active}
                        >
                            <SimulationIcon className={classes.spacedIcon} />{' '}
                            Simulação
                        </Button>
                        <Button
                            tooltip="Ajuda"
                            variant="round"
                            color={'warn'}
                            disabled={!active}
                            onClick={this.toggleHelp}
                        >
                            <HelpOutline
                                className={`${classes.spacedIcon} ${classes.helpIcon}`}
                            />{' '}
                            Ajuda
                        </Button>
                    </Box>

                    <Box>
                        <Button
                            tooltip={'F8-'}
                            variant={'round'}
                            color={'warn'}
                            onClick={this.doStock}
                            disabled={!active}
                        >
                            <Filter9Plus className={classes.spacedIcon} />{' '}
                            Estoque
                        </Button>
                    </Box>
                </Box>

                <Box className={classes.columns}>
                    <Box className={classes.columnPdv}>
                        <Editor
                            isFixo={true}
                            isPdv={true}
                            enabled={active && !wsStop}
                            active={active && !wsStop}
                            id={id}
                            showTitle={false}
                            onRowClick={noop}
                            onRowDoubleClick={noop}
                            onScroll={noop}
                            actions={false}
                            rows={rows}
                            cols={cols}
                        >
                            {mult || estoq || tabPreco ? (
                                <Box className={classes.pdvInformations}>
                                    <Box>
                                        <Text bold small>
                                            Qtd. de Itens:
                                        </Text>
                                        <Text small>{qtdItens || 0}</Text>
                                    </Box>
                                    {mult ? (
                                        <Box>
                                            <Text bold small>
                                                Multiplicador:
                                            </Text>
                                            <Text small>{mult}</Text>
                                        </Box>
                                    ) : (
                                        ''
                                    )}
                                    {estoq ? (
                                        <Box>
                                            <Text bold small>
                                                Estoque:
                                            </Text>
                                            <Text small>{estoq}</Text>
                                        </Box>
                                    ) : (
                                        ''
                                    )}
                                    {tabPreco ? (
                                        <Box>
                                            <Text bold small>
                                                Tab. de Preço:
                                            </Text>
                                            <Text small>{tabPreco}</Text>
                                        </Box>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                            ) : (
                                ''
                            )}
                        </Editor>

                        <Box className={classes.actions}>
                            <Button
                                tooltip={'ESC-'}
                                variant={'round'}
                                color={'success'}
                                onClick={this.doBack}
                                disabled={!active}
                            >
                                <ArrowLeftIcon /> Voltar
                            </Button>

                            <div className={classes.data}>
                                <Box className={classes.priceOthers}>
                                    <Box display={'flex'}>
                                        {ship ? (
                                            <Box
                                                display={'flex'}
                                                marginRight={3}
                                            >
                                                <div className="price-title">
                                                    Frete:
                                                </div>
                                                <div className="price-value">
                                                    <NumberFormat
                                                        value={ship}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        displayType={'text'}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        prefix={'R$ '}
                                                    />
                                                </div>
                                            </Box>
                                        ) : null}
                                        {troca ? (
                                            <Box
                                                display={'flex'}
                                                marginRight={3}
                                            >
                                                <div className="price-title">
                                                    Troca:
                                                </div>
                                                <div className="price-value">
                                                    <NumberFormat
                                                        value={troca}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        displayType={'text'}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        prefix={'R$ '}
                                                    />
                                                </div>
                                            </Box>
                                        ) : null}
                                    </Box>

                                    <Box>
                                        <Box display={'flex'}>
                                            {desc ? (
                                                <Fragment>
                                                    <div className="price-title">
                                                        Desconto:
                                                    </div>
                                                    <div className="price-value">
                                                        <NumberFormat
                                                            value={desc}
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                            displayType={'text'}
                                                            thousandSeparator={
                                                                '.'
                                                            }
                                                            decimalSeparator={
                                                                ','
                                                            }
                                                            prefix={'R$ '}
                                                        />
                                                    </div>
                                                </Fragment>
                                            ) : null}
                                        </Box>
                                        <Box display={'flex'}>
                                            <div className="price-title">
                                                Subtotal:
                                            </div>
                                            <div className="price-value">
                                                <NumberFormat
                                                    value={subtot || 0}
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    displayType={'text'}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    prefix={'R$ '}
                                                />
                                            </div>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className={classes.priceTotal}>
                                    <div className="price-title">Total:</div>
                                    <div className="price-value">
                                        <NumberFormat
                                            value={tot}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            prefix={'R$ '}
                                        />
                                    </div>
                                </Box>
                            </div>

                            <Button
                                tooltip={'F9-'}
                                variant={'round'}
                                color={'success'}
                                onClick={this.doForward}
                                disabled={!active}
                            >
                                Avançar <ArrowRightIcon />
                            </Button>
                        </Box>
                    </Box>
                    {hasPreviewer ? (
                        <Box className={classes.columnFotos}>
                            <PdvPreviewer
                                showDefault
                                table="prdpicture" // TODO: Provavelmente isso dará algum problema no futuro, WS precisa enviar essa informação
                                title={title}
                                product={activeProduct}
                                prdno={
                                    activeProduct && activeProduct?.prdno
                                        ? activeProduct.prdno
                                        : undefined
                                }
                                grade={
                                    activeProduct && activeProduct?.grade
                                        ? activeProduct?.grade
                                        : undefined
                                }
                            />
                        </Box>
                    ) : null}
                </Box>
            </Box>
        )
    }
}

export default connect(EditorFormEditPdv.mapStateToProps)(
    withStyles(styles)(withWS(EditorFormEditPdv))
)
