import About from './About'
import BiometriaFacial from './BiometriaFacial'
import BoletoDialog from './BoletoDialog'
import Calculator from './Calculator'
import Content from './Content'
import DanfeDialog from './DanfeDialog/index'
import Dialogo from './Dialogo'
import DialogoProgressao from './DialogoProgressao'
import MessageWindow from './MessageWindow'
import Editor from './Editor'
import EditorDuplo from './EditorDuplo'
import EditorForm from './EditorForm'
import EditorFormEdit from './EditorFormEdit'
import EditorFormEditPdv from './EditorFormEditPdv'
import FilePreviewer from './FilePreviewer'
import FileSend from './FileSend'
import Formulario from './Formulario'
import FormularioGrid from './FormularioGrid'
import Foto from './Foto'
import HintWeb from './Hint'
import ListaSelecao from './ListaSelecao'
import Menu from './Menu'
import PainelO2 from './PainelO2'
import RelatorioDialog from './RelatorioDialog'
import RelatorioDialogGerador from './RelatorioDialogGerador'

export default {
    About,
    BiometriaFacial,
    Calculator,
    Content,
    Dialogo,
    DialogoProgressao,
    Editor,
    EditorDuplo,
    EditorForm,
    EditorFormEdit,
    EditorFormEditPdv,
    FilePreviewer,
    FileSend,
    Foto,
    Formulario,
    FormularioGrid,
    HintWeb,
    ListaSelecao,
    Menu,
    PainelO2,
    RelatorioDialog,
    RelatorioDialogGerador,
    DanfeDialog,
    BoletoDialog,
    MessageWindow,
}
