import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function QueryIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0,19.2)">
                <path d="M 0,687 H 1000 V -87 H 0 Z M 953,439 V 641 H 758 V 439 Z M 285,393 V 198 H 480 V 393 Z M 239,198 V 393 H 46 V 198 Z M 480,439 V 641 H 285 V 439 Z m 47,202 V 439 H 712 V 641 Z M 480,152 H 285 V -40 h 195 z m 47,0 V -40 h 185 v 192 z m 0,46 H 712 V 393 H 527 Z M 758,393 V 198 H 953 V 393 Z M 239,641 H 46 V 439 H 239 Z M 46,152 V -40 H 239 V 152 Z M 758,-40 H 953 V 152 H 758 Z" />
            </g>
        </SvgIcon>
    )
}
