import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'

export const QUERY_REPORT = gql`
    query queryReport(
        $queries: [StatementInput]
        $type: Int
        $filters: Any
        $user_id: Int
    ) {
        queryReport(
            queries: $queries
            type: $type
            filters: $filters
            user_id: $user_id
        ) {
            data
        }
    }
`

export default Component => {
    return graphql(QUERY_REPORT, {
        name: 'queryReport',
        skip: props => {
            return (
                !parseInt(props.type) ||
                !props.queries?.length ||
                !props.user_id
            )
        },
        options: props => {
            return {
                fetchPolicy: 'network-only',
                variables: {
                    queries: props.queries
                        ? props.queries.map(({ id, query }) => ({
                              id,
                              query: query ? query : '',
                          }))
                        : [],
                    type: parseInt(props.type),
                    filters: props.activeFilters || props.filters || null,
                    user_id: props.user_id,
                },
            }
        },
    })(Component)
}
