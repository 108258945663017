import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { recToClass } from './Recs'

export class Rec extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        updateField: PropTypes.func,
        useDiv: PropTypes.bool,
        enabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        lbl: PropTypes.string,
        value: PropTypes.string,
        typeRec: PropTypes.string,
        updateActiveField: PropTypes.func,
        onSubmit: PropTypes.func,
    }

    render() {
        const { enabled, lbl, value, typeRec, useDiv, updateField } = this.props

        if (useDiv === true) {
            return (
                <div className="input-form">
                    <label>{lbl}</label>

                    <div className="input-value">{value}</div>
                </div>
            )
        }

        const Component = recToClass[typeRec]

        if (!Component) {
            console.warn(`Rec não implementada: ${typeRec}`)
            return null
        }

        const props = {
            ...this.props,
            onChange: value => {
                updateField(this.props, value)
            },
            onValueSend: ({ value }) => {
                updateField(this.props, value)
            },
        }

        return Component ? (
            <Component {...props} />
        ) : (
            <div className={`input form-group${enabled ? '' : ' disabled'}`}>
                {`Não ${this.props.typeRec} implementado`}
            </div>
        )
    }
}

export default connect(state => {
    return {
        wsStop: state.app.wsStop,
    }
})(Rec)
