import { alpha } from '@material-ui/core'

export const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 1px',
    },
    inactive: {
        opacity: 0.4,
    },
    headerRoot: {
        display: 'flex',
        padding: `0 100px ${theme.spacing(1)}px 0`,
        whiteSpace: 'nowrap',
        alignItems: 'center',
        '& > div:first-child': {
            maxWidth: 270,
        },
        '& > div:nth-child(2)': {
            maxWidth: 890,
        },
        '& > div:nth-child(2) > span:first-child': {
            marginRight: 70,
        },
        '& button.dashboard': {
            color: '#b21e28 !important',
            '&.Mui-disabled': {
                color: '#e8e8e8 !important',
                // style={{ color: '#b21e28' }}
            },
        },
        '& .pdv-report-content': {
            backgroundColor: '#e8e8e8 !important',
        },
    },
    markdown: {
        maxWidth: '100%',
        '& ul': {
            padding: '0 0 0 24px',
            margin: 0,
        },
        '& h3': {
            fontSize: 14,
            padding: 4,
            color: '#b21e28 !important',
            backgroundColor: 'rgba(178, 30, 40, 0.1)',
            marginLeft: -8,
            marginRight: -8,
            width: 'calc(100% + 20px)',
        },
        '& blockquote': {
            backgroundColor: 'rgba(239, 214, 77, 0.8)',
            borderRadius: '10px',
            padding: '8px 12px',
            margin: '0 8px',
            '& p:last-child': {
                marginBottom: 0,
            },
        },
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
    spacedRight: {
        paddingRight: theme.spacing(2),
    },
    spacedIcon: {
        margin: `0 ${theme.spacing(1)}px 0 0`,
    },
    helpIcon: {
        fontSize: `18px !important`,
    },
    details: {
        flex: 0,
        flexWrap: 'nowrap',
        opacity: 0.75,
        position: 'absolute',
        right: 20,
        lineHeight: '10px',
    },
    pdvInformations: {
        cursor: 'default',
        borderTop: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
        backgroundColor: `${alpha(theme.palette.common.black, 0.05)}`,
        color: alpha(theme.palette.common.black, 0.35),
        display: 'flex',
        padding: `0px ${theme.spacing(0.5)}px`,
        alignItems: 'center',
        justifyContent: 'space-between',
        '& span': {
            fontSize: theme.spacing(1.5),
            lineHeight: 1,
            [theme.breakpoints.down('lg')]: {
                fontSize: theme.spacing(1.35),
            },
            [theme.breakpoints.down('md')]: {
                fontSize: theme.spacing(1.25),
            },
        },
    },
    columns: {
        display: 'flex',
        flex: 1,
        alignItems: 'flex-start',
    },
    columnPdv: {
        flex: 1,
        height: 'calc(100% - 52px)',
    },
    columnFotos: {
        minWidth: '20%',
        maxWidth: '20%',
        height: '100%',
        marginLeft: theme.spacing(2),
    },
    actions: {
        padding: `${theme.spacing(1)}px 0`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    data: {
        flex: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    priceOthers: {
        display: 'flex',
        marginRight: 24,
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        '& .price-title': {
            textTransform: 'uppercase',
            textAlign: 'right',
            display: 'block',
            width: '100%',
            fontSize: 18,
            whiteSpace: 'nowrap',
        },
        '& .price-value': {
            textAlign: 'right',
            lineHeight: '24px',
            display: 'block',
            width: '100%',
            fontSize: 18,
            color: theme.palette.purple.main,
            whiteSpace: 'nowrap',
        },
    },
    priceTotal: {
        minWidth: 180,
        marginRight: 24,
        display: 'block',
        '& .price-title': {
            textTransform: 'uppercase',
            textAlign: 'right',
            display: 'block',
            width: '100%',
            fontSize: 12,
            whiteSpace: 'nowrap',
        },
        '& .price-value': {
            textAlign: 'right',
            lineHeight: '32px',
            display: 'block',
            width: '100%',
            fontSize: 40,
            color: theme.palette.purple.main,
            whiteSpace: 'nowrap',
        },
    },
})
