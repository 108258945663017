import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import keycodes from '@nerus/framework/common/Keycodes'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { setWsStop } from '../../../../App/AppActions'
import { withWS } from '../../../Business/Websocket/Context'
import { sendBuffer } from '../../../Eac/EacActions'

const debug = require('debug')('nerus:keyboard')

// Valor em ms para aguardar por teclas antes de enviar pro backend
const KEY_STROKE_TIMEOUT = 750

const styles = () => ({
    labelRoot: {
        top: '-15px !important',
    },
    inputRoot: {
        marginTop: '0 !important',
    },
    svg: {
        verticalAlign: 'middle',
    },
})

class Search extends EnhancedComponent {
    static propTypes = {
        mdQuery: PropTypes.bool,
        placeholder: PropTypes.string,
        value: PropTypes.any,
        classes: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            value: props.value || '',
            disabled: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.getRef('searchField')) {
            this.getRef('searchField').focus()
            this.getRef('searchField').addEventListener(
                'keydown',
                this.handleKeyDown
            )
        }

        if (
            prevProps.value !== this.props.value ||
            (this.props.value === this.state.value && this.state.disabled) ||
            ((prevProps.mdQuery !== this.props.mdQuery ||
                (prevProps.value === null &&
                    this.props.value === null &&
                    this.props.mdQuery)) &&
                this.state.disabled)
        ) {
            this.setState({
                disabled: false,
                value: this.props.value || this.state.value || '',
            })
            this.props.dispatch(setWsStop(false))
        }
    }

    handleChange = event => {
        this.setState({
            value: event.target.value.toUpperCase(),
        })
    }

    clearField = () => {
        this.setState({
            value: '',
        })
    }

    handleKeyDown = event => {
        debug('keydown', event)
        const doSend = extraKey => () => {
            this.props.dispatch(setWsStop(true))
            let value = ''
            const len = this.props.value?.length
            if (len) {
                value = '\b'.repeat(len)
            }

            this.props.ws.send(
                sendBuffer({ value: value + event.target.value }, 'sendEdit')
            )

            if (extraKey) {
                this.props.ws.send(sendBuffer(extraKey, 'key'))
            }

            clearTimeout(this.timeout)
            this.timeout = null
        }

        event.stopPropagation()
        if (
            [
                keycodes.TAB_KEY,
                keycodes.ENTER_KEY,
                keycodes.ESCAPE_KEY,
                keycodes.BACKSPACE_KEY,
                keycodes.LEFT_ARROW_KEY,
                keycodes.RIGHT_ARROW_KEY,
                keycodes.DOWN_ARROW_KEY,
                keycodes.UP_ARROW_KEY,
                keycodes.SHIFT_KEY,
                keycodes.CAPS_LOCK_KEY,
                keycodes.DELETE_KEY,
            ].includes(event.keyCode)
        ) {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                this.props.dispatch(setWsStop(false))
                this.props.ws.send(sendBuffer(event.keyCode, 'key'))
                return
            }

            if (event.keyCode === keycodes.TAB_KEY) {
                event.preventDefault()
            }

            if (!event.target.value && !this.timeout) {
                this.props.ws.send(sendBuffer(event.keyCode, 'key'))
            } else {
                clearTimeout(this.timeout)
                this.timeout = null
                this.timeout = setTimeout(() => {
                    this.setState(
                        { disabled: true },
                        doSend(
                            // evita o disparo de backspace a mais
                            event.keyCode !== keycodes.BACKSPACE_KEY
                                ? event.keyCode
                                : null
                        )
                    )
                }, KEY_STROKE_TIMEOUT)
            }

            if (
                [
                    keycodes.ENTER_KEY,
                    keycodes.ESCAPE_KEY,
                    keycodes.DOWN_ARROW_KEY,
                    keycodes.UP_ARROW_KEY,
                ].indexOf(event.keyCode) > -1
            ) {
                this.props.dispatch(setWsStop(false))
            }
        } else {
            clearTimeout(this.timeout)
            this.timeout = null
            this.timeout = setTimeout(() => {
                this.setState(
                    { disabled: true, value: event.target.value },
                    doSend()
                )
            }, KEY_STROKE_TIMEOUT)
        }
    }

    render() {
        const { classes, mdQuery, placeholder, className } = this.props
        if (!placeholder) {
            return null
        }

        return (
            <TextField
                autoFocus
                margin="none"
                label={
                    <span>
                        <SearchIcon className={classes.svg} /> {placeholder}
                    </span>
                }
                value={this.state.value}
                inputProps={{
                    ref: this.createRef('searchField'),
                }}
                disabled={this.state.disabled || !mdQuery}
                onChange={this.handleChange}
                //onKeyDown={this.handleKeyDown} - Correção Ticket 94515 (enter 2x)
                className={className}
                type="search"
                name="search"
                InputLabelProps={{
                    classes: {
                        root: classes.labelRoot,
                    },
                    shrink: true,
                }}
                InputProps={{
                    classes: {
                        root: classes.inputRoot,
                    },
                }}
            />
        )
    }
}

export default connect()(withWS(withStyles(styles)(Search)))
