import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import keycodes, { navigationKeys } from '@nerus/framework/common/Keycodes'
import AnalyticIcon from '@nerus/framework/icons/Analytic'
import BuildingIcon from '@nerus/framework/icons/Building'
import ChartIcon from '@nerus/framework/icons/Chart'
import CloseIcon from '@nerus/framework/icons/Close'
import CoinsIcon from '@nerus/framework/icons/Coins'
import Dashboard from '@nerus/framework/icons/Dashboard'
import DollarIcon from '@nerus/framework/icons/Dollar'
import Enotice from '@nerus/framework/icons/Enotice'
import GearIcon from '@nerus/framework/icons/Gear'
import LockIcon from '@nerus/framework/icons/Lock'
import MoneyIcon from '@nerus/framework/icons/Money'
import MonitorIcon from '@nerus/framework/icons/Monitor'
import QueryIcon from '@nerus/framework/icons/Query'
import SaleIcon from '@nerus/framework/icons/Sale'
import StockIcon from '@nerus/framework/icons/Stock'
import UserIcon from '@nerus/framework/icons/User'
import WrenchIcon from '@nerus/framework/icons/Wrench'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import React from 'react'

import { toggleSidebar } from '../../../App/AppActions'
import { sendBuffer } from '../../Eac/EacActions'
import SelectInput from '../Formulario/Estrutura/Recs/PopupMenu/SelectInput'

const debug = require('debug')('nerus:baseMenu')

const icons = [
    <GearIcon key={`menuPrincipalIcon-1`} />,
    <BuildingIcon key={`menuPrincipalIcon-2`} />,
    <MoneyIcon key={`menuPrincipalIcon-3`} />,
    <DollarIcon key={`menuPrincipalIcon-4`} />,
    <StockIcon key={`menuPrincipalIcon-5`} />,
    <CoinsIcon key={`menuPrincipalIcon-6`} />,
    <AnalyticIcon key={`menuPrincipalIcon-7`} />,
    <SaleIcon key={`menuPrincipalIcon-8`} />,
    <WrenchIcon key={`menuPrincipalIcon-9`} />,
    <QueryIcon key={`menuPrincipalIcon-10`} />,
    <MonitorIcon key={`menuPrincipalIcon-11`} />,
    <ChartIcon key={`menuPrincipalIcon-12`} />,
    <LockIcon key={`menuPrincipalIcon-13`} />,
    <UserIcon key={`menuPrincipalIcon-14`} />,
    <LockIcon key={`menuPrincipalIcon-15`} />,
    <UserIcon key={`menuPrincipalIcon-16`} />,
    <UserIcon key={`menuPrincipalIcon-17`} />,
    <UserIcon key={`menuPrincipalIcon-18`} />,
]

class BaseMenu extends EnhancedComponent {
    static propTypes = {
        dispatch: PropTypes.func,
        showSidebar: PropTypes.bool.isRequired,
        classes: PropTypes.object,
        menu: PropTypes.object,
        isMenuPrincipal: PropTypes.bool,
        activeComponent: PropTypes.object,
        lastActiveComponent: PropTypes.object,
        typeMenu: PropTypes.string,
        isPdv: PropTypes.bool,
        right: PropTypes.bool,
        ws: PropTypes.object.isRequired,
    }

    state = {
        strListValue: '',
    }

    componentDidUpdate(prevProps) {
        const active = this.createRef('activeOption')
        if (
            active.current &&
            prevProps?.menu &&
            this.props?.menu &&
            prevProps.menu.active !== this.props.menu.active &&
            document.activeElement
        ) {
            document.activeElement.blur()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.checkProps(
                [
                    'menu',
                    'isAdmin',
                    'isPdv',
                    'choice',
                    'isMenuPrincipal',
                    'showSidebar',
                    'enabled',
                    'match',
                ],
                nextProps
            ) || this.state.strListValue !== nextState.strListValue
        )
    }

    handleMenu = target => {
        return event => {
            if (
                window.location.pathname != '/' &&
                window.location.pathname.indexOf('/bin') === -1
            ) {
                this.props.history.push('/')
            }

            if (
                window.location.pathname.indexOf('/bin/o2') > -1 &&
                window.location.pathname.indexOf('/dashboard') > -1
            ) {
                this.props.history.push('/bin/o2')
            }
            if (
                window.location.pathname.indexOf('/bin/o2') > -1 &&
                window.location.pathname.indexOf('/enotice') > -1
            ) {
                this.props.history.push('/bin/o2')
            }

            if (this.clicking) {
                return
            }

            this.clicking = true

            const {
                activeComponent,
                lastActiveComponent,
                menu: { typeMenu },
                x,
                y,
                ws,
            } = this.props

            event.preventDefault()
            if (activeComponent?.name === 'Editor' && typeMenu === 'lateral') {
                ws.send(sendBuffer(keycodes.ESCAPE_KEY))
                if (
                    lastActiveComponent?.payload?.mdQuery &&
                    lastActiveComponent.payload.lbQuery
                ) {
                    ws.send(sendBuffer(keycodes.ESCAPE_KEY))
                }
            }

            if (
                typeMenu === 'opcoes' &&
                activeComponent?.payload?.strList?.length > 9
            ) {
                ws.send(
                    sendBuffer(
                        {
                            x,
                            y,
                            key: keycodes.ENTER_KEY,
                            value: target,
                        },
                        'sendEdit'
                    )
                )
            } else {
                ws.send(sendBuffer(target))
            }
            this.clicking = false
        }
    }

    toggleSidebar = () => {
        this.props.dispatch(toggleSidebar())
    }

    handleChange = event => {
        this.setState({
            strListTarget: event.target,
            strListValue: event.target.value,
        })
    }

    handleKeyDown = event => {
        const { x, y, ws } = this.props
        debug('keydown', event)

        if (this.clicking) {
            return
        }

        this.clicking = true

        if (event.keyCode !== keycodes.ESCAPE_KEY) {
            event.stopPropagation()
            event.stopImmediatePropagation && event.stopImmediatePropagation()
        }

        if (
            navigationKeys.indexOf(event.keyCode) > -1 &&
            this.state.strListValue !== '' &&
            this.props.menu.shtct.includes(this.state.strListValue)
        ) {
            event.stopPropagation()

            const buffer = {
                x,
                y,
                key: event.keyCode,
                value: this.state.strListValue,
            }
            ws.send(sendBuffer(buffer, 'sendEdit'))
        }
        this.clicking = false
    }

    handleDashboards = () => {
        if (this.props.location.pathname.indexOf('/bin/o2') > -1) {
            return this.props.history.push('/bin/o2/dashboard')
        }

        this.props.history.push('/dashboard')
    }
    handleReports = () => this.props.history.push('/library')

    handleEnotice = () => {
        if (this.props.location.pathname.indexOf('/bin/o2') > -1) {
            return this.props.history.push('/bin/o2/enotice')
        }

        this.props.history.push('/enotice')
    }

    render() {
        const props = this.props
        const state = {
            title: props.menu ? props.menu.title : null,
            right: props.menu ? props.menu.right : false,
            atalhos: props.menu ? props.menu.shtct : null,
            choice: props.menu ? props.menu.strList : null,
            typeMenu: props.menu ? props.menu.typeMenu : 'lateral',
            isMenuPrincipal: props.isMenuPrincipal,
            isAdmin: props.isAdmin,
            activeComponent: props.activeComponent,
            lastActiveComponent: props.lastActiveComponent,
            wrapperClass: 'sidebar',
            open:
                props.menu && props.menu.typeMenu === 'lateral'
                    ? props.showSidebar
                    : true,
        }
        const typeMenusHighligth = ['lateral', 'normal', 'box']

        const { classes, isAdmin, isPdv, match, enabled } = this.props
        const { open, atalhos, isMenuPrincipal, choice, right } = state
        let { typeMenu } = state

        if (typeMenu === 'lateral' && isPdv) {
            typeMenu = 'box'
        }

        if (!atalhos || (typeMenu === 'box' && !open)) {
            return null
        }

        const ListMenu = (
            <List
                className={clsx({
                    [classes.listRoot]: true,
                    [classes.listRootTypes]:
                        typeMenu !== 'lateral' && typeMenu !== 'box',
                    [classes.listRootSecondary]:
                        !isMenuPrincipal &&
                        (typeMenu === 'lateral' || typeMenu === 'box'),
                    [classes.listRootSecondaryClosed]:
                        !isMenuPrincipal &&
                        !open &&
                        (typeMenu === 'lateral' || typeMenu === 'box'),
                    [classes.listRootBox]: typeMenu === 'box',
                    [classes.right]: right,
                })}
            >
                {typeMenu === 'opcoes' && choice.length > 9 ? (
                    <SelectInput
                        inputRef={this.createRef('inputMenuOpcoes')}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        value={this.state.strListValue}
                        enabled={this.props.enabled}
                    />
                ) : null}
                {choice.map((object, i) => {
                    let atalho = atalhos[i]
                    let regex
                    try {
                        regex = new RegExp(
                            atalho.replace('*', '\\*').replace('.', '\\.')
                        )
                    } catch (e) {
                        console.error(e)
                        console.warn(
                            atalho.replace('*', '\\*').replace('.', '\\.')
                        )
                    }

                    let text = (
                        <span
                            dangerouslySetInnerHTML={{
                                __html:
                                    match.path.indexOf('/dashboard') > -1
                                        ? object
                                        : object.replace(
                                              atalho && regex ? regex : '',
                                              `<span class="highlight">${atalho}</span>`
                                          ),
                            }}
                        />
                    )
                    return (
                        <ListItem
                            button
                            onClick={this.handleMenu(atalho)}
                            key={'menu-item-' + i}
                            ref={
                                props.menu &&
                                atalho === atalhos[props.menu.active]
                                    ? this.createRef('activeOption')
                                    : undefined
                            }
                            classes={{
                                root: clsx({
                                    [classes.root]: typeMenu === 'normal',
                                    [classes.menuRootTypes]:
                                        typeMenu !== 'lateral',
                                    [classes.listItemBox]: typeMenu === 'box',
                                    [classes.backgroudCurrentChoiceMenu]:
                                        typeMenusHighligth.includes(typeMenu) &&
                                        props.menu &&
                                        atalho === atalhos[props.menu.active] &&
                                        (match?.path === '/' ||
                                            (match?.path?.indexOf?.('/bin') >
                                                -1 &&
                                                match?.path?.indexOf?.(
                                                    '/dashboard'
                                                ) === -1)),
                                }),
                            }}
                        >
                            {isMenuPrincipal ? (
                                <ListItemIcon>{icons[i]}</ListItemIcon>
                            ) : null}
                            <ListItemText primary={open ? text : null} />
                        </ListItem>
                    )
                })}
                {/* MENU EXTRA */}
                {isMenuPrincipal &&
                window.NERUS_CONFIG.dashboard.use &&
                isAdmin ? (
                    <ListItem
                        button
                        onClick={this.handleDashboards}
                        key={'menu-item-dashboards'}
                        classes={{
                            root: clsx({
                                [classes.root]: typeMenu === 'normal',
                                [classes.menuRootTypes]: typeMenu !== 'lateral',
                                [classes.listItemBox]: typeMenu === 'box',
                                [classes.backgroudCurrentChoiceMenu]:
                                    match.path.indexOf('/dashboard') > -1,
                            }),
                        }}
                    >
                        {isMenuPrincipal ? (
                            <ListItemIcon>
                                <Dashboard />
                            </ListItemIcon>
                        ) : null}
                        <ListItemText primary={open ? 'Dashboards' : null} />
                    </ListItem>
                ) : (
                    ''
                )}

                {isMenuPrincipal && window.NERUS_CONFIG.enotice && isAdmin ? (
                    <ListItem
                        button
                        onClick={this.handleEnotice}
                        key={'menu-item-enotice'}
                        classes={{
                            root: clsx({
                                [classes.root]: typeMenu === 'normal',
                                [classes.menuRootTypes]: typeMenu !== 'lateral',
                                [classes.listItemBox]: typeMenu === 'box',
                                [classes.backgroudCurrentChoiceMenu]:
                                    match.path.indexOf('/enotice') > -1,
                            }),
                        }}
                    >
                        {isMenuPrincipal ? (
                            <ListItemIcon>
                                <Enotice />
                            </ListItemIcon>
                        ) : null}
                        <ListItemText primary={open ? 'Enotice' : null} />
                    </ListItem>
                ) : (
                    ''
                )}
            </List>
        )

        if (typeMenu !== 'lateral' && typeMenu !== 'box') {
            return ListMenu
        }

        return (
            <Drawer
                BackdropProps={{
                    classes: {
                        root:
                            typeMenu === 'box'
                                ? classes.menuBackdrop
                                : undefined,
                    },
                }}
                ModalProps={{
                    disablePortal: typeMenu === 'box',
                }}
                open={true}
                variant={typeMenu === 'box' ? 'temporary' : 'persistent'}
                anchor={right ? 'right' : 'left'}
                className={clsx(classes.root, {
                    [classes.belowActive]: typeMenu === 'box' && !enabled,
                })}
                PaperProps={{
                    className: clsx({
                        [classes.menuRoot]: true,
                        [classes.menuRootRight]: right,
                        [classes.menuClosed]: !isMenuPrincipal && !open,
                        [classes.menuPrincipalClosed]: isMenuPrincipal && !open,
                        [classes.menuBox]: typeMenu === 'box',
                    }),
                    elevation: 5,
                }}
            >
                {typeMenu !== 'box' ? (
                    <div
                        className={clsx({
                            [classes.close]: true,
                            [classes.closeSecondary]: !isMenuPrincipal,
                            [classes.closed]: !open,
                        })}
                        onClick={this.toggleSidebar}
                    >
                        <CloseIcon />
                    </div>
                ) : (
                    ''
                )}

                {ListMenu}
            </Drawer>
        )
    }
}
export default BaseMenu
