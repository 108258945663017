/* global Promise */
import { EventEmitter } from 'events'
import React from 'react'

const debug = require('debug')('nerus:ws:pdv:manager')

export const DLL_WS_URI = 'ws://localhost:25830/ws'

export const pdvManager = React.createContext(null)

export default class PdvServiceManager extends EventEmitter {
    static CLOSED = 0
    static CONNECTING = 10
    static CONNECTED = 20
    static WAITING = 30
    static PROCESSING = 40
    static DEAD = 50

    static MAX_RETRIES = 3

    _connection = null
    _endpoint = null

    _retries = 0
    _queue = []

    state = PdvServiceManager.CLOSED

    alive = false
    waiting = false

    constructor(props = {}) {
        const { ...options } = props

        super(options)

        this._triggerChangeState(PdvServiceManager.CLOSED)
    }

    _triggerChangeState = state => {
        if (state !== this.state) {
            this.state = state
            this.emit('STATE_CHANGE', { state })
        }
    }

    isConnected() {
        return (
            this.state === PdvServiceManager.CONNECTED ||
            this.state === PdvServiceManager.WAITING ||
            this.state === PdvServiceManager.PROCESSING
        )
    }

    reconnect() {
        return this.connect(this._endpoint)
    }

    connect(endpoint) {
        /**
         * Adiciona um método para enviar dados ao websocket
         * permitindo disparar alguns eventos durante o envio
         * das mensagens pro WS
         */
        if (this._connection) {
            debug('WS já conectado.')
            return this
        }

        this._endpoint = endpoint || DLL_WS_URI
        if (typeof WebSocket !== 'undefined') {
            this._triggerChangeState(PdvServiceManager.CONNECTING)

            return new Promise(fulfill => {
                const ws = new WebSocket(this._endpoint)

                ws.onopen = event => {
                    debug('onopen', event)

                    fulfill(ws)

                    this._triggerChangeState(PdvServiceManager.CONNECTED)
                    this.emit('connected', { ws })
                }

                ws.onmessage = event => {
                    debug('onmessage', event)

                    this._triggerChangeState(PdvServiceManager.CONNECTED)
                    this.emit('RAW_MESSAGE', event)

                    const data = JSON.parse(event.data)
                    this.emit('message', data)

                    if (this._queue?.length > 0) {
                        const nextMessage = this._queue.shift()
                        if (nextMessage) {
                            this.emit('sending', nextMessage)
                            this._triggerChangeState(PdvServiceManager.WAITING)
                            this._connection.send(nextMessage)
                        }
                    }
                }

                ws.onclose = err => {
                    debug('onclose', err)

                    this._triggerChangeState(PdvServiceManager.CLOSED)

                    if (err) {
                        this.emit('ERROR', err)
                    }

                    this._connection = null

                    if (
                        this._retries < PdvServiceManager.MAX_RETRIES &&
                        !err.wasClean
                    ) {
                        this._retries++
                        this.connect(endpoint)
                    } else {
                        this._triggerChangeState(PdvServiceManager.DEAD)
                    }
                }

                ws.onerror = err => {
                    this._retries = PdvServiceManager.MAX_RETRIES
                    const e = new Error(err.message)
                    e.original = err
                    this.emit('ERROR', e)
                }

                const _send = ws.send
                ws.send = function(body) {
                    let parsedBody = body
                    if (typeof parsedBody === 'object') {
                        parsedBody = JSON.stringify(body)
                    }

                    return _send.call(this, parsedBody)
                }
            }).then(ws => {
                this._connection = ws
                return this
            })
        }

        return false
    }

    send(payload) {
        if (this._connection) {
            this.emit('sending', payload)

            if (
                this.state === PdvServiceManager.WAITING ||
                this._queue?.length > 0
            ) {
                if (!this._queue) {
                    this._queue = []
                }
                return this._queue.push(payload)
            }

            this._triggerChangeState(PdvServiceManager.WAITING)
            this._connection.send(payload)
        }
    }

    close() {
        if (this._connection) {
            this.emit('closing')
            this._triggerChangeState(PdvServiceManager.CLOSED)
            this._connection.close()
            this._connection = null
            this.emit('closed')
        }
    }
}
