import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const UPDATE_ENOTICE_MUTATION = gql`
    mutation updateEnotice($enotice: EnoticeInput!) {
        updateEnotice(enotice: $enotice) {
            error
            message
        }
    }
`

export default function useUpdateEnotice(options) {
    return useMutation(UPDATE_ENOTICE_MUTATION, {
        displayName: 'updateEnotice',
        ...options,
    })
}
