import keycodes from '@nerus/framework/common/Keycodes'

import {
    nextElementIndex,
    previousElementIndex,
    sendBuffer,
} from '../EacActions'
import Menu from './Menu'

export function handleKeyboard(ws) {
    return (dispatch, event /*, data*/) => {
        if (
            event.keyCode === keycodes.TAB_KEY ||
            event.keyCode === keycodes.RIGHT_ARROW_KEY
        ) {
            dispatch(nextElementIndex())
            return
        }
        if (event.keyCode === keycodes.LEFT_ARROW_KEY) {
            dispatch(previousElementIndex())
            return
        }
        if (event.keyCode === keycodes.ESCAPE_KEY) {
            ws.send(
                sendBuffer(
                    {
                        key: keycodes.ESCAPE_KEY,
                    },
                    'sendEdit'
                )
            )
        }
    }
}

export default class Foto extends Menu {
    static before({ state, action }) {
        action.payload.disableKeyboard = true
        action.payload.keyboardActions = handleKeyboard

        return { state, action }
    }
}
