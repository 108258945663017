import { alpha } from '@material-ui/core/styles'

export const styles = theme => ({
    root: {
        lineHeight: 0,
        textAlign: 'right',
        borderRadius: '50%',
        width: 'auto !important',
        height: 'auto !important',
        padding: '10px !important',
        fontSize: '12px !important',
        color: alpha(theme.palette.common.white, 0.75),
        boxShadow: `0 0 6px 2px ${alpha(
            theme.palette.common.white,
            0.5
        )} inset`,
        '&:hover': {
            color: theme.palette.textColor,
            backgroundColor: '#fff !important',
            boxShadow: '0 3px 6px ' + alpha(theme.palette.textColor, 0.5),
        },
        '& svg': {
            right: '3px',
            width: '12px',
            height: '12px',
            position: 'relative',
            transform: 'scaleX(1.3)',
        },
    },
})
