/**
 * Essas constantes são usadas para padronizar os eventos que ocorrem
 * na aplicação de forma a simplificar a alteração
 *
 * Devem ser nomes que denotem o que ocorrerá na ação
 * Sempre em caixa alta com _, sem espaços ou acentos
 */
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE'
export const SIDEBAR_SET = 'SIDEBAR_SET'
export const LOCK = 'LOCK'
export const WS_STOP = 'WS_STOP'

export const CALCULATOR_TOGGLE = 'CALCULATOR_TOGGLE'
export const ABOUT_TOGGLE = 'ABOUT_TOGGLE'

export const LOADING = 'LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const ACTION_FAILED = 'ACTION_FAILED'
export const ACTION_SUCCEED = 'ACTION_SUCCEED'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS'
export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'
export const DATEPICKER_OPEN = 'DATEPICKER_OPEN'
export const DATEPICKER_CLOSE = 'DATEPICKER_CLOSE'
export const IS_ADMIN = 'IS_ADMIN'

// Export Actions

export function isAdmin() {
    return {
        type: IS_ADMIN,
    }
}

export function datePickerOpen() {
    return {
        type: DATEPICKER_OPEN,
    }
}

export function datePickerClose() {
    return {
        type: DATEPICKER_CLOSE,
    }
}

/**
 * Alterna a abertura da tela da "Calculadora"
 */
export function toggleCalculator() {
    return {
        type: CALCULATOR_TOGGLE,
    }
}

/**
 * Alterna a abertura da tela do "Sobre"
 */
export function toggleAbout() {
    return {
        type: ABOUT_TOGGLE,
    }
}

/**
 * Alterna a sidebar entre aberto e fechado
 */
export function toggleSidebar() {
    return {
        type: SIDEBAR_TOGGLE,
    }
}

/**
 * Seta se a sidebar está aberta ou não
 * @param show boolean
 */
export function setSidebar(show) {
    return {
        type: SIDEBAR_SET,
        showSidebar: show,
    }
}

/**
 * Seta se aplicação está travada ou não
 * @param lock boolean
 */
export function setLock(lock) {
    return {
        type: LOCK,
        isLocked: lock,
    }
}

/**
 * Impede o WS de comunicar
 */
export function setWsStop(value) {
    return {
        type: WS_STOP,
        value,
    }
}

// Ações padrões, só são usadas em casos de erro

export function startLoading() {
    return {
        type: LOADING,
    }
}

export function stopLoading() {
    return {
        type: STOP_LOADING,
    }
}

export function clearError() {
    return {
        type: CLEAR_ERROR,
    }
}

export function clearSuccess() {
    return {
        type: CLEAR_SUCCESS,
    }
}

export function actionFailed(message, original) {
    return {
        type: ACTION_FAILED,
        error: {
            message,
            original,
        },
    }
}

export function actionSucceed(message, original) {
    return {
        type: ACTION_SUCCEED,
        success: {
            message,
            original,
        },
    }
}

export function showAlert(title, mensage, detail) {
    return {
        type: SHOW_ALERT,
        alert: {
            title,
            mensage,
            detail,
        },
    }
}

export function hideAlert() {
    return {
        type: HIDE_ALERT,
    }
}
