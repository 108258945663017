import Formulario from '@nerus/framework/components/Formulario'
import React, { Component } from 'react'

// import { RelatorioDialogGerador } from '../../NerusWeb/Components/RelatorioDialogGerador'

// import BoxSeletor from '@nerus/framework/BoxSeletor'
// import Editor from '@nerus/framework/Editor'
// import Formulario from '@nerus/framework/Formulario'
// import Hint from '@nerus/framework/Hint'
// import Breadcrumb from '@nerus/framework/Breadcrumb'
// import Dialogo from '@nerus/framework/Dialogo'

// {
//     "type":"Editor",
//     "id":74267,
//     "data":{
//     "title":"EMPRESAS",
//     "flt":false,
//     "new":false,

//   }
// }
class PageTest extends Component {
    render() {
        const opts = []
        for (let x = 1; x < 20; x++) {
            opts.push(`Valor ${x}`)
        }
        return (
            <div className="components componentBoxSeletor">
                {/*<RelatorioDialogGerador />*/}
                {/*<Hint text="Teste de HINT!!" />*/}
                <Formulario
                    list={[
                        {
                            type: 'TextFieldWeb',
                            lbl: 'No. Funcionario           ',
                            name: 'funcionario',
                            isTitle: false,
                            value: '1',
                            desc: '',
                            enabled: 1,
                            id: 1,
                            typeRec: 'REC_SHORT',
                            typeRecMod: 'm',
                            attrib: 0,
                            prec: 0,
                            sz: 5,
                            x: 31,
                            y: 1,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'No. da Carteira Trabalho  ',
                            isTitle: false,
                            value: '048291-002',
                            desc: '',
                            enabled: 1,
                            id: 2,
                            typeRec: 'REC_STRING',
                            typeRecMod: 'm',
                            attrib: 0,
                            prec: 0,
                            sz: 20,
                            x: 31,
                            y: 2,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'Nome Funcionario          ',
                            isTitle: false,
                            value: 'VENDEDOR',
                            desc: '',
                            enabled: 1,
                            id: 3,
                            typeRec: 'REC_STRING',
                            typeRecMod: 'm',
                            attrib: 0,
                            prec: 0,
                            sz: 40,
                            x: 31,
                            y: 3,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'Apelido Funcionario       ',
                            isTitle: false,
                            value: 'BEBE',
                            desc: '',
                            enabled: 1,
                            id: 4,
                            typeRec: 'REC_STRING',
                            typeRecMod: 'n',
                            attrib: 0,
                            prec: 0,
                            sz: 36,
                            x: 31,
                            y: 4,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'Endereco                  ',
                            isTitle: false,
                            value: 'RUA PADRE ROLIM, 815',
                            desc: '',
                            enabled: 1,
                            id: 5,
                            typeRec: 'REC_STRING',
                            typeRecMod: 'm',
                            attrib: 0,
                            prec: 0,
                            sz: 32,
                            x: 31,
                            y: 5,
                            bt: [
                                {
                                    text:
                                        'F2-localiza rua, avenida ou cidade pelo cep',
                                    key: 113,
                                },
                            ],
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'Bairro                    ',
                            isTitle: false,
                            value: 'SAO LUCAS',
                            desc: '',
                            enabled: 1,
                            id: 6,
                            typeRec: 'REC_STRING',
                            typeRecMod: 'n',
                            attrib: 0,
                            prec: 0,
                            sz: 20,
                            x: 31,
                            y: 6,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'Cidade                    ',
                            isTitle: false,
                            value: 'BH',
                            desc: '',
                            enabled: 1,
                            id: 7,
                            typeRec: 'REC_STRING',
                            typeRecMod: 'm',
                            attrib: 0,
                            prec: 0,
                            sz: 24,
                            x: 31,
                            y: 7,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'Estado                    ',
                            isTitle: false,
                            value: 'MG',
                            desc: '',
                            enabled: 1,
                            id: 8,
                            typeRec: 'REC_STRING',
                            typeRecMod: 'm',
                            attrib: 0,
                            prec: 0,
                            sz: 2,
                            x: 31,
                            y: 8,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'CEP                       ',
                            isTitle: false,
                            value: '30130090',
                            desc: '',
                            enabled: 1,
                            id: 9,
                            typeRec: 'REC_LONG',
                            typeRecMod: 'n',
                            attrib: 0,
                            prec: 0,
                            sz: 8,
                            x: 31,
                            y: 9,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'DDD                       ',
                            isTitle: false,
                            value: '123',
                            desc: '',
                            enabled: 1,
                            id: 10,
                            typeRec: 'REC_SHORT',
                            typeRecMod: 'n',
                            attrib: 0,
                            prec: 0,
                            sz: 4,
                            x: 31,
                            y: 10,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'Telefone                  ',
                            isTitle: false,
                            value: '2412780',
                            desc: '',
                            enabled: 1,
                            id: 11,
                            typeRec: 'REC_LONG',
                            typeRecMod: 'n',
                            attrib: 0,
                            prec: 0,
                            sz: 9,
                            x: 31,
                            y: 11,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'No. da Loja               ',
                            isTitle: false,
                            value: '1',
                            desc: "LOJA MATRIZZZZ''''\"\\\"\\'",
                            enabled: 1,
                            id: 12,
                            typeRec: 'REC_SHORT',
                            typeRecMod: 'n',
                            attrib: 0,
                            prec: 0,
                            sz: 5,
                            x: 31,
                            y: 12,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'Funcao/Cargo              ',
                            isTitle: false,
                            value: '2',
                            desc: 'VENDEDOR',
                            enabled: 1,
                            id: 13,
                            typeRec: 'REC_SHORT',
                            typeRecMod: 'm',
                            attrib: 0,
                            prec: 0,
                            sz: 5,
                            x: 31,
                            y: 13,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: '. Outras Informacoes...',
                            isTitle: false,
                            value: '',
                            desc: '',
                            enabled: 1,
                            id: 14,
                            typeRec: 'REC_PROMPT',
                            typeRecMod: 'n',
                            attrib: 0,
                            prec: 0,
                            sz: 1,
                            x: 26,
                            y: 15,
                            onClick: () => {
                                alert('Outras Informações!')
                            },
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'C.P.F.                    ',
                            isTitle: false,
                            value: '751.585.746-33',
                            desc: '',
                            enabled: 1,
                            id: 15,
                            typeRec: 'REC_STRING',
                            typeRecMod: 'm',
                            attrib: 0,
                            prec: 0,
                            sz: 20,
                            x: 31,
                            y: 17,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'Comissao Venda a Vista    ',
                            isTitle: false,
                            value: '2.00',
                            desc: '',
                            enabled: 1,
                            id: 16,
                            typeRec: 'REC_PERC',
                            typeRecMod: 'n',
                            attrib: 0,
                            prec: 2,
                            sz: 5,
                            x: 31,
                            y: 18,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'Comissao Venda a Prazo    ',
                            isTitle: false,
                            value: '2.00',
                            desc: '',
                            enabled: 1,
                            id: 17,
                            typeRec: 'REC_PERC',
                            typeRecMod: 'n',
                            attrib: 0,
                            prec: 2,
                            sz: 5,
                            x: 31,
                            y: 19,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'No. da Meta               ',
                            isTitle: false,
                            value: '2',
                            desc: 'META 2',
                            enabled: 1,
                            id: 18,
                            typeRec: 'REC_SHORT',
                            typeRecMod: 'n',
                            attrib: 0,
                            prec: 0,
                            sz: 5,
                            x: 31,
                            y: 20,
                        },
                        {
                            type: 'TextFieldWeb',
                            lbl: 'Senha do Funcionario      ',
                            isTitle: false,
                            value: "6'( B))-",
                            desc: '',
                            enabled: 1,
                            id: 19,
                            typeRec: 'REC_STRING',
                            typeRecMod: 'P',
                            attrib: 0,
                            prec: 0,
                            sz: 8,
                            x: 31,
                            y: 21,
                        },
                    ]}
                />
                {/*
                <Editor
                    title="EMPRESAS"
                    flt={true}
                    new={false}
                    onCloseClick={() => {
                        alert('Fechar Modal!')
                    }}
                    cols={['No.', 'Nome Empresa']}
                    colsType={[
                        {
                            title: 'No.',
                            name: 'no',
                            type: 'GR_SHORT',
                            sz: 7,
                            dec: 0,
                        },
                        {
                            title: 'Nome Empresa',
                            name: 'name',
                            type: 'GR_STRING',
                            sz: 41,
                            dec: 0,
                        },
                    ]}
                    rows={[
                        ['     1', ' LOJA 1 E CD'],
                        ['     2', ' GRUPO RESTANTE'],
                        ['     3', ' LOJAS 1 E 16'],
                        ['     4', ' LOJAS ATIVAS'],
                        ['     5', ' LOJAS 1,2,3'],
                        ['     6', ' EMPRESA PARA CARTOES'],
                        ['     7', ' EMPRESA PARA CARTOES 2'],
                    ]}
                    lines={16}
                    mdQuery={false}
                    lbQuery={null}
                    query={null}
                    bt={[
                        {
                            text: ' P osicionar',
                            onClick: () => {
                                alert('Posicionar!')
                            },
                        },
                    ]}
                    btIcons={[
                        {
                            text: '(I)nserir',
                            onClick: () => {
                                alert('Inserir!')
                            },
                        },
                    ]}
                    edtLin={0}
                />
                //*/}
                {/*<BoxSeletor options={opts} />*/}
                {/*
                <Breadcrumb
                    data={['Menu', 'Menu 2', 'Menu 3', 'Menu 4', 'Fim']}
                />
                */}
                {/*
                    <Dialogo
                        title="Teste"
                        optType={-1}
                        msg="Mensagem 1"
                        msgShow="Mensagem 2"
                    />
                */}
            </div>
        )
    }
}

export default PageTest
