import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function SaleIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.02404433,0,0,-0.02404433,-0.02329208,19.24899)">
                <path d="m 381,308 q 18,-13 32,-21 l 36,-23 -31,-28 -19,38 q -2,4 -8.5,16 -6.5,12 -9.5,18 z m 612,448 q 7,-9 6,-18 L 951,363 q 0,-9 -8,-15 L 372,-154 q -19,-15 -33,2 L 8,223 q -15,19 2,33 l 572,502 q 6,6 15,6 h 378 q 11,0 18,-8 z M 356,67 q 57,53 18,96 -25,30 -75,10 -36,-14 -45,-3 -11,13 9,32 16,15 36,20 l -16,32 q -19,-4 -44,-25 -24,-22 -28,-48 -4,-26 13,-44 25,-29 77,-7 32,14 42,2 14,-16 -9,-36 -17,-16 -44,-24 l 18,-32 q 24,6 48,27 z m 164,150 35,32 -173,103 -43,-38 85,-182 33,30 -22,46 41,36 z m 54,49 89,80 -24,27 -57,-51 -104,116 -32,-29 z m 109,97 91,82 -24,26 -59,-52 -30,34 52,47 -23,26 -53,-47 -26,30 56,50 -24,26 -89,-79 z m 230,248 q 17,15 18.5,38.5 Q 933,673 918,690 902,707 879,709 856,711 838,695 821,680 819.5,656.5 818,633 833,616 q 16,-18 39,-19.5 23,-1.5 41,14.5 z" />
            </g>
        </SvgIcon>
    )
}
