export const styles = theme => ({
    root: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        position: 'fixed',
        zIndex: 2000,
        transition: 'ease 50ms all',
        backgroundColor: 'rgba(34, 34, 34, 0.8)', // TODO: mover isso para palette
    },
    logo: {
        width: 200,
        marginBottom: theme.spacing(2),
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    wrapper: {
        width: '100%',
        height: '100%',
        display: 'table',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    holder: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
})
