import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { alpha } from '@material-ui/core/styles'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import ActionDelete from '@material-ui/icons/Delete'
import HelpRounded from '@material-ui/icons/HelpRounded'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import BaseFlutuante from '@nerus/framework/components/Base/Flutuante'
import CloseIcon from '@nerus/framework/icons/Close'
import Button from '@nerus/framework/styled/Button'
import clsx from 'clsx'
import cloneDeep from 'lodash/cloneDeep'
import * as PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import help from './help.js'

const defaultFilters = [
    { filter: '[hoje]', desc: 'Data de hoje no formato YYYYMMDD' },
    { filter: '[ontem]', desc: 'Data de ontem no formato YYYYMMDD' },
    { filter: '[amanha]', desc: 'Data de amanhã no formato YYYYMMDD' },
    {
        filter: '[hora]',
        desc: 'Hora atual em segundos a partir do inicio do dia',
    },
    { filter: '[anomes]', desc: 'Ano e mês atual no formato YYYYMM' },
    {
        filter: '[iniciosemana]',
        desc: 'Data do inicio da semana atual no formato YYYYMMDD',
    },
    {
        filter: '[fimsemana]',
        desc: 'Data do fim da semana atual no formato YYYYMMDD',
    },
    {
        filter: '[iniciomes]',
        desc: 'Data do inicio do mês atual no formato YYYYMMDD',
    },
    {
        filter: '[fimmes]',
        desc: 'Data do fim do mês atual no formato YYYYMMDD',
    },
    {
        filter: '[inicioano]',
        desc: 'Data do inicio do ano atual no formato YYYYMMDD',
    },
    {
        filter: '[fimano]',
        desc: 'Data do fim do mês atual no formato YYYYMMDD',
    },
    {
        filter: '[usuario_atual]',
        desc: 'Usuário atualmente acessando a SELECT, formato inteiro',
    },
    {
        filter: '[usuario_login_atual]',
        desc:
            'Usuário atualmente acessando a SELECT, formato "login do usuário"',
    },
    { filter: '[banco]', desc: 'Nome do banco de dados atual' },
    { filter: '[bancopdv]', desc: 'Nome do banco de dados atual do PDV' },
]

const styles = theme => ({
    root: {
        flex: 1,
        height: '100%',
    },
    input: {
        height: 'inherit',
        flex: 1,
        maxHeight: '100%',
        minHeight: '100%',
        fontFamily:
            '"monospace", "Monaco", "Menlo", "Andale Mono", "Lucida Sans Typewriter", "Droid Sans Mono", "Deja Vu Sans Mono", "Courier New", "Courier"',
        whiteSpace: 'pre',
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: alpha('#000000', 0.15),
            '&:hover': {
                backgroundColor: alpha('#000000', 0.15),
            },
        },
        '&::-webkit-scrollbar': {
            width: 8,
            height: 8,
            cursor: 'pointer',
        },
    },
    background: {
        height: '100%',
        backgroundColor: theme.palette.common.white + ' !important',
        border: '1px solid ' + theme.palette.primary.dark,
        padding: theme.spacing(1),
        overflow: 'auto',
        '&:hover': {
            backgroundColor: theme.palette.common.white + ' !important',
        },
    },
    rootContainer: {
        display: 'flex',
        flex: 1,
    },
    flexContainer: {
        display: 'flex',
        overflow: 'auto',
    },
    actions: {
        paddingTop: theme.spacing(1),
        '& button:last-child': {
            margin: 0,
        },
    },
    flex: {
        flex: 1,
    },
    queryMenu: {
        minWidth: 200,
    },
    right: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
        display: 'flex',
    },
    listRoot: {
        marginRight: theme.spacing(1),
        flex: 1,
        overflow: 'auto',
        maxHeight: '100%',
        padding: 0,
        borderRadius: 4,
    },
    item: {
        '& svg': {
            color: theme.palette.primary.main,
        },
    },
    itemActive: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
        '& svg': {
            color: theme.palette.common.white,
        },
    },
    itemText: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
    },
    help: {
        '& blockquote': {
            backgroundColor: '#f9f9f9',
            whiteSpace: 'pre-line',
            margin: '8px 0',
            padding: 8,
        },
        '& dl dt': {
            display: 'inline-block',
            fontWeight: 'bold',
            marginRight: 8,
            minWidth: 110,
            float: 'left',
        },
        '& dl dd': {},
    },
    filters: {
        padding: theme.spacing(1),
        margin: theme.spacing(0, 1),
        background: '#f9f9f9',
        position: 'relative',
        flex: 0,
        transition: 'ease-in-out all 0.5s',
        '& h4': {
            color: theme.palette.primary.main,
            margin: 0,
        },
        '& dl': {
            fontSize: 12,
            '& dt::after': {
                content: "': '",
            },
        },
        '& dt': {
            cursor: 'pointer',
            fontWeight: 'bold',
        },
        '& button': {
            padding: 8,
            fontSize: 12,
            width: '100%',
            color: '#333',
            border: 'none',
            borderRadius: 4,
            marginBottom: 16,
            cursor: 'pointer',
            fontWeight: 'bold',
            background: 'rgba(0,0,0,0.05)',
        },
        '& .filter-content': {
            width: 240,
            height: '100%',
            overflow: 'auto',
        },
    },
    filtersClosed: {
        maxWidth: 0,
        padding: 0,
        margin: 0,
        '& .filter-content': {
            maxWidth: 0,
            display: 'none',
            overflow: 'hidden',
        },
    },
    close: {
        top: 0,
        zIndex: 1,
        color: theme.palette.contrastText.main,
        width: '20px',
        height: '30px',
        left: '-20px',
        display: 'flex',
        cursor: 'pointer',
        position: 'absolute',
        fontSize: '14px',
        margin: '0 !important',
        borderRadius: '50% 0 0 50%',
        backgroundColor: theme.palette.primary.main,
        transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            fontSize: '16px',
            verticalAlign: 'middle',
            transform: 'rotate(180deg)',
        },
    },
    closed: {
        '& svg': {
            transform: 'rotate(0deg)',
        },
    },
    info: {
        padding: 8,
        fontSize: 11,
        color: '#505050',
        cursor: 'default',
        textAlign: 'justify',
        background: '#fffcda',
    },
})

class Queries extends EnhancedComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        minQueries: PropTypes.number,
        maxQueries: PropTypes.number,
        initialData: PropTypes.array,
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        filters: PropTypes.array,
    }

    static defaultProps = {
        minQueries: 1,
        maxQueries: null,
    }

    constructor(props) {
        super(props)
        this.state = {
            allowInsert: props.maxQueries === null || props.maxQueries > 1,
            queries: props.initialData || [],
            filterOpen: true,
            showAll: false,
            showAllDefault: false,
            showHelp: false,
            active: 0,
        }

        const minQueries = props.minQueries
        for (let i = this.state.queries.length; i < minQueries; i++) {
            this.state.queries.push({ query: '' })
        }
    }

    handleInsert = () => {
        if (
            this.props.maxQueries &&
            this.state.queries.length >= this.props.maxQueries
        ) {
            return
        }

        this.setState({
            queries: [
                ...this.state.queries.map(query => ({ ...query })),
                { query: '' },
            ],
            active: this.state.queries.length,
        })
    }

    handleChange = event => {
        const queries = [...this.state.queries.map(query => ({ ...query }))]
        const query = queries[this.state.active]

        query.query = event.target.value
        this.setState({
            queries,
        })
    }

    handleClose = () => {
        this.props.onClose?.()
    }

    handleSelect = active => () => {
        this.setState({
            active,
        })
    }

    handleDelete = index => event => {
        event.stopPropagation()
        const queries = [...this.state.queries.map(query => ({ ...query }))]

        queries.splice(index, 1)

        this.setState({
            queries,
            active: 0,
        })
    }

    handleSave = () => {
        this.props.onSave?.(cloneDeep(this.state.queries))
        this.setState({ queries: [] })
    }

    handleHelp = () => {
        this.setState({
            showHelp: true,
        })
    }
    handleCloseHelp = () => {
        this.setState({
            showHelp: false,
        })
    }

    filterToggle = () => {
        this.setState(state => ({
            ...state,
            filterOpen: !state.filterOpen,
        }))
    }

    showAllToggle = () => {
        this.setState(state => ({
            ...state,
            showAll: !state.showAll,
        }))
    }

    showAllDefaultToggle = () => {
        this.setState(state => ({
            ...state,
            showAllDefault: !state.showAllDefault,
        }))
    }

    doSelectAndCopy = event => {
        let currentRange
        if (document.getSelection?.()?.rangeCount > 0) {
            for (let i = document.getSelection?.()?.rangeCount; i > 0; i--) {
                currentRange = document.getSelection().getRangeAt(0)
                window.getSelection().removeRange(currentRange)
            }
        } else {
            currentRange = false
        }

        if (document.createRange) {
            let range = document.createRange()
            range.selectNode(event.target)
            window.getSelection().addRange(range)
            document.execCommand('copy')
            navigator.clipboard.writeText(
                event.target.innerText.replace(/\n/g, '')
            )
        } else if (document.body.createTextRange) {
            const range = document.body.createTextRange()
            range.moveToElementText(event.target)
            range.select()
            document.execCommand('copy')
            navigator.clipboard.writeText(
                event.target.innerText.replace(/\n/g, '')
            )
        } else if (window.getSelection) {
            const selection = window.getSelection()
            const range = document.createRange()
            range.selectNodeContents(event.target)
            selection.removeAllRanges()
            selection.addRange(range)
            document.execCommand('copy')
            navigator.clipboard.writeText(
                event.target.innerText.replace(/\n/g, '')
            )
        }
    }

    render() {
        const {
            props: { classes, maxQueries, filters: allDynamicFilters = [] },
            state: { active, queries, allowInsert, showHelp },
            handleChange,
            handleClose,
            handleDelete,
            handleInsert,
            handleSelect,
            handleSave,
            handleHelp,
            handleCloseHelp,
            doSelectAndCopy,
        } = this

        const disabledInsert = maxQueries && queries.length >= maxQueries
        let insertButton = (
            <Button
                color="default"
                lbl={'Inserir'}
                dialog
                tooltip="Você atingiu o máximo de SELECT's"
                disabled={disabledInsert}
                onClick={handleInsert}
            />
        )

        if (disabledInsert) {
            insertButton = (
                <Tooltip title="Você atingiu o máximo de SELECT's">
                    <span>{insertButton}</span>
                </Tooltip>
            )
        }

        let filters = defaultFilters.filter(Boolean)
        if (!this.state.showAllDefault) {
            if (window.outerHeight <= 760) {
                filters = filters.slice(0, 3)
            } else if (window.outerHeight <= 1000) {
                filters = filters.slice(0, 5)
            }
        }

        const dynamicFilters = allDynamicFilters
        return (
            <Fragment>
                <BaseFlutuante
                    title="Editor de SELECT's"
                    size="fullscreen"
                    onClose={handleClose}
                    scroll
                >
                    <Box
                        className={clsx(
                            classes.rootContainer,
                            classes.flexContainer
                        )}
                    >
                        {allowInsert ? (
                            <Box className={classes.queryMenu}>
                                <List
                                    component="nav"
                                    className={classes.listRoot}
                                >
                                    {queries.map((item, index) => (
                                        <ListItem
                                            button
                                            key={item.query + index}
                                            className={clsx(classes.item, {
                                                [classes.itemActive]:
                                                    active === index,
                                            })}
                                            onClick={handleSelect(index)}
                                        >
                                            <ListItemText
                                                primary={`SELECT ${index + 1}`}
                                            />

                                            {index > 0 || queries.length > 1 ? (
                                                <ListItemIcon
                                                    onClick={handleDelete(
                                                        index
                                                    )}
                                                >
                                                    <ActionDelete />
                                                </ListItemIcon>
                                            ) : null}
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        ) : null}

                        <Box className={classes.flex}>
                            <TextField
                                autoFocus
                                multiline
                                fullWidth
                                rows={42}
                                variant="filled"
                                value={queries[active]?.query}
                                onChange={handleChange}
                                className={classes.root}
                                InputProps={{
                                    classes: {
                                        root: classes.background,
                                        input: classes.input,
                                    },
                                }}
                            />
                        </Box>

                        <Box
                            className={clsx(classes.flex, classes.filters, {
                                [classes.filtersClosed]: !this.state.filterOpen,
                            })}
                        >
                            <div
                                className={clsx({
                                    [classes.close]: true,
                                    [classes.closed]: !this.state.filterOpen,
                                })}
                                onClick={this.filterToggle}
                            >
                                <CloseIcon />
                            </div>

                            <div className="filter-content">
                                <h4>Variáveis pré-definidas</h4>

                                <dl>
                                    {filters.map(({ filter, desc }) => {
                                        return (
                                            <Fragment key={filter}>
                                                <dt onClick={doSelectAndCopy}>
                                                    {filter.toUpperCase()}
                                                </dt>
                                                <dd>{desc}</dd>
                                            </Fragment>
                                        )
                                    })}
                                </dl>

                                {filters.length !== defaultFilters.length ||
                                this.state.showAllDefault ? (
                                    <button onClick={this.showAllDefaultToggle}>
                                        {this.state.showAllDefault
                                            ? `Exibir menos`
                                            : `Exibir todas`}
                                    </button>
                                ) : null}

                                <h4>Variáveis dinâmicas</h4>

                                <dl>
                                    {dynamicFilters.map(
                                        ({
                                            key,
                                            desc,
                                            default: defaultValue,
                                        }) => {
                                            return (
                                                <Fragment key={key}>
                                                    <dt
                                                        onClick={
                                                            doSelectAndCopy
                                                        }
                                                    >
                                                        [{key.toUpperCase()}]
                                                    </dt>
                                                    <dd>
                                                        {desc}, valor padrão:{' '}
                                                        {defaultValue}
                                                    </dd>
                                                </Fragment>
                                            )
                                        }
                                    )}
                                </dl>

                                {dynamicFilters.length !==
                                allDynamicFilters.length ? (
                                    <button onClick={this.showAllToggle}>
                                        {this.state.showAll
                                            ? `Exibir menos`
                                            : `Exibir todas`}
                                    </button>
                                ) : null}

                                <div className={classes.info}>
                                    Clicando no filtro desejado, ele será
                                    automaticamente copiado podendo ser aplicado
                                    na SELECT usando o atalho CTRL-V
                                </div>
                            </div>
                        </Box>
                    </Box>

                    <Box
                        className={clsx(classes.flexContainer, classes.actions)}
                    >
                        <Box className={classes.flex}>
                            {allowInsert ? insertButton : null}

                            <Tooltip title="Ajuda">
                                <IconButton size="small" onClick={handleHelp}>
                                    <HelpRounded />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Box className={classes.right}>
                            <Button
                                lbl={'Cancelar'}
                                dialog
                                color="danger"
                                onClick={handleClose}
                            />
                            <Button
                                lbl={'Salvar'}
                                primary
                                dialog
                                onClick={handleSave}
                            />
                        </Box>
                    </Box>
                </BaseFlutuante>

                {showHelp ? (
                    <BaseFlutuante
                        title="Ajuda sobre SELECT's"
                        size="medium"
                        onClose={handleCloseHelp}
                        scroll
                    >
                        <span
                            className={classes.help}
                            dangerouslySetInnerHTML={help}
                        />
                    </BaseFlutuante>
                ) : null}
            </Fragment>
        )
    }
}

export default connect()(withStyles(styles)(Queries))
