import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import LinearProgress from '@material-ui/core/LinearProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import UploadIcon from '@material-ui/icons/CloudUpload'
import ClearUploadIcon from '@material-ui/icons/Delete'
import config from '@nerus/config'
import keycodes from '@nerus/framework/common/Keycodes'
import Dialogo from '@nerus/framework/components/Dialogo'
import { Input } from '@nerus/framework/styled/Input'
import { styles } from '@nerus/styles/components/fileSend'
import Axios from 'axios'
import * as PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import { normalizeStringFilename } from '../../../../../../util/formatter'
import { withWS } from '../../../../Business/Websocket/Context'
import { sendBuffer } from '../../../../Eac/EacActions'
import AbstractTextField from './AbstractTextField'

/**
 * Componente TextFieldUpload
 *
 * Rec: REC_UPLOAD
 *
 * Renderiza um campo de texto com botão para upload de arquivo
 */
export class TextFieldUpload extends AbstractTextField {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        componentId: PropTypes.number,
        ws: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)
        this.state.loading = false
        this.state.showMaxSizeDialog = false
    }

    selectFile = e => {
        e.preventDefault()
        this.getRef('inputUpload').click()
    }

    onChangeFile = e => {
        const selectedFile = e.target.files ? e.target.files[0] : ''
        if (
            this.props.sz >= selectedFile?.name?.length &&
            config.files.uploadDir.length + selectedFile?.name?.length < 64
        ) {
            const regex = /[^a-zA-Z0-9ç_.]|ç/u

            const uploadFile = regex.test(selectedFile.name)
                ? new File(
                      [e.target.files[0]],
                      normalizeStringFilename(e.target.files[0].name),
                      {
                          type: e.target.files[0].type,
                          lastModified: e.target.files[0].lastModified,
                      }
                  )
                : e.target.files[0]

            e.persist()
            this.setState(
                {
                    selectedFile: uploadFile,
                },
                () => {
                    this.onSave(this.state.selectedFile)
                }
            )
        } else {
            e.target.value = null
            this.setState({
                selectedFile: null,
                showMaxSizeDialog: true,
            })
        }
    }

    clearFile = e => {
        e.persist()
        this.setState({
            selectedFile: null,
        })
    }

    onSave = blob => {
        const { selectedFile } = this.state
        const { uid, gid } = this.props

        const useBlob =
            blob ||
            new Blob([this.state.content], {
                type: 'text/plain;charset=iso-8859-1',
            })

        const parts = selectedFile.name.split('/')
        const data = new FormData()
        data.append('uri', `${config.files.uploadDir}/${selectedFile.name}`)
        data.append('uid', uid)
        data.append('gid', gid)
        data.append('file', useBlob, parts.pop())

        return Axios.post(`/webprint/persist`, data, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            },
            timeout: 30000, // 30 segs
            onDownloadProgress: progressEvent => {
                const totalLength = progressEvent.lengthComputable
                    ? progressEvent.total
                    : progressEvent.target.getResponseHeader(
                          'content-length'
                      ) ||
                      progressEvent.target.getResponseHeader(
                          'x-decompressed-content-length'
                      )
                if (totalLength !== null) {
                    this.setState({
                        downloadingProgress: Math.round(
                            (progressEvent.loaded * 100) / totalLength
                        ),
                    })
                }
            },
            onUploadProgress: progressEvent => {
                const totalLength = progressEvent.lengthComputable
                    ? progressEvent.total
                    : progressEvent.target.getResponseHeader(
                          'content-length'
                      ) ||
                      progressEvent.target.getResponseHeader(
                          'x-decompressed-content-length'
                      )
                if (totalLength !== null) {
                    this.setState({
                        downloadingProgress: Math.round(
                            (progressEvent.loaded * 100) / totalLength
                        ),
                    })
                }
            },
        }).then(() => {
            this.setState({
                loading: false,
            })

            const editValue = this.state.selectedFile
                ? `${config.files.uploadDir}/${this.state.selectedFile.name}`
                : this.state.value

            this.props.ws.send(
                sendBuffer(
                    {
                        x: this.props.x,
                        y: this.props.y,
                        key: keycodes.ENTER_KEY,
                        value: editValue.padEnd(this.props.sz, ' '),
                    },
                    'sendEdit'
                )
            )
        })
    }

    onClose = () => {
        this.setState(prevState => ({
            selectedFile: null,
            showMaxSizeDialog: !prevState.showMaxSizeDialog,
        }))
    }

    render() {
        const {
            hasError,
            value,
            selectedFile,
            loading,
            downloadingProgress,
            showMaxSizeDialog,
        } = this.state
        const {
            classes,
            enabled,
            lbl,
            sz,
            x,
            y,
            inputWidth,
            labelWidth,
            componentId,
            InputProps,
            dispatch,
            orientation = 'horizontal',
        } = this.props
        if (hasError) {
            return this.renderError()
        }

        const fieldType = this.getFieldType()
        const type = fieldType ? fieldType.type : 'text'

        const inputPropsWithAdornment = {
            ...InputProps,
            endAdornment: loading ? (
                <LinearProgress
                    variant={'determinate'}
                    value={downloadingProgress}
                    size={20}
                    color={'primary'}
                    className={classes.progressBar}
                />
            ) : selectedFile ? (
                <InputAdornment position="end">
                    <IconButton onClick={this.clearFile} disabled={!enabled}>
                        <ClearUploadIcon />
                    </IconButton>
                </InputAdornment>
            ) : (
                <InputAdornment position="end">
                    <IconButton onClick={this.selectFile} disabled={!enabled}>
                        <UploadIcon />
                    </IconButton>
                </InputAdornment>
            ),
        }

        return (
            <Fragment>
                <Dialogo
                    title="Atenção!"
                    open={showMaxSizeDialog}
                    optType={-1}
                    onClose={this.onClose}
                    msg={`Caminho completo do arquivo excede o comprimento máximo de caracteres.`}
                />

                <Input
                    dispatch={dispatch}
                    label={lbl}
                    ref={this.createRef('input')}
                    value={
                        selectedFile
                            ? `${config.files.uploadDir}/${selectedFile.name}`
                            : value
                    }
                    componentId={componentId}
                    disabled={!enabled}
                    position={{ x, y }}
                    inputWidth={inputWidth}
                    labelWidth={labelWidth}
                    orientation={orientation}
                    InputProps={inputPropsWithAdornment}
                    autoComplete="off"
                    type={type}
                    maxLength={sz}
                    fieldType={fieldType}
                    beforeSend={this.beforeSend}
                    onChange={this.handleInputChange}
                    dispatchToWs={true}
                />
                <input
                    type="file"
                    ref={this.createRef('inputUpload')}
                    onChange={this.onChangeFile}
                    accept={'.txt'}
                    className={classes.inputHidden}
                />
            </Fragment>
        )
    }
}

export default withStyles(styles)(withWS(TextFieldUpload))
