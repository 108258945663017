import makeStyles from '@material-ui/core/styles/makeStyles'
import { styles } from '@nerus/styles/components/formulario/descricao'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import Label from './Label'

export const useStyles = makeStyles(styles)

const Description = ({ text }) => {
    const classes = useStyles()
    return (
        <div
            className={clsx({
                [classes.root]: true,
            })}
        >
            <Label text={text} />
        </div>
    )
}

Description.propTypes = {
    text: PropTypes.string,
}

export default Description
