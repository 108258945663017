import { MenuItem, Switch, TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import keycodes from '@nerus/framework/common/Keycodes'
import BaseFlutuante from '@nerus/framework/components/Base/Flutuante'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import Button from '@nerus/framework/styled/Button'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

import useFilterTypes from '../../hooks/useFilterTypes'
import Actions from '../index'

const useStyles = makeStyles(() => ({}))

const getInputSample = ({ type }) => {
    switch (type?.type) {
        case 0:
            return `1.234,56`
        case 1:
            return `R$ 1.234,56`
        case 2:
            return `24/08/1990`
        case 3:
            return `23:59:59`
        case 4:
            return `08/1990`
    }
    return `Permite selecionar múltiplos valores`
}

const getOutputSample = ({ type, keepprec }) => {
    switch (type?.type) {
        case 0:
            return !keepprec ? 123456 : 1234.56
        case 1:
            return !keepprec ? 123456 : 1234.56
        case 2:
            return `19900824`
        case 3:
            return `86399`
        case 4:
            return `199008`
    }
    return `(1, 2, 3, 4, 5)`
}

export default function FilterForm({ filter, onShow, onSave }) {
    const types = useFilterTypes()
    const classes = useStyles()
    const [value, setValue] = useState(
        filter != {}
            ? {
                  ...filter,
                  type:
                      typeof filter.type === 'object'
                          ? filter.type.type
                          : filter.type || 0,
              }
            : { type: 0, prec: 2 }
    )

    const handleOnKeyDown = useCallback(event => {
        let isNum = event.keyCode >= 97 && event.keyCode <= 105
        isNum = isNum || (event.keyCode >= 48 && event.keyCode <= 57)
        const ignores = [8, 46]
        if (
            event.target.name === 'prec' &&
            !isNum &&
            ignores.indexOf(event.keyCode) === -1
        ) {
            event.preventDefault()
        }
    }, [])

    const handleOnChange = useCallback(
        event => {
            let value =
                event.target.type === 'checkbox'
                    ? event.target.checked
                    : event.target.value
            const name = event.target.name
            setValue(obj => {
                let extra = {}

                if (name === 'type') {
                    if (value?.type === 0) {
                        extra.prec = 0
                        extra.keepprec = 0
                    }
                    if (value?.type === 1) {
                        extra.prec = 2
                        extra.keepprec = 1
                    }
                }

                if (name === 'key') {
                    value = value.replace(/ /g, '_').toUpperCase()
                }

                return {
                    ...obj,
                    [name]: value,
                    ...extra,
                }
            })
        },
        [setValue]
    )

    const handleOnSave = useCallback(() => {
        onSave && onSave(value)
    }, [onSave, value])

    const onKeyDown = useCallback(
        event => {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                event.stopImmediatePropagation()
                onShow()
            }
        },
        [onShow]
    )

    useKeyboardTrap(onKeyDown, { capture: true })

    const title = useMemo(
        () =>
            typeof filter?.id === 'number' ? `Editar Filtro` : `Novo Filtro`,
        []
    )

    return (
        <BaseFlutuante title={title} onClose={onShow}>
            <Grid
                container
                spacing={2}
                style={{ marginBottom: 16, maxWidth: 800 }}
                alignItems="flex-start"
            >
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                label="Tipo de Filtro"
                                fullWidth
                                select
                                name="type"
                                value={value?.type}
                                autoFocus={!value?.id}
                                onChange={handleOnChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                defaultValue={types.list[0]}
                            >
                                {types?.map?.(type => (
                                    <MenuItem key={type.type} value={type.type}>
                                        {type.lbl}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                label="Nome do filtro para uso na SELECT"
                                name="key"
                                value={value?.key}
                                autoFocus={!!value?.id}
                                onChange={handleOnChange}
                                fullWidth
                                inputProps={{
                                    maxLength: 20,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Descrição que será mostrada para o usuário"
                                name="desc"
                                value={value?.desc}
                                inputProps={{
                                    maxLength: 255,
                                }}
                                onChange={handleOnChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Valor padrão (default)"
                                name="default"
                                value={value?.default}
                                onChange={handleOnChange}
                                fullWidth
                                inputProps={{
                                    maxLength: 255,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        {value?.type?.type === 0 || value?.type?.type === 1 ? (
                            <React.Fragment>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Precisão"
                                        name="prec"
                                        value={value?.prec}
                                        onChange={handleOnChange}
                                        onKeyDown={handleOnKeyDown}
                                        fullWidth
                                        inputProps={{
                                            maxLength: 1,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Switch
                                        name="keepprec"
                                        onChange={handleOnChange}
                                        checked={Boolean(value?.keepprec)}
                                    />{' '}
                                    Manter precisão na SELECT
                                </Grid>
                            </React.Fragment>
                        ) : null}
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <div>
                        <Typography style={{ marginBottom: 0 }}>
                            Exemplo do que o usuário irá digitar:
                        </Typography>
                        <Typography variant="caption">
                            {getInputSample(value)}
                        </Typography>
                    </div>
                    <div>
                        <Typography style={{ marginBottom: 0 }}>
                            Exemplo de valor na SELECT:
                        </Typography>
                        <Typography variant="caption">
                            {getOutputSample(value)}
                        </Typography>
                    </div>

                    <div>
                        <Typography style={{ marginBottom: 0 }}>
                            Exemplo de uso na SELECT:
                        </Typography>
                        <Typography variant="caption">
                            {value?.key
                                ? `SELECT * FROM tmp WHERE campo ${
                                      value?.type?.type > 4 ? `IN` : `=`
                                  } [${value.key}]`
                                : `Entre com a chave de uso na SELECT`}
                        </Typography>
                    </div>
                </Grid>
            </Grid>

            <Actions className={classes.modalActions}>
                <Button dialog color="danger" lbl="Cancelar" onClick={onShow} />
                <Button dialog primary lbl="Confirmar" onClick={handleOnSave} />
            </Actions>
        </BaseFlutuante>
    )
}

FilterForm.propTypes = {
    filter: PropTypes.shape({
        id: PropTypes.number || PropTypes.string,
        type: PropTypes.number || PropTypes.object,
    }),
    onShow: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
}
