import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import IconPaste from '@material-ui/icons/FileCopy'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { styles } from '../../../../theme/components/calculator'
import { toggleCalculator } from '../../../App/AppActions'
import { setFormulario } from '../../Eac/EacActions'
import BaseFlutuante from '../Flutuante/BaseFlutuante'
import Display from './Display'
import Key from './Key'

const debug = require('debug')('nerus:calculator')

const CalculatorOperations = {
    '/': (prevValue, nextValue) => prevValue / nextValue,
    '*': (prevValue, nextValue) => prevValue * nextValue,
    '+': (prevValue, nextValue) => prevValue + nextValue,
    '-': (prevValue, nextValue) => prevValue - nextValue,
    '=': (prevValue, nextValue) => nextValue,
}

export class Calculator extends Component {
    static propTypes = {
        eac: PropTypes.object,
        app: PropTypes.object,
        dispatch: PropTypes.func,
        classes: PropTypes.object,
    }

    static mapStateToProps = state => {
        return {
            app: state.app,
            eac: state.eac,
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            Formulario: props.eac.Formulario,
            value: null,
            displayValue: '0',
            operator: null,
            waitingForOperand: false,
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown)
    }

    clearAll = () => {
        this.setState({
            value: null,
            displayValue: '0',
            operator: null,
            waitingForOperand: false,
        })
    }

    clearDisplay = () => {
        this.setState({
            displayValue: '0',
        })
    }

    pasteValueFromDisplay = value => {
        let x = this.state.Formulario.componentList.map(function(object) {
            if (object.enabled === true) {
                object.texto = value
            }
            return object
        })

        this.setState(
            {
                Formulario: {
                    ...this.state.Formulario,
                    componentList: x,
                },
            },
            () => {
                this.props.dispatch(setFormulario(this.state.Formulario))
                this.props.dispatch(toggleCalculator())
            }
        )
    }

    clearLastChar = () => {
        const { displayValue } = this.state

        this.setState({
            displayValue:
                displayValue.substring(0, displayValue.length - 1) || '0',
        })
    }

    toggleSign = () => {
        const { displayValue } = this.state
        const newValue = parseFloat(displayValue) * -1

        this.setState({
            displayValue: String(newValue),
        })
    }

    inputPercent = () => {
        const { displayValue } = this.state
        const currentValue = parseFloat(displayValue)

        if (currentValue === 0) return

        const fixedDigits = displayValue.replace(/^-?\d*\.?/, '')
        const newValue = parseFloat(displayValue) / 100

        this.setState({
            displayValue: String(newValue.toFixed(fixedDigits.length + 2)),
        })
    }

    inputDot = () => {
        const { displayValue } = this.state

        if (!/\./.test(displayValue)) {
            this.setState({
                displayValue: displayValue + '.',
                waitingForOperand: false,
            })
        }
    }

    inputDigit = digit => {
        const { displayValue, waitingForOperand } = this.state

        if (waitingForOperand) {
            this.setState({
                displayValue: String(digit),
                waitingForOperand: false,
            })
        } else {
            this.setState({
                displayValue:
                    displayValue === '0' ? String(digit) : displayValue + digit,
            })
        }
    }

    performOperation = nextOperator => {
        const { value, displayValue, operator } = this.state
        const inputValue = parseFloat(displayValue)

        if (value == null) {
            this.setState({
                value: inputValue,
            })
        } else if (operator) {
            const currentValue = value || 0
            const newValue = CalculatorOperations[operator](
                currentValue,
                inputValue
            )

            this.setState({
                value: newValue,
                displayValue: String(newValue),
            })
        }

        this.setState({
            waitingForOperand: true,
            operator: nextOperator,
        })
    }

    handleKeyDown = event => {
        debug('keydown', event)

        event.preventDefault()
        let { key } = event

        if (key === 'Enter') key = '='

        if (/\d/.test(key)) {
            event.preventDefault()
            this.inputDigit(parseInt(key))
        } else if (key in CalculatorOperations) {
            event.preventDefault()
            this.performOperation(key)
        } else if (key === '.') {
            event.preventDefault()
            this.inputDot()
        } else if (key === '%') {
            event.preventDefault()
            this.inputPercent()
        } else if (key === 'Backspace') {
            event.preventDefault()
            this.clearLastChar()
        } else if (key === 'Clear') {
            event.preventDefault()

            if (this.state.displayValue !== '0') {
                this.clearDisplay()
            } else {
                this.clearAll()
            }
        }
    }

    onClose = () => this.props.dispatch(toggleCalculator())

    onPaste = displayValue => () => this.pasteValueFromDisplay(displayValue)

    onKeyClear = clearDisplay => () =>
        clearDisplay ? this.clearDisplay() : this.clearAll()

    onDigit = digit => () => this.inputDigit(digit)

    onOperation = op => () => this.performOperation(op)

    render() {
        const {
            state: { displayValue },
            props: { classes },
        } = this
        const clearDisplay = displayValue !== '0'
        const clearText = clearDisplay ? 'C' : 'AC'

        const styles = {
            smallIcon: {
                width: 16,
                height: 16,
            },
            small: {
                width: 32,
                height: 32,
                padding: 6,
            },
        }

        return (
            <BaseFlutuante
                title="Calculadora"
                handleClose={this.onClose}
                nospace
            >
                <div className={classes.root}>
                    <IconButton
                        iconStyle={styles.smallIcon}
                        style={styles.small}
                        onClick={this.onPaste(displayValue)}
                    >
                        <IconPaste color={'#fff'} />
                    </IconButton>
                    <Display classes={classes} value={displayValue} />
                    <div className={classes.keypad}>
                        <div className={classes.inputKeys}>
                            <div className={classes.funcKeys}>
                                <Key
                                    className={clsx(
                                        classes.button,
                                        classes.buttonGray
                                    )}
                                    onPress={this.onKeyClear(clearDisplay)}
                                >
                                    {clearText}
                                </Key>
                                <Key
                                    className={clsx(
                                        classes.button,
                                        classes.buttonGray
                                    )}
                                    onPress={this.toggleSign}
                                >
                                    ±
                                </Key>
                                <Key
                                    className={clsx(
                                        classes.button,
                                        classes.buttonGray
                                    )}
                                    onPress={this.inputPercent}
                                >
                                    %
                                </Key>
                            </div>
                            <div className={classes.digitKeys}>
                                <Key
                                    className={clsx(
                                        classes.button,
                                        classes.zeroKey
                                    )}
                                    onPress={this.onDigit(0)}
                                >
                                    0
                                </Key>
                                <Key
                                    className={clsx(
                                        classes.button,
                                        classes.dotKey
                                    )}
                                    onPress={this.inputDot}
                                >
                                    ●
                                </Key>
                                <Key
                                    className={clsx(classes.button)}
                                    onPress={this.onDigit(1)}
                                >
                                    1
                                </Key>
                                <Key
                                    className={clsx(classes.button)}
                                    onPress={this.onDigit(2)}
                                >
                                    2
                                </Key>
                                <Key
                                    className={clsx(classes.button)}
                                    onPress={this.onDigit(3)}
                                >
                                    3
                                </Key>
                                <Key
                                    className={clsx(classes.button)}
                                    onPress={this.onDigit(4)}
                                >
                                    4
                                </Key>
                                <Key
                                    className={clsx(classes.button)}
                                    onPress={this.onDigit(5)}
                                >
                                    5
                                </Key>
                                <Key
                                    className={clsx(classes.button)}
                                    onPress={this.onDigit(6)}
                                >
                                    6
                                </Key>
                                <Key
                                    className={clsx(classes.button)}
                                    onPress={this.onDigit(7)}
                                >
                                    7
                                </Key>
                                <Key
                                    className={clsx(classes.button)}
                                    onPress={this.onDigit(8)}
                                >
                                    8
                                </Key>
                                <Key
                                    className={clsx(classes.button)}
                                    onPress={this.onDigit(9)}
                                >
                                    9
                                </Key>
                            </div>
                        </div>
                        <div className={classes.opKeys}>
                            <Key
                                className={clsx(
                                    classes.button,
                                    classes.buttonRed,
                                    classes.margin
                                )}
                                onPress={this.onOperation('/')}
                            >
                                ÷
                            </Key>
                            <Key
                                className={clsx(
                                    classes.button,
                                    classes.buttonRed,
                                    classes.margin
                                )}
                                onPress={this.onOperation('*')}
                            >
                                ×
                            </Key>
                            <Key
                                className={clsx(
                                    classes.button,
                                    classes.buttonRed,
                                    classes.margin
                                )}
                                onPress={this.onOperation('-')}
                            >
                                −
                            </Key>
                            <Key
                                className={clsx(
                                    classes.button,
                                    classes.buttonRed,
                                    classes.margin
                                )}
                                onPress={this.onOperation('+')}
                            >
                                +
                            </Key>
                            <Key
                                className={clsx(
                                    classes.button,
                                    classes.buttonRed,
                                    classes.margin
                                )}
                                onPress={this.onOperation('=')}
                            >
                                =
                            </Key>
                        </div>
                    </div>
                </div>
            </BaseFlutuante>
        )
    }
}

export default connect(Calculator.mapStateToProps)(
    withStyles(styles)(Calculator)
)
