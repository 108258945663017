import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import Button from '@nerus/framework/styled/Button'
import PropTypes from 'prop-types'
import React from 'react'

import BaseFlutuante from '../../../NerusWeb/Components/Flutuante/BaseFlutuante'

const useStyles = makeStyles(theme => ({
    actionsRight: {
        textAlign: 'right',
        padding: theme.spacing(1),
        flex: 1,
    },
    formBox: {
        width: 600,
    },
    fieldBox: {
        width: '50%',
    },
}))

export default function RemoveRecord({
    onClose,
    onSubmit,
    message = 'Deseja realmente retirar este registro?',
}) {
    const classes = useStyles()
    return (
        <BaseFlutuante title={`NerusWeb`} handleClose={onClose}>
            <Box className={classes.formBox}>
                <Typography variant="body1">{message}</Typography>
            </Box>
            <Box className={classes.actionsRight}>
                <Button
                    variant={'rounded'}
                    lbl={'Cancelar'}
                    dialog
                    onClick={onClose}
                />
                <Button
                    data-testid="btnRemoveRecord"
                    primary
                    variant={'rounded'}
                    lbl={'Retirar'}
                    dialog
                    onClick={onSubmit}
                />
            </Box>
        </BaseFlutuante>
    )
}

RemoveRecord.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    message: PropTypes.string,
}
