import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import keycodes from '@nerus/framework/common/Keycodes'
import BaseFlutuante from '@nerus/framework/components/Base/Flutuante'
import IconButton from '@nerus/framework/components/Editor/Estrutura/IconButton.jsx'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import Button from '@nerus/framework/styled/Button'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useHistory } from 'react-router'

import useFetchMenu from '../hooks/useFetchMenu'
import Actions from './index'

const useStyles = makeStyles(() => ({
    content: {
        overflowX: 'hidden',
        overflowY: 'auto',
        display: 'flex',
        height: '100%',
        flex: 1,
    },
    container: {
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
    },
    input: {
        marginRight: 8,
        marginLeft: 8,
        flex: 1,
    },
    icon: {
        marginRight: 8,
    },
    text: {
        cursor: 'default',
    },
    separator: {
        backgroundColor: 'rgba(0,0,0,0.25)',
        margin: '0 8px',
        height: '100%',
        maxWidth: 1,
        flex: 1,
    },
    inputUnderline: {
        '&::before': {
            border: 'none !important',
        },
    },
    // modals
    list: {
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        margin: 0,
    },
    modalActions: {
        marginBottom: 8,
        marginTop: 16,
    },
}))

function flatten(arr) {
    return arr.reduce((red, current) => {
        if (current.children) {
            const children = current.children
            delete current.children
            return [...red, current, ...flatten(children)]
        }

        return [...red, current]
    }, [])
}

function arrange(data) {
    let ref = {}
    const arranged = data.reduce((index, current) => {
        ref[current.id] = current

        if (!current.menu_id) {
            current.depth = 0
            return [...index, current]
        }

        if (ref[current.menu_id]) {
            if (!ref[current.menu_id].children) {
                ref[current.menu_id].children = []
            }

            current.depth = ref[current.menu_id].depth + 1

            ref[current.menu_id].children.push(current)
        }

        return index
    }, [])
    ref = null
    return arranged
}

export default function MenuSelector({ dashboard, onSave }) {
    const classes = useStyles()
    const { data } = useFetchMenu()
    const [value, setValue] = useState(null)
    const [show, setShow] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (dashboard?.menu) {
            setValue(dashboard?.menu?.id)
        }
    }, [setValue, dashboard])

    const menu = useMemo(() => {
        if (!data?.allMenu) {
            return []
        }

        return flatten(arrange(data.allMenu))
    }, [data])

    const selectedMenu = useMemo(() => {
        return menu.filter(m => m.id === value).pop()
    }, [menu, value])

    const handleOnChange = useCallback(event => {
        setValue(event.target.value)
    }, [])

    const renderValue = useCallback(
        value => {
            if (!value) {
                return 'Selecione...'
            }

            const i = menu.filter(i => i.id === value)

            if (i && i[0]) {
                return i[0].title
            }

            return 'Selecione...'
        },
        [menu]
    )

    const handleOnSave = useCallback(() => {
        onSave && onSave(selectedMenu)
        setShow(show => !show)
    }, [onSave, selectedMenu])

    const onShow = useCallback(() => {
        setShow(show => !show)
        setValue(dashboard?.menu?.id)
    }, [setShow, setValue, dashboard])

    const onKeyDown = useCallback(
        event => {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                if (show) {
                    event.stopImmediatePropagation()
                    onShow()
                }
            }
        },
        [history, setValue, dashboard, show, setShow]
    )

    useKeyboardTrap(onKeyDown, { capture: true })

    return (
        <Fragment>
            <IconButton
                size="small"
                className={classes.icon}
                tooltip="Menu"
                onClick={onShow}
            />

            <Typography className={clsx(classes.icon, classes.text)}>
                {dashboard?.menu?.title}
            </Typography>

            {show ? (
                <BaseFlutuante title="Selecione o menu" onClose={onShow}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="menu-id-label">
                            Menu *
                        </InputLabel>
                        <Select
                            className={classes.fieldBox}
                            renderValue={renderValue}
                            onChange={handleOnChange}
                            value={value}
                            displayEmpty
                            required
                        >
                            <MenuItem value={null}>Não exibir</MenuItem>
                            {menu.map(reg => (
                                <MenuItem key={reg.id} value={reg.id}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                reg.depth > 0
                                                    ? '&nbsp;&nbsp;'.repeat(
                                                          reg.depth || 0
                                                      )
                                                    : '',
                                        }}
                                    />
                                    {reg.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Actions className={classes.modalActions}>
                        <Button
                            dialog
                            color="danger"
                            lbl="Cancelar"
                            onClick={onShow}
                        />
                        <Button
                            dialog
                            primary
                            lbl="Confirmar"
                            onClick={handleOnSave}
                        />
                    </Actions>
                </BaseFlutuante>
            ) : null}
        </Fragment>
    )
}

MenuSelector.propTypes = {
    dashboard: PropTypes.shape({
        menu: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
        }),
    }),
    onSave: PropTypes.func.isRequired,
}
