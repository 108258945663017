import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Row from './Row'

export default class Body extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        classes: PropTypes.object.isRequired,
        legend: PropTypes.array,
        onFocus: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        onDoubleClick: PropTypes.func,
        types: PropTypes.array.isRequired,
        activeRow: PropTypes.number,
        mouseLock: PropTypes.bool,
        colsType: PropTypes.array,
        actions: PropTypes.array,
        activeColumnSearch: PropTypes.number,
        onDrag: PropTypes.func,
        draggable: PropTypes.bool,
        isViewOnly: PropTypes.bool,
    }

    onFocus = (object, i) => () => this.props.onFocus.call(object, i)
    onClick = (object, i) => () => {
        const { mouseLock, onClick } = this.props
        if (mouseLock !== true) {
            onClick.call(object, i)
        }
    }

    onDoubleClick = (object, i) => () => {
        const { mouseLock, onDoubleClick } = this.props
        if (mouseLock !== true) {
            onDoubleClick.call(object, i)
        }
    }

    dragged = null
    overIndex = null

    onDragStart = (e, index) => {
        this.dragged = index
        this.overIndex = null
        e.dataTransfer.effectAllowed = 'move'
        e.dataTransfer.setData('text/html', e.target.parentNode)
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20)
    }

    onDragEnd = () => {
        const { onDrag } = this.props

        onDrag &&
            onDrag(
                this.dragged === this.overIndex,
                true,
                this.dragged,
                this.overIndex
            )

        this.dragged = null
        this.overIndex = null
    }

    onDragOver = overIndex => {
        const { onDrag } = this.props
        this.overIndex = overIndex
        return (
            onDrag &&
            onDrag(this.dragged === overIndex, false, this.dragged, overIndex)
        )
    }

    render() {
        const {
            types,
            data,
            actions,
            legend,
            classes,
            activeRow,
            activeColumnSearch,
            onDrag,
            draggable,
            isViewOnly,
        } = this.props

        if (!data || !data.length) {
            return null
        }

        return (
            <tbody>
                {data.map((object, i) => {
                    return (
                        <Row
                            key={i}
                            ref={'row-' + i}
                            legend={legend || []}
                            index={i}
                            actions={actions}
                            data={object}
                            classes={classes}
                            types={types}
                            onDrag={onDrag}
                            draggable={draggable}
                            isViewOnly={isViewOnly}
                            onDragStart={this.onDragStart}
                            onDragOver={this.onDragOver}
                            onDragEnd={this.onDragEnd}
                            activeColumnSearch={activeColumnSearch}
                            onFocus={this.onFocus(object, i)}
                            onClick={this.onClick(object, i)}
                            onDoubleClick={this.onDoubleClick(object, i)}
                            className={
                                activeRow === i && !isViewOnly
                                    ? classes.tableRowActive
                                    : ''
                            }
                        />
                    )
                })}
            </tbody>
        )
    }
}
