import PropTypes from 'prop-types'

import { TextFieldNegativableShort } from './NegativableShort'

/**
 * Componente TextFieldPerc
 *
 * Rec: REC_PERC
 *
 * Renderiza um campo de texto que tem formatação de casas
 * decimais
 */
export class TextFieldPerc extends TextFieldNegativableShort {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    }

    fixedDecimalScale = true
    allowNegative = true
    useFormat = false

    beforeSend = value => {
        if (!value) {
            return '0'
        }

        return value
    }
}

export default TextFieldPerc
