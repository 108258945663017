import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'

export const UPDATE_PICTURES = gql`
    mutation updatePictures($pictures: [PictureInput]) {
        updatePictures(pictures: $pictures) {
            error
            message
        }
    }
`

export default Component => {
    return graphql(UPDATE_PICTURES, {
        name: 'updatePictures',
        fetchPolicy: 'cache-and-network',
    })(Component)
}
