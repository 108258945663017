export const styles = () => ({
    root: {
        padding: `0.8571428571428571em`,
        display: 'flex',
        borderRadius: 2,
        alignItems: 'center',
        boxSizing: `border-box`,
        justifyContent: 'center',
        border: `1px solid #E0E0E0`,
        maxHeight: `3.2142857142857144em`,
        marginRight: `0.5714285714285714em`,
        '&:last-child': {
            marginRight: 0,
        },
        flexGrow: 1,
    },
    colorBall: {
        display: 'inline-block',
        width: `1em`,
        height: `1em`,
        marginLeft: `1.1428571428571428em`,
        borderRadius: '3.5714285714285716em 3.5714285714285716em',
        backgroundColor: '#C4C4C4',
    },
    colorConnected: {
        backgroundColor: '#11BD0F',
    },
    colorWarning: {
        backgroundColor: '#FF9900',
    },
    colorError: {
        backgroundColor: '#DE3500',
    },
    noMargin: { margin: 0 },
})
