import PropTypes from 'prop-types'
import React from 'react'

export default function PendenciaIcon({ fill = '#ffffff' }) {
    return (
        <svg
            width="23"
            height="27"
            viewBox="0 0 23 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.6111 14.0556C13.0844 14.0556 10.2222 16.9178 10.2222 20.4444C10.2222 23.9711 13.0844 26.8333 16.6111 26.8333C20.1378 26.8333 23 23.9711 23 20.4444C23 16.9178 20.1378 14.0556 16.6111 14.0556ZM18.7194 23.4472L15.9722 20.7V16.6111H17.25V20.1761L19.6139 22.54L18.7194 23.4472ZM17.8889 2.55556H13.8256C13.2889 1.07333 11.8833 0 10.2222 0C8.56111 0 7.15556 1.07333 6.61889 2.55556H2.55556C1.15 2.55556 0 3.70556 0 5.11111V24.2778C0 25.6833 1.15 26.8333 2.55556 26.8333H10.3628C9.60681 26.0996 8.99182 25.2334 8.54833 24.2778H2.55556V5.11111H5.11111V8.94444H15.3333V5.11111H17.8889V11.6022C18.7961 11.73 19.6522 11.9983 20.4444 12.3689V5.11111C20.4444 3.70556 19.2944 2.55556 17.8889 2.55556ZM10.2222 5.11111C9.51944 5.11111 8.94444 4.53611 8.94444 3.83333C8.94444 3.13056 9.51944 2.55556 10.2222 2.55556C10.925 2.55556 11.5 3.13056 11.5 3.83333C11.5 4.53611 10.925 5.11111 10.2222 5.11111Z"
                fill={fill}
            />
        </svg>
    )
}

PendenciaIcon.propTypes = {
    fill: PropTypes.string,
}
