import { LinearProgress } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { pdvManager } from './PdvServiceManager'

class DllUpdate extends PureComponent {
    state = {
        connected: pdvManager.current && pdvManager.current.isConnected(),
        hide: true,
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
    }

    static events = {
        message(message) {
            if (message?.type === 'update') {
                const { data } = message
                this.setState({ ...data, hide: false })
            }
            if (message?.type === 'connected') {
                this.setState({ hide: true })
            }
        },
    }

    constructor(props) {
        super(props)

        this.eventsBind = {}
        Object.keys(DllUpdate.events).forEach(event => {
            this.eventsBind[event] = DllUpdate.events[event].bind(this)
        })
    }

    componentDidMount() {
        Object.keys(this.eventsBind).forEach(event => {
            pdvManager.current.off(event, this.eventsBind[event])
            pdvManager.current.on(event, this.eventsBind[event])
        })
    }

    componentWillUnmount() {
        Object.keys(this.eventsBind).forEach(event => {
            pdvManager.current.off(event, this.eventsBind[event])
        })
    }

    render() {
        const {
            props: { classes },
            state: { currentName, current = 0, total = 0, hide = false },
        } = this

        if (hide) {
            return null
        }

        return (
            <div className={classes.root}>
                <div className={classes.box}>
                    <div className={classes.updateText}>
                        Atualizando DLL{' '}
                        <span className={classes.bold}>{current}</span> de{' '}
                        <span className={classes.bold}>{total}</span>
                    </div>
                    <LinearProgress
                        color="primary"
                        variant="determinate"
                        value={
                            total > 0 && current > 0
                                ? Math.round(current / total)
                                : 0
                        }
                    />
                    <div className={classes.sync}>
                        Sincronizando DLL{' '}
                        <span className={classes.bold}>{currentName}</span>
                        ...
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        marginRight: theme.spacing(2),
        '& *': { margin: 0 },
        background: 'rgba(255,255,255,0.5)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    box: {
        backgroundColor: '#ffffff',
        boxShadow: '0 0 5px -2px #000000',
        borderRadius: 4,
        padding: `8px 16px`,
    },
    updateText: {
        fontSize: 14,
        textAlign: 'center',
        color: '#333333',
        margin: 4,
    },
    sync: {
        fontSize: 10,
        textAlign: 'center',
        color: '#909090',
        margin: 4,
    },
    bold: {
        fontWeight: `bold`,
    },
}))(DllUpdate)
