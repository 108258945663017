export const styles = theme => ({
    root: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        display: 'flex',
        backgroundColor: '#EBEBEB',
        marginLeft: -44,
        marginRight: -44,
        width: 'calc(100% + 88px)',
        padding: '0 44px',
    },
    column: {
        padding: theme.spacing(0, 4, 1),
        borderRight: '1px solid #E0E0E0',
        '&:first-child': {
            paddingLeft: 0,
        },
        '&:last-child': {
            borderRight: 'none',
            paddingRight: 0,
        },
        flexGrow: 1,
    },
    connections: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: `0.8571428571428571em`,
        fontWeight: 'bold',
        margin: '0.5714285714285714em 0',
        color: '#AFAFAF',
        lineHeight: '1.1428571428571428em',
    },
})
