import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class Header extends Component {
    static propTypes = {
        actions: PropTypes.array,
        data: PropTypes.array.isRequired,
        classes: PropTypes.object.isRequired,
        activeColumnSearch: PropTypes.number,
        types: PropTypes.array,
        draggable: PropTypes.bool,
        handleOrdering: PropTypes.func,
        sortIcon: PropTypes.object,
    }

    render() {
        const {
            actions,
            data,
            activeColumnSearch,
            types,
            classes,
            draggable,
            handleOrdering,
            sortIcon,
        } = this.props

        if (!data || !data.length) {
            return null
        }

        const parsedData = [...data]
        if (actions && actions.length) {
            parsedData.push('Ações')
        }

        const total = types.reduce((a, b) => {
            return a + b.sz
        }, 0)

        const percentages = types.map(t => `${(t.sz / total) * 100}%`)

        const onClickOrdering = header => () => handleOrdering(header)

        return (
            <thead>
                <tr>
                    {draggable ? (
                        <th
                            className={clsx({
                                [classes.tableHeader]: true,
                            })}
                        >
                            &nbsp;
                        </th>
                    ) : null}
                    {parsedData.map((header, i) => {
                        return (
                            <th
                                className={clsx({
                                    [classes.tableHeader]: true,
                                    [classes.tableInSearch]:
                                        activeColumnSearch === i,
                                })}
                                key={'th' + i}
                                style={{ width: percentages[i] }}
                            >
                                {handleOrdering ? (
                                    <>
                                        <IconButton
                                            style={{
                                                padding: 1,
                                            }}
                                            onClick={onClickOrdering(header)}
                                        >
                                            {sortIcon}
                                        </IconButton>
                                        {header}
                                    </>
                                ) : (
                                    header
                                )}
                            </th>
                        )
                    })}
                </tr>
            </thead>
        )
    }
}
