export function zeroPad(num, numZeros) {
    const n = Math.abs(num)
    const zeros = Math.max(0, numZeros - Math.floor(n).toString().length)
    let zeroString = Math.pow(10, zeros)
        .toString()
        .substr(1)
    if (num < 0) {
        zeroString = '-' + zeroString
    }
    return zeroString + n + ''
}

export default class TextFieldInterface {
    type = 'text'
    isRequired = false
    toEnd = false
    zeroPad = false
    zeroShow = false
    mask = {}
    jump = true

    constructor(element) {
        this.setMask(element)
    }

    setMask(element = {}) {
        this.mask = element

        if (element) {
            // if (hasModifier(mask, recTypesModifier.REC_PAD_ZERO)) {
            if (element.pz) {
                this.zeroPad = true
            }
            // if (hasModifier(mask, recTypesModifier.REC_SHOW_ZERO)) {
            if (element.zero) {
                this.zeroShow = true
            }
        }
    }

    formatter(text) {
        return text?.trimStart ? text.trimStart() : text
    }

    hasModification() {
        return false
    }

    modifier(text) {
        if (this.zeroPad) {
            return zeroPad(text, this.mask.sz)
        }

        return text
    }

    dontJump() {
        this.jump = false
        return this
    }

    shouldJump() {
        return false
    }

    required() {
        this.isRequired = true
        return this
    }
}
