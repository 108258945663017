import BaseFixa from '@nerus/framework/components/Base/Fixa'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import Content from '../../../NerusWeb/Components/Content'
import Menu from '../../../NerusWeb/Components/Menu'
import TopBar from '../../../NerusWeb/Components/TopBar'

function NerusITLayout({ children, onBack }) {
    return (
        <Fragment>
            <TopBar onBack={onBack} />

            <Menu />

            <Content>
                <BaseFixa>
                    <Fragment>{children}</Fragment>
                </BaseFixa>
            </Content>
        </Fragment>
    )
}

NerusITLayout.propTypes = {
    children: PropTypes.node,
    onBack: PropTypes.func,
}

export default NerusITLayout
