const __html = `<div style="max-width: 580px; text-align: justify;">
<p>Você pode criar uma consulta utilizando-se a linguagem SQL. Neste caso, será permitida apenas a criação de consultas SELECT (select * from tabela).</p>
<p>As consultas relativas a remoção/inserção de dados, alterações/ remoção de tabelas, bem como atualizações em bancos de dados não serão permitidas (delete, insert, update, drop, create e alter). Para maior comodidade na construção da sua consulta SQL, você poderá utilizar algumas variáveis pré-definidas.</p>

<p>As variáveis existentes são:</p>

<dl>
  <dt>[ONTEM]</dt> <dd>Data do dia anterior (formato yyyymmdd)</dd>
  <dt>[HOJE]</dt> <dd>Data do dia (formato yyyymmdd)</dd>
  <dt>[AMANHA]</dt> <dd>Data do dia seguinte (formato yyyymmdd)</dd>
  <dt>[ANOMES]</dt> <dd>Fornece a data no formato yyyymm</dd>
  <dt>[INICIOSEMANA]</dt> <dd>Data do fim da semana corrente (formato yyyymmdd)</dd>
  <dt>[FIMSEMANA]</dt> <dd>Data do fim da semana corrente (formato yyyymmdd)</dd>
  <dt>[HORA]</dt> <dd>Número de segundos passados desde a meia noite</dd>
  <dt>[INICIOMES]</dt> <dd>Data do início do mês corrente (formato yyyymmdd)</dd>
  <dt>[FIMMES]</dt> <dd>Data do fim do mês corrente (formato yyyymmdd)</dd>
  <dt>[INICIOANO]</dt> <dd>Data do início do ano corrente (formato yyyymmdd)</dd>
  <dt>[FIMANO]</dt> <dd>Data do fim do ano corrente (formato yyyymmdd)</dd>
  <dt>[USUARIO_ATUAL]</dt> <dd>Usuário atualmente acessando a SELECT no formato inteiro</dd>
  <dt>[USUARIO_LOGIN_ATUAL]</dt> <dd>Usuário atualmente acessando a SELECT no formato "login do usuário"</dd>
  <dt>[BANCO]</dt> <dd>Nome do banco de dados atual</dd>
  <dt>[BANCOPDV]</dt> <dd>Nome do banco de dados atual do PDV</dd>
</dl>

<blockquote>SELECT sum((price/100) * (qtty / 1000)) AS Subtotal
FROM sqldados.xalog2
WHERE storeno = 1 AND date = [HOJE]
ORDER BY storeno, date;</blockquote>

<!--
<p>Você poderá fazer uso de todas as variáveis pré-definidas ou definir as suas próprias variáveis. Para isto, você deverá adicionar uma seção para a definição de suas variávies antes do comando SELECT. Veja a sintáxe a seguir (observe a alteração na sintáxe, em relação às versões 1.x):</p>
<blockquote>%vars
$variavel={:tipo:texto[,tamanho][;default[;s|n]]}
%</blockquote>

<p>Onde:</p>

<ul>
    <li><strong>$variavel</strong> é o nome da variável que será utilizada no comando SELECT</li>
    <li><strong>tipo</strong> é o tipo da variável (veja na tabela a seguir os possíveis tipos)</li>
    <li><strong>texto</strong> é uma descrição que aparecerá no momento de informar o valor da variável</li>
    <li><strong>tamanho</strong> é o tamanho do campo para o valor da variável</li>
    <li><strong>default</strong> é o valor padrão que será exibido no campo da variável</li>
    <li><strong>s|n</strong> indica se o preenchimento do valor da variável é obrigatório ou não (s ou n)</li>
</ul>

<div class="info">
=> Na definição de variável acima, os símbolos '[' ']' indicam que o parâmetro é opcional, isto é, pode ser informado ou não. Vale ressaltar que a ordem dos parâmetros bem como a sintáxe acima deve ser preservada.
</div>

<p>* Importante:</p>

<ul>
    <li>As variáveis do tipo $variavel possuem caso-sensitividade, isto é, a variável $var é diferente das variáveis $VAR, $Var, $vAr, etc.</li>
    <li>Tabela de tipos de variáveis:
        <dl>
            <dt>c</dt> <dd>Textosúmeros inteiros</dd>
            <dt>f</dt> <dd>Números reais</dd>
            <dt>d</dt> <dd>Data</dd>
            <dt>$</dt> <dd>Valor em dinheiro (centavos separados por vírgula)</dd>
            <dt>h</dt> <dd>HorayMes/Ano</dd>
            <dt>prd</dt> <dd>No. Produto</dd>
            <dt>corp</dt> <dd>Grupo de lojas</dd>
            <dt>vendgr</dt> <dd>Grupo de Fornecedores</dd>
            <dt>custp</dt> <dd>No. Cliente</dd>
            <dt>vend</dt> <dd>No. Fornecedor</dd>
            <dt>bank</dt> <dd>No. Banco</dd>
            <dt>cl</dt> <dd>No. Centro Lucro</dd>
            <dt>emp</dt> <dd>No. Funcionario</dd>
            <dt>store</dt> <dd>No. Loja</dd>
            <dt>type</dt> <dd>No. Tipo</dd>
            <dt>acc</dt> <dd>No. Conta Razao</dd>
            <dt>x</dt> <dd>Senha</dd>
            <dt>fxatp</dt> <dd>No.Tipo Transacao</dd>
            <dt>m</dt> <dd>variavel do tipo menu.</dd>
        </dl>
    </li>
</ul>

<p>Sintaxe do menu:</p>

<blockquote>$var1={:m:texto,opções][;default[;s|n]]}</blockquote>

<p>O exemplo a seguir ilustra a utilização destas variáveis (a seção de variáveis deverá aparecer sempre antes da SELECT:</p>

<blockquote>%vars
$loja = {:store:Informe o número da loja}
%
SELECT sum((price/100) * (qtty / 1000)) AS Subtotal
FROM sqldados.xalog2
WHERE storeno = [$loja]
AND date = [hoje]
ORDER BY storeno,date;</blockquote>
<p>Quando a SELECT acima for executada você deverá informar um valor para a sua variável $loja. Observe que na definição da variável não é utilizado os colchetes ($var), porém, quando a variável é utilizada na SELECT esta deve estar entre colchetes ([$var]).</p>
    -->
<h4>Recursos avançados para a criação de SELECTS</h4>

<p>Existem informações que não podem ser extraídas das tabelas utilizando-se uma única SQL, por exemplo, se você deseja ver o estoque existente em todas as lojas desprezando os produtos cujos estoques são negativos (neste caso se em uma loja o estoque do produto 1 com a grade 1 for igual a 2 e para o mesmo produto com a grade 2 for -4, o resultado do estoque para este produto será -2, e deverá ser desprezado no cálculo do estoque total para esta loja).</p>
<p>Portanto, para extrair esta informação torna-se necessária a utilização de uma tabela temporária onde estariam armazenados a soma dos estoques para cada produto existente em cada uma das lojas. Além disto, nesta tabela não pode aparecer os produtos cujos estoques finais são negativos, i.e., deve-se aplicar uma condição para restringir a entrada destes produtos na tabela temporária. Tendo disponível a tabela acima (resultado parcial), é possível calcular-se o estoque total para cada loja, considerando apenas os produtos que existem em quantidades positivas (a tabela temporária não possui estoques negativos). Neste caso, considera-se todas as grades existentes para cada um dos produtos.</p>

<p>A seguir encontra-se um exemplo com a utilização de tabelas temporárias para solucionar o problema proposto anteriormente.</p>
<p><strong>Exemplo:</strong> Resolução do problema do estoque utilizando tabela temporária: </p>
<blockquote>#
    SELECT store.no
    AS Loja, prdno AS Produto, SUM(qtty_fiscal/1000)
    AS Estoque
    FROM store
    LEFT JOIN stk
    GROUP BY Loja, Produto
    HAVING Estoque >= 0;
    #
    SELECT Loja,
    SUM(Estoque) AS "Estoque Final"
    FROM [TABELA1]
    GROUP BY Loja;
</blockquote>
<p>Observe que você deverá iniciar a edição com o símbolo '#' e separar as duas SELECTs por meio deste mesmo símbolo. Além disso, você deve se referir ao resultado gerado pela primeira SELECT através da variável [tabela1]. Vale lembrar que é permitido o uso de quaisquer variáveis descritas no início da ajuda. Vale ressaltar que não há limitações quanto ao número de SELECTs utilizadas, bastando que as mesmas estejam separadas pelo símbolo '#'.</p>
<!--    - Utilizando o comando SELECT_LIST
    A utilização do SELECT_LIST tem como objetivo a geração, a partir de uma SELECT, de uma lista de valores separados por vírgulas. Sendo que estas listas de valores estão disponíveis através das variáveis [lista#], onde # se refere ao número de ordem da SELECT. Veja o exemplo a seguir que exibe a utlização do comando SELECT_LIST.
    - Exemplo de utilização do comando SELECT_LIST
    #
    SELECT_LIST no
    FROM sqldados.store
    WHERE
    uf = "MG";
    #
    SELECT storeno AS Loja,
    SUM(qtty / 1000 * price / 100)
    FROM sqldados.xalog2
    WHERE
    storeno IN ([lista1]) AND date = [hoje];
    - O comando SELECT_VARS
    Através desse novo comando, é possível a criação de variáveis 'genéricas' que podem ser usadas em qualquer lugar dentro de outras SELECTs. Exemplos de uso desse novo recurso:
    1) Títulos de colunas com mês/ano calculados automaticamente: Suponha um relatório que mostre as vendas dos últimos 3 meses. Através do SELECT_VARS pode-se fazer com que os títulos das colunas sejam calculados e mostrados automaticamente de acordo com o dia da emissão do relatório. O arquivo para o QuerySQL ficaria da seguinte forma:
    #
    SELECT_VARS
    DATE_FORMAT(NOW(), "%m/%Y") as Mes1,
    DATE_FORMAT(DATE_SUB(NOW(), INTERVAL 1 MONTH), "%m/%Y") as Mes2,
    DATE_FORMAT(DATE_SUB(NOW(), INTERVAL 2 MONTH), "%m/%Y") as Mes3;
    #
    SELECT ... as '[VAR1]',
    ... as '[VAR2]',
    ... as '[VAR3]'
    FROM ...
    WHERE ...;
    Explicando o exemplo acima:
    - A função NOW() retorna a data e a hora atual;
    - A função DATE_FORMAT usada com "%m/%Y" retorna o mês/ano,
    no formato mm/aaaa (exemplo: 03/2005);
    - A função DATE_SUB efetua uma conta entre datas - no exemplo
    acima, foram calculados os 2 últimos meses (INTERVAL 1 MONTH
    e INTERVAL 2 MONTH);
    - Para cada coluna existente no comando SELECT_VARS, será
    criada uma variável correspondente, que pode ser acessada
    através do campo [VARx], onde x é a coluna da variável.
    - No exemplo acima, os títulos das 3 colunas seriam impressos
    da seguinte forma:
    03/2005 02/2005 01/2005
    2) Fórmulas grandes ou complexas que precisem ser utilizadas várias vezes em uma ou mais SELECTs: Suponha um relatório que mostre as vendas de produtos e o Lucro Bruto (Venda - CMV), tudo baseado na tabela xalog2. O cálculo da venda por produto é baseada na seguinte fórmula:
    (((xalog2.qtty/1000)*ABS(xalog2.price))-xalog2.discount)
    Atraves do SELECT_VARS pode-se fazer com que essa fórmula seja associada a uma variável e a mesma pode ser facilmente utilizada por todas as SELECTs, aumentando a clareza e a documentação da SELECT. Detalhe importante: para o caso de fórmulas, devem existir aspas simples no início e no final da fórmula. Isso porque na verdade a variável criada pelo SELECT_VARS irá guardar o texto da fórmula, e não o resultado. QuerySQL ficaria da seguinte forma:
    %vars
    $loja = {:store:No.da Loja;1;S}
    $dataInicial = {:d:Data Inicial}
    $dataFinal = {:d:Data Final}
    %
    #
    SELECT_VARS
    '(((xalog2.qtty/1000)*xalog2.price) - xalog2.discount)'
    #
    SELECT
    xalog2.prdno as Codigo,
    SUM(xalog2.qtty) as Quantidade,
    SUM([VAR1]) as Venda,
    SUM([VAR1]) - SUM((xalog2.qtty)*xalog2.cost_cm)/100000 as LucroBruto
    FROM sqldados.xalog2
    WHERE xalog2.storeno = [$loja] AND
    xalog2.date BETWEEN [$dataInicial] AND [$dataFinal]
    GROUP BY xalog2.prdno;
-->
</div>`

export default {
    __html,
}
