import ActionPrev from '@material-ui/icons/KeyboardArrowLeft'
import ActionNext from '@material-ui/icons/KeyboardArrowRight'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Slider from 'react-slick/lib'

import { formatTooltip } from '../../../common/Formatter'
import StyledButton from '../../../styled/Button'

class Icon extends Component {
    static propTypes = {
        className: PropTypes.string,
        onClick: PropTypes.func,
        children: PropTypes.node,
    }

    render() {
        const { className, children, onClick } = this.props
        return React.cloneElement(children, {
            className,
            onClick,
        })
    }
}

class SliderWithBtn extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        data: PropTypes.array.isRequired,
        maxButtonsSlider: PropTypes.number,
        showMore: PropTypes.bool,
        classes: PropTypes.object.isRequired,
    }

    buttonMount = buttons => {
        return buttons
            ? buttons.map((object, i) => {
                  return (
                      <StyledButton
                          key={'btn-' + i}
                          type="button"
                          onClick={object.onClick}
                      >
                          {formatTooltip(object.text)}
                      </StyledButton>
                  )
              })
            : []
    }

    edge(index, count) {
        if (index + 1 === count) {
            this.setState({ shadow: 'no-shadow' })
        } else {
            this.setState({ shadow: '' })
        }
    }

    onAfterChange = index => this.edge(index, this.props.data?.length || 0)

    render() {
        const { classes } = this.props
        if (
            (this.props.data?.length || 0) < this.props.maxButtonsSlider ||
            this.props.showMore
        ) {
            return this.buttonMount(this.props.data)
        }

        let settings = {
            dots: true,
            speed: 500,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll:
                this.props.maxButtonsSlider < (this.props.data?.length || 0)
                    ? this.props.maxButtonsSlider
                    : this.props.maxButtonsSlider - 1,
            variableWidth: true,
            prevArrow: (
                <Icon>
                    <ActionPrev color={'action'} />
                </Icon>
            ),
            nextArrow: (
                <Icon>
                    <ActionNext color={'action'} />
                </Icon>
            ),
            className: classes.slider,
        }

        return (
            <Slider {...settings} afterChange={this.onAfterChange}>
                {this.buttonMount(this.props.data)}
            </Slider>
        )
    }
}

export default SliderWithBtn
