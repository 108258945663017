export const styles = theme => ({
    root: {
        alignSelf: 'center',
        width: 'auto',
        display: 'inline-block',
        fontWeight: '900',
        color: '#232222',
        fontSize: 14,
    },
    inline: {
        display: 'inline-block',
        marginRight: theme.spacing(1),
        cursor: 'default',
    },
    titleRoot: {
        width: '100%',
        cursor: 'default',
        margin: '8px -8px 8px',
        padding: '2px 10px',
        display: 'block',
        whiteSpace: 'pre-wrap',
        boxSizing: 'content-box',
        backgroundColor: 'rgba(178, 30, 40, 0.1)',
    },
    label: {
        color: theme.palette.purple.main,
        padding: '4px 0',
        display: 'inline-block',
        margin: 0,
    },
    title: {
        margin: 0,
        fontSize: '12px',
        color: '#b21e28',
    },
    input: {
        margin: '4px 0',
        padding: 0,
        fontSize: 14,
        position: 'relative',
        '& span': {
            display: 'inline-block',
            position: 'relative',
            fontSize: 12,
            color: theme.palette.primary.dark,
        },
        '& span::after': {
            content: '" "',
            position: 'absolute',
            right: -28,
            bottom: 0,
            width: 20,
            height: '100%',
            borderBottom: '1px dotted #949494',
        },
    },
    enabled: {
        color: theme.palette.primary.main,
    },
    disabled: {},
    disabledAutoResize: {
        fontFamily: theme.palette.families.monospace,
    },
})
