export const styles = theme => ({
    root: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: theme.spacing(1),
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    rows: {
        flex: '1',
        // overflow: 'auto',
    },
    actions: {
        marginBottom: 0,
        marginTop: `${theme.spacing(2)}px`,
        minHeight: `${theme.spacing(4) + 1}px`,
        padding: `${theme.spacing(1)}px 0 0 0`,
        borderTop: '1px solid rgba(0, 0, 0, 0.15)',
        textAlign: 'right',
    },
    left: {
        textAlign: 'left',
    },
    right: {
        textAlign: 'right',
    },
    tabbed: {
        padding: '5px 10px 10px',
        border: '1px solid #ccc',
        borderTop: 0,
        borderRadius: '0 0 2px 2px',
    },
    tabbedText: {
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: 'normal',
        textTransform: 'uppercase',
        color: '#4a4a4a',
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    titleColumn: {
        backgroundColor: 'rgba(178, 30, 40, 0.1)',
        padding: '0 8px',
        margin: `8px -8px 8px`,
        '& > div': {
            width: 'auto',
            padding: `0 !important`,
            margin: `0 !important`,
            backgroundColor: 'transparent !important',
        },
    },
    onlyButtons: {
        marginTop: theme.spacing(1),
        borderBottom: '1px solid #ccc',
        background: '#f9f9f9',
    },
    buttonTab: {
        bottom: 0,
    },
    buttonWrapper: {
        display: 'flex',
        position: 'relative',
        '&:not(.active) button': {
            borderRight: '1px solid rgba(0,0,0,0.15)',
        },
        '&.active button': {
            borderRadius: theme.spacing(1, 1, 0, 0),
            backgroundColor: `${theme.palette.primary.dark}`,
            color: 'white',
        },
        '&.active::before': {
            content: "' '",
            display: 'block',
            width: 1,
            position: 'absolute',
            height: '100%',
            left: 9,
            zIndex: 1,
        },
    },
})
