import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function GearIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.02402402,0,0,-0.02402402,2.01e-6,19.219218)">
                <path d="m 500,495 q 79,0 135,-56 56,-56 56,-135 0,-79 -56,-135 -56,-56 -135,-56 -79,0 -135,56 -56,56 -56,135 0,79 56,135 56,56 135,56 z m 0,-291 q 41,0 70.5,29.5 Q 600,263 600,304 600,345 570.5,374.5 541,404 500,404 459,404 429.5,374.5 400,345 400,304 400,263 429.5,233.5 459,204 500,204 Z M 999,230 852,199 Q 838,154 820,124 L 902,-2 802,-102 675,-19 Q 641,-38 601,-49 L 570,-199 H 428 l -31,150 q -38,12 -73,30 L 196,-102 96,-2 179,125 q -19,35 -31,74 L 0,230 v 142 l 146,31 q 12,41 31,76 L 96,604 196,704 320,624 q 36,21 78,33 l 30,143 h 142 l 30,-143 q 43,-12 80,-33 L 802,704 902,604 822,481 q 19,-34 32,-79 L 999,372 Z M 500,62 q 100,0 171,71 71,71 71,171 0,100 -71,170.5 Q 600,545 500,545 400,545 329.5,474.5 259,404 259,304 259,204 329.5,133 400,62 500,62 Z" />
            </g>
        </SvgIcon>
    )
}
