import keycodes from '@nerus/framework/common/Keycodes'

import { sendBuffer } from '../EacActions'
import Menu from './Menu'

export function handleKeyboard(ws) {
    return (_, o, data) => {
        if (o.keyCode === keycodes.ESCAPE_KEY) {
            ws.send(
                sendBuffer(
                    {
                        key: o.keyCode,
                        facePicStatus: 0,
                        biometryStatus: 3,
                        consultId: null,
                    },
                    data.retType || 'BiometriaFacialRetorno'
                )
            )
            return
        }

        if (o.keyCode === keycodes.ENTER_KEY) {
            ws.send(
                sendBuffer(
                    {
                        facePicStatus:
                            data.faceBiometry.result === 'identified' ? 1 : 0,
                        biometryStatus:
                            data.faceBiometry.result === 'identified' ? 1 : 0,
                        consultId: data.faceBiometry.faceBiometry[0].id,
                        key: o.keyCode,
                    },
                    data.retType || 'BiometriaFacialRetorno'
                )
            )
            return
        }
    }
}

export default class BiometriaFacial extends Menu {
    static before({ state, action }) {
        action.payload.disableKeyboard = true
        action.payload.keyboardActions = handleKeyboard

        return { state, action }
    }
}
