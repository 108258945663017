import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import Error404 from './modules/App/pages/Error404'

let NerusWebRoutes = []
try {
    NerusWebRoutes = require('./modules/NerusWeb/routes').default
} catch (e) {
    // eslint-disable-next-line
    console.error('Houve um erro ao carregar as rotas do NerusWeb', e)
}

let NerusITRoutes = []
try {
    if (
        process.env.USE_DASHBOARD ||
        (typeof window !== 'undefined' && window.NERUS_CONFIG.dashboard.use)
    ) {
        NerusITRoutes = require('./modules/NerusIT/routes').default
    }
} catch (e) {
    // eslint-disable-next-line
    console.error('Houve um erro ao carregar as rotas do NerusIT', e)
}

export const routes = [
    ...NerusWebRoutes,
    ...NerusITRoutes,
    // 404
    {
        path: '*',
        component: Error404,
    },
]

export const renderRoutes = routes => {
    return routes.map((route, i) => {
        if (route.redirect) {
            const { exact, redirect } = route

            if (redirect.from) {
                return (
                    <Redirect
                        exact={exact}
                        key={i}
                        from={redirect.from}
                        to={redirect.to}
                    />
                )
            } else {
                return <Redirect exact={exact} key={i} to={redirect.to} />
            }
        }

        return <Route key={i} {...route} />
    })
}

export default routes

// For hot reloading of react components
// Webpack Hot Module Replacement API
if (module.hot) {
    module.hot.accept()
}
