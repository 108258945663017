import { Box } from '@material-ui/core'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import keycodes from '@nerus/framework/common/Keycodes'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { sendBuffer, updateWsData } from '../../Eac/EacActions'
import Actions from './Estrutura/Actions'
import Rec from './Estrutura/Rec'

/**
 * Classe base do Formulário
 */
export default class BaseFormulario extends EnhancedComponent {
    static propTypes = {
        id: PropTypes.any,
        data: PropTypes.object,
        dispatch: PropTypes.func,
        list: PropTypes.array,
        active: PropTypes.any,
        useDiv: PropTypes.bool,
        isEditorForm: PropTypes.bool,
        isGuia: PropTypes.bool,
        ws: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            forms: props.data?.ws?.form ?? [],
            actions: props.data?.botoesMensagem ?? [],
            walked: false,
            width: 0,
        }
        this.activeValue = this.createRef('activeValue')
    }

    updateField = (field, value, persistState = false) => {
        const { dispatch } = this.props

        this.activeValue.current = value

        if (persistState) {
            dispatch(
                updateWsData(this.props.id, {
                    data: null,
                    ...field,
                    value,
                    form: this.state.forms.map(form => {
                        let rows = {}
                        Object.values(form.rows).forEach(row => {
                            let y = 0
                            const elements = row.elements.map(el => {
                                y = el.y
                                if (el.x === field.x && el.y === field.y) {
                                    return {
                                        ...el,
                                        ...field,
                                        value,
                                    }
                                }
                                return el
                            })
                            rows[y] = { ...row, elements }
                        })

                        form.rows = rows

                        return form
                    }),
                })
            )
        }
    }

    componentDidMount() {
        const that = this
        if (!this.state.walked) {
            const f = this.state.forms[0]
            if (f && f.hasTab && f.hasActiveElement) {
                const firstRow = Object.keys(f.rows).shift()
                const row = f.rows[firstRow]
                if (row && row.tab) {
                    // ignoramos o botão Geral
                    let skips = -1,
                        activeFound = false
                    row.elements.forEach(element => {
                        if ((!activeFound && element.enabled) || activeFound) {
                            activeFound = true
                            if (skips >= 0) {
                                that.props.ws.send(
                                    sendBuffer(
                                        {
                                            key: keycodes.DOWN_ARROW_KEY,
                                            value: element.value,
                                            x: element.x,
                                            y: element.y,
                                        },
                                        'sendEdit'
                                    )
                                )
                            }
                            skips++
                        }
                    })
                }
                this.setState({
                    walked: true,
                })
            }
        }

        this.componentDidUpdate({})
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.state.width !== nextState.width ||
            this.props.waiting !== nextProps.waiting ||
            this.state.walked !== nextState.walked ||
            this.state.actions !== nextState.actions ||
            this.props.active !== nextProps.active ||
            this.props.data !== nextProps.data
        )
    }

    componentDidUpdate(prevProps) {
        const updateState = {}
        if (this.props.data !== prevProps.data) {
            const { data } = this.props
            updateState.actions = data.botoesMensagem
            updateState.forms = data.ws.form
        }

        if (updateState.forms) {
            this.setState(updateState)
        }

        const ref = this.getRef('formWrapper')
        if (ref && this.props.data !== prevProps.data) {
            if (
                this.state.width !== ref.scrollWidth &&
                ref.scrollWidth > this.state.width
            ) {
                this.setState({
                    width: ref.scrollWidth,
                })
            }
        }
    }

    // Renderiza uma linha do formulário
    renderRow = (row, i, form) => {
        const { active, activeElement, classes, data, id, useDiv } = this.props

        let autoResizeApplied = false
        return (
            <div
                className={clsx({
                    [classes.row]: true,
                    [classes.onlyButtons]: row.hasGeneralButton,
                    [classes.titleColumn]: row.columnTitle,
                })}
                key={`form-row-${i}`}
            >
                {row.elements.map((element, e) => {
                    const overrideElement = {
                        ...element,
                    }
                    /**
                     * Se o Formulario não está ativo,
                     * desabilitamos todos os elementos
                     */

                    if (!active) {
                        overrideElement.enabled = false
                    }

                    if (useDiv === true) {
                        overrideElement.useDiv = false
                    }

                    const inputWidth = overrideElement.inputWidth
                    let minInputWidth = form.inputWidths[e]
                    let labelWidth = form.columnWidths[e]
                    let boxWidth = form.boxWidths[e]
                    if (row.elements?.length >= 5) {
                        boxWidth = 0
                    }
                    /* não podemos considerar o tamanho do label nos
                        casos atípicos quando temos 5 ou mais colunas */
                    if (row.elements?.length >= 5 && e > 0) {
                        labelWidth = 0
                    }
                    if (row.elements?.length >= 3) {
                        minInputWidth = 0
                    }

                    if (
                        autoResizeApplied ||
                        (row.elements?.length >= 2 && data.autoResize === false)
                    ) {
                        boxWidth = 0
                        labelWidth = 0
                        minInputWidth = 0
                        autoResizeApplied = true
                    }

                    return (
                        <Rec
                            index={i + '-' + e}
                            updateField={this.updateField}
                            autoResize={
                                data.autoResize !== undefined
                                    ? data.autoResize
                                    : true
                            }
                            widths={form.columnWidths}
                            inputWidths={form.inputWidths}
                            boxWidth={boxWidth}
                            qtdColumns={row.columns}
                            componentId={id}
                            inputWidth={inputWidth}
                            minInputWidth={minInputWidth}
                            labelWidth={labelWidth}
                            activeValue={this.activeValue}
                            activeElement={activeElement}
                            column={e}
                            key={`el-e-${element.lbl}-${e}`}
                            {...overrideElement}
                        />
                    )
                })}
            </div>
        )
    }

    // renderiza o formulário
    renderForm = forms => {
        const that = this
        const onSubmit = e => {
            e.preventDefault()
        }

        const { hasError, width } = this.state

        if (hasError) {
            return this.renderError()
        }

        const opts = {
            onSubmit,
            action: '',
            method: 'POST',
            autoComplete: 'off',
        }

        const {
            activeElement,
            classes,
            isEditorForm,
            isGuia,
            useDiv,
        } = this.props

        let align = 'left'
        if (activeElement?.bt) {
            activeElement.bt.forEach(e => {
                if (
                    e?.text?.toLowerCase() === 'salvar' ||
                    e?.text?.toLowerCase() === 'fechar' ||
                    e?.primary ||
                    e?.secondary ||
                    e?.dialog
                ) {
                    align = 'right'
                }
            })
        }

        const TabContainer = (
            <h3 className={classes.tabbedText}>
                Clique no título ou aperte enter para editar o conteúdo desta
                aba.
            </h3>
        )

        return (
            <form
                {...opts}
                ref={this.createRef('formWrapper')}
                className={clsx({
                    [classes.root]: true,
                    [classes.spaceLeft]: isEditorForm && !isGuia,
                    [this.props.className]: this.props.className,
                })}
                style={{ minWidth: width }}
            >
                <Box className={classes.rows}>
                    {forms.map((form, i) => {
                        if (!form.rows) {
                            return null
                        }

                        if (i !== 0) {
                            return (
                                <div
                                    key={'tab-' + i}
                                    className={classes.tabbed}
                                >
                                    {// se a aba possui elemento ativo, renderizamos os elementos
                                    form.generalBtn && form.generalBtn.active
                                        ? Object.values(form.rows).map(
                                              (row, r) => {
                                                  return that.renderRow(
                                                      row,
                                                      r,
                                                      form,
                                                      i
                                                  )
                                              }
                                          )
                                        : TabContainer}
                                </div>
                            )
                        }

                        const rows = Object.values(form.rows)
                        const lastRow =
                            rows.length > 0 ? rows[rows.length - 1] : {}
                        const fields = rows.map((row, r) => {
                            return that.renderRow(row, r, form, i)
                        })

                        if (lastRow?.tab && lastRow?.isLast) {
                            fields.push(
                                <div
                                    key={`tabContainer-${form?.rows?.length -
                                        1}`}
                                    className={classes.tabbed}
                                >
                                    {TabContainer}
                                </div>
                            )
                        }

                        return fields
                    })}
                </Box>

                {!useDiv && !isEditorForm ? (
                    <Actions
                        data={activeElement || null}
                        active={this.props.active}
                        activeValue={this.activeValue}
                        align={align}
                    />
                ) : (
                    ''
                )}
            </form>
        )
    }
}
