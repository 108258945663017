import makeStyles from '@material-ui/styles/makeStyles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 16,
    },
}))

export default function Actions({ children, className }) {
    const classes = useStyles()
    return (
        <div className={clsx(classes.root, { [className]: className })}>
            {children}
        </div>
    )
}

Actions.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}
