export const styles = theme => ({
    root: {},
    content: {
        marginTop: theme.spacing(2),
    },
    actions: {
        borderTop: '1px solid #ccc',
        padding: `8px 0 0`,
        marginTop: '10px',
        overflow: 'hidden',
        textAlign: 'right',
    },
})
