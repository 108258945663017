import { Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/layout/about'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import packageJson from '../../../../../package.json'
import Logo from '../../../../../public/img/logo.png'
import { toggleAbout } from '../../../App/AppActions'
import { getNerusInfo } from '../../Eac/EacReducer'
import BaseFlutuante from '../Flutuante/BaseFlutuante'

/**
 * Tela de Sobre
 * Utilizamos a versão do packageJson que é gerada na tag do gitlab
 */
export class About extends Component {
    static propTypes = {
        nerusInfo: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    }

    /**
     * Recupera os dados do Redux
     */
    static mapStateToProps = state => {
        return {
            nerusInfo: getNerusInfo(state),
        }
    }

    handleClose = () => this.props.dispatch(toggleAbout())

    render() {
        const { classes } = this.props

        return (
            <BaseFlutuante
                noheader
                nospace
                size={'small'}
                handleClose={this.handleClose}
            >
                <div className={classes.root}>
                    <div className={classes.logoContainer}>
                        <img className="logo" src={Logo} width="150" />
                    </div>
                    <div>
                        <Typography variant={'h6'} gutterBottom>
                            Versão: {this.props.nerusInfo.version}
                        </Typography>
                        <Typography variant={'h6'} gutterBottom>
                            Web Layer: {packageJson.version}
                        </Typography>
                        <Typography paragraph>
                            Somos a Nérus, especializada em software de gestão
                            de varejo. Trabalhamos para o sucesso de nossos
                            clientes, que são as redes varejistas dos setores de
                            Móveis e Eletro, Material de Construção, Moda e
                            Calçados e Autopeças.
                        </Typography>
                    </div>
                </div>
            </BaseFlutuante>
        )
    }
}

export default connect(About.mapStateToProps)(withStyles(styles)(About))
