import transformColor from '@nerus/framework/util/transformColor'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'

import getFormat from './common/getFormat'

export default function getGraphProps(
    initialProperties,
    axes,
    data,
    tooltip,
    custom = {}
) {
    let labelFormat = undefined
    let tooltipFormat = undefined
    let colors = { scheme: 'NerusWeb' }
    let labelColor = '#ffffff'

    if (axes?.y?.color || axes?.x?.color) {
        colors = []
    }
    if (axes?.x?.color) {
        colors.push(transformColor(axes.x.color))
    }
    if (axes?.y?.color) {
        colors.push(transformColor(axes.y.color))
    }
    if (axes?.x?.labelColor) {
        labelColor = transformColor(axes?.x?.labelColor)
    }
    if (axes?.y?.labelColor) {
        labelColor = transformColor(axes?.y?.labelColor)
    }
    if (axes?.x?.format) {
        labelFormat = getFormat(axes?.x?.format)
        if (axes.x.format === 'money') {
            tooltipFormat = value =>
                Number(value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                })
        }
    }
    if (axes?.y?.format) {
        labelFormat = getFormat(axes?.y?.format)
        if (axes.y.format === 'money') {
            tooltipFormat = value =>
                Number(value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                })
        }
    }

    if (custom?.borderColor?.modifiers) {
        const modifiers = custom?.borderColor?.modifiers
        if (modifiers[0].length === 1) {
            if (isNaN(Number(modifiers[0][0]))) {
                custom.borderColor.modifiers = [
                    [
                        modifiers[0][0],
                        initialProperties.borderColor.modifiers[0][1],
                    ],
                ]
            } else {
                custom.borderColor.modifiers = [
                    [
                        initialProperties.borderColor.modifiers[0][0],
                        Number(modifiers[0][1]),
                    ],
                ]
            }
        } else {
            custom.borderColor.modifiers = [
                [modifiers[0][0], Number(modifiers[0][1])],
            ]
        }
    }

    const graphProps = merge(cloneDeep({ ...initialProperties }), {
        colors: cloneDeep(colors),
        labelTextColor: labelColor,
        label: labelFormat,
        arcLabel: labelFormat,
        ...cloneDeep({ ...custom }),
        tooltip,
        tooltipFormat,
        data,
    })

    if (!graphProps.showLegend) {
        graphProps.legends = []
    }

    if (graphProps.axisLeft || graphProps.axisLeft?.enable) {
        if (!graphProps.axisLeft.legend && axes?.y?.title) {
            graphProps.axisLeft.legend = axes?.y?.title
        }
        graphProps.axisLeft.format = value =>
            axes?.y?.format === 'money'
                ? Number(value).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                  })
                : value
    }

    if (graphProps.axisBottom || graphProps.axisBottom?.enable) {
        if (!graphProps.axisBottom.legend && axes?.x?.title) {
            graphProps.axisBottom.legend = axes?.x?.title
        }
        graphProps.axisBottom.format = value =>
            axes?.x?.format === 'money'
                ? Number(value).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                  })
                : axes?.x?.format === 'time'
                ? `${value}hs`
                : value
    }

    return cloneDeep(graphProps)
}
