import makeStyles from '@material-ui/styles/makeStyles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import getFormat from '../../components/common/getFormat'

const useStyles = makeStyles(() => ({
    root: {
        alignItems: 'center',
        display: 'flex',
    },
    bg: {
        borderRadius: 2,
        padding: '5px 9px',
        boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px',
        background: '#ffffff',
    },
    rootVertical: {
        flexDirection: 'column',
    },
    noshadow: {
        boxShadow: 'none !important',
        padding: 0,
    },
    colorBlock: {
        display: 'inline-block',
        marginRight: 8,
        height: 17,
        width: 17,
    },
    value: {
        marginLeft: 4,
        marginTop: 1,
        height: 17,
    },
    label: {
        height: 17,
    },
}))

export default function GraphTooltip({
    color,
    label,
    value,
    format = 'default',
    noshadow = false,
    children,
    vertical = false,
}) {
    const classes = useStyles()

    return (
        <div
            className={clsx(classes.root, classes.bg, {
                [classes.rootVertical]: vertical,
                [classes.noshadow]: noshadow,
            })}
        >
            {children ? (
                children(classes)
            ) : (
                <Fragment>
                    <span
                        style={{ backgroundColor: color }}
                        className={classes.colorBlock}
                    />

                    <strong className={classes.label}>{label}: </strong>

                    <span className={classes.value}>
                        {getFormat(format)(value)}
                    </span>
                </Fragment>
            )}
        </div>
    )
}

GraphTooltip.propTypes = {
    color: PropTypes.string,
    format: PropTypes.string,
    noshadow: PropTypes.bool,
    vertical: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.any,
}
