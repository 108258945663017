import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'

export const DASHBOARD_RENDER = gql`
    query dashboardRender(
        $binario: String
        $menu: String
        $user_id: String
        $activeFilters: Any
        $dashboard_id: Int
        $painelO2: Boolean
    ) {
        dashboardRender(
            binario: $binario
            menu: $menu
            user_id: $user_id
            activeFilters: $activeFilters
            dashboard_id: $dashboard_id
            painelO2: $painelO2
        ) {
            data
            activeFilters
        }
    }
`

export default Component => {
    return graphql(DASHBOARD_RENDER, {
        name: 'dashboardRender',
        skip: props => {
            const bin = props?.nerusInfo?.name
            if (bin === 'nerus_o2') {
                return false
            }

            return (
                !props.dashboard_id &&
                (!props.nerusInfo ||
                    !props.nerusInfo.usrno ||
                    !props.nerusInfo.name ||
                    !props.menu?.title)
            )
        },
        options: props => {
            return {
                fetchPolicy: 'cache-and-network',
                variables: {
                    dashboard_id: props.dashboard_id,
                    binario:
                        props.nerusInfo.name === 'nerus_o2' &&
                        props.menu?.title === 'Menu Principal'
                            ? 'nerusweb'
                            : props.nerusInfo.name,
                    painelO2:
                        props.nerusInfo.name === 'nerus_o2' &&
                        props.menu?.title !== 'Menu Principal',
                    menu: props.menu?.title,
                    user_id: props.nerusInfo.usrno
                        ? String(props.nerusInfo.usrno)
                        : null,
                    activeFilters: props.activeFilters || {},
                },
            }
        },
    })(Component)
}
