import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function DashboardExportIcon(props) {
    return (
        <SvgIcon {...props} viewBox="2 2 20 20">
            <g>
                <path d="M0,0h24v24H0V0z" fill="none" />
            </g>
            <g>
                <path
                    d="M18,2h-8L4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C20,2.9,19.1,2,18,2z M12,17l-4-4h3V9.02L13,9v4h3L12,17z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    )
}
