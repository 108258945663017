import ConthraxBoldFontTtf from '../../../public/fonts/conthrax/conthrax.ttf'
import ConthraxBoldFontWoff from '../../../public/fonts/conthrax/conthrax.woff'
import ConthraxBoldFontWoff2 from '../../../public/fonts/conthrax/conthrax.woff2'
import FiraCodeRegularFontTtf from '../../../public/fonts/fira-code/regular/FiraCode-Regular.ttf'
import FiraCodeRegularFontWoff from '../../../public/fonts/fira-code/regular/FiraCode-Regular.woff'
import FiraCodeRegularFontWoff2 from '../../../public/fonts/fira-code/regular/FiraCode-Regular.woff2'
import FrutigerBoldFontTtf from '../../../public/fonts/frutiger/bold/frutiger-bold.ttf'
import FrutigerBoldFontWoff from '../../../public/fonts/frutiger/bold/frutiger-bold.woff'
import FrutigerBoldFontWoff2 from '../../../public/fonts/frutiger/bold/frutiger-bold.woff2'
import FrutigerLightFontTtf from '../../../public/fonts/frutiger/light/frutiger-light.ttf'
import FrutigerLightFontWoff from '../../../public/fonts/frutiger/light/frutiger-light.woff'
import FrutigerLightFontWoff2 from '../../../public/fonts/frutiger/light/frutiger-light.woff2'
import FrutigerRegularFontTtf from '../../../public/fonts/frutiger/regular/frutiger.ttf'
import FrutigerRegularFontWoff from '../../../public/fonts/frutiger/regular/frutiger.woff'
import FrutigerRegularFontWoff2 from '../../../public/fonts/frutiger/regular/frutiger.woff2'

// @TODO: Add other types later
//         @font-face {
//     font-family: 'Fira Code';
//     src: url('FiraCode-Regular.eot');
//     src: url('FiraCode-Regular.eot?#iefix') format('embedded-opentype'),
//         url('FiraCode-Regular.woff2') format('woff2'),
//         url('FiraCode-Regular.woff') format('woff'),
//         url('FiraCode-Regular.ttf') format('truetype'),
//         url('FiraCode-Regular.svg#FiraCode-Regular') format('svg');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }
const createFontFace = (
    fontFamily,
    fontWeight,
    localName,
    woff2,
    woff,
    ttf
) => ({
    fontFamily,
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight,
    src: `local('${localName}'), local('${localName.replace(/ /g, '-')}'),
            url(${woff2}) format('woff2'),
            url(${woff}) format('woff'),
            url(${ttf}) format('ttf')`,
})

export const FiraCode = {
    regular: createFontFace(
        'Fira Code',
        400,
        'Fira Code',
        FiraCodeRegularFontWoff2,
        FiraCodeRegularFontWoff,
        FiraCodeRegularFontTtf
    ),
}

export const Conthrax = {
    bold: createFontFace(
        'Conthrax',
        600,
        'Conthrax Sb',
        ConthraxBoldFontWoff2,
        ConthraxBoldFontWoff,
        ConthraxBoldFontTtf
    ),
}

export const Frutiger = {
    light: createFontFace(
        'Frutiger',
        300,
        'Frutiger Light',
        FrutigerLightFontWoff2,
        FrutigerLightFontWoff,
        FrutigerLightFontTtf
    ),
    regular: createFontFace(
        'Frutiger',
        400,
        'Frutiger Regular',
        FrutigerRegularFontWoff2,
        FrutigerRegularFontWoff,
        FrutigerRegularFontTtf
    ),
    bold: createFontFace(
        'Frutiger',
        700,
        'Frutiger Bold',
        FrutigerBoldFontWoff2,
        FrutigerBoldFontWoff,
        FrutigerBoldFontTtf
    ),
}

export const styles = theme => ({
    root: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: theme.sizing.topBar + 20,
        zIndex: 1500,
        transition: 'ease 50ms all',
        [theme.breakpoints.down('md')]: {
            top: theme.sizing.topBarSmall + 20,
        },
    },
    open: {
        left: theme.sizing.sidebar + theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            left: theme.sizing.sidebarSmall + theme.spacing(3),
        },
    },
    closed: {
        left: 0,
    },
    closedPrincipal: {
        left: theme.sizing.sidebarPrincipalClosed + 24,
    },
    right: {
        right: theme.sizing.sidebar + theme.spacing(2),
        left: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            right: theme.sizing.sidebarSmall + theme.spacing(2),
        },
    },
})
