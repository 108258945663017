import Interface from './Interface'

export default class Editor extends Interface {
    static before({ state, action }) {
        if (action.payload.grid) {
            action.payload.rows = action.payload.grid.rows
            action.payload.cols = action.payload.grid.cols
            action.payload.edtLin = action.payload.grid.edtLin
        }

        return { state, action }
    }

    static buildDefaultsDeepSource(editorPayload = {}) {
        editorPayload.clrs?.forEach(clr => {
            delete clr.lns, delete clr.cols
        })

        return editorPayload
    }
}
