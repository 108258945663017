import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/formulario'
import PropTypes from 'prop-types'
import React from 'react'

import keycodes from '../../../../common/Keycodes'
import Button from '../../../../styled/Button'
import Label from '../../../../styled/Label'
import AbstractTextField from './AbstractTextField'

/**
 * Componente TextFieldPrompt
 *
 * Rec: REC_PROMPT
 *
 * Renderizamos um botão, quando agrupado, viram abas
 */
export class TextFieldPrompt extends AbstractTextField {
    static propTypes = {
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    }

    static withoutWrapper = true

    handleClick = e => {
        e.stopPropagation()
        e.preventDefault()

        if (e.detail === 1) {
            const { onSendValue, x, y } = this.props
            onSendValue &&
                onSendValue({
                    x,
                    y,
                    key: keycodes.ENTER_KEY,
                })
        }
    }

    render() {
        const {
            lbl,
            active,
            enabled,
            index,
            classes,
            onBlur,
            onFocus,
        } = this.props

        const opts = {
            className: active ? classes.buttonTab : '',
            tab: 'new',
            disabled: !enabled,
            id: index,
            onClick: this.handleClick,
            onBlur,
            onFocus,
            active: enabled || active,
        }

        return (
            <Button {...opts}>
                <Label text={lbl} noDots />
            </Button>
        )
    }
}

export default withStyles(styles)(TextFieldPrompt)
