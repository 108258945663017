import { Input } from '@nerus/framework/styled/Input'
import * as PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import CurrencyInput from 'react-number-format'

import AbstractTextField from './AbstractTextField'
import { LETTERS_MAPPING } from '../../../../../../framework/common/Keycodes'
import { sendBuffer } from '../../../../Eac/EacActions'

const NumberFormat = props => {
    const {
        inputRef,
        onChange,
        precision,
        allowNegative,
        fixedDecimalScale = false,
        useFormat = true,
        isTitle,
        attrib,
        maxLength,
        ...other
    } = props

    const onValueChange = useCallback(
        values => {
            onChange({
                target: {
                    value: values.floatValue,
                },
            })
        },
        [onChange]
    )

    const getInputRef = useCallback(() => {
        return inputRef
    }, [inputRef])

    return (
        <CurrencyInput
            {...other}
            customInput={Input}
            isTitle={isTitle}
            attrib={attrib}
            thousandSeparator={useFormat ? '.' : ''}
            decimalSeparator={useFormat ? ',' : '.'}
            allowedDecimalSeparators={['.', ',']}
            getInputRef={getInputRef}
            decimalScale={precision}
            fixedDecimalScale={fixedDecimalScale}
            allowEmptyFormatting={false}
            defaultValue={''}
            allowNegative={allowNegative}
            onValueChange={onValueChange}
            dispatchToWs={true}
            maxLength={maxLength}
        />
    )
}

NumberFormat.propTypes = {
    precision: PropTypes.any,
    allowNegative: PropTypes.any,
    maxLength: PropTypes.any,
    inputRef: PropTypes.any,
    onChange: PropTypes.func,
    fixedDecimalScale: PropTypes.bool,
    useFormat: PropTypes.bool,
    fieldType: PropTypes.object,
    isTitle: PropTypes.bool,
    attrib: PropTypes.number,
    commasNumber: PropTypes.number,
}

/**
 * Componente TextFieldShort
 *
 * Rec: REC_SHORT
 * Rec: REC_SHORT_WITH_HELP
 * Rec: REC_LONG
 *
 * Renderiza um campo de texto que tem formatação de casas
 * decimais
 */
export class TextFieldShort extends AbstractTextField {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    }

    fixedDecimalScale = false
    allowNegative = false
    useFormat = false
    commasNumber = 0

    constructor(props) {
        super(props)
        this.state = {
            value: parseFloat(props.value) || '',
            commasNumber: 0,
            pointsNumber: 0,
            btnsLetters: [],
        }
    }

    componentDidUpdate(prevProps) {
        const { value, enabled, activeValue } = this.props

        if (enabled && activeValue && this.getRef('input')) {
            activeValue.current = this.beforeSend
                ? this.beforeSend(this.getRef('input').value)
                : this.getRef('input').value
        }

        if (prevProps.value !== value || prevProps.enabled !== enabled) {
            this.setState(
                {
                    value: parseFloat(value) || '',
                },
                this.triggerUpdateField(value)
            )
        }
    }

    componentDidMount() {
        const { activeElement } = this.props

        const btnsLetters = activeElement?.bt?.filter(({ key }) =>
            LETTERS_MAPPING.includes(key)
        )
        if (btnsLetters?.length && activeElement.typeRec == 'REC_LONG') {
            this.setState({ btnsLetters })

            this.getRef('input').addEventListener(
                'keydown',
                this.customKeyDown(btnsLetters)
            )
        }
    }

    customKeyDown(btnsLetters) {
        const { ws } = this.props
        const state = btnsLetters

        return function (event) {
            const keyIsPresent = state.some(({ key }) => key == event.keyCode)
            if (keyIsPresent) {
                ws.send(sendBuffer(event.keyCode))
            }
        }
    }

    componentWillUnmount() {
        if (this.state.btnsLetters.length) {
            this.getRef('input').removeEventListener(
                'keydown',
                this.customKeyDown(this.state.btnsLetters)
            )
        }
    }

    triggerUpdateField = () => () => {
        const { updateField, enabled } = this.props
        // Aqui usamos o value da referência em vez do float que temos no state
        // para evitar que enviemos o valor incorreto pro WS
        if (enabled) {
            updateField &&
                updateField(
                    this.props,
                    this.beforeSend
                        ? this.beforeSend(this.getRef('input').value)
                        : this.getRef('input').value
                )
        }
    }

    beforeSend = value => {
        if (!value) {
            return '0'
        }

        // Sem .toString() para conseguirmos ver se em algum momento
        // um valor float/int chega aqui (o que está incorreto)
        return value.replace(/\./g, '').replace(/,/g, '.')
    }

    isAllowed = values => {
        const { formattedValue, floatValue } = values
        const { sz } = this.props

        const commasNumber = formattedValue.match(/,/g)?.length || 0
        const pointsNumber = formattedValue.match(/\./g)?.length || 0
        const maxLength =
            sz + commasNumber + (pointsNumber > 1 ? pointsNumber - 1 : 0)
        const maxValue = '9'.repeat(maxLength)

        this.setState({ commasNumber, pointsNumber })

        return (
            formattedValue === '' ||
            (this.allowNegative && formattedValue === '-') ||
            floatValue <= parseInt(maxValue)
        )
    }

    render() {
        const { value, commasNumber = 0, pointsNumber = 0 } = this.state

        const {
            enabled,
            index,
            prec,
            lbl,
            sz,
            x,
            y,
            inputWidth,
            labelWidth,
            componentId,
            InputProps,
            dispatch,
            attrib,
            isTitle,
        } = this.props

        const fieldType = this.getFieldType()
        const type = 'text'
        const maxLength =
            sz + commasNumber + (pointsNumber > 1 ? pointsNumber - 1 : 0)

        return (
            <NumberFormat
                orientation={'horizontal'}
                label={lbl}
                attrib={attrib}
                isTitle={isTitle}
                tabIndex={index}
                dispatch={dispatch}
                inputRef={this.createRef('input')}
                disabled={!enabled}
                componentId={componentId}
                InputProps={InputProps}
                onChange={this.handleInputChange}
                type={type}
                value={value}
                beforeSend={this.beforeSend}
                fieldType={fieldType}
                position={{ x, y }}
                maxLength={maxLength}
                precision={prec}
                inputWidth={inputWidth}
                labelWidth={labelWidth}
                fixedDecimalScale={this.fixedDecimalScale}
                allowNegative={this.allowNegative}
                useFormat={this.useFormat}
                autoComplete="off"
                overwriteOnFirst={true}
                dispatchToWs={true}
                isAllowed={this.isAllowed}
            />
        )
    }
}

export default TextFieldShort
