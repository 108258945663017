import { defaultProps } from '@nivo/pie'
import moment from 'moment'

import {
    defsProperties,
    getLegendsProps,
    groupProperties,
    themeProperty,
} from '../nivo/utils/componentProperties'

const props = [
    {
        key: 'startAngle',
        help: 'Start angle (in degrees)',
        type: 'number',
        required: false,
        defaultValue: 90,
        controlType: 'angle',
        group: 'Base',
        controlOptions: {
            unit: '°',
            min: -180,
            max: 360,
            step: 5,
        },
    },
    {
        key: 'innerRadius',
        help: `Donut chart if greater than 0. Value should be between 0~1 as it's a ratio from original radius.`,
        type: 'number',
        required: false,
        defaultValue: defaultProps.innerRadius,
        controlType: 'range',
        group: 'Base',
        controlOptions: {
            min: 0,
            max: 0.95,
            step: 0.05,
        },
    },
    {
        key: 'cornerRadius',
        help: 'Rounded slices.',
        type: 'number',
        required: false,
        defaultValue: defaultProps.cornerRadius,
        controlType: 'range',
        group: 'Base',
        controlOptions: {
            unit: 'px',
            min: 0,
            max: 45,
            step: 1,
        },
    },
    {
        key: 'margin',
        help: 'Chart margin.',
        type: 'object',
        required: false,
        controlType: 'margin',
        group: 'Base',
    },
    themeProperty,
    {
        key: 'colors',
        help: 'Defines color range.',
        type: 'string | Function | string[]',
        required: false,
        defaultValue: { scheme: 'NerusWeb' },
        controlType: 'ordinalColors',
        group: 'Style',
    },
    ...defsProperties('Style', ['svg', 'api']),
    {
        key: 'borderWidth',
        help: 'Slices border width.',
        type: 'number',
        required: false,
        defaultValue: defaultProps.borderWidth,
        controlType: 'lineWidth',
        group: 'Style',
    },
    {
        key: 'borderColor',
        help: 'Method to compute border color.',
        type: 'string | object | Function',
        required: false,
        defaultValue: defaultProps.borderColor,
        controlType: 'inheritedColor',
        group: 'Style',
    },
    {
        key: 'enableArcLabels',
        help: 'Enable/disable arc labels.',
        type: 'boolean',
        required: false,
        defaultValue: defaultProps.enableArcLabels,
        controlType: 'switch',
        group: 'Arc labels',
    },
    {
        key: 'arcLabel',
        help:
            'Defines how to get label text, can be a string (used to access current node data property) or a function which will receive the actual node data.',
        type: 'string | Function',
        required: false,
        defaultValue: defaultProps.arcLabel,
        controlType: 'choices',
        group: 'Arc labels',
        controlOptions: {
            choices: ['name', 'value', 'percent'].map(choice => ({
                label: choice,
                value: choice,
            })),
        },
    },
    {
        key: 'arcLabelsTextColor',
        help: 'Defines how to compute arc label text color.',
        type: 'string | object | Function',
        required: false,
        defaultValue: defaultProps.arcLabelsTextColor,
        controlType: 'inheritedColor',
        group: 'Arc labels',
    },
    {
        key: 'enableArcLinkLabels',
        help: 'Enable/disable arc link labels.',
        type: 'boolean',
        required: false,
        defaultValue: defaultProps.enableArcLinkLabels,
        controlType: 'switch',
        group: 'Arc link labels',
    },
    {
        key: 'showLegend',
        flavors: ['svg', 'canvas'],
        help: 'Enable/disable legend.',
        type: 'boolean',
        required: true,
        defaultValue: true,
        controlType: 'switch',
        group: 'Legends',
    },
    {
        key: 'legends',
        flavors: ['svg', 'canvas'],
        type: 'Legend[]',
        help: `Optional chart's legends.`,
        group: 'Legends',
        controlType: 'array',
        controlOptions: {
            props: getLegendsProps(['svg', 'canvas']),
            shouldCreate: false,
            addLabel: 'add legend',
            shouldRemove: false,
            getItemTitle: (index, legend) =>
                `legend[${index}]: ${legend.anchor}, ${legend.direction}`,
            defaults: {
                anchor: 'top-left',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: 0,
                itemWidth: 100,
                itemHeight: 20,
                itemsSpacing: 0,
                symbolSize: 20,
                itemDirection: 'left-to-right',
            },
        },
    },
]

export const groups = groupProperties(props)

export const sampleData = []
for (let i = 0; i < 12; i++) {
    sampleData.push({
        name: moment()
            .subtract(i, 'months')
            .format('MM/YYYY'),
        value: i * Math.floor(Math.random() * (200 - 100) + 100),
    })
}
