import PropTypes from 'prop-types'

import { TextFieldQty } from './Qty'

/**
 * Componente TextFieldMoney
 *
 * Rec: REC_MONEY
 *
 * Renderiza um campo de texto que tem formatação de casas
 * decimais
 */
export class TextFieldMoney extends TextFieldQty {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    }

    fixedDecimalScale = true
    allowNegative = true
    useFormat = true
}

export default TextFieldMoney
