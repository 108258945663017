import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/editor/form'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getComponentById, isActiveComponent } from '../../Eac/EacReducer'
import Editor from '../Editor'
import Formulario from '../Formulario'

export class EditorForm extends Component {
    static mapStateToProps = (state, ownProps) => {
        const EditorForm = getComponentById(ownProps.id, state).payload
        if (!EditorForm) {
            return {}
        }

        return {
            title: EditorForm.editor.data.title,
            formularioId: EditorForm.form.id,
            editorId: EditorForm.editor.id,
            flt: EditorForm.flt,
            active:
                ownProps.active === undefined
                    ? isActiveComponent(ownProps.id, state)
                    : ownProps.active,
        }
    }

    static propTypes = {
        active: PropTypes.bool,
        title: PropTypes.string,
        editorId: PropTypes.string,
        formularioId: PropTypes.string,
        classes: PropTypes.object.isRequired,
        flt: PropTypes.bool,
        isFixo: PropTypes.bool,
        isGuia: PropTypes.bool,
    }

    static baseOpts = {
        title: ({ editor }) => {
            return editor && editor.data ? editor.data.title : ''
        },
        nospace: true,
        size: ({ editor }) => {
            return editor?.data?.cols?.length > 5 ? 'big' : 'medium'
        },
    }

    render() {
        const {
            active,
            editorId,
            classes,
            formularioId,
            isFixo = false,
            isGuia = false,
            flt = true,
        } = this.props

        if (!editorId) {
            return null
        }

        return (
            <div className={classes.root}>
                <Editor
                    key={editorId}
                    isFixo={isFixo}
                    id={editorId}
                    showTitle={!flt && !isGuia}
                    flt={flt}
                    isGuia={isGuia}
                    editorType={'EditorForm'}
                    isEditorForm
                    active={active}
                />

                <div
                    className={clsx({
                        [classes.form]: !isGuia,
                    })}
                >
                    <Formulario
                        isGuia={isGuia}
                        isEditorForm={true}
                        key={formularioId}
                        id={formularioId}
                        useDiv
                    />
                </div>
            </div>
        )
    }
}

export default connect(EditorForm.mapStateToProps)(
    withStyles(styles)(EditorForm)
)
