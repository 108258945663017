import { connect } from 'react-redux'

import { withWS } from '../../../../Business/Websocket/Context'
import CharField from './Char'
import DateField from './Date'
import DateMonthYearField from './DateMonthYear'
import MoneyField from './Money'
import TextFieldNegativableShort from './NegativableShort'
import PercField from './Percentage'
import TextFieldPopupMenu from './PopupMenu'
import PreviewField from './Preview'
import PromptField from './Prompt'
import TextFieldQty from './Qty'
import ShortField from './Short'
import SkipField from './Skip'
import StringField from './String'
import StringMultilineField from './StringMultiline'
import TimeField from './Time'
import ToggleField from './Toggle'
import Upload from './Upload'

export const recsPopup = [
    'REC_POPMENU',
    'REC_MENU',
    'REC_ONE_CHOICE',
    'REC_BIT_MENU',
]

function connectWithWS(Component) {
    return withWS(connect()(Component))
}

export const recToClass = {
    // ! Os TODO's especificam que precisamos
    // ! especificar o OCTAL para a opção

    // * Edição Ponto Flutuante
    REC_PERC: connectWithWS(PercField),
    REC_MONEY: connectWithWS(MoneyField),
    REC_DOUBLE: connectWithWS(MoneyField),

    REC_F_SHORT: connectWithWS(TextFieldNegativableShort),

    REC_F_LONG: connectWithWS(TextFieldQty),
    REC_DQTTY: connectWithWS(TextFieldQty),
    REC_QTTD: connectWithWS(TextFieldQty),
    REC_QTTY: connectWithWS(TextFieldQty),

    // * Edição de Texto
    REC_STRING: connectWithWS(StringField),
    REC_CHAR: connectWithWS(CharField),
    REC_STR_POP: connectWithWS(StringField),
    REC_STR_POP2: connectWithWS(StringField),
    REC_STR_NT: connectWithWS(StringField),

    // * Edição de texto Especial
    REC_PSWD: connectWithWS(StringField),
    REC_PRDNO: connectWithWS(StringField),

    // * Edição de texto com várias linhas
    REC_STR_LINES: connectWithWS(StringMultilineField),
    REC_STR_LINES_BOX: connectWithWS(StringMultilineField),
    REC_STR_SCROLL: connectWithWS(StringMultilineField),
    REC_STR_SCROLL_BOX: connectWithWS(StringMultilineField),
    REC_STR_LINES_UPPER: connectWithWS(StringMultilineField),

    // * Menu de Opções
    REC_POPMENU: connectWithWS(TextFieldPopupMenu),
    REC_MENU: connectWithWS(TextFieldPopupMenu),
    REC_ONE_CHOICE: connectWithWS(TextFieldPopupMenu),
    REC_BIT_MENU: connectWithWS(TextFieldPopupMenu),
    REC_BIT: connectWithWS(ToggleField),
    REC_SN: connectWithWS(ToggleField),
    REC_SN_GLOBAL: connectWithWS(ToggleField),
    REC_BIT_SN_WITH_HELP: connectWithWS(ToggleField),
    REC_BIT_SN: connectWithWS(ToggleField),

    // * Edição de Data
    REC_DATE: connectWithWS(DateField),
    REC_YM: connectWithWS(DateMonthYearField),
    REC_DATEYY: connectWithWS(DateField),
    REC_DATE_UTC: connectWithWS(DateField),
    REC_TIME: connectWithWS(TimeField),

    // * Edição Inteiro
    REC_SHORT: connectWithWS(ShortField),
    REC_SHORT_WITH_HELP: connectWithWS(ShortField),
    REC_LONG: connectWithWS(ShortField),

    // * Controle especiais
    REC_PROMPT: connectWithWS(PromptField),
    REC_SKIP: connectWithWS(SkipField),

    // * Campos customizados
    REC_UPLOAD: connectWithWS(Upload),
    REC_PREVIEW: connectWithWS(PreviewField),

    // * Tabelas Especiais
    // TODO: Revisar com o TEAM C
    // TODO: REC_DUPTOC
    // TODO: REC_STORE
    // TODO: REC_PRD
    // TODO: REC_INDX
    // TODO: REC_CUSTP
    // TODO: REC_VEND
    // TODO: REC_BANK
    // TODO: REC_CL
    // TODO: REC_ACC
    // TODO: REC_EMP
    // TODO: REC_ARQF
    // TODO: REC_TYPENO        (REC_SHORT)
    // TODO: REC_FXATP         (REC_SHORT)
    // TODO: REC_PAYM          (REC_SHORT)
    // TODO: REC_STORE_L
    // TODO: REC_USER          (REC_SHORT)
    // TODO: REC_CORP          (REC_SHORT)

    // * Array
    // TODO: REC_SHORT_ARRAY
    // TODO: REC_LONG_ARRAY
}
