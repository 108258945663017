/**
 * Convert decimal to binary.
 *
 * @param decimal Decimal Number.
 * @param maxLength max length of bin.
 *
 * @return Binary Number.
 */
export default function convertDecToBin(decimal, maxLength) {
    return Number(decimal)
        .toString(2)
        .padStart(maxLength, 0)
}
