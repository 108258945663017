import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import RefreshOutlined from '@material-ui/icons/RefreshOutlined'
import transformColor from '@nerus/framework/util/transformColor'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'

const useStyles = makeStyles(theme => {
    return {
        root: {
            justifySelf: 'flex-end',
            alignSelf: 'flex-end',
            display: 'flex',
            marginBottom: 2,
            marginLeft: 8,
            cursor: 'pointer',
        },
        previewer: {
            position: 'relative',
            '& button': {
                position: 'absolute',
                right: 0,
                top: 0,
                width: 18,
                height: 18,
                color: theme.palette.primary.main,
            },
            '& svg': {
                width: 16,
                height: 16,
            },
        },
        label: {
            display: 'inline-block',
            width: 24,
            height: 24,
            border: '1px solid ' + theme.palette.divider,
        },
        input: { display: 'none' },
        picker: {
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            // marginTop: 24,
        },
        positioned: {
            position: 'fixed',
            zIndex: '2',
        },
        closeArea: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },
    }
})

export default function NerusColorPicker({
    name,
    value,
    onChange,
    allowReset = false,
    style,
}) {
    const classes = useStyles()
    const [localColor, setColor] = useState(value)
    const [visible, setVisible] = useState(false)

    const onClick = useCallback(() => {
        setVisible(true)
    }, [setVisible])

    const onHide = useCallback(() => {
        setVisible(false)
    }, [setVisible])

    const handleOnChange = useCallback(
        color => {
            setColor(color.rgb)
            return onChange && onChange(name, color.rgb)
        },
        [onChange]
    )

    const onReset = useCallback(() => {
        setColor('')
        return onChange && onChange(name, '')
    }, [name, onChange])

    useEffect(() => {
        if (localColor !== value) {
            setColor(value)
        }
    }, [setColor, value, localColor])

    return (
        <span className={classes.root}>
            <span className={classes.previewer}>
                {localColor && allowReset ? (
                    <IconButton onClick={onReset}>
                        <RefreshOutlined />
                    </IconButton>
                ) : null}

                <span
                    style={{
                        backgroundColor: localColor
                            ? transformColor(localColor)
                            : undefined,
                    }}
                    className={classes.label}
                    onClick={onClick}
                />
            </span>

            {visible && (
                <div className={classes.picker} style={style}>
                    <div className={classes.closeArea} onClick={onHide} />
                    <div className={classes.positioned}>
                        <SketchPicker
                            color={localColor}
                            onChange={handleOnChange}
                        />
                    </div>
                </div>
            )}
        </span>
    )
}

NerusColorPicker.propTypes = {
    name: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func,
    allowReset: PropTypes.bool,
    style: PropTypes.object,
}
