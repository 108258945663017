import makeStyles from '@material-ui/core/styles/makeStyles'
import { styles } from '@nerus/styles/components/painelo2/conexao-status'
import PropTypes from 'prop-types'
import React from 'react'

import Status from './Status'

const useStyles = makeStyles(styles, { name: 'PainelO2-ConexaoStatus' })

export default function ConexaoStatus({ url, icone: Icon, status }) {
    const classes = useStyles()

    if (!url) {
        return (
            <span className={classes.root}>
                <Icon disabled={status === null || status === undefined} />

                <Status status={status} />
            </span>
        )
    }

    return (
        <a href={url} className={classes.root} target="_blank" rel="noreferrer">
            <Icon disabled={status === null || status === undefined} />

            <Status status={status} />
        </a>
    )
}

ConexaoStatus.propTypes = {
    icone: PropTypes.func,
    status: PropTypes.string,
    url: PropTypes.string,
}
