import makeStyles from '@material-ui/core/styles/makeStyles'
import useWS from '@nerus/framework/hooks/useWS'
import { styles } from '@nerus/styles/components/painelo2/productCicle'
import React, { useCallback } from 'react'

import { sendBuffer } from '../../../NerusWeb/Eac/EacActions'
import Box from './Box'
import PricingIcon from './Icones/Pricing'
import ProductIcon from './Icones/Product'
import PromotionIcon from './Icones/Promotion'

const useStyles = makeStyles(styles, { name: 'PainelO2-ProductCicle' })

export default function ProductCicle({ data }) {
    const classes = useStyles()
    const ws = useWS()
    const dispatchKey = useCallback(
        key => () => {
            ws.current.send(sendBuffer(key))
        },
        [ws]
    )

    const [{ data: produtos }, { data: precificacao }, { data: promocoes }] =
        data.length ? data : [{ data: [] }, { data: [] }, { data: [] }]

    return (
        <Box title="Ciclo do Produto" bevel className={classes.root}>
            <Box
                title="PRODUTOS"
                color="green"
                onClick={dispatchKey('d')}
                productCicle={true}
                details={{
                    title: 'Produtos ativos',
                    value: produtos[0],
                }}
                value={<ProductIcon width={40} height={40} />}
            />

            <Box
                title="PRECIFICAÇÃO"
                color="blue"
                onClick={dispatchKey('g')}
                productCicle={true}
                details={{
                    title: 'Produtos com precificação ativa',
                    value: precificacao[0],
                }}
                value={<PricingIcon width={40} height={40} />}
            />

            <Box
                title="MOTOR DE PROMOÇÕES"
                color="blue"
                onClick={dispatchKey('h')}
                productCicle={true}
                details={{
                    title: 'Produtos com promoção ativa',
                    value: promocoes[0],
                }}
                value={<PromotionIcon width={40} height={40} />}
            />
        </Box>
    )
}

ProductCicle.propTypes = {}
