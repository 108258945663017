import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useCallback, useState } from 'react'

import { ENOTICES_QUERY } from './useFetchEnotices'

export const REMOVE_ENOTICE_MUTATION = gql`
    mutation removeEnotice($enotno: Int!) {
        removeEnotice(enotno: $enotno) {
            error
            message
        }
    }
`

export default function useRemoveEnotice(onRemove, options) {
    const [enotno, setShowRemoveDialog] = useState(null)

    const [removeEnotice] = useMutation(REMOVE_ENOTICE_MUTATION, {
        displayName: 'removeEnotice',
        refetchQueries: () => [{ query: ENOTICES_QUERY }],
        ...options,
    })

    const onCloseRemove = useCallback(() => {
        setShowRemoveDialog(null)
    }, [setShowRemoveDialog])

    const onShowRemoveDialog = useCallback(
        id => {
            setShowRemoveDialog(id)
        },
        [setShowRemoveDialog]
    )

    const onRemoveEnotice = useCallback(async () => {
        try {
            const data = await removeEnotice({
                variables: {
                    enotno,
                },
            })

            onRemove && onRemove(data)

            onCloseRemove()
        } catch (error) {
            // TODO: Exibir dialogo de erro
            console.error(error)
        }
    }, [enotno, removeEnotice, onRemove, onCloseRemove])

    return {
        showRemoveDialog: enotno,
        onShowRemoveDialog,
        onRemoveEnotice,
        onCloseRemove,
    }
}
