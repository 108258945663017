import { useQuery } from '@apollo/react-hooks'
import { Avatar } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import IconExit from '@material-ui/icons/ExitToApp'
import NavigationExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconInfo from '@material-ui/icons/InfoOutlined'
import { useO2 } from '@nerus/framework/hooks/useO2'
import { styles } from '@nerus/styles/components/topBar/userMenu'
import clsx from 'clsx'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isAdmin } from '../../../App/AppActions'
import DashboardShortcut from '../../../NerusIT/Dashboard/DashboardShortcut'
import IconActions from './IconActions'
import News from './News'

export const GET_ME = gql`
    query Me($usrno: Int!) {
        me(usrno: $usrno) {
            no
            name
            isAdmin
            groups {
                no
            }
        }
    }
`

function UserMenu({
    classes,
    anchorEl,
    nerusInfo,
    isMenuPrincipal,
    onAbout,
    onExit,
    onUserMenu,
    onUserMenuClose,
}) {
    const dispatch = useDispatch()
    const componentsLifo = useSelector(state => state.eac.componentsLifo)
    const { isO2 } = useO2()
    const { data } = useQuery(GET_ME, {
        variables: { usrno: nerusInfo?.usrno },
        skip: !nerusInfo || !nerusInfo.usrno,
    })

    useEffect(() => {
        const user = data?.me ? data.me : null
        if (user) {
            if (user.isAdmin) {
                dispatch(isAdmin())
            }
        }
    }, [data])

    const av = nerusInfo?.usr
        ?.split?.(' ')
        ?.slice?.(0, 2)
        ?.map?.(s => s[0])
        ?.join?.('')

    const userBlock = (
        <Fragment>
            {nerusInfo ? (
                <Fragment>
                    {isO2 && (
                        <Avatar
                            className={classes.userInfoAvatar}
                            variant="circular"
                        >
                            {av}
                        </Avatar>
                    )}

                    {!isO2 && (
                        <Divider
                            className={classes.divider}
                            component={'div'}
                            orientation={'vertical'}
                        />
                    )}

                    <div className={classes.userInfo}>
                        <Typography
                            variant={'h3'}
                            className={clsx(classes.userName, {
                                [classes.userNameO2]: isO2,
                            })}
                            id="nerusInfo-user"
                        >
                            {nerusInfo.usr}
                        </Typography>
                        {nerusInfo.cpy ? (
                            <Typography
                                variant={'h4'}
                                className={clsx(classes.detail, {
                                    [classes.userCompanyO2]: isO2,
                                })}
                                id="nerusInfo-company"
                            >
                                {nerusInfo.cpy}
                            </Typography>
                        ) : null}
                        {!isO2 ? (
                            <div
                                className={classes.version}
                                id="nerusInfo-version"
                            >
                                {nerusInfo.v}
                            </div>
                        ) : null}
                    </div>
                </Fragment>
            ) : null}

            {!isO2 && (
                <Divider
                    className={classes.divider}
                    component={'div'}
                    orientation={'vertical'}
                />
            )}

            <IconButton
                id="expandUserMenu"
                className={clsx(classes.expandButton, {
                    [classes.expandButtonO2]: isO2,
                })}
                onClick={onUserMenu}
            >
                <NavigationExpandMoreIcon />
            </IconButton>
        </Fragment>
    )

    return (
        <div className={classes.root}>
            {isO2 && (
                <IconActions
                    classes={classes}
                    hasActiveComponent={Boolean(componentsLifo.length)}
                    isO2
                />
            )}

            <DashboardShortcut userNo={nerusInfo?.usrno} />

            <News userNo={nerusInfo?.usrno} />

            {isO2 ? (
                <div className={classes.userInfoBlock}>{userBlock}</div>
            ) : (
                userBlock
            )}

            <Menu
                className="menu"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={onUserMenuClose}
            >
                <div className="menuItens">
                    <MenuItem id="menuAbout" onClick={onAbout}>
                        <ListItemIcon>
                            <IconInfo />
                        </ListItemIcon>
                        <ListItemText primary="Sobre" />
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        id="menuLogout"
                        onClick={onExit}
                        disabled={
                            Boolean(!isMenuPrincipal && !isO2) ||
                            Boolean(isO2 && componentsLifo.length)
                        }
                    >
                        <ListItemIcon>
                            <IconExit />
                        </ListItemIcon>
                        <ListItemText primary="Desconectar" />
                    </MenuItem>
                </div>
            </Menu>
        </div>
    )
}

export default React.memo(withStyles(styles)(UserMenu))

UserMenu.propTypes = {
    classes: PropTypes.object,
    anchorEl: PropTypes.any,
    nerusInfo: PropTypes.object,
    isMenuPrincipal: PropTypes.bool,
    onAbout: PropTypes.func,
    onExit: PropTypes.func,
    onUserMenu: PropTypes.func,
    onUserMenuClose: PropTypes.func,
}
