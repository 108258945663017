import { ApolloProvider } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import packageJson from '../package.json'
// Apollo Client GRAPHQL
import client from './config/ApolloClient'
import App from './modules/App/App'
import { configureStore } from './store'

if (process.env.NODE_ENV === 'local') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    })
}

// Initialize store
const store = configureStore(window.__INITIAL_STATE__ || {})

if (process.env.NODE_ENV === 'local') {
    Sentry.init({
        dsn: 'https://452e1e06b72c4ef2a4f43c1e2f7ea3f8@sentry.io/1831323',
        release: 'nerusweb@' + packageJson.version,
        environment: process.env.NODE_ENV,
        beforeBreadcrumb: breadcrumb => {
            if (
                breadcrumb.data &&
                breadcrumb.data.extra &&
                breadcrumb.data.extra.arguments
            ) {
                breadcrumb.data.extra.arguments = breadcrumb.data.extra.arguments.map(
                    arg => ({
                        message: arg.message,
                        name: arg.name,
                        stack: arg.stack,
                    })
                )
            }
            return breadcrumb
        },
    })
}

// render or hydrate?
hydrate(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ApolloProvider>
    </Provider>,
    document.getElementById('root'),
    () => {
        // [ReHydratation](https://github.com/cssinjs/jss/blob/master/docs/ssr.md)
        const jssStyles = document.getElementById('jss-ssr')
        if (jssStyles && jssStyles.parentNode)
            jssStyles.parentNode.removeChild(jssStyles)
    }
)

if (module.hot) {
    module.hot.accept()
}
