import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import keycodes from '@nerus/framework/common/Keycodes'
import StyledButton from '@nerus/framework/styled/Button'
import { styles } from '@nerus/styles/components/fileSend'
import Axios from 'axios'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { removeComponent } from '../../Eac/EacActions'
import { getComponentById } from '../../Eac/EacReducer'
import BaseFlutuante from '../Flutuante/BaseFlutuante'

export class FilePreviewer extends EnhancedComponent {
    static mapStateToProps = (state, ownProps) =>
        getComponentById(ownProps.id, state).payload

    static propTypes = {
        rows: PropTypes.array,
        title: PropTypes.string,
        editorId: PropTypes.string,
        fields: PropTypes.array,
    }

    static baseOpts = {
        skip: true,
    }

    state = {
        uri: '',
        loading: false,
        error: false,
        downloadingProgress: 0,
    }

    componentDidMount() {
        const {
            data: { localFileName },
        } = this.props

        const uri = `/webprint/load${localFileName}.txt`

        this.setState({
            uri,
            loading: true,
        })

        this.downloadFile(uri).then(() => {
            const editor = this.getRef('editor')
            if (editor) {
                editor.addEventListener('keydown', this.onKeydown)
            }
        })
    }

    downloadFile = uri => {
        return Axios.head(uri)
            .then(res => {
                if (res.status === 200) {
                    return Axios.get(uri, {
                        onDownloadProgress: progressEvent => {
                            const totalLength = progressEvent.lengthComputable
                                ? progressEvent.total
                                : progressEvent.target.getResponseHeader(
                                      'content-length'
                                  ) ||
                                  progressEvent.target.getResponseHeader(
                                      'x-decompressed-content-length'
                                  )
                            if (totalLength !== null) {
                                this.setState({
                                    downloadingProgress: Math.round(
                                        (progressEvent.loaded * 100) /
                                            totalLength
                                    ),
                                })
                            }
                        },
                    }).then(res => {
                        this.setState({
                            loading: false,
                            content: res.data,
                        })
                    })
                } else {
                    this.setState({
                        error: true,
                        loading: false,
                        details: {
                            code: res.status,
                        },
                    })
                }
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: true,
                    details: e,
                })
            })
    }

    onKeydown = event => {
        event.stopImmediatePropagation && event.stopImmediatePropagation()

        if (event.keyCode === keycodes.ESCAPE_KEY) {
            this.onClose()
        }
    }

    onClose = () => {
        this.props.dispatch(removeComponent('FilePreviewer'))
    }

    saveFile = () => {
        const {
            data: { localFileName },
        } = this.props
        const uri = `/webprint/load${localFileName}.txt`
        window.open(uri)
    }

    renderEditUpload() {
        const {
            state: { downloadingProgress, loading, content },
            props: { classes },
        } = this

        return (
            <React.Fragment>
                {loading ? (
                    <div className={classes.fileIsTooBig}>
                        <div className={classes.progress}>
                            <div className={classes.progressIndicator}>
                                {downloadingProgress > 0
                                    ? downloadingProgress === 100
                                        ? 'Processo concluído.'
                                        : `Carregando: ${downloadingProgress}%`
                                    : 'Iniciando processo...'}
                            </div>

                            <LinearProgress
                                variant={'determinate'}
                                value={downloadingProgress}
                                size={120}
                                color={'primary'}
                                className={classes.progressBar}
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}

                <TextField
                    autoFocus
                    multiline
                    fullWidth
                    rows={42}
                    variant="filled"
                    inputRef={this.createRef('editor')}
                    value={content}
                    className={classes.editorRoot}
                    InputProps={{
                        classes: {
                            root: classes.editorBackground,
                            input: classes.editor,
                        },
                    }}
                />

                <div className={classes.actions}>
                    <StyledButton dialog onClick={this.saveFile} primary>
                        Salvar
                    </StyledButton>
                    <StyledButton dialog onClick={this.onClose}>
                        Fechar
                    </StyledButton>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const {
            props: {
                data: { title, localFileName },
                classes,
            },
        } = this

        let component = this.renderEditUpload()

        if (component) {
            component = <Box className={clsx(classes.root)}>{component}</Box>
        } else {
            component = (
                <Typography color={'primary'} variant={'h4'}>
                    Operação não implementada.
                </Typography>
            )
        }

        return (
            <BaseFlutuante
                size="fullscreen"
                handleClose={this.onClose}
                title={title || `Visualizador de Arquivo: ${localFileName}.txt`}
            >
                {component}
            </BaseFlutuante>
        )
    }
}

export default connect(FilePreviewer.mapStateToProps)(
    withStyles(styles)(FilePreviewer)
)
