export const initialProperties = {
    keys: ['y'],
    indexBy: 'x',
    groupMode: 'grouped',

    margin: { top: 20, right: 20, bottom: 80, left: 80 },
    padding: 0.3,
    innerPadding: 0,
    minValue: 'auto',
    maxValue: 'auto',

    // groupMode: 'stacked',
    // layout: 'vertical',
    reverse: false,

    valueScale: { type: 'linear' },
    indexScale: { type: 'band', round: true },
    valueFormat: { format: '', enabled: false },

    colors: { scheme: 'NerusWeb' },
    colorBy: 'id',
    defs: [
        // patternDotsDef('dots', {
        //     background: 'inherit',
        //     color: '#38bcb2',
        //     size: 4,
        //     padding: 1,
        //     stagger: true,
        // }),
        // patternLinesDef('lines', {
        //     background: 'inherit',
        //     color: '#eed312',
        //     rotation: -45,
        //     lineWidth: 6,
        //     spacing: 10,
        // }),
    ],
    fill: [
        // { match: { id: 'fries' }, id: 'dots' },
        // { match: { id: 'sandwich' }, id: 'lines' },
    ],
    layers: ['grid', 'axes', 'bars', 'markers', 'legends', 'annotations'],
    borderRadius: 0,
    borderWidth: 0,
    borderColor: {
        from: 'color',
        modifiers: [['darker', 1.6]],
    },
    axisTop: null,
    axisRight: null,
    axisBottom: {
        enable: true,
        legendPosition: 'middle',
        legendOffset: 60,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
    },
    axisLeft: {
        enable: true,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: -75,
    },

    enableGridX: false,
    enableGridY: true,

    enableLabel: true,
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor:
        '#ffffff' /*{
        from: 'color',
        modifiers: [['darker', 1.6]],
    }*/,

    showLegend: true,
    legends: [
        {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemOpacity: 1,
                    },
                },
            ],
        },
    ],

    isInteractive: true,
    animate: true,
    motionConfig: 'default',
    motionStiffness: 90,
    motionDamping: 15,
}
