import React from 'react'

export default function BoxIcon() {
    return (
        <svg
            width="28"
            height="30"
            viewBox="0 0 28 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.6349 1.35361C13.0525 1.1217 13.5223 1 14 1C14.4776 1 14.9474 1.1217 15.365 1.35361L25.93 7.22164C26.1491 7.34344 26.3316 7.52159 26.4586 7.73762C26.5857 7.95366 26.6527 8.19973 26.6526 8.45035V20.0276C26.6525 20.5289 26.5183 21.0212 26.264 21.4533C26.0096 21.8854 25.6444 22.2416 25.206 22.485L15.365 27.9538C14.9474 28.1857 14.4776 28.3074 14 28.3074C13.5223 28.3074 13.0525 28.1857 12.6349 27.9538L2.79391 22.485C2.35579 22.2417 1.99065 21.8857 1.73631 21.4539C1.48197 21.0221 1.34766 20.5301 1.34729 20.029V8.45035C1.34728 8.19973 1.41427 7.95366 1.54132 7.73762C1.66837 7.52159 1.85086 7.34344 2.0699 7.22164L12.6363 1.35361H12.6349Z"
                stroke="#8862E0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14 14.653V28.7115M1.34729 7.62371L14 14.653L1.34729 7.62371ZM14 14.653L26.6526 7.62371L14 14.653Z"
                stroke="#8862E0"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                d="M7.67358 11.1383L20.3263 4.10905"
                stroke="#8862E0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.56482 15.1141L9.78238 17.4647"
                stroke="#8862E0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
