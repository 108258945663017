import Alphanumeric from './Alphanumeric'
import Anything from './Anything'
import Bit from './Bit'
import CaseChange from './CaseChange'
import LeftJustify from './LeftJustify'
import Numeric from './Numeric'
import Password from './Password'
import Web from './Web'

export default {
    a: Anything,
    b: Bit,
    c: Anything,
    d: Numeric,
    D: function(mask) {
        return new Numeric(mask).required()
    },
    e: CaseChange,
    E: function(mask) {
        return new CaseChange(mask).uppercase()
    },
    f: Alphanumeric,
    F: function(mask) {
        return new Alphanumeric(mask).uppercase()
    },
    l: LeftJustify,
    m: function(mask) {
        return new CaseChange(mask)
            .uppercase()
            .required()
            .dontJump()
    },
    n: function(mask) {
        return new CaseChange(mask).uppercase().dontJump()
    },
    M: function(mask) {
        return new CaseChange(mask)
            .uppercase()
            .required()
            .dontJump()
            .fromEnd()
    },
    N: function(mask) {
        return new CaseChange(mask)
            .uppercase()
            .dontJump()
            .fromEnd()
    },
    p: Password,
    P: function(mask) {
        return new Password(mask).required()
    },
    s: function(mask) {
        return new Anything(mask).required()
    },
    w: Web,
    W: function(mask) {
        return new Web(mask).required()
    },
    x: function(mask) {
        return new Numeric(mask).dontJump()
    },
    X: function(mask) {
        return new Numeric(mask).required().dontJump()
    },
    y: function(mask) {
        return new Numeric(mask)
    },
    Y: function(mask) {
        return new Numeric(mask).required()
    },
    z: function(mask) {
        return new CaseChange(mask)
            .uppercase()
            .required()
            .dontJump()
            .fromEnd()
    },
    '?': function(mask) {
        return new Bit(mask).allowQuestion()
    },
    '#': Numeric,
}
