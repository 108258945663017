export const styles = theme => ({
    root: {},
    columns: {
        display: 'flex',
        flex: '1 1 100%',
    },
    column: {
        flex: 1,
        borderRight: '1px solid #dedede',
        '&:last-child': {
            borderRight: 0,
        },
    },
    titles: {
        display: 'flex',
        padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        margin: `${theme.spacing(1)}px`,
        borderBottom: '1px solid #dedede',
    },
    title: {
        flex: '1 1 100%',
        paddingRight: theme.spacing(3),
    },
    titleSecondary: {
        minWidth: 80,
        textAlign: 'right',
    },
    body: {},
    toggleWrapper: {
        display: 'flex',
        padding: `0 ${theme.spacing(2)}px`,
        position: 'relative',
    },
    toggle: {
        position: 'relative',
        top: -18,
    },
    toggleDisabled: {},
    label: {
        whiteSpace: 'nowrap',
        width: '100%',
        cursor: 'pointer',
        paddingRight: 62,
        fontSize: 16,
    },
    actions: {
        margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
        padding: `${theme.spacing(1)}px 0 0`,
        borderTop: '1px solid #dedede', // TODO: Usar cor do tema
    },
    selectedLabel: {
        backgroundColor: 'rgba(215, 152, 157, 0.33)',
        opacity: 1,
    },
})
