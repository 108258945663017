import PropTypes from 'prop-types'
import React from 'react'

import GraficoBarra from '../../../components/GraficoBarra'
import GraficoBarraGrouped from '../../../components/GraficoBarraGrouped'
import GraficoBarraHor from '../../../components/GraficoBarraHor'
import GraficoBarraLinha from '../../../components/GraficoBarraLinha'
import GraficoBarraStacked from '../../../components/GraficoBarraStacked'
import GraficoLinha from '../../../components/GraficoLinha'
import GraficoPizza from '../../../components/GraficoPizza'
import {
    TYPE_GRAPH_BAR,
    TYPE_GRAPH_BAR_AND_LINE,
    TYPE_GRAPH_BAR_GROUPED,
    TYPE_GRAPH_BAR_HOR,
    TYPE_GRAPH_BAR_STACKED,
    TYPE_GRAPH_LINE,
    TYPE_GRAPH_PIZZA,
} from '../../Components/constants'
import { sampleData as sampleDataBar, sampleDataGrouped } from './props/bar'
import { sampleData as sampleDataLine } from './props/line'
import { sampleData as sampleDataPie } from './props/pie'

export default function SampleGraph({ type, settings }) {
    switch (type) {
        case TYPE_GRAPH_PIZZA:
            return (
                <GraficoPizza
                    axes={{}}
                    data={sampleDataPie}
                    custom={settings}
                />
            )
        case TYPE_GRAPH_LINE:
            return (
                <GraficoLinha
                    axes={{}}
                    data={[{ id: 'sample', data: sampleDataLine }]}
                    custom={settings}
                />
            )
        case TYPE_GRAPH_BAR:
            return (
                <GraficoBarra
                    axes={{}}
                    data={[{ data: sampleDataBar }]}
                    custom={settings}
                />
            )
        case TYPE_GRAPH_BAR_AND_LINE:
            return (
                <GraficoBarraLinha
                    axes={{}}
                    data={[{ data: sampleDataBar }]}
                    custom={settings}
                />
            )
        case TYPE_GRAPH_BAR_HOR:
            return (
                <GraficoBarraHor
                    axes={{}}
                    data={[{ data: sampleDataBar }]}
                    custom={settings}
                />
            )
        case TYPE_GRAPH_BAR_GROUPED:
            return (
                <GraficoBarraGrouped
                    axes={{}}
                    data={[
                        {
                            data: sampleDataGrouped,
                            legends: ['Coluna 1', 'Coluna 2', 'Coluna 3'],
                        },
                    ]}
                    custom={settings}
                />
            )
        case TYPE_GRAPH_BAR_STACKED:
            return (
                <GraficoBarraStacked
                    axes={{}}
                    data={[
                        {
                            data: sampleDataGrouped,
                            legends: ['Coluna 1', 'Coluna 2', 'Coluna 3'],
                        },
                    ]}
                    custom={settings}
                />
            )
        default:
            return 'Tipo de gráfico inválido.'
    }
}

SampleGraph.propTypes = {
    type: PropTypes.number,
    settings: PropTypes.any,
}
