import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function MonitorIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0,19.212)">
                <path d="M 874,645 H 125 V 147 h 749 z m 63,125 q 26,0 44.5,-18.5 Q 1000,733 1000,708 V 84 Q 1000,58 981,39.5 962,21 937,21 H 62 Q 37,21 18.5,39.5 0,58 0,84 v 624 q 0,26 18,44 18,18 44,18 z M 562,-103 h 312 v -66 H 125 v 66 h 312 v 63 h 125 z" />
            </g>
        </SvgIcon>
    )
}
