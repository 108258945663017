import keycodes from '@nerus/framework/common/Keycodes'

import { sendBuffer } from '../EacActions'
import Menu from './Menu'

export function handleKeyboard(ws) {
    return (dispatch, o /*, data*/) => {
        if (o.key === keycodes.ESCAPE_KEY) {
            ws.send(
                sendBuffer(
                    {
                        key: keycodes.ESCAPE_KEY,
                    },
                    'RelatorioDialogGeradorRet'
                )
            )
        }
    }
}

export default class RelatorioDialogGerador extends Menu {
    static before({ state, action }) {
        action.payload.disableKeyboard = true
        action.payload.keyboardActions = handleKeyboard
        return { state, action }
    }
}
