import React, { forwardRef, useMemo } from 'react'

export const WebsocketContext = React.createContext(null)
export const WaitingContext = React.createContext(false)

export function withWS(Component) {
    const ConnectedComponent = forwardRef(function ConnectedComponent(
        props,
        ref
    ) {
        const WaitingConsumer = useMemo(() => {
            const Waiting = waiting => (
                <WebsocketContext.Consumer>
                    {ws => (
                        <Component
                            ref={ref}
                            {...props}
                            ws={ws.current}
                            waiting={waiting}
                        />
                    )}
                </WebsocketContext.Consumer>
            )

            return Waiting
        }, [Component, props])

        return (
            <WaitingContext.Consumer>{WaitingConsumer}</WaitingContext.Consumer>
        )
    })

    ConnectedComponent.displayName = Component.displayName || Component.name
    if (Component.baseOpts !== undefined) {
        ConnectedComponent.baseOpts = Component.baseOpts || {}
    }

    if (Component.withoutWrapper !== undefined) {
        ConnectedComponent.withoutWrapper = Component.withoutWrapper
    }

    return ConnectedComponent
}
