import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function ReportIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0.024,19.2)">
                <path d="M 296,139 H 397 V -30 H 296 Z M 448,273 H 549 V -32 H 448 Z M 600,410 H 702 V -30 H 600 Z m 203,270 q 42,0 72,-30.5 30,-30.5 30,-72.5 v -677 q 0,-42 -30,-71 -30,-29 -72,-29 H 194 q -42,0 -71.5,29 -29.5,29 -29.5,71 v 677 q 0,42 29.5,72.5 Q 152,680 194,680 h 68 V 579 H 194 V -98 H 803 V 579 H 736 V 680 Z M 323,511 q -11,0 -19,8.5 -8,8.5 -8,20.5 v 140 l 79,29 19,30 q 15,28 43,44.5 28,16.5 60,16.5 71,0 104,-61 l 19,-30 82,-29 V 540 q 0,-12 -8.5,-20.5 Q 685,511 674,511 Z m 174,227 q -23,0 -40,-16.5 -17,-16.5 -17,-40.5 0,-23 17,-40 17,-17 40,-17 24,0 41,17 17,17 17,40 0,24 -17,40.5 -17,16.5 -41,16.5 z" />
            </g>
        </SvgIcon>
    )
}
