import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function BuildingIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0,19.2)">
                <path d="m 0,-167 h 1000 v -33 H 0 Z M 76,713 V -135 H 54 v 870 h 94 v 65 h 379 v -65 h 94 V 638 H 867 V 516 h 78 V -135 H 600 V 516 713 Z M 622,617 V 517 h 224 v 100 z m -1,-200 v -43 h 257 v 43 z m 0,-116 v -43 h 257 v 43 z m 0,-116 v -42 h 257 v 42 z M 621,69 V 27 h 257 v 42 z m 0,-115 v -43 h 257 v 43 z M 123,417 H 552 V 374 H 123 Z M 123,69 H 552 V 27 H 123 Z m 0,464 H 552 V 490 H 123 Z m 0,-579 H 552 V -89 H 123 Z m 0,231 H 552 V 143 H 123 Z m 0,116 H 552 V 258 H 123 Z m 0,348 H 552 V 606 H 123 Z" />
            </g>
        </SvgIcon>
    )
}
