import PropTypes from 'prop-types'
import React from 'react'

import useFetchTable from '../hooks/useFetchTable'
import BaseSelector from './BaseSelector'

export default function UserSelector({ dashboard, onSave }) {
    const { data } = useFetchTable({
        variables: {
            type: 18, // type No. Usuarios
        },
    })
    return (
        <BaseSelector
            title="Usuários"
            label="usuário"
            onSave={onSave}
            data={data?.fetchTable?.data || []}
            currentData={dashboard?.users}
        />
    )
}

UserSelector.propTypes = {
    dashboard: PropTypes.shape({
        users: PropTypes.arrayOf(
            PropTypes.shape({
                no: PropTypes.number,
                title: PropTypes.string,
            })
        ),
    }),
    onSave: PropTypes.func.isRequired,
}
