export const styles = theme => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        zIndex: 99999,
        cursor: 'default',
    },
    title: {
        fontSize: 12,
        padding: '6px 12px',
        margin: 0,
        textTransform: 'uppercase',
        fontWeight: 700,
        borderBottom: '1px solid #eee',
    },
    close: {
        width: 18,
        height: 18,
        padding: 0,
        float: 'right',
        marginTop: -2,
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    content: {
        position: 'absolute',
        background: '#fff',
        padding: 0,
        '&::after': {
            content: "' '",
            background: theme.palette.common.white,
            transform: 'rotate(45deg)',
            width: 15,
            height: 15,
            position: 'absolute',
            bottom: -7,
            right: 3,
            borderRadius: 2,
        },
    },
    list: {
        position: 'relative',
        zIndex: 1,
        margin: 0,
        padding: 4,
        listStyle: 'none',
    },
    listItem: {
        padding: '5px 15px',
        fontSize: 14,
    },
})
