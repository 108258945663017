import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function UserIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0.024,19.2)">
                <path d="M 795,458 Q 795,316 708,215.5 621,115 499,115 377,115 290,215.5 203,316 203,458 q 0,142 87,242 87,100 209,100 122,0 209,-100 87,-100 87,-242 z M 667,131 957,-106 v -94 H 41 v 94 l 290,237 q 76,-53 168,-53 90,0 168,53 z" />
            </g>
        </SvgIcon>
    )
}
