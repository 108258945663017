import React from 'react'

import keycodes, { controlKeys } from '../../../../common/Keycodes'
import { RECS } from '../../../../hooks/useOverwrite'
import { Input } from '../../../../styled/Input'
import FieldType from '../FieldType'
import AbstractTextField from './AbstractTextField'

const debug = require('debug')('nerus:StringMultiline')

/**
 * Componente StringMultilineField
 *
 * Rec: REC_STR_LINES
 *
 * Renderiza um campo de texto
 */
export class StringMultilineField extends AbstractTextField {
    constructor(props) {
        super(props)

        this.state = { value: props.value || '' }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps)

        if (!prevProps.enabled && this.props.enabled) {
            const input = this.getRef('input')
            // Força o caret a ir para o inicio quando o campo é ativado
            input.selectionStart = input.selectionEnd = 0
        }
    }

    beforeSend = text => {
        // insere 1 nova linha no fim para evitar 1 caracter ser removido
        return `${text}\n`
    }

    handleInputKeydown = event => {
        event.persist && event.persist()

        let {
            target: { selectionStart },
        } = event

        const { ln, lsz, sz } = this.props
        const {
            target: { value },
            keyCode,
        } = event
        const bypassKeys = [
            keycodes.RIGHT_ARROW_KEY,
            keycodes.LEFT_ARROW_KEY,
            keycodes.RIGHT_CTRL_KEY,
            keycodes.DOWN_ARROW_KEY,
            keycodes.BACKSPACE_KEY,
            keycodes.PAGE_DOWN_KEY,
            keycodes.UP_ARROW_KEY,
            keycodes.PAGE_UP_KEY,
            keycodes.DELETE_KEY,
            keycodes.SHIFT_KEY,
            keycodes.CTRL_KEY,
            keycodes.HOME_KEY,
            keycodes.ALT_KEY,
            keycodes.END_KEY,
        ]
        const isControlKey = controlKeys.indexOf(keyCode) > -1
        const isBypassedKey = bypassKeys.indexOf(keyCode) > -1
        const isEscape = keyCode === keycodes.ESCAPE_KEY
        const isEnter = keyCode === keycodes.ENTER_KEY
        const isShiftEnter = isEnter && event.shiftKey
        const isBackspace = keyCode === keycodes.BACKSPACE_KEY
        const isArrowUp = keyCode === keycodes.UP_ARROW_KEY
        const isArrowDown = keyCode === keycodes.DOWN_ARROW_KEY
        const unparsedLines = String(value).split('\n')
        const lines = unparsedLines.length
        const parsedText = `${value}`

        if (isEscape) {
            return
        }

        let currentSelLines = parsedText
            .substr(0, selectionStart)
            .match(new RegExp(`.*(\n)*`, 'g')) || [
            parsedText.substr(0, selectionStart),
        ]

        if (
            currentSelLines.length >= 2 &&
            currentSelLines[currentSelLines.length - 2] &&
            currentSelLines[currentSelLines.length - 2].indexOf('\n') === -1
        ) {
            // se a última linha não possui \n removemos a linha em branco
            // comportamento do próprio regex, mas, útil.
            currentSelLines.pop()
        }

        const currentLineNumber = currentSelLines.length
        const isFullOfLines = lines === ln
        const isLineFullSize = unparsedLines[currentLineNumber - 1]
            ? unparsedLines[currentLineNumber - 1].length >= lsz
            : false

        // preenche as linhas anteriores com espaço se necessário
        // permite sabermos o tamanho total em uso do campo
        let cleanedAndCompleteValue
        unparsedLines.forEach((line, index) => {
            const cleanedLine = line.replace('\n', '')
            unparsedLines[index] =
                cleanedLine.length < lsz &&
                index !== unparsedLines.length - 1 &&
                index + 1 !== currentLineNumber
                    ? cleanedLine +
                      ' '.repeat(
                          lsz - cleanedLine.length > 0
                              ? lsz - cleanedLine.length
                              : 0
                      )
                    : line
        })
        cleanedAndCompleteValue = unparsedLines.join('')

        const isFullSize = cleanedAndCompleteValue.length >= sz

        if (isEnter && !event.shiftKey) {
            event.preventDefault()
        } else if (
            isBypassedKey ||
            isFullOfLines ||
            isFullSize ||
            (isLineFullSize && isFullSize)
        ) {
            if (isFullOfLines && isEnter) {
                event.preventDefault()
            }

            if (
                !isControlKey &&
                isLineFullSize &&
                ((isFullSize && !isBackspace) || (!isFullSize && isFullOfLines))
            ) {
                debug(`Previne linha cheia e/ou corpo cheio`)
                event.preventDefault()
                return
            }

            if (
                (isArrowUp && currentLineNumber === 1) ||
                (isArrowDown && currentLineNumber === lines)
            ) {
                // do nothing
            } else {
                debug(`Prevent WS message send`)
                event.stopImmediatePropagation &&
                    event.stopImmediatePropagation()
            }
            return
        }

        if (!isFullOfLines) {
            if (isShiftEnter) {
                debug(`New line - by shift + enter !`)
                event.stopImmediatePropagation &&
                    event.stopImmediatePropagation()
                return
            }

            if (
                lines < ln &&
                cleanedAndCompleteValue.length > 0 &&
                cleanedAndCompleteValue.length % lsz === 0 &&
                currentSelLines[currentLineNumber - 1].length > 0 &&
                currentSelLines[currentLineNumber - 1].length % lsz === 0 &&
                !isControlKey &&
                !isBackspace
            ) {
                debug(`New line - by hitting the end of the line !`)
                event.target.value += '\n'
                selectionStart++
                event.target.selectionStart = event.target.selectionEnd = selectionStart
            }
        }
    }

    handleInputChange = (event, callback) => {
        const { onChange } = this.props
        event.persist && event.persist()

        const fieldType = this.getFieldType()
        let selectionStart = event.target ? event.target.selectionStart : 0

        let value = event.target.value
        if (fieldType && RECS.indexOf(fieldType.typeRec) === -1) {
            if (fieldType.formatter) {
                value = fieldType.formatter(value)
                debug(`apply formatter: ${value}`)
            }

            if (fieldType && fieldType.modifier) {
                value = fieldType.modifier(value)
                debug(`apply modifier: ${value}`)
            }
        }

        this.setState(
            {
                value,
            },
            () => {
                if (event.target) {
                    event.target.selectionStart = event.target.selectionEnd = selectionStart
                }

                callback && callback(event)
                onChange && onChange(this.state.value, event)
            }
        )
    }

    getFieldType = () => {
        const defaultFieldTypeClass = FieldType['n']
        return new defaultFieldTypeClass()
    }

    render() {
        const {
            props: {
                // numeros de linhas a serem exibidas
                lns,
                orientation,
                enabled,
                lbl,
                index,
                x,
                y,
                labelWidth,
                inputWidth,
                componentId,
                InputProps,
                onBlur,
                onFocus,
            },
            state: { value },
        } = this

        const fieldType = this.getFieldType()

        return (
            <Input
                orientation={orientation}
                multiline
                fieldType={fieldType}
                InputProps={InputProps}
                beforeSend={this.beforeSend}
                label={lbl}
                tabIndex={index}
                ref={this.createRef('input')}
                className="input-form"
                value={value}
                position={{ x, y }}
                disabled={!enabled}
                helperText="* Para pular uma linha, use <SHIFT> + <ENTER>"
                style={{}}
                componentId={componentId}
                labelWidth={labelWidth}
                inputWidth={inputWidth}
                onFocus={onFocus}
                onBlur={onBlur}
                autoComplete="off"
                rows={lns}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeydown}
                autoFocusOnBlur={false}
                preventDispatchToWS={true}
            />
        )
    }
}

export default StringMultilineField
