import keycodes, { navigationKeys } from '@nerus/framework/common/Keycodes'
import { StyledKeyboardDatePicker } from '@nerus/framework/styled/DatePicker'
import moment from 'moment/moment'
import * as PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { datePickerClose, datePickerOpen } from '../../../../../App/AppActions'
import { withWS } from '../../../../Business/Websocket/Context'
import { sendBuffer } from '../../../../Eac/EacActions'
import AbstractTextField from './AbstractTextField'

/**
 * Componente TextFieldDate
 *
 * Rec: REC_DATE
 *
 * Renderiza um campo de texto que tem formatação de data
 * com datepicker
 */
export class TextFieldDate extends AbstractTextField {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        componentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }

    views = ['year', 'month', 'date']
    format = 'YYYYMMDD'
    displayFormat = 'DD/MM/YYYY'
    separator = '/'
    sendFormat = 'DDMMYYYY'
    zeroedValue = this.sendFormat.replace(/./g, '0')

    constructor(props) {
        super(props)
        this.state = {
            value: props.value ? props.value.trim() : '',
            isPickerOpen: false,
            steps: 0,
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.enabled !== nextProps.enabled ||
            this.props.value !== nextProps.value ||
            this.state.isPickerOpen !== nextState.isPickerOpen ||
            this.state.value !== nextState.value
        )
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps)

        if (!this.props.enabled && this.state.isPickerOpen) {
            this.setState({ isPickerOpen: false, steps: 0 })
            this.props.dispatch && this.props.dispatch(datePickerClose())
        }
    }

    toggleState = (isPickerOpen, callback) => {
        this.setState({ isPickerOpen }, callback)
        const input = this.getRef('input')
        if (!isPickerOpen && input && input.current) {
            input.current.focus()
        }
    }

    timer = null

    onCaretChange = e => {
        e.persist && e.persist()
        clearTimeout(this.timer)
        if (!navigationKeys.includes(e.keyCode)) {
            const maxSizing = this.displayFormat.length || 10
            this.timer = setTimeout(() => {
                if (
                    e.target &&
                    e.target.selectionStart === e.target.selectionEnd &&
                    e.target.selectionStart === maxSizing
                ) {
                    this.triggerKeyCode(keycodes.ENTER_KEY)
                }
            }, 50)
        }
    }

    onDateChange = (date, callback) => {
        if (date && date.format) {
            this.handleInputChange(
                {
                    target: {
                        value:
                            date && date.isValid()
                                ? date.format(
                                      this.props.value.indexOf(this.separator) >
                                          -1
                                          ? this.displayFormat
                                          : this.format
                                  )
                                : date,
                    },
                },
                callback
            )
        }
    }

    triggerKeyCode = keyCode => {
        const { x, y, ws } = this.props

        const value = this.beforeSend
            ? this.beforeSend(this.state.value, { keyCode })
            : this.state.value

        ws.send(
            sendBuffer(
                {
                    x,
                    y,
                    key: keyCode,
                    value,
                },
                'sendEdit'
            )
        )
    }

    onDateAccept = date => {
        this.onDateChange(date, () => {
            this.triggerKeyCode(keycodes.ENTER_KEY)
        })
    }

    beforeSend = (value, event) => {
        if (
            event &&
            event.keyCode === keycodes.ESCAPE_KEY &&
            this.state.isPickerOpen
        ) {
            event.stopImmediatePropagation && event.stopImmediatePropagation()
            event.stopPropation && event.stopPropation()
        }
        if (value) {
            const regex = new RegExp(this.separator, 'g')
            const sendValue = value.toString().replace(regex, '')
            const date = moment(
                this.state.value,
                this.props.value.indexOf(this.separator) > -1
                    ? this.displayFormat
                    : this.format
            )
            if (this.state.value || sendValue) {
                if (date.isValid()) {
                    this.props.dispatch &&
                        this.props.dispatch(datePickerClose())
                    return date.format(
                        this.props.value.indexOf(this.separator) > -1
                            ? this.displayFormat
                            : this.sendFormat
                    )
                }
                return sendValue
            }
        }
        return this.zeroedValue
    }

    onOpen = () => {
        this.toggleState(true, () => {
            this.props.dispatch(datePickerOpen())
        })
    }

    onClose = () => {
        this.toggleState(false, () => {
            setTimeout(() => {
                this.props.dispatch(datePickerClose())
            }, 100)
        })
    }

    onKeyDown = e => {
        e.preventDefault()
        e.stopPropagation()
        e.stopImmediatePropagation && e.stopImmediatePropagation()
    }

    render() {
        let { value, isPickerOpen } = this.state
        const {
            lbl,
            enabled,
            orientation = 'horizontal',
            x,
            y,
            inputWidth,
            labelWidth,
            componentId,
            InputProps,
            dispatch,
        } = this.props

        const fieldType = this.getFieldType()

        if (value === this.zeroedValue) {
            value = null
        }

        if (value && !(value instanceof moment)) {
            value = moment(
                value,
                this.props.value.indexOf(this.separator) > -1
                    ? this.displayFormat
                    : this.format
            )
        }

        return (
            <StyledKeyboardDatePicker
                orientation={orientation}
                value={value}
                fieldType={fieldType}
                disabled={!enabled}
                open={isPickerOpen}
                componentId={componentId}
                InputProps={InputProps}
                dispatch={dispatch}
                onOpen={this.onOpen}
                onClose={this.onClose}
                position={{ x, y }}
                format={this.displayFormat}
                views={this.views}
                onChange={this.onDateChange}
                onAccept={this.onDateAccept}
                onKeyDown={this.onCaretChange}
                beforeSend={this.beforeSend}
                ref={this.createRef('input')}
                InputAdornmentProps={{
                    position: 'end',
                    onKeyDown: this.onKeyDown,
                }}
                inputWidth={inputWidth}
                labelWidth={labelWidth}
                label={lbl}
                dispatchToWs={true}
                overwrite={true}
            />
        )
    }
}

export default connect()(withWS(TextFieldDate))
