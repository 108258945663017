import { useEffect } from 'react'

export function useKeyboardTrap(onKeydown, options) {
    useEffect(() => {
        document.addEventListener('keydown', onKeydown, options)

        return () => {
            document.removeEventListener('keydown', onKeydown, options)
        }
    }, [onKeydown])
}
