import PropTypes from 'prop-types'
import React, { Component } from 'react'

class AutoScalingText extends Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node,
    }

    state = {
        scale: 1,
    }

    componentDidUpdate() {
        const { scale } = this.state

        const node = this.node
        const parentNode = node.parentNode

        const availableWidth = parentNode.offsetWidth
        const actualWidth = node.offsetWidth
        const actualScale = availableWidth / actualWidth

        if (scale === actualScale) return

        if (actualScale < 1) {
            this.setState({ scale: actualScale })
        } else if (scale < 1) {
            this.setState({ scale: 1 })
        }
    }

    setRef = node => (this.node = node)

    render() {
        const {
            state: { scale },
            props: { className, children },
        } = this

        return (
            <div
                className={className}
                style={{
                    transform: `scale(${scale},${scale})`,
                    display: 'inline-block',
                    transformOrigin: 'left',
                }}
                ref={this.setRef}
            >
                {children}
            </div>
        )
    }
}
export default AutoScalingText
