export const styles = () => ({
    root: {
        color: '#000000',
        zIndex: 1503,
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.1,
        fontStyle: 'italic',
        borderRadius: 2,
        position: 'fixed',
        textAlign: 'justify',
        whiteSpace: 'normal',
        backgroundColor: 'rgba(239, 214, 77, 0.8)',
        boxShadow: '0 2px 4px rgba(0,0,0,0.35)',
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing',
        },
    },
    handle: {
        touchAction: 'none',
        position: 'absolute',
        width: 20,
        height: 20,
        bottom: 0,
        right: 0,
        // background:
        //     "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'content-box',
        padding: '0 3px 3px 0',
        boxSizing: 'border-box',
        // cursor: 'se-resize',
    },
})
