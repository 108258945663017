import defaultsDeep from 'lodash/defaultsDeep'

import Interface from './Interface'

export default class EditorDuplo extends Interface {
    static before({ state, action }) {
        // Separamos os campos para criar 2 componentes distintos
        const { active, editor1, editor2 } = action.payload

        // Criamos a estrutura do Editor principal (cima)
        const Editor1 = {
            name: 'Editor',
            payload: {
                id: editor1.id,
                ...editor1.data,
                enabled: active === 1,
            },
        }

        // Criamos a estrutura do Editor detalhe (baixo)
        const Editor2 = {
            name: 'Editor',
            payload: {
                id: editor2.id,
                ...editor2.data,
                enabled: active === 2,
            },
        }

        if (state.components[editor1.id]) {
            Editor1.payload = defaultsDeep(
                Editor1.payload,
                state.components[editor1.id].payload || {}
            )
        }

        if (state.components[editor2.id]) {
            Editor2.payload = defaultsDeep(
                Editor2.payload,
                state.components[editor2.id].payload || {}
            )
        }

        state.components[editor1.id] = Editor1
        state.components[editor2.id] = Editor2

        state.components[editor1.id].payload.flt = false
        action.payload.hasTripleId = true
        action.payload.editor1.flt = false
        action.payload.editor1.id = editor1.id
        action.payload.editor2.id = editor2.id

        return { state, action }
    }

    static buildDefaultsDeepSource(payload = {}) {
        payload.editor1?.data?.clrs?.forEach(clr => {
            delete clr.lns, delete clr.cols
        })

        payload.editor2?.data?.clrs?.forEach(clr => {
            delete clr.lns, delete clr.cols
        })

        return payload
    }
}
