import PropTypes from 'prop-types'
import React from 'react'

import useFetchGroups from '../hooks/useFetchGroups'
import BaseSelector from './BaseSelector'

export default function GroupsSelector({ dashboard, onSave }) {
    const { data } = useFetchGroups()
    return (
        <BaseSelector
            title="Grupos"
            label="grupo"
            onSave={onSave}
            data={data?.getAllUserGroups}
            currentData={dashboard?.groups}
        />
    )
}

GroupsSelector.propTypes = {
    dashboard: PropTypes.shape({
        groups: PropTypes.arrayOf(
            PropTypes.shape({
                no: PropTypes.number,
                title: PropTypes.string,
            })
        ),
    }),
    onSave: PropTypes.func.isRequired,
}
