import TextFieldNegativableShort from './NegativableShort'

/**
 * Componente TextFieldDouble
 *
 * Rec: REC_DOUBLE
 *
 * Renderiza um campo de texto que tem formatação de casas
 * decimais
 */
export default class TextFieldDouble extends TextFieldNegativableShort {
    beforeSend = value => {
        if (!value) return '0'
        return value.indexOf(',') > -1
            ? value.replace(/\./g, '').replace(/,/g, '.')
            : value
    }
}
