import React from 'react'

export default function ShoppingCartIcon() {
    return (
        <svg
            width="29"
            height="27"
            viewBox="0 0 29 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.7657 5.82176C27.5143 5.38609 27.1542 5.02303 26.7206 4.76799C26.2871 4.51295 25.7948 4.37463 25.2918 4.36653H6.66494L5.82091 1.07772C5.73563 0.760246 5.54538 0.480997 5.28113 0.285455C5.01688 0.089913 4.69422 -0.0104002 4.36568 0.000853462H1.45523C1.06928 0.000853462 0.699134 0.154172 0.426226 0.427079C0.153318 0.699987 0 1.07013 0 1.45608C0 1.84203 0.153318 2.21217 0.426226 2.48508C0.699134 2.75799 1.06928 2.91131 1.45523 2.91131H3.25971L7.27613 17.8419C7.36141 18.1594 7.55167 18.4387 7.81591 18.6342C8.08016 18.8297 8.40282 18.9301 8.73136 18.9188H21.8284C22.0971 18.918 22.3604 18.8428 22.589 18.7015C22.8176 18.5602 23.0026 18.3584 23.1236 18.1184L27.8967 8.57214C28.1036 8.1385 28.1999 7.6604 28.177 7.18048C28.1542 6.70057 28.0129 6.23378 27.7657 5.82176ZM20.9262 16.0083H9.83733L7.46531 7.27699H25.2918L20.9262 16.0083Z"
                fill="white"
            />
            <path
                d="M8.00376 26.1949C9.20931 26.1949 10.1866 25.2176 10.1866 24.0121C10.1866 22.8065 9.20931 21.8293 8.00376 21.8293C6.79821 21.8293 5.82092 22.8065 5.82092 24.0121C5.82092 25.2176 6.79821 26.1949 8.00376 26.1949Z"
                fill="white"
            />
            <path
                d="M22.556 26.1949C23.7616 26.1949 24.7388 25.2176 24.7388 24.0121C24.7388 22.8065 23.7616 21.8293 22.556 21.8293C21.3505 21.8293 20.3732 22.8065 20.3732 24.0121C20.3732 25.2176 21.3505 26.1949 22.556 26.1949Z"
                fill="white"
            />
        </svg>
    )
}
