import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import React from 'react'

import EnhancedComponent from '../../../common/EnhancedComponent'
import keycodes from '../../../common/Keycodes'

const debug = require('debug')('nerus:keyboard')

const styles = () => ({
    labelRoot: {
        top: '-15px !important',
    },
    inputRoot: {
        marginTop: '0 !important',
    },
    svg: {
        verticalAlign: 'middle',
    },
})

class Search extends EnhancedComponent {
    static propTypes = {
        mdQuery: PropTypes.bool,
        placeholder: PropTypes.string,
        value: PropTypes.any,
        classes: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            value: '',
        }
    }

    componentDidUpdate() {
        if (this.getRef('searchField')) {
            this.getRef('searchField').focus()
        }
    }

    handleChange = event => {
        this.setState({
            value: event.target.value,
        })
    }

    clearField = () => {
        this.setState({
            value: '',
        })
    }

    handleKeyDown = event => {
        debug('keydown', event)
        if (
            event.keyCode === keycodes.BACKSPACE_KEY &&
            this.state.value === ''
        ) {
            event.stopPropagation()
            event.preventDefault()
        }
        // se precisarmos tratar casos de keydown
        // não esquecer de chamar o event.stopPropagation();
    }

    render() {
        const { classes, mdQuery, placeholder, className } = this.props
        let { value } = this.props
        if (!placeholder) {
            return null
        }

        value = value ? value.trim() : false

        return (
            <TextField
                autoFocus
                margin="none"
                label={
                    <span>
                        <SearchIcon className={classes.svg} /> {placeholder}
                    </span>
                }
                value={value || this.state.value}
                inputProps={{
                    ref: this.createRef('searchField'),
                }}
                disabled={!mdQuery}
                onFocus={this.clearField}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                className={className}
                type="search"
                name="search"
                InputLabelProps={{
                    classes: {
                        root: classes.labelRoot,
                    },
                    shrink: true,
                }}
                InputProps={{
                    classes: {
                        root: classes.inputRoot,
                    },
                }}
            />
        )
    }
}

export default withStyles(styles)(Search)
