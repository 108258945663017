import LinearProgress from '@material-ui/core/LinearProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/layout/loading'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'

import LogoWhite from '../../../../public/img/logo-white.png'

export class Loading extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        classes: PropTypes.object,
    }

    render() {
        const { isLoading = false, classes } = this.props

        if (!isLoading) {
            return null
        }

        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <div className={classes.holder}>
                        <img src={LogoWhite} className={classes.logo} />
                        <LinearProgress mode="indeterminate" />
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Loading)
