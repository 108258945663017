import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import config from '@nerus/config'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import keycodes from '@nerus/framework/common/Keycodes'
import Dialogo from '@nerus/framework/components/Dialogo'
import Button from '@nerus/framework/styled/Button'
import StyledToggle from '@nerus/framework/styled/Toggle'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { withWS } from '../../NerusWeb/Business/Websocket/Context'
import DialogoProgressao from '../../NerusWeb/Components/DialogoProgressao'
import BaseFlutuante from '../../NerusWeb/Components/Flutuante/BaseFlutuante'
import { sendBuffer } from '../../NerusWeb/Eac/EacActions'
import Grade from './Grade'
import Pictures from './Pictures'
import Previewer from './Previewer'
import withCreatePicture from './withCreatePicture'
import withGetPicture from './withGetPicture'
import withRemovePicture from './withRemovePicture'
import withUpdatePictures from './withUpdatePictures'

import throttle from 'lodash.throttle'

export const GRADE_SEM_NOME = 'FOTOS GENÉRICAS'
export const MAX_FOTOS_PER_PROD = 10

const styles = theme => ({
    root: {
        display: 'flex',
    },
    leftSide: {
        minWidth: 200,
        marginRight: theme.spacing(1),
        background: theme.palette.background.default,
    },
    rightSide: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 538,
        [`@media (min-height:768px)`]: {
            minHeight: 654,
        },
    },
    rightSideGuia: {
        margin: theme.spacing(1, 0, 0),
    },
    actions: {
        display: 'flex',
        borderTop: '1px solid #ccc',
        width: 'auto',
        // paddingTop: 1,
        margin: `0 ${theme.spacing(-1)}px ${theme.spacing(-1)}px`,
    },
    actionsGuia: {
        margin: 0,
    },
    actionsLeft: {
        textAlign: 'left',
        padding: theme.spacing(1),
    },
    actionsRight: {
        textAlign: 'right',
        padding: theme.spacing(1),
        flex: 1,
    },
    semGrade: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(4),
        maxWidth: 538,
        minHeight: 466,
        flex: 1,
        display: 'flex',
    },
    baseFlutuante: {
        // maxWidth: 638,
        minWidth: '554px !important',
    },
    baseFlutuanteGrades: {
        maxWidth: 638,
        minWidth: '768px !important',
    },
    isNotFlt: {
        paddingLeft: 8,
    },
})

export class Picture extends EnhancedComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        prdno: PropTypes.string,
        fkno: PropTypes.number,
        custno: PropTypes.number,
        prdSerie: PropTypes.string,
        asrmano: PropTypes.number,
        type: PropTypes.bool,
        grade: PropTypes.string,
        table: PropTypes.string,
        isGuia: PropTypes.bool,
        flt: PropTypes.bool,
        createPicture: PropTypes.func,
        getPicture: PropTypes.object,
        removePicture: PropTypes.func,
        updatePictures: PropTypes.func,
        pictureTablesMap: PropTypes.object, // isRequired?
    }

    constructor(props) {
        super(props)

        this.state = {
            activeFoto: null,
            activeGrade: props.grade ?? GRADE_SEM_NOME,
            removePic: null,
            fileIsTooBig: null,
            maxFotosPerProd: props.maxFotosPerProd ?? MAX_FOTOS_PER_PROD,
            showTrayWarning: null,
            photosToUpload: null,
            invalidPhotosToUpload: null,
        }
    }
    componentDidMount() {
        this._mounted = true
        super.componentDidMount()
    }

    componentWillUnmount() {
        this._mounted = false
        super.componentWillUnmount()
    }

    onFotoClick = activeFoto => () => {
        this.setState({ activeFoto })
    }

    onGradeClick = ({ prdno, table }) => selectedGrade => () => {
        const grade =
            selectedGrade.title !== GRADE_SEM_NOME
                ? selectedGrade.title
                : undefined

        this.props.getPicture.refetch({
            grade,
            prdno,
            table,
        })

        this.setState({
            activeGrade: selectedGrade.title,
            activeFoto: null,
        })
    }

    onConfirmarClick = () => {
        this.props.ws.send(sendBuffer(keycodes.ENTER_KEY))
    }

    onUploadClick = () => {
        this.getRef('upload').click()
    }

    onRemoveClick = removePic => () => {
        this.setState({ removePic })
    }

    doRemove = args => {
        const { grade, foto, table, pictureTablesMap } = args

        this.props
            .removePicture({
                variables: {
                    prdno: foto.prdno,
                    seqno: parseInt(foto.seqno),
                    fkno: parseInt(foto.prdno) || parseInt(foto.fkno) || null,
                    custno: parseInt(foto.custno),
                    seqnoAuto: parseInt(foto.seqnoAuto) || null,
                    grade,
                    pictureTablesMap,
                    table,
                },
            })
            .then(() => {
                this.props.getPicture.refetch()
            })

        this.setState({
            activeFoto: null,
            removePic: null,
        })
    }

    validateUpload = files => {
        return new Promise(async resolve => {
            let invalidPhotosType = []
            if (
                config.files.validateTrayPhotosType &&
                this.props.table === 'prdpicture'
            ) {
                const filesObj = {}
                files.forEach(selectedFile => {
                    filesObj[selectedFile.name] = new Promise(resolve => {
                        const reader = new FileReader()

                        reader.readAsText(selectedFile)

                        reader.onloadend = () => {
                            return resolve(reader.result)
                        }
                    })
                })

                const textFilesResults = await Promise.all(
                    Object.values(filesObj)
                )

                textFilesResults.forEach((textFileResult, i) => {
                    // Se algum arquivo tiver o formato RIFF...
                    if (textFileResult.search(/RIFF/gim) > -1) {
                        invalidPhotosType.push(Object.keys(filesObj)[i])
                    }
                })
            }

            resolve(invalidPhotosType)
        })
    }

    uploadFiles = files => {
        const filesToUpload = files || this.state.photosToUpload
        this.setState(
            {
                loading: true,
                photosToUpload: null,
                showTrayWarning: null,
                invalidPhotosToUpload: null,
            },
            () => {
                // eslint-disable-next-line no-undef
                let prom = Promise.resolve()
                filesToUpload.forEach(selectedFile => {
                    const promise = () =>
                        // eslint-disable-next-line no-undef
                        new Promise(resolve => {
                            const reader = new FileReader()

                            reader.readAsDataURL(selectedFile)

                            reader.onloadend = () => {
                                const {
                                    prdno,
                                    fkno,
                                    custno,
                                    table,
                                    asrmano,
                                    prdSerie,
                                    type,
                                } = this.props

                                const grade =
                                    this.state.activeGrade !== GRADE_SEM_NOME
                                        ? this.state.activeGrade
                                        : undefined
                                const pictureTablesMap = this.props
                                    .pictureTablesMap

                                let keys = {}

                                const variables = !pictureTablesMap
                                    ? {
                                          prdno,
                                          fkno,
                                          table,
                                          grade,
                                          type,
                                          foto: reader.result,
                                          ...keys,
                                          showDefault: false,
                                      }
                                    : {
                                          prdno,
                                          custno,
                                          asrmano,
                                          prdSerie,
                                          grade,
                                          table,
                                          pictureTablesMap,
                                          foto: reader.result,
                                          ...keys,
                                          showDefault: false,
                                      }

                                return this.props
                                    .createPicture({
                                        variables,
                                    })
                                    .then(response =>
                                        resolve(response.data.createPicture)
                                    )
                                    .catch(() => {
                                        return null
                                    })
                            }
                        })

                    prom = prom.then(promise)
                })

                prom.then(activeFoto => {
                    if (this._mounted) {
                        this.setState({
                            loading: false,
                            activeFoto,
                        })
                    }
                }).then(() => this.props.getPicture.refetch())
            }
        )
    }

    doUpload = async e => {
        if (!e.target.files?.length) {
            return
        }
        const { getPicture } = this.props
        const { maxFotosPerProd } = this.state

        const { findPicture: fotos } = getPicture || { findPicture: [] }

        if (e.target.files.length + fotos?.length > maxFotosPerProd) {
            this.setState({
                tooManyFiles: true,
                filesExceed: Math.abs(
                    maxFotosPerProd - (e.target.files.length + fotos?.length)
                ),
            })
            e.target.value = ''
            return
        }

        let filesTooBig = []
        Array.from(e.target.files).forEach(selectedFile => {
            const size = selectedFile.size
            if (size > config.files.editMaxFileSize) {
                filesTooBig.push(selectedFile)
            }
        })

        if (filesTooBig.length) {
            this.setState({
                fileIsTooBig: true,
                fileErrors: filesTooBig,
            })
            e.target.value = ''
            return
        }

        e.persist()
        const files = [...Array.from(e.target.files)]

        const invalidPhotosType = await this.validateUpload(files)

        if (invalidPhotosType && invalidPhotosType?.length) {
            this.setState({
                invalidPhotosToUpload: invalidPhotosType,
                showTrayWarning: true,
                photosToUpload: files,
            })
        } else {
            this.uploadFiles(files)
        }

        e.target.value = ''
    }

    onCheck = () => {
        this.setState({
            checked: !this.state.checked,
        })
    }

    handleDoRemove = () => {
        this.doRemove(this.state.removePic)
    }

    closeExcluirDialog = () => {
        this.setState({
            removePic: null,
            fileIsTooBig: null,
            tooManyFiles: null,
            showTrayWarning: null,
            photosToUpload: null,
            invalidPhotosToUpload: null,
        })
    }

    onDrag = throttle((isSame, isEnd, draggedIdx, overIdx) => {
        if (!isSame && isEnd) {
            const images = this.props.getPicture.findPicture.filter(
                ({}, i) => i != draggedIdx
            )

            images.splice(
                overIdx,
                0,
                this.props.getPicture.findPicture[draggedIdx]
            )

            const pictures = images.map(({ seqno }, i) => ({
                prdseq: i + 1,
                seqno,
            }))

            this.props
                .updatePictures({ variables: { pictures } })
                .then(() => this.props.getPicture.refetch())
        }
    }, 100)

    render() {
        const {
            props: {
                classes,
                table,
                prdno,
                fkno,
                custno,
                type,
                title,
                upload = true,
                flt = true,
                isGuia = false,
                getPicture,
                pictureTablesMap,
            },
            state: { activeGrade, activeFoto, checked, maxFotosPerProd },
        } = this

        const { loading, error, findPicture: fotos, findGradePicture: grades } =
            getPicture || {}

        const showGrades = grades?.length > 0

        const entregaOuMontagem =
            type !== undefined && type !== null && typeof type !== 'number'
        let useTitle = ''

        switch (table) {
            case 'mtgpicture':
                if (type) {
                    useTitle = `Entrega: ${fkno}`
                } else {
                    useTitle = `Montagem: ${fkno}`
                }

                break
            case 'ctpicture':
                useTitle = `Cliente: ${title}`
                break
            default:
                useTitle = `Produto: ${prdno} - ${title}`
                break
        }

        let content = ''
        if (loading && !fotos && !grades) {
            content = <DialogoProgressao data={{ msg1: 'Carregando...' }} />
        } else if (error) {
            content = `Houve um erro no processamento da imagem.`
        } else {
            const active = activeGrade ?? grades[0]?.title

            const foto = activeFoto ? activeFoto : fotos ? fotos[0] : null

            const url = foto?.urlImagem
                ? foto.urlImagem
                : foto?.foto
                ? `data:image/jpg;base64,${foto.foto}`
                : null

            const actions = (
                <Fragment>
                    {loading ? (
                        <CircularProgress
                            size={`16px`}
                            style={{ marginRight: 8 }}
                            variant={'indeterminate'}
                        />
                    ) : null}
                    {fotos?.length < maxFotosPerProd ||
                    (!fotos && upload && !entregaOuMontagem) ? (
                        <Button
                            variant={'rounded'}
                            lbl={'Upload'}
                            disabled={fotos?.length === maxFotosPerProd}
                            dialog
                            onClick={this.onUploadClick}
                        />
                    ) : null}

                    {/* {url && upload && !entregaOuMontagem ? ( */}
                    {url && upload ? (
                        <Button
                            color="danger"
                            lbl={'Excluir'}
                            variant={'rounded'}
                            dialog
                            onClick={this.onRemoveClick({
                                prdno,
                                fkno: table === 'mtgpicture' ? prdno : null,
                                custno,
                                grade: active,
                                foto,
                                table,
                                pictureTablesMap,
                            })}
                        />
                    ) : (
                        ''
                    )}

                    {!isGuia ? (
                        <Button
                            primary
                            variant={'rounded'}
                            lbl={'Fechar'}
                            onClick={this.onConfirmarClick}
                            dialog
                        />
                    ) : null}
                </Fragment>
            )

            content = (
                <Fragment>
                    <Box className={classes.root}>
                        {showGrades ? (
                            <Box className={classes.leftSide}>
                                <Grade
                                    data={grades?.filter(
                                        grade => checked || grade.count > 0
                                    )}
                                    active={active}
                                    onClick={this.onGradeClick({
                                        table,
                                        prdno,
                                    })}
                                />
                            </Box>
                        ) : null}

                        <Box
                            className={clsx(classes.rightSide, {
                                [classes.rightSideGuia]: isGuia,
                                [classes.isNotFlt]: !flt,
                            })}
                        >
                            {loading ? (
                                <DialogoProgressao
                                    data={{ msg1: 'Carregando...' }}
                                />
                            ) : null}
                            {url ? (
                                <Fragment>
                                    <Previewer url={url} />

                                    <Pictures
                                        data={fotos}
                                        active={foto}
                                        onClick={this.onFotoClick}
                                        onDrag={
                                            table === 'prdpicture'
                                                ? this.onDrag
                                                : null
                                        }
                                    />
                                </Fragment>
                            ) : (
                                <Box className={classes.semGrade}>
                                    <Typography variant="h6" align="center">
                                        Não há nenhuma imagem cadastrada para{' '}
                                        {showGrades
                                            ? 'esta grade'
                                            : 'este produto'}
                                        .
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>

                    <Box
                        className={clsx(classes.actions, {
                            [classes.actionsGuia]: isGuia,
                        })}
                    >
                        {showGrades ? (
                            <Box className={classes.actionsLeft}>
                                <StyledToggle
                                    checked={this.state.checked}
                                    onChange={this.onCheck}
                                    label="Mostrar grades sem foto"
                                />
                            </Box>
                        ) : null}

                        <Box className={classes.actionsRight}>{actions}</Box>
                    </Box>
                </Fragment>
            )
        }

        let errorMessage = null
        if (this.state.fileErrors?.length) {
            errorMessage = `O arquivo não pode ser maior que 2MB. Por favor envie outra foto.<br />Verifique ${
                this.state.fileErrors.length === 1 ? 'a foto' : 'as fotos'
            } abaixo: <ul class="padded">`
            this.state.fileErrors.forEach(file => {
                errorMessage += `<li>${file.name}</li>`
            })
            errorMessage = `${errorMessage}</ul>`
        }

        content = (
            <Fragment>
                {content}
                {!loading && (
                    <input
                        type="file"
                        multiple
                        accept=".jpg,.png"
                        ref={this.createRef('upload')}
                        onChange={this.doUpload}
                        style={{ display: 'none' }}
                    />
                )}
            </Fragment>
        )

        if (flt || flt === undefined) {
            content = (
                <BaseFlutuante
                    size="medium"
                    title={useTitle}
                    className={
                        !grades || !showGrades
                            ? classes.baseFlutuante
                            : classes.baseFlutuanteGrades
                    }
                >
                    {content}
                </BaseFlutuante>
            )
        }

        return (
            <Fragment>
                {content}
                {this.state.showTrayWarning ? (
                    <Dialogo
                        strList={[
                            {
                                label: 'Continuar',
                                onClick: () => this.uploadFiles(),
                            },
                            {
                                label: 'Cancelar',
                                onClick: this.closeExcluirDialog,
                            },
                        ]}
                        msg={`As fotos abaixo não são compatíveis com a plataforma TrayCorp.
                            \n${this.state.invalidPhotosToUpload.join('\n')}
                            \nDeseja continuar o upload?`}
                        onClose={this.closeExcluirDialog}
                        title="Confirme sua ação"
                        activeElementIndex={this.props.activeElementIndex}
                        open
                    />
                ) : null}
                {this.state.removePic ? (
                    <Dialogo
                        strList={[
                            {
                                label: 'Confirmar',
                                onClick: this.handleDoRemove,
                            },
                            {
                                label: 'Cancelar',
                                onClick: this.closeExcluirDialog,
                            },
                        ]}
                        msg="Deseja realmente excluir esta foto?"
                        onClose={this.closeExcluirDialog}
                        title="Confirme sua ação"
                        activeElementIndex={this.props.activeElementIndex}
                        open
                    />
                ) : null}
                {this.state.tooManyFiles ? (
                    <Dialogo
                        strList={[
                            {
                                label: 'Fechar',
                                onClick: this.closeExcluirDialog,
                            },
                        ]}
                        msg={`Você pode enviar mais ${maxFotosPerProd -
                            fotos?.length} ${
                            maxFotosPerProd - fotos?.length === 1
                                ? 'foto'
                                : 'fotos'
                        }. Caso deseje enviar mais fotos, apague\noutras fotos do produto para liberar espaço.`}
                        onClose={this.closeExcluirDialog}
                        title="Quantidade máxima de fotos excedido"
                        open
                    />
                ) : null}
                {this.state.fileIsTooBig ? (
                    <Dialogo
                        strList={[
                            {
                                label: 'Fechar',
                                onClick: this.closeExcluirDialog,
                            },
                        ]}
                        msg={errorMessage}
                        onClose={this.closeExcluirDialog}
                        title="Tamanho de imagem excedido"
                        open
                    />
                ) : null}
            </Fragment>
        )
    }
}

export default connect()(
    withStyles(styles)(
        withWS(
            withCreatePicture(
                withUpdatePictures(withRemovePicture(withGetPicture(Picture)))
            )
        )
    )
)
