import { Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { formatTooltip } from '../../../modules/NerusWeb/Business/Keyboard'
import EnhancedComponent from '../../common/EnhancedComponent'

// TODO: Transform this to CSS-IN-JS
const styles = theme => ({
    icon: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        padding: 0,
        '& svg': {
            fontSize: theme.spacing(2.25),
        },
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        padding: theme.spacing(1),
        '& svg': {
            fontSize: theme.spacing(2),
        },
    },
    medium: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        padding: theme.spacing(1),
    },
    default: {
        verticalAlign: 'middle',
    },
    contrastedIcon: {
        '& svg': {
            fill: theme.palette.contrastText.main,
        },
    },
    info: {
        backgroundColor: theme.palette.blue.main,
        '&:hover': {
            backgroundColor: theme.palette.blue.dark,
        },
    },
    warn: {
        backgroundColor: theme.palette.orange.main,
        '&:hover': {
            backgroundColor: theme.palette.orange.dark,
        },
    },
    success: {
        backgroundColor: theme.palette.primaryAction.main,
        '&:hover': {
            backgroundColor: theme.palette.primaryAction.dark,
        },
    },
    disabled: {
        cursor: 'default',
        opacity: 0.8,
        color: theme.palette.common.black,
    },
})

class Icon extends EnhancedComponent {
    static propTypes = {
        classes: PropTypes.shape({
            icon: PropTypes.string,
            small: PropTypes.string,
            medium: PropTypes.string,
            default: PropTypes.string,
            info: PropTypes.string,
            warn: PropTypes.string,
            success: PropTypes.string,
            contrastedIcon: PropTypes.string,
            disabled: PropTypes.string,
        }),
        tooltip: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        onClick: PropTypes.func,
        size: PropTypes.oneOf(['icon', 'small', 'medium']),
        variant: PropTypes.oneOf(['default', 'info', 'warn', 'success']),
        className: PropTypes.string,
        disabled: PropTypes.bool,
        skipTooltip: PropTypes.bool,
        children: PropTypes.any,
    }

    static defaultProps = {
        size: 'medium',
        variant: 'default',
    }

    render() {
        const {
            onClick,
            tooltip,
            disabled,
            className,
            classes,
            size,
            variant,
            children,
            skipTooltip,
        } = this.props

        const tooltipProps = {
            title: tooltip ? formatTooltip(tooltip) : null,
            placement: 'bottom',
        }

        const useClasses = {
            [classes.default]: true,
            [classes[size]]: size && classes[size],
            [classes[variant]]: variant && classes[variant],
            [classes.contrastedIcon]: variant !== 'default',
            [className]: !!className,
            [classes.disabled]: disabled,
        }

        const props = {
            onClick: onClick,
            style: styles[size],
            disabled: disabled,
            className: clsx(useClasses),
        }

        const IB = (
            <IconButton ref={this.createRef('iconButton')} {...props}>
                {children}
            </IconButton>
        )

        if (tooltipProps.title && !skipTooltip && !disabled) {
            return (
                <Tooltip
                    PopperProps={{
                        anchorEl: this.getRef('iconButton'),
                    }}
                    {...tooltipProps}
                >
                    <span>{IB}</span>
                </Tooltip>
            )
        }

        return IB
    }
}

export default withStyles(styles)(Icon)
