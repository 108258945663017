export default class Interface {
    constructor() {
        throw new Error('Essa classe não deve ser instanciada.')
    }

    static before({ state, action }) {
        return { state, action }
    }

    static after({ state, action }) {
        return { state, action }
    }

    static buildDefaultsDeepSource({ state, action }) {
        return { state, action }
    }
}
