import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { formatTooltip } from '../../../../modules/NerusWeb/Business/Keyboard'
import { sendBuffer } from '../../../../modules/NerusWeb/Eac/EacActions'
import { getBuffer } from '../../../../modules/NerusWeb/Eac/EacReducer'

class Actions extends Component {
    static propTypes = {
        wsStop: PropTypes.bool,
        buffer: PropTypes.array,
        data: PropTypes.any,
        activeValue: PropTypes.any,
        dispatch: PropTypes.func,
    }

    static mapStateToProps = state => {
        return {
            wsStop: state.app.wsStop,
            buffer: getBuffer(state),
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            className: ['action'],
        }
    }

    encodeEntity(str) {
        return str.toString().replace(/ /g, '&nbsp')
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         data: nextProps.data,
    //     })
    //
    //     let steps = []
    //     if (
    //         this.props.wsStop &&
    //         nextProps.buffer.last &&
    //         nextProps.buffer.last.length > 0 &&
    //         steps[steps.length - 1] != nextProps.buffer.last[0]
    //     ) {
    //         nextProps.buffer.last.forEach(
    //             (el => {
    //                 steps.push(this.encodeEntity(el))
    //             }).bind(this)
    //         )
    //
    //         this.setState({ steps })
    //     }
    // }

    render() {
        const { wsStop, buffer, data, activeValue, dispatch } = this.props

        let steps = []
        if (
            wsStop &&
            buffer.last &&
            buffer.last.length > 0 &&
            steps[steps.length - 1] !== buffer.last[0]
        ) {
            buffer.last.forEach(
                (el => {
                    steps.push(this.encodeEntity(el))
                }).bind(this)
            )

            this.setState({ steps })
        }

        return (
            // eslint-disable-next-line react/no-string-refs
            <div ref="action" className={this.state.className.join(' ')}>
                {data && data.bt
                    ? data.bt.map((button, i) => {
                          return (
                              <button
                                  // eslint-disable-next-line react/jsx-no-bind
                                  onClick={() =>
                                      dispatch(
                                          sendBuffer(
                                              {
                                                  x: data.x,
                                                  y: data.y,
                                                  key: button.key,
                                                  value: activeValue,
                                              },
                                              'sendEdit'
                                          )
                                      )
                                  }
                                  key={'btn-' + i}
                                  className="button white"
                                  type="button"
                              >
                                  {formatTooltip(button.text)}
                              </button>
                          )
                      })
                    : null}
                {wsStop && steps.length ? (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `"${steps.join('", "')}"`,
                        }}
                    />
                ) : null}
            </div>
        )
    }
}

export default connect(Actions.mapStateToProps)(Actions)
