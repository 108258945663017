import { AddBox, Traffic } from '@material-ui/icons'
import GaugeChart from '@material-ui/icons/AvTimer'
import BarChartIcon from '@material-ui/icons/BarChart'
import GridIcon from '@material-ui/icons/GridOn'
import ImageIcon from '@material-ui/icons/Image'
import SeparatorIcon from '@material-ui/icons/LineStyle'
import MapChart from '@material-ui/icons/Map'
import MultilineChart from '@material-ui/icons/MultilineChart'
import PieChartIcon from '@material-ui/icons/PieChart'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import TimelineIcon from '@material-ui/icons/Timeline'
import TitleIcon from '@material-ui/icons/Title'
import BarGroupedIcon from '@nerus/framework/icons/BarGrouped'
import BarStackedIcon from '@nerus/framework/icons/BarStacked'
import React from 'react'

export const TYPE_SEPARATOR = 1
export const TYPE_IMAGE = 2
export const TYPE_TITLE_BLOCK = 3
export const TYPE_GRAPH_BAR_AND_LINE = 4
export const TYPE_GRAPH_BAR = 5
export const TYPE_GRAPH_LINE = 6
export const TYPE_GRAPH_BAR_HOR = 7
export const TYPE_GRAPH_PIZZA = 8
export const TYPE_GRID = 9
export const TYPE_GRAPH_BAR_STACKED = 10
export const TYPE_GRAPH_BAR_GROUPED = 11

// Usados no Painel O2
export const TYPE_GRAPH_MULTLINHAS = 12
export const TYPE_GRAPH_GAUGE = 20
export const TYPE_GRAPH_MAP = 21
export const TYPE_FULFILLMENT = 22
export const TYPE_PICKINGPACKING = 23
export const TYPE_PRODUCTCICLE = 24

const BarChartHorIcon = props => {
    return <BarChartIcon {...props} style={{ transform: 'rotate(90deg)' }} />
}

const BarLineChartIcon = props => {
    return (
        <React.Fragment>
            <ShowChartIcon
                style={{
                    position: 'absolute',
                    color: '#b21e28',
                }}
            />
            <BarChartIcon {...props} />
        </React.Fragment>
    )
}

export const getIconByType = type => {
    let Icon = null

    switch (type) {
        case TYPE_SEPARATOR:
            Icon = SeparatorIcon
            break
        case TYPE_TITLE_BLOCK:
            Icon = TitleIcon
            break
        case TYPE_GRID:
            Icon = GridIcon
            break
        case TYPE_IMAGE:
            Icon = ImageIcon
            break
        case TYPE_GRAPH_BAR_AND_LINE:
            Icon = BarLineChartIcon
            break
        case TYPE_GRAPH_BAR:
            Icon = BarChartIcon
            break
        case TYPE_GRAPH_BAR_HOR:
            Icon = BarChartHorIcon
            break
        case TYPE_GRAPH_LINE:
            Icon = TimelineIcon
            break
        case TYPE_GRAPH_PIZZA:
            Icon = PieChartIcon
            break
        case TYPE_GRAPH_BAR_GROUPED:
            Icon = BarGroupedIcon
            break
        case TYPE_GRAPH_BAR_STACKED:
            Icon = BarStackedIcon
            break
        case TYPE_GRAPH_MULTLINHAS:
            Icon = MultilineChart
            break
        case TYPE_GRAPH_GAUGE:
            Icon = GaugeChart
            break
        case TYPE_GRAPH_MAP:
            Icon = MapChart
            break
        case TYPE_FULFILLMENT:
            Icon = Traffic
            break
        case TYPE_PICKINGPACKING:
            Icon = AddBox
            break
        case TYPE_PRODUCTCICLE:
            Icon = AddBox
            break
    }

    return Icon
}

export const getColorsByType = type => {
    const colors = { color: '', backgroundColor: '' }

    switch (type) {
        case TYPE_SEPARATOR:
            colors.color = '#000000'
            colors.backgroundColor = '#e6e6e6'
            break
        case TYPE_TITLE_BLOCK:
            colors.color = '#ffffff'
            colors.backgroundColor = '#98c1c3'
            break
        case TYPE_GRID:
            colors.color = '#ffffff'
            colors.backgroundColor = '#b298c3'
            break
        case TYPE_IMAGE:
            colors.color = '#ffffff'
            colors.backgroundColor = '#c3a698'
            break
        case TYPE_GRAPH_BAR_AND_LINE:
            colors.color = '#ffffff'
            colors.backgroundColor = '#c3bc98'
            break
        case TYPE_GRAPH_BAR:
            colors.color = '#ffffff'
            colors.backgroundColor = '#b2c398'
            break
        case TYPE_GRAPH_BAR_HOR:
            colors.color = '#ffffff'
            colors.backgroundColor = '#a2bf6a'
            break
        case TYPE_GRAPH_LINE:
            colors.color = '#ffffff'
            colors.backgroundColor = '#98a3c3'
            break
        case TYPE_GRAPH_PIZZA:
            colors.color = '#ffffff'
            colors.backgroundColor = '#c39898'
            break
        case TYPE_GRAPH_BAR_GROUPED:
            colors.color = '#ffffff'
            colors.backgroundColor = '#459447'
            break
        case TYPE_GRAPH_BAR_STACKED:
            colors.color = '#ffffff'
            colors.backgroundColor = '#946945'
            break
        case TYPE_GRAPH_MAP:
            colors.color = '#ffffff'
            colors.backgroundColor = '#c3b898'
            break
        case TYPE_GRAPH_GAUGE:
            colors.color = '#ffffff'
            colors.backgroundColor = '#646898'
            break
        case TYPE_GRAPH_MULTLINHAS:
            colors.color = '#ffffff'
            colors.backgroundColor = '#8c2c2c'
            break
        case TYPE_FULFILLMENT:
            colors.color = '#ffffff'
            colors.backgroundColor = '#00415a'
            break
        case TYPE_PICKINGPACKING:
            colors.color = '#ffffff'
            colors.backgroundColor = '#1e96f3'
            break
        case TYPE_PRODUCTCICLE:
            colors.color = '#ffffff'
            colors.backgroundColor = '#1e96f3'
            break
    }

    return colors
}

export const getTypeTitle = type => {
    switch (type) {
        case TYPE_SEPARATOR:
            return 'Separador'
        case TYPE_IMAGE:
            return 'Imagem'
        case TYPE_TITLE_BLOCK:
            return 'Bloco Título'
        case TYPE_GRAPH_BAR_AND_LINE:
            return 'Barra e Linha'
        case TYPE_GRAPH_BAR:
            return 'Barra Vertical'
        case TYPE_GRAPH_BAR_HOR:
            return 'Barra Horizontal'
        case TYPE_GRAPH_LINE:
            return 'Linha'
        case TYPE_GRAPH_PIZZA:
            return 'Gráfico Pizza'
        case TYPE_GRID:
            return 'Grid'
        case TYPE_GRAPH_BAR_GROUPED:
            return 'Barras Múltiplas'
        case TYPE_GRAPH_BAR_STACKED:
            return 'Barras Empilhadas'
        case TYPE_GRAPH_MAP:
            return 'Mapa'
        case TYPE_GRAPH_GAUGE:
            return 'Medidor'
        case TYPE_GRAPH_MULTLINHAS:
            return 'Múltiplas Linhas'
        case TYPE_FULFILLMENT:
            return 'Operações Fulfillment'
        case TYPE_PICKINGPACKING:
            return 'Picking & Packing'
        case TYPE_PRODUCTCICLE:
            return 'Ciclo do Produto'
    }

    return 'Não Reconhecido'
}

export const rgba = (r, g, b, a = 1) => ({ r, g, b, a })

export const defaultColors = {
    title: rgba(0, 0, 0, 0.87),
    footer: rgba(170, 170, 170),
    value: rgba(0, 0, 0, 0.87),
    separator: rgba(0, 0, 0, 0.3),
}

export const defaultAxes = {
    x: {
        title: '',
        format: null,
        color: '',
        labelColor: '',
    },
    y: {
        title: '',
        format: null,
        color: '',
        labelColor: '',
    },
}

export const defaultState = {
    title: '',
    info: '',
    query: '',
    type: '',
    size: '',
    height: '',
    image: '',
    colors: { ...defaultColors },
    axes: { ...defaultAxes },
    queries: [],
}
export const defaultValuesByType = {
    [TYPE_SEPARATOR]: {
        size: 12,
        height: 1,
    },
    [TYPE_IMAGE]: {
        size: 6,
        height: 6,
    },
    [TYPE_TITLE_BLOCK]: {
        size: 4,
        height: 2,
    },
    [TYPE_GRAPH_BAR_AND_LINE]: {
        size: 6,
        height: 6,
    },
    [TYPE_GRAPH_BAR]: {
        size: 6,
        height: 6,
    },
    [TYPE_GRAPH_LINE]: {
        size: 6,
        height: 6,
    },
    [TYPE_GRAPH_BAR_HOR]: {
        size: 6,
        height: 6,
    },
    [TYPE_GRAPH_PIZZA]: {
        size: 6,
        height: 6,
    },
    [TYPE_GRID]: {
        size: 6,
        height: 6,
    },
    [TYPE_GRAPH_BAR_STACKED]: {
        size: 6,
        height: 6,
    },
    [TYPE_GRAPH_BAR_GROUPED]: {
        size: 6,
        height: 6,
    },
    [TYPE_GRAPH_MAP]: {
        size: 6,
        height: 6,
    },
    [TYPE_GRAPH_GAUGE]: {
        size: 6,
        height: 6,
    },
    [TYPE_GRAPH_MULTLINHAS]: {
        size: 6,
        height: 6,
    },
    [TYPE_FULFILLMENT]: {
        size: 6,
        height: 4,
    },
    [TYPE_PICKINGPACKING]: {
        size: 5,
        height: 4,
    },
    [TYPE_PRODUCTCICLE]: {
        size: 3,
        height: 4,
    },
}
