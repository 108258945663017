import { controlKeys, keycodes } from '@nerus/framework/common/Keycodes'
import FieldType from '@nerus/framework/components/Formulario/Estrutura/FieldType'
import { Input } from '@nerus/framework/styled/Input'
import RichText from '@nerus/framework/styled/RichText'
import PropTypes from 'prop-types'
import React from 'react'

import AbstractTextField, { RECS } from './AbstractTextField'

const debug = require('debug')('nerus:StringMultiline')

/**
 * Componente StringMultilineField
 *
 * Rec: REC_STR_LINES
 *
 * Renderiza um campo de texto
 */
export class StringMultilineField extends AbstractTextField {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            value: props.value || '',
            isForceOverwrite: true,
        }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps)
        const input = this.getRef('input')
        const typeRec = this.props.typeRec
        const isRichText = this.props.attrib === 12
        if (
            !isRichText &&
            this.props &&
            typeRec === 'REC_STR_SCROLL' &&
            input?.textContent &&
            input.textContent.search('#') > 0 &&
            this.state.isForceOverwrite
        ) {
            const nextElement = input.textContent.search('#')
            input.selectionStart = input.selectionEnd = nextElement
        } else if (
            !isRichText &&
            !prevProps.enabled &&
            this.props.enabled &&
            input
        ) {
            // Força o caret a ir para o inicio quando o campo é ativado
            input.selectionStart = input.selectionEnd = 0
        }
    }

    beforeSend = text => {
        // insere 1 nova linha no fim para evitar 1 caracter ser removido
        return `${text}\n`
    }

    handleInputKeydown = event => {
        event.persist && event.persist()

        let {
            target: { selectionStart },
        } = event

        const { attrib, ln, lsz, sz } = this.props
        const isRichText = attrib === 12
        const {
            target: { value },
            keyCode,
        } = event
        const bypassKeys = [
            keycodes.RIGHT_ARROW_KEY,
            keycodes.LEFT_ARROW_KEY,
            keycodes.RIGHT_CTRL_KEY,
            keycodes.DOWN_ARROW_KEY,
            keycodes.BACKSPACE_KEY,
            keycodes.PAGE_DOWN_KEY,
            keycodes.UP_ARROW_KEY,
            keycodes.PAGE_UP_KEY,
            keycodes.DELETE_KEY,
            keycodes.SHIFT_KEY,
            keycodes.CTRL_KEY,
            keycodes.HOME_KEY,
            keycodes.ALT_KEY,
            keycodes.END_KEY,
        ]
        const isControlKey = controlKeys.indexOf(keyCode) > -1
        const isBypassedKey = bypassKeys.indexOf(keyCode) > -1
        const isEscape = keyCode === keycodes.ESCAPE_KEY
        const isEnter = keyCode === keycodes.ENTER_KEY
        const isShiftEnter = isEnter && event.shiftKey
        const isBackspace = keyCode === keycodes.BACKSPACE_KEY
        const isArrowUp = keyCode === keycodes.UP_ARROW_KEY
        const isArrowDown = keyCode === keycodes.DOWN_ARROW_KEY
        const unparsedLines = String(value).split('\n')
        const lines = unparsedLines?.length || 0
        const parsedText = `${value}`
        const elementIndex = event.target.textContent.search('#')

        if (isEscape || isRichText) {
            return
        }

        if (
            keycodes.RIGHT_ARROW_KEY === keyCode ||
            keycodes.LEFT_ARROW_KEY === keyCode ||
            keycodes.BACKSPACE_KEY === keyCode ||
            keycodes.HOME_KEY === keyCode ||
            keycodes.END_KEY === keyCode
        ) {
            this.setState({ isForceOverwrite: false })
        }

        let currentSelLines = parsedText
            .substr(0, selectionStart)
            .match(new RegExp(`.*(\n)*`, 'g')) || [
            parsedText.substr(0, selectionStart),
        ]

        if (
            currentSelLines?.length >= 2 &&
            currentSelLines[currentSelLines.length - 2] &&
            currentSelLines[currentSelLines.length - 2].indexOf('\n') === -1
        ) {
            // se a última linha não possui \n removemos a linha em branco
            // comportamento do próprio regex, mas, útil.
            currentSelLines.pop()
        }

        const currentLineNumber = currentSelLines?.length
        const isFullOfLines = lines === ln
        const isLineFullSize = unparsedLines[currentLineNumber - 1]
            ? unparsedLines[currentLineNumber - 1]?.length >= lsz
            : false

        // preenche as linhas anteriores com espaço se necessário
        // permite sabermos o tamanho total em uso do campo
        let cleanedAndCompleteValue
        unparsedLines.forEach((line, index) => {
            const cleanedLine = line.replace('\n', '')
            unparsedLines[index] =
                cleanedLine?.length < lsz &&
                index !== unparsedLines?.length - 1 &&
                index + 1 !== currentLineNumber
                    ? cleanedLine +
                      ' '.repeat(
                          lsz - cleanedLine.length > 0
                              ? lsz - cleanedLine.length
                              : 0
                      )
                    : line
        })
        cleanedAndCompleteValue = unparsedLines.join('')

        const isFullSize = cleanedAndCompleteValue?.length >= sz

        if (isEnter && !event.shiftKey) {
            event.preventDefault()
        } else if (
            isBypassedKey ||
            isFullOfLines ||
            isFullSize ||
            (isLineFullSize && isFullSize)
        ) {
            if (isFullOfLines && isEnter) {
                event.preventDefault()
            }

            if (
                !isControlKey &&
                isLineFullSize &&
                ((isFullSize && !isBackspace) || (!isFullSize && isFullOfLines))
            ) {
                debug(`Previne linha cheia e/ou corpo cheio`)
                event.preventDefault()
                return
            }

            if (
                (isArrowUp && currentLineNumber === 1) ||
                (isArrowDown && currentLineNumber === lines)
            ) {
                // do nothing
            } else {
                debug(`Prevent WS message send`)
                event.stopImmediatePropagation &&
                    event.stopImmediatePropagation()
            }
            return
        }

        if (!isFullOfLines) {
            if (isShiftEnter) {
                debug(`New line - by shift + enter !`)
                event.stopImmediatePropagation &&
                    event.stopImmediatePropagation()
                return
            }

            if (
                lines < ln &&
                cleanedAndCompleteValue?.length > 0 &&
                cleanedAndCompleteValue?.length % lsz === 0 &&
                currentSelLines[currentLineNumber - 1]?.length > 0 &&
                currentSelLines[currentLineNumber - 1]?.length % lsz === 0 &&
                !isControlKey &&
                !isBackspace
            ) {
                debug(`New line - by hitting the end of the line !`)
                event.target.value += '\n'
                selectionStart++
                this.setState(
                    {
                        value: event.target.value,
                    },
                    () => {
                        event.target.selectionStart = event.target.selectionEnd = selectionStart
                    }
                )
            }
        }

        if (
            this.props &&
            this.props.activeElement.typeRec === 'REC_STR_SCROLL'
        ) {
            const currentCharacter = event.target.textContent.substring(
                event.target.selectionStart,
                event.target.selectionEnd + 1
            )

            if (
                elementIndex > 0 &&
                this.props.value.indexOf('#', elementIndex) > 0 &&
                (this.state.isForceOverwrite || currentCharacter === '#')
            ) {
                const nextElement = event.target.textContent.search('#')
                event.target.selectionStart = event.target.selectionEnd = nextElement
            } else {
                this.setState({ isForceOverwrite: false })
            }
        }
    }

    handleInputChange = (event, callback) => {
        const { onChange } = this.props
        event.persist && event.persist()

        const fieldType = this.getFieldType()
        let selectionStart = event.target ? event.target.selectionStart : 0

        let value = event.target.value
        if (fieldType && RECS.indexOf(fieldType.typeRec) === -1) {
            if (fieldType.formatter) {
                value = fieldType.formatter(value)
                debug(`apply formatter: ${value}`)
            }

            if (fieldType && fieldType.modifier) {
                value = fieldType.modifier(value)
                debug(`apply modifier: ${value}`)
            }
        }

        this.setState(
            {
                value,
            },
            () => {
                if (event.target) {
                    event.target.selectionStart = event.target.selectionEnd = selectionStart
                }

                this.triggerUpdateField(value)()

                callback && callback(event)

                onChange && onChange(this.state.value, event)
            }
        )
    }

    getFieldType = () => {
        const defaultFieldTypeClass =
            this.props.typeRec === 'REC_STR_SCROLL'
                ? FieldType['a']
                : FieldType[this.props.typeRecMod]
        return new defaultFieldTypeClass()
    }

    render() {
        const {
            // numeros de linhas a serem exibidas
            lns,
            orientation,
            enabled,
            lbl,
            index,
            x,
            y,
            labelWidth,
            inputWidth,
            componentId,
            InputProps,
            dispatch,
            manualfocus = false,
            style,
        } = this.props

        const { value } = this.state
        const fieldType = this.getFieldType()
        const isRichText = this.props.attrib === 12
        return (
            <Input
                lns={this.props.lns}
                lsz={this.props.lsz}
                attrib={this.props.attrib}
                bt={this.props.bt || []}
                orientation={orientation}
                dispatch={dispatch}
                multiline
                fieldType={fieldType}
                InputProps={{
                    ...InputProps,
                    inputComponent: isRichText ? RichText : 'textarea',
                }}
                beforeSend={this.beforeSend}
                label={lbl}
                tabIndex={index}
                ref={this.createRef('input')}
                className="input-form"
                forceOverwrite={
                    !isRichText && this.state.isForceOverwrite === true
                }
                overwrite={!isRichText}
                clickToTrackCaret={!isRichText}
                value={isRichText ? this.props.value : value}
                position={{ x, y }}
                disabled={!enabled}
                helperText="* Para pular uma linha, use <SHIFT> + <ENTER>"
                componentId={componentId}
                labelWidth={labelWidth}
                inputWidth={inputWidth}
                autoComplete="off"
                minRows={lns}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeydown}
                dispatchToWs={true}
                manualfocus={+manualfocus}
                style={style}
            />
        )
    }
}

export default StringMultilineField
