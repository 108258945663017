import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

export function useO2(byLocation = false) {
    const location = useLocation()
    const isO2 = useSelector(state => state.eac.painelO2)

    return useMemo(() => {
        return {
            isO2: byLocation ? location.pathname.indexOf('o2') > -1 : isO2,
        }
    }, [location.pathname, isO2])
}
