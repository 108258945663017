import PropTypes from 'prop-types'
import React, { Component } from 'react'

const divStyle = {
    padding: '10%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999,
    background: '#dd0000dd',
}
const titleStyle = {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 36,
    textShadow: '2px 2px 3px #00000050',
}
const preStyle = {
    display: 'block',
    overflow: 'auto',
    background: '#00000050',
    fontSize: 10,
    color: '#fff',
    padding: 15,
}

class UnstableHandleError extends Component {
    static dispatch = 'UnstableHandleError'

    static propTypes = {
        children: PropTypes.node.isRequired,
    }

    state = { hasError: false, err: null }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: !!error }
    }

    componentDidCatch(error, info) {
        if (error) {
            this.setState({
                error,
                info,
            })
        }
    }

    render() {
        const { hasError, error, info } = this.state
        if (hasError) {
            if (process.env.NODE_ENV === 'development') {
                return (
                    <div style={divStyle}>
                        <h2 style={titleStyle}>
                            {error ? error.message : 'Sem mensagem de erro'}
                        </h2>
                        <pre style={preStyle}>
                            {info
                                ? info.componentStack
                                : 'Sem informação da stack'}
                        </pre>
                    </div>
                )
            } else {
                return (
                    <div style={divStyle}>
                        <h2 style={titleStyle}>
                            Tivemos um problema para processar sua solicitação!
                        </h2>
                        <pre style={preStyle}>
                            Entre em contato com nosso suporte técnico.
                        </pre>
                    </div>
                )
            }
        }

        return this.props.children
    }
}

export default UnstableHandleError
