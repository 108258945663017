import { withStyles } from '@material-ui/core/styles'
import { ResponsiveBar } from '@nivo/bar'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'

import { withWS } from '../../NerusWeb/Business/Websocket/Context'
import { sendBuffer } from '../../NerusWeb/Eac/EacActions'
import { initialProperties } from '../Dashboard/Actions/GraphConfig/defaults/bar-grouped'
import GraphTooltip from '../Dashboard/Components/GraphTooltip'
import getGraphProps from './getGraphProps'

const styles = {
    grafico: {
        width: '100%',
        height: '100%',
    },
}

class GraficoBarraGrouped extends Component {
    constructor(props) {
        super(props)
    }

    static propTypes = {
        axes: PropTypes.object,
        classes: PropTypes.object,
        custom: PropTypes.object,
        isPainelO2: PropTypes.bool,
        data: PropTypes.array.isRequired,
        ws: PropTypes.object,
    }

    render() {
        const {
            props: { axes, classes, data, custom = {}, isPainelO2, ws },
        } = this

        function tooltip(props) {
            const { indexValue, value, color } = props

            return (
                <GraphTooltip
                    noshadow
                    color={color}
                    value={value}
                    label={indexValue}
                    format={axes?.y?.format}
                />
            )
        }

        function onClick(key) {
            return () => ws.send(sendBuffer(key))
        }

        const [indexBy, ...keys] = this.props.data.length
            ? [...this.props.data[0].legends]
            : []

        const graphProps = getGraphProps(
            initialProperties,
            axes,
            data.length ? data[0].data : [],
            tooltip,
            custom
        )

        if (isPainelO2) {
            graphProps.colors.scheme = 'O2Short'
            graphProps.innerPadding = 3
            graphProps.margin.top = 10
            graphProps.margin.bottom = 20
            graphProps.margin.left = 40
            graphProps.margin.right = 180
            graphProps.enableLabel = false

            graphProps.legends = [
                {
                    dataFrom: 'keys',
                    anchor: 'top-right',
                    direction: 'column',
                    justify: false,
                    translateX: 60,
                    translateY: 0,
                    itemsSpacing: 3,
                    itemWidth: 60,
                    itemHeight: 10,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 10,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]

            graphProps.theme = {
                textColor: '#A1A6AE',
                fontSize: 12,
                grid: {
                    line: {
                        stroke: '#E9EDF5',
                        strokeWidth: 1,
                    },
                },
            }
        }

        graphProps.keys = keys
        graphProps.indexBy = indexBy

        return (
            <div
                className={classes.grafico}
                onClick={isPainelO2 ? onClick('f') : null}
                style={{ cursor: isPainelO2 ? 'pointer' : 'auto' }}
            >
                <ResponsiveBar {...graphProps} />
            </div>
        )
    }
}

export default withStyles(styles)(withWS(GraficoBarraGrouped))
