import DragIcon from '@material-ui/icons/DragIndicator'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import Tr from '@nerus/framework/components/Editor/Estrutura/Tr'
import { measureText } from '@nerus/framework/components/Formulario/Reducer/Form'
import { isRec } from '@nerus/framework/util/rec'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import { withWS } from '../../../Business/Websocket/Context'
import { sendBuffer } from '../../../Eac/EacActions'

const validIcons = [
    'ecom',
    'meli',
    'maga',
    'subm',
    'amer',
    'shot',
    'extr',
    'pont',
    'casa',
    'disc',
    'amaz',
    'mone',
    'grou',
    'olis',
    'made',
    'shop',
    'carr',
    // custom
    'cnov',
    'b2w',
    'camr',
    'cvrd',
    'cvrm',
    'qamr',
    'qvrd',
    'qvrm',
    'webc',
]

export function getValue(rowData, s = {}) {
    if (!rowData || typeof rowData !== 'object') {
        return null
    }

    let fontSize = 14
    if (window.innerWidth <= 1024) {
        fontSize = 12
    }

    let value = rowData.value

    switch (rowData.typeRec) {
        case 'REC_DOUBLE':
        case 'REC_MONEY':
        case 'REC_PERC':
            // eslint-disable-next-line no-case-declarations
            const formatterWithPrec = new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: rowData.prec,
                useGrouping: true,
            })
            if (!parseFloat(value)) {
                value = ''
            } else {
                value = parseFloat(value)
            }

            if (value) {
                value = formatterWithPrec.format(value)
            }
            break
        case 'REC_F_SHORT':
        case 'REC_F_LONG':
        case 'REC_LONG':
        case 'REC_DQTTY':
        case 'REC_QTTD':
        case 'REC_QTTY':
            // eslint-disable-next-line no-case-declarations
            const formatter = new Intl.NumberFormat('pt-BR', {
                maximumSignificantDigits: undefined,
            })

            if (!parseFloat(value)) {
                value = ''
            }

            if (value) {
                value = formatter.format(value)
            }
            break
        case 'REC_DATE':
            if (value === '00000000') value = ''
            else if (value)
                value = moment(value, 'YYYYMMDD').format('DD/MM/YYYY')
            break
        case 'REC_DATEYY':
        case 'REC_DATE_UTC':
            if (value) {
                value = moment(value, 'YYYYMMDD').format('DD/MM/YYYY')
            }
            break
        case 'REC_YM':
            if (value) {
                value = moment(value, 'YYYYMM').format('MM/YYYY')
            }
            break
        case 'REC_TIME':
            if (value) {
                value = moment(value, 'HHmm').format('HH:mm')
            }
            break
    }

    const inputSz = rowData.lsz || rowData.sz
    if (isRec(rowData, 'REC_DATE')) {
        rowData.inputWidth = inputSz > 8 ? inputSz * 9 + 9 : inputSz * 11 + 9
        rowData.inputWidth += 15
    } else if (isRec(rowData, 'REC_TIME')) {
        rowData.inputWidth = inputSz > 8 ? inputSz * 7 + 9 : inputSz * 9 + 9
    } else {
        rowData.inputWidth = measureText(
            '0'.repeat(inputSz > 0 ? inputSz : 0),
            fontSize,
            'font-family: Frutiger, sans-serif'
        )
    }

    const style = {
        ...s,
        width: rowData.sz ? rowData.inputWidth || rowData.sz * 7.5 : 'auto',
        display: 'inline-block',
    }

    return (
        <div tabIndex={0} className="inputFake" style={style}>
            {value}
        </div>
    )
}

class Row extends EnhancedComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        data: PropTypes.array.isRequired,
        types: PropTypes.array.isRequired,
        legend: PropTypes.array.isRequired,
        onFocus: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        onDoubleClick: PropTypes.func,
        index: PropTypes.number.isRequired,
        activeColumnSearch: PropTypes.number,
        activeValue: PropTypes.object,
        columns: PropTypes.number,
        className: PropTypes.string,
        active: PropTypes.bool,
        isPdv: PropTypes.bool,
        draggable: PropTypes.bool,
        onDrag: PropTypes.func,
        onDragStart: PropTypes.func,
        onDragOver: PropTypes.func,
        onDragEnd: PropTypes.func,
        dispatch: PropTypes.func,
    }

    shouldComponentUpdate(nextProps /*, nextState, nextContext */) {
        return this.checkProps(
            ['data', 'active', 'activeColumnSearch'],
            nextProps
        )
    }

    colorConversion(color) {
        return color
    }

    getStyles = index => {
        const { types } = this.props
        const type = types[index]
        const styles = {}
        if (type) {
            switch (type.type) {
                case 'GR_DOUBLE':
                case 'GR_DQTTY':
                case 'GR_QTTY':
                case 'GR_PERC':
                case 'GR_MONEY': {
                    styles.textAlign = 'right'
                    break
                }
                case 'GR_ICON': {
                    styles.textAlign = 'center'
                    styles.backgroundRepeat = `no-repeat`
                    styles.backgroundPosition = `center`
                    styles.backgroundSize = 'contain'
                    break
                }
                case 'GR_STRING':
                default: {
                    // do nothing
                    break
                }
            }
        }

        return styles
    }

    onDragOver = index => () =>
        this.props.onDragOver && this.props.onDragOver(index)

    onClick = event => this.props.onClick && this.props.onClick(event)

    onDragStart = index => e =>
        this.props.onDragStart && this.props.onDragStart(e, index)

    onDragEnd = index => () =>
        this.props.onDragEnd && this.props.onDragEnd(index)

    rowOnClick = rowData => () => {
        const { activeValue, ws, data } = this.props
        const activeField = data.filter(field => field.enabled)

        if (activeField && activeField[0]) {
            ws.send(
                sendBuffer(
                    {
                        componentId: activeField[0].componentId,
                        fieldId: activeField[0].id,
                        value: activeValue.current,
                        x: activeField[0].x,
                        y: activeField[0].y,
                        key: -1,
                    },
                    'sendMouse'
                )
            )
        }

        ws.send(
            sendBuffer(
                {
                    componentId: rowData.componentId,
                    fieldId: rowData.id,
                    x: rowData.x,
                    y: rowData.y,
                },
                'sendMouse'
            )
        )
    }

    render() {
        let tdIsEmpty = true
        const {
            classes,
            data,
            index,
            activeColumnSearch,
            activeValue,
            legend,
            className,
            onFocus,
            onDoubleClick,
            enabled,
            columns,
            isPdv,
            draggable,
            active,
            style: trStyle,
        } = this.props

        const row = data.map((rowData, j) => {
            let styles = this.getStyles(j)

            let legendToApply =
                legend &&
                legend.filter(legend => {
                    const isCol = legend.col === j || legend.col === -1
                    const isLn = legend?.lns?.indexOf(index) > -1
                    return isCol && isLn && activeColumnSearch !== j
                })

            if (rowData !== '' && rowData.trim && rowData.trim()) {
                tdIsEmpty = false
            }

            if (legendToApply?.length && !tdIsEmpty) {
                styles.color =
                    this.colorConversion(legendToApply[1]?.fg) ||
                    this.colorConversion(legendToApply[0].fg)
                styles.backgroundColor =
                    this.colorConversion(legendToApply[1]?.bg) ||
                    this.colorConversion(legendToApply[0].bg)
            }

            if (tdIsEmpty && (!rowData || (rowData.trim && !rowData.trim()))) {
                return (
                    <td
                        key={'td-' + j}
                        className={clsx({
                            [classes.tableBody]: true,
                            [classes.tableRowInSearch]:
                                activeColumnSearch === j,
                        })}
                        style={styles}
                    >
                        &nbsp;
                    </td>
                )
            }

            const Component = rowData.component

            const fakeInput = getValue(rowData, styles)

            if (typeof rowData === 'object') {
                if (rowData?.width) {
                    styles.width = rowData.width
                    styles.maxWidth = rowData.width
                    styles.padding = 0
                } else if (rowData?.inputWidth) {
                    styles.minWidth = rowData.inputWidth
                }
            }

            // const inputProps = { ...rowData.InputProps, style: styles }
            const { types } = this.props
            const type = types[j]
            let useRowData = rowData
            if (type?.type === 'GR_ICON' && typeof rowData === 'string') {
                useRowData = ''
                let useIcon = 'generic'
                if (validIcons.indexOf(rowData.toLowerCase()) > -1) {
                    useIcon = rowData.toLowerCase()
                }
                styles.backgroundImage = `url('/icons/${useIcon}.png')`
            }

            return (
                <td
                    style={{ ...styles, ...trStyle }}
                    className={clsx({
                        [classes.tableBody]: true,
                        [classes.tableRowInSearch]: activeColumnSearch === j,
                    })}
                    key={'td-' + j}
                    onClick={
                        Component && !rowData.enabled
                            ? this.rowOnClick(rowData)
                            : undefined
                    }
                >
                    {Component ? (
                        rowData.enabled || rowData.forceComponent ? (
                            <Component
                                key={'td-comp-' + j}
                                {...rowData}
                                // InputProps={inputProps}
                                activeValue={activeValue}
                                enabled={rowData.enabled && active}
                            />
                        ) : (
                            fakeInput
                        )
                    ) : (
                        useRowData
                    )}
                </td>
            )
        })

        // O BUG ocorre por conta de estarem chegando valor = '' nas colunas, logo o tamanho fica maior e distorce o restante

        if (row?.length !== columns) {
            for (let x = columns - row?.length; x > 0; x--) {
                row.push(
                    <td
                        className={clsx({
                            [classes.tableBody]: true,
                            [classes.tableRowInSearch]:
                                activeColumnSearch === columns - x,
                        })}
                        key={'td-' + (columns - x)}
                    />
                )
            }
        }

        return (
            <Tr
                key={'Tr' + index}
                draggable={draggable}
                onFocus={onFocus}
                onClick={this.onClick}
                onDoubleClick={onDoubleClick}
                onDragOver={this.onDragOver}
                className={clsx({
                    [classes.tableRowColored]: index % 2 === 0,
                    [classes.tableRowHover]: enabled,
                    [classes.tableRow]: !isPdv,
                    [className]: className,
                })}
            >
                <Fragment>
                    {this.props.draggable ? (
                        <td
                            draggable
                            onDragStart={this.onDragStart(index)}
                            onDragEnd={this.onDragEnd(index)}
                            className={clsx({
                                [classes.tableBody]: true,
                                [classes.draggable]: true,
                            })}
                        >
                            <DragIcon />
                        </td>
                    ) : (
                        undefined
                    )}
                    {row}
                </Fragment>
            </Tr>
        )
    }
}

export default withWS(Row)
