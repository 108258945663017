export const styles = theme => ({
    root: {
        color: theme.palette.common.white,
        fontSize: '13px',
        fontWeight: 700,
        lineHeight: 1.5,
        flex: '1 1 100%',
    },
    list: {
        margin: 0,
        paddingLeft: 0,
        display: 'inline-block',
    },
    listItem: {
        display: 'inline-block',
        textTransform: 'capitalize',
    },
    listFirstItem: {
        paddingLeft: '10px',
    },
    listOtherItems: {
        '&::before': {
            content: '"/"',
            display: 'inline-block',
            color: theme.palette.common.white,
            margin: `0 0 0 ${theme.spacing(1) / 2}px`,
            paddingRight: `${theme.spacing(1) / 2}px`,
        },
    },
})
