import { Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import { styles } from '@nerus/styles/components/editor/legend'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { toggleLegend } from '../../../Eac/EacActions'

class Legend extends EnhancedComponent {
    static propTypes = {
        iconRef: PropTypes.any,
        legend: PropTypes.array.isRequired,
        dispatch: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
        flt: PropTypes.bool,
    }

    static defaultProps = {
        legend: [],
    }

    state = {
        left: 925,
        top: '80%',
        width: 300,
        display: 'none',
    }

    componentDidMount() {
        const flt = this.props.flt
        const icons = this.props.iconRef
        const legend = this.getRef('content')
        if (icons && legend && icons.children?.length) {
            const icon = icons.children[icons.children.length - 1]
            let { offsetTop, offsetLeft } = icon
            const { x, y } = icon.getBoundingClientRect()
            const { clientWidth, clientHeight } = legend

            // Adicionamos as medidas 8px relativos ao
            // spacing/2 que temos de padding de cada lado no btn
            const midIconWidth =
                Math.ceil(icon.offsetWidth / 2) + (flt ? 8 : 10)
            const top = (flt ? offsetTop : y) - clientHeight + (flt ? 8 : -24)
            const left = (flt ? offsetLeft : x) - clientWidth + midIconWidth
            this.setPosition(left, top)
        }
    }

    componentWillUnmount() {
        this.contentRef = null
    }

    setPosition(left, top = null) {
        this.setState({
            left,
            top,
            display: 'block',
        })
    }

    handleToggle = e => {
        e.stopPropagation()
        this.props.dispatch(toggleLegend())
    }

    render() {
        const { legend, classes } = this.props

        const { left, width, top } = this.state

        return (
            <div className={classes.root} onClick={this.handleToggle}>
                <div
                    className={classes.content}
                    ref={this.createRef('content')}
                    style={{ left, top, width }}
                >
                    <Typography variant={'h4'} className={classes.title}>
                        Legenda
                        <IconButton
                            className={classes.close}
                            onClick={this.handleToggle}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Typography>

                    <ul className={classes.list}>
                        {legend?.length ? (
                            legend.map((leg, i) => (
                                <li
                                    className={classes.listItem}
                                    key={i}
                                    style={{
                                        color: leg.fg,
                                        backgroundColor: leg.bg,
                                    }}
                                >
                                    {leg.sbt}
                                </li>
                            ))
                        ) : (
                            <li>Não há legendas no momento.</li>
                        )}
                    </ul>
                </div>
            </div>
        )
    }
}

export default connect()(withStyles(styles)(Legend))
