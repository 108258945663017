import MenuItem from '@material-ui/core/MenuItem'
import keycodes from '@nerus/framework/common/Keycodes'
import clsx from 'clsx'
import React from 'react'

import StyledSelect from '../../../../styled/Select'
import AbstractTextField from './AbstractTextField'

/**
 * Componente PopupMenu
 */
export default class TextFieldPopupMenu extends AbstractTextField {
    state = {
        open: this.props.open !== undefined ? this.props.open : false,
    }

    handleOnChange = event => {
        const { onChange } = this.props
        onChange && onChange(event.target.value)
        this.setState(
            {
                open: false,
            },
            () => {
                this.handleOnBlur(event)
            }
        )
    }

    handleOnFocus = callback => {
        this.setState(
            {
                open: true,
            },
            () => {
                this.props.onFocus && this.props.onFocus()
                typeof callback === 'function' && callback()
            }
        )
    }

    handleOnBlur = callback => {
        this.setState(
            {
                open: false,
            },
            () => {
                this.props.onBlur && this.props.onBlur()
                typeof callback === 'function' && callback()
            }
        )
    }

    handleRootKeyDown = event => {
        // Precisamos fazer nesse momento o target do keyDown visto que o nosso
        // hook não pega o evento no momento correto e assim evitamos interferencias
        // em outros componentes.
        const input = event.currentTarget
        const form = input.parentElement.parentElement

        const elements = form.querySelectorAll(
            'input, select, textarea, button'
        )
        const currentIndex = this.props.index || 0

        if (
            event.keyCode === keycodes.UP_ARROW_KEY ||
            (event.keyCode === keycodes.TAB_KEY && event.shiftKey)
        ) {
            event.preventDefault()
            const nextIndex = currentIndex - 1
            if (elements.item(nextIndex)) {
                this.handleOnBlur(() => {
                    if (this.props.updateActiveField) {
                        this.props.updateActiveField(nextIndex, true, true)
                    }
                    elements.item(nextIndex).focus()
                })
            }
        } else if (
            event.keyCode === keycodes.ENTER_KEY ||
            event.keyCode === keycodes.DOWN_ARROW_KEY ||
            (event.keyCode === keycodes.TAB_KEY && !event.shiftKey)
        ) {
            if (event.keyCode !== keycodes.ENTER_KEY) {
                event.preventDefault()
            }

            const nextIndex = currentIndex + 1
            let item = elements.item(nextIndex)

            if (item) {
                this.handleOnBlur(() => {
                    if (this.props.updateActiveField) {
                        this.props.updateActiveField(nextIndex, true, false)
                    }
                    if (item.type && item.type.toLowerCase() === 'hidden') {
                        item.parentElement.click()
                    } else {
                        item.focus()
                    }
                })
            } else {
                this.handleOnBlur(() => {
                    if (this.props.updateActiveField) {
                        this.props.updateActiveField(nextIndex, true, false)
                    }

                    input.blur()
                })
            }
        }
    }

    render() {
        const { open } = this.state
        const {
            index,
            options,
            enabled,
            lbl,
            value,
            orientation = 'horizontal',
            inputWidth,
            labelWidth,
            ...otherProps
        } = this.props

        let data = false
        if (options) {
            data = options.data
        }

        return (
            <StyledSelect
                // onKeyDownRoot={this.handleRootKeyDown}
                InputLabelProps={{
                    style: { minWidth: labelWidth },
                    disabled: !enabled,
                }}
                InputProps={{
                    style: {
                        width: inputWidth,
                        minWidth: 160,
                    },
                }}
                MenuProps={{
                    disableAutoFocus: true,
                    disableAutoEnforceFocus: true,
                    disableRestoreFocus: true,
                }}
                label={lbl}
                disabled={!enabled || !data}
                value={value}
                id={'field-' + index}
                index={index}
                tabIndex={index}
                orientation={orientation}
                {...otherProps}
                open={open}
                onChange={this.handleOnChange}
                onFocus={this.handleOnFocus}
                onOpen={this.handleOnFocus}
                onClose={this.handleOnBlur}
                dispatchToWs={false}
            >
                {data && data.strList
                    ? data.strList.map((object, i) => {
                          const parts = object.split('. ')
                          return (
                              <MenuItem
                                  key={object + '-' + i}
                                  value={data.shtct[i]}
                              >
                                  {parts.length > 1 ? (
                                      // TODO: Criar componente de highlight
                                      <span
                                          className={clsx('highlight', {
                                              disabled: !enabled,
                                          })}
                                          // TODO: Criar styles via withStyles
                                          style={{
                                              minHeight: 'unset',
                                              lineHeight: 'inherit',
                                              marginTop: -4,
                                              marginRight: 8,
                                          }}
                                      >
                                          {data.shtct[i]}.{' '}
                                      </span>
                                  ) : null}

                                  {parts.pop()}
                              </MenuItem>
                          )
                      })
                    : null}
            </StyledSelect>
        )
    }
}
