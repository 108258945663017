import PropTypes from 'prop-types'

import TextFieldString from './String'

/**
 * Componente TextFieldChar
 *
 * Rec: REC_CHAR
 *
 * Renderiza um campo de texto que edita apenas 1 char
 */
export default class TextFieldChar extends TextFieldString {
    static propTypes = {
        mascara: PropTypes.object,
    }
}
