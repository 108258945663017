import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import useActiveFieldNavigation from '@nerus/framework/hooks/useActiveFieldNavigation'
import useAutoFocus from '@nerus/framework/hooks/useAutoFocus'
import useDispatchToWS from '@nerus/framework/hooks/useDispatchToWS'
import usePreventDispatchToWS from '@nerus/framework/hooks/usePreventDispatchToWS'
import usePreventTyping from '@nerus/framework/hooks/usePreventTyping'
import Label from '@nerus/framework/styled/Label'
import React from 'react'

import Description from './Description'
import { hookStyles } from './Input'

const StyledSelectComponent = (compProps, ref) => {
    const {
        testid,
        children,
        InputProps,
        InputLabelProps,
        label,
        disabled,
        open,
        desc,
        orientation = 'vertical',
        // variáveis que não queremos que apareça no DOM
        componentId, // eslint-disable-line
        beforeSend, // eslint-disable-line
        fieldType, // eslint-disable-line
        onKeyDownRoot, // eslint-disable-line
        // Hook switchers
        dispatchToWs = false,
        autoFocus = true, // eslint-disable-line
        preventTyping = true, // eslint-disable-line
        preventDispatchToWS = false, // eslint-disable-line
        dispatch, // eslint-disable-line
        ...props
    } = compProps
    const classes = hookStyles[orientation]()

    const hookProps = {
        ...props,
        dispatch,
        beforeSend,
        fieldType,
        componentId,
    }

    useAutoFocus(ref, hookProps, autoFocus)
    usePreventDispatchToWS(ref, hookProps, preventDispatchToWS && !dispatchToWs)
    useActiveFieldNavigation(ref, hookProps, preventDispatchToWS)
    usePreventTyping(ref, hookProps, preventTyping)
    useDispatchToWS(ref, hookProps, dispatchToWs && !preventDispatchToWS)

    return (
        <FormControl
            classes={{
                root: classes.root,
            }}
            ref={ref}
            onKeyDown={onKeyDownRoot}
        >
            {label && label.trim() ? (
                <InputLabel
                    {...InputLabelProps}
                    shrink
                    classes={{
                        root: classes.labelRoot,
                    }}
                >
                    {<Label text={label.trim()} />}
                </InputLabel>
            ) : (
                ''
            )}
            <Select
                style={InputProps ? InputProps.style || {} : {}}
                input={
                    <Input
                        {...InputProps}
                        classes={{ root: classes.selectFormControl }}
                        inputProps={{ 'data-testid': testid }}
                    />
                }
                classes={{
                    select: classes.selectRoot,
                }}
                MenuProps={{
                    hideBackdrop: true,
                    PopoverClasses: {
                        root: classes.popoverRoot,
                    },
                    classes: {
                        paper: classes.selectMenuList,
                    },
                }}
                disabled={disabled}
                open={open}
                {...props}
            >
                {children}
            </Select>
            {desc ? <Description text={desc} /> : null}
        </FormControl>
    )
}

export const StyledSelect = React.forwardRef(StyledSelectComponent)
export default StyledSelect
