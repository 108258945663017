export const styles = () => ({
    root: {
        flex: '1 1 20%',
        cursor: 'pointer',
        '& *': {
            cursor: 'pointer',
            alignItems: 'center',
        },
        '& svg': {
            width: 20,
        },
    },
    gray: {
        color: '#A1A6AE',
    },
})
