import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function Enotice(props) {
    return (
        <SvgIcon {...props} viewBox="2 2 22 22">
            <g>
                <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9v-2H4V8l8 5 8-5v5h2V6c0-1.1-.9-2-2-2zm-8 7L4 6h16l-8 5zm7 4 4 4-4 4v-3h-4v-2h4v-3z"></path>
            </g>
        </SvgIcon>
    )
}
