import PropTypes from 'prop-types'
import React from 'react'

import EnhancedComponent from '../../../../common/EnhancedComponent'
import { skipOverwrite } from '../../../../hooks/useOverwrite'
import { Input } from '../../../../styled/Input'
import FieldType from '../FieldType'

const debug = require('debug')('nerus:useOverwrite')

class AbstractTextField extends EnhancedComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        enabled: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
            .isRequired,
        widths: PropTypes.object.isRequired,
        orientation: PropTypes.string.isRequired,
        column: PropTypes.number.isRequired,
        updateField: PropTypes.func.isRequired,
        typeRecMod: PropTypes.string,
        mascara: PropTypes.object,
        wsStop: PropTypes.bool,
        lbl: PropTypes.string,
        width: PropTypes.number,
        sz: PropTypes.number,
        componentId: PropTypes.number,
        desc: PropTypes.string,
        onSendValue: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
    }

    static defaultProps = {
        wsStop: false,
    }

    fieldType = null

    constructor(props) {
        super(props)
        this.state = {
            value: props.value
                ? props.value.trimRight
                    ? props.value.trimRight()
                    : props.value
                : '',
        }
    }

    componentDidUpdate(prevProps) {
        const { value, enabled } = this.props
        if (prevProps.value !== value || prevProps.enabled !== enabled) {
            this.setState({
                value: value,
            })
        }
    }

    handleInputChange = (event, callback) => {
        event.persist && event.persist()
        const { onChange } = this.props
        const fieldType = this.getFieldType()
        const selectionStart = event.target ? event.target.selectionStart : 0

        let value = event.target.value
        if (fieldType && skipOverwrite(fieldType.typeRec)) {
            if (fieldType.formatter) {
                value = fieldType.formatter(value)
                debug(`apply formatter: ${value}`)
            }

            if (fieldType && fieldType.modifier) {
                value = fieldType.modifier(value)
                debug(`apply modifier: ${value}`)
            }
        }

        this.setState(
            {
                value,
            },
            () => {
                if (event.target) {
                    event.target.selectionStart = event.target.selectionEnd = selectionStart
                }

                callback && callback(event)
                onChange && onChange(this.state.value, event)
            }
        )
    }

    getFieldType = () => {
        const { typeRecMod, typeRec } = this.props
        if (typeRecMod && !this.fieldType) {
            const fieldTypeClass = FieldType[typeRecMod]
            const defaultFieldTypeClass = FieldType['n']

            const fieldType = fieldTypeClass
                ? new fieldTypeClass(this.props)
                : new defaultFieldTypeClass(this.props)

            fieldType.typeRec = typeRec
            this.fieldType = fieldType
            return fieldType
        }
        return this.fieldType
    }

    triggerKeyCode = keyCode => {
        const {
            props: { onSendValue, x, y },
            state: { value },
            beforeSend,
        } = this.props

        const parsedValue = beforeSend ? beforeSend(value, { keyCode }) : value

        onSendValue &&
            onSendValue({
                x,
                y,
                key: keyCode,
                value: parsedValue,
            })
    }

    render() {
        const {
            state: { hasError, value },
            props: {
                enabled,
                lbl,
                sz,
                x,
                y,
                index,
                orientation = 'horizontal',
                ...otherProps
            },
        } = this

        if (hasError) {
            return this.renderError()
        }

        const fieldType = this.getFieldType()
        const type = fieldType ? fieldType.type : 'text'

        return (
            <Input
                autoFocusOnBlur={false}
                {...otherProps}
                index={index}
                tabIndex={index}
                ref={this.createRef('input')}
                disabled={!enabled}
                position={{ x, y }}
                label={lbl}
                maxLength={sz}
                beforeSend={this.beforeSend}
                onChange={this.handleInputChange}
                orientation={orientation}
                value={value}
                type={type}
            />
        )
    }
}

export default AbstractTextField
