import { useCallback } from 'react'

const tableTypes = [
    { lbl: 'No. Produto', table: 'prd' },
    { lbl: 'No. Cliente', table: 'custp' },
    {
        lbl: 'No. Fornecedor',

        table: 'vend',
    },
    { lbl: 'No. Banco', table: 'bank' },
    {
        lbl: 'No. Centro Lucro',
        table: 'cl',
    },
    {
        lbl: 'No. Funcionario',
        table: 'emp',
    },
    { lbl: 'No. Loja', table: 'store' },
    { lbl: 'No. Tipo', table: 'type' },
    {
        lbl: 'No. Conta Razao',

        table: 'acc',
    },
    {
        lbl: 'No. Tipo Transacao',

        table: 'fxatp',
    },
    { lbl: 'No. Empresa', table: 'corp' },
    {
        lbl: 'No. Funcionario',

        table: 'emp',
    },
    { lbl: 'No. da Area', table: 'area' },
    {
        lbl: 'No. Usuario',
        table: 'users',
        where: 'bits1 & pow(2,0) != pow(2,0)',
    },
    {
        lbl: 'No. Grupo Fornecedores',

        table: 'vendgr',
    },
    { lbl: 'No. Funcao', table: 'empfnc' },
    {
        lbl: 'No. Transportadora',

        table: 'carr',
    },
    {
        lbl: 'No. do Centro Custo',

        table: 'ccusto',
    },
    {
        lbl: 'No. Grupo C.Custo',

        table: 'gcc',
    },
    {
        lbl: 'No. Metodo Pagto',

        table: 'paym',
    },
    { lbl: 'No. Rota', table: 'route' },
    {
        lbl: 'No. Aplicacao',

        table: 'aplic',
    },
    { lbl: 'No. Cartao', table: 'card' },
    {
        lbl: 'No. Marca',

        table: 'vendm',
        id: 'seqnoAuto',
        desc: 'marca',
    },
    {
        lbl: 'Classificacoes',

        table: 'cl',
    },
    {
        lbl: 'No. Promocao',

        table: 'promo',
    },
    {
        lbl: 'No. PDV',

        table: 'pdv',
        id: 'pdvno',
        desc: 'pdvno',
    },
    {
        lbl: 'No. Autor',

        table: 'autor',
        id: 'autorno',
        desc: 'name',
    },
    {
        lbl: 'No. da Editora',

        table: 'editora',
        id: 'editorano',
        desc: 'name',
    },
]

const types = [
    { lbl: 'Número', type: 0 },
    { lbl: 'Dinheiro', type: 1 },
    { lbl: 'Data', type: 2 },
    { lbl: 'Hora', type: 3 },
    { lbl: 'Mês/Ano', type: 4 },
]

tableTypes.forEach((t, i) => {
    types.push({
        ...t,
        type: 5 + i,
    })
})

export const list = types.reduce((prev, current) => {
    return {
        ...prev,
        [current.type]: current,
    }
}, {})

export default function useFilterTypes() {
    const byType = useCallback(
        type => {
            return typeof type === 'object' ? type : list[type]
        },
        [list]
    )

    const map = useCallback(
        callbackFn => {
            return Object.values(list).map(callbackFn)
        },
        [list]
    )

    return {
        list,
        byType,
        map,
    }
}
