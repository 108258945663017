import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useCallback, useState } from 'react'

import { DASHBOARDS_QUERY } from './useFetchDashboards'

export const REMOVE_DASHBOARD_MUTATION = `
    mutation RemoveDashboard($dashboard_id: Int!) {
        removeDashboard(id: $dashboard_id) {
            message
        }
    }
`

export default function useRemoveDashboard(onRemove, options) {
    const [dashboard_id, setShowRemoveDialog] = useState(null)

    const [removeDashboard] = useMutation(gql(REMOVE_DASHBOARD_MUTATION), {
        displayName: 'removeDashboard',
        refetchQueries: () => [{ query: DASHBOARDS_QUERY }],
        ...options,
    })

    const onRemoveDashboard = useCallback(() => {
        removeDashboard({
            variables: {
                dashboard_id,
            },
        })
            .then(data => {
                onRemove && onRemove(data)
                setShowRemoveDialog(null)
            })
            .catch(err => {
                // TODO: Exibir dialogo de erro
                console.error(err)
            })
    }, [dashboard_id, removeDashboard, onRemove])

    const onCloseRemove = useCallback(() => {
        setShowRemoveDialog(null)
    }, [setShowRemoveDialog])

    const onShowRemoveDialog = useCallback(
        id => () => {
            setShowRemoveDialog(id)
        },
        [setShowRemoveDialog]
    )

    return {
        showRemoveDialog: dashboard_id,
        onShowRemoveDialog,
        onRemoveDashboard,
        onCloseRemove,
    }
}
