import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import * as Icons from '@material-ui/icons'
import withStyles from '@material-ui/styles/withStyles'
import transformColor from '@nerus/framework/util/transformColor'
import * as PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

class BlocoTitulo extends PureComponent {
    constructor(props) {
        super(props)
    }

    static propTypes = {
        value: PropTypes.string.isRequired,
        info: PropTypes.string,
        colors: PropTypes.object,
        interceptions: PropTypes.object,
        classes: PropTypes.object,
        image: PropTypes.string,
        height: PropTypes.number,
        size: PropTypes.number,
    }

    getFontSize = value => {
        switch (value) {
            case 'small': {
                return 36
            }
            case 'normal': {
                return 72
            }
            case 'huge': {
                return 144
            }
            case 'extrahuge': {
                return 216
            }
        }
    }

    render() {
        const {
            colors = {},
            interceptions = {},
            value,
            image,
            classes,
        } = this.props

        const min = interceptions?.min.replace(/,/gim, '.')
        const max = interceptions?.max.replace(/,/gim, '.')
        const formattedValue = Number(value.replace(/,/gim, '.'))

        const {
            titleMinValue = 'rgba(0,0,0,0.87)',
            titleMaxValue = 'rgba(0,0,0,0.87)',
            icon = 'rgba(255,255,255,1)',
            value: defaultColor = 'rgba(0,0,0,0.87)',
            alignIcon = 'center',
            alignValue = 'center',
            sizeIcon = 'normal',
            sizeValue = 'normal',
            spaceIcon = 2,
            spaceValue = 10,
        } = colors

        const color =
            formattedValue >= Number(max) && max
                ? transformColor(titleMaxValue)
                : formattedValue >= Number(min) && min
                ? transformColor(titleMinValue)
                : transformColor(defaultColor)

        const iconColor = transformColor(icon)

        const fontSize = this.getFontSize(sizeValue)
        const fontSizeIcon = this.getFontSize(sizeIcon)
        const valueTypo = (
            <Typography
                variant="h4"
                style={{ color, fontSize, textAlign: alignValue }}
            >
                {value}
            </Typography>
        )

        if (image) {
            const Icon = Icons[image]
            return (
                <Grid container className={classes.centered}>
                    <Grid
                        item
                        xs={spaceIcon}
                        style={{ color: iconColor, textAlign: alignIcon }}
                    >
                        <Icon style={{ fontSize: fontSizeIcon }} />
                    </Grid>
                    <Grid item xs={spaceValue}>
                        {valueTypo}
                    </Grid>
                </Grid>
            )
        }

        return (
            <Grid container className={classes.centered}>
                <Grid item xs={12}>
                    {valueTypo}
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(() => ({
    centered: {
        flex: 1,
        height: '90%',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiGrid-item': {
            textAlign: 'center',
        },
    },
}))(BlocoTitulo)
