import * as PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

class Imagem extends PureComponent {
    constructor(props) {
        super(props)
    }
    static propTypes = {
        data: PropTypes.array.isRequired,
        image: PropTypes.string,
        height: PropTypes.number,
    }
    render() {
        const { image, height } = this.props
        if (!image) {
            return null
        }

        const styles = { maxWidth: '100%', maxHeight: '100%' }

        const stylesBox = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            height: '90%',
            maxHeight: height ? height * 64 - 84 : undefined,
        }

        return (
            <div style={stylesBox}>
                <img style={styles} src={image} />
            </div>
        )
    }
}

export default Imagem
