import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
    slider: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        maxWidth: 536,
    },
    sliderItem: {
        width: 100,
        height: 100,
        border: '1px solid #ccc',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '&:last-child': {
            marginRight: 0,
        },
        '&:nth-child(5n)': {
            marginRight: 0,
        },
        '&:nth-child(5+n)': {
            marginBottom: 0,
        },
        '@media(max-height: 880px)': {
            height: '50px',
        },
    },
    sliderItemActive: {
        borderColor: theme.palette.primary.main,
    },
    imgPreview: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    draggable: {
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing',
        },
        '& svg': {
            fontSize: 16,
        },
    },
}))

const Pictures = ({ data, active, onClick, classes = {}, onDrag }) => {
    let dragged, overIndex
    const cls = useStyles()

    const onDragStart = (e, index) => {
        dragged = index
        overIndex = null
        e.dataTransfer.effectAllowed = 'move'
        e.dataTransfer.setData('text/html', e.target.parentNode)
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20)
    }

    const onDragEnd = () => {
        onDrag(dragged === overIndex, true, dragged, overIndex)

        dragged = null
        overIndex = null
    }

    const onDragOver = overIndexParam => {
        overIndex = overIndexParam
        return onDrag(dragged === overIndex, false, dragged, overIndex)
    }

    return (
        <Box className={clsx(cls.slider, classes.slider)}>
            {data.map((foto, index) => {
                const {
                    seqno,
                    seqnoAuto,
                    // prdseq,
                    foto: base64,
                    urlImagem,
                } = foto

                const sliderItemActive =
                    (seqno && seqno === active.seqno) ||
                    // (prdseq && prdseq === active.prdseq) || -> removido para o ticket 98179
                    (seqnoAuto && seqnoAuto === active.seqnoAuto)

                return (
                    <Box
                        onDragOver={() => onDrag && onDragOver(index)}
                        key={seqno || seqnoAuto}
                        className={clsx(cls.sliderItem, {
                            [cls.sliderItemActive]: sliderItemActive,
                            [classes.sliderItem]: classes.sliderItem,
                            [classes.sliderItemActive]:
                                classes.sliderItemActive && sliderItemActive,
                        })}
                        onClick={onClick && onClick(foto)}
                    >
                        <img
                            draggable={Boolean(onDrag)}
                            onDragStart={e => onDrag && onDragStart(e, index)}
                            onDragEnd={() => onDrag && onDragEnd(index)}
                            src={
                                urlImagem
                                    ? urlImagem
                                    : 'data:image/jpg;base64,' + base64
                            }
                            className={clsx(
                                cls.imgPreview,
                                classes.imgPreview,
                                { [cls.draggable]: Boolean(onDrag) }
                            )}
                        />
                    </Box>
                )
            })}
        </Box>
    )
}

Pictures.propTypes = {
    data: PropTypes.array,
    onDrag: PropTypes.func,
    onClick: PropTypes.func,
    active: PropTypes.object,
    classes: PropTypes.object,
}

export default Pictures
