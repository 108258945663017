import { withStyles } from '@material-ui/core/styles'
import { ResponsiveBar } from '@nivo/bar'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'

import { initialProperties } from '../Dashboard/Actions/GraphConfig/defaults/bar-stacked'
import GraphTooltip from '../Dashboard/Components/GraphTooltip'
import getGraphProps from './getGraphProps'

const styles = {
    grafico: {
        width: '100%',
        height: '100%',
    },
}

class GraficoBarraStacked extends Component {
    constructor(props) {
        super(props)
    }

    static propTypes = {
        axes: PropTypes.object,
        classes: PropTypes.object,
        custom: PropTypes.object,
        data: PropTypes.array.isRequired,
    }

    render() {
        const {
            props: { axes, classes, data, custom = {} },
        } = this

        function tooltip(props) {
            const { indexValue, value, color } = props

            return (
                <GraphTooltip
                    noshadow
                    color={color}
                    value={value}
                    label={indexValue}
                    format={axes?.y?.format}
                />
            )
        }

        const keys = this.props.data.length
            ? [...this.props.data[0].legends]
            : []
        const indexBy = keys.shift()

        const graphProps = getGraphProps(
            initialProperties,
            axes,
            data.length ? data[0].data : [],
            tooltip,
            custom
        )

        graphProps.keys = keys
        graphProps.indexBy = indexBy

        return (
            <div className={classes.grafico}>
                <ResponsiveBar {...graphProps} />
            </div>
        )
    }
}

export default withStyles(styles)(GraficoBarraStacked)
