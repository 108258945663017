import PropTypes from 'prop-types'

import { TextFieldString } from './String'

/**
 * Componente TextFieldChar
 *
 * Rec: REC_CHAR
 *
 * Renderiza um campo de texto que edita apenas 1 char
 */
export class TextFieldChar extends TextFieldString {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        mascara: PropTypes.object,
    }
}

export default TextFieldChar
