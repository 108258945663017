import { withStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { alpha } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import BaseFlutuante from '@nerus/framework/components/Base/Flutuante'
import Button from '@nerus/framework/styled/Button'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import NerusColorPicker from './NerusColorPicker'

const styles = theme => ({
    root: {
        flex: 1,
        height: '100%',
    },
    input: {
        height: 'inherit',
        flex: 1,
        maxHeight: '100%',
        minHeight: '100%',
        fontFamily:
            '"monospace", "Monaco", "Menlo", "Andale Mono", "Lucida Sans Typewriter", "Droid Sans Mono", "Deja Vu Sans Mono", "Courier New", "Courier"',
        whiteSpace: 'pre',
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: alpha('#000000', 0.15),
            '&:hover': {
                backgroundColor: alpha('#000000', 0.15),
            },
        },
        '&::-webkit-scrollbar': {
            width: 8,
            height: 8,
            cursor: 'pointer',
        },
    },
    background: {
        height: '100%',
        backgroundColor: theme.palette.common.white + ' !important',
        border: '1px solid ' + theme.palette.primary.dark,
        padding: theme.spacing(1),
        overflow: 'auto',
        '&:hover': {
            backgroundColor: theme.palette.common.white + ' !important',
        },
    },
    rootContainer: {
        display: 'flex',
        flex: 1,
    },
    flexContainer: {
        display: 'flex',
    },
    actions: {
        paddingTop: theme.spacing(1),
        '& button:last-child': {
            margin: 0,
        },
    },
    flex: {
        flex: 1,
    },
    queryMenu: {
        minWidth: 200,
    },
    right: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
        display: 'flex',
    },
    listRoot: {
        marginRight: theme.spacing(1),
        flex: 1,
        overflow: 'auto',
        maxHeight: '100%',
        padding: 0,
        borderRadius: 0,
    },
    item: {
        borderLeft: `4px solid ${theme.palette.common.white}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        '& span': {
            fontSize: 12,
        },
        '& svg': {
            color: theme.palette.primary.main,
        },
        '&:hover': {
            borderLeft: `4px solid ${theme.palette.primary.dark}`,
        },
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    itemActive: {
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        '&:hover': {
            borderLeft: `4px solid ${theme.palette.primary.dark}`,
        },
        '& svg': {
            color: theme.palette.common.white,
        },
    },
    itemText: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
    },
})

class Axes extends EnhancedComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        initialData: PropTypes.object,
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        allowedAxis: PropTypes.array,
        type: PropTypes.number,
    }

    static defaultProps = {
        allowedAxis: [],
    }

    constructor(props) {
        super(props)
        this.state = {
            active: 'x',
            axes: props.initialData || {},
        }
    }

    handleChange = event => {
        const axes = { ...this.state.axes }
        const axis = axes[this.state.active]

        axis[event.target.name] = event.target.value
        this.setState({
            axes,
        })
    }

    handleColor = (name, color) => {
        const axes = { ...this.state.axes }
        const axis = axes[this.state.active]

        axis[name] = color
        this.setState({
            axes,
        })
    }

    handleClose = () => {
        this.props.onClose?.()
    }

    handleSelect = active => () => {
        this.setState({
            active,
        })
    }

    handleDelete = index => event => {
        event.stopPropagation()
        const axes = { ...this.state.axes }

        axes.splice(index, 1)

        this.setState({
            axes,
            active: 0,
        })
    }

    handleSave = () => {
        this.props.onSave?.(this.state.axes)
    }

    render() {
        const {
            props: { classes, allowedAxis },
            state: { active, axes },
            handleClose,
            handleSelect,
            handleSave,
        } = this

        const currentAxis = axes[active]
        const shouldColorize =
            allowedAxis.length === 0 || allowedAxis.indexOf(active) > -1

        return (
            <BaseFlutuante
                title="Editor de Eixos"
                size="medium"
                onClose={handleClose}
            >
                <Box
                    className={clsx(
                        classes.rootContainer,
                        classes.flexContainer
                    )}
                >
                    <Box className={classes.queryMenu}>
                        <List component="nav" className={classes.listRoot}>
                            {Object.keys(axes).map(index => {
                                return (
                                    <ListItem
                                        button
                                        key={index}
                                        className={clsx(classes.item, {
                                            [classes.itemActive]:
                                                active === index,
                                        })}
                                        onClick={handleSelect(index)}
                                    >
                                        <ListItemText
                                            primary={`Eixo ${index}`}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Box>

                    <Box className={classes.flex}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Título"
                                    name="title"
                                    value={currentAxis.title}
                                    className={classes.titleInput}
                                    onChange={this.handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel shrink id="menu-id-label">
                                        Formato do Campo
                                    </InputLabel>
                                    <Select
                                        className={classes.titleInput}
                                        value={currentAxis.format || null}
                                        name="format"
                                        onChange={this.handleChange}
                                        displayEmpty
                                        required
                                    >
                                        <MenuItem value={null}>
                                            Sem formatação
                                        </MenuItem>
                                        <MenuItem value="money">
                                            Dinheiro
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.flexContainer}
                            >
                                <TextField
                                    fullWidth
                                    label="Cor do Rótulo"
                                    disabled
                                    className={classes.titleInput}
                                />
                                <NerusColorPicker
                                    allowReset
                                    name="labelColor"
                                    value={currentAxis.labelColor}
                                    onChange={this.handleColor}
                                />
                            </Grid>
                            {shouldColorize ? (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.flexContainer}
                                >
                                    <TextField
                                        fullWidth
                                        label="Cor do Eixo"
                                        disabled
                                        className={classes.titleInput}
                                    />
                                    <NerusColorPicker
                                        allowReset
                                        name="color"
                                        value={currentAxis.color}
                                        onChange={this.handleColor}
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                    </Box>
                </Box>

                <Box className={clsx(classes.flexContainer, classes.actions)}>
                    <Box className={classes.flex} />

                    <Box className={classes.right}>
                        <Button
                            lbl={'Cancelar'}
                            dialog
                            color="danger"
                            onClick={handleClose}
                        />
                        <Button
                            lbl={'Salvar'}
                            primary
                            dialog
                            onClick={handleSave}
                        />
                    </Box>
                </Box>
            </BaseFlutuante>
        )
    }
}

export default connect()(withStyles(styles)(Axes))
