import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/editor'
import React from 'react'
import { connect } from 'react-redux'

import BaseFlutuante from '../Base/Flutuante'
import BaseEditor from './BaseEditor'

export class Editor extends BaseEditor {
    static mapStateToProps = (state, ownProps) => {
        return {
            title: ownProps.title,
            search: ownProps.query,
            searchLabel: ownProps.lbQuery,
            isNew: ownProps.new || true,
            mdQuery: ownProps.mdQuery,
            rows: ownProps.rows || [],
            columns: ownProps.cols || [],
            columnsType: ownProps.colsType || [],
            gridTot: ownProps.gridTot,
            buttons: ownProps.bt || [],
            icons: ownProps.btIcons || [],
            activeRow: ownProps.edtLin || 0,
            floating: ownProps.flt || false,
            enabled: ownProps.enabled,
            legend: ownProps.clrs || [],
            mouseLock: state.eac.mouseLock || false,
            showLegend: ownProps.showLegend || false,
            handleOrdering: ownProps.handleOrdering,
            sortIcon: ownProps.sortIcon,
        }
    }

    render() {
        const rendered = BaseEditor.prototype.render.call(this)
        const { floating, title, columns, onClose } = this.props

        if (floating) {
            return (
                <BaseFlutuante
                    key="baseFlutuanteEditorGenerico"
                    title={title}
                    actions={null}
                    onClose={onClose}
                    nospace
                    size={columns?.length > 5 ? 'big' : 'medium'}
                >
                    {rendered}
                </BaseFlutuante>
            )
        }
        return rendered
    }
}

export default connect(Editor.mapStateToProps)(withStyles(styles)(Editor))
