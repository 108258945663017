export const styles = theme => ({
    root: {
        display: 'flex',
        borderRadius: 5,
        flexDirection: 'column',
        boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.1)`,
        backgroundColor: theme.palette.common.white,
        marginRight: `0.8571428571428571em`,
        flex: 1,
        cursor: 'pointer',
        overflow: 'hidden',
        justifyContent: 'center',
        minHeight: theme.spacing(19), // 152px
        '&:last-child': {
            marginRight: 0,
        },
        '& *': {
            cursor: 'pointer',
        },
    },
    icon: {
        display: 'flex',
    },
    value: {
        fontSize: `3em`,
        fontWeight: 700,
        textAlign: 'right',
    },
    valueOnly: {
        textAlign: 'center',
        padding: theme.spacing(1),
    },
    info: {
        fontSize: 10,
        fontWeight: 400,
        // minHeight: theme.spacing(3),
    },
    right: {
        textAlign: 'right',
    },
    content: {
        display: 'flex',
    },
    bevel: {
        borderRadius: 10,
        padding: theme.spacing(0, 1, 2),
        backgroundColor: `#EBEBEB`,
        boxShadow: `inset 0px 1px 1px rgba(0, 0, 0, 0.25)`,
    },
    title: {
        margin: 0,
        fontSize: 12,
        fontWeight: 700,
        color: '#333333',
        textAlign: 'center',
        whiteSpace: 'break-spaces',
        padding: theme.spacing(1),
    },
    maxHeight: {
        height: '100%',
    },
    alignItems: {
        alignItems: 'center',
    },
    bevelTitle: {
        fontSize: 14,
        textAlign: 'initial',
    },
    colorBlue: {
        backgroundColor: '#2196F3',
        color: '#ffffff',
        '& h3': {
            color: '#ffffff',
        },
    },
    colorBlue800: {
        backgroundColor: '#000D15',
        color: '#ffffff',
        '& h3': {
            color: '#ffffff',
        },
    },
    colorBlue700: {
        backgroundColor: '#00131E',
        color: '#ffffff',
        '& h3': {
            color: '#ffffff',
        },
    },
    colorBlue600: {
        backgroundColor: '#001B2B',
        color: '#ffffff',
        '& h3': {
            color: '#ffffff',
        },
    },
    colorBlue500: {
        backgroundColor: '#003757',
        color: '#ffffff',
        '& h3': {
            color: '#ffffff',
        },
    },
    colorBlue400: {
        backgroundColor: '#004F7C',
        color: '#ffffff',
        '& h3': {
            color: '#ffffff',
        },
    },
    colorBlue300: {
        backgroundColor: '#0071B1',
        color: '#ffffff',
        '& h3': {
            color: '#ffffff',
        },
    },
    colorBlue200: {
        backgroundColor: '#00A1FC',
        color: '#ffffff',
        '& h3': {
            color: '#ffffff',
        },
    },
    colorBlue100: {
        backgroundColor: '#4CBDFD',
        color: '#ffffff',
        '& h3': {
            color: '#ffffff',
        },
    },
    colorGreen: {
        backgroundColor: '#1DD795',
        color: '#ffffff',
        '& h3': {
            color: '#ffffff',
        },
    },
    colorDarkGreen: {
        color: '#008180',
        '& h3': {
            color: '#008180',
        },
    },
    colorPurple: {
        color: '#8862E0',
        '& h3': {
            color: '#8862E0',
        },
    },
    details: {
        padding: theme.spacing(1),
    },
    statusDetails: {
        whiteSpace: 'nowrap',
        backgroundColor: `#F3F3F3`,
        color: `#A1A6AE`,
        padding: theme.spacing(1),
    },
})
