import TextFieldNegativableShort from './NegativableShort'

/**
 * Componente TextFieldPerc
 *
 * Rec: REC_PERC
 *
 * Renderiza um campo de texto que tem formatação de casas
 * decimais
 */
export default class TextFieldPerc extends TextFieldNegativableShort {
    fixedDecimalScale = true
}
