import { Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import RemoveAllIcon from '@material-ui/icons/ArrowBack'
import AddAllIcon from '@material-ui/icons/ArrowForward'
import { styles } from '@nerus/styles/components/boxSelector'
import throttle from 'lodash.throttle'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Icon from '../Button/IconButton'
import Editor from '../Editor'
import Formulario from '../Formulario'
import OptionBox from './Estrutura/OptionBox'
import { generateId } from './Estrutura/OptionItem'

function findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i
        }
    }
    return -1
}

export class BoxSeletor extends Component {
    static propTypes = {
        options: PropTypes.array,
        multiselect: PropTypes.bool,
        allowDuplicated: PropTypes.bool,
        showSelectedBox: PropTypes.bool,
        onChange: PropTypes.func,
        width: PropTypes.number,
        height: PropTypes.number,
        title: PropTypes.string,
        selectedTitle: PropTypes.string,
        cols: PropTypes.array,
        colsType: PropTypes.array,
        actions: PropTypes.array,
        descriptionFields: PropTypes.array,
        value: PropTypes.array,
        classes: PropTypes.object.isRequired,
        onRowClick: PropTypes.func,
        onRowDoubleClick: PropTypes.func,
    }

    static defaultProps = {
        multiselect: false,
        allowDuplicated: true,
        showSelectedBox: false,
        width: null,
        height: 400,
        title: 'Valores:',
        selectedTitle: null,
        descriptionFields: [],
    }

    constructor(props) {
        super(props)
        this.state = {
            options: props.options,
            selectedToAdd: [],
            selectedToRemove: [],
            value: props.value,
        }
    }

    addAll = () => {
        const { options, allowDuplicated } = this.props
        this.setState(
            {
                options: allowDuplicated ? options : [],
                value: options.map((opt, i) => {
                    let opts = {
                        id: generateId(opt, i),
                    }

                    if (typeof opt === 'object') {
                        opts = {
                            ...opts,
                            ...opt,
                        }
                    } else {
                        opts.lbl = opt
                        opts.value = opt
                    }

                    return opts
                }),
            },
            () => this.onUpdateSelectedOptions(this.state.value)
        )
    }

    addSelected = () => {
        const { allowDuplicated } = this.props
        const { value, selectedToAdd, options } = this.state

        const newOptions = [...options]
        const newValue = [...value]
        selectedToAdd.forEach(opt => {
            if (!allowDuplicated) {
                const index = newOptions.indexOf(opt)
                if (index > -1) {
                    newOptions.splice(index, 1)
                }
            }

            let size = 1
            do {
                opt.id = generateId(opt, newValue.length + size)
            } while (findWithAttr(newValue, 'id', opt.id) > -1 && size++)

            newValue.push(opt)
        })

        this.setState(
            {
                selectedToAdd: [],
                options: newOptions,
                value: newValue,
            },
            () => this.onUpdateSelectedOptions(this.state.value)
        )
    }

    removeSelected = () => {
        const { allowDuplicated } = this.props
        const { value, selectedToRemove, options } = this.state

        const newOptions = [...options]
        const newValue = [...value]

        selectedToRemove.forEach(opt => {
            const index = findWithAttr(newValue, 'id', opt.id)
            if (index > -1) {
                newValue.splice(index, 1)
            }
            if (!allowDuplicated) {
                newOptions.push(opt)
            }
        })

        this.setState(
            {
                selectedToRemove: [],
                options: newOptions,
                value: newValue,
            },
            () => this.onUpdateSelectedOptions(this.state.value)
        )
    }

    removeAll = () => {
        const { options } = this.props
        this.setState(
            {
                options,
                value: [],
            },
            () => this.onUpdateSelectedOptions(this.state.value)
        )
    }

    updateSelectedToAdd = (item, selected) => {
        this.setState(
            {
                selectedToAdd: selected,
                selectedToRemove: [],
            },
            () => {
                this.addSelected()
            }
        )
    }

    updateSelectedToRemove = (item, selected) => {
        this.setState(
            {
                selectedToAdd: [],
                selectedToRemove: selected,
            },
            () => {
                this.removeSelected()
            }
        )
    }

    onUpdateSelectedOptions = selected => {
        const { onChange } = this.props
        onChange && onChange(selected)
    }

    onRowClick = value => row => {
        this.props.onRowClick && this.props.onRowClick(value, row)
    }
    onDoubleRowClick = value => row => {
        this.props.onRowDoubleClick && this.props.onRowDoubleClick(value, row)
    }

    onDrag = throttle((isSame, isEnd, draggedIndex, overIndex) => {
        if (!isSame && isEnd) {
            let fields = this.state.value.filter((e, i) => i !== draggedIndex)
            fields.splice(overIndex, 0, this.state.value[draggedIndex])
            this.setState(
                {
                    value: fields,
                },
                () => {
                    this.onUpdateSelectedOptions(fields)
                }
            )
        }
    }, 100)

    render() {
        const {
            multiselect,
            width,
            height,
            title,
            selectedTitle,
            cols,
            colsType,
            actions,
            descriptionFields,
            classes,
        } = this.props
        const { options, value, selectedToAdd, selectedToRemove } = this.state

        return (
            <div className={classes.root}>
                <div className={classes.optionsBox}>
                    {title ? (
                        <Typography variant={'h2'} className={classes.boxTitle}>
                            {title}
                        </Typography>
                    ) : null}
                    <OptionBox
                        multiselect={multiselect}
                        onSelectItem={this.updateSelectedToAdd}
                        options={options}
                        selected={selectedToAdd}
                        width={width}
                        height={height}
                    />
                </div>
                <div className={classes.actions}>
                    <Icon
                        disabled={!options.length}
                        onClick={this.addAll}
                        tooltip="Adicionar Todos"
                    >
                        <AddAllIcon />
                    </Icon>
                    <Icon
                        disabled={!value.length}
                        onClick={this.removeAll}
                        tooltip="Remover Todos"
                    >
                        <RemoveAllIcon />
                    </Icon>
                </div>
                <div className={classes.selectedBox}>
                    {selectedTitle ? (
                        <Typography variant={'h2'}>{selectedTitle}</Typography>
                    ) : null}
                    <Editor
                        nospace
                        hideTitle
                        cols={cols}
                        isFixo={true}
                        actions={actions.map(action => ({
                            ...action,
                            onClick: (row, index) => {
                                return action.onClick(row, index, selected => {
                                    this.updateSelectedToRemove(row, [selected])
                                })
                            },
                        }))}
                        isEditorForm={true}
                        colsType={colsType}
                        edtLin={selectedToRemove}
                        className={classes.editor}
                        onRowClick={this.onRowClick(value)}
                        onRowDoubleClick={this.onDoubleRowClick(value)}
                        rows={value.map(sel =>
                            colsType.map(
                                type =>
                                    `${
                                        sel[type.name] !== undefined
                                            ? sel[type.name]
                                            : ''
                                    }`
                            )
                        )}
                        draggable
                        onDrag={this.onDrag}
                    />
                    {descriptionFields.length ? (
                        <Formulario
                            useDiv
                            hideSubmit
                            list={descriptionFields}
                        />
                    ) : null}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(BoxSeletor)
