import makeStyles from '@material-ui/core/styles/makeStyles'
import config from '@nerus/config'
import { styles } from '@nerus/styles/components/painelo2/conexoes'
import React from 'react'

import ConexaoStatus from './ConexaoStatus'
import Anymarket from './Icones/Anymarket'
import Ideris from './Icones/Ideris'
import LojaIntegrada from './Icones/LojaIntegrada'
import Magento from './Icones/Magento'
import Pluggto from './Icones/Pluggto'
import Tray from './Icones/Tray'
import Vendala from './Icones/Vendala'
import Vtex from './Icones/Vtex'

const useStyles = makeStyles(styles, { name: 'PainelO2-Conexoes' })

export default function Conexoes() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.column}>
                <h4 className={classes.title}>Hubs conectados</h4>
                <div className={classes.connections}>
                    <ConexaoStatus
                        url={config.painelO2.links.ideris}
                        status={config.painelO2.links.ideris ? 'sucesso' : null}
                        icone={Ideris}
                    />
                    <ConexaoStatus
                        url={config.painelO2.links.anymarket}
                        status={
                            config.painelO2.links.anymarket ? 'sucesso' : null
                        }
                        icone={Anymarket}
                    />
                    <ConexaoStatus
                        url={config.painelO2.links.pluggto}
                        status={
                            config.painelO2.links.pluggto ? 'sucesso' : null
                        }
                        icone={Pluggto}
                    />
                    <ConexaoStatus
                        url={config.painelO2.links.vendala}
                        status={
                            config.painelO2.links.vendala ? 'sucesso' : null
                        }
                        icone={Vendala}
                    />
                </div>
            </div>
            <div className={classes.column}>
                <h4 className={classes.title}>Plataformas conectadas</h4>
                <div className={classes.connections}>
                    <ConexaoStatus
                        url={config.painelO2.links.lojaIntegrada}
                        status={
                            config.painelO2.links.lojaIntegrada
                                ? 'sucesso'
                                : null
                        }
                        icone={LojaIntegrada}
                    />
                    <ConexaoStatus
                        url={config.painelO2.links.tray}
                        status={config.painelO2.links.tray ? 'sucesso' : null}
                        icone={Tray}
                    />
                    <ConexaoStatus
                        url={config.painelO2.links.vtex}
                        status={config.painelO2.links.vtex ? 'sucesso' : null}
                        icone={Vtex}
                    />
                    <ConexaoStatus
                        url={config.painelO2.links.magento}
                        status={
                            config.painelO2.links.magento ? 'sucesso' : null
                        }
                        icone={Magento}
                    />
                </div>
            </div>
        </div>
    )
}
