import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const ENOTICE_RAW_QUERY = gql`
    query rawQueryEnotice($sql: String!) {
        rawQueryEnotice(sql: $sql) {
            error
            message
            data
        }
    }
`

export default function useRawQuery() {
    return useLazyQuery(ENOTICE_RAW_QUERY, {
        displayName: 'rawQuery',
        fetchPolicy: 'cache-and-network',
    })
}
