import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function WrenchIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0.012,19.2)">
                <path d="M 681,639 Q 681,582 648.5,536 616,490 564,470 v -605 q 0,-27 -19,-46 -19,-19 -46,-19 -26,0 -45,19 -19,19 -19,46 v 605 q -52,20 -84.5,66 -32.5,46 -32.5,103 0,53 27,96 27,43 73,65 V 691 q 0,-34 23.5,-57.5 Q 465,610 499,610 q 34,0 58,23.5 24,23.5 24,57.5 V 800 Q 626,778 653.5,734.5 681,691 681,639 Z M 499,-170 q 15,0 25,10 10,10 10,25 0,14 -10,24.5 -10,10.5 -25,10.5 -14,0 -24.5,-10.5 Q 464,-121 464,-135 q 0,-15 10.5,-25 10.5,-10 24.5,-10 z" />
            </g>
        </SvgIcon>
    )
}
