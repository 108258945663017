import { useCallback, useEffect, useState } from 'react'

const debug = require('debug')('nerus:useClickToTrackCaret')

export default function useClickToTrackCaret(
    ref,
    { fieldType, disabled },
    enabled = true
) {
    const [initialized, setInitialized] = useState(false)

    const onClick = useCallback(() => {
        if (!enabled) {
            debug(`Hook is not enabled`)
            return
        }

        if (!ref || !ref.current || disabled) {
            return
        }

        let range = 0
        const input = ref.current
        if (fieldType && fieldType.toEnd === true) {
            range = input.value.length
        }
        debug(`Moving to range: ${range}`)
        if (input.createTextRange) {
            let range = input.createTextRange()
            range.move('character', range)
            range.select()
        } else if (input.setSelectionRange) {
            input.setSelectionRange(range, range)
        }
    }, [enabled, disabled, fieldType])

    useEffect(() => {
        if (disabled && initialized) {
            setInitialized(false)
        }

        if (!disabled && !initialized && enabled) {
            setInitialized(true)
            onClick()
        }
    }, [disabled, enabled])

    return ref
}
