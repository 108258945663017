import keycodes from '@nerus/framework/common/Keycodes'

import Interface from './Interface'

export function handleKeyboard() {
    return (dispatch, o, data) => {
        const { localFileName } = data

        if (o.keyCode === keycodes.ENTER_KEY) {
            o.preventDefault()
            o.stopImmediatePropagation && o.stopImmediatePropagation()

            if (localFileName) {
                window.open(`/webprint/load${localFileName}`)
                return
            }
        }
    }
}

export default class FileSend extends Interface {
    static before({ state, action }) {
        action.payload.disableKeyboard = true
        action.payload.keyboardActions = handleKeyboard

        return { state, action }
    }
}
