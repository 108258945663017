export class PromiseContainer {
    constructor() {
        this.store = null
        this.clear()
    }

    clear() {
        this.promises = []
        this.promisesRequired = []
    }

    setStore(store) {
        this.store = store
    }

    add(promise, required) {
        if (required) {
            this.promisesRequired.push(promise)
        } else {
            this.promises.push(promise)
        }
    }

    onResolve() {
        const promises = [],
            promisesRequired = [],
            that = this

        that.promisesRequired.forEach(promise => {
            promisesRequired.push(promise(that.store))
        })

        return Promise.all(promisesRequired)
            .then(() => {
                that.promises.forEach(promise => {
                    promises.push(promise(that.store))
                })

                return Promise.all(promises)
            })
            .then(data => {
                that.clear()

                return Promise.resolve(data)
            })
    }
}

export default new PromiseContainer()
