import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import keycodes from '@nerus/framework/common/Keycodes'
import Flutuante from '@nerus/framework/components/Base/Flutuante'
import IconButton from '@nerus/framework/components/Editor/Estrutura/IconButton'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import { useO2 } from '@nerus/framework/hooks/useO2'
import BlankDocumentIcon from '@nerus/framework/icons/BlankDocument'
import DashboardLibraryIcon from '@nerus/framework/icons/DashboardLibrary'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

import NerusITLayout from '../components/common/Layout'
import Actions from './Actions'
import Search from './Actions/Search'
import { renderCard } from './DashboardCard'
import DashboardLibrary from './DashboardLibrary'
import RemoveRecord from './Dialogs/RemoveRecord'
import useFetchDashboards from './hooks/useFetchDashboards'
import useRemoveDashboard from './hooks/useRemoveDashboard'

const useStyles = makeStyles(() => ({
    content: {
        overflowX: 'hidden',
        overflowY: 'auto',
        display: 'flex',
        height: '100%',
        flex: 1,
    },
    container: {
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        margin: 0,
    },
    dialogo: {
        backgroundColor: '#E8E8E8',
        '& > div:last-child': {
            paddingTop: 16,
        },
    },
    paper: {
        background: '#E8E8E8',
        padding: 16,
        textAlign: 'center',
        width: 205,
        height: 194,
        cursor: 'pointer',
        margin: 8,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: 8,
    },
    formBox: {
        width: 466,
    },
    formBoxText: {
        fontSize: 12,
    },
    icon: {
        color: '#97262c',
        height: 90,
        width: 90,
    },
    iconText: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '666666',
    },
}))

function applyFilter(filter, data) {
    if (!filter) return data

    return data.filter(
        row => row.title.toLowerCase().indexOf(filter.toLowerCase()) > -1
    )
}

function Dashboard({ history }) {
    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(16)
    const { loading, data } = useFetchDashboards()

    const { isO2 } = useO2(true)
    const {
        showRemoveDialog,
        onShowRemoveDialog,
        onRemoveDashboard,
        onCloseRemove,
    } = useRemoveDashboard()

    const [showDashboardAdd, setShowDashboardAdd] = useState(false)
    const [showDashboardLibrary, setShowDashboardLibrary] = useState(false)

    useKeyboardTrap(
        event => {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                if (showRemoveDialog) {
                    onCloseRemove()
                } else if (showDashboardAdd) {
                    setShowDashboardAdd(false)
                } else if (showDashboardLibrary) {
                    setShowDashboardLibrary(false)
                } else {
                    history.push(isO2 ? '/bin/o2' : '/')
                }
            }
        },
        [
            history,
            onCloseRemove,
            showRemoveDialog,
            showDashboardAdd,
            showDashboardLibrary,
        ]
    )

    const classes = useStyles()

    const onAdd = useCallback(() => {
        setShowDashboardAdd(true)
    }, [])

    const onCloseDashboardAdd = useCallback(() => {
        if (showDashboardAdd) {
            setShowDashboardAdd(false)
        }
    }, [showDashboardAdd])

    const handleAddNew = useCallback(() => {
        history.push(`${isO2 ? '/bin/o2' : ''}/dashboard/new`)
    }, [history])

    const handleChange = useCallback(
        (_event, page) => {
            setPage(page)
        },
        [setPage]
    )
    const handlePageSize = useCallback(
        event => {
            setPageSize(event.target.value)
        },
        [setPage]
    )

    const labelDisplay = useCallback(
        ({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`,
        []
    )

    const dashboards = useMemo(() => {
        return applyFilter(filter, data?.allDashboard || [])
    }, [filter, data])

    const dashboardsList = useMemo(() => {
        const startIndex = page * pageSize
        const endIndex = Math.min(startIndex + pageSize, dashboards.length)
        return dashboards.slice(startIndex, endIndex).map(dashboard => {
            return (
                <Grid key={dashboard.id} item md={4} lg={3} xl={3}>
                    {renderCard({
                        data: dashboard,
                        history,
                        onRemove: onShowRemoveDialog(dashboard.id),
                    })}
                </Grid>
            )
        })
    }, [dashboards, page, pageSize])

    const handleAddNewByLibrary = useCallback(() => {
        if (showDashboardLibrary) {
            setShowDashboardLibrary(false)
        } else {
            setShowDashboardLibrary(true)
        }
    }, [showDashboardLibrary])

    return (
        <NerusITLayout>
            <Actions>
                <Search onChange={setFilter} />

                <IconButton onClick={onAdd} tooltip="Inserir" />
            </Actions>

            <div className={classes.content}>
                {loading ? (
                    <Typography variant="h6">Buscando registros...</Typography>
                ) : dashboards.length ? (
                    <Grid className={classes.container} container spacing={2}>
                        {dashboardsList}
                    </Grid>
                ) : filter ? (
                    <Typography variant="h4">
                        Não encontramos nenhum registro com essa busca...
                    </Typography>
                ) : (
                    <Typography variant="h4">
                        Não encontramos nenhum registro...
                    </Typography>
                )}
            </div>

            <TablePagination
                component="div"
                count={dashboards.length}
                onPageChange={handleChange}
                page={page}
                rowsPerPageOptions={[8, 12, 16, 24, 32, 40]}
                rowsPerPage={pageSize}
                labelRowsPerPage="Registros por página:"
                nextIconButtonText="Próxima Página"
                backIconButtonText="Página Anterior"
                labelDisplayedRows={labelDisplay}
                onRowsPerPageChange={handlePageSize}
            />

            {showRemoveDialog ? (
                <RemoveRecord
                    onSubmit={onRemoveDashboard}
                    onClose={onCloseRemove}
                />
            ) : null}

            {showDashboardAdd ? (
                <Flutuante
                    title="Novo Dashboard"
                    onClose={onCloseDashboardAdd}
                    className={classes.dialogo}
                    size="small"
                >
                    <Box className={classes.formBox}>
                        <Typography
                            variant="body2"
                            className={classes.formBoxText}
                        >
                            Para criar um novo dashboard, você pode criar a
                            partir de um documento em branco ou a partir da
                            nossa biblioteca de dashboards, escolha a opção para
                            continuar
                        </Typography>
                    </Box>

                    <Grid container direction="row" justifyContent="center">
                        <Paper className={classes.paper} onClick={handleAddNew}>
                            <BlankDocumentIcon className={classes.icon} />
                            <Typography
                                variant="button"
                                gutterBottom
                                className={classes.iconText}
                            >
                                Em branco
                            </Typography>
                        </Paper>
                        <Paper
                            className={classes.paper}
                            onClick={handleAddNewByLibrary}
                        >
                            <DashboardLibraryIcon className={classes.icon} />
                            <Typography
                                variant="button"
                                gutterBottom
                                className={classes.iconText}
                            >
                                A partir de um modelo
                            </Typography>
                        </Paper>
                    </Grid>
                </Flutuante>
            ) : null}

            {showDashboardLibrary ? (
                <DashboardLibrary
                    history={history}
                    handleToggle={handleAddNewByLibrary}
                />
            ) : null}
        </NerusITLayout>
    )
}

export default Dashboard

Dashboard.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
}
