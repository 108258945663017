import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import EnhancedComponent from '../../common/EnhancedComponent'
import Body from './Estrutura/Body'
import ButtonActions from './Estrutura/ButtonActions'
import Header from './Estrutura/Header'
import IconActions from './Estrutura/IconActions'
import Legend from './Estrutura/Legend'
import Pagination from './Estrutura/Pagination'
import Search from './Estrutura/Search'
import Totalizador from './Estrutura/Totalizador'

export default class BaseEditor extends EnhancedComponent {
    static propTypes = {
        rows: PropTypes.array,
        columns: PropTypes.array,
        columnsType: PropTypes.array,
        buttons: PropTypes.array,
        icons: PropTypes.array,
        activeRow: PropTypes.number,
        onRowClick: PropTypes.func,
        onRowDoubleClick: PropTypes.func,
        title: PropTypes.string,
        search: PropTypes.string,
        searchLabel: PropTypes.string,
        dispatch: PropTypes.func.isRequired,
        mouseLock: PropTypes.bool,
        floating: PropTypes.bool,
        classes: PropTypes.object.isRequired,
        /* novo */
        actions: PropTypes.array,
        onDrag: PropTypes.array,
        draggable: PropTypes.bool,
        handleOrdering: PropTypes.func,
        sortIcon: PropTypes.object,
    }

    constructor(props) {
        super(props)
        const enabled = props.enabled === undefined ? true : props.enabled
        this.state = {
            ...props,
            enabled,
            icons: enabled ? props.icons : [],
            buttons: enabled ? props.buttons : [],
            buttonsSearch: [],
            buttonsSearchKeys: {},
            buttonsNormal: [],
            buttonsNormalKeys: {},
            gridMargin: 0,
            showMore: false,
            showTitle:
                props.showTitle === undefined
                    ? !props.floating
                    : props.showTitle,
            shadow: '',
            maxButtonsSlider: 7,
        }
    }

    componentWillReceiveProps(nextProps) {
        const enabled =
            nextProps.enabled === undefined ? true : nextProps.enabled

        this.componentDidUpdate()

        return this.setState({
            ...nextProps,
            buttons: enabled ? nextProps.buttons : [],
            icons: enabled ? nextProps.icons : [],
        })
    }

    componentDidMount() {
        this.setState(
            {
                isMount: true,
            },
            () => {
                this.componentDidUpdate()
            }
        )
    }

    componentDidUpdate() {
        if (!this.state.enabled) {
            return
        }

        if (
            this.getRef('buttonsHolder') &&
            this.state.gridMargin !== this.getRef('buttonsHolder').clientHeight
        ) {
            this.setState({
                gridMargin: this.getRef('buttonsHolder').clientHeight,
            })
        }
    }

    rowClick = rowNumber => {
        this.props.onRowClick && this.props.onRowClick(rowNumber, this)
    }

    rowDoubleClick = rowNumber => {
        this.props.onRowDoubleClick &&
            this.props.onRowDoubleClick(rowNumber, this)
    }

    setActiveRow = index => {
        this.setState({ activeRow: index })
    }

    toggleMore = () => {
        this.setState(state => {
            return { showMore: !state.showMore }
        })
    }

    render() {
        const {
            isViewOnly,
            isFixo,
            isEditorForm,
            isEditorFormEdit,
            columnsType,
            gridTot,
            classes,
            actions,
            onDrag,
            draggable,
            isGuia = false,
            hideActions = false,
            handleOrdering = null,
            sortIcon,
        } = this.props

        const {
            showTitle,
            showLegend,
            showMore,
            hideTitle,
            title,
            legend,
            floating,
            searchLabel,
            columns = [],
            mdQuery,
            search,
            rows,
            activeRow,
            maxButtonsSlider,
            className,
            icons,
            buttons,
            isNew,
        } = this.state

        const customClasses = {
            root: className,
            editor: '',
            header: '',
            ...(this.props.customClasses || {}),
        }

        let activeLabel = searchLabel
            ? searchLabel.replace(/:/g, '').trim()
            : null
        let activeColumnSearch =
            columns.indexOf(activeLabel) > -1
                ? columns.indexOf(activeLabel)
                : null
        return (
            <div
                key="grid"
                className={clsx({
                    [classes.root]: true,
                    [classes.fullHeight]: !isEditorForm,
                    [classes.activeLegend]: showLegend,
                    [customClasses.root]: customClasses.root,
                })}
            >
                {showLegend ? (
                    <Legend iconRef={this.getRef('icons')} legend={legend} />
                ) : null}

                {!hideTitle && (showTitle || (isNew && searchLabel)) ? (
                    <div
                        key="grid-header"
                        className={clsx({
                            [classes.header]: true,
                            [classes.headerFlt]: floating,
                        })}
                    >
                        <h1 key="grid-title" className={classes.title}>
                            {showTitle && title
                                ? typeof title === 'string'
                                    ? title.toLowerCase()
                                    : title
                                : null}
                        </h1>
                        {searchLabel !== null ? (
                            <Search
                                mdQuery={mdQuery}
                                placeholder={searchLabel}
                                value={search}
                                className={clsx({
                                    [classes.searchFieldFlt]: floating,
                                })}
                            />
                        ) : null}
                    </div>
                ) : null}
                <div
                    key="gridtable"
                    className={clsx(classes.editor, {
                        [classes.bordered]: isFixo,
                        [customClasses.editor]: customClasses.editor,
                    })}
                >
                    <div
                        key="grid-table"
                        className={clsx(classes.content, {
                            [classes.contentFlt]:
                                (floating || isEditorForm) && !isGuia,
                            [customClasses.content]: customClasses.content,
                        })}
                    >
                        <table
                            key="table-element"
                            className={clsx(classes.table, {
                                [customClasses.table]: customClasses.table,
                            })}
                        >
                            <Header
                                activeColumnSearch={activeColumnSearch}
                                handleOrdering={handleOrdering}
                                sortIcon={sortIcon}
                                actions={actions}
                                types={columnsType}
                                floating={floating}
                                classes={classes}
                                draggable={draggable}
                                data={columns}
                            />
                            <Body
                                actions={actions}
                                data={rows}
                                legend={legend}
                                classes={classes}
                                onDrag={onDrag}
                                draggable={draggable}
                                types={columnsType}
                                isViewOnly={isViewOnly}
                                onClick={this.rowClick}
                                onDoubleClick={this.rowDoubleClick}
                                onFocus={this.setActiveRow}
                                mouseLock={this.props.mouseLock}
                                activeRow={mdQuery ? null : activeRow}
                                activeColumnSearch={
                                    mdQuery ? activeColumnSearch : null
                                }
                            />

                            {gridTot ? (
                                <Totalizador
                                    activeColumnSearch={activeColumnSearch}
                                    data={gridTot}
                                    types={columnsType}
                                />
                            ) : null}
                        </table>
                    </div>

                    {!hideActions ? (
                        <div
                            key="grid-actions"
                            ref={this.createRef('buttonsHolder')}
                            className={clsx(classes.actions, {
                                [classes.emptyActionButtonsEditorFormEdit]: isEditorFormEdit,
                                [customClasses.actions]: customClasses.actions,
                            })}
                        >
                            {showMore ? <Pagination classes={classes} /> : null}
                            <ButtonActions
                                data={buttons}
                                maxButtonsSlider={maxButtonsSlider}
                                showMore={showMore}
                                classes={classes}
                                toggleShowMore={this.toggleMore}
                            />
                            <IconActions
                                innerRef={this.createRef('icons')}
                                isNew={isNew}
                                data={icons}
                                classes={classes}
                                showMore={showMore}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }
}
