import LinearProgress from '@material-ui/core/LinearProgress'
import PropTypes from 'prop-types'
import React from 'react'

import EnhancedComponent from '../../common/EnhancedComponent'

export default class DialogoProgressao extends EnhancedComponent {
    static propTypes = {
        data: PropTypes.object,
    }

    render() {
        const { data } = this.props

        if (!data) {
            return null
        }

        return (
            <div className="progress-small">
                {data.msg1 ? <p className="big">{data.msg1}</p> : null}
                {data.msg2 ? <p>{data.msg2}</p> : null}
                <LinearProgress mode="indeterminate" />
            </div>
        )
    }
}
