const daysOfWeek = [
    { id: 0, name: 'Domingo' },
    { id: 1, name: 'Segunda' },
    { id: 2, name: 'Terça' },
    { id: 3, name: 'Quarta' },
    { id: 4, name: 'Quinta' },
    { id: 5, name: 'Sexta' },
    { id: 6, name: 'Sábado' },
]
const monthsOfYear = [
    { id: 0, name: 'Janeiro' },
    { id: 1, name: 'Fevereiro' },
    { id: 2, name: 'Março' },
    { id: 3, name: 'Abril' },
    { id: 4, name: 'Maio' },
    { id: 5, name: 'Junho' },
    { id: 6, name: 'Julho' },
    { id: 7, name: 'Agosto' },
    { id: 8, name: 'Setembro' },
    { id: 9, name: 'Outubro' },
    { id: 10, name: 'Novembro' },
    { id: 11, name: 'Dezembro' },
]
const daysOfMonth = [
    { id: 0, name: '1' },
    { id: 1, name: '2' },
    { id: 2, name: '3' },
    { id: 3, name: '4' },
    { id: 4, name: '5' },
    { id: 5, name: '6' },
    { id: 6, name: '7' },
    { id: 7, name: '8' },
    { id: 8, name: '9' },
    { id: 9, name: '10' },
    { id: 10, name: '11' },
    { id: 11, name: '12' },
    { id: 12, name: '13' },
    { id: 13, name: '14' },
    { id: 14, name: '15' },
    { id: 15, name: '16' },
    { id: 16, name: '17' },
    { id: 17, name: '18' },
    { id: 18, name: '19' },
    { id: 19, name: '20' },
    { id: 20, name: '21' },
    { id: 21, name: '22' },
    { id: 22, name: '23' },
    { id: 23, name: '24' },
    { id: 24, name: '25' },
    { id: 25, name: '26' },
    { id: 26, name: '27' },
    { id: 27, name: '28' },
    { id: 28, name: '29' },
    { id: 29, name: '30' },
    { id: 30, name: '31' },
]

const enoticeOperator = [
    { id: 0, name: 'Menor que' },
    { id: 1, name: 'Menor ou igual a' },
    { id: 2, name: 'Igual a' },
    { id: 3, name: 'Maior que' },
    { id: 4, name: 'Maior ou igual a' },
    { id: 5, name: 'Diferente de' },
]

const enoticeSendCondition = [
    { id: 0, name: 'Somente uma vez no dia quando a condição for Satisfeita' },
    { id: 1, name: 'Sempre que a condição for Satisfeita' },
]

const enoticeStatusLog = [
    'Todos',
    'Ativado',
    'Desativado',
    'Enviado',
    'Enviado e não será reenviado nesta data',
    'Enviado enotice com agendamento',
    'Erro no envio do enotice',
    'Verificador ativado para averiguação de e-notices...',
    'Enviado SMS',
    'Usuario inválido (SMS)',
    'Numero de telefone inválido ou não coberto pelo sistema',
    'Problema com operadorar do celular',
    'Créditos insuficientes',
    'Erro na configuração do SMS',
    'Problema com operadora de SMS',
    'Senha inválida (SMS)',
    'Usuário inativo (SMS)',
    'Quantidade de caracteres excedidos (SMS)',
    'Enviado sms com restrições',
    'Falha de autenticação',
    'Tempo mínimo entre requisições excedidas  (SMS)',
    'Cliente está cancelando a mensagem',
    'Erro no agendamento',
]

const newEnotice = {
    userno: '',
    title: '',
    operator: 0,
    value1: '',
    value2: '',
    sql: 0,
    send: '',
    subject: '',
    message: '',
    time: null,
    ativo: 1,
    envio: 1,
    agenda: 1,
    agendaMes: 0,
    agendaDia: 0,
    agendaDiaDoMes: 0,
    horario: '',
    cc: '',
    bcc: '',
    sender: '',
    ccSql: null,
    toSql: null,
    grupo: 0,
    attach: '',
    envioSms: 0,
    emailHtml: 1,
    folderno: -1,
    operadora: 0,
    operadoraurl: '',
    inibeEmptyEnotice: 0,
}

export {
    newEnotice,
    daysOfWeek,
    daysOfMonth,
    monthsOfYear,
    enoticeOperator,
    enoticeStatusLog,
    enoticeSendCondition,
}
