export const styles = theme => ({
    root: {},
    previewer: {
        display: 'flex',
        minHeight: 400,
        width: 400,
        margin: '0 0 8px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        position: 'relative',
    },
    canvas: {
        minHeight: 400,
        width: 400,
        objectFit: 'cover',
        border: `2px solid ${theme.palette.primary.main}`,
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        background: 'rgba(0,0,0,0.5)',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#ffffff',
        '& > *': {
            fontSize: 18,
        },
    },
    action: {
        display: 'inline-block',
        padding: '5px 10px',
        color: '#433f3f',
        fontFamily: theme.palette.families.titles,
    },
    buttonsHolder: {
        height: 'auto !important',
        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.6)',
        transform: 'scale(1.05) translateY(-20px)',
        background: '#c7c6c5',
    },
    button: {
        backgroundColor: '#c7c6c5',
        transition: 'all 0.1s ease-in-out !important',
        fontFamily: theme.palette.families.titles,
        position: 'relative',
        '& .highlight': {
            color: theme.palette.primary.dark,
        },
        '&::before': {
            left: 0,
            bottom: 0,
            width: '1px',
            content: '" "',
            height: '90%',
            display: 'block',
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
        '&:last-child::after': {
            right: 0,
            bottom: 0,
            width: '1px',
            content: '" "',
            height: '90%',
            display: 'block',
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
        '&:hover': {
            zIndex: 1,
            transform: 'scale(1.1)',
            backgroundColor: theme.palette.primary.dark,
            '& .highlight, & span, & div': {
                color: theme.palette.common.white,
            },
        },
    },
    buttonLabel: {
        fontSize: 12,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    buttonWrapper: {
        color: theme.palette.primary.dark,
        '& svg': {
            width: 50,
            height: 50,
            marginBottom: 10,
        },
    },
    actions: {
        textAlign: 'right',
        '& button:last-child': {
            marginRight: 0,
        },
    },
    overlay: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        opacity: 0.6,
        height: 400,
        width: 400,
    },
})
