import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(
    theme => ({
        root: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1,
            padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
            background:
                theme.overrides.MuiCssBaseline['@global'].body.backgroundColor,

            display: 'flex',
            flexDirection: 'column',
        },
        adjustTop: {
            top: -1 * theme.spacing(2),
        },
    }),
    { name: 'BaseFixa' }
)

export default function BaseFixa({ children, className, adjustTop }) {
    const classes = useStyles()
    return (
        <div
            className={clsx({
                [classes.root]: true,
                // Usado no Index.js para ajustar alinhamento do topo
                [classes.adjustTop]: adjustTop,
                className,
            })}
        >
            {React.cloneElement(children, { isFixo: true })}
        </div>
    )
}

BaseFixa.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    adjustTop: PropTypes.bool,
}
