import withStyles from '@material-ui/core/styles/withStyles'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import { styles } from '@nerus/styles/components/hint'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import Picture from '../../../NerusIT/Picture'
import { getActiveElementIndex, getComponentById } from '../../Eac/EacReducer'

class Foto extends EnhancedComponent {
    static propTypes = {
        data: PropTypes.object,
        classes: PropTypes.object,
    }

    static baseOpts = {
        skip: true,
    }

    static mapStateToProps(state, ownProps) {
        return {
            flt: ownProps.flt !== undefined ? ownProps.flt : true,
            data: getComponentById(ownProps.id, state).payload,
            activeElementIndex: getActiveElementIndex(state),
        }
    }

    state = {}

    render() {
        const { data, activeElementIndex, flt, isGuia = false } = this.props

        /*
            Dentro de "data" vem a prop 
            "type"="Foto" quando acionado a aba do Enriquecimento de Produtos
            "type"="0" na tabela mtgpicture
            porém para o Enriquecimento, essa prop esta quebrando o código, será feito uma tratativa
            mas a priori a melhor solução seria ajustar isso ao lado do C
        */

        const { type } = data.type === 'Foto' ? {} : data

        return (
            <Picture
                flt={flt}
                isGuia={isGuia}
                {...data}
                type={type}
                showDefault={false}
                activeElementIndex={activeElementIndex}
            />
        )
    }
}

export default connect(Foto.mapStateToProps)(withStyles(styles)(Foto))
