export const styles = theme => ({
    root: {
        display: 'flex',
        width: '470px',
        padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
    logoContainer: {
        display: 'flex',
        paddingRight: theme.spacing(4),
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        maxWidth: '100%',
    },
})
