export default function wsDisconnect(event, props, callback) {
    // Fecha a conexão de uma sessão cujo binario é pvNFCE caso ela receba in_use false
    if (
        props.props?.match?.params?.binario === 'pvNFCE' &&
        event.newValue &&
        event.oldValue
    ) {
        try {
            const oldValue = JSON.parse(event.oldValue)
            const newValue = JSON.parse(event.oldValue)

            if (
                oldValue.bin === 'nerus_pvNFCE' &&
                oldValue.session_id === newValue.session_id &&
                oldValue.startedAt === newValue.startedAt &&
                !oldValue.in_use &&
                !newValue.in_use
            ) {
                props.props.ws.close()

                callback ? callback() : null
            }
        } catch (e) {
            // error
        }
    }

    return null
}
