import MenuItem from '@material-ui/core/MenuItem'
import keycodes, { navigationKeys } from '@nerus/framework/common/Keycodes'
import StyledSelect from '@nerus/framework/styled/Select'
import clsx from 'clsx'
import React from 'react'

import { sendBuffer } from '../../../../../Eac/EacActions'
import AbstractTextField from '../AbstractTextField'
import SelectInput from './SelectInput'

const debug = require('debug')('nerus:popupmenu')

/**
 * Componente PopupMenu
 */
export class TextFieldPopupMenu extends AbstractTextField {
    constructor(props) {
        super(props)
        this.state = {
            strListValue: props.value || '',
            strListSize: props.options?.data?.strList?.length || 0,
        }
    }

    handleOnChange = event => {
        const { x, y, ws } = this.props

        if (event.keyCode !== keycodes.ESCAPE_KEY) {
            event.stopPropagation()
            event.nativeEvent?.stopImmediatePropagation &&
                event.nativeEvent.stopImmediatePropagation()
        }

        if (
            (event.keyCode === keycodes.ENTER_KEY &&
                this.state.strListValue !== '' &&
                this.props.options.data.shtct.includes(
                    this.state.strListValue
                )) ||
            event.nativeEvent instanceof MouseEvent
        ) {
            ws.send(
                sendBuffer(
                    {
                        x,
                        y,
                        key: keycodes.ENTER_KEY,
                        value: event.target.value,
                    },
                    'sendEdit'
                )
            )
        }
    }

    handleChange = event => {
        this.setState({
            strListTarget: event.target,
            strListValue: event.target.value,
        })
    }

    handleKeyDown = event => {
        const { x, y, ws } = this.props
        debug('keydown', event)

        // Alteração referente ao ticket 92132
        if (event.keyCode === keycodes.UP_ARROW_KEY) {
            // event.stopPropagation() -> ticket 98413
        } else if (event.keyCode !== keycodes.DOWN_ARROW_KEY) {
            if (
                navigationKeys.indexOf(event.keyCode) > -1 &&
                this.state.strListValue !== '' &&
                this.props.options.data.shtct.includes(this.state.value)
            ) {
                event.stopPropagation()

                event.nativeEvent?.stopImmediatePropagation &&
                    event.nativeEvent.stopImmediatePropagation()

                // Alteração para o ticket 95174 && Revert:94218
                if (
                    event.keyCode == keycodes.ENTER_KEY &&
                    this.props.options.data.shtct.length <= 10
                ) {
                    ws.send(
                        sendBuffer(
                            {
                                x: x,
                                y: y,
                                key: event.keyCode,
                                value: this.state.value,
                            },
                            'sendEdit'
                        )
                    )
                } else {
                    /*
                        Ticket 95776
                        Definido a regra que se for enviado ESC, a prop "value" nao dever ser passada no buffer
                    */
                    const buffer =
                        event.keyCode == keycodes.ESCAPE_KEY
                            ? { x: x, y: y, key: event.keyCode }
                            : {
                                  x: x,
                                  y: y,
                                  key: event.keyCode,
                                  value: this.state.strListValue,
                              }

                    ws.send(sendBuffer(buffer, 'sendEdit'))
                }
            }
            // Alteração referente ao ticket 92132
            else if (
                this.state.strListValue !== '' &&
                this.props.options.data.shtct.includes(
                    this.state.strListValue
                ) &&
                this.state.strListSize <= 10
            ) {
                event.stopPropagation()
                event.nativeEvent?.stopImmediatePropagation &&
                    event.nativeEvent.stopImmediatePropagation()

                ws.send(
                    sendBuffer(
                        {
                            x,
                            y,
                            key: keycodes.ENTER_KEY,
                            value: event.key,
                        },
                        'sendEdit'
                    )
                )
            }
        }
    }

    render() {
        const {
            index,
            options,
            enabled,
            lbl,
            value,
            desc,
            orientation = 'horizontal',
            inputWidth,
            labelWidth,
            componentId,
        } = this.props

        let data = false
        if (options) {
            data = options.data
        }

        let child = []

        if (data && data.strList) {
            child = data.strList.map((object, i) => {
                const parts = object.split('. ')
                const shortcut = parts.shift()
                const value = data.shtct[i].trim()
                return (
                    <MenuItem
                        key={object + '-' + i}
                        value={value}
                        style={{ paddingTop: 0 }}
                    >
                        {shortcut && parts?.length ? (
                            // TODO: Criar componente de highlight
                            <span
                                className={clsx('highlight', {
                                    disabled: !enabled,
                                })}
                                // TODO: Criar styles via withStyles
                                style={{
                                    minHeight: 'unset',
                                    lineHeight: 'inherit',
                                    marginTop: -4,
                                    marginRight: 8,
                                }}
                            >
                                {data.shtct[i]}.{' '}
                            </span>
                        ) : null}

                        {parts.join('. ')}
                    </MenuItem>
                )
            })

            // TODO: Tem que validar isso, mas, preferencialmente o campo aparecer
            if (this.state.strListSize > 10) {
                child.unshift(
                    <MenuItem key={`selectInput`} value={''} button={false}>
                        <SelectInput
                            inputRef={this.createRef('input')}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            value={this.state.strListValue}
                            enabled={enabled}
                            removeSpaces
                        />
                    </MenuItem>
                )
            }
        }

        return (
            <StyledSelect
                InputLabelProps={{
                    style: { minWidth: labelWidth },
                    disabled: !enabled,
                }}
                InputProps={{
                    style: {
                        width: inputWidth,
                        minWidth: 160,
                    },
                }}
                label={lbl}
                open={!!enabled}
                disabled={!enabled}
                value={value.trim()}
                id={'field-' + index}
                tabIndex={index}
                orientation={orientation}
                componentId={componentId}
                onChange={this.handleOnChange}
                onKeyDownRoot={this.handleKeyDown}
                desc={desc}
                dispatchToWs={true}
            >
                {child}
            </StyledSelect>
        )
    }
}

export default TextFieldPopupMenu
