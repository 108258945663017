import * as PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import EnhancedComponent from '../../../common/EnhancedComponent'
import IconButton from './IconButton'

export class IconActions extends EnhancedComponent {
    constructor(props) {
        super(props)
        this.state = {}
    }

    sortIcons(data) {
        const icons = []
        let indexPosition = 6
        data.forEach(function(btn) {
            switch (btn.text) {
                case '(I)nserir': {
                    icons[0] = btn
                    break
                }
                case '(L)istar': {
                    icons[1] = btn
                    break
                }
                case '(M)odificar': {
                    icons[2] = btn
                    break
                }
                case '(O)rdenar': {
                    icons[3] = btn
                    break
                }
                case '(R)etirar': {
                    icons[4] = btn
                    break
                }
                case '(P)osicionar': {
                    icons[5] = btn
                    break
                }
                default: {
                    icons[indexPosition] = btn
                    indexPosition++
                }
            }
        })
        return icons
    }

    render() {
        const { data, isNew, innerRef, classes } = this.props
        if (!data || !data.length) {
            return null
        }

        const icons = this.sortIcons(data)
        return (
            <div className={classes.iconsRoot} ref={innerRef}>
                {icons.map((object, i) => {
                    if (isNew && object.txt === 'o(U)tros') {
                        return null
                    }

                    return (
                        <IconButton
                            key={'icon-button-' + i}
                            size={'small'}
                            className={classes.iconButton}
                            tooltip={object.text}
                            onClick={object.onClick}
                        />
                    )
                })}
            </div>
        )
    }
}

IconActions.propTypes = {
    isNew: PropTypes.bool,
    data: PropTypes.array,
    classes: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
        PropTypes.node,
    ]).isRequired,
}

export default connect()(IconActions)
