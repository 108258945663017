import { applyMiddleware, compose, createStore } from 'redux'
import promise from 'redux-promise'
import thunk from 'redux-thunk'

import rootReducer from './reducers'
import PromiseContainer from './util/PromiseContainer'

export function configureStore(initialState = {}, middlewares = []) {
    // Middleware and store enhancers
    const enhancers = [applyMiddleware(promise), applyMiddleware(thunk)]

    middlewares.forEach(middleware => {
        enhancers.push(applyMiddleware(middleware))
    })

    if (
        process.env.NODE_ENV === 'development' &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION__
    ) {
        // Enable DevTools only when rendering on client and during development.
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
    }

    const store = createStore(rootReducer, initialState, compose(...enhancers))
    PromiseContainer.setStore(store)

    if (module.hot) {
        module.hot.accept('./reducers.js', () => {
            const nextRootReducer = require('./reducers').default
            store.replaceReducer(nextRootReducer)
            PromiseContainer.setStore(store)
        })
    }

    return store
}

export default {
    configureStore,
}
