import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function PaymentIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 512.000000 512.000000">
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="inherit"
                stroke="none"
            >
                <path d="M2987 5104 c-446 -69 -808 -415 -904 -864 -24 -116 -24 -324 0 -440 82 -381 356 -694 715 -816 454 -155 947 -7 1234 371 334 440 293 1050 -97 1440 -251 251 -597 364 -948 309z m328 -199 c340 -60 624 -321 716 -660 32 -115 32 -335 0 -450 -67 -249 -247 -468 -476 -580 -142 -70 -223 -88 -395 -88 -172 0 -253 18 -395 88 -230 112 -408 329 -476 580 -18 65 -22 107 -22 225 0 172 18 253 88 395 176 359 566 558 960 490z" />
                <path d="M3124 4711 c-45 -19 -64 -59 -64 -135 l-1 -71 -51 -30 c-167 -96 -196 -327 -58 -465 50 -50 103 -75 190 -89 81 -13 115 -42 115 -100 0 -70 -71 -117 -138 -92 -15 6 -47 29 -72 52 -60 56 -109 63 -153 23 -30 -28 -36 -44 -33 -92 2 -33 83 -115 146 -149 l55 -28 0 -74 c0 -70 1 -74 35 -108 31 -31 40 -35 72 -30 20 4 48 18 62 32 22 22 26 36 30 103 l3 77 51 30 c166 98 194 328 57 465 -51 51 -103 75 -190 89 -53 9 -70 16 -94 42 -23 26 -27 38 -22 67 14 92 102 114 195 49 65 -45 99 -47 142 -9 26 23 32 36 32 71 0 51 -38 95 -119 138 l-52 27 -4 78 c-3 67 -7 81 -28 103 -28 28 -74 39 -106 26z" />
                <path d="M4613 2876 c-113 -37 -177 -98 -264 -251 -76 -134 -119 -194 -195 -269 -58 -57 -162 -132 -218 -157 -26 -12 -29 -11 -50 16 -50 70 -93 107 -164 143 l-76 37 -580 5 -581 5 -140 67 c-222 105 -290 122 -485 122 -156 0 -236 -18 -375 -83 -242 -113 -508 -350 -675 -601 -24 -36 -45 -67 -46 -69 -2 -2 -12 3 -21 12 -32 28 -73 39 -107 27 -33 -11 -604 -575 -626 -619 -29 -57 -31 -55 583 -668 394 -394 591 -584 610 -589 15 -3 40 -1 55 5 16 7 164 148 330 314 323 324 321 321 288 385 -8 15 -35 47 -60 72 l-46 44 28 29 c37 40 115 89 187 118 l60 24 795 6 c851 6 821 5 966 58 259 97 777 517 1001 811 142 187 266 413 300 543 45 176 -46 361 -215 440 -55 26 -81 32 -152 34 -53 2 -101 -2 -127 -11z m190 -200 c72 -30 125 -123 113 -199 -16 -95 -160 -351 -293 -517 -187 -235 -613 -586 -838 -692 -139 -64 -94 -61 -965 -68 -887 -7 -830 -2 -989 -81 -46 -22 -111 -65 -146 -94 l-62 -53 -358 358 -358 358 34 57 c52 87 187 251 276 336 150 143 290 234 438 285 68 24 97 28 195 28 154 1 219 -14 390 -94 80 -37 161 -74 180 -81 27 -10 165 -15 617 -19 l581 -5 43 -30 c72 -51 102 -137 79 -222 -17 -58 -71 -114 -126 -131 -30 -9 -180 -12 -563 -12 -566 0 -564 0 -589 -55 -15 -33 -15 -57 0 -90 25 -55 22 -55 592 -55 351 0 543 4 578 11 159 34 286 169 313 332 5 32 12 40 56 63 225 114 410 298 524 524 39 77 64 108 110 136 46 27 117 32 168 10z m-3641 -1523 l488 -488 -212 -212 -213 -213 -492 492 -493 493 207 207 c114 115 212 208 218 208 5 0 229 -219 497 -487z" />
                <path d="M984 960 c-51 -21 -77 -85 -53 -133 16 -32 149 -166 181 -183 78 -41 165 26 144 111 -9 34 -147 179 -190 199 -38 18 -51 19 -82 6z" />
            </g>
        </SvgIcon>
    )
}
