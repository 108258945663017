import makeStyles from '@material-ui/core/styles/makeStyles'
import { styles } from '@nerus/styles/components/formulario/label'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { preprocessMessageOutput } from '../common/Formatter'

export const useStyles = makeStyles(styles)

const Label = ({ text, className, noShortcut, noDots, style }) => {
    const classes = useStyles()
    const appliedClasses = {
        [classes.root]: true,
    }

    if (className) {
        appliedClasses[className] = true
    }

    let label = preprocessMessageOutput(text, noShortcut)
    if (noDots) {
        label = label.replace(/\./g, '')
    }

    return (
        <span
            style={style}
            className={clsx(appliedClasses)}
            dangerouslySetInnerHTML={{
                __html: label,
            }}
        />
    )
}

Label.propTypes = {
    style: PropTypes.object,
    text: PropTypes.string,
    className: PropTypes.string,
    noShortcut: PropTypes.bool,
    noDots: PropTypes.bool,
}

export default Label
