import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function ExcelIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0,19.991086)">
                <path d="m 265,752 q 252,47 264,47 18,1 24,-13 6,-14 6,-57 v -55 h 210 q 157,0 184.5,-2 27.5,-2 35.5,-13 7,-11 9,-67.5 2,-56.5 2,-257.5 0,-310 -7,-327 l -7,-18 H 773 560 l -2,-57 q -2,-57 -14,-64 -12,-8 -266,40 Q 26,-46 12,-38 4,-34 2,21.5 0,77 0,331 q 0,364 8,368 9,6 257,53 z M 497,331 q 0,79 -0.5,137 -0.5,58 -1,102.5 -0.5,44.5 -1,73 -0.5,28.5 -1.5,46.5 -1,18 -2,25.5 -1,7.5 -3,6.5 -3,0 -213,-39 L 70,643 V 330 q 0,-313 4,-313 3,0 209,-39 208,-38 210,-38 4,0 4,391 z m 433,-3 V 604 H 745 559 v -28 -28 h 55 q 43,0 57,-6 14,-6 14,-25 0,-19 -14,-25.5 -14,-6.5 -57,-6.5 h -55 v -28 -28 h 52 q 43,0 58.5,-8 15.5,-8 15.5,-29 0,-20 -14,-26.5 -14,-6.5 -57,-6.5 h -55 v -28 -28 h 55 q 45,0 58,-6 13,-6 13,-28 0,-22 -13,-28.5 -13,-6.5 -58,-6.5 h -55 v -28 -28 h 55 q 43,0 57,-6.5 14,-6.5 14,-26.5 0,-21 -15.5,-29 Q 654,108 611,108 H 559 V 80 52 H 745 930 Z M 337,458 q 39,49 56.5,59 17.5,10 35.5,-8 23,-23 -5,-57 -3,-4 -52,-66 l -36,-46 52,-60 q 53,-62 53,-74 0,-14 -15,-25 -15,-11 -29,-8 -17,5 -61,56 l -44,53 -42,-53 q -35,-44 -48,-52.5 -13,-8.5 -31,-0.5 -22,11 -16,33.5 6,22.5 44,70.5 l 44,56 -44,51 q -41,46 -44.5,69.5 -3.5,23.5 26.5,32.5 11,3 22,-4.5 11,-7.5 40,-39.5 l 46,-50 z m 412.5,81.5 q 8.5,8.5 59.5,8.5 37,0 45.5,-2.5 Q 863,543 869,530 879,507 867,497.5 855,488 812,487 q -50,-3 -61,6 -9,7 -9.5,22.5 -0.5,15.5 8,24 z M 753,417 q 12,13 51.5,13 39.5,0 56.5,-14 10,-8 12,-14 2,-6 -2,-19 -6,-14 -14,-17.5 -8,-3.5 -45,-4.5 -50,-2 -61,6 -10,8 -9.5,23 0.5,15 11.5,27 z m -1,-125 q 12,11 56,11 35,0 50.5,-8 15.5,-8 15.5,-26.5 0,-18.5 -15.5,-26.5 -15.5,-8 -51,-8 -35.5,0 -51,8 -15.5,8 -15.5,27 0,13 11,23 z m -2,-123 q 9,9 61,7 38,-1 46,-4 8,-3 14,-18 4,-13 2,-19 -2,-6 -12,-14 Q 846,108 809.5,107.5 773,107 757,119 q -13,9 -15,24.5 -2,15.5 8,25.5 z" />
            </g>
        </SvgIcon>
    )
}
