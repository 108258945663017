import { default as MuiIconButton } from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ActionPlus from '@material-ui/icons/Add'
import ActionAdd from '@material-ui/icons/AddCircleOutline'
import ActionDelete from '@material-ui/icons/Delete'
import ActionEdit from '@material-ui/icons/Edit'
import ActionFastForward from '@material-ui/icons/FastForward'
import ActionFastRewind from '@material-ui/icons/FastRewind'
import ActionOthers from '@material-ui/icons/Forward'
import ActionLegend from '@material-ui/icons/HelpOutlined'
import ActionPhoto from '@material-ui/icons/InsertPhoto'
import ActionPlay from '@material-ui/icons/PlayArrow'
import ActionList from '@material-ui/icons/Print'
import ActionMinus from '@material-ui/icons/Remove'
import ActionOrder from '@material-ui/icons/Reorder'
import ActionSkipNext from '@material-ui/icons/SkipNext'
import ActionSkipPrevious from '@material-ui/icons/SkipPrevious'
import ActionView from '@material-ui/icons/Visibility'
import ActionAccess from '@material-ui/icons/VpnKey'
import ActionDefault from '@material-ui/icons/Warning'
import ActionPosition from '@nerus/framework/icons/Glass'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { formatTooltip } from '../../../Business/Keyboard'
import { toggleLegend } from '../../../Eac/EacActions'

class IconButton extends Component {
    legendIcon = null

    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        tooltip: PropTypes.string.isRequired,
        iconColor: PropTypes.string,
        onClick: PropTypes.func,
        className: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            styles: {
                smallIcon: {
                    width: 24,
                    height: 24,
                },
                small: {
                    width: 48,
                    height: 48,
                    padding: 8,
                },
            },
        }
    }

    icon = (str, dispatch) => {
        switch (str) {
            case '(I)nserir': {
                return ActionAdd
            }
            case '(L)istar': {
                return ActionList
            }
            case '(M)odificar': {
                return ActionEdit
            }
            case '(P)osicionar': {
                return ActionPosition
            }
            case '(O)rdenar': {
                return ActionOrder
            }
            case '(R)etirar': {
                return ActionDelete
            }
            case '(l)egenda': {
                return {
                    icon: ActionLegend,
                    action: () => {
                        dispatch(toggleLegend())
                    },
                }
            }
            case '(C)TRL+F-foto': {
                return ActionPhoto
            }
            case '(A)cessos': {
                return ActionAccess
            }
            case '(V)isualizar': {
                return ActionView
            }
            case 'o(U)tros': {
                return ActionOthers
            }
            case 'Esconder todos': {
                return ActionMinus
            }
            case 'Mostrar todos': {
                return ActionPlus
            }
            case 'Primeiro': {
                return ActionSkipPrevious
            }
            case 'PgUp': {
                return ActionFastRewind
            }
            case 'Anterior': {
                return { icon: ActionPlay, className: 'invert' }
            }
            case 'Próximo': {
                return ActionPlay
            }
            case 'PgDn': {
                return ActionFastForward
            }
            case 'Último': {
                return ActionSkipNext
            }
        }

        return ActionDefault
    }

    onOpen = () => {
        this.setState({ open: true })
    }

    onClose = () => {
        this.setState({ open: false })
    }

    render() {
        const { tooltip, dispatch, onClick, className } = this.props
        const { open } = this.state
        let Ico = this.icon(tooltip, dispatch)
        const props = {
            onClick: () => {
                onClick && onClick()
                setTimeout(() => {
                    window.document.body.focus()
                }, 10)
            },
            style: this.state.styles.small,
            className: '',
        }

        // const iconColor = iconColor
        //     ? iconColor
        //     : '#433f3f'

        const IconProps = {
            // color: iconColor,
        }

        if (typeof Ico == 'object' && Ico.icon) {
            props.className = Ico.className
            if (Ico.action) {
                props.onClick = Ico.action
            }
            if (Ico.ref) {
                props.ref = Ico.ref
            }
            Ico = Ico.icon
        }

        if (className) {
            props.className += ' ' + className
        }

        return (
            <Tooltip
                onOpen={this.onOpen}
                onClose={this.onClose}
                open={open}
                title={formatTooltip(tooltip)}
                placement={'top'}
            >
                <span
                    onMouseEnter={this.onOpen}
                    onMouseLeave={this.onClose}
                    onClick={this.onClose}
                >
                    <MuiIconButton {...props}>
                        <Ico {...IconProps} />
                    </MuiIconButton>
                </span>
            </Tooltip>
        )
    }
}

export default connect()(IconButton)
