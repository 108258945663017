import PropTypes from 'prop-types'
import React from 'react'

export default function ProductIcon({ width = 40, height = 40 }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 -2 42 42"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
        >
            <g transform="matrix(0.04,0,0,-0.04,0,30)">
                <path d="m 799,786 q 20,12 51.5,13.5 31.5,1.5 56.5,-9.5 24,-10 54,-43.5 30,-33.5 35,-55.5 6,-24 0,-57 -3,-26 -35.5,-62.5 Q 928,535 757,364 L 522,128 420,99 Q 336,75 304,71.5 272,68 267,82 q -4,13 26,122 l 30,111 227,229 q 228,229 249,242 z m 111,-75 q -28,27 -51,30 -23,3 -47,-17 l -20,-18 55,-55 54,-55 20,19 q 23,23 20.5,45.5 Q 939,683 910,711 Z M 809,608 Q 755,661 751.5,661 748,661 571,484 L 394,307 449,253 504,198 682,377 861,555 Z M 84,700 q 13,4 150,2 l 135,-1 2,-16 Q 375,658 353,652.5 331,647 229,647 H 102 L 79,626 55,604 53,268 q -2,-302 6,-348 6,-34 27,-46 3,-2 8,-5 15,-7 79,-8.5 64,-1.5 309,-0.5 l 365,2 19,20 21,20 4,133 q 2,86 5,110.5 3,24.5 11,29.5 13,8 26,0 8,-5 10,-30 2,-25 2,-119 0,-106 -2,-127.5 -2,-21.5 -16,-38.5 -19,-25 -44,-40 -19,-12 -70,-14.5 -51,-2.5 -329,-4.5 h -2 l -375,-2 -38,19 q -38,19 -53,47 -12,21 -14,70 -2,49 -2,318 0,235 2,301 2,66 10,82 9,19 31.5,39 22.5,20 40.5,25 z" />
            </g>
        </svg>
    )
}

ProductIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}
