import { withStyles } from '@material-ui/core/styles'
import { colorSchemes } from '@nivo/colors'
import * as echarts from 'echarts'
import * as PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import brMap from '../../../../public/br-map.json'

const styles = {
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
}

function Map({ classes, data, ...rest }) {
    const ref = useRef()
    const instance = useRef()

    let useData = []
    let max = 0
    let min = 0
    if (data.length) {
        useData = data[0].data
        max = useData[0]?.value || 0
        min = useData[0]?.value || 0

        useData.forEach(st => {
            if (st.value < min) {
                min = st.value
            }

            if (st.value > max) {
                max = st.value
            }
        })
    }

    useEffect(() => {
        const onResize = () => {
            instance.current && instance.current.resize()
        }
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    })

    useEffect(() => {
        instance.current && instance.current.resize()
    }, [instance.current, rest])

    useEffect(() => {
        if (ref.current) {
            if (!instance.current) {
                instance.current = echarts.init(ref.current)
            }

            echarts.registerMap('BR', { geoJSON: brMap })
            const option = {
                color: colorSchemes.NerusWeb,
                title: {
                    show: false,
                },
                tooltip: {
                    trigger: 'item',
                    showDelay: 0,
                    transitionDuration: 0.2,
                    formatter: function(params) {
                        const formatterWithPrec = new Intl.NumberFormat(
                            'pt-BR',
                            {
                                minimumFractionDigits: 2,
                                useGrouping: true,
                            }
                        )

                        const valueStr = formatterWithPrec.format(
                            parseFloat(params.data?.value) // params.value is different from params.data.value - look into why
                        )

                        return (
                            params.seriesName +
                            '<br/>' +
                            params.name +
                            ': R$ ' +
                            (isNaN(params.data?.value) ? '0,00' : valueStr)
                        )
                    },
                },
                visualMap: {
                    left: 'right',
                    min,
                    max,
                    inRange: {
                        color: [
                            '#313695',
                            '#4575b4',
                            '#74add1',
                            '#abd9e9',
                            '#e0f3f8',
                            '#ffffbf',
                            '#fee090',
                            '#fdae61',
                            '#f46d43',
                            '#d73027',
                            '#a50026',
                        ],
                    },
                    text: [],
                    calculable: true,
                },
                toolbox: {
                    show: false,
                },
                series: [
                    {
                        name: 'Estados',
                        type: 'map',
                        roam: true,
                        map: 'BR',
                        emphasis: {
                            label: {
                                show: true,
                            },
                        },
                        data: useData,
                    },
                ],
            }
            instance.current.setOption(option)
        }

        return () => {}
    }, [ref, data])

    return <div ref={ref} className={classes.root} />
}

Map.propTypes = {
    classes: PropTypes.any,
    data: PropTypes.array,
    axes: PropTypes.any,
}

export default withStyles(styles)(Map)
