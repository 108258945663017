import * as PropTypes from 'prop-types'
import { Component } from 'react'

import { setLock } from '../../../App/AppActions'

class LockComponent extends Component {
    constructor(props) {
        super(props)
    }
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    }
    componentDidMount() {
        this.props.dispatch(setLock(true))
    }

    componentWillUnmount() {
        this.props.dispatch(setLock(false))
    }
}

export default LockComponent
