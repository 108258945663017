import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function CalculatorIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0,19.2)">
                <path d="M 125,800 H 875 V -200 H 125 Z m 145,-922 q 28,0 48,19.5 20,19.5 20,47.5 0,28 -20,48 -20,20 -48,20 -28,0 -48,-20 -20,-20 -20,-48 0,-28 20,-47.5 20,-19.5 48,-19.5 z m 0,191 q 28,0 48,20 20,20 20,48 0,28 -20,47.5 -20,19.5 -48,19.5 -28,0 -48,-19.5 -20,-19.5 -20,-47.5 0,-28 20,-48 20,-20 48,-20 z m 0,192 q 28,0 48,19.5 20,19.5 20,47.5 0,28 -20,47.5 Q 298,395 270,395 242,395 222,375.5 202,356 202,328 202,300 222,280.5 242,261 270,261 Z m 230,-383 q 28,0 47.5,19.5 Q 567,-83 567,-55 567,-27 547.5,-7 528,13 500,13 q -28,0 -48,-20 -20,-20 -20,-48 0,-28 20,-47.5 20,-19.5 48,-19.5 z m 0,191 q 28,0 47.5,20 19.5,20 19.5,48 0,28 -19.5,47.5 Q 528,204 500,204 472,204 452,184.5 432,165 432,137 q 0,-28 20,-48 20,-20 48,-20 z m 0,192 q 28,0 47.5,19.5 Q 567,300 567,328 567,356 547.5,375.5 528,395 500,395 472,395 452,375.5 432,356 432,328 432,300 452,280.5 472,261 500,261 Z m 229,-383 q 28,0 48,19.5 20,19.5 20,47.5 0,28 -20,48 -20,20 -48,20 -28,0 -48,-20 -20,-20 -20,-48 0,-28 20,-47.5 20,-19.5 48,-19.5 z m 0,191 q 28,0 48,20 20,20 20,48 0,28 -20,47.5 -20,19.5 -48,19.5 -28,0 -48,-19.5 -20,-19.5 -20,-47.5 0,-28 20,-48 20,-20 48,-20 z m 0,192 q 28,0 48,19.5 20,19.5 20,47.5 0,28 -20,47.5 Q 757,395 729,395 701,395 681,375.5 661,356 661,328 661,300 681,280.5 701,261 729,261 Z M 836,483 V 762 H 163 V 483 Z" />
            </g>
        </SvgIcon>
    )
}
