import { alpha } from '@material-ui/core/styles'

export const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        textAlign: 'right',
        lineHeight: '14px',
        height: '36px',
        '& svg': {
            maxWidth: 20,
            maxHeight: 21,
        },
        '& button': {
            maxWidth: 36,
            maxHeight: 36,
        },
    },
    userInfoBlock: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: theme.sizing.topBar,
        backgroundColor: '#0088A8',
        marginLeft: 16,
        textAlign: 'left',
    },
    userInfoAvatar: {
        border: `2px solid #007B98`,
        color: `#616161`,
        margin: `14px 30px`,
        width: 48,
        height: 48,
        borderRadius: `100px 100px`,
    },
    expandButton: {
        color: theme.palette.common.white,
    },
    userInfo: {},
    userName: {
        margin: 0,
        fontSize: '16px',
        cursor: 'default',
        fontWeight: 'normal',
        [theme.breakpoints.down('lg')]: {
            fontSize: 14,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        },
    },
    userNameO2: {
        fontWeight: 700,
        fontSize: 15,
        lineHeight: `12px`,
        letterSpacing: `0.14px`,
        marginBottom: 4,
    },
    userCompanyO2: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: `12px`,
        letterSpacing: `0.14px`,
    },
    expandButtonO2: {
        margin: `0 32px 0 8px`,
    },
    detail: {
        fontSize: '15px',
        display: 'block',
        cursor: 'default',
        [theme.breakpoints.down('lg')]: {
            fontSize: 13,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 11,
        },
    },
    divider: {
        margin: `0 ${theme.spacing(1)}px 0 ${theme.spacing(3)}px`,
    },
    version: {
        fontSize: '10px',
        cursor: 'default',
        textAlign: 'right',
        color: alpha(theme.palette.common.white, 0.65),
        [theme.breakpoints.down('lg')]: {
            fontSize: 9,
        },
    },
    iconWhite: {
        color: 'rgba(255,255,255,1)',
    },
})
