import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/styles/makeStyles'
import { formatTooltip } from '@nerus/framework/common/Formatter'
import keycodes from '@nerus/framework/common/Keycodes'
import BaseFlutuante from '@nerus/framework/components/Base/Flutuante'
import IconButton from '@nerus/framework/components/Editor/Estrutura/IconButton'
import EditorFormEdit from '@nerus/framework/components/EditorFormEdit'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import Button from '@nerus/framework/styled/Button'
import { noop } from '@nerus/framework/util'
import PropTypes from 'prop-types'
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import GenericMessage from '../Dialogs/GenericMessage'
import Actions from './index'

const columns = [
    { label: 'Código', size: 8, key: 'no' },
    { label: 'Nome', size: 60, key: 'name' },
]

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: 8,
    },
    actions: {
        padding: 8,
        marginBottom: 0,
    },
    leftActions: {
        display: 'flex',
        flex: 1,
    },
    removeIcon: {
        '& svg': {
            fontSize: '16px !important',
            color: theme.palette.primary.main,
        },
    },
    search: {
        margin: 8,
    },
}))

export default function BaseSelector({
    title,
    label,
    data,
    currentData,
    onSave,
    asButton,
}) {
    const classes = useStyles()
    const [showResults, setShowResults] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [showModal, setModalMessage] = useState(false)
    const [selected, setSelected] = useState([])
    const [show, setShow] = useState(false)
    const searchRef = useRef()
    const statesRef = useRef()

    const useCurrentData = useMemo(() => {
        return (typeof currentData === 'string'
            ? currentData.split(',')
            : currentData || []
        )
            .map(item => {
                if (typeof item === 'object') return item
                const search = data.filter(r => r.no === parseInt(item.trim()))
                if (search.length > 0) return search[0]
                return null
            })
            .filter(Boolean)
    }, [currentData, data])

    if (!statesRef.current) {
        statesRef.current = {}
    }

    useEffect(() => {
        if (useCurrentData && selected !== useCurrentData) {
            setSelected(useCurrentData)
        }
    }, [setSelected, useCurrentData])

    useEffect(() => {
        if (searchRef.current && !(showResults || showModal)) {
            searchRef.current.focus()
        }
    }, [showResults, showModal])

    const onAdd = useCallback(
        (value, cancel, func) => {
            if (cancel) {
                setShow(show => !show)
                return
            }

            if (func && func === keycodes.F2_KEY) {
                setShowSearch(true)
                setTimeout(() => {
                    if (searchRef.current) {
                        searchRef.current.value = value
                    }
                }, 100)
                return
            }

            if (!value) {
                setModalMessage(
                    asButton ? `Informe um registro.` : `Informe um ${label}.`
                )
                return
            }

            const groups = data || []
            const group = groups.filter(g => g.no === parseInt(value))
            if (group?.length) {
                const selectedGroups = selected
                const selectedGroup = selectedGroups.filter(
                    g => g.no === parseInt(value)
                )
                if (selectedGroup && selectedGroup.length) {
                    setModalMessage(
                        asButton
                            ? `O registro informado já foi inserido.`
                            : `O ${label} informado já foi inserido.`
                    )
                    setSelected(selected => [...selected])
                } else {
                    setSelected(selected => [...selected, ...group])
                    return true
                }
            } else {
                setModalMessage(
                    asButton
                        ? `O registro informado não existe.`
                        : `O ${label} informado não existe.`
                )
                setSelected(selected => [...selected])
            }
        },
        [setSelected, data, selected]
    )

    const onDoubleClickAdd = useCallback(
        row => {
            // TODO: E se não for no?
            if (onAdd(row.no)) {
                setShowResults(false)
                setShowSearch(false)
            }
        },
        [onAdd]
    )

    const onRemove = useCallback(
        value => {
            const selected = (data || []).filter(g => g.no === value.no)
            if (selected?.length) {
                setSelected(selected =>
                    selected
                        .map(row => (row.no === value.no ? null : row))
                        .filter(Boolean)
                )
            }
        },
        [setSelected, data]
    )

    const onClearModal = useCallback(() => {
        setModalMessage(false)
    }, [setModalMessage])

    const handleOnSave = useCallback(() => {
        onSave && onSave(selected)
        setShow(show => !show)
    }, [onSave, selected])

    const onShow = useCallback(() => {
        setShow(show => !show)
        setSelected(useCurrentData)
    }, [setShow, setSelected, useCurrentData])

    const onShowSearch = useCallback(() => {
        setShowSearch(show => !show)
    }, [setShowSearch])

    const onShowResults = useCallback(() => {
        setShowResults(false)
    }, [setShowResults])

    const onKeyDownSearch = useCallback(
        event => {
            if (
                event.keyCode === keycodes.ENTER_KEY &&
                statesRef.current.show &&
                statesRef.current.search
            ) {
                event.preventDefault()
                event.stopPropagation()

                setShowResults(event.target.value)
            }

            if (
                event.keyCode === keycodes.ESCAPE_KEY &&
                (statesRef.current.search ||
                    statesRef.current.results !== false ||
                    statesRef.current.show ||
                    statesRef.current.modal)
            ) {
                event.preventDefault()
                event.stopPropagation()
                event.stopImmediatePropagation?.()

                if (statesRef.current.modal) {
                    setModalMessage(null)
                } else if (statesRef.current.results !== false) {
                    setShowResults(false)
                } else if (statesRef.current.search) {
                    setShowSearch(false)
                } else if (statesRef.current.show) {
                    setShow(false)
                }
            }
        },
        [statesRef, setShow, setShowSearch, setShowResults]
    )

    useKeyboardTrap(onKeyDownSearch, { capture: true })

    const onSearchConfirm = useCallback(() => {
        onKeyDownSearch({
            keyCode: keycodes.ENTER_KEY,
            target: { value: searchRef.current?.value },
            preventDefault: noop,
            stopPropagation: noop,
        })
    }, [searchRef, onKeyDownSearch])

    // const onCloseResults = useCallback(
    //     event => {
    //         event.preventDefault()
    //         setShowResults(false)
    //     },
    //     [setShowResults]
    // )

    const searched = useMemo(() => {
        if (!data) {
            return []
        }

        return showResults !== false
            ? data.filter(
                  group =>
                      group.name
                          .toLowerCase()
                          .indexOf(showResults.toLowerCase()) > -1
              )
            : data
    }, [data, showResults])

    statesRef.current.show = show
    statesRef.current.results = showResults
    statesRef.current.search = showSearch
    statesRef.current.modal = showModal

    return (
        <Fragment>
            {!asButton ? (
                <IconButton
                    size="small"
                    className={classes.icon}
                    tooltip={title}
                    onClick={onShow}
                />
            ) : (
                <Grid container>
                    <Grid item xs={11}>
                        <TextField
                            disabled
                            value={
                                selected?.map
                                    ? selected.map(r => r.no).join(', ')
                                    : useCurrentData
                            }
                            label={`${label}`}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            size="small"
                            className={classes.icon}
                            tooltip={`Selecione`}
                            onClick={onShow}
                        />
                    </Grid>
                </Grid>
            )}

            {show ? (
                <BaseFlutuante
                    title={asButton ? label : `Selecione os ${label}s`}
                    size="medium"
                    onClose={onShow}
                    nospace
                >
                    <EditorFormEdit
                        active={!showModal && !showSearch && !showResults}
                        columns={columns}
                        rows={selected}
                        onInsert={onAdd}
                        onRemove={onRemove}
                    />

                    <Actions className={classes.actions}>
                        <div className={classes.leftActions}>
                            <Button onClick={onShowSearch}>
                                {formatTooltip('F2-Pesquisar por nome')}
                            </Button>
                        </div>

                        <Button
                            dialog
                            color="danger"
                            lbl="Cancelar"
                            onClick={onShow}
                        />
                        <Button
                            dialog
                            primary
                            lbl="Confirmar"
                            onClick={handleOnSave}
                        />
                    </Actions>
                </BaseFlutuante>
            ) : null}

            {showSearch ? (
                <BaseFlutuante
                    title="Pesquisa por descrição"
                    onClose={onShowSearch}
                    nospace
                >
                    <TextField
                        onKeyDown={onKeyDownSearch}
                        className={classes.search}
                        disabled={showResults || showModal}
                        autoFocus
                        inputRef={searchRef}
                    />

                    <Actions className={classes.actions}>
                        <Button
                            onClick={onShowSearch}
                            color="danger"
                            lbl="Cancelar"
                            dialog
                        />
                        <Button
                            onClick={onSearchConfirm}
                            lbl="Confirmar"
                            dialog
                            primary
                        />
                    </Actions>
                </BaseFlutuante>
            ) : null}

            {showResults !== false ? (
                <BaseFlutuante
                    title={`Duplo click com o mouse para selecionar o ${
                        asButton ? `registro` : label
                    }`}
                    size="medium"
                    onClose={onShowResults}
                    nospace
                >
                    <EditorFormEdit
                        active={!showModal}
                        columns={columns}
                        rows={searched}
                        onDoubleClick={onDoubleClickAdd}
                    />

                    <Actions className={classes.actions}>
                        <Button
                            dialog
                            color="danger"
                            lbl="Cancelar"
                            onClick={onShowResults}
                        />
                    </Actions>
                </BaseFlutuante>
            ) : null}

            {showModal ? (
                <GenericMessage message={showModal} onClose={onClearModal} />
            ) : null}
        </Fragment>
    )
}

BaseSelector.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    data: PropTypes.any,
    currentData: PropTypes.any,
    asButton: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
}
