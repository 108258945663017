import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function FileViewIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M21.4,5.1c0.3,0.2,0.5,0.6,0.6,1c0.2,0.4,0.3,0.8,0.3,1.2v15.4c0,0.4-0.1,0.7-0.4,0.9C21.7,23.9,21.4,24,21,24H3
	c-0.4,0-0.7-0.1-0.9-0.4s-0.4-0.6-0.4-0.9V1.3c0-0.4,0.1-0.7,0.4-0.9S2.6,0,3,0h12c0.4,0,0.8,0.1,1.2,0.3s0.8,0.4,1,0.6L21.4,5.1z
	 M15.4,1.8v5h5c-0.1-0.3-0.2-0.4-0.3-0.5L16,2.1C15.9,2,15.7,1.9,15.4,1.8z M20.6,22.3V8.6H15c-0.4,0-0.7-0.1-0.9-0.4
	c-0.3-0.3-0.4-0.6-0.4-0.9V1.7H3.4v20.6H20.6z M6.9,10.7c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h9.4c0.1,0,0.2,0,0.3,0.1
	s0.1,0.2,0.1,0.3v0.9c0,0.1,0,0.2-0.1,0.3C16.9,12,16.8,12,16.7,12H7.3c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3V10.7z
	 M16.7,13.7c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3V15c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1H7.3
	c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-0.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1H16.7z M16.7,17.1
	c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.9c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1H7.3c-0.1,0-0.2,0-0.3-0.1
	c-0.1-0.1-0.1-0.2-0.1-0.3v-0.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1H16.7z"
            />
        </SvgIcon>
    )
}
