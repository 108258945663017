/**
 * Build HTML template for email and/or SQL query results.
 *
 * @param initialTpl Email template without [sql] substitution (enotice.message).
 * @param queryResult SQL query result.
 *
 * @return String containing the HTML template.
 */

export function buildEmailTemplate(initialTpl, queryResult = []) {
    const sqlHtml = buildSQLQueryTemplate(queryResult)

    const finalTpl = initialTpl.replace(/\[sql]/gim, sqlHtml)

    return finalTpl
}

export function buildSQLQueryTemplate(queryResult) {
    let sqlHtml =
        '<table width="100%"><tbody><tr bgcolor="#000099" style="color: #FFFFFF;">'

    let bg = '#EEEEEF'
    const headers = queryResult.length ? Object.keys(queryResult[0]) : []

    for (const header of headers) {
        sqlHtml += `<td align="left"><b>${header}</b></td>`
    }

    sqlHtml += '</tr>'

    for (const row of queryResult) {
        const values = Object.values(row)

        sqlHtml += `<tr bgcolor=${bg}>`

        for (const value of values) {
            sqlHtml += `<td>${value}</td>`
        }

        sqlHtml += `</tr>`

        bg = bg == '#EEEEEF' ? '#FFFFEE' : '#EEEEEF'
    }

    sqlHtml += '</tbody></table>'

    return sqlHtml
}
