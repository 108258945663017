import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function INerusIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.02402883,0,0,-0.02402883,-0.014415,19.617139)">
                <path d="m 46,662 q 18,8 454,8 436,0 454,-8 15,-8 29,-27 10,-13 12,-55 2,-42 4,-249 2,-287 -6,-309 -7,-25 -26,-41 L 946,-36 H 500 54 L 33,-19 Q 14,-3 7,22 -1,44 1,331 q 2,207 4,249 2,42 12,55 14,19 29,27 z m 879,-37 q 1,2 -51,3 -52,1 -150.5,2 Q 625,631 500,631 375,631 276.5,630 178,629 126,628 74,627 76,625 109,593 291.5,436.5 474,280 482,276 q 14,-6 29,-3 8,3 186,154 178,151 228,198 z M 208,455 Q 64,581 51,590 45,595 43,582 39,559 39,321 39,174 41,104.5 43,35 46,27 55,10 74,3 94,-4 500,-3.5 906,-3 927,4 q 20,7 27,24 6,16 5,294 L 958,599 803,463 Q 647,328 647,324 q 0,-5 88,-89 89,-83 87,-97 -3,-22 -26,-8 -23,13 -91,78 l -94,87 -35,-28 q -42,-34 -76,-34 -34,0 -77,34 l -34,28 -94,-87 q -90,-85 -107,-84 -8,0 -10,14 -2,14 87,97 88,84 88,89 0,4 -145,131 z" />
            </g>
        </SvgIcon>
    )
}
