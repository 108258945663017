import PropTypes from 'prop-types'
import React, { Component } from 'react'

import AutoScalingText from './AutoScalingText'

class Display extends Component {
    static propTypes = {
        value: PropTypes.any,
        classes: PropTypes.object,
    }

    render() {
        const { value, classes, ...rest } = this.props

        const language = navigator.language || 'en-US'
        let formattedValue = parseFloat(value).toLocaleString(language, {
            useGrouping: true,
            maximumFractionDigits: 6,
        })

        const match = value.match(/\.\d*?(0*)$/)

        if (match)
            formattedValue += /[1-9]/.test(match[0]) ? match[1] : match[0]

        return (
            <div {...rest} className={classes.display}>
                <AutoScalingText className={classes.scalingText}>
                    {formattedValue}
                </AutoScalingText>
            </div>
        )
    }
}

export default Display
