import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import Button from '@nerus/framework/styled/Button'
import PropTypes from 'prop-types'
import React from 'react'

import BaseFlutuante from '../../../NerusWeb/Components/Flutuante/BaseFlutuante'

const useStyles = makeStyles(theme => ({
    actionsRight: {
        textAlign: 'right',
        padding: theme.spacing(1),
        flex: 1,
    },
    formBox: {},
    fieldBox: {
        width: '50%',
    },
}))

export default function GenericMessage({ title, message, onClose }) {
    const classes = useStyles()
    return (
        <BaseFlutuante title={title || `NerusWeb`} handleClose={onClose}>
            <Box className={classes.formBox}>
                <Typography variant="body1">{message}</Typography>
            </Box>
            <Box className={classes.actionsRight}>
                <Button
                    onClick={onClose}
                    variant="rounded"
                    lbl="Fechar"
                    primary
                    dialog
                    data-testid="CloseGenericMessage"
                />
            </Box>
        </BaseFlutuante>
    )
}

GenericMessage.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}
