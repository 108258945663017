import { alpha } from '@material-ui/core/styles'

export const styles = theme => ({
    root: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: 0,
        minHeight: '100%',
        maxHeight: '100%',
    },
    bold: {
        fontWeight: 'bold',
    },
    uploadDownload: {
        fontSize: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    fileIsTooBig: {
        background: 'rgba(0,0,0,0.5)',
        color: theme.palette.common.white,
        fontSize: 12,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        zIndex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    rootSelected: {
        bottom: 42,
        left: theme.spacing(1),
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    editor: {
        height: 'inherit',
        flex: 1,
        maxHeight: '100%',
        minHeight: '100%',
        fontFamily:
            '"monospace", "Monaco", "Menlo", "Andale Mono", "Lucida Sans Typewriter", "Droid Sans Mono", "Deja Vu Sans Mono", "Courier New", "Courier"',
        padding: '0 8px',
        whiteSpace: 'pre',
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: alpha('#000000', 0.15),
            '&:hover': {
                backgroundColor: alpha('#000000', 0.15),
            },
        },
        '&::-webkit-scrollbar': {
            width: 8,
            height: 8,
            cursor: 'pointer',
        },
    },
    editorRoot: {
        flex: 1,
        maxHeight: 'calc(100% - 34px)',
    },
    editorBackground: {
        height: '100%',
        backgroundColor: theme.palette.common.white + ' !important',
        border: '1px solid ' + theme.palette.primary.dark,
        padding: 0,
        overflow: 'auto',
        '&:hover': {
            backgroundColor: theme.palette.common.white + ' !important',
        },
    },
    actions: {
        padding: '8px 0 0 0',
        textAlign: 'right',
        '& button:last-child': {
            marginRight: 0,
        },
    },
    progress: {
        padding: 8,
        background: theme.palette.common.white,
        borderRadius: 4,
        boxShadow: '0px 0px 3px -2px #000',
        width: 550,
    },
    progressIndicator: {
        padding: 4,
        textAlign: 'center',
        color: theme.palette.common.black,
        fontSize: 16,
    },
    progressBar: {},
    inputHidden: {
        display: 'none',
        visibility: 'hidden',
    },
    upload: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: `1px solid #ddd`,
        padding: '20px 16px 10px',
        cursor: 'pointer',
        '& *': {
            cursor: 'pointer',
        },
    },
})
