export const styles = () => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1500,
        position: 'fixed',
        backgroundColor: 'rgba(35, 34, 34, 0.65)',
    },
    draggable: {
        padding: '0 !important',
    },
    content: {
        padding: 10,
    },
})
