import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useCallback, useState } from 'react'

import { ENOTICELOG_QUERY } from './useFetchEnoticesLog'

export const REMOVE_ENOTICELOG_MUTATION = gql`
    mutation removeEnoticeLog($logno: [Int!]) {
        removeEnoticeLog(logno: $logno) {
            error
            message
        }
    }
`

export default function useRemoveEnoticeLog(onRemove, options) {
    const [logno, setShowRemoveDialog] = useState(null)

    const [removeEnoticeLog] = useMutation(REMOVE_ENOTICELOG_MUTATION, {
        displayName: 'removeEnoticeLog',
        refetchQueries: () => [{ query: ENOTICELOG_QUERY }],
        ...options,
    })

    const onCloseRemove = useCallback(() => {
        setShowRemoveDialog(null)
    }, [setShowRemoveDialog])

    const onShowRemoveDialog = useCallback(
        id => {
            setShowRemoveDialog(id)
        },
        [setShowRemoveDialog]
    )

    const onRemoveEnoticeLog = useCallback(async () => {
        try {
            const data = await removeEnoticeLog({
                variables: {
                    logno,
                },
            })

            onRemove && onRemove(data)

            onCloseRemove()
        } catch (error) {
            // TODO: Exibir dialogo de erro
            console.error(error)
        }
    }, [logno, removeEnoticeLog, onRemove, onCloseRemove])

    return {
        showRemoveDialog: logno,
        onShowRemoveDialog,
        onRemoveEnoticeLog,
        onCloseRemove,
    }
}
