import makeStyles from '@material-ui/core/styles/makeStyles'
import { styles } from '@nerus/styles/components/topBar/title'
import * as PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import LogoNerusO2 from '../../../../../public/img/logo-neruso2.svg'
import LogoWhite from '../../../../../public/img/logo-white.png'
import { getNerusInfo } from '../../Eac/EacReducer'
import IconActions from './IconActions'

const useStyles = makeStyles(styles)

export default function Title({ isO2 }) {
    const classes = useStyles()
    const nerusInfo = useSelector(getNerusInfo)
    return (
        <div className={!isO2 ? classes.root : classes.rootO2}>
            <img
                src={isO2 ? LogoNerusO2 : LogoWhite}
                alt="Nerus Web"
                height="48"
                className={classes.logo}
            />

            {isO2 ? <h3>{nerusInfo?.cpy}</h3> : null}

            {!isO2 ? <IconActions classes={classes} /> : null}
        </div>
    )
}

Title.propTypes = {
    isO2: PropTypes.bool,
    title: PropTypes.string,
    classes: PropTypes.object,
}
