import Drawer from '@material-ui/core/Drawer'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import * as Icons from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Close from '@material-ui/icons/Close'
import makeStyles from '@material-ui/styles/makeStyles'
import config from '@nerus/config'
import keycodes from '@nerus/framework/common/Keycodes'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import Button from '@nerus/framework/styled/Button'
import Toggle from '@nerus/framework/styled/Toggle'
import { mergeDeep } from 'apollo-utilities'
import cloneDeep from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import uuid from 'uuid'

import * as constants from '../Components/constants'
import { defaultState, defaultValuesByType } from '../Components/constants'
import GenericConfirmation from '../Dialogs/GenericConfirmation'
import GenericMessage from '../Dialogs/GenericMessage'
import Axes from './Axes'
import GraphConfig from './GraphConfig'
import NerusColorPicker from './NerusColorPicker'
import NerusInputUpload from './NerusInputUpload'
import Queries from './Queries'

const useStyles = makeStyles(theme => ({
    root: {
        padding: 35,
        height: 100,
        background: theme.palette.bodyColor,
        border: '2px dashed #000',
        boxShadow: 'none',
        textAlign: 'center',
        cursor: 'pointer',
        fontSize: '18px',
    },
    icon: {
        marginBottom: -5,
        marginRight: 5,
    },
    icons: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'flex',
    },
    actionsRight: {
        textAlign: 'right',
        padding: theme.spacing(2),
        boxShadow:
            '0 -1px 3px 0 rgb(0 0 0 / 6%), 0 -1px 1px 0 rgb(0 0 0 / 3%), 0 -2px 1px -1px rgb(0 0 0 / 2%)',
    },
    formBox: {
        minWidth: '95% !important',
    },
    fieldBox: {
        width: '50%',
    },
    previewerBox: {
        padding: 10,
    },
    titleInput: {
        fontSize: 12,
    },
    colorInput: {
        fontSize: 12,
        marginTop: -12,
    },
    drawer: {
        width: 400,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 400,
        border: 'none',
        boxShadow:
            '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    headingBox: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },
    headingConfig: {
        padding: 16,
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        alignItems: 'center',
        display: 'flex',
        '& span': {
            flex: 1,
            alignItems: 'center',
            display: 'flex',
        },
        '& svg:first-child': {
            marginRight: 8,
        },
    },
    headingClose: {
        cursor: 'pointer',
        float: 'right',
    },
    queriesButton: {
        width: '100%',
    },
    headingSummary: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    detailsTipo: {
        paddingTop: 15,
    },
    flex: {
        display: 'flex',
    },
    detailsBiblioteca: {
        padding: 0,
    },
    boxTipo: {
        width: '100%',
        height: 100,
        textAlign: 'center',
        background: '#F5F5F5',
        borderRadius: 10,
        fontSize: 12,
        cursor: 'pointer',
        transform: 'scale(1)',
        transition: '300ms ease-in-out all',
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow:
                '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        },
    },
    boxContent: {
        padding: theme.spacing(1),
        margin: 0,
        width: '100%',
    },
    blockTitle: {
        background: 'rgba(178, 30, 40, 0.1)',
        color: '#b21e28',
        fontSize: 12,
        padding: theme.spacing(1, 2),
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        width: 'calc(100% + ' + theme.spacing(2) * 2 + 'px)',
    },
    boxIcon: {
        margin: 25,
        marginBottom: 5,
        display: 'inline-block',
        position: 'relative',
    },
    iconInbox: {
        fontSize: 30,
    },
    queryTitle: {
        color: '#7b151c',
        paddingBottom: 20,
        marginBottom: 10,
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
}))

const { getIconByType, getTypeTitle } = constants

const typesAvailable = [
    constants.TYPE_SEPARATOR,
    constants.TYPE_IMAGE,
    constants.TYPE_TITLE_BLOCK,
    constants.TYPE_GRAPH_BAR_AND_LINE,
    constants.TYPE_GRAPH_BAR,
    constants.TYPE_GRAPH_BAR_HOR,
    constants.TYPE_GRAPH_BAR_GROUPED,
    constants.TYPE_GRAPH_BAR_STACKED,
    constants.TYPE_GRAPH_LINE,
    constants.TYPE_GRAPH_MULTLINHAS,
    constants.TYPE_GRAPH_PIZZA,
    constants.TYPE_GRID,
    constants.TYPE_GRAPH_GAUGE,
    constants.TYPE_GRAPH_MAP,
]

if (config.painelO2?.allowEdit) {
    typesAvailable.push(constants.TYPE_PICKINGPACKING)
    typesAvailable.push(constants.TYPE_FULFILLMENT)
}

export default function ComponentHandler({
    onOpen,
    dashboard,
    editing,
    onClose,
    onSave,
}) {
    const inputMinRef = useRef(null)
    const hasChanges = useRef(false)
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)
    const [showQueries, setShowQueries] = useState(false)
    const [showGraphConfig, setShowGraphConfig] = useState(false)
    const [showModalInterceptions, setShowModalInterceptions] = useState('')
    const [showModal, setShowModal] = useState('')
    const [showAxes, setShowAxes] = useState(false)
    const [current, setCurrent] = useState(null)
    const classes = useStyles()

    useEffect(() => {
        if (editing) {
            setCurrent({
                ...editing,
                height: editing.height > 12 ? 12 : editing.height,
            })
        }
    }, [editing, setCurrent])

    const handleOnSave = useCallback(() => {
        if (
            Number(current?.interceptions?.min) >
            Number(current?.interceptions?.max)
        ) {
            setShowModalInterceptions(
                'O valor do campo mínimo não pode ser maior que o valor máximo'
            )

            return
        }

        let isNew = true
        const components = (dashboard.components || []).map(component => {
            if (component.id === current.id) {
                isNew = false
                return { ...current }
            }

            return { ...component }
        })

        if (isNew) {
            current.id = uuid()
            components.push(current)
        }

        onSave && onSave(components)
        setCurrent(null)
    }, [onSave, setCurrent, current, dashboard.components])

    const onAdd = useCallback(() => {
        onOpen && onOpen()
        setCurrent(cloneDeep(defaultState))
    }, [setCurrent, onOpen])

    const doCancel = useCallback(() => {
        setCurrent(null)
        setShowCancelConfirmation(false)
        hasChanges.current = false
        onClose && onClose()
    }, [setCurrent, hasChanges, onClose])

    const onKeyDown = useCallback(
        event => {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                if (current) {
                    event.stopImmediatePropagation()
                    if (showGraphConfig) {
                        setShowGraphConfig(false)
                    } else if (showModal) {
                        setShowModal('')
                    } else if (showAxes) {
                        setShowAxes(false)
                    } else if (showQueries) {
                        setShowQueries(false)
                    } else if (hasChanges.current) {
                        setShowCancelConfirmation(true)
                    } else {
                        doCancel()
                    }
                }
            }
        },
        [
            setShowCancelConfirmation,
            setShowGraphConfig,
            showGraphConfig,
            setShowQueries,
            setShowModal,
            showQueries,
            setShowAxes,
            hasChanges,
            showModal,
            showAxes,
            doCancel,
            current,
        ]
    )

    useKeyboardTrap(onKeyDown, { capture: true })

    const hideCancel = useCallback(() => {
        setShowCancelConfirmation(false)
    }, [setShowCancelConfirmation])

    const handleOnClose = useCallback(() => {
        if (hasChanges.current) {
            setShowCancelConfirmation(true)
        } else {
            doCancel()
        }
    }, [setShowCancelConfirmation, doCancel, hasChanges])

    const title = useMemo(() => {
        if (current?.type) {
            const Icon = getIconByType(current.type)
            const subtitle = getTypeTitle(current.type)

            return (
                <Fragment>
                    <Icon /> {subtitle}
                </Fragment>
            )
        }

        return 'Escolha o tipo do componente'
    }, [current])

    const handleTypeSelection = useCallback(
        type => () => {
            const defaultValues = defaultValuesByType[type]
            hasChanges.current = true
            setCurrent(current => ({
                ...current,
                ...defaultValues,
                type,
            }))
        },
        [setCurrent, hasChanges]
    )

    const hideModal = useCallback(() => {
        setShowModal('')
    }, [setShowModal])

    const hideModalInterceptions = useCallback(() => {
        setShowModalInterceptions('')
        inputMinRef.current.focus()
    }, [setShowModalInterceptions])

    const handleUpdate = useCallback(
        event => {
            let { name, value } = event.target

            if (name === 'width' && parseInt(value) > 12) {
                setShowModal('A largura não pode passar de 12.')
                return
            }

            if (name === 'height' && parseInt(value) > 12) {
                setShowModal('A altura não pode passar de 12.')
                return
            }

            if (name === 'invisible') {
                value = event.target.checked || false
            }

            hasChanges.current = true
            setCurrent(current => {
                if (
                    [
                        'sizeIcon',
                        'sizeValue',
                        'alignIcon',
                        'alignValue',
                        'spaceIcon',
                        'spaceValue',
                    ].indexOf(name) > -1
                ) {
                    return {
                        ...current,
                        colors: {
                            ...(current.colors || {}),
                            [name]: value,
                        },
                    }
                }

                return {
                    ...current,
                    [name]: value,
                }
            })
        },
        [setCurrent, hasChanges, setShowModal]
    )

    const handleColor = useCallback(
        (name, color) => {
            setCurrent(current => {
                const colors = { ...current.colors }
                colors[name] = color
                return {
                    ...current,
                    colors,
                }
            })
            hasChanges.current = true
        },
        [setCurrent, hasChanges]
    )

    const handleInterceptions = useCallback(
        event => {
            const { name, value } = event.target

            setCurrent(current => {
                const interceptions = { ...(current.interceptions || {}) }

                interceptions[name] = value

                return {
                    ...current,
                    interceptions,
                }
            })
            hasChanges.current = true
        },
        [setCurrent, hasChanges]
    )

    const handleOpenGraphConfig = useCallback(() => {
        setShowGraphConfig(true)
    }, [setShowGraphConfig])

    const handleOpenAxes = useCallback(() => {
        setShowAxes(true)
    }, [setShowAxes])

    const handleOpenQueries = useCallback(() => {
        setShowQueries(true)
    }, [setShowQueries])

    const handleUpdateInput = useCallback(
        updateValue => {
            setCurrent(current => ({
                ...current,
                ...updateValue,
            }))
            hasChanges.current = true
        },
        [setCurrent, hasChanges]
    )

    const handleChangeFormat = useCallback(
        axis => event => {
            const value = event.target.value

            setCurrent(current => {
                const updatedAxes = {}

                axis.forEach(eixo => {
                    updatedAxes[eixo] = {
                        ...current.axes[eixo],
                        format: value,
                    }
                })

                return {
                    ...current,
                    axes: {
                        ...current.axes,
                        ...updatedAxes,
                    },
                }
            })

            hasChanges.current = true
        },
        [setCurrent, hasChanges]
    )

    const handleCloseAxes = useCallback(
        axes => {
            if (axes) {
                setCurrent(current => ({
                    ...current,
                    axes,
                }))
                hasChanges.current = true
            }
            setShowAxes(false)
        },
        [hasChanges, setShowAxes, setCurrent]
    )

    const handleCloseQuery = useCallback(
        queries => {
            if (queries) {
                setCurrent(current => ({
                    ...current,
                    queries,
                }))
                hasChanges.current = true
            }
            setShowQueries(false)
        },
        [setShowQueries, setCurrent, hasChanges]
    )

    const handleCloseGraphConfig = useCallback(
        custom => {
            if (custom) {
                setCurrent(current => mergeDeep(cloneDeep(current), { custom }))
                hasChanges.current = true
            }
            setShowGraphConfig(false)
        },
        [setShowGraphConfig, setCurrent, hasChanges]
    )

    const icons = useMemo(() => {
        return Object.keys(Icons)
            .filter(
                k =>
                    k.indexOf('Rounded') === -1 &&
                    k.indexOf('Outlined') === -1 &&
                    k.indexOf('Sharp') === -1 &&
                    k.indexOf('TwoTone') === -1
            )
            .map(icon => {
                const Icon = Icons[icon]
                return (
                    <MenuItem key={icon} value={icon}>
                        <ListItemIcon>
                            <Icon />
                        </ListItemIcon>{' '}
                        {icon}
                    </MenuItem>
                )
            })
    }, [])
    let minQueries = 1
    let maxQueries = 1
    switch (current?.type) {
        case constants.TYPE_GRAPH_BAR_AND_LINE:
            minQueries = 2
            maxQueries = 2
            break
        case constants.TYPE_GRAPH_MULTLINHAS:
            maxQueries = 5
            break
        case constants.TYPE_PICKINGPACKING:
            minQueries = 5
            maxQueries = 5
            break
        case constants.TYPE_FULFILLMENT:
            minQueries = 7
            maxQueries = 7
            break
    }

    return (
        <Fragment>
            <Paper className={classes.root} onClick={onAdd}>
                <AddCircleIcon className={classes.icon} />
                Adicionar Componente
            </Paper>

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={Boolean(current)}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.headingBox}>
                    <Typography className={classes.headingConfig}>
                        <span>{title}</span>
                        <Close
                            className={classes.headingClose}
                            onClick={handleOnClose}
                        />
                    </Typography>
                </div>
                <Grid container spacing={2} className={classes.boxContent}>
                    {!current || !current.type ? (
                        typesAvailable.map(type => {
                            const Icon = getIconByType(type)
                            const label = getTypeTitle(type)
                            return (
                                <Grid item key={type} xs={4}>
                                    <div
                                        id={type}
                                        className={classes.boxTipo}
                                        onClick={handleTypeSelection(type)}
                                    >
                                        <span className={classes.boxIcon}>
                                            <Icon
                                                className={classes.iconInbox}
                                            />
                                        </span>
                                        <div>{label}</div>
                                    </div>
                                </Grid>
                            )
                        })
                    ) : (
                        <Fragment>
                            <Grid item xs={12} className={classes.flex}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Título"
                                        name="title"
                                        value={current.title}
                                        className={classes.titleInput}
                                        onChange={handleUpdate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>

                                <NerusColorPicker
                                    name="title"
                                    value={current.colors.title}
                                    onChange={handleColor}
                                />
                            </Grid>
                            {current.type !== constants.TYPE_SEPARATOR ? (
                                <Fragment>
                                    <Grid item xs={12} className={classes.flex}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Rodapé"
                                                name="info"
                                                value={current.info}
                                                className={classes.titleInput}
                                                onChange={handleUpdate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                        <NerusColorPicker
                                            name="footer"
                                            value={current.colors.footer}
                                            onChange={handleColor}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Largura (em colunas: 1 - 12)"
                                                name="size"
                                                value={current.size}
                                                className={classes.titleInput}
                                                onChange={handleUpdate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Altura (em linhas: 1 - 12)"
                                                name="height"
                                                value={current.height}
                                                className={classes.titleInput}
                                                onChange={handleUpdate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Grid item xs={12} className={classes.flex}>
                                        <TextField
                                            label="Cor da Separação"
                                            className={classes.colorInput}
                                            disabled
                                            fullWidth
                                        />
                                        <NerusColorPicker
                                            name="separator"
                                            value={current.colors.separator}
                                            onChange={handleColor}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.flex}>
                                        <TextField
                                            label="Invisível"
                                            className={classes.colorInput}
                                            disabled
                                            fullWidth
                                        />
                                        <Toggle
                                            name="invisible"
                                            checked={current.invisible}
                                            onChange={handleUpdate}
                                        />
                                    </Grid>
                                </Fragment>
                            )}
                            {current.type === constants.TYPE_GRAPH_PIZZA ||
                            current.type === constants.TYPE_GRAPH_GAUGE ? (
                                <Grid item xs={12} className={classes.flex}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Formatação do Valor"
                                        className={classes.titleInput}
                                        value={current.axes?.y?.format || null}
                                        onChange={handleChangeFormat([
                                            'x',
                                            'y',
                                        ])}
                                        required
                                    >
                                        <MenuItem value={null}>
                                            Sem formatação
                                        </MenuItem>
                                        <MenuItem value="money">
                                            Dinheiro
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                            ) : null}
                            {current.type ===
                            constants.TYPE_GRAPH_BAR_AND_LINE ? (
                                <Grid item xs={12} className={classes.flex}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Formatação do Valor da Linha"
                                        className={classes.titleInput}
                                        value={current.axes?.y?.format || null}
                                        onChange={handleChangeFormat(['y'])}
                                        required
                                    >
                                        <MenuItem value={null}>
                                            Sem formatação
                                        </MenuItem>
                                        <MenuItem value="money">
                                            Dinheiro
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                            ) : null}
                            {current.type ===
                            constants.TYPE_GRAPH_BAR_AND_LINE ? (
                                <Grid item xs={12} className={classes.flex}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Formatação do Valor da Barra"
                                        className={classes.titleInput}
                                        value={current.axes?.x?.format || null}
                                        onChange={handleChangeFormat(['x'])}
                                        required
                                    >
                                        <MenuItem value={null}>
                                            Sem formatação
                                        </MenuItem>
                                        <MenuItem value="money">
                                            Dinheiro
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                            ) : null}
                            {current.type === constants.TYPE_TITLE_BLOCK ? (
                                <Fragment>
                                    <Grid item xs={12} className={classes.flex}>
                                        <Typography
                                            variant="h2"
                                            className={classes.blockTitle}
                                        >
                                            Bloco
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.flex}>
                                        <TextField
                                            label="Cor de Fundo"
                                            className={classes.colorInput}
                                            disabled
                                            fullWidth
                                        />
                                        <NerusColorPicker
                                            name="background"
                                            value={current.colors.background}
                                            onChange={handleColor}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.flex}>
                                        <Typography
                                            variant="h2"
                                            className={classes.blockTitle}
                                        >
                                            Valor
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.flex}>
                                        <TextField
                                            label="Cor do Valor"
                                            className={classes.colorInput}
                                            disabled
                                            fullWidth
                                        />
                                        <NerusColorPicker
                                            name="value"
                                            value={current.colors.value}
                                            onChange={handleColor}
                                            style={{
                                                alignItems: 'center',
                                            }}
                                        />
                                    </Grid>

                                    {current.type ==
                                    constants.TYPE_TITLE_BLOCK ? (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.flex}
                                            >
                                                <TextField
                                                    label="Mínimo"
                                                    name="min"
                                                    value={
                                                        current.interceptions
                                                            ?.min || ''
                                                    }
                                                    className={
                                                        classes.titleInput
                                                    }
                                                    onChange={
                                                        handleInterceptions
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputRef={inputMinRef}
                                                    fullWidth
                                                />
                                                <NerusColorPicker
                                                    name="titleMinValue"
                                                    value={
                                                        current.colors
                                                            .titleMinValue
                                                    }
                                                    onChange={handleColor}
                                                    style={{
                                                        alignItems: 'center',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.flex}
                                            >
                                                <TextField
                                                    label="Máximo"
                                                    name="max"
                                                    value={
                                                        current.interceptions
                                                            ?.max || ''
                                                    }
                                                    className={
                                                        classes.titleInput
                                                    }
                                                    onChange={
                                                        handleInterceptions
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    fullWidth
                                                />
                                                <NerusColorPicker
                                                    name="titleMaxValue"
                                                    value={
                                                        current.colors
                                                            .titleMaxValue
                                                    }
                                                    onChange={handleColor}
                                                    style={{
                                                        alignItems: 'center',
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    ) : null}
                                    <Grid item xs={6} className={classes.flex}>
                                        <TextField
                                            select
                                            fullWidth
                                            name="sizeValue"
                                            label="Tamanho do Valor"
                                            value={
                                                current.colors.sizeValue ||
                                                'normal'
                                            }
                                            onChange={handleUpdate}
                                        >
                                            <MenuItem value="small">
                                                Pequeno
                                            </MenuItem>
                                            <MenuItem value="normal">
                                                Normal
                                            </MenuItem>
                                            <MenuItem value="huge">
                                                Grande
                                            </MenuItem>
                                            <MenuItem value="extrahuge">
                                                Enorme
                                            </MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} className={classes.flex}>
                                        <TextField
                                            select
                                            fullWidth
                                            name="alignValue"
                                            label="Alinhamento do Valor"
                                            value={
                                                current.colors.alignValue ||
                                                'center'
                                            }
                                            onChange={handleUpdate}
                                        >
                                            <MenuItem value="left">
                                                Esquerda
                                            </MenuItem>
                                            <MenuItem value="center">
                                                Centralizado
                                            </MenuItem>
                                            <MenuItem value="right">
                                                Direita
                                            </MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} className={classes.flex}>
                                        <Typography
                                            variant="h2"
                                            className={classes.blockTitle}
                                        >
                                            Ícone
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.flex}>
                                        <TextField
                                            select
                                            fullWidth
                                            name="image"
                                            label="Ícone"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            SelectProps={{
                                                classes: {
                                                    select: classes.icons,
                                                },
                                                // open: false, // TODO: Usar uma outra abordagem de renderização para melhorar a performance
                                                displayEmpty: true,
                                            }}
                                            value={current.image}
                                            onChange={handleUpdate}
                                        >
                                            <MenuItem value="">
                                                Sem ícone
                                            </MenuItem>
                                            {icons}
                                        </TextField>
                                    </Grid>
                                    {current.image ? (
                                        <Fragment>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.flex}
                                            >
                                                <TextField
                                                    label="Cor do Ícone"
                                                    className={
                                                        classes.colorInput
                                                    }
                                                    disabled
                                                    fullWidth
                                                />
                                                <NerusColorPicker
                                                    name="icon"
                                                    value={current.colors.icon}
                                                    onChange={handleColor}
                                                    style={{
                                                        alignItems: 'flex-end',
                                                        top: -100,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                className={classes.flex}
                                            >
                                                <TextField
                                                    select
                                                    fullWidth
                                                    name="sizeIcon"
                                                    label="Tamanho do Ícone"
                                                    value={
                                                        current.colors
                                                            .sizeIcon ||
                                                        'normal'
                                                    }
                                                    onChange={handleUpdate}
                                                >
                                                    <MenuItem value="small">
                                                        Pequeno
                                                    </MenuItem>
                                                    <MenuItem value="normal">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="huge">
                                                        Grande
                                                    </MenuItem>
                                                    <MenuItem value="extrahuge">
                                                        Enorme
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                className={classes.flex}
                                            >
                                                <TextField
                                                    select
                                                    fullWidth
                                                    name="alignIcon"
                                                    label="Alinhamento do Ícone"
                                                    value={
                                                        current.colors
                                                            .alignIcon ||
                                                        'center'
                                                    }
                                                    onChange={handleUpdate}
                                                >
                                                    <MenuItem value="left">
                                                        Esquerda
                                                    </MenuItem>
                                                    <MenuItem value="center">
                                                        Centralizado
                                                    </MenuItem>
                                                    <MenuItem value="right">
                                                        Direita
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>
                                        </Fragment>
                                    ) : null}
                                </Fragment>
                            ) : current.type !== constants.TYPE_GRAPH_PIZZA &&
                              current.type !== constants.TYPE_SEPARATOR &&
                              current.type !== constants.TYPE_IMAGE &&
                              current.type !== constants.TYPE_GRAPH_BAR &&
                              current.type !== constants.TYPE_GRAPH_LINE &&
                              current.type !==
                                  constants.TYPE_GRAPH_BAR_GROUPED &&
                              current.type !== constants.TYPE_GRAPH_BAR_HOR &&
                              current.type !==
                                  constants.TYPE_GRAPH_BAR_STACKED &&
                              current.type !==
                                  constants.TYPE_GRAPH_MULTLINHAS &&
                              current.type !==
                                  constants.TYPE_GRAPH_BAR_AND_LINE &&
                              current.type !== constants.TYPE_GRAPH_MAP ? (
                                <Grid item xs={12} className={classes.flex}>
                                    <TextField
                                        label="Cor do Valor"
                                        className={classes.titleInput}
                                        disabled
                                        fullWidth
                                    />
                                    <NerusColorPicker
                                        name="value"
                                        value={current.colors.value}
                                        onChange={handleColor}
                                    />
                                </Grid>
                            ) : current.type !== constants.TYPE_GRAPH_PIZZA &&
                              current.type !== constants.TYPE_SEPARATOR &&
                              current.type !== constants.TYPE_IMAGE &&
                              current.type !==
                                  constants.TYPE_GRAPH_BAR_AND_LINE &&
                              current.type !==
                                  constants.TYPE_GRAPH_MULTLINHAS &&
                              current.type !== constants.TYPE_FULFILLMENT &&
                              current.type !== constants.TYPE_PICKINGPACKING &&
                              current.type !== constants.TYPE_GRAPH_MAP &&
                              current.type !== constants.TYPE_GRAPH_GAUGE ? (
                                <Grid item xs={12} className={classes.flex}>
                                    <Button
                                        default
                                        variant={'rounded'}
                                        lbl={`Eixos...`}
                                        dialog
                                        onClick={handleOpenAxes}
                                        className={classes.queriesButton}
                                    />
                                </Grid>
                            ) : null}
                            {current.type !== constants.TYPE_SEPARATOR &&
                            current.type !== constants.TYPE_IMAGE ? (
                                <Grid item xs={12}>
                                    <Button
                                        default
                                        variant={'rounded'}
                                        lbl={`SELECTs (${current.queries
                                            ?.length || 0})...`}
                                        dialog
                                        onClick={handleOpenQueries}
                                        className={classes.queriesButton}
                                    />
                                </Grid>
                            ) : null}
                            {current.type !== constants.TYPE_SEPARATOR &&
                            current.type !== constants.TYPE_IMAGE &&
                            current.type !== constants.TYPE_TITLE_BLOCK &&
                            current.type !== constants.TYPE_GRID &&
                            current.type !==
                                constants.TYPE_GRAPH_BAR_AND_LINE &&
                            current.type !== constants.TYPE_GRAPH_MULTLINHAS &&
                            current.type !== constants.TYPE_PICKINGPACKING &&
                            current.type !== constants.TYPE_FULFILLMENT &&
                            current.type !== constants.TYPE_GRAPH_MAP &&
                            current.type !== constants.TYPE_GRAPH_GAUGE ? (
                                <Grid item xs={12}>
                                    <Button
                                        default
                                        variant={'rounded'}
                                        lbl="Personalizar..."
                                        dialog
                                        onClick={handleOpenGraphConfig}
                                        className={classes.queriesButton}
                                    />
                                </Grid>
                            ) : null}
                            {current.type === constants.TYPE_IMAGE ? (
                                <Grid item xs={12}>
                                    <NerusInputUpload
                                        name="image"
                                        value={current.image}
                                        onChange={handleUpdateInput}
                                    />
                                </Grid>
                            ) : null}
                        </Fragment>
                    )}
                </Grid>

                <div className={classes.actionsRight}>
                    <Button
                        lbl="Cancelar"
                        dialog
                        color="danger"
                        onClick={handleOnClose}
                    />

                    <Button
                        lbl="Confirmar"
                        primary
                        dialog
                        onClick={handleOnSave}
                    />
                </div>
            </Drawer>

            {showQueries ? (
                <Queries
                    filters={dashboard.filters}
                    initialData={current.queries}
                    minQueries={minQueries}
                    maxQueries={maxQueries}
                    onClose={handleCloseQuery}
                    onSave={handleCloseQuery}
                />
            ) : null}

            {showGraphConfig ? (
                <GraphConfig
                    component={current}
                    initialCustom={current.custom}
                    onClose={handleCloseGraphConfig}
                    onSave={handleCloseGraphConfig}
                />
            ) : null}

            {showAxes ? (
                <Axes
                    initialData={current.axes}
                    onClose={handleCloseAxes}
                    onSave={handleCloseAxes}
                    type={current.type}
                />
            ) : null}

            {showCancelConfirmation ? (
                <GenericConfirmation
                    onSubmit={doCancel}
                    onClose={hideCancel}
                    message="Você poderá perder qualquer alteração realizada, confirma?"
                />
            ) : null}
            {showModal ? (
                <GenericMessage onClose={hideModal} message={showModal} />
            ) : null}
            {showModalInterceptions ? (
                <GenericMessage
                    onClose={hideModalInterceptions}
                    message={showModalInterceptions}
                />
            ) : null}
        </Fragment>
    )
}

ComponentHandler.propTypes = {
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    editing: PropTypes.object,
    dashboard: PropTypes.shape({
        components: PropTypes.array,
        filters: PropTypes.array,
    }),
}
