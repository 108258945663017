import { alpha, darken } from '@material-ui/core/styles'

export const styles = theme => ({
    root: {
        display: 'flex',
        width: '100%',
        '& ::-webkit-scrollbar-thumb': {
            backgroundColor: alpha('#000000', 0.15) + ' !important',
        },
        '& .formulario': {
            marginTop: 8,
            '& form': {
                display: 'flex',
            },
            '& .row': {
                display: 'flex',
            },
            '& .input-form': {
                display: 'flex',
                fontSize: 12,
                marginRight: 8,
                '& label': {
                    marginRight: 8,
                    color: 'rgb(124, 21, 28)',
                },
                '& .input-value': {
                    borderBottom: '1px dotted rgba(0,0,0,0.25)',
                },
            },
        },
    },
    list: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        margin: 0,
        padding: 0,
        display: 'flex',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        background: theme.palette.common.white,
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    listItem: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContenet: 'center',
        '& > span': {
            flexGrow: 1,
        },
        '& > button': {
            display: 'none',
            background: theme.palette.blue.main,
            color: theme.palette.common.white,
            padding: 0,
            '&:hover': {
                background: darken(theme.palette.blue.main, 0.2),
            },
            '& > span > svg': {
                width: 20,
                height: 20,
            },
        },
        '&:hover > button': {
            display: 'inline-block',
        },
    },
    listItemActive: {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.common.white,
    },
    optionsBox: {
        boxShadow: `
            0 1px 3px 0 rgba(0, 0, 0, 0.2),
            0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12)
        `,
    },
    boxTitle: {
        fontFamily: 'Frutiger, sans-serif',
        borderBottom: '1px solid ' + theme.palette.primary.dark,
        margin: 0,
        padding: '12px 8px',
        fontSize: '14px',
        fontWeight: 700,
        textAlign: 'left',
        textTransform: 'normal',
    },
    editor: {
        minWidth: 440,
        height: 440,
        '& > div > div:last-child': {
            display: 'none',
        },
        '& > div > div:first-child': {
            overflow: 'auto',
            maxHeight: 440,
        },
    },
    selectedBox: {
        display: 'flex',
        flex: '1 1 100%',
        flexDirection: 'column',
    },
    actions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& button': {
            margin: '5px 10px',
        },
    },
})
