import BaseFlutuante from '@nerus/framework/components/Base/Flutuante'
import StyledButton from '@nerus/framework/components/Button'
import PropTypes from 'prop-types'
import React from 'react'

import EnhancedComponent from '../../common/EnhancedComponent'
import { preprocessMessageOutput } from '../../common/Formatter'
import keycodes from '../../common/Keycodes'

export default class BaseDialogo extends EnhancedComponent {
    static propTypes = {
        data: PropTypes.object,
        onClose: PropTypes.func,
        msg: PropTypes.string,
        msgShow: PropTypes.string,
        title: PropTypes.string,
    }
    static defaultProps = {
        strList: [],
        optType: 0,
    }

    state = {
        activeDrags: 0,
        activeElementIndex: 0,
        wrapperClass: 'dialogContent componentDialogo',
    }

    constructor(props) {
        super(props)

        this.button0 = this.createRef('button0')
        this.button1 = this.createRef('button1')
        this.button2 = this.createRef('button2')
        this.button3 = this.createRef('button3')
        this.dialog = this.createRef('dialog')
    }

    onKeydown = event => {
        const index = `button${this.state.activeElementIndex}`
        if (event.keyCode === keycodes.ENTER_KEY) {
            event.preventDefault()
            event.stopPropagation()
            event.stopImmediatePropagation && event.stopImmediatePropagation()
            // run the active action
            if (this.getRef(index)) {
                this.getRef(index).click()
            }
        } else if (event.keyCode !== keycodes.ESCAPE_KEY) {
            // faz nada, deixa passar pro KeyboardHandler
        } else if (
            event.keyCode !== keycodes.TAB_KEY &&
            event.keyCode !== keycodes.ESCAPE_KEY
        ) {
            event.preventDefault()
            event.stopPropagation()
            event.stopImmediatePropagation && event.stopImmediatePropagation()
        }
    }

    componentDidMount() {
        const that = this
        const index = `button${that.state.activeElementIndex}`

        /**
         * utilizado para dar focus no elemento correto
         * precisamos dar o timeout por conta da forma como o browser
         * funciona senão ele remove o focus do campo
         */
        setTimeout(() => {
            if (that.getRef(index)) {
                that.getRef(index).focus()
            } else {
                // verificar como faremos focus em outro botao
            }
        }, 50)

        if (that.getRef('dialog')) {
            that.getRef('dialog').onkeydown = this.onKeydown
        }
    }

    isFocused = i => {
        return this.props.activeElementIndex === parseInt(i)
    }

    renderButtons = () => {
        const { optType, strList, onClose } = this.props

        switch (optType) {
            case 1:
            case 0: {
                return strList.map(({ onClick, label, ...object }, i) => {
                    const buttonNum = i
                    return (
                        <StyledButton
                            tabIndex={buttonNum + 1}
                            ref={this[`button${buttonNum}`]}
                            key={buttonNum}
                            onClick={onClick}
                            primary={this.isFocused(buttonNum)}
                            isFocused={this.isFocused(buttonNum)}
                            dialog
                            {...object}
                        >
                            {label}
                        </StyledButton>
                    )
                })
            }
            case -1: {
                return (
                    <StyledButton
                        tabIndex={1}
                        ref={this.button0}
                        onClick={onClose}
                        isFocused
                        primary
                        dialog
                    >
                        Fechar
                    </StyledButton>
                )
            }
        }
    }

    render() {
        const { open, title, msg, msgShow, onClose } = this.props

        const __html = preprocessMessageOutput(msg)
        const __msgShow = preprocessMessageOutput(msgShow)

        if (!open) {
            return null
        }

        return (
            <BaseFlutuante
                contentRef={this.createRef('dialog')}
                title={title}
                actions={this.renderButtons()}
                onClose={onClose}
            >
                {__msgShow ? (
                    <p>
                        <strong
                            dangerouslySetInnerHTML={{
                                __html: __msgShow,
                            }}
                        />
                    </p>
                ) : (
                    ''
                )}
                <p dangerouslySetInnerHTML={{ __html }} />
            </BaseFlutuante>
        )
    }
}
