import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function PrintIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0.024,19.2)">
                <path d="m 227,791 q 15,8 274,8 l 260,1 17,-18 q 13,-12 15.5,-29 2.5,-17 2.5,-92 V 557 h 54 q 47,0 75,-10.5 28,-10.5 43,-35.5 9,-15 11,-50.5 Q 981,425 981,287 981,161 979,118.5 977,76 970,61 958,35 932,24.5 906,14 853,14 h -57 v -90 q 0,-62 -2.5,-79 -2.5,-17 -13.5,-29 l -15,-16 H 502 q -261,0 -274,4 -18,7 -25,35.5 -7,28.5 -7,92.5 V 14 H 138 Q 92,14 67.5,22.5 43,31 28,51 21,62 19,103.5 17,145 17,286 v 218 l 25,25 q 18,17 30.5,21 12.5,4 58.5,6 l 65,3 v 95 q 0,75 6,101.5 6,26.5 25,35.5 z m 524,-139 -2,94 -251,3 -252,1 V 654 557 H 499 753 Z M 916,496 q -11,7 -83.5,9 Q 760,507 494,507 194,507 139.5,505 85,503 74,493 64,483 62,453.5 60,424 60,287 V 259 Q 60,113 68.5,88.5 77,64 129,64 h 10 57 v 114 q 0,113 10,121 8,8 297,7 l 289,-2 2,-120 2,-120 h 57 q 56,0 67,12 7,7 9,43.5 2,36.5 2,166.5 0,148 -2,175 -2,27 -13,35 z M 753,58 V 257 H 499 246 l 1,-205 2,-206 242,-2 q 242,-2 252,7 6,5 8,46.5 2,41.5 2,160.5 z M 338,181 q 6,8 34,10 28,2 123,2 147,0 156,-7 10,-5 7,-22 l -2,-18 H 500 Q 396,146 368.5,148.5 341,151 336,159 q -7,11 2,22 z m 0,-114 q 6,8 35,9.5 29,1.5 129,0.5 L 656,75 V 54 32 H 500 Q 396,32 368.5,34 341,36 336,44 q -7,12 2,23 z m 0,-114 q 6,7 35,9 29,2 129,1 l 154,-2 V -61 -82 H 500 q -104,0 -131.5,2 -27.5,2 -32.5,10 -7,12 2,23 z" />
            </g>
        </SvgIcon>
    )
}
