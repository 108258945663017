import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const NEWS_QUERY = `
    query GetNews($data: NewsInput!) {
        news(data: $data) {
            id
            title
            content
            author
            date
            permalink
            image
            unread
            tags
        }
    }
`

export default function useFetchNews(options) {
    return useQuery(gql(NEWS_QUERY), {
        displayName: 'news',
        fetchPolicy: 'cache-and-network',
        ...options,
    })
}
