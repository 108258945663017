import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const DASHBOARD_QUERY = `
    query GetDashboard($id: Int!, $user_id: Int) {
        findDashboard(id: $id, user_id: $user_id) {
            id
            title
            library
            refresh
            refresh_measure_type
            users {
                no
                name
            }
            groups {
                no
                name
            }
            menu {
                id
                title
            }
            components {
                id
                report_id
                height
                size
                order
                invisible
                title
                info
                type
                colors
                custom
                axes
                image
                interceptions
                queries {
                    id
                    query
                }
            }
            filters {
                id
                key
                type
                desc
                default
                prec
                keepprec
            }
        }
    }
`

export default function useFetchDashboard(options) {
    return useQuery(gql(DASHBOARD_QUERY), {
        displayName: 'fetchDashboard',
        fetchPolicy: 'cache-and-network',
        ...options,
    })
}
