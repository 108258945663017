import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/menu'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getIsPdv } from '../../../App/AppReducer'
import { withWS } from '../../Business/Websocket/Context'
import {
    checkIsMenuPrincipal,
    getActiveComponent,
    getLastActiveComponent,
    getMenu,
    isActiveComponent,
} from '../../Eac/EacReducer'
import BaseMenu from './BaseMenu'
import Guias from './Estrutura/Guias'

export class Menu extends BaseMenu {
    static defaultProps = {
        isMenuPrincipal: false,
    }

    static baseOpts = {
        title: true,
        nospace: true,
    }

    static mapStateToProps = (state, ownProps) => {
        let id = ownProps.id
        if (state.eac.menusLifo.length > 0 && !id) {
            const lastIndex =
                state.eac.menusLifo[state.eac.menusLifo.length - 1]
            id = state.eac.components?.[lastIndex]?.payload?.id
        }
        return {
            isAdmin: state.app.isAdmin,
            isLocked: state.app.isLocked,
            showSidebar: state.app.showSidebar,
            menu: getMenu(state, id),
            activeComponent: getActiveComponent(state),
            enabled: isActiveComponent(id, state),
            lastActiveComponent: getLastActiveComponent(state),
            isMenuPrincipal: checkIsMenuPrincipal(state, id),
            isPdv: getIsPdv(state),
            isO2: state.eac.painelO2,
        }
    }

    render() {
        const { menu } = this.props
        const typeMenu = menu && menu.typeMenu ? menu.typeMenu : 'lateral'

        if (typeMenu === 'guias') {
            // eslint-disable-next-line no-unused-vars
            const { classes, ...props } = this.props
            return <Guias {...props} />
        }

        return BaseMenu.prototype.render.call(this)
    }
}

export default withWS(
    connect(Menu.mapStateToProps)(withRouter(withStyles(styles)(Menu)))
)
