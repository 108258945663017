import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'

export const REMOVE_PICTURE = gql`
    mutation RemovePicture(
        $prdno: String
        $seqno: Int
        $fkno: Int
        $asrmano: Int
        $prdSerie: String
        $custno: Int
        $seqnoAuto: Int
        $table: String
        $grade: String
        $pictureTablesMap: Any
    ) {
        removePicture(
            prdno: $prdno
            seqno: $seqno
            fkno: $fkno
            custno: $custno
            asrmano: $asrmano
            prdSerie: $prdSerie
            seqnoAuto: $seqnoAuto
            table: $table
            grade: $grade
            pictureTablesMap: $pictureTablesMap
        ) {
            error
            message
        }
    }
`

export default Component => {
    return graphql(REMOVE_PICTURE, {
        name: 'removePicture',
        fetchPolicy: 'cache-and-network',
    })(Component)
}
