import DragIcon from '@material-ui/icons/DragIndicator'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'

import { getValue } from '../../../../modules/NerusWeb/Components/Editor/Estrutura/Row'
import IconButton from './IconButton'
import Tr from './Tr'

export default class Row extends Component {
    static propTypes = {
        actions: PropTypes.array,
        data: PropTypes.array.isRequired,
        types: PropTypes.array.isRequired,
        classes: PropTypes.object.isRequired,
        legend: PropTypes.array.isRequired,
        onFocus: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        onDoubleClick: PropTypes.func.isRequired,
        index: PropTypes.number.isRequired,
        active: PropTypes.bool,
        activeValue: PropTypes.object,
        activeColumnSearch: PropTypes.number,
        className: PropTypes.string,
        onDrag: PropTypes.func,
        isViewOnly: PropTypes.bool,
        draggable: PropTypes.bool,
        onDragStart: PropTypes.func,
        onDragOver: PropTypes.func,
        onDragEnd: PropTypes.func,
    }

    constructor(props) {
        super(props)
    }

    colorConversion(color) {
        switch (color) {
            case '#008080': {
                return '#008080'
            }
            case '#ffffff': {
                return '#ffffff'
            }
            default: {
                return color
            }
        }
    }

    getStyles = index => {
        const { types } = this.props
        const type = types[index]
        const styles = {}
        if (type) {
            switch (type.type) {
                case 'GR_DOUBLE':
                case 'GR_DQTTY':
                case 'GR_QTTY':
                case 'GR_PERC':
                case 'GR_MONEY': {
                    styles.textAlign = 'right'
                    break
                }
                case 'GR_STRING':
                default: {
                    // do nothing
                    break
                }
            }
        }

        return styles
    }

    onClick = (action, data, index) => () => action.onClick(data, index)

    render() {
        let tdIsEmpty = true
        const {
            active = true,
            classes,
            actions,
            data,
            index,
            activeValue,
            activeColumnSearch,
            legend,
            className,
            onFocus,
            onClick,
            onDoubleClick,
            onDragStart,
            onDragEnd,
            onDragOver,
            isViewOnly,
        } = this.props
        const row = data.map((rowData, j) => {
            let styles = this.getStyles(j)
            let legendToApply = legend.filter(legend => {
                const isCol = legend.col === j || legend.col === -1
                const isLn = legend.lns.indexOf(index) > -1
                return isCol && isLn && activeColumnSearch !== j
            })

            if (
                rowData !== '' &&
                rowData !== undefined &&
                rowData !== null &&
                rowData.trim &&
                rowData.trim()
            ) {
                tdIsEmpty = false
            }

            if (legendToApply.length) {
                styles.color = this.colorConversion(legendToApply[0].fg)
                styles.backgroundColor = this.colorConversion(
                    legendToApply[0].bg
                )
            }

            if (tdIsEmpty && (!rowData || (rowData.trim && !rowData.trim()))) {
                return (
                    <td
                        key={'td-' + j}
                        className={clsx({
                            [classes.tableBody]: true,
                            [classes.tableBodyViewOnly]: isViewOnly,
                            [classes.tableRowInSearch]:
                                activeColumnSearch === j,
                        })}
                        style={styles}
                    >
                        &nbsp;
                    </td>
                )
            }

            const Component = rowData?.component

            const fakeInput = getValue(rowData, styles)
            if (typeof rowData === 'object' && rowData?.inputWidth) {
                styles.minWidth = rowData.inputWidth
            }
            return (
                <td
                    style={styles}
                    className={clsx({
                        [classes.tableBody]: true,
                        [classes.tableBodyViewOnly]: isViewOnly,
                        [classes.tableRowInSearch]: activeColumnSearch === j,
                    })}
                    key={'td-' + j}
                >
                    {Component ? (
                        rowData.enabled ? (
                            <Component
                                key={'td-comp-' + j}
                                {...rowData}
                                // InputProps={inputProps}
                                activeValue={activeValue}
                                enabled={rowData.enabled && active}
                            />
                        ) : (
                            fakeInput
                        )
                    ) : (
                        rowData
                    )}
                </td>
            )
        })

        if (actions && actions.length) {
            row.push(
                <td
                    className={clsx({
                        [classes.tableBody]: true,
                        [classes.tableBodyViewOnly]: isViewOnly,
                        [classes.rowActions]: true,
                    })}
                    key={`actions-column`}
                >
                    {actions.map((action, i) => {
                        return (
                            <IconButton
                                key={`${index}-${i}-action`}
                                tooltip={action.tooltip}
                                onClick={this.onClick(action, data, index)}
                            />
                        )
                    })}
                </td>
            )
        }

        return (
            <Tr
                onFocus={onFocus}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                // eslint-disable-next-line react/jsx-no-bind
                onDragOver={() => onDragOver && onDragOver(index)}
                className={clsx({
                    [classes.tableRowColored]: index % 2 === 0,
                    [classes.tableRowHover]: !isViewOnly,
                    [classes.tableRow]: true,
                    [className]: className,
                })}
            >
                <Fragment>
                    {this.props.draggable ? (
                        <td
                            draggable
                            // eslint-disable-next-line react/jsx-no-bind
                            onDragStart={e =>
                                onDragStart && onDragStart(e, index)
                            }
                            // eslint-disable-next-line react/jsx-no-bind
                            onDragEnd={() => onDragEnd && onDragEnd(index)}
                            className={clsx({
                                [classes.tableBody]: true,
                                [classes.tableBodyViewOnly]: isViewOnly,
                                [classes.draggable]: true,
                            })}
                        >
                            <DragIcon />
                        </td>
                    ) : null}
                    {row}
                </Fragment>
            </Tr>
        )
    }
}
