import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/editor'
import { connect } from 'react-redux'

import { withWS } from '../../Business/Websocket/Context'
import { getComponentById, isActiveComponent } from '../../Eac/EacReducer'
import BaseEditor from './BaseEditor'

export class Editor extends BaseEditor {
    static mapStateToProps = (state, ownProps) => {
        const Editor = getComponentById(ownProps.id, state).payload || {}
        return {
            title: Editor.title,
            search: Editor.query,
            searchLabel: Editor.lbQuery,
            isNew: Editor.new,
            mdQuery: Editor.mdQuery,
            rows: ownProps.rows?.length ? ownProps.rows : Editor.rows,
            columns: ownProps.cols?.length ? ownProps.cols : Editor.cols,
            columnsType: Editor.colsType || [],
            gridTot: Editor.gridTot,
            buttons: ownProps.bt !== undefined ? ownProps.bt : Editor.bt || [],
            icons: Editor.btIcons || [],
            active:
                ownProps.active ||
                (ownProps.enabled === undefined
                    ? isActiveComponent(ownProps.id, state)
                    : ownProps.enabled),
            activeRow: Editor.edtLin,
            floating: ownProps.flt ?? Editor.flt,
            enabled: Editor.enabled,
            legend: Editor.clrs,
            mouseLock: state.eac.mouseLock,
            showLegend: Editor.showLegend || false,
            colFix: Editor.colFix || 0,
        }
    }

    static defaultProps = {
        cols: [],
        colsType: [],
        rows: [],
        flt: false,
    }

    static baseOpts = {
        title: true,
        nospace: true,
        size: ({ cols }) => {
            return cols?.length > 5 ? 'big' : 'medium'
        },
    }
}

export default withWS(
    connect(Editor.mapStateToProps)(withStyles(styles)(Editor))
)
