import cloneDeep from 'lodash/cloneDeep'

import EditorForm from './EditorForm'
import { getActiveField } from './Formulario'

export default class EditorFormEditPdv extends EditorForm {
    static before({ state, action }) {
        const fields = action.payload?.form?.data
            ? cloneDeep(action.payload.form.data.list)
            : []

        if (!action.payload.ws) {
            action.payload.ws = {}
        }

        if (action.payload?.form?.retType) {
            action.payload.ws.retType = action.payload.form.retType
        }

        state.menusLifo = []
        const activeElement = getActiveField(fields)
        action.payload.editor.data.rows = []

        while (fields.length) {
            action.payload.editor.data.cols.forEach(() => {
                const element = fields.shift()
                if (element) {
                    // element.desc = ''
                    element.activeElement = cloneDeep(activeElement)
                }
            })
        }

        if (activeElement) {
            action.payload.ws.data = {
                x: activeElement.x,
                y: activeElement.y,
            }

            action.payload.editor.data.bt = cloneDeep(activeElement.bt || [])
        }

        action.payload.hasTripleId = true
        action.payload.flt = false

        return super.before({ state, action })
    }
}
