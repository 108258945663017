import TextFieldInterface from './TextFieldInterface'

class Bit extends TextFieldInterface {
    question = false

    formatter(text) {
        if (this.question) {
            return text.replace(/[^0-1?.]/g, '')
        }
        return text.replace(/[^0-1.]/g, '')
    }

    allowQuestion() {
        return (this.question = true)
    }
}

export default Bit
