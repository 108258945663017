import { defaultProps } from '@nivo/pie'

export const initialProperties = {
    margin: { top: 40, right: 200, bottom: 40, left: 80 },
    lineWidth: 2,
    colors: { scheme: 'NerusWeb' },

    valueFormat: { format: '', enabled: false },

    startAngle: defaultProps.startAngle,
    innerRadius: 0.5,
    padAngle: 0.7,
    cornerRadius: 3,
    activeInnerRadiusOffset: defaultProps.activeInnerRadiusOffset,
    activeOuterRadiusOffset: 8,

    borderWidth: 0,
    borderColor: {
        from: 'color',
        modifiers: [['darker', 0.2]],
    },

    enableArcLinkLabels: defaultProps.enableArcLinkLabels,
    arcLinkLabel: defaultProps.arcLinkLabel,
    arcLinkLabelsSkipAngle: 10,
    arcLinkLabelsTextOffset: 6,
    arcLinkLabelsTextColor: '#333333',
    arcLinkLabelsOffset: 0,
    arcLinkLabelsDiagonalLength: 16,
    arcLinkLabelsStraightLength: 24,
    arcLinkLabelsThickness: 2,
    arcLinkLabelsColor: { from: 'color' },

    enableArcLabels: true,
    arcLabel: 'value',
    arcLabelsRadiusOffset: 0.5,
    arcLabelsSkipAngle: 10,
    arcLabelsTextColor: '#333333',

    isInteractive: true,
    tooltip: null,

    defs: [],
    fill: [],

    animate: defaultProps.animate,
    motionConfig: defaultProps.motionConfig,
    transitionMode: defaultProps.transitionMode,

    showLegend: true,
    legends: [
        {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 0,
            translateY: 0,
            itemsSpacing: 0,
            itemWidth: 60,
            itemHeight: 16,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 16,
            symbolShape: 'circle',
        },
    ],
}
