import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const MENU_QUERY = `
    query GetMenu {
        allMenu {
            id
            title
            menu_id
        }
    }
`

export default function useFetchMenu(options) {
    return useQuery(gql(MENU_QUERY), {
        displayName: 'fetchMenu',
        fetchPolicy: 'cache-and-network',
        ...options,
    })
}
