const config = Object.assign(
    {
        port: process.env.PORT,
        WS: {
            ENDPOINT: process.env.WS,
            WS_MESSAGE_TIMEOUT:
                process.env.WS_MESSAGE_TIMEOUT == undefined
                    ? 180 // Define 180s como padrão caso a variável de ambiente "WS_MESSAGE_TIMEOUT" não seja definida
                    : Number(process.env.WS_MESSAGE_TIMEOUT) > 60
                    ? Number(process.env.WS_MESSAGE_TIMEOUT)
                    : 60, // Define 60s como padrão caso a variável de ambiente "WS_MESSAGE_TIMEOUT" seja menor que 60s
        },
        linesPerPage: process.env.LINE_PER_PAGE,
        sendEditBeforeMouse: process.env.SEND_EDIT_BEFORE_MOUSE || false,
        login: {
            message: process.env.LOGIN_MESSAGE,
            slides: process.env.LOGIN_SLIDES?.split?.(';'),
            logo: process.env.LOGIN_LOGO,
        },
        dashboard: {
            use:
                process.env.USE_DASHBOARD !== undefined
                    ? process.env.USE_DASHBOARD !== 'false'
                    : true,
            export:
                process.env.EXPORT_DASHBOARD !== undefined
                    ? process.env.EXPORT_DASHBOARD !== 'false'
                    : true,
        },
        pdvPhotos: process.env.PDV_PHOTOS
            ? parseInt(process.env.PDV_PHOTOS)
            : 0,
        binPhotos: process.env.PDV_BIN_PHOTOS || 'all',
        db: {
            host: process.env.DB_HOSTNAME,
            user: process.env.DB_USERNAME,
            password: process.env.DB_PASSWORD,
            database: process.env.DB_DATABASE,
            dialect: 'mysql',
            define: {
                freezeTableName: true,
                timestamps: false,
            },
        },
        enotice: {
            interval: parseInt(process.env.ENOTICE_INTERVAL) || 1,
            emailService: process.env.ENOTICE_EMAIL_SERVICE,
            emailHost: process.env.ENOTICE_EMAIL_HOST,
            emailPort: process.env.ENOTICE_EMAIL_PORT || 465,
            emailUser: process.env.ENOTICE_EMAIL_USER,
            emailPassword: process.env.ENOTICE_EMAIL_PASSWORD,
            emailClientId: process.env.ENOTICE_EMAIL_CLIENT_ID,
            emailClientSecret: process.env.ENOTICE_EMAIL_CLIENT_SECRET,
            emailRefreshToken: process.env.ENOTICE_EMAIL_REFRESH_TOKEN,
            emailRedirectUri: process.env.ENOTICE_EMAIL_REDIRECT_URI,
        },
        files: {
            // Usuário usado para upload do arquivo, por padrão primeiro usuário unix
            uid: parseInt(process.env.UPLOAD_UID) || 1000,
            // Usuário usado para upload do arquivo, por padrão primeiro usuário unix
            gid: parseInt(process.env.UPLOAD_GID) || 1000,
            // Onde é permitido fazer upload do arquivo
            uploadDir: process.env.DEFAULT_UPLOAD_DIR || '/tmp',
            // Usado para validar o caminho que o usuário está usando para fazer download do arquivo
            basePath: process.env.FILES_BASE_PATH || '/v/saci',
            // Tamanho máximo de arquivo permitido para edição via browser
            editMaxFileSize: process.env.MAXFILE_EDIT || Math.pow(1024, 2) * 2, // 2mb
            // AWS Access Id
            aws: {
                bucket: process.env.NERUS_FILES_AWS_BUCKET,
                prefix: process.env.NERUS_FILES_AWS_PREFIX,
            },
            validateTrayPhotosType: process.env.VALIDA_FOTOS_RIFF_TRAYCORP || 0,
        },
        lojaOffline: {
            ws: process.env.WS_OFFLINE,
            intervalReloadStoreGrid: 60000,
        },
        painelO2: {
            allowEdit: process.env.PAINELO2_EDICAO === 'true' || false,
            links: {
                ideris: process.env.PAINELO2_LINKS_IDERIS,
                // 'https://app.ideris.com.br/',
                anymarket: process.env.PAINELO2_LINKS_ANYMARKET,
                // 'http://app.anymarket.com.br/',
                pluggto: process.env.PAINELO2_LINKS_PLUGGTO,
                // 'https://painel.plugg.to/',
                vendala: process.env.PAINELO2_LINKS_VENDALA,
                // 'https://vendala.com.br/',
                lojaIntegrada: process.env.PAINELO2_LINKS_LOJAINTEGRADA,
                // 'https://app.lojaintegrada.com.br/',
                vtex: process.env.PAINELO2_LINKS_VTEX,
                // 'https://vtex.com/',
                tray: process.env.PAINELO2_LINKS_TRAY,
                // 'https://www.tray.com.br/login/',
                magento: process.env.PAINELO2_LINKS_MAGENTO,
                // 'https://magento.com/',
            },
        },
        biometry: {
            endpoint: process.env.UNICO_ENDPOINT,
            issuer: process.env.UNICO_ISSUER,
            audience: process.env.UNICO_AUDIENCE || process.env.UNICO_ENDPOINT,
            apiKey: process.env.UNICO_APIKEY,
            tenantKey: process.env.UNICO_TENANTKEY,
            privateKey:
                process.env.UNICO_PRIVATE_KEY || '/v/saci/keys/unico.pem',
        },
        camera: {
            allowTurnOff: process.env.CAMERA_ALLOW_OFF === 'true' || false,
            picWidth: process.env.CAMERA_PIC_WIDTH
                ? parseInt(process.env.CAMERA_PIC_WIDTH)
                : 800,
            picHeight: process.env.CAMERA_PIC_HEIGHT
                ? parseInt(process.env.CAMERA_PIC_HEIGHT)
                : 600,
            picQuality: process.env.CAMERA_PIC_QUALITY
                ? parseFloat(process.env.CAMERA_PIC_QUALITY)
                : 0.75,
        },
    },
    typeof window !== 'undefined' ? window.NERUS_CONFIG : {}
)

export const env = process.env.NODE_ENV || 'development'
export default config
