import { useCallback, useRef } from 'react'

import { cancellablePromise, delay } from '../util'

const useCancellablePromises = () => {
    const pendingPromises = useRef([])

    const appendPendingPromise = useCallback(
        promise =>
            (pendingPromises.current = [...pendingPromises.current, promise]),
        []
    )

    const removePendingPromise = useCallback(
        promise =>
            (pendingPromises.current = pendingPromises.current.filter(
                p => p !== promise
            )),
        []
    )

    const clearPendingPromises = useCallback(() => {
        pendingPromises.current.map(p => p.cancel())
        pendingPromises.current = []
    }, [])

    return {
        appendPendingPromise,
        removePendingPromise,
        clearPendingPromises,
    }
}

const usePreventClickOnDoubleClick = (onClick, onDoubleClick) => {
    const api = useCancellablePromises()

    const handleClick = useCallback(() => {
        api.clearPendingPromises()
        const waitForClick = cancellablePromise(delay(200))
        api.appendPendingPromise(waitForClick)

        return waitForClick.promise
            .then(() => {
                api.removePendingPromise(waitForClick)
                onClick && onClick()
            })
            .catch(errorInfo => {
                api.removePendingPromise(waitForClick)
                if (!errorInfo.isCanceled) {
                    throw errorInfo.error
                }
            })
    }, [api])

    const handleDoubleClick = useCallback(() => {
        api.clearPendingPromises()
        onDoubleClick && onDoubleClick()

        if (
            window &&
            window.getSelection &&
            window.getSelection().removeAllRanges
        )
            window.getSelection().removeAllRanges()
    }, [onDoubleClick])

    return [handleClick, handleDoubleClick]
}

export default usePreventClickOnDoubleClick
