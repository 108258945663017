import LinearProgress from '@material-ui/core/LinearProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import { styles } from '@nerus/styles/components/dialogoProgressao'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { getDialogoProgressao } from '../../Eac/EacReducer'

export class DialogoProgressao extends EnhancedComponent {
    static mapStateToProps = (state, ownProps) => {
        return {
            data: getDialogoProgressao(state) || ownProps.data,
        }
    }

    static propTypes = {
        data: PropTypes.object,
    }

    static baseOpts = {
        skip: true,
    }

    shouldComponentUpdate(nextProps) {
        const msg1 = this.getRef('msg1')
        const msg2 = this.getRef('msg2')
        if (msg1 && nextProps.data?.msg1 !== msg1.innerHTML) {
            msg1.innerHTML = nextProps.data.msg1
        }

        if (msg2 && nextProps.data?.msg2 !== msg2.innerHTML) {
            msg2.innerHTML = nextProps.data.msg2
        }

        if (
            (!this.props.data?.msg1 && nextProps.data?.msg1) ||
            (!this.props.data?.msg2 && nextProps.data?.msg2)
        ) {
            return true
        }

        return false // this.checkProps(['data'], nextProps)
    }

    render() {
        const { data, classes } = this.props

        if (!data) {
            return null
        }

        const { hasError } = this.state

        if (hasError) {
            return this.renderError()
        }

        const { msg1, msg2 } = data

        return (
            <div className={classes.root}>
                {msg1 ? (
                    <p
                        ref={this.createRef('msg1')}
                        className={clsx(classes.text, classes.textBig)}
                    >
                        {msg1}
                    </p>
                ) : null}
                {msg2 ? (
                    <p ref={this.createRef('msg2')} className={classes.text}>
                        {msg2}
                    </p>
                ) : null}
                <LinearProgress
                    className={classes.progressBar}
                    mode="indeterminate"
                />
            </div>
        )
    }
}

export default connect(DialogoProgressao.mapStateToProps)(
    withStyles(styles)(DialogoProgressao)
)
