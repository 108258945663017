/**
 * Ações usadas por esse reducer para alterar o contexto da aplicação
 */
/**
 * Ações usadas por esse reducer para alterar o contexto do Nérus
 */
import {
    ADD_COMPONENT,
    O2_CLOSE,
    O2_OPEN,
    RESET,
    UPDATE_COMPONENT,
    WS_DISCONNECT,
    WS_NERUSINFO,
} from '../NerusWeb/Eac/EacActions'
import {
    ABOUT_TOGGLE,
    ACTION_FAILED,
    ACTION_SUCCEED,
    CALCULATOR_TOGGLE,
    CLEAR_ERROR,
    CLEAR_SUCCESS,
    DATEPICKER_CLOSE,
    DATEPICKER_OPEN,
    HIDE_ALERT,
    IS_ADMIN,
    LOADING,
    LOCK,
    SHOW_ALERT,
    SIDEBAR_SET,
    SIDEBAR_TOGGLE,
    STOP_LOADING,
    WS_STOP,
} from './AppActions'

/**
 * Estado inicial da aplicação
 * @type {Object}
 */
const initialState = {
    wsStop: false,
    isLocked: false,
    isLoading: false,
    isPdv: false,
    isAdmin: false,
    showAbout: false,
    showSidebar: true,
    showCalculator: false,
    datepickerOpen: false,
    error: {
        message: null,
        original: null,
    },
    success: {
        message: null,
        original: null,
    },
    alert: {
        title: '',
        mensage: '',
        open: false,
    },
}

const AppReducer = (state = initialState, action) => {
    switch (action.type) {
        // Componente global, exibimos quando ocorre este evento
        case CALCULATOR_TOGGLE: {
            return {
                ...state,
                showCalculator: !state.showCalculator,
                isLocked: !state.showCalculator,
            }
        }

        // Componente global, exibimos quando ocorre este evento
        case WS_STOP: {
            return {
                ...state,
                wsStop:
                    action.value !== undefined ? action.value : !state.wsStop,
            }
        }

        // Datepicker
        case DATEPICKER_OPEN: {
            return { ...state, datepickerOpen: true }
        }

        case DATEPICKER_CLOSE: {
            return { ...state, datepickerOpen: false }
        }

        // Componente global, exibimos quando ocorre este evento
        case ABOUT_TOGGLE: {
            return { ...state, showAbout: !state.showAbout }
        }

        // Reseta a aplicação sempre que desconectamos do WS
        case WS_DISCONNECT: {
            return { ...initialState }
        }

        case WS_NERUSINFO: {
            return {
                ...state,
                isLocked: false,
                isPdv: action.payload.isPdv || false,
                showSidebar: !action.payload.isPdv,
            }
        }

        case IS_ADMIN: {
            return {
                ...state,
                isAdmin: true,
            }
        }

        // Executa sempre que adicionamos um componente
        case ADD_COMPONENT:
        case UPDATE_COMPONENT: {
            switch (action.name) {
                case 'MenuLateral':
                case 'Menu': {
                    const isPdv =
                        action.name === 'Menu' &&
                        action.payload.title &&
                        action.payload.title.trim() === 'Menu Principal'
                            ? false
                            : state.isPdv
                    state.isPdv = isPdv // trick, propaga uma alteração de forma rápida
                    return {
                        ...state,
                        wsStop: false,
                        isLocked: false,
                        showSidebar: action.payload.typeMenu === 'lateral',
                        isLoading: false,
                        isPdv,
                    }
                }
                case 'FormularioGrid':
                case 'EditorFormEdit':
                case 'EditorFormEditPdv':
                case 'EditorDuplo':
                case 'EditorForm':
                case 'Editor': {
                    return {
                        ...state,
                        wsStop: false,
                        isLocked: false,
                        showSidebar: false,
                        isLoading: false,
                    }
                }
                case 'Formulario': {
                    return {
                        ...state,
                        wsStop: false,
                        isLoading: false,
                        isLocked: false,
                        showSidebar: !state.isPdv && state.showSidebar,
                    }
                }
                case 'Dialogo': {
                    return {
                        ...state,
                        isLocked: false,
                        wsStop: false,
                        isLoading: false,
                    }
                }
            }

            return {
                ...state,
                isLoading: false,
                wsStop: false,
                isLocked: false,
            }
        }

        // Fecha ou abre a sidebar
        case SIDEBAR_TOGGLE: {
            return { ...state, showSidebar: !state.showSidebar }
        }

        // Define se a sidebar está aberta ou fechada
        case SIDEBAR_SET: {
            return { ...state, showSidebar: action.showSidebar }
        }

        /**
         * Define se a aplicação está travada
         * Enquanto ela está travada algumas ações deixam de ocorrer, como:
         * - Teclas deixam de ser enviadas para o WS
         * - Algumas ações são desabilitadas
         * - Se estamos em um form, deixa o campo livre para ser digitado
         */
        case LOCK: {
            return { ...state, isLocked: action.isLocked }
        }

        case RESET: {
            return { ...initialState }
        }

        case LOADING: {
            return { ...state, isLoading: true }
        }

        case STOP_LOADING: {
            return { ...state, isLoading: false }
        }

        case ACTION_FAILED: {
            return { ...state, ...action }
        }

        case ACTION_SUCCEED: {
            return { ...state, ...action }
        }

        case CLEAR_ERROR: {
            return {
                ...state,
                error: {
                    message: null,
                    original: null,
                },
                showSidebar: state.showSidebar,
            }
        }

        case CLEAR_SUCCESS: {
            return {
                ...state,
                success: {
                    message: null,
                    original: null,
                },
                showSidebar: state.showSidebar,
            }
        }

        case SHOW_ALERT: {
            return { ...state, alert: { ...action.alert, open: true } }
        }

        case HIDE_ALERT: {
            return { ...state, alert: { ...initialState.alert } }
        }

        case O2_CLOSE:
        case O2_OPEN: {
            return {
                ...state,
                showSidebar: action.type === O2_CLOSE,
                isLoading: false,
            }
        }

        default: {
            return state
        }
    }
}

/* Selectors */
export const getIsPdv = state => state.app.isPdv || false

// Export Reducer
export default AppReducer
