import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { SyncDisabledRounded, SyncRounded } from '@material-ui/icons'
import makeStyles from '@material-ui/styles/makeStyles'
import { formatTooltip } from '@nerus/framework/common/Formatter'
import keycodes from '@nerus/framework/common/Keycodes'
import BaseFlutuante from '@nerus/framework/components/Base/Flutuante'
import { default as MuiIconButton } from '@nerus/framework/components/Button/IconButton'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import Button from '@nerus/framework/styled/Button'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useEffect, useState } from 'react'

import Actions from './index'

const useStyles = makeStyles(() => ({}))

export default function RefreshSelector({
    dashboard,
    onSaveRefresh,
    onSaveRefreshMeasure,
}) {
    const classes = useStyles()
    const [value, setValue] = useState(null)
    const [showError, setShowError] = useState(false)
    const [show, setShow] = useState(false)
    const [timeMeasureType, setTimeMeasureType] = useState('seconds')

    useEffect(() => {
        if (dashboard?.refresh) {
            setValue(dashboard?.refresh)
        }
        if (dashboard?.refresh_measure_type) {
            setTimeMeasureType(dashboard?.refresh_measure_type)
        }
    }, [setValue, setTimeMeasureType, dashboard])

    const handleOnChange = useCallback(
        event => {
            const val =
                event.target.value === '' ? '' : Number(event.target.value)
            timeMeasureType === 'seconds'
                ? setValue(val > 300 ? 300 : val)
                : setValue(val > 300 ? 300 * 60 : val * 60)
            setShowError(val > 0 && (val < 5 || val > 300))
        },
        [setValue, timeMeasureType]
    )

    const handleTimeMeasureToggle = useCallback(
        event => {
            // if (
            //     event.target.value === 'minutes' &&
            //     event.target.value !== timeMeasureType
            // )
            //     // roundSecondsToMinutes(value)

            // if (
            //     event.target.value === 'seconds' &&
            //     event.target.value !== timeMeasureType
            // )
            //     // value > 300 && setValue(300)

            event.target.value !== timeMeasureType &&
                timeConversion(event.target.value)

            setTimeMeasureType(
                current => current !== event.target.value && event.target.value
            )
        },
        [setTimeMeasureType, value]
    )

    const handleOnBlur = useCallback(
        event => {
            let val =
                event.target.value === '' ? '' : Number(event.target.value)
            timeMeasureType === 'seconds'
                ? setValue(val > 4 ? (val > 300 ? 300 : val) : 0)
                : setValue(val > 4 ? (val > 300 ? 300 * 60 : val * 60) : 0)
            setShowError(val > 0 && (val < 5 || val > 300))
        },
        [setValue, setShowError, timeMeasureType]
    )

    const handleSliderChange = useCallback(
        (event, newValue) => {
            timeMeasureType === 'seconds'
                ? setValue(newValue > 4 ? (newValue > 300 ? 300 : newValue) : 0)
                : setValue(
                      newValue > 4
                          ? newValue > 300
                              ? 300 * 60
                              : newValue * 60
                          : 0
                  )

            setShowError(newValue > 0 && (newValue < 5 || newValue > 300))
        },
        [setValue, timeMeasureType]
    )

    const handleOnSave = useCallback(() => {
        onSaveRefreshMeasure && onSaveRefreshMeasure(timeMeasureType)

        onSaveRefresh && onSaveRefresh(value)

        setShow(show => !show)
    }, [onSaveRefresh, onSaveRefreshMeasure, value, timeMeasureType])

    const onShow = useCallback(() => {
        setShow(show => !show)
        setValue(dashboard?.refresh)
        setTimeMeasureType(dashboard?.refresh_measure_type)
    }, [setShow, setValue, setTimeMeasureType, dashboard])

    const onKeyDown = useCallback(
        event => {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                if (show) {
                    event.stopImmediatePropagation()
                    onShow()
                }
            }
        },
        [setValue, dashboard, show, setShow]
    )

    const ariaValueText = useCallback(value => {
        return value > 0 ? `${value}s` : null
    }, [])

    useKeyboardTrap(onKeyDown, { capture: true })

    const timeConversion = useCallback(
        targetMeasureType => {
            switch (targetMeasureType) {
                case 'seconds':
                    setValue(current => current / 60)
                    break
                case 'minutes':
                    setValue(current => current * 60)
                    break
            }
            // const min =
            //     value / 60 > 1 ? Math.round(value / 60) : value / 60 > 0 ? 1 : 0

            // setValue(min * 60)
        },
        [setValue]
    )

    return (
        <Fragment>
            <Tooltip
                title={formatTooltip('Atualização Automática')}
                placement={'top'}
            >
                <MuiIconButton onClick={onShow} className={classes.icon}>
                    {dashboard.refresh === 0 ? (
                        <SyncDisabledRounded />
                    ) : (
                        <SyncRounded />
                    )}
                </MuiIconButton>
            </Tooltip>

            <Typography className={clsx(classes.icon, classes.text)}>
                {dashboard?.refresh
                    ? `Atualiza a cada ${
                          dashboard?.refresh_measure_type === 'minutes'
                              ? dashboard.refresh / 60
                              : dashboard.refresh
                      } ${
                          dashboard?.refresh_measure_type === 'minutes'
                              ? 'minutos'
                              : 'segundos'
                      }`
                    : 'Não Atualiza'}
            </Typography>

            {show ? (
                <BaseFlutuante
                    title="Selecione as configurações de atualização"
                    onClose={onShow}
                >
                    <FormControl fullWidth>
                        <Typography id="input-slider" gutterBottom>
                            O Dashboard pode ser usado como um Painel de
                            Visualização, onde as
                            <br />
                            informações são atualizadas automaticamente, sem a
                            intervenção do
                            <br />
                            usuário. Para isso, basta informar o tempo, em
                            segundos ou minutos, em que os
                            <br />
                            valores serão recalculados para nova exibição do
                            Dashboard.
                        </Typography>
                        <Typography gutterBottom style={{ marginBottom: 16 }}>
                            Para Dashboards que NÃO serão utilizados como
                            Painel, deixe o<br />
                            campo abaixo zerado.
                        </Typography>
                        <Grid
                            container
                            spacing={2}
                            style={{ marginBottom: 16 }}
                            alignItems="center"
                        >
                            <Grid item>
                                {value === 0 ? (
                                    <SyncDisabledRounded />
                                ) : (
                                    <SyncRounded />
                                )}
                            </Grid>
                            <Grid item xs>
                                <Slider
                                    min={0}
                                    step={5}
                                    max={300}
                                    valueLabelDisplay="auto"
                                    onChange={handleSliderChange}
                                    getAriaValueText={ariaValueText}
                                    value={
                                        typeof value === 'number'
                                            ? timeMeasureType === 'seconds'
                                                ? value
                                                : value / 60
                                            : 0
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Input
                                    className={classes.input}
                                    value={
                                        timeMeasureType === 'seconds'
                                            ? value
                                            : value / 60
                                    }
                                    margin="dense"
                                    onChange={handleOnChange}
                                    onBlur={handleOnBlur}
                                    inputProps={{
                                        step: 5,
                                        min: 0,
                                        max: 300,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                            </Grid>
                            <FormControl>
                                <Grid item>
                                    <Select
                                        className={classes.input}
                                        value={timeMeasureType}
                                        margin="dense"
                                        onChange={handleTimeMeasureToggle}
                                    >
                                        <MenuItem value="seconds">
                                            Segundos
                                        </MenuItem>
                                        <MenuItem value="minutes">
                                            Minutos
                                        </MenuItem>
                                    </Select>
                                </Grid>
                            </FormControl>
                        </Grid>

                        {showError && (
                            <Typography variant="subtitle2" color="error">
                                O valor deve ser maior que 5 e menor que 300
                                {timeMeasureType === 'seconds'
                                    ? ' segundos'
                                    : ' minutos'}
                            </Typography>
                        )}
                    </FormControl>

                    <Actions className={classes.modalActions}>
                        <Button
                            dialog
                            color="danger"
                            lbl="Cancelar"
                            onClick={onShow}
                        />
                        <Button
                            dialog
                            primary
                            lbl="Confirmar"
                            onClick={handleOnSave}
                        />
                    </Actions>
                </BaseFlutuante>
            ) : null}
        </Fragment>
    )
}

RefreshSelector.propTypes = {
    dashboard: PropTypes.shape({
        refresh: PropTypes.number,
        refresh_measure_type: PropTypes.string,
    }),
    onSaveRefresh: PropTypes.func.isRequired,
    onSaveRefreshMeasure: PropTypes.func.isRequired,
}
