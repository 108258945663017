import keycodes from '@nerus/framework/common/Keycodes'

import { removeComponent } from '../EacActions'
import Menu from './Menu'

export function handleKeyboard() {
    return (dispatch, o) => {
        switch (o.keyCode) {
            case keycodes.ENTER_KEY:
            case keycodes.ESCAPE_KEY: {
                dispatch(removeComponent('FilePreviewer'))
                return
            }
        }
    }
}

export default class FilePreviewer extends Menu {
    static before({ state, action }) {
        action.payload.disableKeyboard = true
        action.payload.keyboardActions = handleKeyboard
        return { state, action }
    }
}
