import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import keycodes from '@nerus/framework/common/Keycodes'
import StyledButton from '@nerus/framework/styled/Button'
import Text from '@nerus/framework/styled/Text'
import { styles } from '@nerus/styles/components/fileSend'
import Axios from 'axios'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { withWS } from '../../Business/Websocket/Context'
import { resetElementIndex, sendBuffer } from '../../Eac/EacActions'
import { getActiveElementIndex, getComponentById } from '../../Eac/EacReducer'

// TODO: Mover essa configuração pro config/index.js
const maxFileEditSize = process.env.RAZZLE_MAXFILE_EDIT || Math.pow(1024, 2) // 2mb

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export class FileSend extends EnhancedComponent {
    static mapStateToProps = (state, ownProps) => {
        const FileSend = getComponentById(ownProps.id, state).payload
        return {
            data: FileSend,
            activeElementIndex: getActiveElementIndex(state),
        }
    }

    static propTypes = {
        rows: PropTypes.array,
        title: PropTypes.string,
        editorId: PropTypes.string,
        fields: PropTypes.array,
        ws: PropTypes.object.isRequired,
        activeElementIndex: PropTypes.number.isRequired,
    }

    static baseOpts = {
        title: props => props.title || 'Editor de Texto',
        size: props => {
            return props.edit
                ? 'fullscreen'
                : props.oper === 'down' || props.oper === 'up'
                ? 'small'
                : 'big'
        },
    }

    state = {
        uri: '',
        loading: false,
        error: false,
        fileIsTooBig: false,
        doUpload: false,
        doDownload: false,
        downloadingProgress: 0,
    }

    isFocused = i => {
        return this.props.activeElementIndex === parseInt(i)
    }

    componentDidMount() {
        const {
            data: { localFileName, oper, edit },
        } = this.props
        const uri = `/webprint/load${localFileName}`

        this.setState({
            uri,
            loading: true,
        })

        if (oper === 'down_up' && edit) {
            this.downloadFile(uri)
            const editor = this.getRef('editor')

            if (editor) {
                editor.addEventListener('keydown', this.onKeydown)
            }
        }
    }

    componentDidUpdate() {
        if (!this.timer) {
            /**
             * utilizado para dar focus no elemento correto
             * precisamos dar o timeout por conta da forma como o browser
             * funciona senão ele remove o focus do campo
             */
            clearTimeout(this.timer)
            this.timer = null
            this.timer = setTimeout(this.onUpdateComponent, 50)
        }
    }

    onUpdateComponent = () => {
        const index = `button${this.props.activeElementIndex}`
        clearTimeout(this.timer)
        this.timer = null
        if (this.getRef(index) && document.activeElement?.type !== 'textarea') {
            this.getRef(index).focus()
        } else {
            const {
                props: {
                    data: { oper },
                },
            } = this

            switch (oper) {
                case 'down_up':
                    // apenas 1 botão
                    if (this.props.activeElementIndex > 0) {
                        this.props.dispatch(resetElementIndex(0))
                    }
                    break
                case 'up':
                case 'down':
                    // 2 botões
                    if (this.props.activeElementIndex > 1) {
                        this.props.dispatch(resetElementIndex(0))
                    }
                    break
            }
        }
    }

    downloadFile = uri => {
        return Axios.head(uri)
            .then(res => {
                if (res.status === 200) {
                    const contentLength = parseInt(
                        res.headers['content-length']
                    )

                    if (contentLength > maxFileEditSize) {
                        this.setState({
                            fileIsTooBig: contentLength,
                            loading: false,
                        })
                    } else {
                        return Axios.get(uri, {
                            onDownloadProgress: progressEvent => {
                                const totalLength = progressEvent.lengthComputable
                                    ? progressEvent.total
                                    : progressEvent.target.getResponseHeader(
                                          'content-length'
                                      ) ||
                                      progressEvent.target.getResponseHeader(
                                          'x-decompressed-content-length'
                                      )
                                if (totalLength !== null) {
                                    this.setState({
                                        downloadingProgress: Math.round(
                                            (progressEvent.loaded * 100) /
                                                totalLength
                                        ),
                                    })
                                }
                            },
                        }).then(res => {
                            this.setState({
                                loading: false,
                                content: res.data,
                            })
                        })
                    }
                } else {
                    this.setState({
                        error: true,
                        loading: false,
                        details: {
                            code: res.status,
                        },
                    })
                }
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: true,
                    details: e,
                })
            })
    }

    onKeydown = event => {
        event.stopImmediatePropagation && event.stopImmediatePropagation()
    }

    onEdit = event => {
        this.setState({ content: event.target.value })
    }

    openOnClick = uri => () => {
        window.open(uri)

        this.setState({
            doUpload: true,
        })
    }

    onClose = () => {
        this.props.ws.send(sendBuffer(keycodes.ESCAPE_KEY))
    }

    onSaveFile = () => this.onSave(this.state.selectedFile)

    onSave = blob => {
        const {
            data: { localFileName, uid, gid },
        } = this.props

        const useBlob =
            blob ||
            new Blob([this.state.content], {
                type: 'text/plain;charset=iso-8859-1',
            })

        const parts = localFileName.split('/')
        const data = new FormData()
        data.append('uri', localFileName)
        data.append('uid', uid)
        data.append('gid', gid)
        data.append('file', useBlob, parts.pop())

        return Axios.post(`/webprint/persist`, data, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            },
            timeout: 30000, // 30 segs
            onDownloadProgress: progressEvent => {
                const totalLength = progressEvent.lengthComputable
                    ? progressEvent.total
                    : progressEvent.target.getResponseHeader(
                          'content-length'
                      ) ||
                      progressEvent.target.getResponseHeader(
                          'x-decompressed-content-length'
                      )
                if (totalLength !== null) {
                    this.setState({
                        downloadingProgress: Math.round(
                            (progressEvent.loaded * 100) / totalLength
                        ),
                    })
                }
            },
            onUploadProgress: progressEvent => {
                const totalLength = progressEvent.lengthComputable
                    ? progressEvent.total
                    : progressEvent.target.getResponseHeader(
                          'content-length'
                      ) ||
                      progressEvent.target.getResponseHeader(
                          'x-decompressed-content-length'
                      )
                if (totalLength !== null) {
                    this.setState({
                        downloadingProgress: Math.round(
                            (progressEvent.loaded * 100) / totalLength
                        ),
                    })
                }
            },
        }).then(() => {
            this.setState({
                loading: false,
            })
            this.props.ws.send(sendBuffer(keycodes.ENTER_KEY))
        })
    }

    doSave = () => {
        if (this.state.selectedFile) {
            this.onSaveFile()
        } else {
            this.onSave()
        }
    }

    renderEditUpload() {
        const {
            state: {
                downloadingProgress,
                selectedFile,
                fileIsTooBig,
                doUpload,
                loading,
                content,
                error,
                uri,
            },
            props: { classes },
        } = this

        return (
            <Fragment>
                {loading ? (
                    <div className={classes.fileIsTooBig}>
                        <div className={classes.progress}>
                            <div className={classes.progressIndicator}>
                                {downloadingProgress > 0
                                    ? downloadingProgress === 100
                                        ? 'Processo concluído.'
                                        : `Carregando: ${downloadingProgress}%`
                                    : 'Iniciando processo...'}
                            </div>

                            <LinearProgress
                                variant={'determinate'}
                                value={downloadingProgress}
                                size={120}
                                color={'primary'}
                                className={classes.progressBar}
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}
                {fileIsTooBig ? (
                    <div
                        className={clsx(classes.fileIsTooBig, {
                            [classes.rootSelected]: Boolean(selectedFile),
                        })}
                    >
                        {!doUpload ? (
                            <Fragment>
                                <Typography variant={'h3'} gutterBottom>
                                    O arquivo é muito grande para ser editado.
                                    Ele possui {formatBytes(fileIsTooBig)} e o
                                    tamanho máximo é{' '}
                                    <span className={classes.bold}>
                                        {formatBytes(maxFileEditSize)}
                                    </span>
                                </Typography>
                                <Typography variant={'h4'} gutterBottom>
                                    Mas, você ainda pode baixá-lo para editar.
                                </Typography>

                                <StyledButton
                                    primary
                                    dialog
                                    ref={this.createRef(`button0`)}
                                    isFocused={this.isFocused(0)}
                                    onClick={this.openOnClick(uri)}
                                >
                                    Baixar
                                </StyledButton>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Typography variant={'h4'} gutterBottom>
                                    Após editar o arquivo, clique no botão
                                    abaixo para anexar o arquivo.
                                </Typography>

                                <input
                                    type="file"
                                    ref={this.createRef('input')}
                                    className={classes.inputHidden}
                                    onChange={this.onChangeFile}
                                    accept={'.txt'}
                                />

                                <StyledButton
                                    primary
                                    dialog
                                    ref={this.createRef(`button0`)}
                                    isFocused={this.isFocused(0)}
                                    onClick={this.selectFile}
                                >
                                    {!selectedFile
                                        ? 'Anexar Arquivo'
                                        : `Selecionado: ${selectedFile.name}`}
                                </StyledButton>
                            </Fragment>
                        )}
                    </div>
                ) : null}

                <TextField
                    autoFocus
                    multiline
                    fullWidth
                    rows={42}
                    disabled={error || fileIsTooBig}
                    variant="filled"
                    inputRef={this.createRef('editor')}
                    value={content}
                    onChange={this.onEdit}
                    className={classes.editorRoot}
                    InputProps={{
                        classes: {
                            root: classes.editorBackground,
                            input: classes.editor,
                        },
                    }}
                />

                <div className={classes.actions}>
                    <StyledButton
                        dialog
                        primary
                        ref={this.createRef(`button0`)}
                        isFocused={this.isFocused(0)}
                        onClick={this.doSave}
                    >
                        Salvar
                    </StyledButton>
                    <StyledButton
                        dialog
                        ref={this.createRef(`button1`)}
                        isFocused={this.isFocused(1)}
                        onClick={this.onClose}
                    >
                        Fechar
                    </StyledButton>
                </div>
            </Fragment>
        )
    }

    renderDownload = () => {
        const { uri } = this.state
        const {
            classes,
            data: { localFileName },
        } = this.props
        return (
            <Fragment>
                <div className={classes.uploadDownload}>
                    <Text bold component={'p'}>
                        Arquivo: {localFileName}
                    </Text>
                    <Text component={'p'}>Selecione uma ação abaixo:</Text>
                </div>
                <div className={classes.actions}>
                    <StyledButton
                        primary
                        dialog
                        autoFocus
                        onClick={this.openOnClick(uri)}
                        ref={this.createRef(`button0`)}
                        isFocused={this.isFocused(0)}
                    >
                        Baixar
                    </StyledButton>
                    <StyledButton
                        dialog
                        onClick={this.onClose}
                        ref={this.createRef(`button1`)}
                        isFocused={this.isFocused(1)}
                    >
                        Fechar
                    </StyledButton>
                </div>
            </Fragment>
        )
    }

    selectFile = e => {
        e.preventDefault()
        this.getRef('input').click()
    }

    onChangeFile = e => {
        e.persist()
        this.setState({
            selectedFile: e.target.files[0],
        })
    }

    renderUpload = () => {
        const {
            props: { classes },
            state: { selectedFile },
        } = this
        return (
            <Fragment>
                <input
                    type="file"
                    ref={this.createRef('input')}
                    className={classes.inputHidden}
                    onChange={this.onChangeFile}
                    accept={'.txt'}
                />
                <div className={classes.upload} onClick={this.selectFile}>
                    <Typography paragraph gutterBottom={false}>
                        Clique para selecionar um arquivo
                        {selectedFile
                            ? [
                                  <br key={'br-upload'} />,
                                  <strong key={'upload-filename'}>
                                      {selectedFile.name}
                                  </strong>,
                              ]
                            : ''}
                    </Typography>
                </div>
                <div className={classes.actions}>
                    <StyledButton
                        primary
                        dialog
                        autoFocus
                        onClick={this.onSaveFile}
                        ref={this.createRef(`button0`)}
                        isFocused={this.isFocused(0)}
                    >
                        Salvar
                    </StyledButton>
                    <StyledButton
                        dialog
                        onClick={this.onClose}
                        ref={this.createRef(`button1`)}
                        isFocused={this.isFocused(1)}
                    >
                        Cancelar
                    </StyledButton>
                </div>
            </Fragment>
        )
    }

    render() {
        const {
            props: {
                data: { edit, oper },
                classes,
            },
        } = this

        let component = null
        switch (oper) {
            case 'down_up':
                component = this.renderEditUpload(edit)
                break
            case 'down':
                component = this.renderDownload()
                break
            case 'up':
                component = this.renderUpload()
                break
        }

        if (component) {
            return <Box className={clsx(classes.root)}>{component}</Box>
        }

        return (
            <Typography color={'primary'} variant={'h4'}>
                Operação não implementada.
            </Typography>
        )
    }
}

export default connect(FileSend.mapStateToProps)(
    withStyles(styles)(withWS(FileSend))
)
