import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import Description from '@nerus/framework/styled/Description'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { withWS } from '../../../Business/Websocket/Context'
import { sendBuffer } from '../../../Eac/EacActions'
import { recToClass } from './Recs'

export class Rec extends EnhancedComponent {
    static propTypes = {
        useDiv: PropTypes.bool,
        lbl: PropTypes.string,
        value: PropTypes.string,
        isLocked: PropTypes.bool,
        typeRec: PropTypes.string,
        sendMouse: PropTypes.bool,
        autoResize: PropTypes.bool,
        activeValue: PropTypes.any,
        minInputWidth: PropTypes.number,
        activeElement: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        enabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        ws: PropTypes.object.isRequired,
    }

    onMouseClick = () => {
        let shouldSend = true
        const {
            x,
            y,
            componentId,
            id,
            activeValue,
            activeElement = {},
            sendMouse = true,
            ws,
            enabled,
        } = this.props

        if (!sendMouse || enabled) {
            return
        }

        if (activeElement && activeValue?.current) {
            ws.send(
                sendBuffer(
                    {
                        x: activeElement.x,
                        y: activeElement.y,
                        fieldId: activeElement.id,
                        key: -1,
                        value: activeValue.current,
                    },
                    'sendEdit'
                )
            )
        }

        if (!activeValue) {
            shouldSend = false
            console.trace(
                'Campo ativo sem ref. de valor tem que ser verificado.'
            )
        }

        if (shouldSend) {
            ws.send(
                sendBuffer(
                    {
                        componentId,
                        fieldId: id,
                        x,
                        y,
                    },
                    'sendMouse'
                )
            )
        }
    }

    shouldComponentUpdate(nextProps /*, nextState, nextContext */) {
        return this.checkProps(
            [
                'value',
                'desc',
                'enabled',
                'typeRec',
                'typeRecMod',
                'waiting',
                'prec',
                'sz',
                'lbl',
            ],
            nextProps
        )
    }

    render() {
        const { hasError } = this.state
        const {
            sz,
            lbl,
            desc,
            value,
            useDiv,
            enabled,
            typeRec,
            boxWidth,
            qtdColumns,
            waiting,
            autoResize,
        } = this.props

        let maxWidth

        if (hasError) {
            return this.renderError()
        }

        if (useDiv === true) {
            return (
                <div className="input-form">
                    <label>{lbl}</label>

                    <div className="input-value">{value}</div>
                </div>
            )
        }

        const Component = recToClass[typeRec]

        //O numero 15 é referente ao icone do calendario
        if (typeRec === 'REC_DATE') {
            maxWidth = sz > 8 ? 15 + (sz * 9 + 9) : 15 + (sz * 11 + 9)
        } else if (typeRec === 'REC_TIME') {
            maxWidth = sz > 8 ? 15 + (sz * 7 + 9) : 15 + (sz * 9 + 9)
        } else if (typeRec === 'REC_YM') {
            maxWidth = sz > 8 ? 15 + (sz * 9 + 9) : 15 + (sz * 10 + 9)
        }

        const otherProps = { autoResize }
        // Caso a aplicação esteja travada, desabilita o campo ativo
        // fazendo aqui a alteração é menor e com menos possibilidade de erros
        if (waiting && enabled) {
            otherProps.enabled = false
        }

        if (Component && Component.withoutWrapper) {
            otherProps.onClick = enabled ? undefined : this.onMouseClick
        }

        let renderedField = <Component {...this.props} {...otherProps} />
        if (Component && !Component.withoutWrapper) {
            renderedField = (
                <div
                    style={{
                        minWidth: boxWidth,
                        maxWidth: maxWidth ? maxWidth : null,
                        /* não podemos considerar o tamanho do box nos
                         casos atípicos quando temos 5 ou mais colunas */
                        flex:
                            boxWidth < 50 && qtdColumns < 5 && autoResize
                                ? 1
                                : undefined,
                    }}
                    onClick={enabled ? undefined : this.onMouseClick}
                >
                    {renderedField}

                    {desc ? <Description text={desc} /> : null}
                </div>
            )
        }

        return Component ? (
            renderedField
        ) : (
            <div>{`${typeRec}: Não implementado`}</div>
        )
    }
}

export default withWS(
    connect(state => ({
        wsStop: state.app.wsStop,
        isLocked: state.app.isLocked,
    }))(Rec)
)
