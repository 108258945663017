import { makeStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { useO2 } from '@nerus/framework/hooks/useO2'
import StyledButton from '@nerus/framework/styled/Button'
import { styles } from '@nerus/styles/login'
import { styles as stylesO2 } from '@nerus/styles/loginO2'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import useWS from '@nerus/framework/hooks/useWS'
import { resetComponents } from '../../EacActions'
import Slider from '../Login/Slider'
import SliderO2 from '../Login/SliderO2'

export function Logout() {
    const { isO2 } = useO2(true)
    const classes = makeStyles(isO2 ? stylesO2 : styles)()
    const dispatch = useDispatch()
    const ws = useWS()

    const doLogin = useCallback(() => {
        ws?.connect?.()
        dispatch(resetComponents(false))
        // if (this.props.ws) {
        //     this.props.ws.close()
        // }
    }, [dispatch])

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.fontConthrax]: !isO2,
            })}
        >
            <div
                className={clsx({
                    [classes.content]: true,
                })}
            >
                {!isO2 ? <Slider /> : <SliderO2 classes={classes} />}

                <div className={classes.formWrapper}>
                    <div className={classes.callingWrapper}>
                        <h2 className={classes.caller}>Obrigado!</h2>
                        <h3 className={classes.welcomeText}>
                            Você saiu com sucesso do sistema.
                        </h3>
                    </div>

                    <StyledButton
                        secondary
                        size={'medium'}
                        lbl={'Efetuar novo Login'}
                        className={classes.submit}
                        onClick={doLogin}
                    />

                    {isO2 ? (
                        <div className={classes.footer}>
                            <svg
                                width="119"
                                height="44"
                                viewBox="0 0 119 44"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M108.963 35.7417C107.016 35.7417 105.068 35.7314 103.12 35.7499C102.836 35.7519 102.624 35.5227 102.636 35.2403C102.663 34.6571 102.659 34.0719 102.638 33.4887C102.626 33.1224 102.771 33.0201 103.122 33.0201C106.56 33.0303 109.998 33.0303 113.437 33.0242C113.876 33.0242 114.315 32.9915 114.751 32.9444C115.194 32.8973 115.548 32.5536 115.599 32.1095C115.652 31.6552 115.684 31.1825 115.623 30.7324C115.544 30.1573 115.027 29.7256 114.447 29.7092C113.896 29.6928 113.343 29.699 112.791 29.699C110.921 29.699 109.053 29.7194 107.183 29.6887C106.456 29.6765 105.723 29.6007 105.007 29.4739C103.618 29.2304 102.783 28.3075 102.722 26.8833C102.677 25.8806 102.677 24.8615 102.81 23.8691C102.965 22.7068 103.539 21.7348 105.005 21.4196C105.686 21.2723 106.393 21.1802 107.087 21.1761C110.733 21.1536 114.382 21.17 118.028 21.1577C118.318 21.1577 118.543 21.393 118.535 21.6836C118.518 22.2648 118.518 22.848 118.537 23.4291C118.545 23.7013 118.332 23.9243 118.059 23.9243C114.568 23.912 111.078 23.9161 107.587 23.9182C107.275 23.9182 106.958 23.9264 106.648 23.9611C106.148 24.0164 105.758 24.4379 105.707 24.9393C105.676 25.2503 105.664 25.5654 105.674 25.8785C105.699 26.5845 106.285 27.1452 106.989 27.1493C108.506 27.1554 110.023 27.1452 111.538 27.1554C112.832 27.1636 114.125 27.1718 115.419 27.2148C116.132 27.2373 116.811 27.4317 117.422 27.8205C118.249 28.3464 118.569 29.1628 118.622 30.0837C118.671 30.9452 118.671 31.8148 118.624 32.6763C118.545 34.1681 117.849 35.091 116.422 35.4531C115.697 35.6373 114.929 35.711 114.18 35.7274C112.442 35.7662 110.703 35.7396 108.963 35.7417Z"
                                    fill="#A1A1A1"
                                />
                                <path
                                    d="M83.5834 26.7584C83.5834 25.0681 83.5834 23.3779 83.5834 21.6877C83.5834 21.1699 83.6017 21.1679 84.1326 21.1679C84.8839 21.1658 85.6373 21.1658 86.3886 21.1679C86.8745 21.1699 86.8909 21.1822 86.8909 21.6877C86.8909 24.53 86.8888 27.3743 86.895 30.2166C86.895 30.6218 86.9276 31.029 86.9746 31.4301C87.0624 32.1892 87.6585 32.8052 88.4139 32.9095C88.8039 32.9627 89.202 32.9771 89.5961 32.9771C91.505 32.9771 93.412 32.9811 95.3209 32.9545C96.354 32.9402 97.1993 32.1033 97.2238 31.0699C97.2728 29.0093 97.2768 26.9487 97.2932 24.888C97.3013 23.8015 97.3034 22.7128 97.2891 21.6263C97.285 21.2989 97.3708 21.1515 97.7301 21.1618C98.5447 21.1843 99.3593 21.1761 100.174 21.1638C100.388 21.1618 100.564 21.3336 100.564 21.5485C100.56 24.798 100.574 28.0475 100.548 31.295C100.544 31.8864 100.405 32.4859 100.264 33.0671C99.9208 34.4893 98.8877 35.1932 97.5504 35.4653C96.7378 35.6311 95.8967 35.7129 95.0657 35.7273C92.922 35.7621 90.7782 35.7723 88.6345 35.7211C87.8219 35.7027 86.993 35.5656 86.2069 35.3507C84.3857 34.8555 83.7528 33.5684 83.663 31.8639C83.5731 30.1654 83.6426 28.4609 83.6426 26.7584C83.6221 26.7584 83.6038 26.7584 83.5834 26.7584Z"
                                    fill="#A1A1A1"
                                />
                                <path
                                    d="M80.8701 35.2875C81.1048 35.4205 81.013 35.7765 80.7435 35.7786C79.6267 35.7827 77.8239 35.7745 76.8398 35.7643C76.3763 35.7602 75.672 35.5289 75.2738 35.2875C72.7177 33.7425 70.1697 32.1873 67.6176 30.6362C67.3154 30.4521 67.2276 30.5073 67.2276 30.9084C67.2236 32.3633 67.2113 33.8162 67.2338 35.2711C67.2399 35.6599 67.1154 35.7602 66.7438 35.7499C65.8454 35.7254 64.9451 35.7417 64.0059 35.7417C63.9957 35.5514 63.9814 35.398 63.9814 35.2465C63.9793 30.5544 63.9793 25.8602 63.9814 21.168C68.1178 21.17 72.2542 21.1537 76.3906 21.1823C77.4972 21.1905 78.6018 21.3215 79.6369 21.7757C80.6495 22.2198 81.2559 23.0342 81.3682 24.084C81.4927 25.2585 81.5091 26.4638 81.4091 27.6404C81.258 29.4371 79.9554 30.227 78.516 30.5441C76.901 30.9002 75.2677 30.7488 73.6405 30.7938C73.5017 30.7979 72.8565 30.759 72.8483 30.7856C72.8422 30.8061 78.2894 33.8305 80.8701 35.2875ZM67.6809 23.8855C67.393 23.8834 67.2338 24.0185 67.2358 24.4421C67.2378 24.8657 67.2521 27.5074 67.2317 27.7305C67.1848 28.2461 67.3256 28.3587 67.7279 28.3566C70.4861 28.3444 73.2424 28.3526 76.0007 28.3464C76.3253 28.3464 76.654 28.3178 76.9766 28.2728C77.632 28.1827 78.1016 27.7898 78.1444 27.2353C78.1914 26.6132 78.1689 25.985 78.1771 25.3588C78.1893 24.4748 77.6891 23.9755 76.701 23.9632C75.3269 23.9448 69.8308 23.9039 67.6809 23.8855Z"
                                    fill="#A1A1A1"
                                />
                                <path
                                    d="M53.0564 20.11L54.8796 17.8182C55.1205 17.5153 55.486 17.3373 55.8739 17.3352L57.8216 17.3291L55.4778 19.7212C55.2389 19.9647 54.9123 20.1018 54.5734 20.1039L53.0564 20.11Z"
                                    fill="#A1A1A1"
                                />
                                <path
                                    d="M56.6701 29.7112C54.708 29.7112 51.474 29.7194 49.514 29.703C49.2364 29.7009 49.0281 29.926 49.0465 30.2043C49.0853 30.7486 49.0588 31.2991 49.1445 31.8352C49.2445 32.4552 49.7958 32.8952 50.4185 32.9505C50.9167 32.9934 51.4169 33.0241 51.915 33.0241C55.0531 33.0303 58.1891 33.0282 61.3271 33.0282C61.613 33.0282 61.8457 33.2594 61.8457 33.5439C61.8478 34.1373 61.8375 34.7287 61.8477 35.3221C61.8539 35.6106 61.7702 35.7457 61.4476 35.7457C57.8134 35.7355 54.1792 35.7702 50.5471 35.715C49.61 35.7007 48.6484 35.5615 47.7643 35.1461C46.4332 34.5179 45.886 33.3679 45.8492 32.0051C45.786 29.6375 45.7982 27.2659 45.8472 24.8983C45.8697 23.7769 46.2617 22.7517 47.2396 22.0846C47.599 21.8391 48.0195 21.616 48.4401 21.5403C49.3977 21.3664 50.3695 21.2006 51.3393 21.1884C54.6203 21.1454 57.9012 21.1699 61.1822 21.1699C61.7375 21.1699 61.7375 21.172 61.7375 21.7409C61.7375 22.3179 61.7253 22.895 61.7436 23.47C61.7538 23.7872 61.664 23.9038 61.3251 23.9038C58.136 23.8915 54.9469 23.8915 51.7558 23.8977C51.3495 23.8977 50.9432 23.9263 50.539 23.9713C49.7203 24.0614 49.0792 24.7407 49.0547 25.5654C49.0424 25.9746 49.0628 26.3839 49.0445 26.7911C49.0343 27.0305 49.2262 27.2065 49.465 27.1963C50.345 27.1615 57.0866 27.1922 57.3581 27.1922C57.9196 27.1922 57.9196 27.1799 57.9216 27.716C57.9216 28.2399 57.9053 28.7658 57.9278 29.2876C57.942 29.615 57.8257 29.7194 57.501 29.7173C55.5267 29.705 58.6423 29.7112 56.6701 29.7112Z"
                                    fill="#A1A1A1"
                                />
                                <path
                                    d="M40.1122 31.6756C40.1285 31.4403 40.1428 31.3298 40.1428 31.2172C40.1428 28.0598 40.1428 24.9024 40.1408 21.7449C40.1408 21.1781 40.151 21.1679 40.7043 21.1679C41.4189 21.1679 42.1335 21.1781 42.846 21.1638C43.093 21.1576 43.2952 21.352 43.2952 21.5996C43.289 26.0012 43.2931 30.4028 43.289 34.8044C43.289 35.4735 42.8154 35.936 42.0885 36.0158C41.1575 36.1161 40.3592 35.8787 39.6508 35.2178C36.8578 32.6149 34.0403 30.0365 31.233 27.45C30.5878 26.8566 29.9406 26.2672 29.2934 25.6779C29.2199 25.6124 29.1362 25.5572 28.9892 25.4446C28.9667 25.68 28.9382 25.8396 28.9382 25.9992C28.9361 29.0768 28.9361 32.1565 28.9361 35.2341C28.9361 35.7396 28.9341 35.7416 28.4155 35.7416C27.6887 35.7416 26.9618 35.7314 26.235 35.7457C26.0308 35.7498 25.8634 35.5881 25.8634 35.3835C25.8695 31.0085 25.8532 26.6315 25.8777 22.2565C25.8818 21.3684 26.3085 21.0328 27.2722 20.8671C28.3359 20.6849 29.0729 21.2579 29.7875 21.9393C33.095 25.0845 36.4168 28.2153 39.7345 31.3503C39.8223 31.4382 39.9223 31.514 40.1122 31.6756Z"
                                    fill="#A1A1A1"
                                />
                                <path
                                    d="M26.088 38.6669H27.2007C27.4396 38.6669 27.6683 38.6873 27.8888 38.7262C28.1093 38.7671 28.3032 38.8367 28.4727 38.937C28.6421 39.0373 28.7769 39.1723 28.8769 39.3422C28.977 39.512 29.028 39.7289 29.028 39.9929C29.028 40.2487 28.9831 40.4656 28.8953 40.6457C28.8075 40.8257 28.687 40.9669 28.5339 41.0774C28.3808 41.1859 28.2011 41.2657 27.997 41.3169C27.7908 41.368 27.5723 41.3926 27.3375 41.3926H26.968V43.0603H26.088V38.6669ZM26.968 40.7009H27.2763C27.3845 40.7009 27.4906 40.6907 27.5907 40.6702C27.6907 40.6498 27.7805 40.6129 27.8581 40.5638C27.9357 40.5127 27.997 40.4472 28.0439 40.3633C28.0909 40.2794 28.1133 40.175 28.1133 40.0481C28.1133 39.9213 28.0909 39.8149 28.048 39.7269C28.0051 39.6389 27.9439 39.5673 27.8663 39.512C27.7887 39.4568 27.7009 39.4179 27.5988 39.3954C27.4988 39.3729 27.3906 39.3606 27.2783 39.3606H26.97V40.7009H26.968Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M30.447 38.6678H31.4393L33.1604 43.0633H32.1865L31.8088 42.0566H30.0305L29.6467 43.0633H28.7361L30.447 38.6678ZM30.9309 39.5498H30.9186L30.2837 41.3628H31.5658L30.9309 39.5498Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M33.6502 38.6678H34.5996C34.8344 38.6678 35.0733 38.674 35.3183 38.6842C35.5633 38.6945 35.7858 38.7354 35.9839 38.807C36.1819 38.8786 36.3452 38.9953 36.4739 39.1569C36.6025 39.3186 36.6658 39.5519 36.6658 39.8588C36.6658 39.9898 36.6454 40.1126 36.6025 40.2271C36.5596 40.3417 36.5004 40.4461 36.4228 40.5361C36.3452 40.6262 36.2493 40.7019 36.137 40.7633C36.0247 40.8247 35.8981 40.8636 35.7593 40.8799V40.8922C35.8185 40.8963 35.8716 40.9127 35.9185 40.9434C35.9655 40.9741 36.0104 41.0088 36.0472 41.0539C36.0839 41.0989 36.1186 41.1459 36.1472 41.1951C36.1758 41.2462 36.2023 41.2953 36.2227 41.3465L36.9271 43.0654H35.9471L35.4204 41.6923C35.3877 41.6125 35.355 41.5429 35.3224 41.4836C35.2917 41.4242 35.2529 41.3772 35.206 41.3424C35.159 41.3076 35.1059 41.281 35.0427 41.2646C34.9794 41.2483 34.9018 41.2401 34.8099 41.2401H34.5282V43.0654H33.6482V38.6678H33.6502ZM34.5302 40.5443H34.8181C34.9263 40.5443 35.0365 40.5382 35.1488 40.5259C35.2591 40.5136 35.3591 40.487 35.4469 40.4441C35.5347 40.4031 35.6062 40.3417 35.6633 40.2619C35.7205 40.1821 35.7491 40.0737 35.7491 39.9345C35.7491 39.8036 35.7205 39.7012 35.6633 39.6235C35.6062 39.5457 35.5347 39.4884 35.4469 39.4495C35.3591 39.4127 35.2591 39.3882 35.1488 39.3779C35.0386 39.3677 34.9283 39.3616 34.8181 39.3616H34.5302V40.5443Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M38.8219 38.6678H39.8141L41.5352 43.0633H40.5614L40.1837 42.0566H38.4054L38.0215 43.0633H37.111L38.8219 38.6678ZM39.3058 39.5498H39.2935L38.6585 41.3628H39.9407L39.3058 39.5498Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M43.6321 40.8534C43.6321 40.5178 43.679 40.2108 43.7709 39.9346C43.8628 39.6583 43.9996 39.4189 44.1833 39.2204C44.365 39.0219 44.5896 38.8664 44.8591 38.7579C45.1266 38.6495 45.4349 38.5942 45.782 38.5942C46.125 38.5942 46.4312 38.6495 46.6987 38.762C46.9661 38.8725 47.1927 39.028 47.3745 39.2286C47.5562 39.4271 47.695 39.6665 47.7889 39.9427C47.8828 40.219 47.9298 40.5239 47.9298 40.8554C47.9298 41.1869 47.8828 41.4918 47.7889 41.7721C47.695 42.0504 47.5562 42.2919 47.3745 42.4965C47.1927 42.7012 46.9682 42.8587 46.7027 42.9713C46.4373 43.0838 46.129 43.1411 45.782 43.1411C45.4308 43.1411 45.1204 43.0859 44.853 42.9754C44.5855 42.8649 44.361 42.7073 44.1813 42.5068C44.0016 42.3062 43.8648 42.0648 43.773 41.7824C43.679 41.4979 43.6321 41.1889 43.6321 40.8534ZM44.5508 40.8534C44.5508 41.0498 44.5733 41.2442 44.6162 41.4366C44.6611 41.6269 44.7305 41.7967 44.8305 41.9461C44.9285 42.0955 45.0551 42.2162 45.2103 42.3083C45.3654 42.4004 45.5553 42.4474 45.782 42.4474C46.0086 42.4474 46.1985 42.4004 46.3536 42.3083C46.5088 42.2162 46.6354 42.0955 46.7334 41.9461C46.8314 41.7967 46.9028 41.6269 46.9477 41.4366C46.9927 41.2462 47.0131 41.0518 47.0131 40.8534C47.0131 40.661 46.9906 40.4707 46.9437 40.2845C46.8967 40.0983 46.8252 39.9305 46.7232 39.7811C46.6231 39.6317 46.4945 39.513 46.3393 39.423C46.1842 39.333 45.9984 39.2879 45.7799 39.2879C45.5615 39.2879 45.3757 39.333 45.2205 39.423C45.0653 39.513 44.9387 39.6338 44.8367 39.7811C44.7366 39.9305 44.6631 40.0983 44.6162 40.2845C44.5733 40.4707 44.5508 40.659 44.5508 40.8534Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M53.671 42.9999C53.5118 43.0367 53.3484 43.0695 53.1769 43.0981C53.0075 43.1247 52.838 43.1391 52.6706 43.1391C52.3153 43.1391 51.9887 43.094 51.6947 43.004C51.3986 42.914 51.1455 42.7789 50.9352 42.5988C50.7228 42.4188 50.5595 42.1916 50.4411 41.9154C50.3247 41.6412 50.2655 41.3158 50.2655 40.9434C50.2655 40.5648 50.3206 40.2313 50.4309 39.9387C50.5411 39.646 50.7004 39.4005 50.9086 39.202C51.1169 39.0035 51.368 38.8521 51.6661 38.7477C51.9642 38.6454 52.299 38.5942 52.6706 38.5942C52.8176 38.5942 52.9789 38.6086 53.1565 38.6393C53.3341 38.6679 53.5036 38.715 53.6628 38.7784L53.5996 39.5212C53.4321 39.425 53.2708 39.3616 53.1198 39.3329C52.9687 39.3043 52.8115 39.2879 52.6522 39.2879C52.4256 39.2879 52.2214 39.3289 52.0397 39.4107C51.858 39.4926 51.7028 39.6051 51.5783 39.7484C51.4517 39.8916 51.3558 40.0594 51.2863 40.2517C51.2169 40.4441 51.1822 40.6549 51.1822 40.882C51.1822 41.105 51.2149 41.3117 51.2823 41.502C51.3496 41.6923 51.4456 41.8581 51.5742 41.9993C51.7028 42.1405 51.858 42.251 52.0418 42.3308C52.2255 42.4106 52.4358 42.4495 52.6706 42.4495C52.8462 42.4495 53.0238 42.4331 53.2055 42.3983C53.3852 42.3656 53.5281 42.3205 53.6322 42.2653L53.671 42.9999Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M54.1038 40.8534C54.1038 40.5178 54.1507 40.2108 54.2426 39.9346C54.3345 39.6583 54.4713 39.4189 54.655 39.2204C54.8367 39.0219 55.0613 38.8664 55.3308 38.7579C55.5983 38.6495 55.9066 38.5942 56.2536 38.5942C56.5966 38.5942 56.9029 38.6495 57.1704 38.762C57.4378 38.8725 57.6644 39.028 57.8461 39.2286C58.0279 39.4271 58.1667 39.6665 58.2606 39.9427C58.3545 40.219 58.4015 40.5239 58.4015 40.8554C58.4015 41.1869 58.3545 41.4918 58.2606 41.7721C58.1667 42.0504 58.0279 42.2919 57.8461 42.4965C57.6644 42.7012 57.4399 42.8587 57.1744 42.9713C56.909 43.0838 56.6007 43.1411 56.2536 43.1411C55.9025 43.1411 55.5921 43.0859 55.3247 42.9754C55.0572 42.8649 54.8326 42.7073 54.653 42.5068C54.4733 42.3062 54.3365 42.0648 54.2446 41.7824C54.1507 41.4979 54.1038 41.1889 54.1038 40.8534ZM55.0205 40.8534C55.0205 41.0498 55.0429 41.2442 55.0858 41.4366C55.1307 41.6269 55.2001 41.7967 55.3002 41.9461C55.3982 42.0955 55.5248 42.2162 55.6799 42.3083C55.8351 42.4004 56.025 42.4474 56.2516 42.4474C56.4782 42.4474 56.6681 42.4004 56.8233 42.3083C56.9784 42.2162 57.105 42.0955 57.203 41.9461C57.301 41.7967 57.3725 41.6269 57.4174 41.4366C57.4623 41.2462 57.4827 41.0518 57.4827 40.8534C57.4827 40.661 57.4603 40.4707 57.4133 40.2845C57.3664 40.0983 57.2949 39.9305 57.1928 39.7811C57.0928 39.6317 56.9641 39.513 56.809 39.423C56.6538 39.333 56.468 39.2879 56.2496 39.2879C56.0311 39.2879 55.8453 39.333 55.6901 39.423C55.535 39.513 55.4084 39.6338 55.3063 39.7811C55.2063 39.9305 55.1328 40.0983 55.0858 40.2845C55.045 40.4707 55.0205 40.659 55.0205 40.8534Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M59.2059 38.6678H60.6126L61.656 41.9993H61.6682L62.7115 38.6678H64.1182V43.0633H63.277V39.4373H63.2648L62.0847 43.0633H61.2435L60.0634 39.4373L60.0512 39.4495V43.0633H59.21V38.6678H59.2059Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M66.3906 38.6678H67.3828L69.1039 43.0633H68.13L67.7523 42.0566H65.9741L65.5902 43.0633H64.6797L66.3906 38.6678ZM66.8744 39.5498H66.8622L66.2272 41.3628H67.5094L66.8744 39.5498Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M69.6266 38.6678H70.7066L72.3766 41.9542H72.3889V38.6678H73.23V43.0633H72.1561L70.4779 39.7769H70.4657V43.0633H69.6245V38.6678H69.6266Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M74.2061 38.6678H75.4066C75.7414 38.6678 76.0619 38.7006 76.3682 38.7661C76.6744 38.8315 76.9419 38.9461 77.1746 39.1119C77.4074 39.2776 77.5932 39.5027 77.73 39.7851C77.8688 40.0696 77.9382 40.4277 77.9382 40.8656C77.9382 41.3035 77.8688 41.6616 77.73 41.9461C77.5911 42.2305 77.4074 42.4535 77.1746 42.6193C76.9419 42.785 76.6724 42.8996 76.3682 42.9651C76.0619 43.0306 75.7414 43.0633 75.4066 43.0633H74.2061V38.6678ZM75.084 42.3696H75.574C75.7577 42.3696 75.9374 42.3369 76.1109 42.2694C76.2845 42.2018 76.4376 42.1036 76.5723 41.9767C76.7071 41.8478 76.8133 41.6923 76.8949 41.504C76.9766 41.3178 77.0174 41.105 77.0174 40.8656C77.0174 40.6262 76.9766 40.4134 76.8949 40.2271C76.8133 40.0409 76.705 39.8834 76.5723 39.7544C76.4376 39.6255 76.2845 39.5293 76.1109 39.4618C75.9374 39.3943 75.7577 39.3616 75.574 39.3616H75.084V42.3696Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M78.5261 40.8534C78.5261 40.5178 78.5731 40.2108 78.6649 39.9346C78.7568 39.6583 78.8936 39.4189 79.0774 39.2204C79.2591 39.0219 79.4837 38.8664 79.7532 38.7579C80.0206 38.6495 80.3289 38.5942 80.676 38.5942C81.019 38.5942 81.3253 38.6495 81.5927 38.762C81.8602 38.8725 82.0868 39.028 82.2685 39.2286C82.4502 39.4271 82.589 39.6665 82.683 39.9427C82.7769 40.219 82.8238 40.5239 82.8238 40.8554C82.8238 41.1869 82.7769 41.4918 82.683 41.7721C82.589 42.0504 82.4502 42.2919 82.2685 42.4965C82.0868 42.7012 81.8622 42.8587 81.5968 42.9713C81.3314 43.0838 81.0231 43.1411 80.676 43.1411C80.3248 43.1411 80.0145 43.0859 79.747 42.9754C79.4796 42.8649 79.255 42.7073 79.0753 42.5068C78.8957 42.3062 78.7589 42.0648 78.667 41.7824C78.5731 41.4979 78.5261 41.1889 78.5261 40.8534ZM79.4449 40.8534C79.4449 41.0498 79.4673 41.2442 79.5102 41.4366C79.5551 41.6269 79.6245 41.7967 79.7246 41.9461C79.8226 42.0955 79.9492 42.2162 80.1043 42.3083C80.2595 42.4004 80.4494 42.4474 80.676 42.4474C80.9026 42.4474 81.0925 42.4004 81.2477 42.3083C81.4028 42.2162 81.5294 42.0955 81.6274 41.9461C81.7254 41.7967 81.7969 41.6269 81.8418 41.4366C81.8867 41.2462 81.9071 41.0518 81.9071 40.8534C81.9071 40.661 81.8847 40.4707 81.8377 40.2845C81.7907 40.0983 81.7193 39.9305 81.6172 39.7811C81.5172 39.6317 81.3885 39.513 81.2334 39.423C81.0782 39.333 80.8924 39.2879 80.674 39.2879C80.4555 39.2879 80.2697 39.333 80.1145 39.423C79.9594 39.513 79.8328 39.6338 79.7307 39.7811C79.6307 39.9305 79.5572 40.0983 79.5102 40.2845C79.4673 40.4707 79.4449 40.659 79.4449 40.8534Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M85.3739 38.6678H86.5744C86.9092 38.6678 87.2298 38.7006 87.5361 38.7661C87.8423 38.8315 88.1098 38.9461 88.3425 39.1119C88.5753 39.2776 88.761 39.5027 88.8978 39.7851C89.0367 40.0696 89.1061 40.4277 89.1061 40.8656C89.1061 41.3035 89.0367 41.6616 88.8978 41.9461C88.759 42.2284 88.5753 42.4535 88.3425 42.6193C88.1098 42.785 87.8403 42.8996 87.5361 42.9651C87.2298 43.0306 86.9092 43.0633 86.5744 43.0633H85.3739V38.6678ZM86.2539 42.3696H86.7439C86.9276 42.3696 87.1073 42.3369 87.2808 42.2694C87.4544 42.2018 87.6075 42.1036 87.7423 41.9767C87.877 41.8478 87.9832 41.6923 88.0648 41.504C88.1465 41.3178 88.1873 41.105 88.1873 40.8656C88.1873 40.6262 88.1465 40.4134 88.0648 40.2271C87.9832 40.0409 87.875 39.8834 87.7423 39.7544C87.6075 39.6255 87.4544 39.5293 87.2808 39.4618C87.1073 39.3943 86.9276 39.3616 86.7439 39.3616H86.2539V42.3696Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M89.696 40.8534C89.696 40.5178 89.743 40.2108 89.8349 39.9346C89.9268 39.6583 90.0635 39.4189 90.2473 39.2204C90.429 39.0219 90.6536 38.8664 90.9231 38.7579C91.1905 38.6495 91.4988 38.5942 91.8459 38.5942C92.1889 38.5942 92.4952 38.6495 92.7626 38.762C93.0301 38.8725 93.2567 39.028 93.4384 39.2286C93.6201 39.4271 93.759 39.6665 93.8529 39.9427C93.9468 40.219 93.9938 40.5239 93.9938 40.8554C93.9938 41.1869 93.9468 41.4918 93.8529 41.7721C93.759 42.0504 93.6201 42.2919 93.4384 42.4965C93.2567 42.7012 93.0321 42.8587 92.7667 42.9713C92.5013 43.0838 92.193 43.1411 91.8459 43.1411C91.4948 43.1411 91.1844 43.0859 90.917 42.9754C90.6495 42.8649 90.4249 42.7073 90.2453 42.5068C90.0656 42.3062 89.9288 42.0648 89.8369 41.7824C89.741 41.4979 89.696 41.1889 89.696 40.8534ZM90.6128 40.8534C90.6128 41.0498 90.6352 41.2442 90.6781 41.4366C90.723 41.6269 90.7924 41.7967 90.8925 41.9461C90.9905 42.0955 91.117 42.2162 91.2722 42.3083C91.4274 42.4004 91.6173 42.4474 91.8439 42.4474C92.0705 42.4474 92.2604 42.4004 92.4155 42.3083C92.5707 42.2162 92.6973 42.0955 92.7953 41.9461C92.8933 41.7967 92.9647 41.6269 93.0097 41.4366C93.0546 41.2462 93.075 41.0518 93.075 40.8534C93.075 40.661 93.0525 40.4707 93.0056 40.2845C92.9586 40.0983 92.8872 39.9305 92.7851 39.7811C92.685 39.6317 92.5564 39.513 92.4013 39.423C92.2461 39.333 92.0603 39.2879 91.8418 39.2879C91.6234 39.2879 91.4376 39.333 91.2824 39.423C91.1273 39.513 91.0007 39.6338 90.8986 39.7811C90.7985 39.9305 90.7251 40.0983 90.6781 40.2845C90.6352 40.4707 90.6128 40.659 90.6128 40.8534Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M96.0968 38.6678H97.0401L98.165 42.1179H98.1773L99.29 38.6678H100.164L98.6877 43.0633H97.6076L96.0968 38.6678Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M101.523 38.6678H102.516L104.237 43.0633H103.263L102.885 42.0566H101.107L100.723 43.0633H99.8125L101.523 38.6678ZM102.007 39.5498H101.995L101.36 41.3628H102.642L102.007 39.5498Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M104.727 38.6678H105.676C105.911 38.6678 106.15 38.674 106.395 38.6842C106.64 38.6945 106.862 38.7354 107.061 38.807C107.259 38.8786 107.422 38.9953 107.551 39.1569C107.679 39.3186 107.742 39.5519 107.742 39.8588C107.742 39.9898 107.722 40.1126 107.679 40.2271C107.636 40.3417 107.577 40.4461 107.499 40.5361C107.422 40.6262 107.326 40.7019 107.214 40.7633C107.101 40.8247 106.975 40.8636 106.836 40.8799V40.8922C106.895 40.8963 106.948 40.9127 106.995 40.9434C107.044 40.972 107.087 41.0088 107.124 41.0539C107.161 41.0989 107.195 41.1459 107.224 41.1951C107.252 41.2462 107.279 41.2953 107.299 41.3465L108.004 43.0654H107.024L106.497 41.6923C106.464 41.6125 106.432 41.5429 106.399 41.4836C106.368 41.4242 106.33 41.3772 106.283 41.3424C106.236 41.3076 106.183 41.281 106.119 41.2646C106.056 41.2483 105.978 41.2401 105.887 41.2401H105.605V43.0654H104.725V38.6678H104.727ZM105.607 40.5443H105.895C106.003 40.5443 106.113 40.5382 106.225 40.5259C106.336 40.5136 106.436 40.487 106.524 40.4441C106.611 40.4031 106.683 40.3417 106.74 40.2619C106.797 40.1821 106.826 40.0737 106.826 39.9345C106.826 39.8036 106.797 39.7012 106.74 39.6235C106.683 39.5457 106.611 39.4884 106.524 39.4495C106.436 39.4127 106.336 39.3882 106.225 39.3779C106.115 39.3677 106.005 39.3616 105.895 39.3616H105.607V40.5443Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M108.641 38.6678H111.23V39.3595H109.521V40.442H111.085V41.1337H109.521V42.3676H111.242V43.0592H108.641V38.6678Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M111.762 42.2939C111.822 42.3144 111.883 42.3328 111.95 42.3471C112.018 42.3615 112.095 42.3696 112.183 42.3696C112.326 42.3696 112.436 42.3451 112.514 42.2939C112.591 42.2428 112.649 42.1793 112.683 42.1016C112.718 42.0238 112.74 41.9399 112.747 41.8499C112.753 41.7599 112.757 41.6739 112.757 41.5941V38.6659H113.637V42.0156C113.637 42.1323 113.616 42.2571 113.578 42.3881C113.537 42.519 113.471 42.6398 113.377 42.7503C113.284 42.8608 113.159 42.9528 113.008 43.0265C112.855 43.1002 112.665 43.137 112.44 43.137C112.318 43.137 112.208 43.1309 112.103 43.1206C112.001 43.1104 111.889 43.0879 111.767 43.0551V42.2939H111.762Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M114.364 40.8534C114.364 40.5178 114.41 40.2108 114.502 39.9346C114.594 39.6583 114.731 39.4189 114.915 39.2204C115.096 39.0219 115.321 38.8664 115.591 38.7579C115.858 38.6495 116.166 38.5942 116.513 38.5942C116.856 38.5942 117.163 38.6495 117.43 38.762C117.698 38.8725 117.924 39.028 118.106 39.2286C118.288 39.4271 118.426 39.6665 118.52 39.9427C118.614 40.219 118.661 40.5239 118.661 40.8554C118.661 41.1869 118.614 41.4918 118.52 41.7721C118.426 42.0504 118.288 42.2919 118.106 42.4965C117.924 42.7012 117.7 42.8587 117.434 42.9713C117.169 43.0838 116.86 43.1411 116.513 43.1411C116.162 43.1411 115.852 43.0859 115.584 42.9754C115.317 42.8649 115.092 42.7073 114.913 42.5068C114.733 42.3062 114.596 42.0648 114.504 41.7824C114.408 41.4979 114.364 41.1889 114.364 40.8534ZM115.28 40.8534C115.28 41.0498 115.303 41.2442 115.346 41.4366C115.39 41.6269 115.46 41.7967 115.56 41.9461C115.658 42.0955 115.785 42.2162 115.94 42.3083C116.095 42.4004 116.285 42.4474 116.511 42.4474C116.738 42.4474 116.928 42.4004 117.083 42.3083C117.238 42.2162 117.365 42.0955 117.463 41.9461C117.561 41.7967 117.632 41.6269 117.677 41.4366C117.722 41.2462 117.742 41.0518 117.742 40.8534C117.742 40.661 117.72 40.4707 117.673 40.2845C117.626 40.0983 117.555 39.9305 117.453 39.7811C117.353 39.6317 117.224 39.513 117.069 39.423C116.914 39.333 116.728 39.2879 116.509 39.2879C116.291 39.2879 116.105 39.333 115.95 39.423C115.795 39.513 115.668 39.6338 115.566 39.7811C115.466 39.9305 115.393 40.0983 115.346 40.2845C115.303 40.4707 115.28 40.659 115.28 40.8534Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M33.1277 0.117627C28.489 3.007 20.0631 9.1991 10.7184 20.5642C10.3549 21.0083 9.6465 20.8814 9.46071 20.3371C8.49908 17.5111 6.18179 10.523 5.22016 6.10714C5.05887 5.36433 4.01558 5.32545 3.8012 6.05393C2.1597 11.6342 -0.823159 23.7769 0.214007 35.4981C0.279341 36.2389 1.27363 36.4292 1.60234 35.7621C2.9478 33.0221 5.18955 28.5878 6.32471 27.0715C6.32471 27.0715 11.094 37.0124 14.081 41.3157C14.4587 41.86 15.306 41.6615 15.406 41.0067C16.239 35.5799 19.8997 18.1189 34.073 1.21649C34.6079 0.575997 33.8362 -0.324373 33.1277 0.117627Z"
                                    fill="#7B7B7B"
                                />
                                <path
                                    d="M33.1277 0.117675C28.4911 3.00705 20.0651 9.19709 10.7225 20.5581C10.357 21.0042 9.64651 20.8814 9.46072 20.3351C8.4991 17.5091 6.18384 10.5251 5.22222 6.11128C5.06093 5.36642 4.01559 5.32345 3.80121 6.05398C3.59092 6.77018 3.35613 7.59689 3.11317 8.51567C1.45942 14.7466 6.63097 27.7038 6.88618 28.2236C8.23368 30.9656 11.7168 37.9087 14.081 41.3158C14.4567 41.858 15.304 41.6575 15.4061 41.0047C16.2391 35.5759 19.8998 18.1189 34.0689 1.22063C34.6059 0.578088 33.8382 -0.324326 33.1277 0.117675Z"
                                    fill="#A1A1A1"
                                />
                            </svg>
                            <p>
                                Copyright © {new Date().getFullYear()}{' '}
                                <a
                                    href="https://www.nerus.com.br?utm_source=nerusweb&utm_campaign=neruso2"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Nérus
                                </a>
                                . Todos os direitos reservados.
                            </p>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(Logout)
