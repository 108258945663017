import Dashboard from './Dashboard'
import DashboardEdit from './Dashboard/DashboardEdit'
import Enotice from './Enotice'
import EnoticeCreate from './Enotice/EnoticeCreate'
import EnoticeLog from './Enotice/EnoticeLog'

export const routes = [
    {
        path: '/dashboard',
        exact: true,
        component: Dashboard,
    },
    {
        path: '/dashboard/:dashboard_id',
        exact: true,
        component: DashboardEdit,
    },
    {
        path: '/bin/:binario/dashboard',
        exact: true,
        component: Dashboard,
    },
    {
        path: '/bin/:binario/dashboard/:dashboard_id',
        exact: true,
        component: DashboardEdit,
    },
    {
        path: '/enotice',
        exact: true,
        component: Enotice,
    },
    {
        path: '/enotice/create',
        exact: true,
        component: EnoticeCreate,
    },
    {
        path: '/enotice/edit/:enotno',
        exact: true,
        component: EnoticeCreate,
    },
    {
        path: '/enotice/log',
        exact: true,
        component: EnoticeLog,
    },
    {
        path: '/enotice/log/:enotno',
        exact: true,
        component: EnoticeLog,
    },
]

export default routes

// For hot reloading of react components
// Webpack Hot Module Replacement API
if (module.hot) {
    module.hot.accept()
}
