import TextFieldInterface from './TextFieldInterface'

class Web extends TextFieldInterface {
    formatter(text) {
        let changed = ''
        // será que RegExp será melhor?
        for (let c = 0; c <= text.length; c++) {
            let code = text.charCodeAt(c)
            if (code >= 32 && code <= 127) {
                changed += text[c]
            }
        }
        return changed
    }
}

export default Web
