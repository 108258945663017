import PropTypes from 'prop-types'
import React, { Component } from 'react'
import PointTarget from 'react-point'

class Key extends Component {
    static propTypes = {
        onPress: PropTypes.func,
        className: PropTypes.string,
    }

    render() {
        const { onPress, className, ...rest } = this.props

        return (
            <PointTarget onPoint={onPress}>
                <button
                    className={`calculator-key ${className} calculator-button`}
                    {...rest}
                />
            </PointTarget>
        )
    }
}
export default Key
