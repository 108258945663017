import defaultsDeep from 'lodash/defaultsDeep'
import isString from 'lodash/isString'

import { makeHash } from '../EacReducer'
import EditorReducer from './Editor'
import FormularioReducer from './Formulario'
import Interface from './Interface'

export default class EditorForm extends Interface {
    static before({ state, action }) {
        // Separamos os campos para criar 2 componentes distintos
        const { editor, form } = action.payload
        // Criamos a estrutura do Editor principal (cima)
        const Editor = {
            name: 'Editor',
            payload: {
                id: editor.id,
                ...editor.data,
                ws: {
                    ...editor.ws,
                    data: editor.data,
                },
            },
        }

        // Criamos a estrutura do detalhe (baixo)
        const Formulario = {
            name: 'Formulario',
            payload: {
                id: form.id,
                ...form.data,
                flt: false,
                ws: {
                    ...form.ws,
                    data: form.data,
                },
            },
        }

        const checkIfEmpty = v => !isString(v)

        // Geramos um ID para ele
        const EditorId = checkIfEmpty(Editor.payload.id)
            ? makeHash(Editor)
            : Editor.payload.id
        // Geramos um ID para o Editor
        const FormularioId = checkIfEmpty(Formulario.payload.id)
            ? makeHash(Formulario)
            : Formulario.payload.id

        Editor.payload.id = EditorId
        Formulario.payload.id = FormularioId

        if (state.components[EditorId]) {
            Editor.payload = defaultsDeep(
                Editor.payload,
                state.components[EditorId].payload || {}
            )
        }

        if (state.components[FormularioId]) {
            Formulario.payload = defaultsDeep(
                Formulario.payload,
                state.components[FormularioId].payload || {}
            )
        }

        state.components[EditorId] = Editor
        state.components[FormularioId] = Formulario

        // Inserimos os IDs separados
        let editorReduced = EditorReducer.before({ action: Editor, state })
        let formReduced = FormularioReducer.before({
            action: Formulario,
            state,
        })

        state.components[EditorId] = editorReduced.action
        state.components[FormularioId] = formReduced.action

        editorReduced = EditorReducer.after({
            action: state.components[EditorId],
            state,
        })
        formReduced = FormularioReducer.after({
            action: state.components[FormularioId],
            state,
        })

        state.components[EditorId].payload.flt = false
        state.components[EditorId].payload.showTitle = false
        state.components[FormularioId] = { ...formReduced.action }
        state.components[EditorId] = { ...editorReduced.action }

        // Setamos que o Editor não é flutuante pois ele abriria outro modal
        action.payload.hasTripleId = true
        action.payload.editor.data.flt = false
        action.payload.editor.id = EditorId
        action.payload.form.id = FormularioId

        action.payload.flt =
            action.payload.flt === undefined ? true : action.payload.flt

        return { state, action }
    }

    static buildDefaultsDeepSource(payload) {
        return EditorReducer.buildDefaultsDeepSource(payload.editor.data)
    }
}
