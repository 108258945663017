import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import { Input } from '@nerus/framework/styled/Input'
import { styles } from '@nerus/styles/components/messageWindow'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import normalizeKeyForReactStyle from '../../../../util/normalizeKeyForReactStyle'
import { getMessageWindow } from '../../Eac/EacReducer'

// const debug = require('debug')('nerus:messageWindow')

export class MessageWindow extends EnhancedComponent {
    static mapStateToProps = (state, ownProps) => {
        return {
            data: getMessageWindow(state) || ownProps.data,
        }
    }

    static propTypes = {
        data: PropTypes.object,
    }

    // shouldComponentUpdate() {}

    render() {
        const { data, classes } = this.props

        const { title, width = 150, height = 150, x, y, fieldList } = data

        const fields = fieldList.map(({ label, value, row, attributes }) => {
            let style = {}
            const styles = attributes.split(/;/)
            // 'font-color: #2d42bb; font-weight: bold' => ['font-color: #2d42bb', 'font-weight: bold']

            for (const customStyle of styles) {
                const [key, value] = customStyle?.trim().split(/:/)

                style[normalizeKeyForReactStyle(key)] = value?.trim()
            }

            return (
                <Input
                    key={row}
                    index={row}
                    componentId={row}
                    inputWidth={Number(width)}
                    labelWidth={100}
                    label={label}
                    value={value}
                    InputProps={{ style }}
                    // InputLabelProps={{ style }}
                    disabled
                />
            )
        })

        return (
            <div
                className={classes.root}
                tabIndex="-1"
                style={{
                    top: 0,
                    left: 0,
                    marginTop: y,
                    marginLeft: x,
                    minWidth: Number(width) || 150,
                    minHeight: Number(height),
                }}
            >
                <div className={classes.container}>
                    <div className={classes.header}>
                        <Typography variant={'h3'} className={classes.title}>
                            <span>{title}</span>
                        </Typography>
                    </div>

                    <div className={classes.content}>{fields}</div>
                </div>
            </div>
        )
    }
}

export default connect(MessageWindow.mapStateToProps)(
    withStyles(styles)(MessageWindow)
)
