/**
 * Root Reducer
 */

// Import Reducers
import app from './modules/App/AppReducer'
import eac from './modules/NerusWeb/Eac/EacReducer'

const combineReducers = reducers => (state, action) =>
    Object.keys(reducers).reduce(
        (reduced, name) => ({
            ...reduced,
            [name]: reducers[name](state[name], action, state),
        }),
        {}
    )

const reducers = {
    app,
    eac,
}

const appReducer = combineReducers(reducers)

// Combine all reducers into one root reducer
const rootReducer = (state, action) => {
    return appReducer(state, action)
}

export default rootReducer
