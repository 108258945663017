import withStyles from '@material-ui/core/styles/withStyles'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import StyledButton from '@nerus/framework/styled/Button'
import { styles } from '@nerus/styles/components/formulario'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { formatTooltip } from '../../../Business/Keyboard'
import { withWS } from '../../../Business/Websocket/Context'
import { sendBuffer } from '../../../Eac/EacActions'
import { getBuffer } from '../../../Eac/EacReducer'
import TextFieldSkip from './Recs/Skip'

class Actions extends EnhancedComponent {
    static mapStateToProps = state => {
        return {
            wsStop: state.app.wsStop,
            buffer: getBuffer(state),
        }
    }

    static propTypes = {
        wsStop: PropTypes.bool,
        classes: PropTypes.object,
        buffer: PropTypes.object,
        data: PropTypes.object,
        active: PropTypes.bool,
        activeValue: PropTypes.any,
        dispatch: PropTypes.func,
        align: PropTypes.oneOf(['right', 'left']),
        ws: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            className: ['action'],
        }
    }

    shouldComponentUpdate(nextProps) {
        return this.checkProps(['data'], nextProps)
    }

    encodeEntity(str) {
        return str.toString().replace(/ /g, '&nbsp')
    }

    handleClick = (button, data, activeValue, active) => () => {
        if (!active) {
            return
        }

        const buffer = {
            x: data.x,
            y: data.y,
            key: button.key,
            value: activeValue.current,
        }

        if (!activeValue.current || !data.enabled) {
            delete buffer.x
            delete buffer.y
            delete buffer.value
        }

        this.props.ws.send(sendBuffer(buffer, 'sendEdit'))
    }

    render() {
        const {
            align,
            wsStop,
            buffer,
            data,
            active,
            activeValue,
            classes,
        } = this.props

        let steps = []
        if (
            wsStop &&
            buffer.last &&
            buffer.last.length > 0 &&
            steps[steps.length - 1] !== buffer.last[0]
        ) {
            buffer.last.forEach(
                (el => {
                    steps.push(this.encodeEntity(el))
                }).bind(this)
            )

            this.setState({ steps })
        }

        return (
            <div
                className={clsx(classes.actions, {
                    [classes[align]]: align,
                })}
            >
                {data && data.bt
                    ? data.bt.map((button, i) => {
                          const { text, key, ...props } = button
                          let tooltip = text
                          let label = ''

                          // fallback para uma renderização amigavel
                          if (tooltip.indexOf(':') > -1) {
                              ;[label, tooltip] = tooltip.split(':')
                          }

                          const renderedLabel = (
                              <TextFieldSkip
                                  key={'action-label'}
                                  lbl={`${label || tooltip}:`}
                                  isTitle
                                  inline
                              />
                          )

                          const renderedButton = (
                              <StyledButton
                                  onClick={this.handleClick(
                                      button,
                                      data,
                                      activeValue,
                                      active
                                  )}
                                  key={'btn-' + i}
                                  type="button"
                                  tab
                                  border="none"
                                  color="default"
                                  {...props}
                                  active={active}
                              >
                                  {formatTooltip(tooltip)}
                              </StyledButton>
                          )

                          if (label) {
                              return [renderedLabel, renderedButton]
                          }

                          if (key === undefined || key === false) {
                              return renderedLabel
                          }

                          return renderedButton
                      })
                    : null}
                {wsStop && steps.length ? (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `"${steps.join('", "')}"`,
                        }}
                    />
                ) : null}
            </div>
        )
    }
}

export default connect(Actions.mapStateToProps)(
    withStyles(styles)(withWS(Actions))
)
