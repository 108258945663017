import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class Header extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        classes: PropTypes.object.isRequired,
        activeColumnSearch: PropTypes.number,
        types: PropTypes.array,
        draggable: PropTypes.bool,
        isGuia: PropTypes.bool,
    }

    getStyles = index => {
        const { types } = this.props
        const type = types[index]

        const total = types.reduce((a, b) => {
            return a + b.sz
        }, 0)
        const percentages = types.map(t => `${(t.sz / total) * 100}%`)

        const styles = {}
        if (type) {
            switch (type.type) {
                case 'GR_DOUBLE':
                case 'GR_DQTTY':
                case 'GR_QTTY':
                case 'GR_PERC':
                case 'GR_MONEY': {
                    styles.textAlign = 'right'
                    styles.width = percentages[index]
                    break
                }
                case 'GR_STRING':
                default: {
                    styles.width = percentages[index]
                    // do nothing
                    break
                }
            }
        }

        return styles
    }

    render() {
        const {
            data,
            activeColumnSearch,
            classes,
            draggable,
            isGuia,
        } = this.props

        if (!data || !data.length) {
            return null
        }

        return (
            <thead>
                <tr>
                    {draggable ? (
                        <th
                            className={clsx({
                                [classes.tableHeader]: true,
                                [classes.tableHeaderGuia]: isGuia,
                            })}
                        >
                            &nbsp;
                        </th>
                    ) : (
                        undefined
                    )}
                    {data.length ? (
                        data.map((object, i) => {
                            const style = this.getStyles(i)
                            return (
                                <th
                                    className={clsx({
                                        [classes.tableHeader]: true,
                                        [classes.tableHeaderGuia]: isGuia,
                                        [classes.tableInSearch]:
                                            activeColumnSearch === i,
                                    })}
                                    key={`th-${i}-${object}`}
                                    style={style}
                                >
                                    {object || ''}
                                </th>
                            )
                        })
                    ) : (
                        <th />
                    )}
                </tr>
            </thead>
        )
    }
}
