export const orderByOrderAndTitle = (a, b) =>
    a?.order > b?.order
        ? 1
        : a?.order == b?.order
        ? a?.title.toLowerCase() < b?.title.toLowerCase()
            ? -1
            : a?.title.toLowerCase() > b?.title.toLowerCase()
            ? 1
            : 0
        : -1
