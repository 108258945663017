import config from '@nerus/config'
import Flutuante from '@nerus/framework/components/Base/Flutuante'
import TextFieldPopupMenu from '@nerus/framework/components/Formulario/Estrutura/Recs/PopupMenu'
import axios from 'axios'
import compareVersions from 'compare-versions'
import * as PropTypes from 'prop-types'
import React from 'react'
import { connect, ReactReduxContext } from 'react-redux'
import { withRouter } from 'react-router'

import packageJson from '../../../../../package.json'
import localStorage from '../../../../util/localStorage'
import Components from '../../Components'
import DllUpdate from '../../Components/EditorFormEditPdv/DllUpdate'
import PdvServiceManager, {
    pdvManager as wsPdvManager,
} from '../../Components/EditorFormEditPdv/PdvServiceManager'
import {
    addComponent,
    clearFingerprint,
    connect as wsConnect,
    dialogoProgressao,
    disconnect as wsDisconnect,
    messageWindow,
    fingerprint,
    login,
    loginEnd,
    nerusInfo,
    o2,
    removeComponent,
    resetComponents,
    tooMuchRetries,
    updateComponent,
} from '../../Eac/EacActions'
import {
    getActiveComponent,
    removeSession,
    updateSession,
} from '../../Eac/EacReducer'
import { WaitingContext, WebsocketContext } from './Context'
import WebsocketManager, { wsManager } from './Manager'
import SessionHandler from './SessionHandler'

const debug = require('debug')('nerus:ws')

const regWs = new RegExp(/((ws[s]?):\/\/)|(\/ws)/gim)

function transformComponent(comp) {
    return {
        id: comp.id,
        ws: {
            retType: comp.retType,
            data: null,
        },
        // normaliza o flt
        flt: comp.flt === 'undefined' ? true : comp.flt,
        ...comp.data,
    }
}

function checkMinimumVersion(that, ni) {
    const versionInfo = /(\d+\.\d+\.\d+)/.exec(ni.v)[0]
    const minimumVersion = that.state.MINIMUM_VERSION
    const isLogged = that.props.isLogged

    if (
        packageJson.version !== '0.0.0' &&
        (!isLogged || versionInfo !== minimumVersion) &&
        compareVersions(minimumVersion, versionInfo) > 0
    ) {
        that.props.dispatch(
            addComponent('Dialogo', {
                msg: `Para execução da versão do cliente ${packageJson.version} é necessário que o
                Nérus esteja atualizado até a release V${that.state.MINIMUM_VERSION}`,
                dontClean: true,
                optType: -1,
            })
        )
    }
}

const connector = WrappedComponent => {
    class Websocket extends SessionHandler {
        static propTypes = {
            ...SessionHandler.propTypes,
            isLogin: PropTypes.bool,
            isLogout: PropTypes.bool,
            isTooMuchRetries: PropTypes.bool,
            alreadyConnected: PropTypes.bool,
            wsStop: PropTypes.bool,
            activeComponent: PropTypes.object,
        }

        static mapStateToProps = state => {
            return {
                isLogin: state.eac.isLogin,
                isLogged: state.eac.isLogged,
                isLogout: state.eac.isLogout,
                isTooMuchRetries: state.eac.isTooMuchRetries,
                session: state.eac.session,
                wsStop: state.app.wsStop,
                activeComponent: getActiveComponent(state),
            }
        }

        static events = {
            /**
             * Quando conectamos, esse evento é lançado.
             *
             * Responsável pelo handshake com o WS e definir que estamos conectados
             */
            connected({ ws }) {
                const { session } = this.props

                const { session_id, type, bin, config, perfil } = session || {}

                if (!session_id || !type) {
                    alert('Não foi possível recuperar esta sessão.')
                    return
                }

                localStorage.session_id = session_id
                if (
                    bin !== 'nerus_pvECF' &&
                    bin !== 'nerus_pvNFCE' &&
                    bin !== 'nerus_pv' &&
                    bin !== 'nerus_o2' &&
                    bin !== 'nerusweb'
                ) {
                    alert('Binário não suportado.')
                    return
                }

                const handshake = {
                    session_id,
                    program: bin,
                    type,
                    param1: '0',
                    param2: `--nerusResolucao=${window.screen.width}x${window.screen.height}`,
                    param3: `--versaoCliente=${packageJson.version}`,
                }

                let tmps = {}

                if (
                    (bin.toLowerCase() === 'nerus_pvecf' ||
                        bin.toLowerCase() === 'nerus_pvnfce') &&
                    wsManager.current.perfil
                ) {
                    tmps = {
                        param1: '0',
                        param2: wsManager.current.perfil,
                        param3: handshake.param2,
                        param4: handshake.param3,
                    }
                } else if (bin.toLowerCase() === 'nerus_pv') {
                    if (perfil) {
                        tmps = {
                            param1: '0',
                            param2: perfil.replace(/\.-/g, ''),
                            param3: handshake.param2,
                            param4: handshake.param3,
                        }
                    } else if (config) {
                        tmps = {
                            param1: '0',
                            param2: '-c',
                            param3: handshake.param2,
                            param4: handshake.param3,
                        }
                    } else {
                        tmps = {
                            param1: '0',
                            param2: handshake.param2,
                            param3: handshake.param3,
                        }
                    }
                }

                // Envia a mensagem de conexão para abrir o MenuPrincipal
                ws.send(Object.assign(handshake, tmps))

                this.props.dispatch(wsConnect())
            },

            /**
             * Atualiza a referência do waiting para corrigir atualizações
             */
            STATE_CHANGE({ state }) {
                const waiting = state === WebsocketManager.WAITING
                if (waiting !== this.state.waiting) {
                    this.setState({
                        waiting,
                    })
                    debug(`STATE_CHANGE:waiting`, this.state.waiting)
                }

                const { dispatch } = this.props
                if (state === WebsocketManager.DEAD) {
                    dispatch(tooMuchRetries())
                }
            },

            /**
             * Recebe as informações do Nerus/Saci e usuário logado
             */
            nerusInfo({ data }) {
                const {
                    dispatch,
                    session: { session_id },
                } = this.props
                const ni = data || {}
                dispatch(nerusInfo(ni))
                updateSession(session_id, session => ({
                    ...session,
                    nerusInfo: {
                        ...ni,
                        sessionTime: ni.sessionTime || 28800,
                    },
                }))

                checkMinimumVersion(this, ni)
            },

            /**
             * ignora
             */
            DLL() {},

            /**
             * Exibe interface para colher fingerprint
             */
            Fingerprint(payload) {
                this.props.dispatch(fingerprint(payload.data))
            },

            /**
             * Fecha a interface para colher fingerprint
             */
            FingerprintClose() {
                this.props.dispatch(clearFingerprint())
            },

            /**
             * Recebe a mensagem que nos avisa para ir para interface de login
             * Atualmente como temos uma interface estatica e só conectamos
             * depois que temos os dados de acesso, não temos muito uso
             */
            Login({ data }) {
                const parts =
                    window.location.pathname.substring(1).split('/') || []
                const module = parts.shift()
                if (module && module !== 'bin') {
                    this.props.history.push('/')
                }

                this.props.dispatch(login(data))
            },

            /**
             * Modifica o estado da aplicação para informar que passamos pelo Login
             * com sucesso.
             */
            LoginEnd() {
                const {
                    dispatch,
                    session: { session_id },
                } = this.props
                dispatch(loginEnd())
                updateSession(session_id, session => ({
                    ...session,
                    loggedIn: true,
                }))
            },

            /**
             * Trata o DialogoProgressao de forma diferente dos outros componentes
             * usamos isso para evitar ele ficar sobreposto pelo loading, forçando
             * ele a ficar por cima de todas as janelas
             */
            DialogoProgressao({ data }) {
                this.props.dispatch(dialogoProgressao(data))
            },

            /**
             * Trata o MessageWindow de forma diferente dos outros componentes
             * usamos isso para evitar ele ficar sobreposto pelo loading, forçando
             * ele a ficar por cima de todas as janelas
             */
            MessageWindow({ data }) {
                this.props.dispatch(messageWindow(data))
            },
            /**
             * Remove componentes da stack
             *
             * Substitui:
             *  - RelatorioDialogEnd
             *  - CloseDialogoProgressao
             *  - CloseMessageWindow
             *  - FechaHint
             *
             *  A regra é verificar se possui 'End' || 'Close' || 'Fecha' no type
             *  caso possua, removemos e usamos como nome do componente a ser removido
             */
            ComponentClose({ data: { component, id } }) {
                this.props.dispatch(removeComponent(component, id))
            },

            /**
             * Atualiza componentes existentes na stack
             *
             * O conteudo da atualização pode ser parcial
             */
            ComponentUpdate({ data }) {
                const comp = data.atualiza
                this.props.dispatch(
                    updateComponent(comp.type, transformComponent(comp))
                )
            },

            /**
             * Insere componentes na stack ou atualiza componentes existentes
             *
             * Por definição, esse evento só recebe componentes inteiros
             */
            ComponentInsert(type) {
                return function({ data }) {
                    this.props.dispatch(
                        addComponent(type, {
                            id: data.id,
                            ws: {
                                retType: data.retType,
                                data: null,
                            },
                            ...data,
                        })
                    )
                }
            },

            /**
             * Abre o O2
             */
            AbrePainelO2() {
                this.props.dispatch(o2(true))
            },

            /**
             * Fecha o O2
             */
            FechaPainelO2() {
                this.props.dispatch(o2(false))
            },

            /**
             * Quando o WS vai ser desligado para manutenção ou efetuamos um
             * logout, ele envia o shutdown que nos permite resetar tudo e remover
             * a sessão do browser
             */
            shutdown() {
                const {
                    session: { session_id },
                    dispatch,
                } = this.props

                removeSession(session_id)
                dispatch(resetComponents(true))
                dispatch(wsDisconnect())

                const parts = window.location.pathname.split('/') || []
                const module = parts.shift()
                if (module && module !== 'bin') {
                    this.props.history.push('/')
                }
            },
            closed() {
                const { dispatch } = this.props
                dispatch(wsDisconnect())
            },
        }

        state = {
            MINIMUM_VERSION: '4.0.37',
            MINIMAL_CACHED_VERSION: '',
            waiting: false,
            showSelect: false,
            dll: false,
        }

        constructor(props) {
            super(props)

            this.bindedEvents = {}
            Object.keys(Websocket.events).forEach(event => {
                this.bindedEvents[event] = Websocket.events[event].bind(this)
            })

            this.componentEvents = {}
            Object.keys(Components).forEach(component => {
                if (!Websocket.events[component]) {
                    this.componentEvents[
                        component
                    ] = Websocket.events.ComponentInsert(component).bind(this)
                }
            })
        }

        componentWillUnmount() {
            super.componentWillUnmount && super.componentWillUnmount()

            // Faz unbind dos eventos que tratamos
            Object.keys(this.bindedEvents).forEach(event => {
                wsManager.current.off(event, this.bindedEvents[event])
            })

            // Faz unbind dos elementos que foram implementados
            Object.keys(this.componentEvents).forEach(component => {
                wsManager.current.off(
                    component,
                    this.componentEvents[component]
                )
            })

            const wsPdv = wsPdvManager.current || false
            if (wsPdv) {
                wsPdv.off('message', this.onPdvMessage)
                wsPdv.off('ERROR', this.onPdvError)
            }
        }

        onPdvError = err => {
            if (!err.wasClean) {
                this.props.dispatch(
                    addComponent('Dialogo', {
                        msg: `Para acessar o sistema você precisar estar rodando o Nérus Web Service, verifique se o mesmo está rodando ou entre em contato com suporte.`,
                        dontClean: true,
                        optType: -1,
                    })
                )
            }
        }

        perfil = null
        dlls = null
        onPdvMessage = message => {
            const { session } = this.props

            const bin = (session && session.bin) || 'nerusweb'

            const wsURI = config.WS.ENDPOINT?.replace(regWs, '')
            const windowURI = window.location.host

            const uri = `${window.location.protocol}//${wsURI || windowURI}`

            if (message.type === 'handshake') {
                this.setState({ dll: true })
                axios
                    .post(`${uri}/nerus/uuid`, {
                        uuid: message.uid,
                        bin,
                    })
                    .then(resp => {
                        this.dlls = resp.data.data.dlls
                        this.perfil = resp.data.data.perfil

                        // garante que o perfil seja resetado
                        wsManager.current.perfil = null

                        if (this.perfil.length === 1) {
                            wsManager.current.perfil = this.perfil[0].perfil
                        } else if (this.perfil.length > 1) {
                            this.setState({
                                showSelect: this.perfil.map(
                                    ({ perfil }) => perfil
                                ),
                            })
                        } else if (this.perfil.length === 0) {
                            this.props.dispatch(
                                addComponent('Dialogo', {
                                    msg: `Você não possui um perfil vinculado a sua estação. Entre em contato com seu administrador.`,
                                    dontClean: true,
                                    optType: -1,
                                })
                            )
                        }

                        const checkForUpdates =
                            (this.perfil?.length === 1 &&
                                this.perfil[0].dllupdate) ||
                            false
                        wsPdvManager.current.send({
                            type: 'handshake',
                            endpoint: `${uri}/nerus/dll?file=`,
                            dlls: checkForUpdates ? this.dlls : [],
                        })
                    })
                    .catch(() => {
                        this.props.dispatch(
                            addComponent('Dialogo', {
                                msg: `Você não possui um perfil vinculado a sua estação. Entre em contato com seu administrador.`,
                                dontClean: true,
                                optType: -1,
                            })
                        )
                    })
            }

            if (message.type === 'connected') {
                if (wsManager.current.perfil) {
                    wsManager.current.connect(config.WS.ENDPOINT)
                }
            }
        }

        componentDidUpdate(prevProps) {
            // reconecta após entrar novamente na tela de login
            if (!prevProps.isLogin && this.props.isLogin) {
                this.componentDidMount()
            }
        }

        componentDidMount() {
            super.componentDidMount()

            // Faz bind dos eventos que tratamos
            Object.keys(this.bindedEvents).forEach(event => {
                wsManager.current.off(event, this.bindedEvents[event])
                wsManager.current.on(event, this.bindedEvents[event])
            })

            // Faz bind dos elementos que foram implementados
            Object.keys(this.componentEvents).forEach(component => {
                wsManager.current.off(
                    component,
                    this.componentEvents[component]
                )
                wsManager.current.on(component, this.componentEvents[component])
            })

            if (!wsPdvManager.current) {
                wsPdvManager.current = new PdvServiceManager()
            }

            const { session, isLogout } = this.props
            if (config.WS.ENDPOINT && !isLogout) {
                if (session) {
                    if (
                        session.bin === 'nerusweb' ||
                        session.bin === 'nerus_o2' ||
                        session.bin === 'nerus_pv' ||
                        wsManager.current.perfil
                    ) {
                        wsManager.current.connect(config.WS.ENDPOINT)
                    } else {
                        const wsPdv = wsPdvManager.current || false
                        if (wsPdv) {
                            wsPdv.off('message', this.onPdvMessage)
                            wsPdv.off('ERROR', this.onPdvError)
                            wsPdv.on('message', this.onPdvMessage)
                            wsPdv.on('ERROR', this.onPdvError)
                        }

                        if (!wsPdv.isConnected()) {
                            wsPdv.connect()
                        }
                    }
                } else {
                    this.setState({
                        alreadyConnected: true,
                    })
                }
            }
        }

        handleProfileSelect = value => {
            wsManager.current.perfil = this.state.showSelect[value]
            const hasPerfil = this.perfil.find(
                p => p.perfil === wsManager.current.perfil
            )
            if (hasPerfil) {
                const wsURI = config.WS.ENDPOINT?.replace(regWs, '')
                const windowURI = window.location.host

                const uri = `${window.location.protocol}//${wsURI || windowURI}`

                wsPdvManager.current.send({
                    type: 'handshake',
                    endpoint: `${uri}/nerus/dll?file=`,
                    dlls: hasPerfil.dllupdate ? this.dlls : [],
                })
            } else {
                wsManager.current.connect(config.WS.ENDPOINT)
            }
            this.setState({
                showSelect: false,
            })
        }

        reduxConsumer = store => {
            wsManager.current.redux = store
            const { showSelect, dll } = this.state
            return (
                <WaitingContext.Provider value={this.state.waiting}>
                    <WebsocketContext.Provider value={wsManager}>
                        <WrappedComponent ws={wsManager.current}>
                            {this.props.children}
                        </WrappedComponent>
                        {dll ? <DllUpdate /> : null}
                        {showSelect ? (
                            <Flutuante title="Selecione o terminal">
                                <TextFieldPopupMenu
                                    enabled
                                    open
                                    lbl="Perfis disponíveis"
                                    onChange={this.handleProfileSelect}
                                    options={{
                                        data: {
                                            strList: showSelect.map(
                                                (perfil, index) =>
                                                    `${index}. ${perfil}`
                                            ),
                                            shtct: Object.keys(showSelect),
                                        },
                                    }}
                                />
                            </Flutuante>
                        ) : null}
                    </WebsocketContext.Provider>
                </WaitingContext.Provider>
            )
        }

        render() {
            return (
                <ReactReduxContext.Consumer>
                    {this.reduxConsumer}
                </ReactReduxContext.Consumer>
            )
        }
    }

    return connect(Websocket.mapStateToProps)(withRouter(Websocket))
}

/**
 * Um HOC (High Order Component) que realiza as interações
 * com o WebSocket da Nérus
 *
 * Trata os buffers e tratamento das mensagens do WS
 */
export default function WebsocketHandle(WrappedComponent) {
    return connector(WrappedComponent)
}
