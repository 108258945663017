import TextFieldInterface from './TextFieldInterface'

class CaseChange extends TextFieldInterface {
    upper = false
    lower = false
    jump = true
    toEnd = false

    formatter(text) {
        if (this.upper) {
            return text.toUpperCase()
        }

        if (this.lower) {
            return text.toLowerCase()
        }

        return text
    }

    fromEnd() {
        this.toEnd = true
        return this
    }

    uppercase() {
        this.upper = true
        return this
    }

    lowercase() {
        this.lower = true
        return this
    }

    shouldJump(text) {
        return this.jump && text.length === this.mask.sz
    }
}

export default CaseChange
