import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/styles/makeStyles'
import keycodes from '@nerus/framework/common/Keycodes'
import IconButton from '@nerus/framework/components/Editor/Estrutura/IconButton'
import Tr from '@nerus/framework/components/Editor/Estrutura/Tr'
import { styles } from '@nerus/styles/components/editor'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

const useStyles = makeStyles(styles)

export default function EditorFormEditBody({
    active = true,
    columns,
    onInsert,
    onRemove,
    onDoubleClick,
    rows = [],
}) {
    const classes = useStyles()
    const inputRef = useRef()

    useEffect(() => {
        if (active) {
            if (inputRef.current) inputRef.current.focus()
        }
    }, [active, inputRef])

    const cols = useMemo(() => {
        const total = columns.reduce((a, b) => {
            return a + b.size
        }, 0)

        columns.map(col => {
            col.style = { width: `${(col.size / total) * 100}%` }
        })

        return columns
    }, [columns])

    const handleOnDoubleClick = useCallback(
        row => () => {
            onDoubleClick && onDoubleClick(row)
        },
        [onDoubleClick]
    )

    const handleOnRemove = useCallback(
        row => () => {
            onRemove && onRemove(row)
        },
        [onRemove]
    )

    const handleOnInsert = useCallback(
        event => {
            if (event.defaultPrevented) {
                return
            }

            event.stopPropagation()
            if (event.keyCode === keycodes.ENTER_KEY) {
                onInsert && onInsert(event.target?.value)
                event.target.value = ''
            } else if (event.keyCode === keycodes.ESCAPE_KEY) {
                onInsert && onInsert(undefined, true)
                event.target.value = ''
            } else if (
                event.keyCode >= keycodes.F1_KEY &&
                event.keyCode <= keycodes.F12_KEY
            ) {
                onInsert && onInsert(event.target?.value, false, event.keyCode)
                event.target.value = ''
            }
        },
        [onInsert]
    )

    const triggerFocus = useCallback(
        event => {
            if (active) {
                event.target.focus()
            }
        },
        [active]
    )

    return (
        <tbody>
            {rows.map((row, r) => {
                return (
                    <Tr
                        key={`tr-${r}`}
                        className={clsx({
                            [classes.tableRowColored]: r % 2 === 0,
                            [classes.tableRowHoverNormal]: true,
                            [classes.tableRow]: true,
                        })}
                        onDoubleClick={handleOnDoubleClick(row)}
                    >
                        {cols.map(col => (
                            <td
                                className={clsx({
                                    [classes.tableBody]: true,
                                })}
                                key={'td' + col.label}
                                style={col.style}
                            >
                                {col.key ? row[col.key] : '-'}
                            </td>
                        ))}
                        {onRemove ? (
                            <td
                                className={clsx({
                                    [classes.tableBody]: true,
                                })}
                                key={`td-remove-${r}`}
                            >
                                <IconButton
                                    size="small"
                                    className={classes.icon}
                                    tooltip="Retirar"
                                    onClick={handleOnRemove(row)}
                                />
                            </td>
                        ) : null}
                    </Tr>
                )
            })}
            {onInsert ? (
                <tr
                    key={`tr-insert`}
                    className={clsx({
                        [classes.tableRowHoverNormal]: true,
                        [classes.tableRow]: true,
                    })}
                >
                    {cols.map((col, c) => {
                        return (
                            <td
                                className={clsx({
                                    [classes.tableBody]: true,
                                })}
                                key={'td-insert-' + col.label}
                                style={col.style}
                            >
                                {c > 0 ? (
                                    ''
                                ) : (
                                    <TextField
                                        inputRef={inputRef}
                                        autoFocus
                                        disabled={!active}
                                        InputProps={{
                                            classes: {
                                                input: classes.inputInsertEdit,
                                            },
                                        }}
                                        onKeyDown={handleOnInsert}
                                        onBlur={triggerFocus}
                                    />
                                )}
                            </td>
                        )
                    })}
                    {onRemove ? (
                        <td
                            className={clsx({
                                [classes.tableBody]: true,
                            })}
                            key={'td-emptyaction'}
                        />
                    ) : null}
                </tr>
            ) : null}
        </tbody>
    )
}

EditorFormEditBody.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            size: PropTypes.number,
            key: PropTypes.string,
        })
    ),
    rows: PropTypes.arrayOf(PropTypes.any),
    onDoubleClick: PropTypes.func,
    onInsert: PropTypes.func,
    onRemove: PropTypes.func,
    active: PropTypes.bool,
}
