import keycodes from '@nerus/framework/common/Keycodes'

import { sendBuffer } from '../EacActions'
import Interface from './Interface'

export function handleKeyboard(ws) {
    return (dispatch, o, data) => {
        switch (o.keyCode) {
            case keycodes.F3_KEY:
            case keycodes.F4_KEY:
                ws.send(
                    sendBuffer(
                        {
                            key: o.keyCode,
                            value: data.ws.data.value,
                            option: data.ws.data.option,
                        },
                        data.ws.data.retType
                    )
                )

                break
            case keycodes.F2_KEY:
            case keycodes.F9_KEY:
            case keycodes.ESCAPE_KEY: {
                ws.send(sendBuffer(o.keyCode))
                return
            }
        }
    }
}

export default class ListaSelecao extends Interface {
    static before({ state, action }) {
        action.payload.ws.data = {
            value: action.payload.list.map(item => {
                return item.enabled === true ? 1 : 0
            }),
            option: action.payload.option,
        }

        action.payload.disableKeyboard = true
        action.payload.keyboardActions = handleKeyboard
        action.payload.data = action.payload.list

        delete action.payload.list

        return { state, action }
    }
}
