import keycodes from '@nerus/framework/common/Keycodes'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { withWS } from '../../../Business/Websocket/Context'
import { sendBuffer } from '../../../Eac/EacActions'
import IconButton from './IconButton'

class Pagination extends Component {
    static propTypes = {
        active: PropTypes.bool,
        classes: PropTypes.object,
        dispatch: PropTypes.func,
        ws: PropTypes.object.isRequired,
    }

    sendBuffer = keyCode => () => this.props.ws.send(sendBuffer(keyCode))

    render() {
        const { classes, active } = this.props
        return (
            <div className={classes.pagination}>
                <IconButton
                    disabled={!active}
                    tooltip="Primeiro"
                    onClick={this.sendBuffer(keycodes.HOME_KEY)}
                />
                <IconButton
                    disabled={!active}
                    tooltip="PgUp"
                    onClick={this.sendBuffer(keycodes.PAGE_UP_KEY)}
                />
                <IconButton
                    disabled={!active}
                    tooltip="Anterior"
                    onClick={this.sendBuffer(keycodes.UP_ARROW_KEY)}
                />
                <IconButton
                    disabled={!active}
                    tooltip="Próximo"
                    onClick={this.sendBuffer(keycodes.DOWN_ARROW_KEY)}
                />
                <IconButton
                    disabled={!active}
                    tooltip="PgDn"
                    onClick={this.sendBuffer(keycodes.PAGE_DOWN_KEY)}
                />
                <IconButton
                    disabled={!active}
                    tooltip="Último"
                    onClick={this.sendBuffer(keycodes.END_KEY)}
                />
            </div>
        )
    }
}

export default connect()(withWS(Pagination))
