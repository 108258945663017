import moment from 'moment/moment'
import * as PropTypes from 'prop-types'
import React from 'react'

import keycodes from '../../../../common/Keycodes'
import { StyledKeyboardTimePicker } from '../../../../styled/TimePicker'
import TextFieldDate from './Date'

/**
 * Componente TextFieldTime
 *
 * Rec: REC_TIME
 *
 * Renderiza um campo de texto que tem formatação de data
 * com datepicker
 */
export default class TextFieldTime extends TextFieldDate {
    static propTypes = {
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    }

    views = ['hours', 'minutes', 'seconds']
    format = 'HHmmss'
    displayFormat = 'HH:mm:ss'
    sendFormat = 'HHmmss'
    zeroedValue = this.sendFormat.replace(/./g, '0')

    onDateChange = date => {
        if (date && date.format) {
            this.handleInputChange(
                {
                    target: {
                        value:
                            date && date.isValid()
                                ? date.format(this.format)
                                : date,
                    },
                },
                () => {
                    const { steps } = this.state
                    if (date.isValid() && steps === 2) {
                        this.triggerKeyCode(keycodes.ENTER_KEY)
                        this.setState({ steps: 0 })
                    }

                    this.setState({
                        steps: steps + 1,
                    })
                }
            )
        }
    }

    render() {
        let { value, isPickerOpen } = this.state
        const {
            lbl,
            enabled,
            orientation = 'horizontal',
            inputWidth,
            labelWidth,
            InputProps,
            componentId,
            x,
            y,
            onBlur,
            onFocus,
        } = this.props

        const fieldType = this.getFieldType()
        if (value === this.zeroedValue) {
            value = null
        }

        if (value && !(value instanceof moment)) {
            value = moment(value, this.format)
        }

        return (
            <StyledKeyboardTimePicker
                orientation={orientation}
                value={value}
                componentId={componentId}
                InputProps={InputProps}
                fieldType={fieldType}
                disabled={!enabled}
                open={isPickerOpen}
                onOpen={this.onOpen}
                onClose={this.onClose}
                position={{ x, y }}
                format={this.displayFormat}
                views={this.views}
                onChange={this.onDateChange}
                onFocus={onFocus}
                onBlur={onBlur}
                beforeSend={this.beforeSend}
                ref={this.createRef('input')}
                InputAdornmentProps={{
                    position: 'end',
                    onKeyDown: this.onKeyDown,
                }}
                inputWidth={inputWidth}
                labelWidth={labelWidth}
                label={lbl}
            />
        )
    }
}
