import withStyles from '@material-ui/styles/withStyles'
import Editor from '@nerus/framework/components/Editor'
import * as PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import SortIcon from '@material-ui/icons/Sort'
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'

class Grid extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            order: 'random',
            rows: [],
            columns: [],
            sortIcon: <FormatAlignLeftIcon />,
        } // default state
    }

    static propTypes = {
        data: PropTypes.array.isRequired,
        info: PropTypes.string,
        colors: PropTypes.object,
        classes: PropTypes.object,
        axes: PropTypes.object,
    }

    componentDidUpdate(nextProps, nextState) {
        if (
            nextProps.data !== this.props.data ||
            this.props?.data[0]?.dados.length !== this.state.rows.length
        ) {
            const { data } = this.props

            if (!data || !data[0]) {
                return null
            }

            const dados = data[0].dados
            if (!dados || !dados?.length) {
                return null
            }

            const columns = Object.keys(dados[0])
            let rows = dados.map(row => Object.values(row))

            this.setState({ rows, columns })
        }
    }

    handleOrdering = column => {
        const sortIndex = this.state.columns.findIndex(col => col === column)

        const order =
            this.state.order === 'random'
                ? 'asc'
                : this.state.order === 'desc'
                ? 'asc'
                : 'desc'

        const isNumber = typeof this.state.rows[0][sortIndex] === 'number'
        let rows, sortIcon

        if (order === 'asc') {
            if (isNumber) {
                rows = this.state.rows.sort(
                    (a, b) => a[sortIndex] - b[sortIndex]
                )
            } else {
                rows = this.state.rows.sort((a, b) => {
                    if (a[sortIndex] > b[sortIndex]) return -1
                    else if (a[sortIndex] < b[sortIndex]) return 1
                    return 0
                })
            }

            sortIcon = <SortIcon />
        } else {
            if (isNumber) {
                rows = this.state.rows.sort(
                    (a, b) => b[sortIndex] - a[sortIndex]
                )
            } else {
                rows = this.state.rows.sort((a, b) => {
                    if (a[sortIndex] > b[sortIndex]) return 1
                    else if (a[sortIndex] < b[sortIndex]) return -1
                    return 0
                })
            }

            sortIcon = <SortIcon style={{ transform: 'rotate(180deg)' }} />
        }

        this.setState({ order, rows, sortIcon })
    }

    render() {
        if (this.state.rows && this.state.columns) {
            const { classes } = this.props
            return (
                <Editor
                    isViewOnly
                    hideTitle
                    className={classes.editorRoot}
                    customClasses={{
                        content: classes.editorContent,
                        actions: classes.editorActions,
                        editor: classes.editor,
                    }}
                    cols={this.state.columns}
                    rows={this.state.rows}
                    colsType={[]}
                    handleOrdering={this.handleOrdering}
                    sortIcon={this.state.sortIcon}
                />
            )
        }
        return null
    }
}

export default withStyles(() => ({
    editor: {
        width: '100%',
        maxHeight: '100%',
    },
    editorActions: {
        display: 'none',
    },
    editorRoot: {
        marginLeft: -20,
        marginRight: -20,
        display: 'block',
        width: 'auto',
        marginBottom: 0,
        overflow: 'auto',
    },
    editorContent: {
        flex: 'inherit',
        height: '100%',
        overflow: 'auto',
    },
}))(Grid)
