import makeStyles from '@material-ui/core/styles/makeStyles'
import { styles } from '@nerus/styles/login'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import ReactSlick from 'react-slick/lib'

import Slide1 from '../../../../../../public/img/login-1.jpg'
import Slide2 from '../../../../../../public/img/login-2.jpg'
import Slide3 from '../../../../../../public/img/login-3.jpg'
import Slide4 from '../../../../../../public/img/login-4.jpg'
import Config from '../../../../../config'

const useStyles = makeStyles(styles)

function shuffle(array) {
    let currentIndex = array.length,
        temporaryValue,
        randomIndex
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
    }
    return array
}

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    adaptiveHeight: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
}

export default function Slider() {
    const slides = useMemo(() => {
        if (Config?.login?.slides) {
            return shuffle(
                Config?.login?.slides.map(slide => {
                    return (
                        <div key={slide.trim()} className="slide">
                            <div
                                style={{
                                    backgroundImage: `url(${slide.trim()})`,
                                }}
                            />
                        </div>
                    )
                })
            )
        }

        try {
            // Mantém a ordem feita pelo backend para um bom hydration

            if (typeof document !== undefined && document.querySelectorAll) {
                const dom = document.querySelectorAll(
                    '.slick-slide:not(.slick-cloned) .slide'
                )
                const elements = []
                for (let i = 0; i < dom.length; i++) {
                    const div = dom[i]

                    elements.push(
                        <div
                            key={div.className}
                            className={div.className}
                            style={div.style}
                        >
                            <div
                                style={{
                                    backgroundImage:
                                        div.childNodes[0].style.backgroundImage,
                                }}
                            />
                        </div>
                    )
                }

                if (elements.length) {
                    return elements
                }
            }
        } catch (_) {
            // ignore
        }

        return shuffle([
            <div key="slide-1" className="slide">
                <div style={{ backgroundImage: `url('${Slide1}')` }} />
            </div>,
            <div key="slide-2" className="slide">
                <div style={{ backgroundImage: `url('${Slide2}')` }} />
            </div>,
            <div key="slide-3" className="slide">
                <div style={{ backgroundImage: `url('${Slide3}')` }} />
            </div>,
            <div key="slide-4" className="slide">
                <div style={{ backgroundImage: `url('${Slide4}')` }} />
            </div>,
        ])
    }, [])

    const classes = useStyles()

    return (
        <div
            className={clsx({
                [classes.banner]: true,
                banner: true, // TODO: remover ao tirar o react-slick
            })}
        >
            <ReactSlick {...settings}>{slides}</ReactSlick>
        </div>
    )
}
