import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Close from '@material-ui/icons/Close'
import makeStyles from '@material-ui/styles/makeStyles'
import keycodes from '@nerus/framework/common/Keycodes'
import Editor from '@nerus/framework/components/Editor'
import IconButton from '@nerus/framework/components/Editor/Estrutura/IconButton'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import FilterConfigIcon from '@nerus/framework/icons/FilterConfig'
import Button from '@nerus/framework/styled/Button'
import cloneDeep from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react'
import uuid from 'uuid/v4'

import GenericConfirmation from '../../Dialogs/GenericConfirmation'
import GenericMessage from '../../Dialogs/GenericMessage'
import useFilterTypes from '../../hooks/useFilterTypes'
import FilterForm from './FilterForm'

const useStyles = makeStyles(theme => ({
    icon: {
        marginBottom: -5,
        marginRight: 5,
    },
    iconNoBottom: {
        marginRight: 5,
    },
    actionsRight: {
        textAlign: 'right',
        padding: theme.spacing(2),
        boxShadow:
            '0 -1px 3px 0 rgb(0 0 0 / 6%), 0 -1px 1px 0 rgb(0 0 0 / 3%), 0 -2px 1px -1px rgb(0 0 0 / 2%)',
    },
    titleInput: {
        fontSize: 12,
    },
    drawer: {
        flexShrink: 0,
    },
    drawerPaper: {
        width: 400,
        border: 'none',
        boxShadow:
            '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    headingBox: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },
    headingConfig: {
        padding: 16,
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        alignItems: 'center',
        display: 'flex',
        '& span': {
            flex: 1,
            alignItems: 'center',
            display: 'flex',
        },
        '& svg:first-child': {
            marginRight: 8,
        },
    },
    headingClose: {
        cursor: 'pointer',
        float: 'right',
    },
    flex: {
        display: 'flex',
    },
    boxContent: {
        padding: theme.spacing(1),
        margin: 0,
        width: '100%',
    },
    editorActions: {
        display: 'none',
    },
    editorRoot: {
        marginLeft: -20,
        marginRight: -20,
        display: 'block',
        width: 'auto',
        marginTop: -16,
        minHeight: 400,
    },
    editorContent: {
        flex: 'inherit',
        '& svg': {
            width: 12,
            height: 12,
            color: theme.palette.primary.main,
        },
        '& td:first-child, & th:first-child': {
            paddingLeft: 8,
        },
    },
    addBtn: {
        padding: 8,
        fontSize: 14,
        width: '100%',
        display: 'flex',
        borderRadius: 4,
        cursor: 'pointer',
        boxShadow: 'none',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'transparent',
        border: '2px dashed #000',
    },
    addBtnIcon: {
        marginRight: 8,
    },
}))

export default function FilterHandler({ editing, onOpen, onClose, onSave }) {
    const hasChanges = useRef(false)
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)
    const [showModal, setShowModal] = useState('')
    const [current, setCurrent] = useState(null)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [editFilter, setEditFilter] = useState(null)
    const classes = useStyles()

    const type = useFilterTypes()

    const handleOnSave = useCallback(() => {
        onSave && onSave(current.filters)
        setCurrent(null)
    }, [onSave, setCurrent, current])

    const onShow = useCallback(() => {
        onOpen && onOpen()
        setCurrent(cloneDeep(editing) || {})
    }, [setCurrent, onOpen, editing])

    const doCancel = useCallback(() => {
        setCurrent(null)
        setShowCancelConfirmation(false)
        hasChanges.current = false
        onClose && onClose()
    }, [setCurrent, hasChanges, onClose])

    const onKeyDown = useCallback(
        event => {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                if (current) {
                    event.stopImmediatePropagation()
                    if (showModal) {
                        setShowModal('')
                    } else if (editFilter) {
                        setEditFilter(null)
                    } else if (showRemoveConfirmation) {
                        setShowRemoveConfirmation(false)
                    } else if (hasChanges.current) {
                        setShowCancelConfirmation(true)
                    } else {
                        doCancel()
                    }
                }
            }
        },
        [
            setShowCancelConfirmation,
            setShowRemoveConfirmation,
            showRemoveConfirmation,
            setEditFilter,
            setShowModal,
            editFilter,
            hasChanges,
            showModal,
            doCancel,
            current,
        ]
    )

    useKeyboardTrap(onKeyDown, { capture: true })

    const hideCancel = useCallback(() => {
        setShowCancelConfirmation(false)
    }, [setShowCancelConfirmation])

    const handleOnClose = useCallback(() => {
        if (hasChanges.current) {
            setShowCancelConfirmation(true)
        } else {
            doCancel()
        }
    }, [setShowCancelConfirmation, doCancel, hasChanges])

    const hideModal = useCallback(() => {
        setShowModal('')
    }, [setShowModal])

    const doNew = useCallback(() => {
        setEditFilter({})
    }, [setCurrent])

    const onEdit = useCallback(
        row => () => {
            setEditFilter(row)
        },
        [setCurrent]
    )

    const hideEdit = useCallback(() => {
        setEditFilter(null)
    }, [setEditFilter])

    const doEdit = useCallback(
        row => {
            setCurrent(cur => {
                if (!row.id) {
                    row.id = uuid()
                    return {
                        ...cur,
                        filters: [...cur.filters, row],
                    }
                }

                return {
                    ...cur,
                    filters: cur.filters.map(f => {
                        if (row.id === f.id) {
                            return row
                        }
                        return f
                    }),
                }
            })
            setEditFilter(null)
        },
        [setCurrent]
    )

    const doRemove = useCallback(() => {
        setCurrent(cur => ({
            ...cur,
            filters: cur.filters.filter(
                f => f.id !== showRemoveConfirmation?.id
            ),
        }))
        setShowRemoveConfirmation(null)
    }, [setCurrent, setShowRemoveConfirmation, showRemoveConfirmation])

    const onRemove = useCallback(row => () => setShowRemoveConfirmation(row), [
        setShowRemoveConfirmation,
    ])

    const hideRemove = useCallback(() => setShowRemoveConfirmation(null), [
        setShowRemoveConfirmation,
    ])

    const rows = useMemo(() => {
        return (
            current?.filters?.map?.(row => [
                row.desc,
                typeof row.type === 'number'
                    ? type.byType(row.type)?.lbl
                    : row.type.lbl,
                row.default || '',
                [
                    <IconButton
                        key="edit-btn"
                        tooltip="Modificar"
                        size="small"
                        onClick={onEdit(row)}
                    />,
                    <IconButton
                        key="trash-btn"
                        tooltip="Retirar"
                        size="small"
                        onClick={onRemove(row)}
                    />,
                ],
            ]) || []
        )
    }, [current])

    return (
        <Fragment>
            <IconButton
                size="small"
                className={classes.iconNoBottom}
                tooltip="Configurar Filtros"
                onClick={onShow}
            />

            {editFilter !== null ? (
                <FilterForm
                    filter={editFilter}
                    onSave={doEdit}
                    onShow={hideEdit}
                />
            ) : null}

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={Boolean(current)}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.headingBox}>
                    <Typography className={classes.headingConfig}>
                        <span>
                            <FilterConfigIcon style={{ color: '#ffffff' }} />
                            Configuração de Filtros
                        </span>
                        <Close
                            className={classes.headingClose}
                            onClick={handleOnClose}
                        />
                    </Typography>
                </div>

                {current ? (
                    <Grid container spacing={2} className={classes.boxContent}>
                        <Grid item xs={12} className={classes.flex}>
                            <Editor
                                isViewOnly
                                hideTitle
                                className={classes.editorRoot}
                                customClasses={{
                                    content: classes.editorContent,
                                    actions: classes.editorActions,
                                    editor: classes.editor,
                                }}
                                cols={['Descrição', 'Tipo', 'Padrão', '']}
                                rows={rows}
                                colsType={[]}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.flex}>
                            <button className={classes.addBtn} onClick={doNew}>
                                <AddCircleIcon className={classes.addBtnIcon} />
                                Adicionar Filtro
                            </button>
                        </Grid>
                    </Grid>
                ) : null}

                <div className={classes.actionsRight}>
                    <Button
                        lbl="Cancelar"
                        dialog
                        color="danger"
                        onClick={handleOnClose}
                    />

                    <Button
                        lbl="Confirmar"
                        primary
                        dialog
                        onClick={handleOnSave}
                    />
                </div>
            </Drawer>

            {showRemoveConfirmation ? (
                <GenericConfirmation
                    onSubmit={doRemove}
                    onClose={hideRemove}
                    message="Você confirma a remoção do registro?"
                />
            ) : null}

            {showCancelConfirmation ? (
                <GenericConfirmation
                    onSubmit={doCancel}
                    onClose={hideCancel}
                    message="Você poderá perder qualquer alteração realizada, confirma?"
                />
            ) : null}

            {showModal ? (
                <GenericMessage onClose={hideModal} message={showModal} />
            ) : null}
        </Fragment>
    )
}

FilterHandler.propTypes = {
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    editing: PropTypes.object,
}
