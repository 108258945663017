import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Dialogo from '@nerus/framework/components/Dialogo'
import PrintIcon from '@nerus/framework/icons/Print'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'

import { formatTooltip } from '../../Business/Keyboard'
import { handleKeyboard } from '../../Eac/reducers/BoletoDialog'
import BaseFlutuante from '../Flutuante/BaseFlutuante'

/**
 * Classe base do BaseBoletoDialog
 */
export default class BaseBoletoDialog extends Component {
    static propTypes = {
        data: PropTypes.any,
        classes: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        ws: PropTypes.object.isRequired,
        active: PropTypes.bool,
        pdf: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            dialog: null,
            data: props.data,
            initialSize: 11,
            activeSize: null,
            pdf: null,
        }
    }

    raiseFont = e => {
        e.preventDefault()
        let { activeSize, initialSize } = this.state
        if (!activeSize) {
            activeSize = initialSize
        }

        if (activeSize > 20) {
            return
        }

        this.setState({
            activeSize: activeSize + 2,
        })
    }

    reduceFont = e => {
        e.preventDefault()
        let { activeSize, initialSize } = this.state
        if (!activeSize) {
            activeSize = initialSize
        }

        if (activeSize < 6) {
            return
        }

        this.setState({
            activeSize: activeSize - 2,
        })
    }

    handleDialogClose = () => {
        this.setState({ dialog: null })
    }

    handleClick = o => () => {
        handleKeyboard(this.props.ws)(this.props.dispatch, o, this.props.data)
    }

    onClose = () => {
        handleKeyboard(this.props.ws)(
            this.props.dispatch,
            { keyCode: 27 },
            this.props.data
        )
    }

    renderDialog = () => {
        let { preview } = this.state
        const { classes, data } = this.props

        if (!preview) {
            preview = data.preview
        }

        const opts = {
            className: classes.root,
            autoComplete: 'off',
        }

        const style = {}
        if (this.state.activeSize) {
            style.fontSize = this.state.activeSize
        }

        const { numero = '' } = data

        return (
            <BaseFlutuante
                handleClose={this.onClose}
                title={`Opções de Impressão: ${numero}`}
            >
                <Dialogo
                    open={Boolean(this.state.dialog)}
                    title={this.state.dialog?.title || ''}
                    msg={this.state.dialog?.message || ''}
                    onClose={this.handleDialogClose}
                    optType={-1}
                />

                <div {...opts}>
                    <div className={classes.header}>
                        <Typography variant={'h2'}>Pré-visualização</Typography>
                    </div>

                    <div className={classes.previewer}>
                        <div id="boleto"></div>
                    </div>
                    <BottomNavigation
                        showLabels
                        className={classes.buttonsHolder}
                    >
                        <Tooltip placement="bottom">
                            <BottomNavigationAction
                                href="#"
                                classes={{
                                    root: classes.button,
                                    label: classes.buttonLabel,
                                    wrapper: classes.buttonWrapper,
                                }}
                                key={'Imprimir'}
                                icon={<PrintIcon />}
                                label={formatTooltip(
                                    'Imprimir',
                                    'I',
                                    true,
                                    true
                                )}
                                onClick={this.handleClick({
                                    key: 'I',
                                    keyCode: 73,
                                    file: this.state.pdf,
                                })}
                            />
                        </Tooltip>
                    </BottomNavigation>
                </div>
            </BaseFlutuante>
        )
    }
}
