import BiometriaFacial from './BiometriaFacial'
import DanfeDialog from './DanfeDialog'
import BoletoDialog from './BoletoDialog'
import Editor from './Editor'
import EditorDuplo from './EditorDuplo'
import EditorForm from './EditorForm'
import EditorFormEdit from './EditorFormEdit'
import EditorFormEditPdv from './EditorFormEditPdv'
import FilePreviewer from './FilePreviewer'
import FileSend from './FileSend'
import Formulario from './Formulario'
import Foto from './Fotos'
import Interface from './Interface'
import ListaSelecao from './ListaSelecao'
import Menu from './Menu'
import MenuLateral from './MenuLateral'
import RelatorioDialog from './RelatorioDialog'
import RelatorioDialogGerador from './RelatorioDialogGerador'

export default {
    BiometriaFacial,
    Interface,
    Menu,
    MenuLateral,
    Editor,
    EditorForm,
    EditorFormEdit,
    EditorFormEditPdv,
    EditorDuplo,
    FilePreviewer,
    FileSend,
    Formulario,
    RelatorioDialog,
    RelatorioDialogGerador,
    ListaSelecao,
    Foto,
    DanfeDialog,
    BoletoDialog,
}
