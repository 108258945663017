import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function Biometry(props) {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 448.08 448"
            style={{
                enableBackground: 'new 0 0 448 448',
                // eslint-disable-next-line react/prop-types
                ...(props.style || {}),
            }}
        >
            <path
                style={{ fill: '#575756' }}
                d="M448.08,467.44H0V-0.44h448.08V467.44z M223.18,20.82c-40.24,0.36-72.61,27.77-87.74,52.78
		c-49.34,81.56,27.49,231.96,87.74,232.21c60.48,0.25,139.02-150.64,89.66-232.21C297.34,47.99,263.94,20.46,223.18,20.82z"
            />
            <g style={{ opacity: 0.19 }}>
                <path
                    d="M448.08-0.44v467.87H0V-0.44H448.08 M223.33,305.81c60.46,0,138.84-150.7,89.51-232.21
			c-15.4-25.45-48.47-52.79-88.87-52.79c-0.27,0-0.53,0-0.79,0c-40.24,0.36-72.61,27.77-87.74,52.78
			c-49.34,81.56,27.49,231.96,87.74,232.21C223.23,305.81,223.28,305.81,223.33,305.81 M448.08-3.44H0c-1.66,0-3,1.34-3,3v467.87
			c0,1.66,1.34,3,3,3h448.08c1.66,0,3-1.34,3-3V-0.44C451.08-2.09,449.73-3.44,448.08-3.44L448.08-3.44z M223.33,302.81l-0.14,0
			c-22.7-0.09-50.01-23.7-71.28-61.6c-27.39-48.82-42.32-119.1-13.91-166.06c14.19-23.46,45.02-50.98,85.2-51.34
			c0.26,0,0.51,0,0.77,0c38.16,0,70.84,25.79,86.3,51.34c28.16,46.53,13.07,116.48-14.37,165.16
			C274.18,278.86,246.37,302.81,223.33,302.81L223.33,302.81z"
                />
            </g>
        </SvgIcon>
    )
}
