import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/dialogo'

import BaseDialogo from './BaseDialogo'

export class Dialogo extends BaseDialogo {
    render() {
        return BaseDialogo.prototype.render.call(this)
    }
}

export default withStyles(styles)(Dialogo)
