export const styles = () => ({
    root: {
        display: 'flex',
        margin: '0 44px',
        flex: '1 1 100%',
        flexDirection: 'column',
        height: '100%',
        fontSize: 14,
        '@media(max-width: 1024px)': {
            // zoom: 0.63,
        },
        '@media(min-width: 1025px) and (max-width: 1240px)': {
            // zoom: 0.7,
        },
        // '@media(min-width: 1025px) and (max-width: 1280px)': {
        '@media(min-width: 1241px) and (max-width: 1560px)': {
            fontSize: 10,
            // zoom: 0.8,
        },
        '@media(min-width: 1281px) and (max-width: 1900px)': {
            fontSize: 11,
        },
        '@media(min-width: 1901px) and (max-width: 2100px)': {
            fontSize: 11,
        },
        '@media(min-width: 2100px)': {
            fontSize: 16,
        },
    },
    content: {
        flex: 1,
        overflow: 'auto',
        overflowX: 'hidden',
        paddingRight: 10,
    },
    title: {
        fontSize: `1.7142857142857142em`,
        fontWeight: 700,
        marginRight: 55,
        color: '#333333',
        marginBottom: `-2.0833333333333335em`,
        lineHeight: `1.3333333333333333em`,
    },
})
