import 'moment/locale/pt-br'
import '../../resources/less/style.less'

import { ApolloProvider } from '@apollo/react-hooks'
import MomentUtils from '@date-io/moment'
import { CssBaseline } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import theme from '@nerus/styles'
import themeo2 from '@nerus/styles/o2'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Switch, withRouter } from 'react-router-dom'

import packageJson from '../../../package.json'
// Apollo Client GRAPHQL
import client from '../../config/ApolloClient'
import routes, { renderRoutes } from '../../routes'
import convertSizeToHuman from '../../util/convertSizeToHuman'
import WebsocketHandle from '../NerusWeb/Business/Websocket'
import { getNerusInfo } from '../NerusWeb/Eac/EacReducer'
import Loading from './components/Loading'
import UnstableHandleError from './components/UnstableHandleError'

const meta = [
    { charset: 'utf-8' },
    {
        'http-equiv': 'X-UA-Compatible',
        content: 'IE=edge',
    },
    {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1',
    },
    {
        name: 'theme-color',
        content: theme.palette.primary.main,
    },
]

const WebSocketIncluded = WebsocketHandle(
    class WebSocketHandled extends Component {
        static propTypes = {
            children: PropTypes.node,
        }

        render() {
            return this.props.children
        }
    }
)

/**
 * Classe orchestradora da aplicação
 * Define as rotas, estrutura basica e meta tags
 */
export class App extends Component {
    /**
     * Propriedades necessárias para carregar o componente
     */
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        nerusInfo: PropTypes.object,
    }

    /**
     * Recupera informações do Redux
     */
    static mapStateToProps = state => {
        return {
            isLoading: state.app.isLoading,
            nerusInfo: getNerusInfo(state),
        }
    }

    /**
     * Estado inicial deste componente
     * @type {Object}
     */
    state = {
        isMounted: false, // define se a aplicação está montada ou não no browser
    }

    /**
     * Evento que ocorre antes de montar um componente no browser
     * usamos para setar algumas coisas que dependem do browser
     */
    componentDidMount() {
        const pathname = this.props.location.pathname.replace(/\//g, '')
        this.setState({
            isMounted: true,
            page: pathname,
        })

        if (
            process.env.NODE_ENV === 'development' &&
            typeof window !== 'undefined'
        ) {
            const info = document.getElementById('memory-heap')
            info.style.display = 'block'
            const showMemory = () => {
                const memory = window.performance.memory
                if (memory) {
                    info.innerHTML = `${convertSizeToHuman(
                        memory.usedJSHeapSize
                    )}/${convertSizeToHuman(memory.totalJSHeapSize)}`
                }
            }
            // atualiza gasto de memória a cada 2 minutos
            this.interval = setInterval(showMemory, 1000 * 2)
            showMemory()
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    /**
     * Evento que ocorre quando o componente é atualizado
     */
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            const pathname = this.props.location.pathname.replace(/\//g, '')
            this.setState({
                isMounted: true,
                page: pathname,
            })
        }
    }

    /**
     * Renderiza a aplicação
     * Este componente possui apenas a estrutura da aplicação e define as rotas
     * Exibe um DevTool para os desenvolvedores que precisarem
     *
     * O Switch é usado para garantir que apenas uma rota ficará ativa por vez
     * nada nos impede de ter rotas internas.
     */
    render() {
        /**
         * O MuiThemeProvider define o tema padrão pro Material-UI
         * O Helmet define o titulo e altera as meta tags da aplicação
         */
        const { nerusInfo } = this.props
        const showInfo = nerusInfo?.v ? nerusInfo.v.indexOf(':') > -1 : false
        let perfil = ''
        if (showInfo) {
            const parts = nerusInfo.v.split(' ')
            parts.pop()
            parts.pop()
            perfil = parts.join(' ')
        }

        const useTheme =
            this.props.location.pathname.indexOf('o2') > -1 ? themeo2 : theme

        return (
            <ApolloProvider client={client}>
                <ThemeProvider theme={useTheme}>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
                        <WebSocketIncluded>
                            <Helmet
                                title="Nérus"
                                titleTemplate={`%s ${packageJson.version}${
                                    perfil ? ` - ${perfil}` : ''
                                }`}
                                meta={meta}
                            />

                            <CssBaseline />

                            <Loading isLoading={this.props.isLoading} />

                            <UnstableHandleError>
                                <Switch>{renderRoutes(routes)}</Switch>
                            </UnstableHandleError>

                            <div
                                id="memory-heap"
                                style={{
                                    display: 'none',
                                    position: 'absolute',
                                    bottom: 200,
                                    right: 0,
                                    width: 200,
                                    background: '#ffffff',
                                    fontSize: 14,
                                    padding: 8,
                                    zIndex: 9999999,
                                }}
                            />
                        </WebSocketIncluded>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </ApolloProvider>
        )
    }
}

App.propTypes = {
    location: PropTypes.object,
    isLoading: PropTypes.bool,
}

// Exporta o componente, conectando ao redux e ao router
export default withRouter(connect(App.mapStateToProps)(App))

/**
 * Isso faz com que a aplicação recarregue durante o desenvolvimento
 * não é ativo quando está em prod
 */
if (module.hot) {
    module.hot.accept()
}
