/* global Set */
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
// import CardMedia from '@material-ui/core/CardMedia'
import IconButtonMui from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import IconButton from '@nerus/framework/components/Editor/Estrutura/IconButton'
import { useO2 } from '@nerus/framework/hooks/useO2'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
    getColorsByType,
    getIconByType,
    getTypeTitle,
} from './Components/constants'

const useStyles = makeStyles(() => ({
    actions: {
        opacity: 0,
        transition: 'ease-in-out opacity 0.5s',
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    menu: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    withoutMenu: {
        opacity: 0.5,
    },
    root: {
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        textDecoration: 'none',
        '&:hover $actions': {
            opacity: 1,
        },
        border: '2px solid transparent',
    },
    typeBadge: {
        marginRight: 8,
        marginBottom: 8,
        cursor: 'default',
    },
    content: {
        maxWidth: '100%',
    },
    contentLibrary: {
        maxWidth: '100%',
    },
    title: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
    },
    types: {
        minHeight: 38,
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
    },
    selected: {
        border: '2px solid #97262c',
    },
}))

export function renderCard({ data, history, onRemove }) {
    return (
        <DashboardCard
            key={data.id}
            {...data}
            history={history}
            onRemove={onRemove}
        />
    )
}

export function renderTypeBadge({ classes }) {
    return function TypeBadge(type) {
        const Icon = getIconByType(type)
        const title = getTypeTitle(type)
        const colors = getColorsByType(type)

        if (!Icon) {
            return
        }

        return (
            <Tooltip key={type} title={title}>
                <IconButtonMui
                    size="small"
                    className={classes.typeBadge}
                    style={{ ...colors }}
                >
                    <Icon />
                </IconButtonMui>
            </Tooltip>
        )
    }
}

export default function DashboardCard({
    id,
    title,
    menu,
    components,
    history,
    onRemove,
    onClick,
    onSelect,
    isLibrary,
    selectedLibrary,
}) {
    const classes = useStyles()
    const { isO2 } = useO2(true)
    const uri = `${isO2 ? '/bin/o2' : ''}/dashboard/${id}`

    const handleModify = useCallback(
        event => {
            event.stopPropagation()
            event.preventDefault()
            history.push(uri)
        },
        [history, uri]
    )

    const handleRemove = useCallback(
        event => {
            event.stopPropagation()
            event.preventDefault()

            onRemove()
        },
        [onRemove]
    )

    const handleSelect = useCallback(
        event => {
            event.stopPropagation()
            event.preventDefault()

            onSelect()
        },
        [onSelect]
    )

    const handleClick = useCallback(
        event => {
            event.stopPropagation()
            event.preventDefault()

            onClick()
        },
        [onClick]
    )

    const types = useMemo(() => {
        const uniqueTypes = [
            ...new Set(components.map(component => component.type).sort()),
        ]

        return uniqueTypes.map(renderTypeBadge({ classes }))
    }, [components, classes])

    const [component, to, handle] = onSelect
        ? ['div', null, handleSelect]
        : !onClick
        ? [Link, uri, null]
        : ['div', null, handleClick]

    return (
        <Card
            component={component}
            onClick={handle}
            to={to}
            className={
                id === selectedLibrary
                    ? `${classes.selected} ${classes.root}`
                    : classes.root
            }
        >
            <CardContent
                className={isLibrary ? classes.contentLibrary : classes.content}
            >
                <Typography
                    className={classes.title}
                    color="secondary"
                    variant="h5"
                >
                    {title}
                </Typography>
                <Typography
                    className={clsx(classes.menu, {
                        [classes.withoutMenu]: !menu?.title,
                    })}
                    color="textSecondary"
                    variant="h6"
                    gutterBottom
                >
                    {menu?.title || 'Sem local definido'}
                </Typography>

                <div className={classes.types}>{types}</div>
            </CardContent>

            <CardActions className={classes.actions}>
                {!onClick && !onSelect ? (
                    <IconButton
                        size="small"
                        tooltip={'Modificar'}
                        onClick={handleModify}
                    />
                ) : null}
                {onRemove ? (
                    <IconButton
                        size="small"
                        tooltip={'Retirar'}
                        onClick={handleRemove}
                    />
                ) : null}
            </CardActions>
        </Card>
    )
}

DashboardCard.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    onRemove: PropTypes.func,
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
    isLibrary: PropTypes.bool,
    selectedLibrary: PropTypes.number,
    menu: PropTypes.shape({
        title: PropTypes.string,
    }),
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
    components: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.number,
        })
    ),
}
