export default function(date = new Date()) {
    return new Intl.DateTimeFormat('pt-BR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })
        .format(date)
        .replace(/,/, ' -')
}
