import { Typography } from '@material-ui/core'
import { default as appConfig } from '@nerus/config'
import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import StyledButton from '@nerus/framework/components/Button'
import Dialogo from '@nerus/framework/components/Dialogo'
import * as PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import Biometry from '@nerus/framework/icons/Biometry'
import { handleKeyboard } from '../../Eac/reducers/BiometriaFacial'
import BaseFlutuante from '../Flutuante/BaseFlutuante'

const config = appConfig.camera

export const MIN_DEVICE_HEIGHT_SIZE = 400
export const MIN_DEVICE_WIDTH_SIZE = 400
export const MAX_HEIGHT = config.picHeight || 600
export const MAX_WIDTH = config.picWidth || 800
export const OUTPUT_BIOMETRY_QUALITY = config.picQuality || 0.75

/**
 * Classe base do BaseBiometriaFacial
 */

export default class BaseBiometriaFacial extends EnhancedComponent {
    static propTypes = {
        active: PropTypes.bool,
        classes: PropTypes.object,
        data: PropTypes.any,
        dispatch: PropTypes.func.isRequired,
        retType: PropTypes.string,
        ws: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            dialog: null,
            data: props.data,
            hasSupport: !!(
                'mediaDevices' in navigator &&
                navigator.mediaDevices.getUserMedia
            ),
            videoDevices: [],
        }
    }

    componentDidMount() {
        if (this.state.hasSupport) this.startCamera()
    }

    componentWillUnmount = () => {
        super.componentWillUnmount()
        if (this.state.stream) this.stopCamera(this.state.stream)
        this.setState({ screenshot: null, stream: null })
    }

    stopCamera = stream => {
        if (config.allowTurnOff) {
            stream.getTracks().forEach(function(track) {
                if (track.readyState == 'live') {
                    track.stop()
                }
            })
        }
    }

    startCamera = () => {
        navigator.mediaDevices
            .getUserMedia({
                video: {
                    height: {
                        min: MIN_DEVICE_HEIGHT_SIZE,
                    },
                    width: {
                        min: MIN_DEVICE_WIDTH_SIZE,
                    },
                },
            })
            .then(stream => {
                this.setState({
                    stream,
                })
                this.getRef('video').srcObject = stream
                this.getCameraSelection()
            })
            .catch(() => {
                alert(
                    'Não foi possível iniciar a câmera, verifique as permissões ou configurações do hardware.'
                )
            })
    }

    getCameraSelection = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices()
        const videoDevices = devices.filter(
            device => device.kind === 'videoinput'
        )
        this.setState({ videoDevices })
    }

    handleDialogClose = () => {
        this.setState({ dialog: null })
        this.reTakePicture()
    }

    handleClick = o => () => {
        handleKeyboard(this.props.ws)(this.props.dispatch, o, this.props.data)
    }

    onClose = () => {
        handleKeyboard(this.props.ws)(
            this.props.dispatch,
            { keyCode: 27 },
            this.props.data
        )
    }

    onConfirm = () => {
        const { type, ...payload } = this.props.data
        this.setState(
            {
                loading: true,
            },
            () =>
                this.props
                    .faceBiometry({
                        variables: {
                            type,
                            data: {
                                ...payload,
                                request: {
                                    ...payload.request,
                                    photo: this.state.screenshot,
                                },
                            },
                        },
                    })
                    .then(res => {
                        this.setState({ screenshot: null })
                        handleKeyboard(this.props.ws)(
                            this.props.dispatch,
                            { keyCode: 13 },
                            {
                                ...res.data,
                                retType: this.props.retType,
                            }
                        )
                    })
                    .catch(e => {
                        if (e.graphQLErrors) {
                            const message = e.graphQLErrors[0].message
                            this.setState({
                                loading: false,
                                dialog: {
                                    title: 'Retorno Biometria Facial',
                                    message,
                                },
                            })
                        } else {
                            this.setState({
                                loading: false,
                                dialog: {
                                    title: 'Falha Biometria Facial',
                                    message:
                                        'Houve um problema na biometria facial, entre em contato com o suporte.',
                                },
                            })
                        }
                    })
        )
    }

    reTakePicture = () => {
        this.setState(
            {
                screenshot: null,
                loading: false,
            },
            () => {
                this.startCamera()
            }
        )
    }

    onTakePicture = () => {
        const video = this.getRef('video')
        const canvas = this.getRef('canvas')
        let width = video.videoWidth
        let height = video.videoHeight

        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width
                width = MAX_WIDTH
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height
                height = MAX_HEIGHT
            }
        }

        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(video, 0, 0, width, height)
        this.setState({
            screenshot: canvas.toDataURL('image/jpeg', OUTPUT_BIOMETRY_QUALITY),
        })
    }

    renderComponent = () => {
        const { classes } = this.props
        const { hasSupport, screenshot, loading = false } = this.state
        const opts = {
            className: classes.root,
            autoComplete: 'off',
        }

        return (
            <BaseFlutuante handleClose={this.onClose} title="Biometria Facial">
                <Dialogo
                    open={Boolean(this.state.dialog)}
                    title={this.state.dialog?.title || ''}
                    msg={this.state.dialog?.message || ''}
                    onClose={this.handleDialogClose}
                    optType={-1}
                />

                {loading ? (
                    <div className={classes.loading}>
                        <Typography variant={'h2'}>
                            Validando biometria...
                        </Typography>
                    </div>
                ) : null}

                <div {...opts}>
                    {hasSupport ? (
                        <div className={classes.previewer}>
                            <Biometry className={classes.overlay} />
                            {!screenshot ? (
                                <React.Fragment>
                                    <video
                                        autoPlay
                                        ref={this.createRef('video')}
                                        className={classes.canvas}
                                    />
                                    <canvas
                                        style={{
                                            display: 'none',
                                        }}
                                        ref={this.createRef('canvas')}
                                        className={classes.canvas}
                                    />
                                </React.Fragment>
                            ) : (
                                <img
                                    className={classes.canvas}
                                    src={screenshot}
                                />
                            )}
                        </div>
                    ) : (
                        <Typography>
                            Seu browser não suporta uso de camera
                        </Typography>
                    )}

                    <div className={classes.actions}>
                        {hasSupport ? (
                            <Fragment>
                                <StyledButton
                                    tabIndex={0}
                                    primary={!screenshot}
                                    color={!screenshot ? 'none' : 'info'}
                                    dialog
                                    onClick={
                                        screenshot
                                            ? this.reTakePicture
                                            : this.onTakePicture
                                    }
                                >
                                    {screenshot ? 'Repetir' : 'Tirar foto'}
                                </StyledButton>

                                <StyledButton
                                    tabIndex={0}
                                    primary
                                    dialog
                                    disabled={!screenshot}
                                    onClick={this.onConfirm}
                                >
                                    Confirmar
                                </StyledButton>
                            </Fragment>
                        ) : null}

                        <StyledButton
                            tabIndex={0}
                            dialog
                            onClick={this.onClose}
                        >
                            Cancelar
                        </StyledButton>
                    </div>
                </div>
            </BaseFlutuante>
        )
    }
}
