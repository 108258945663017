import Grid from '@material-ui/core/Grid'
import Portal from '@material-ui/core/Portal'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import keycodes from '@nerus/framework/common/Keycodes'
import Flutuante from '@nerus/framework/components/Base/Flutuante'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import { useO2 } from '@nerus/framework/hooks/useO2'
import Button from '@nerus/framework/styled/Button'
import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useMemo, useState } from 'react'

import Actions from './Actions'
import DashboardCard from './DashboardCard'
import DashboardRender from './DashboardRender'
import useFetchDashboards from './hooks/useFetchDashboards'

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.common.white,
    },
    dialogo: {
        backgroundColor: '#E8E8E8',
        '&:not(:focus-within)': {
            backgroundColor: '#E8E8E8',
        },
        '& > div:last-child': {
            paddingTop: 16,
        },
    },
    content: {
        overflowX: 'hidden',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        flex: 1,
    },
    container: {
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        margin: 0,
    },
    modalActions: {
        marginBottom: 8,
        marginTop: 16,
    },
    leftActions: {
        display: 'flex',
        flex: 1,
    },
}))

function DashboardLibrary({ history, handleToggle }) {
    const [showPreview, setShowPreview] = useState(false)
    const [pageLibrary, setPageLibrary] = useState(0)
    const [pageSizeLibrary, setPageSizeLibrary] = useState(16)

    const [selectedLibrary, setSelectedLibrary] = useState(null)
    const { loadingLibraries, data: dataLibraries } = useFetchDashboards({
        variables: { panel: false, library: 1 },
    })
    const { isO2 } = useO2(true)

    const classes = useStyles()

    const labelDisplay = useCallback(
        ({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`,
        []
    )

    const onSelectLibrary = useCallback(() => {
        history.push(
            `${
                isO2 ? '/bin/o2' : ''
            }/dashboard/new?library_id=${selectedLibrary}`
        )
    }, [selectedLibrary])

    const handleChangeSelectedLibrary = useCallback(
        libraryId => {
            if (selectedLibrary === libraryId) {
                setSelectedLibrary(null)
            } else {
                setSelectedLibrary(libraryId)
            }
        },
        [selectedLibrary, setSelectedLibrary]
    )

    const dashboardsLibrary = useMemo(() => {
        return dataLibraries?.allDashboard || []
    }, [dataLibraries])

    const handleChangePageLibrary = useCallback(
        (_event, page) => {
            setPageLibrary(page)
        },
        [setPageLibrary]
    )

    const handlePageSizeLibrary = useCallback(
        event => {
            setPageSizeLibrary(event.target.value)
        },
        [setPageLibrary]
    )

    const dashboardsLibraryList = useMemo(() => {
        const startIndex = pageLibrary * pageSizeLibrary
        const endIndex = Math.min(
            startIndex + pageSizeLibrary,
            dashboardsLibrary.length
        )
        return dashboardsLibrary.slice(startIndex, endIndex).map(dashboard => {
            return (
                <Grid key={dashboard.id} item md={4} lg={3} xl={3}>
                    <DashboardCard
                        history={history}
                        // eslint-disable-next-line react/jsx-no-bind, prettier/prettier
                        onSelect={() =>
                            handleChangeSelectedLibrary(dashboard.id)
                        }
                        isLibrary={true}
                        //imagePreview: dashboard.image,
                        selectedLibrary={selectedLibrary}
                        {...dashboard}
                    />
                </Grid>
            )
        })
    }, [dashboardsLibrary, pageLibrary, pageSizeLibrary, selectedLibrary])

    const handleTogglePreview = useCallback(() => {
        if (showPreview) {
            setShowPreview(false)
        } else {
            setShowPreview(true)
        }
    }, [setShowPreview, showPreview])

    const onPreviewSelectedLibrary = useCallback(() => {
        setShowPreview(true)
    }, [selectedLibrary])

    const onKeyDown = useCallback(
        event => {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                event.stopImmediatePropagation()

                if (event.defaultPrevented) {
                    return
                }

                if (showPreview) {
                    setShowPreview(false)
                } else if (handleToggle) {
                    handleToggle()
                } else {
                    history.push(`${isO2 ? '/bin/o2' : ''}/dashboard`)
                }
            }
        },
        [setShowPreview, handleToggle, showPreview, history]
    )

    useKeyboardTrap(onKeyDown)

    return (
        <Fragment>
            <Portal container={document.getElementById('#root')}>
                <Flutuante
                    title="Biblioteca de Dashboards"
                    onClose={handleToggle}
                    className={classes.dialogo}
                    size="fullscreen"
                >
                    <div className={classes.content}>
                        {showPreview ? (
                            <DashboardRender
                                dashboard_id={selectedLibrary}
                                handleToggle={handleTogglePreview}
                            />
                        ) : loadingLibraries ? (
                            <Typography variant="h6">
                                Buscando registros...
                            </Typography>
                        ) : dashboardsLibrary.length ? (
                            <Grid
                                spacing={2}
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                {dashboardsLibraryList}
                            </Grid>
                        ) : (
                            <Typography variant="h4">
                                Nenhum modelo encontrado
                            </Typography>
                        )}
                    </div>

                    <TablePagination
                        component="div"
                        count={dashboardsLibrary.length}
                        onPageChange={handleChangePageLibrary}
                        page={pageLibrary}
                        rowsPerPageOptions={[8, 12, 16, 24, 32, 40]}
                        rowsPerPage={pageSizeLibrary}
                        labelRowsPerPage="Registros por página:"
                        nextIconButtonText="Próxima Página"
                        backIconButtonText="Página Anterior"
                        labelDisplayedRows={labelDisplay}
                        onRowsPerPageChange={handlePageSizeLibrary}
                    />

                    <Actions className={classes.modalActions}>
                        <div className={classes.leftActions}>
                            <Button
                                dialog
                                tabIndex={2}
                                disabled={!selectedLibrary}
                                onClick={onPreviewSelectedLibrary}
                            >
                                Pré-visualizar
                            </Button>
                        </div>

                        <Button
                            dialog
                            primary
                            disabled={!selectedLibrary}
                            onClick={onSelectLibrary}
                        >
                            Selecionar modelo
                        </Button>
                        <Button dialog color="danger" onClick={handleToggle}>
                            Cancelar
                        </Button>
                    </Actions>
                </Flutuante>
            </Portal>
        </Fragment>
    )
}

export default DashboardLibrary

DashboardLibrary.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    handleToggle: PropTypes.func.isRequired,
}
