import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef } from 'react'

const useStyles = makeStyles(theme => ({
    previewer: {
        minWidth: 538,
        // minHeight: 365,
        border: '1px solid #ccc',
        flex: 1,
        marginBottom: theme.spacing(1),
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        // [`@media (min-height:768px)`]: {
        //     minHeight: 538,
        // },
    },
    imgPreview: {
        maxWidth: 536,
        maxHeight: 536,
        [`@media (max-height: 767px)`]: {
            maxHeight: 363,
        },
    },
}))

const Previewer = ({ url, className, autoHeight = false }) => {
    const classes = useStyles()
    const ref = useRef()

    const doResize = useCallback(() => {
        if (ref.current && autoHeight) {
            const offsetElement =
                ref.current.parentElement.parentElement.parentElement
                    .parentElement.parentElement

            const offsetColumn = ref.current.parentElement.parentElement

            const calculedOffset =
                offsetElement.offsetHeight - offsetColumn.offsetHeight >= 0
                    ? offsetElement.offsetHeight - offsetColumn.offsetHeight
                    : 0

            let maxHeight =
                window.innerHeight < 616
                    ? window.innerHeight - offsetElement.offsetHeight
                    : window.innerHeight -
                      (offsetElement.offsetHeight - calculedOffset)

            ref.current.style.maxHeight = maxHeight + 'px'
            ref.current.style.minHeight = maxHeight + 'px'
        }
    }, [ref])

    useEffect(() => {
        doResize(url)
    }, [url])

    useEffect(() => {
        if (autoHeight) {
            window.addEventListener('resize', doResize)
            doResize()
        }

        return () => {
            window.removeEventListener('resize', doResize)
        }
    }, [autoHeight, doResize])

    return (
        <Box ref={ref} className={clsx(classes.previewer, className)}>
            <img className={classes.imgPreview} src={url} />
        </Box>
    )
}

Previewer.propTypes = {
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
    autoHeight: PropTypes.bool,
}

export default Previewer
