import FormControlLabel from '@material-ui/core/FormControlLabel'
import Toggle from '@material-ui/core/Switch/Switch'
import useAutoFocus from '@nerus/framework/hooks/useAutoFocus'
import Label from '@nerus/framework/styled/Label'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { hookStyles } from './Input'

export const StyledToggleComponent = (
    {
        label,
        labelWidth,
        labelPlacement = 'start',
        InputProps = {},
        InputLabelProps = {},
        isSelecao = false,
        autoFocus = true,
        testid,
        ...props
    },
    ref
) => {
    const { orientation = 'horizontal', disabled } = props
    const classes = hookStyles[orientation]()

    useAutoFocus(ref, props, autoFocus)

    return (
        <FormControlLabel
            classes={{
                root: clsx({
                    [classes.labelRootSelecao]: isSelecao,
                    [classes.labelRoot]: !isSelecao,
                    [classes.labelSwitch]: true,
                    [classes.doubleLineSwitch]:
                        label && label.search('<br />') > 0,
                }),
                label: classes.labelRoot,
            }}
            disabled={disabled}
            labelPlacement={labelPlacement}
            label={
                label && label.trim() ? (
                    <Label
                        text={label.trim()}
                        style={{ minWidth: labelWidth }}
                    />
                ) : null
            }
            {...InputLabelProps}
            control={
                <Toggle
                    size="small"
                    classes={{
                        root: clsx({
                            [classes.root]: true,
                            [classes.switchRoot]: true,
                        }),
                        thumb: clsx({ [classes.activeThumb]: !disabled }),
                    }}
                    inputProps={{
                        ref: ref,
                        ...InputProps,
                        classes: {
                            root: classes.inputRoot,
                            input: classes.input,
                            formControl: classes.formControl,
                        },
                        'data-testid': testid,
                    }}
                    {...props}
                />
            }
        />
    )
}

const StyledToggle = React.forwardRef(StyledToggleComponent)

StyledToggleComponent.propTypes = {
    label: PropTypes.string,
    labelWidth: PropTypes.number,
    labelPlacement: PropTypes.string,
    InputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    isSelecao: PropTypes.bool,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    orientation: PropTypes.oneOf(['vertical', 'horizontal']),
    testid: PropTypes.string,
}

export default StyledToggle
