import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import Slide1 from '../../../../../../public/img/login-o2-1.jpg'

export default function SliderO2({ classes }) {
    const style = useMemo(() => {
        return {
            backgroundImage: `url(${Slide1})`,
        }
    })

    return (
        <div
            className={clsx({
                [classes.fullbanner]: true,
            })}
            style={style}
        />
    )
}

SliderO2.propTypes = {
    classes: PropTypes.any.isRequired,
}
