export const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
    },
    tab: {
        opacity: 1,
        transition: '0.5s ease-in-out opacity',
        backgroundColor: theme.palette.bodyColor,
        borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
    },
    flex: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    tabContent: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderTop: 0,
        transition: '0.5s ease-in-out opacity',
        opacity: 1,
        position: 'relative',
        zIndex: 0,
    },
    title: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
        fontSize: 20,
        fontWeight: 'bold',
        position: 'relative',
        display: 'inline-block',
        textTransform: 'capitalize',
        lineHeight: 1,
        [theme.breakpoints.down('lg')]: {
            fontSize: theme.spacing(2.25),
        },
        [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(2),
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(1.5),
        },
    },
    disabled: {
        opacity: 1,
    },
    buttonTab: {
        bottom: 0,
    },
    buttonWrapper: {
        display: 'flex',
        position: 'relative',
        '& button': {
            left: 0,
        },
        '&:not(.active) button': {
            borderRight: '1px solid rgba(0,0,0,0.15)',
        },
        '&.active button': {
            borderRadius: theme.spacing(1, 1, 0, 0),
        },
        '&.active::before': {
            content: "' '",
            display: 'block',
            width: 1,
            background: '#f9f9f9',
            position: 'absolute',
            height: '100%',
            left: -1,
            zIndex: 1,
        },
        '&:last-child button': {
            borderRight: 0,
        },
    },
})
