import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import keycodes from '@nerus/framework/common/Keycodes'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
    clearError,
    startLoading,
    stopLoading,
} from '../../../../App/AppActions'
import { withWS } from '../../../Business/Websocket/Context'
import { resetComponents, sendBuffer } from '../../EacActions'
import { getComponentsOfType } from '../../EacReducer'

export class LoginForm extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        ws: PropTypes.object.isRequired,
    }

    static mapStateToProps = state => {
        return {
            app: state.app,
            isConnected: state.eac.connected,
            isLogin: state.eac.isLogin,
            dialogoProgressao: getComponentsOfType('DialogoProgressao', state),
            dialogo: getComponentsOfType('Dialogo', state),
        }
    }

    constructor(props) {
        super(props)

        this.loginRef = React.createRef()
        this.passwordRef = React.createRef()

        this.state = {
            errors: {
                username: null,
                password: null,
            },
            values: {
                username: null,
                password: null,
            },
        }
    }

    handleChange = name => {
        return event => {
            const state = this.state.values
            state[name] = event.target.value
            this.setState({ values: state })
        }
    }

    componentDidUpdate(prevProps) {
        const { errors } = this.state
        const {
            isConnected,
            app: { error },
            dispatch,
        } = this.props
        const loginField = this.loginRef && this.loginRef.current
        const passwordField = this.passwordRef && this.passwordRef.current

        if (isConnected && errors.password) {
            this.setState({ errors: { username: null, password: null } })
        }

        if (error.message && error.message !== prevProps.app.error.message) {
            dispatch(clearError())
            dispatch(stopLoading())
            errors.password = error.message
            this.setState({
                errors,
            })
        }

        if (loginField && !loginField.value && isConnected) {
            loginField.focus()
        }

        if (
            passwordField &&
            loginField.value &&
            loginField !== document.activeElement &&
            isConnected
        ) {
            passwordField.focus()
        }
    }

    handleSubmit = event => {
        event.preventDefault()
        const {
            state: { errors, values },
            props: { ws, dispatch },
        } = this
        if (!values.username) {
            errors.username = 'Este campo é obrigatório'
        } else {
            errors.username = null
        }
        if (!values.password) {
            errors.password = 'Este campo é obrigatório'
        } else {
            errors.password = null
        }

        if (!errors.password && !errors.username) {
            ws.send(sendBuffer([values.username, values.password], 'sendLogin'))
            dispatch(startLoading())
        }

        this.setState({ errors })
    }

    onKeyDown = event => {
        if (event.keyCode === keycodes.ENTER_KEY) {
            this.passwordRef.current.focus()
        }
    }

    doReset = (toLogout = false) => {
        this.props.dispatch(resetComponents(toLogout))
        if (this.props.ws) {
            this.props.ws.close()
        }
    }
    doLogout = () => this.doReset(true)

    render() {
        const {
            isConnected,
            isLogin,
            dialogo,
            dialogoProgressao,
            classes,
            showDisconnect = true,
        } = this.props

        return (
            <form onSubmit={this.handleSubmit} className={classes.form}>
                <TextField
                    fullWidth={true}
                    className={classes.formInput}
                    disabled={!(isConnected && isLogin) || dialogo.length > 0}
                    label="Login *"
                    onChange={this.handleChange('username')}
                    onKeyDown={this.onKeyDown}
                    helperText={this.state.errors.username}
                    error={!!this.state.errors.username}
                    autoComplete="username"
                    inputProps={{
                        ref: this.loginRef,
                    }}
                    InputLabelProps={{
                        classes: {
                            formControl: classes.labelColor,
                            focused: classes.labelFocused,
                            shrink: classes.labelShrink,
                        },
                    }}
                />

                <TextField
                    fullWidth={true}
                    className={classes.formInput}
                    disabled={!(isConnected && isLogin) || dialogo.length > 0}
                    label="Senha *"
                    type="password"
                    onChange={this.handleChange('password')}
                    helperText={this.state.errors.password}
                    error={!!this.state.errors.password}
                    autoComplete="current-password"
                    inputProps={{
                        ref: this.passwordRef,
                    }}
                    InputLabelProps={{
                        classes: {
                            formControl: classes.labelColor,
                            focused: classes.labelFocused,
                            shrink: classes.labelShrink,
                        },
                    }}
                />

                {dialogoProgressao ? (
                    <p className="progressao">{dialogoProgressao.msg1}</p>
                ) : null}

                <div className={classes.actions}>
                    <div className={classes.actionsLeft}>
                        {isConnected && showDisconnect ? (
                            <Button
                                variant="text"
                                color="secondary"
                                onClick={this.doLogout}
                                disabled={!(isConnected && isLogin)}
                                className={classes.fontConthrax}
                            >
                                Desconectar
                            </Button>
                        ) : null}
                    </div>

                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.fontConthrax}
                        disabled={!(isConnected && isLogin)}
                    >
                        Entrar
                    </Button>
                </div>
            </form>
        )
    }
}

LoginForm.propTypes = {
    isConnected: PropTypes.bool,
    isLogin: PropTypes.bool,
    showDisconnect: PropTypes.bool,
    dialogoProgressao: PropTypes.any,
    dialogo: PropTypes.any,
    app: PropTypes.any,
}

export default connect(LoginForm.mapStateToProps)(withWS(LoginForm))
