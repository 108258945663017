import React, { Component } from 'react'
import { connect } from 'react-redux'

import IconButton from './IconButton'

class Pagination extends Component {
    render() {
        return (
            <div className="pagination">
                <IconButton tooltip="Primeiro" />
                <IconButton tooltip="PgUp" />
                <IconButton tooltip="Anterior" />
                <IconButton tooltip="Próximo" />
                <IconButton tooltip="PgDn" />
                <IconButton tooltip="Último" />
            </div>
        )
    }
}

export default connect(null)(Pagination)
