import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

// TODO: Renomear reports para components e allDashboard para dashboards
export const DASHBOARDS_QUERY = gql`
    fragment DashboardList on Dashboard {
        id
        title
        library
        menu {
            id
            title
        }
        components {
            id
            type
        }
        filters {
            id
            type
        }
    }

    query GetDashboards($panel: Boolean, $user_id: Int, $library: Int) {
        allDashboard(panel: $panel, user_id: $user_id, library: $library) {
            ...DashboardList
        }
    }
`

export default function useFetchDashboards(options = {}) {
    const { lazy } = options || {}
    if (lazy) {
        return useLazyQuery(DASHBOARDS_QUERY, {
            displayName: 'fetchDashboards',
            fetchPolicy: 'cache-and-network',
            ...options,
        })
    }
    return useQuery(DASHBOARDS_QUERY, {
        displayName: 'fetchDashboards',
        fetchPolicy: 'cache-and-network',
        ...options,
    })
}
