import React, { Component } from 'react'

/**
 * Página simples que apenas exibe o erro 404
 * Só é exibida se o usuário tentar forçar uma
 * rota inexistente na aplicação ou asset inexistente
 */
class Page404 extends Component {
    render() {
        return (
            <div className="errorPage">
                <div>
                    <div>
                        <h1>404</h1>
                        <h2>Página não encontrada!</h2>
                        <p>Não encontramos a página solicitada.</p>

                        <a
                            onClick={event => {
                                event.preventDefault()
                                window.history.go(-1)
                            }}
                        >
                            Voltar
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Page404
