import withStyles from '@material-ui/core/styles/withStyles'
import { organize } from '@nerus/framework/components/Formulario/Reducer'
import { styles } from '@nerus/styles/components/formulario'
import { connect } from 'react-redux'

import { withWS } from '../../Business/Websocket/Context'
import {
    getActiveComponent,
    getComponentById,
    isActiveComponent,
} from '../../Eac/EacReducer'
import { getActiveField } from '../../Eac/reducers/Formulario'
import BaseFormulario from './BaseFormulario'

/**
 * Componente Formulario
 */
export class Formulario extends BaseFormulario {
    static baseOpts = {
        title: true,
        nospace: true,
    }

    // Renderiza o wrapper
    render() {
        const list = this.props.data?.list ?? []

        if (!list || !list.length || !list[0].columns) {
            return null
        }

        return this.renderForm(list)
    }
}

// Retrieve data from store as props
function mapStateToProps(state, ownProps) {
    const data = getComponentById(ownProps.id, state).payload
    const activeComponent = getActiveComponent(state)

    const isMenuTabs = activeComponent.name === 'Menu'
    const isFormInsideMenuTabs =
        activeComponent?.payload?.id == ownProps?.id ||
        activeComponent?.payload?.content?.id == ownProps?.id

    const active = isMenuTabs
        ? isFormInsideMenuTabs
        : isActiveComponent(ownProps.id, state)

    const list = organize(JSON.parse(JSON.stringify(data.list)))
    let activeElement = getActiveField(data.list)
    if (activeElement.id === undefined) {
        activeElement = { ...data.list[0] }
    }

    // mantém o form ativo se o componente ativo for um HintWeb
    return {
        data: {
            ...data,
            list,
        },
        id: ownProps.id,
        activeElement,
        active: active || activeComponent?.name === 'HintWeb',
        wsStop: state.app.wsStop,
        buffer: state.eac.buffer,
    }
}

export default withWS(connect(mapStateToProps)(withStyles(styles)(Formulario)))
