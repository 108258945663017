import PropTypes from 'prop-types'
import React from 'react'

import useFetchTable from '../../hooks/useFetchTable'
import BaseSelector from '../BaseSelector'

export default function TableSelector({ filter, value, onSave }) {
    const { data, loading } = useFetchTable({
        variables: {
            type: filter.type,
        },
    })

    if (loading) {
        return null
    }

    return (
        <BaseSelector
            asButton
            title={filter.desc}
            label={filter.desc}
            data={data?.fetchTable?.data || []}
            currentData={value}
            onSave={onSave}
        />
    )
}

TableSelector.propTypes = {
    filter: PropTypes.shape({
        id: PropTypes.number || PropTypes.string,
        type: PropTypes.number || PropTypes.object,
        desc: PropTypes.string,
    }),
    value: PropTypes.array,
    onShow: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
}
