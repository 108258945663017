export const styles = theme => ({
    root: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1503,
        position: 'fixed',
        backgroundColor: 'rgba(35, 34, 34, 0.65)',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        background: theme.palette.common.white,
        '&:not(:focus-within)': {
            backgroundColor: 'rgb(255, 255, 254)',
            transition: 'background-color 0.01s',
        },
        minWidth: '340px !important ',
        maxWidth: '95%',
        maxHeight: '95%',
        borderRadius: 2,
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
    },
    containerMedium: {
        minWidth: '600px !important ',
        [theme.breakpoints.down('lg')]: {
            minWidth: '75% !important ',
        },
    },
    containerBig: {
        minWidth: '1100px !important ',
        [theme.breakpoints.down('lg')]: {
            minWidth: '90% !important ',
        },
    },
    containerFullscreen: {
        minWidth: '95% !important',
        minHeight: '95% !important',
    },
    header: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.dark,
        padding: '5px 10px',
        cursor: 'move',
        lineHeight: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    headerClose: {
        marginLeft: theme.spacing(2),
        display: 'inline-flex',
        cursor: 'pointer',
        color: '#fff',
        padding: 1,
        '& svg': {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
    },
    headerlessButton: {
        color: theme.palette.common.black,
        display: 'block',
        alignSelf: 'flex-end',
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
        textAlign: 'right',
        '& svg': {
            cursor: 'pointer',
        },
    },
    title: {
        margin: 0,
        color: theme.palette.contrastText.main,
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: 1.5,
        paddingRight: theme.spacing(1),
        textTransform: 'uppercase',
        flexGrow: 1,
        textAlign: 'left',
    },
    spaced: {
        padding: theme.spacing(1),
    },
    content: {
        fontSize: theme.spacing(2),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100% - 28px)',
        // overflow: 'auto',
        '& p': {
            margin: '0 0 10px',
        },
        '& ul.padded': {
            margin: 0,
            padding: '8px 24px',
        },
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px ${theme.spacing(
            1
        )}px`,
        minHeight: 50,
    },
    scrollable: {
        overflow: 'auto',
    },
})
