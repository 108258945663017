export const styles = theme => {
    return {
        root: {
            padding: 0,
            lineHeight: 1,
            width: 'auto',
            color: '#5e0d8b',
            fontSize: 14,
            margin: '0px 7px',
            whiteSpace: 'nowrap',
            display: 'inline-block',
            [theme.breakpoints.down('lg')]: {
                fontSize: 13,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 12,
            },
        },
    }
}
