/* eslint-disable react/jsx-no-bind */
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { Delete, Edit, Visibility } from '@material-ui/icons'

import { makeStyles } from '@material-ui/styles'
import keycodes from '@nerus/framework/common/Keycodes'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import { useO2 } from '@nerus/framework/hooks/useO2'
import Button from '@nerus/framework/styled/Button'
import { Input } from '@nerus/framework/styled/Input'
import StyledToggle from '@nerus/framework/styled/Toggle'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'

import { getNerusInfo } from '../../NerusWeb/Eac/EacReducer'
import { GET_ME } from '../../NerusWeb/Components/TopBar/UserMenu'
import { isAdmin } from '../../App/AppActions'
import NerusITLayout from '../components/common/Layout'
import RemoveRecord from '../Dashboard/Dialogs/RemoveRecord'
import useFetchEnotices from './hooks/useFetchEnotices'
import useRemoveEnotice from './hooks/useRemoveEnotice'
import useUpdateEnotice from './hooks/useUpdateEnotice'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
    },
    container: {
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    filterActions: {
        display: 'flex',
    },
    inputs: {
        width: '70%',
        display: 'flex',
        gap: 8,
        alignItems: 'end',
    },
    buttons: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    table: {
        display: 'flex',
        marginTop: 16,
    },
    header: {
        border: 'none',
        borderBottom: '2px solid',
        borderBottomColor: '#A52A2A',
    },
    rows: {
        overflowY: 'scroll',
        overflow: 'hidden',
        minHeight: 48,
    },
    colum: {
        textAlign: 'left',
        fontWeight: 'bold',
    },
    columTitle: {
        maxWidth: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    columnIcons: {
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
    },
    toggle: {
        position: 'relative',
    },
    icones: {
        cursor: 'pointer',
    },
}))

/**
 * Componente Enotice
 */

function applyFilter(filter, data) {
    if (!filter.enotno && !filter.title) return data
    else if (filter.enotno && !filter.title) {
        return data.filter(row => row.enotno == filter.enotno)
    } else if (!filter.enotno && filter.title) {
        return data.filter(
            row =>
                row.title.toLowerCase().indexOf(filter.title.toLowerCase()) > -1
        )
    } else {
        return data
            .filter(row => row.enotno == filter.enotno)
            .filter(
                row =>
                    row.title
                        .toLowerCase()
                        .indexOf(filter.title.toLowerCase()) > -1
            )
    }
}

const Toggle = ({ status, onChange, ...props }) => {
    const classes = useStyles()
    const [toggleThisElement, setToggleThisElement] = useState(status)

    return (
        <StyledToggle
            className={classes.toggle}
            InputProps={{}}
            autoFocus={true}
            disabled={false}
            orientation={'horizontal'}
            checked={Boolean(toggleThisElement)}
            onChange={() =>
                onChange() && setToggleThisElement(!toggleThisElement)
            }
            {...props}
        />
    )
}

function Enotice({ history }) {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { loading, data } = useFetchEnotices()
    const [filter, setFilter] = useState({ enotno: '', title: '' })
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(16)
    const { isO2 } = useO2(true)

    const nerusInfo = useSelector(getNerusInfo)
    const admin = useSelector(state => state.app.isAdmin)

    const [updateEnotice] = useUpdateEnotice({})

    const {
        showRemoveDialog,
        onShowRemoveDialog,
        onRemoveEnotice,
        onCloseRemove,
    } = useRemoveEnotice()

    const { data: { me = null } = {} } = useQuery(GET_ME, {
        variables: { usrno: nerusInfo?.usrno },
        skip: !nerusInfo || !nerusInfo.usrno,
    })

    useEffect(() => {
        if (me?.isAdmin) {
            dispatch(isAdmin())
        }
    }, [me, dispatch])

    useKeyboardTrap(
        event => {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                if (showRemoveDialog) {
                    onCloseRemove()
                } else {
                    history.push(isO2 ? '/bin/o2' : '/')
                }
            }
        },
        [history, onCloseRemove, showRemoveDialog]
    )

    const handleChange = useCallback(
        (_event, page) => {
            setPage(page)
        },
        [setPage]
    )

    const handlePageSize = useCallback(event => {
        setPageSize(event.target.value)
    }, [])

    const labelDisplay = useCallback(
        ({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`,
        []
    )

    const enotices = useMemo(() => {
        return applyFilter(filter, data?.allEnotice?.data || [])
    }, [filter, data])

    const handleChangeFilter = useCallback(
        event => {
            const value = event.target.value
            const inputId = event.target.id
            const input = inputId == 0 ? 'enotno' : 'title'
            setFilter({ ...filter, [input]: value })
        },
        [filter]
    )

    const doUpdateEnotice = useCallback(
        async enotice => {
            const { enotno } = enotice
            const ativo = Number(!enotice.ativo)

            await updateEnotice({
                variables: {
                    enotice: { enotno, ativo },
                },
            })
        },
        [updateEnotice]
    )

    const enoticesList = useMemo(() => {
        const startIndex = page * pageSize
        const endIndex = Math.min(startIndex + pageSize, enotices.length)
        return enotices.slice(startIndex, endIndex).map(enotice => {
            return (
                <TableRow
                    key={enotice.enotno}
                    className={classes.rows}
                    data-testid={`enotno-${enotice.enotno}`}
                >
                    <TableCell>{enotice.enotno}</TableCell>
                    <TableCell
                        className={classes.columTitle}
                        data-testid={enotice.title}
                    >
                        {enotice.title}
                    </TableCell>
                    <TableCell>
                        {enotice.agenda ? 'Hora Agendada' : 'Ocorrência'}
                    </TableCell>
                    <TableCell>
                        <Toggle
                            onChange={() => doUpdateEnotice(enotice)}
                            status={Boolean(enotice.ativo)}
                            data-testid={`toggleUpdateEnotice-${enotice.enotno}`}
                        />
                    </TableCell>

                    {/*
                    <TableCell>
                        <GroupIcon className={classes.icones}></GroupIcon>
                    </TableCell>
                    */}

                    {/*
                    <TableCell>
                        <FileCopyIcon className={classes.icones}></FileCopyIcon>
                    </TableCell>
                    */}

                    <TableCell className={classes.columnIcons}>
                        <Tooltip title={'Ver Log'}>
                            <span>
                                <IconButton
                                    id={'verLog'}
                                    data-testid={`verLog-${enotice.enotno}`}
                                    onClick={() => {
                                        history.push(
                                            `/enotice/log/${enotice.enotno}`
                                        )
                                    }}
                                >
                                    <Visibility />
                                </IconButton>
                            </span>
                        </Tooltip>

                        <Tooltip title={'Editar'}>
                            <span>
                                <IconButton
                                    id={'edit'}
                                    data-testid={`edit-${enotice.enotno}`}
                                    onClick={() => {
                                        history.push(
                                            `/enotice/edit/${enotice.enotno}`
                                        )
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            </span>
                        </Tooltip>

                        <Tooltip title={'Remover'}>
                            <span>
                                <IconButton
                                    id={'delet'}
                                    data-testid={`delet-${enotice.enotno}`}
                                    onClick={event => {
                                        event.stopPropagation()
                                        event.preventDefault()

                                        onShowRemoveDialog(enotice.enotno)
                                    }}
                                >
                                    <Delete />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            )
        })
    }, [
        classes.columTitle,
        classes.columnIcons,
        classes.rows,
        doUpdateEnotice,
        enotices,
        history,
        onShowRemoveDialog,
        page,
        pageSize,
    ])

    return admin ? (
        <NerusITLayout>
            <div className={classes.root}>
                <Box className={classes.container}>
                    <Typography variant="h4" style={{ paddingBottom: 16 }}>
                        E-Notice
                    </Typography>

                    <Box className={classes.content}>
                        <Box className={classes.filterActions}>
                            <div className={classes.inputs}>
                                <Input
                                    testid="inputId"
                                    index={0}
                                    componentId={0}
                                    onChange={handleChangeFilter}
                                    type="number"
                                    isTitle={false}
                                    attrib={12}
                                    label={'ID'}
                                    orientation={'horizontal'}
                                    InputProps={{
                                        id: '0',
                                        name: 'inputId',
                                    }}
                                    InputLabelProps={{}}
                                ></Input>

                                <Input
                                    testid="inputTitulo"
                                    index={1}
                                    onChange={handleChangeFilter}
                                    componentId={1}
                                    isTitle={false}
                                    attrib={12}
                                    label={'Título'}
                                    orientation={'horizontal'}
                                    InputProps={{
                                        id: '1',
                                        name: 'inputTitulo',
                                    }}
                                    InputLabelProps={{}}
                                />
                            </div>
                            <div className={classes.buttons}>
                                <Button
                                    dialog
                                    color="danger"
                                    onClick={() => {
                                        history.push('/enotice/create')
                                    }}
                                    lbl={'Novo Enotice'}
                                />
                                {/*
                                <Button
                                    dialog
                                    color="danger"
                                    onClick={() => {
                                        history.push('/enotice/verificador')
                                    }}
                                    lbl={'Verificador'}
                                />
                                */}
                                <Button
                                    dialog
                                    color="danger"
                                    onClick={() => {
                                        history.push('/enotice/log')
                                    }}
                                    lbl={'Ver Logs'}
                                />
                            </div>
                        </Box>

                        <div className={classes.table}>
                            <TableContainer component={Paper}>
                                <Table aria-label="table">
                                    <TableHead className={classes.header}>
                                        <TableRow className={classes.colum}>
                                            <TableCell
                                                width="180px"
                                                className={classes.colum}
                                            >
                                                ID
                                            </TableCell>
                                            <TableCell
                                                width="500px"
                                                className={classes.colum}
                                            >
                                                Título
                                            </TableCell>
                                            <TableCell
                                                width="400px"
                                                className={classes.colum}
                                            >
                                                Tipo
                                            </TableCell>
                                            <TableCell
                                                width="400px"
                                                className={classes.colum}
                                            >
                                                Ativo
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.rows}>
                                        {loading ? (
                                            <TableRow>
                                                <TableCell width="180px"></TableCell>
                                                <TableCell width="500px">
                                                    Carregando...
                                                </TableCell>
                                                <TableCell width="400px"></TableCell>
                                                <TableCell width="400px"></TableCell>
                                            </TableRow>
                                        ) : enotices.length ? (
                                            enoticesList
                                        ) : (
                                            <TableRow>
                                                <TableCell width="180px"></TableCell>
                                                <TableCell width="500px">
                                                    Nenhum enotice encontrado
                                                </TableCell>
                                                <TableCell width="400px"></TableCell>
                                                <TableCell width="400px"></TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>

                                <TablePagination
                                    component="div"
                                    count={enotices.length}
                                    onPageChange={handleChange}
                                    page={page}
                                    rowsPerPageOptions={[8, 12, 16, 24, 32, 40]}
                                    rowsPerPage={pageSize}
                                    labelRowsPerPage="Registros por página:"
                                    nextIconButtonText="Próxima Página"
                                    backIconButtonText="Página Anterior"
                                    labelDisplayedRows={labelDisplay}
                                    onRowsPerPageChange={handlePageSize}
                                />
                            </TableContainer>
                        </div>

                        {showRemoveDialog ? (
                            <RemoveRecord
                                onSubmit={onRemoveEnotice}
                                onClose={onCloseRemove}
                            />
                        ) : null}
                    </Box>
                </Box>
            </div>
        </NerusITLayout>
    ) : (
        <>Sem permissão</>
    )
}

Enotice.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
}

Toggle.propTypes = {
    status: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default Enotice
