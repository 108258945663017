import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import cloneDeep from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { withWS } from '../../Business/Websocket/Context'
import {
    getActiveComponent,
    getComponentById,
    isActiveComponent,
} from '../../Eac/EacReducer'
import { getActiveField } from '../../Eac/reducers/Formulario'
import Editor from '../Editor'
import Rec from '../Formulario/Estrutura/Rec'

export const processRows = (elements, cols, componentId, activeValue) => {
    const rows = []
    if (elements && elements.length) {
        const fields = cloneDeep(elements)
        while (fields.length) {
            const row = []
            cols.forEach(() => {
                let element = fields.shift()
                if (element) {
                    row.push(
                        Object.assign(element, {
                            componentId,
                            activeValue, // isso é um ref, obrigatóriamente
                            key: 'input-editorFormEditPdv-' + element.id,
                            component: Rec,
                            lbl: '',
                            placeholder: element.lbl,
                            column: 0,
                            widths: { '0': 1 },
                            boxWidths: { '0': 1 },
                            InputProps: {
                                disableUnderline: !element.enabled,
                            },
                        })
                    )
                }
                element = null
            })
            rows.push(row)
        }
    }
    return rows
}

export class EditorFormEdit extends EnhancedComponent {
    static mapStateToProps = (state, ownProps) => {
        const EditorForm = getComponentById(ownProps.id, state).payload
        const activeComponent = getActiveComponent(state)
        const active = isActiveComponent(ownProps.id, state)

        return {
            active: active || activeComponent.name === 'HintWeb',
            columns: EditorForm.editor?.data?.cols,
            editorId: EditorForm.editor.id,
            fields: EditorForm.form?.data?.list,
            flt: EditorForm.flt,
            title: EditorForm.editor?.data?.title,
            bt: EditorForm.editor?.data?.bt || [],
        }
    }

    static propTypes = {
        active: PropTypes.bool,
        editorId: PropTypes.string,
        fields: PropTypes.array,
        flt: PropTypes.bool,
        rows: PropTypes.array,
        title: PropTypes.string,
        bt: PropTypes.array,
        isFixo: PropTypes.bool,
        isGuia: PropTypes.bool,
    }

    static baseOpts = {
        title: ({ editor }) => {
            return editor?.data?.title ? editor.data.title : ''
        },
        nospace: true,
        size: ({ editor }) => {
            return editor?.data?.cols?.length > 5 ? 'big' : 'medium'
        },
    }

    render() {
        const {
            id,
            editorId,
            active,
            isGuia,
            fields,
            columns,
            flt,
            bt,
        } = this.props

        const activeElement = getActiveField(fields)
        const activeValue = this.createRef('activeValue', activeElement.value)
        const rows = processRows(fields, columns, id, activeValue)

        return (
            <Editor
                isEditorForm
                isEditorFormEdit
                active={active}
                id={editorId}
                rows={rows}
                isGuia={isGuia}
                showTitle={isGuia && !flt}
                flt={flt}
                bt={bt}
            />
        )
    }
}

export default connect(EditorFormEdit.mapStateToProps)(withWS(EditorFormEdit))
