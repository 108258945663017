import { Chip } from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import makeStyles from '@material-ui/styles/makeStyles'
import keycodes from '@nerus/framework/common/Keycodes'
import IconButton from '@nerus/framework/components/Editor/Estrutura/IconButton'
import { useKeyboardTrap } from '@nerus/framework/hooks/useKeyboardTrap'
import FilterIcon from '@nerus/framework/icons/Filter'
import Button from '@nerus/framework/styled/Button'
import { StyledKeyboardDatePicker } from '@nerus/framework/styled/DatePicker'
import { StyledKeyboardTimePicker } from '@nerus/framework/styled/TimePicker'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useRef, useState } from 'react'
import CurrencyInput from 'react-number-format'
import { useDispatch } from 'react-redux'

import { setWsStop } from '../../../App/AppActions'
import GenericConfirmation from '../Dialogs/GenericConfirmation'
import GenericMessage from '../Dialogs/GenericMessage'
import useFilterTypes from '../hooks/useFilterTypes'
import TableSelector from './FilterConfigHandler/TableSelector'

const useStyles = makeStyles(theme => ({
    iconNoBottom: {
        marginRight: 5,
        marginTop: 2,
    },
    icon: {
        marginBottom: -5,
        marginRight: 5,
    },
    actionsRight: {
        textAlign: 'right',
        padding: theme.spacing(2),
        boxShadow:
            '0 -1px 3px 0 rgb(0 0 0 / 6%), 0 -1px 1px 0 rgb(0 0 0 / 3%), 0 -2px 1px -1px rgb(0 0 0 / 2%)',
    },
    titleInput: {
        fontSize: 12,
    },
    drawer: {
        flexShrink: 0,
    },
    drawerPaper: {
        width: 400,
        border: 'none',
        boxShadow:
            '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    headingBox: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },
    headingConfig: {
        padding: 16,
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        alignItems: 'center',
        display: 'flex',
        '& span': {
            flex: 1,
            alignItems: 'center',
            display: 'flex',
        },
        '& svg:first-child': {
            marginRight: 8,
        },
    },
    headingClose: {
        cursor: 'pointer',
        float: 'right',
    },
    flex: {
        display: 'flex',
        marginBottom: theme.spacing(2),
    },
    boxContent: {
        padding: theme.spacing(1),
        flex: 1,
        margin: 0,
        width: '100%',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
    },
}))

const format = filter => {
    let value = filter.value
    if (filter.type === 2) {
        value = moment(value)
        if (value.isValid()) {
            return value.format('DD/MM/YYYY')
        }
    }

    if (Array.isArray(value) && value.length && value[0].no) {
        return value.map(v => v.no).join(', ')
    }

    return filter.value
}

export default function FilterHandler({
    activeFilters = {},
    filters = [],
    showChips = false,
    onClose,
    onOpen,
    onSave,
    ignoreChanges = false,
}) {
    const dispatch = useDispatch()
    const hasChanges = useRef(false)
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)
    const [showModal, setShowModal] = useState('')
    const [current, setCurrent] = useState(null)
    const classes = useStyles()

    const types = useFilterTypes()

    const handleOnSave = useCallback(() => {
        onSave && onSave(current)
        setCurrent(null)
        dispatch(setWsStop(false))
    }, [onSave, setCurrent, current])

    const onShow = useCallback(() => {
        onOpen && onOpen()
        dispatch(setWsStop(true)) // Pausa a comunicação com o WS
        setCurrent(activeFilters)
    }, [setCurrent, onOpen, activeFilters])

    const doCancel = useCallback(() => {
        setCurrent(null)
        dispatch(setWsStop(false))
        setShowCancelConfirmation(false)
        hasChanges.current = false
        onClose && onClose()
    }, [setCurrent, hasChanges, onClose])

    const onKeyDown = useCallback(
        event => {
            if (event.keyCode === keycodes.ESCAPE_KEY) {
                if (current && !event.defaultPrevented) {
                    event.stopImmediatePropagation()
                    if (showModal) {
                        setShowModal('')
                    } else if (hasChanges.current) {
                        setShowCancelConfirmation(true)
                    } else {
                        doCancel()
                    }
                }
            }
        },
        [
            setShowCancelConfirmation,
            setShowModal,
            hasChanges,
            showModal,
            doCancel,
            current,
        ]
    )

    useKeyboardTrap(onKeyDown, { capture: true })

    const hideCancel = useCallback(() => {
        setShowCancelConfirmation(false)
    }, [setShowCancelConfirmation])

    const handleOnClose = useCallback(() => {
        if (hasChanges.current && !ignoreChanges) {
            setShowCancelConfirmation(true)
        } else {
            doCancel()
        }
    }, [setShowCancelConfirmation, doCancel, hasChanges])

    const hideModal = useCallback(() => {
        setShowModal('')
    }, [setShowModal])

    const handleUpdate = useCallback(
        event => {
            const { name, value, id } = event.target
            hasChanges.current = true
            setCurrent(current => ({
                ...current,
                [name]: { value, id },
            }))
        },
        [setCurrent, hasChanges, setShowModal]
    )

    const appliedFilters = Object.values(activeFilters || {}).filter(f => {
        return Boolean(f.value)
    })

    const onValueChange = useCallback(
        f => values => {
            handleUpdate({
                target: {
                    name: f.key,
                    value: values.floatValue,
                    id: f.id,
                },
            })
        },
        [handleUpdate]
    )

    const onChangePicker = useCallback(
        f => value => {
            handleUpdate({
                target: {
                    name: f.key,
                    value,
                    id: f.id,
                },
            })
        },
        [handleUpdate]
    )

    const onSaveTable = useCallback(
        f => values => {
            handleUpdate({
                target: {
                    name: f.key,
                    value: values,
                    id: f.id,
                },
            })
            dispatch(setWsStop(false))
        },
        [handleUpdate, dispatch]
    )

    let chips = appliedFilters.map(filter => {
        return (
            <Chip
                key={filter.key}
                style={{ marginRight: 8 }}
                label={
                    <span>
                        <strong>{filter.desc}:</strong> {format(filter)}
                    </span>
                }
            />
        )
    })

    return (
        <Fragment>
            {!showChips ? (
                <Badge
                    color="primary"
                    badgeContent={appliedFilters.length}
                    invisible={appliedFilters.length === 0}
                >
                    <IconButton
                        size="small"
                        className={classes.iconNoBottom}
                        tooltip="Filtros"
                        onClick={onShow}
                    />
                </Badge>
            ) : (
                [
                    chips,
                    <IconButton
                        key={'filter-config'}
                        size="small"
                        className={classes.iconNoBottom}
                        tooltip="Filtros"
                        onClick={onShow}
                    />,
                ]
            )}

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={Boolean(current)}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.headingBox}>
                    <Typography className={classes.headingConfig}>
                        <span>
                            <FilterIcon />
                            Filtros
                        </span>
                        <Close
                            className={classes.headingClose}
                            onClick={handleOnClose}
                        />
                    </Typography>
                </div>

                <Grid container spacing={2} className={classes.boxContent}>
                    {(filters || []).map(f => {
                        let component
                        switch (f.type) {
                            case 0:
                                component = (
                                    <CurrencyInput
                                        customInput={TextField}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        allowedDecimalSeparators={[',', '.']}
                                        maxLength={20}
                                        name={f.key}
                                        label={f.desc}
                                        decimalScale={f.prec}
                                        fixedDecimalScale={Boolean(f.prec)}
                                        allowEmptyFormatting={false}
                                        defaultValue={''}
                                        value={current?.[f.key]?.value || null}
                                        fullWidth
                                        allowNegative
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onValueChange={onValueChange(f)}
                                    />
                                )
                                break

                            case 1:
                                component = (
                                    <CurrencyInput
                                        customInput={TextField}
                                        prefix={'R$ '}
                                        name={f.key}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        allowedDecimalSeparators={[',', '.']}
                                        maxLength={20}
                                        label={f.desc}
                                        decimalScale={f.prec}
                                        value={current?.[f.key]?.value || null}
                                        fixedDecimalScale={Boolean(f.prec)}
                                        allowEmptyFormatting={false}
                                        defaultValue={''}
                                        fullWidth
                                        allowNegative
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onValueChange={onValueChange(f)}
                                        // onValueChange
                                    />
                                )
                                break

                            case 2:
                                component = (
                                    <StyledKeyboardDatePicker
                                        autoOk
                                        autoFocusOnBlur={false}
                                        dispatchToWs={false}
                                        autoFocus={false}
                                        fieldType={{ typeRec: 'REC_DATE' }}
                                        views={['year', 'month', 'date']}
                                        format={'DD/MM/YYYY'}
                                        orientation="vertical"
                                        value={current?.[f.key]?.value || null}
                                        label={f.desc}
                                        name={f.key}
                                        fullWidth
                                        overwrite
                                        InputProps={{
                                            disableUnderline: false,
                                        }}
                                        onChange={onChangePicker(f)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )
                                break

                            case 3:
                                component = (
                                    <StyledKeyboardTimePicker
                                        autoOk
                                        name={f.key}
                                        autoFocusOnBlur={false}
                                        dispatchToWs={false}
                                        autoFocus={false}
                                        fieldType={{ typeRec: 'REC_TIME' }}
                                        views={['hours', 'minutes', 'seconds']}
                                        format={'HH:mm:ss'}
                                        orientation="vertical"
                                        value={current?.[f.key]?.value || null}
                                        label={f.desc}
                                        fullWidth
                                        overwrite
                                        onChange={onChangePicker(f)}
                                        InputProps={{
                                            disableUnderline: false,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )
                                break

                            case 4:
                                component = (
                                    <StyledKeyboardDatePicker
                                        autoOk
                                        name={f.key}
                                        autoFocusOnBlur={false}
                                        dispatchToWs={false}
                                        autoFocus={false}
                                        fieldType={{
                                            typeRec: 'REC_DATEYM',
                                        }}
                                        views={['year', 'month']}
                                        format={'MM/YYYY'}
                                        orientation="vertical"
                                        label={f.desc}
                                        value={current?.[f.key]?.value || null}
                                        fullWidth
                                        overwrite
                                        onChange={onChangePicker(f)}
                                        InputProps={{
                                            disableUnderline: false,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )
                                break
                        }

                        if (!component && f.type > 4 && types.byType(f.type)) {
                            component = (
                                <TableSelector
                                    filter={f}
                                    value={current?.[f.key]?.value || []}
                                    onSave={onSaveTable(f)}
                                />
                            )
                        }

                        if (!component) {
                            return null
                        }

                        return (
                            <Grid
                                key={f.id}
                                item
                                xs={12}
                                className={classes.flex}
                            >
                                {component}
                            </Grid>
                        )
                    })}
                </Grid>

                <div className={classes.actionsRight}>
                    <Button
                        lbl="Cancelar"
                        dialog
                        color="danger"
                        onClick={handleOnClose}
                    />

                    <Button
                        lbl="Confirmar"
                        primary
                        dialog
                        onClick={handleOnSave}
                    />
                </div>
            </Drawer>

            {showCancelConfirmation ? (
                <GenericConfirmation
                    onSubmit={doCancel}
                    onClose={hideCancel}
                    message="Você poderá perder qualquer alteração realizada, confirma?"
                />
            ) : null}

            {showModal ? (
                <GenericMessage onClose={hideModal} message={showModal} />
            ) : null}
        </Fragment>
    )
}

FilterHandler.propTypes = {
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    activeFilters: PropTypes.object,
    filters: PropTypes.array,
    showChips: PropTypes.bool,
    ignoreChanges: PropTypes.bool,
}
