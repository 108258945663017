export const styles = theme => ({
    root: {},
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    previewer: {
        display: 'flex',
        margin: '0 0 40px',
        whiteSpace: 'nowrap',
        overflow: 'auto',
        width: '210mm',
        height: '200mm',
    },
    preview: {
        whiteSpace: 'pre',
        fontFamily: theme.palette.families.monospace,
        wordBreak: 'break-word',
        fontSize: '11px',
    },
    fontActions: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    action: {
        display: 'inline-block',
        padding: '5px 10px',
        color: '#433f3f',
        fontFamily: theme.palette.families.titles,
    },
    buttonsHolder: {
        height: 'auto !important',
        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.6)',
        transform: 'scale(1.05) translateY(-20px)',
        background: '#c7c6c5',
    },
    button: {
        backgroundColor: '#c7c6c5',
        transition: 'all 0.1s ease-in-out !important',
        fontFamily: theme.palette.families.titles,
        position: 'relative',
        '& .highlight': {
            color: theme.palette.primary.dark,
        },
        '&::before': {
            left: 0,
            bottom: 0,
            width: '1px',
            content: '" "',
            height: '90%',
            display: 'block',
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
        '&:last-child::after': {
            right: 0,
            bottom: 0,
            width: '1px',
            content: '" "',
            height: '90%',
            display: 'block',
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
        '&:hover': {
            zIndex: 1,
            transform: 'scale(1.1)',
            backgroundColor: theme.palette.primary.dark,
            '& .highlight, & span, & div': {
                color: theme.palette.common.white,
            },
        },
    },
    buttonLabel: {
        fontSize: 12,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    buttonWrapper: {
        color: theme.palette.primary.dark,
        '& svg': {
            width: 50,
            height: 50,
            marginBottom: 10,
        },
    },
    boleto: {
        display: 'flex',
        flex: 1,
        border: 'none',
    },
})
