/**
 * Define o tema que está em uso do MaterialUI
 *
 * @type {[type]}
 */
class newSessionStorage {
    static prefix = 'nerus_'

    get(key) {
        if (typeof sessionStorage !== 'undefined') {
            const value = sessionStorage.getItem(newSessionStorage.prefix + key)
            try {
                const json = JSON.parse(value)
                return json
            } catch (e) {
                /*
                 * É esperado que o JSON.parse falhe
                 * mas não é problema, por isso, não
                 * fazemos nada
                 */
            }
            return value
        }

        return undefined
    }

    set(key, value) {
        if (typeof sessionStorage !== 'undefined') {
            let pval = value
            if (typeof pval === 'object') {
                pval = JSON.stringify(pval)
            }

            return sessionStorage.setItem(newSessionStorage.prefix + key, pval)
        }

        return undefined
    }

    remove(key) {
        if (typeof sessionStorage !== 'undefined') {
            return sessionStorage.removeItem(key)
        }

        return undefined
    }

    clear() {
        if (typeof sessionStorage !== 'undefined') {
            // o sessionStorage.clear() tem alguns bugs
            // Ref: https://stackoverflow.com/questions/7667958/clearing-sessionstorage-in-javascript#comment86201589_7667973
            return Object.keys(sessionStorage).forEach(key => {
                sessionStorage.removeItem(key)
            })
            // return sessionStorage.clear();
        }

        return undefined
    }
}

export default new newSessionStorage()
