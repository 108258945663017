import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function DashboardLibraryIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 90 90">
            <g>
                <path d="M73.125 16.875V28.125H16.875V16.875H73.125ZM73.125 11.25H16.875C15.3832 11.25 13.9524 11.8426 12.8975 12.8975C11.8426 13.9524 11.25 15.3832 11.25 16.875V28.125C11.25 29.6168 11.8426 31.0476 12.8975 32.1025C13.9524 33.1574 15.3832 33.75 16.875 33.75H73.125C74.6168 33.75 76.0476 33.1574 77.1025 32.1025C78.1574 31.0476 78.75 29.6168 78.75 28.125V16.875C78.75 15.3832 78.1574 13.9524 77.1025 12.8975C76.0476 11.8426 74.6168 11.25 73.125 11.25ZM28.125 45V73.125H16.875V45H28.125ZM28.125 39.375H16.875C15.3832 39.375 13.9524 39.9676 12.8975 41.0225C11.8426 42.0774 11.25 43.5082 11.25 45V73.125C11.25 74.6168 11.8426 76.0476 12.8975 77.1025C13.9524 78.1574 15.3832 78.75 16.875 78.75H28.125C29.6168 78.75 31.0476 78.1574 32.1025 77.1025C33.1574 76.0476 33.75 74.6168 33.75 73.125V45C33.75 43.5082 33.1574 42.0774 32.1025 41.0225C31.0476 39.9676 29.6168 39.375 28.125 39.375ZM73.125 45V73.125H45V45H73.125ZM73.125 39.375H45C43.5082 39.375 42.0774 39.9676 41.0225 41.0225C39.9676 42.0774 39.375 43.5082 39.375 45V73.125C39.375 74.6168 39.9676 76.0476 41.0225 77.1025C42.0774 78.1574 43.5082 78.75 45 78.75H73.125C74.6168 78.75 76.0476 78.1574 77.1025 77.1025C78.1574 76.0476 78.75 74.6168 78.75 73.125V45C78.75 43.5082 78.1574 42.0774 77.1025 41.0225C76.0476 39.9676 74.6168 39.375 73.125 39.375Z" />
            </g>
        </SvgIcon>
    )
}
