import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import PdfLayoutIcon from '@material-ui/icons/PictureAsPdf'
import keycodes from '@nerus/framework/common/Keycodes'
import BaseFlutuante from '@nerus/framework/components/Base/Flutuante'
import StyledButton from '@nerus/framework/components/Button'
import Dialogo from '@nerus/framework/components/Dialogo'
import ExcelIcon from '@nerus/framework/icons/Excel'
import FileViewIcon from '@nerus/framework/icons/FileView'
import INerusIcon from '@nerus/framework/icons/INerus'
import PdfIcon from '@nerus/framework/icons/Pdf'
import PrintIcon from '@nerus/framework/icons/Print'
import Axios from 'axios'
import * as PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'

import { setWsStop } from '../../../App/AppActions'
import { formatTooltip } from '../../Business/Keyboard'
import { handleKeyboard } from '../../Eac/reducers/RelatorioDialog'

/**
 * Classe base do RelatorioDialog
 */
export default class BaseRelatorioDialog extends Component {
    static propTypes = {
        data: PropTypes.any,
        classes: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        ws: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            dialog: null,
            data: props.data,
            initialSize: 11,
            activeSize: null,
        }
    }

    handleKeyForPdf = event => {
        event.stopPropagation()
        event.preventDefault()

        if (event.keyCode === keycodes.ESCAPE_KEY) {
            this.closePdf()
        }
    }

    viewPdf = e => {
        e.preventDefault()
        this.props.dispatch(setWsStop(true))
        this.fetchPdf()
    }

    choosePdf = () => {
        const localFileName = this.props.data.localFileName
        const { orientation = 'portrait' } = this.state
        window.open(
            `${window.location.protocol}//${window.location.host}/webprint/pdf${localFileName}.pdf?preview=false&orientation=${orientation}`,
            '_blank'
        )
    }

    fetchPdf = () => {
        const localFileName = this.props.data.localFileName
        const { orientation = 'portrait' } = this.state
        this.setState(
            {
                viewPdf: true,
                pdfLoading: true,
            },
            () => {
                Axios.get(
                    `${window.location.protocol}//${window.location.host}/webprint/pdf${localFileName}.pdf?preview=true&orientation=${orientation}`,
                    {
                        responseType: 'arraybuffer',
                    }
                )
                    .then(resp => {
                        this.setState(
                            {
                                pdf: resp.data,
                                pdfLoading: false,
                                pdfLink: URL.createObjectURL(
                                    new Blob([resp.data], {
                                        type: 'application/pdf',
                                    })
                                ),
                            },
                            () => {
                                window.addEventListener(
                                    'keydown',
                                    this.handleKeyForPdf,
                                    { capture: true }
                                )
                            }
                        )
                    })
                    .catch(() => {
                        this.setState({ preview: false })
                    })
            }
        )
    }

    closePdf = e => {
        e?.preventDefault?.()
        this.props.dispatch(setWsStop(false))
        this.setState({
            viewPdf: false,
        })
        window.removeEventListener('keydown', this.handleKeyForPdf, {
            capture: true,
        })
    }

    raiseFont = e => {
        e.preventDefault()
        let { activeSize, initialSize } = this.state
        if (!activeSize) {
            activeSize = initialSize
        }

        if (activeSize > 20) {
            return
        }

        this.setState({
            activeSize: activeSize + 2,
        })
    }

    reduceFont = e => {
        e.preventDefault()
        let { activeSize, initialSize } = this.state
        if (!activeSize) {
            activeSize = initialSize
        }

        if (activeSize < 6) {
            return
        }

        this.setState({
            activeSize: activeSize - 2,
        })
    }

    componentDidMount() {
        let { localFileName } = this.props.data

        Axios.get(
            `${window.location.protocol}//${window.location.host}/webprint/load${localFileName}.prev.txt`
        )
            .then(resp => {
                this.setState({
                    preview: `${resp.data}`,
                })
            })
            .catch(() => {
                this.setState({ preview: false })
            })

        document.getElementById('hidden-download').onload = e => {
            try {
                const err = JSON.parse(e.path[0].contentDocument.body.innerText)
                if (err && err.message) {
                    this.setState({
                        dialog: {
                            title: 'Erro',
                            message: err.message,
                        },
                    })
                }
            } catch (e) {
                // empty
            }
        }
    }

    componentWillUnmount() {
        document.getElementById('hidden-download').onload = null
    }

    handleDialogClose = () => {
        this.setState({ dialog: null })
    }

    handleClick = o => () => {
        handleKeyboard(this.props.ws)(this.props.dispatch, o, this.props.data)
    }

    closeRelatorio = () => {
        handleKeyboard(this.props.ws)(
            this.props.dispatch,
            { keyCode: keycodes.ESCAPE_KEY },
            this.props.data
        )
    }

    changeOrientation = (a, orientation) => {
        this.setState(
            {
                orientation,
            },
            this.fetchPdf
        )
    }

    renderDialog = () => {
        let { preview, viewPdf, pdfLink } = this.state
        const { classes, data } = this.props
        if (!preview) {
            preview = data.preview
        }

        const { opt, title, localFileName } = data
        const opts = {
            action: '',
            method: 'POST',
            className: classes.root,
            autoComplete: 'off',
        }

        const style = {}
        if (this.state.activeSize) {
            style.fontSize = this.state.activeSize
        }

        const titleFlutuante = title || `Opções de Impressão: ${localFileName}`

        return (
            <Fragment>
                <BaseFlutuante
                    onClose={this.closeRelatorio}
                    title={titleFlutuante}
                >
                    <Dialogo
                        open={Boolean(this.state.dialog)}
                        title={this.state.dialog?.title || ''}
                        msg={this.state.dialog?.message || ''}
                        onClose={this.handleDialogClose}
                        optType={-1}
                    />

                    <div {...opts}>
                        <div className={classes.header}>
                            <Typography variant={'h2'}>
                                Pré-visualização
                            </Typography>

                            <div className={classes.fontActions}>
                                <Link
                                    href="#"
                                    className={classes.action}
                                    onClick={this.reduceFont}
                                >
                                    -a
                                </Link>
                                <Link
                                    href="#"
                                    className={classes.action}
                                    onClick={this.raiseFont}
                                >
                                    +A
                                </Link>
                            </div>
                        </div>

                        <div className={classes.previewer}>
                            {preview ? (
                                <div
                                    className={classes.preview}
                                    style={style}
                                    dangerouslySetInnerHTML={{
                                        __html: preview.replace(
                                            /\n/g,
                                            '<br />'
                                        ),
                                    }}
                                />
                            ) : (
                                'Sem pré-visualização'
                            )}
                        </div>

                        <BottomNavigation
                            id="file-form"
                            component="form"
                            method="post"
                            action={`${window.location.protocol}//${window.location.host}/webprint/xls`}
                            target="hidden-download"
                            showLabels
                            className={classes.buttonsHolder}
                        >
                            <input
                                type="hidden"
                                value={this.props?.data?.localFileName || ''}
                                name="file"
                            />
                            <input
                                type="hidden"
                                value={JSON.stringify(
                                    this.props?.data?.xls || {}
                                )}
                                name="params"
                            />
                            {opt.map(o => {
                                const getIcon = opt => {
                                    let className = opt
                                        .toLowerCase()
                                        .trim()
                                        .replace(/ /g, '-')
                                    if (
                                        o.opt.indexOf('Nérus') > -1 ||
                                        o.opt.indexOf('Nerus') > -1
                                    ) {
                                        className = 'inerus'
                                    }

                                    switch (className) {
                                        case 'excel':
                                            return <ExcelIcon />
                                        case 'pdf':
                                            return <PdfIcon />
                                        case 'pdfl':
                                            return <PdfLayoutIcon />
                                        case 'imprime':
                                        case 'imprimir':
                                        case 'imprime+':
                                        case 'imprimir+':
                                        case 'imprime-+':
                                        case 'imprimir-+':
                                            return <PrintIcon />
                                        case 'inerus':
                                            return <INerusIcon />
                                        case 'consulta':
                                            return <FileViewIcon />
                                    }
                                }

                                if (
                                    o.opt === 'Imprime' ||
                                    o.opt.toLowerCase() === 'imprime +' ||
                                    o.opt.toLowerCase() === 'imprime+' ||
                                    o.opt === 'Imprimir'
                                ) {
                                    return (
                                        <Tooltip
                                            title={`Imprime em ${data.prn} (${data.or})`}
                                            placement="bottom"
                                        >
                                            <BottomNavigationAction
                                                href="#"
                                                classes={{
                                                    root: classes.button,
                                                    label: classes.buttonLabel,
                                                    wrapper:
                                                        classes.buttonWrapper,
                                                }}
                                                key={o.opt}
                                                icon={getIcon(o.opt)}
                                                label={formatTooltip(
                                                    o.opt,
                                                    o.key,
                                                    true,
                                                    true
                                                )}
                                                onClick={this.handleClick(o)}
                                            />
                                        </Tooltip>
                                    )
                                }

                                if (o.opt.toLowerCase() === 'pdf') {
                                    return [
                                        <BottomNavigationAction
                                            href="#"
                                            key="pdf"
                                            classes={{
                                                root: classes.button,
                                                label: classes.buttonLabel,
                                                wrapper: classes.buttonWrapper,
                                            }}
                                            icon={getIcon(o.opt)}
                                            label={formatTooltip(
                                                o.opt,
                                                o.key,
                                                true,
                                                true
                                            )}
                                            onClick={this.handleClick(o)}
                                        />,

                                        <BottomNavigationAction
                                            href="#"
                                            classes={{
                                                root: classes.button,
                                                label: classes.buttonLabel,
                                                wrapper: classes.buttonWrapper,
                                            }}
                                            key="pdfL"
                                            id="print-pdfl"
                                            icon={getIcon('pdfl')}
                                            label={formatTooltip(
                                                'PDF Layout',
                                                'L',
                                                true,
                                                true
                                            )}
                                            onClick={this.viewPdf}
                                        />,
                                    ]
                                }

                                return (
                                    <BottomNavigationAction
                                        href="#"
                                        classes={{
                                            root: classes.button,
                                            label: classes.buttonLabel,
                                            wrapper: classes.buttonWrapper,
                                        }}
                                        key={o.opt}
                                        icon={getIcon(o.opt)}
                                        label={formatTooltip(
                                            o.opt,
                                            o.key,
                                            true,
                                            true
                                        )}
                                        onClick={this.handleClick(o)}
                                    />
                                )
                            })}
                        </BottomNavigation>

                        <iframe
                            id="hidden-download"
                            name="hidden-download"
                            style={{
                                display: 'none',
                                visibility: 'hidden',
                            }}
                            src="about:blank"
                        />
                    </div>
                </BaseFlutuante>

                {viewPdf ? (
                    <BaseFlutuante
                        onClose={this.closePdf}
                        handleClose={this.closePdf}
                        title="Pré-visualizar PDF"
                        size="fullscreen"
                        actions={[
                            <StyledButton
                                tabIndex={0}
                                key={0}
                                onClick={this.choosePdf}
                                primary={true}
                                dialog
                            >
                                Confirmar{' '}
                            </StyledButton>,
                            <StyledButton
                                tabIndex={1}
                                key={1}
                                onClick={this.closePdf}
                                primary={false}
                                dialog
                            >
                                Cancelar
                            </StyledButton>,
                        ]}
                    >
                        <div
                            className={classes.root}
                            style={{
                                flex: 1,
                                flexDirection: 'column',
                                display: 'flex',
                            }}
                        >
                            <div
                                className={classes.fontActions}
                                style={{
                                    flex: 0,
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <strong style={{ marginRight: 8 }}>
                                    Selecione o formato do PDF:
                                </strong>
                                <RadioGroup
                                    row
                                    aria-label="orientation"
                                    defaultValue="portrait"
                                    name="radio-buttons-group"
                                    onChange={this.changeOrientation}
                                >
                                    <FormControlLabel
                                        value="portrait"
                                        control={<Radio />}
                                        label="Retrato"
                                    />
                                    <FormControlLabel
                                        value="landscape"
                                        control={<Radio />}
                                        label="Paisagem"
                                    />
                                </RadioGroup>
                            </div>

                            {pdfLink ? (
                                <iframe
                                    src={`${pdfLink}#toolbar=0`}
                                    width="100%"
                                    style={{
                                        flex: 1,
                                        border: '1px solid #909090',
                                    }}
                                />
                            ) : null}
                        </div>
                    </BaseFlutuante>
                ) : null}
            </Fragment>
        )
    }
}
