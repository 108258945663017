import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import ActionClose from '@material-ui/icons/Close'
import keycodes from '@nerus/framework/common/Keycodes'
import { styles } from '@nerus/styles/components/flutuante'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import Draggable from 'react-draggable'

import EnhancedComponent from '../../common/EnhancedComponent'

export class BaseFlutuante extends EnhancedComponent {
    static propTypes = {
        title: PropTypes.string,
        children: PropTypes.any.isRequired,
        className: PropTypes.string,
        actions: PropTypes.node,
        data: PropTypes.object,
        centered: PropTypes.bool,
        onClose: PropTypes.func,
        onClick: PropTypes.func,
        classes: PropTypes.object,
        nospace: PropTypes.bool,
        noheader: PropTypes.bool,
        scroll: PropTypes.bool,
        size: PropTypes.string,
        contentRef: PropTypes.any,
    }

    state = {
        centerPosition: { x: 0, y: 0 },
        open: true,
    }

    onStart = () => {
        this.setState({ activeDrags: 1 })
    }

    onStop = (e, data) => {
        this.setState({ activeDrags: 0, centerPosition: data })
    }

    componentDidMount() {
        const ref = this.getRef('dialogFlex')
        if (ref) {
            if (this.props.centered) {
                setTimeout(() => {
                    const windowHeight = window.innerHeight
                    const dialogHeight = ref.offsetHeight
                    this.setState({
                        centerPosition: {
                            x: 0,
                            y: (windowHeight - dialogHeight) / 2,
                        },
                    })
                }, 0)

                const focusableElementsString =
                    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]'
                let focusableElements = ref.querySelectorAll(
                    focusableElementsString
                )
                focusableElements = Array.prototype.slice.call(
                    focusableElements
                )
                this.firstTabStop = focusableElements[0]
                this.lastTabStop =
                    focusableElements[focusableElements.length - 1]
                ref.addEventListener('keydown', this._onKeyDown)
            }
        }
    }

    _onKeyDown = e => {
        // Check for TAB key press
        if (e.keyCode === keycodes.TAB_KEY) {
            // SHIFT + TAB
            if (e.shiftKey) {
                if (document.activeElement === this.firstTabStop) {
                    e.preventDefault()
                    this.lastTabStop.focus()
                }

                // TAB
            } else {
                if (document.activeElement === this.lastTabStop) {
                    e.preventDefault()
                    this.firstTabStop.focus()
                }
            }
        }

        // ESCAPE
        if (e.keyCode === keycodes.ESCAPE_KEY) {
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation && e.stopImmediatePropagation()
            this.onClose(e)
        }
    }

    toggleClose = callback =>
        this.setState({ open: !this.state.open }, callback)

    onClose = event => {
        event?.stopPropagation?.()
        if (this.props.onClose) {
            this.props.onClose(event)
        } else {
            this.toggleClose()
        }
    }

    render() {
        const dragHandlers = {
            onStart: this.onStart,
            onMouseDown: this.onStart,
            onStop: this.onStop,
        }
        const { centerPosition } = this.state
        const {
            title,
            children,
            centered,
            classes,
            actions,
            size,
            nospace,
            noheader,
            contentRef,
            scroll,
            className,
        } = this.props

        return (
            <div
                className={clsx(classes.root, {
                    [classes.open]: true,
                })}
                ref={contentRef}
            >
                <Draggable
                    position={centered ? centerPosition : null}
                    handle={`.${classes.header}`}
                    {...dragHandlers}
                >
                    <div
                        className={clsx(
                            classes.container,
                            size === 'medium'
                                ? classes.containerMedium
                                : undefined,
                            size === 'big' ? classes.containerBig : undefined,
                            size === 'fullscreen'
                                ? classes.containerFullscreen
                                : undefined,
                            {
                                [className]: className,
                            }
                        )}
                        ref={this.createRef('dialogFlex')}
                    >
                        {noheader ? (
                            <IconButton
                                className={clsx({
                                    [classes.headerClose]: true,
                                    [classes.headerlessButton]: true,
                                })}
                                onClick={this.onClose}
                                onTouchStart={this.onClose}
                            >
                                <ActionClose color="inherit" />
                            </IconButton>
                        ) : (
                            <div className={classes.header}>
                                {title ? (
                                    <Typography
                                        variant={'h3'}
                                        className={classes.title}
                                    >
                                        {title}
                                    </Typography>
                                ) : null}
                                <IconButton
                                    className={classes.headerClose}
                                    onClick={this.onClose}
                                    onTouchStart={this.onClose}
                                >
                                    <ActionClose color="inherit" />
                                </IconButton>
                            </div>
                        )}

                        <div
                            className={clsx(classes.content, {
                                [classes.spaced]: !nospace,
                                [classes.scrollable]: scroll,
                            })}
                        >
                            {children}
                        </div>

                        {actions ? (
                            <div className={classes.actions}>{actions}</div>
                        ) : (
                            ''
                        )}
                    </div>
                </Draggable>
            </div>
        )
    }
}

export default withStyles(styles)(BaseFlutuante)
