import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export default function ReturnIcon(props) {
    return (
        <SvgIcon {...props}>
            <g transform="matrix(0.024,0,0,-0.024,0.012,19.2)">
                <path d="M 88,300 911,800 V -200 Z" />
            </g>
        </SvgIcon>
    )
}
