import cloneDeep from 'lodash/cloneDeep'

import EditorForm from './EditorForm'
import { getActiveField } from './Formulario'

export default class EditorFormEdit extends EditorForm {
    static before({ state, action }) {
        const fields = cloneDeep(action.payload.form.data.list)

        action.payload.ws.retType = action.payload.form.retType
        state.menusLifo = []

        const activeElement = getActiveField(fields)

        action.payload.editor.data.rows = []

        /*
        Porque isso???
        while (fields.length) {
            action.payload.editor.data.cols.forEach(() => {
                const element = fields.shift()
                if (element) {
                    element.activeElement = activeElement
                }
            })
        }
        */

        // 88273 - substituir apenas se houver um elemento ativo e ele possuir botões
        if (activeElement?.bt?.length > 0) {
            action.payload.ws.data = {
                x: activeElement.x,
                y: activeElement.y,
            }

            while (action.payload.editor.data?.bt?.length) {
                action.payload.editor.data.bt.pop()
            }

            for (const newBt of activeElement?.bt) {
                action.payload.editor.data?.bt?.push(newBt)
            }

            action.payload.editor.data.bt = activeElement.bt
        } else if (activeElement?.enabled) {
            action.payload.editor.data?.bt?.splice(0)
        }

        action.payload.hasTripleId = true

        return super.before({ state, action })
    }

    static buildDefaultsDeepSource(payload = {}) {
        payload.editor?.data?.clrs?.forEach(clr => {
            delete clr.lns, delete clr.cols
        })

        return payload
    }
}
