import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useDrop } from 'react-dnd'

export default function DroppableContent({ children, className }) {
    const ref = useRef(null)
    const [, drop] = useDrop(() => ({
        accept: ['handle'],
        hover: (item, monitor) => {
            const difference = monitor.getDifferenceFromInitialOffset()

            if (item && difference) {
                const { setDashboard, currentItem } = item
                let size = currentItem.size
                let height = currentItem.height

                if (difference.x < 0) {
                    size -= Math.floor(Math.abs(difference.x / 64))
                } else {
                    size += Math.floor(difference.x / 64)
                }

                if (difference.y < 0) {
                    height -= Math.floor(Math.abs(difference.y / 64))
                } else {
                    height += Math.floor(difference.y / 64)
                }

                if (size < 1) {
                    size = 1
                }

                if (size > 11) {
                    size = 12
                }

                if (height > 11) {
                    height = 12
                }

                setDashboard(dashboard => {
                    return {
                        ...dashboard,
                        components: dashboard.components.map(c => ({
                            ...c,
                            height: c.id === currentItem.id ? height : c.height,
                            size: c.id === currentItem.id ? size : c.size,
                        })),
                    }
                })
            }
        },
    }))

    drop(ref)

    return (
        <div ref={ref} className={className}>
            {children}
        </div>
    )
}

DroppableContent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
}
