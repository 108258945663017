import { Input } from '@nerus/framework/styled/Input'
import PropTypes from 'prop-types'
import React from 'react'

import AbstractTextField from './AbstractTextField'

/**
 * Componente TextFieldString
 *
 * Rec: REC_STRING
 *
 * Renderiza um campo de texto
 */
export class TextFieldString extends AbstractTextField {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    }

    beforeSend = value => {

        //TICKET 96762 - Retira acentos, cedilhas dos campos de textos
        let parsedValue = value.normalize('NFD').replace(/\p{Mn}/gu, "")

        const { sz } = this.props
        if (parsedValue.length !== sz) {
            const missingLength = sz - parsedValue.length
            parsedValue =
                parsedValue +
                ' '.repeat(
                    isNaN(missingLength) || missingLength <= 0
                        ? 0
                        : missingLength
                )
        }
        return parsedValue
    }

    render() {
        const { hasError, value } = this.state
        const {
            enabled,
            lbl,
            sz,
            x,
            y,
            inputWidth,
            labelWidth,
            componentId,
            InputProps,
            onKeyDown,
            orientation = 'horizontal',
            dispatch,
            typeRec,
            bt,
            ws,
        } = this.props

        if (hasError) {
            return this.renderError()
        }

        const fieldType = this.getFieldType()
        const type = fieldType ? fieldType.type : 'text'
        return (
            <Input
                label={lbl}
                btns={bt}
                ws={ws}
                ref={this.createRef('input')}
                value={value}
                componentId={componentId}
                overwrite={value.length === sz}
                forceOverwrite={value.length === sz}
                overwriteOnFirst={typeRec === 'REC_PRDNO'}
                dispatch={dispatch}
                disabled={!enabled}
                position={{ x, y }}
                inputWidth={inputWidth}
                labelWidth={labelWidth}
                orientation={orientation}
                InputProps={InputProps}
                autoComplete="off"
                type={type}
                maxLength={sz}
                fieldType={fieldType}
                beforeSend={this.beforeSend}
                onChange={this.handleInputChange}
                dispatchToWs={true}
                onKeyDown={onKeyDown}
            />
        )
    }
}

export default TextFieldString
