export const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
    },
    secondaryRoot: {
        flexGrow: 0,
        height: 'auto',
        marginTop: theme.spacing(2),
    },
})
