import cloneDeep from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import EnhancedComponent from '../../common/EnhancedComponent'
import StyledButton from '../../styled/Button'
import Actions from './Estrutura/Actions'
import Rec from './Estrutura/Rec'
import { getActiveElement, organize } from './Reducer'

/**
 * Classe base do Formulário
 */
export default class BaseFormulario extends EnhancedComponent {
    static propTypes = {
        list: PropTypes.array,
        active: PropTypes.bool,
        submitLabel: PropTypes.string,
        leftLabel: PropTypes.bool,
        hideSubmit: PropTypes.bool,
        useDiv: PropTypes.bool,
        onSubmit: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onClose: PropTypes.func,
    }

    activeElement = null
    elementsIndex = 0

    getFields = props => {
        const list = props ? props.list : this.props.list
        // No framework, quando não passamos valor pro enabled, por padrão, é ativo.
        return list.map(e => {
            if (e.enabled === undefined) {
                return {
                    ...e,
                    enabled: true,
                }
            }
            return e
        })
    }

    constructor(props) {
        super(props)
        const fields = this.getFields(props)
        const list = organize(fields, 0, true)
        const values = {}
        fields.forEach(item => (values[item.name] = item.value))
        let activeField = 0
        let af = getActiveElement(list, activeField)
        while (!af.enabled && activeField <= fields.length) {
            activeField++
            af = getActiveElement(list, activeField)
        }
        this.state = {
            wrapperClass: 'formulario',
            formClass: 'mainForm',
            forms: list,
            values,
            activeField,
            actions: af.bt,
            walked: false,
            activeValue: null,
        }

        if (this.state.forms[0].isOneColumn) {
            this.state.formClass += ' label-up'
        }
    }

    componentDidUpdate(prevProps) {
        const activeField = this.state.activeField
        const list = organize(this.getFields(this.props), 0, true)
        const af = getActiveElement(list, activeField)
        if (this.state.forms !== list && this.props.list !== prevProps.list) {
            const values = this.props.list.reduce((fields, field) => {
                if (!field.name) {
                    return fields
                }

                return {
                    ...fields,
                    [field.name]: field.value,
                }
            }, this.state.values || {})
            this.setState({
                forms: list,
                actions: af.bt,
                values,
            })
        }
    }

    updateField = (field, value) => {
        const values = {
            ...this.state.values,
            [field.name]: value,
        }

        this.setState(
            {
                values,
            },
            () => {
                const { onChange } = this.props
                onChange && onChange(this.state.values)
            }
        )
    }

    updateActiveField = (activeField, isFocus = true, isPrev) => () => {
        const { list } = this.props
        let listLength = list.length - 1
        if (!list[listLength].enabled) {
            listLength--
        }

        if (
            (activeField > listLength && !isPrev) ||
            activeField === undefined
        ) {
            if (this.props.onSubmit) {
                this.onSubmit()
            }
        }

        this.setState({ activeField }, () => {
            const method = isFocus ? 'onFocus' : 'onBlur'
            this.props[method] && this.props[method](activeField)
        })
    }

    // Renderiza uma linha do formulário
    renderRow = (row, i, form) => {
        const { active, useDiv } = this.props
        const { formClass, activeField, values } = this.state
        return (
            <div className={`row ${row.className}`} key={i}>
                {row.elements.map((el, e) => {
                    const element = cloneDeep(el)
                    element.useDiv = useDiv

                    if (element.enabled) {
                        this.activeElement = element
                    }

                    this.elementsIndex++

                    /**
                     * Se o Formulario não está ativo,
                     * desabilitamos todos os elementos
                     */
                    if (!active && active !== undefined) {
                        element.enabled = false
                    }

                    if (
                        element.enabled ||
                        (!form.hasActiveElement &&
                            !element.enabled &&
                            i === 0 &&
                            e === 0)
                    ) {
                        this.activeElement = element
                    }

                    const actives = row.elements.find(el => el.active)
                    if (
                        row.tab &&
                        row.hasGeneralButton &&
                        !row.hasActiveElement &&
                        e === 0 &&
                        (!actives || actives.length > 0)
                    ) {
                        element.active = true
                    }

                    const inputWidth = element.inputWidth
                    let minInputWidth = form.inputWidths[e]
                    let labelWidth = form.columnWidths[e]
                    let boxWidth = form.boxWidths[e]
                    if (row.elements.length >= 3) {
                        boxWidth = 0
                    }
                    if (row.elements.length >= 5 && e > 0) {
                        labelWidth = 0
                    }
                    if (row.elements.length >= 3) {
                        minInputWidth = 0
                    }
                    return (
                        <Rec
                            index={element.index}
                            updateField={this.updateField}
                            labelUp={formClass.indexOf('label-up') > -1}
                            widths={form.columnWidths}
                            boxWidth={boxWidth}
                            inputWidth={inputWidth}
                            minInputWidth={minInputWidth}
                            labelWidth={labelWidth}
                            column={e}
                            key={`el-e-${element.lbl}`}
                            onClick={this.updateActiveField(element.index)}
                            onFocus={this.updateActiveField(element.index)}
                            onBlur={this.props.onBlur}
                            onSubmit={this.onSubmit}
                            autoFocus={activeField === element.index}
                            preventDispatchToWS={true}
                            updateActiveField={this.updateActiveField}
                            {...element}
                            value={
                                element.name
                                    ? values[element.name]
                                    : element.value
                            }
                        />
                    )
                })}
            </div>
        )
    }

    onSubmit = e => {
        e && e.preventDefault()
        this.props.onSubmit && this.props.onSubmit(this.state.values)
    }

    createFormRef = el => (this.form = el)

    // renderiza o formulário
    renderForm = forms => {
        const that = this
        const { classes, hideSubmit, onClose } = this.props

        const { hasError } = this.state
        if (hasError) {
            return this.renderError()
        }

        const opts = {
            onSubmit: this.onSubmit,
            action: '',
            method: 'POST',
            className: this.state.formClass,
            autoComplete: 'off',
        }

        this.elementsIndex = 0

        return (
            <form ref={this.createFormRef} {...opts}>
                {forms.map((form, i) => {
                    if (i !== 0) {
                        return (
                            <div key={'tab-' + i} className="tabbed">
                                {// se a aba possui elemento ativo, renderizamos os elementos
                                Object.values(form.rows).filter(row => {
                                    return row.hasActiveElement
                                }).length > 0 ? (
                                    Object.values(form.rows).map((row, r) => {
                                        return that.renderRow(row, r, form)
                                    })
                                ) : (
                                    <h3 className="tab-notification">
                                        Clique no título ou aperte enter para
                                        editar o conteúdo desta aba.
                                    </h3>
                                )}
                            </div>
                        )
                    }
                    return Object.values(form.rows).map((row, r) => {
                        return that.renderRow(row, r, form)
                    })
                })}

                {!hideSubmit ? (
                    <Fragment>
                        <Actions
                            data={
                                this.activeElement ? this.activeElement : null
                            }
                            activeValue={this.state.activeValue}
                            align={'right'}
                        />
                        <div className={classes.actions}>
                            <StyledButton
                                lbl={this.props.submitLabel || 'Salvar'}
                                tabIndex={this.elementsIndex}
                                primary
                                dialog
                                onClick={this.onSubmit}
                            />
                            <StyledButton
                                lbl="Fechar"
                                tabIndex={this.elementsIndex + 1}
                                dialog
                                onClick={onClose}
                            />
                        </div>
                    </Fragment>
                ) : null}
            </form>
        )
    }
}
