import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React from 'react'

import AbstractTextField from './AbstractTextField'

export const PREVIEW_FETCH = gql`
    query previewFile($data: PreviewFile) {
        previewFile(data: $data) {
            file
        }
    }
`

const attachQuery = Component => {
    return graphql(PREVIEW_FETCH, {
        name: 'previewFile',
        skip: props => {
            return (
                !props.table || !props.columnNo || !props.no || !props.columnPic
            )
        },
        options: props => {
            return {
                fetchPolicy: 'cache-and-network',
                variables: {
                    data: {
                        table: props.table,
                        columnNo: props.columnNo,
                        no: props.no,
                        columnPic: props.columnPic,
                    },
                },
            }
        },
    })(Component)
}

/**
 * Componente PreviewField
 *
 * Rec: REC_STRING
 *
 * Renderiza um campo de texto
 */
export class PreviewField extends AbstractTextField {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    }

    beforeSend = () => {
        return null
    }

    render() {
        const { hasError } = this.state
        const { previewFile } = this.props

        if (hasError) {
            return this.renderError()
        }

        if (previewFile?.loading || !previewFile?.previewFile?.file) return null

        return (
            <div
                className="preview"
                style={{
                    maxWidth: 90,
                    position: 'absolute',
                    right: 30,
                }}
            >
                <img
                    src={`data:image/jpg;base64,${previewFile.previewFile.file}`}
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </div>
        )
    }
}

export default attachQuery(PreviewField)
