// Constants
export const keycodes = {
    BACKSPACE_KEY: 8,
    TAB_KEY: 9,
    ENTER_KEY: 13,
    SHIFT_KEY: 16,
    CTRL_KEY: 17,
    ALT_KEY: 18,
    CAPS_LOCK_KEY: 20,
    ESCAPE_KEY: 27,
    SPACE_KEY: 32,
    PAGE_UP_KEY: 33,
    PAGE_DOWN_KEY: 34,
    END_KEY: 35,
    HOME_KEY: 36,
    LEFT_ARROW_KEY: 37,
    UP_ARROW_KEY: 38,
    RIGHT_ARROW_KEY: 39,
    DOWN_ARROW_KEY: 40,
    INSERT_KEY: 45,
    DELETE_KEY: 46,

    ZERO_KEY: 48,
    ONE_KEY: 49,
    TWO_KEY: 50,
    THREE_KEY: 51,
    FOUR_KEY: 52,
    FIVE_KEY: 53,
    SIX_KEY: 54,
    SEVEN_KEY: 55,
    EIGHT_KEY: 56,
    NINE_KEY: 57,

    A_KEY: 65,
    B_KEY: 66,
    C_KEY: 67,
    D_KEY: 68,
    E_KEY: 69,
    F_KEY: 70,
    G_KEY: 71,
    H_KEY: 72,
    I_KEY: 73,
    J_KEY: 74,
    k_KEY: 75,
    L_KEY: 76,
    M_KEY: 77,
    N_KEY: 78,
    O_KEY: 79,
    P_KEY: 80,
    Q_KEY: 81,
    R_KEY: 82,
    S_KEY: 83,
    T_KEY: 84,
    U_KEY: 85,
    V_KEY: 86,
    W_KEY: 87,
    X_KEY: 88,
    Y_KEY: 89,
    Z_KEY: 90,
    ZERO_PAD_KEY: 96,
    ONE_PAD_KEY: 97,
    E_KEY: 101,
    NINE_PAD_KEY: 105,
    NUMPAD_PLUS: 107,
    NUMPAD_SUBTRACT: 109,
    PLUS: 187,
    MINUS: 189,

    // Functions
    F1_KEY: 112,
    F2_KEY: 113,
    F3_KEY: 114,
    F4_KEY: 115,
    F5_KEY: 116,
    F6_KEY: 117,
    F7_KEY: 118,
    F8_KEY: 119,
    F9_KEY: 120,
    F10_KEY: 121,
    F11_KEY: 122,
    F12_KEY: 123,

    NUM_LOCK_KEY: 144,

    // Controls
    CTRL_A: 1,
    CTRL_B: 2,
    CTRL_C: 3,
    CTRL_D: 4,
    CTRL_E: 5,
    CTRL_F: 6,
    CTRL_G: 7,
    CTRL_H: 8,
    CTRL_I: 9,
    CTRL_J: 10, // ignora
    CTRL_K: 11,
    CTRL_L: 12,
    CTRL_M: 13,
    CTRL_N: 14,
    CTRL_O: 15,
    CTRL_P: 16,
    CTRL_Q: 17,
    CTRL_R: 18,
    CTRL_S: 19,
    CTRL_T: 20,
    CTRL_U: 21,
    CTRL_V: 22,
    CTRL_W: 23,
    CTRL_X: 24,
    CTRL_Y: 25,
    CTRL_Z: 26,

    CTRL_ALT_F: 400,
    CTRL_ALT_P: 401,
    CTRL_ALT_R: 402,
    CTRL_ALT_T: 403,
    CTRL_ALT_W: 404,
    CTRL_ALT_N: 405,
    CTRL_ALT_S: 406,
    CTRL_ALT_O: 407,
    CTRL_ALT_G: 408,
    // reservados
    CTRL_ALT_A: 409,
    CTRL_ALT_B: 410,
    CTRL_ALT_C: 411,
    CTRL_ALT_D: 412,
    CTRL_ALT_E: 413,
    CTRL_ALT_H: 414,
    CTRL_ALT_I: 415,
    CTRL_ALT_J: null,
    CTRL_ALT_K: 416,
    CTRL_ALT_L: 417,
    CTRL_ALT_M: null,
    CTRL_ALT_Q: 418,
    CTRL_ALT_U: 419,
    CTRL_ALT_V: 420,
    CTRL_ALT_X: 421,
    CTRL_ALT_Y: 422,
    CTRL_ALT_Z: 423,

    COMMA_KEY: 188,
    DOT_KEY: 190,
    QUESTION_MARK_KEY: 191,
    RIGHT_CTRL_KEY: 225,
}

export const persistents = [
    keycodes.ESCAPE_KEY,
    keycodes.TAB_KEY,
    keycodes.UP_ARROW_KEY,
    keycodes.DOWN_ARROW_KEY,
    keycodes.ENTER_KEY,
    keycodes.F1_KEY,
    keycodes.F2_KEY,
    keycodes.F3_KEY,
    keycodes.F4_KEY,
    keycodes.F5_KEY,
    keycodes.F6_KEY,
    keycodes.F7_KEY,
    keycodes.F8_KEY,
    keycodes.F9_KEY,
    keycodes.F10_KEY,
    keycodes.F11_KEY,
    keycodes.F12_KEY,
]

export const controlKeys = [
    keycodes.LEFT_ARROW_KEY,
    keycodes.RIGHT_ARROW_KEY,
    keycodes.UP_ARROW_KEY,
    keycodes.DOWN_ARROW_KEY,
    keycodes.PAGE_DOWN_KEY,
    keycodes.PAGE_UP_KEY,
    keycodes.ENTER_KEY,
    keycodes.DELETE_KEY,
    keycodes.END_KEY,
    keycodes.HOME_KEY,
]

export const functionKeys = [
    keycodes.F1_KEY,
    keycodes.F2_KEY,
    keycodes.F3_KEY,
    keycodes.F4_KEY,
    keycodes.F5_KEY,
    keycodes.F6_KEY,
    keycodes.F7_KEY,
    keycodes.F8_KEY,
    keycodes.F9_KEY,
    keycodes.F10_KEY,
    keycodes.F11_KEY,
    keycodes.F12_KEY,
]

export const navigationKeys = [
    keycodes.TAB_KEY,
    keycodes.ENTER_KEY,
    keycodes.ESCAPE_KEY,
    keycodes.UP_ARROW_KEY,
    keycodes.DOWN_ARROW_KEY,
    keycodes.RIGHT_CTRL_KEY,
    keycodes.NUM_LOCK_KEY,
    keycodes.CAPS_LOCK_KEY,
    keycodes.PAGE_UP_KEY,
    keycodes.PAGE_DOWN_KEY,
    // keycodes.END_KEY,
    // keycodes.HOME_KEY,
    // keycodes.LEFT_ARROW_KEY,
    // keycodes.RIGHT_ARROW_KEY,
    // keycodes.QUESTION_MARK_KEY
]

/**
 * Mapeia uma letra ao keycode correto
 * para enviar ao WS
 */
export const CTRL_MAPPING = {
    65: keycodes.CTRL_A,
    66: keycodes.CTRL_B,
    67: keycodes.CTRL_C,
    68: keycodes.CTRL_D,
    69: keycodes.CTRL_E,
    70: keycodes.CTRL_F,
    71: keycodes.CTRL_G,
    72: keycodes.CTRL_H,
    73: keycodes.CTRL_I,
    74: keycodes.CTRL_J,
    75: keycodes.CTRL_K,
    76: keycodes.CTRL_L,
    77: keycodes.CTRL_M,
    78: keycodes.CTRL_N,
    79: keycodes.CTRL_O,
    80: keycodes.CTRL_P,
    81: keycodes.CTRL_Q,
    82: keycodes.CTRL_R,
    83: keycodes.CTRL_S,
    84: keycodes.CTRL_T,
    85: keycodes.CTRL_U,
    86: keycodes.CTRL_V,
    87: keycodes.CTRL_W,
    88: keycodes.CTRL_X,
    89: keycodes.CTRL_Y,
    90: keycodes.CTRL_Z,
}

export const CTRL_ALT_MAPPING = {
    65: keycodes.CTRL_ALT_A,
    66: keycodes.CTRL_ALT_B,
    67: keycodes.CTRL_ALT_C,
    68: keycodes.CTRL_ALT_D,
    69: keycodes.CTRL_ALT_E,
    70: keycodes.CTRL_ALT_F,
    71: keycodes.CTRL_ALT_G,
    72: keycodes.CTRL_ALT_H,
    73: keycodes.CTRL_ALT_I,
    74: keycodes.CTRL_ALT_J,
    75: keycodes.CTRL_ALT_K,
    76: keycodes.CTRL_ALT_L,
    77: keycodes.CTRL_ALT_M,
    78: keycodes.CTRL_ALT_N,
    79: keycodes.CTRL_ALT_O,
    80: keycodes.CTRL_ALT_P,
    81: keycodes.CTRL_ALT_Q,
    82: keycodes.CTRL_ALT_R,
    83: keycodes.CTRL_ALT_S,
    84: keycodes.CTRL_ALT_T,
    85: keycodes.CTRL_ALT_U,
    86: keycodes.CTRL_ALT_V,
    87: keycodes.CTRL_ALT_W,
    88: keycodes.CTRL_ALT_X,
    89: keycodes.CTRL_ALT_Y,
    90: keycodes.CTRL_ALT_Z,
}

export const PLUS_MAPPING = {
    107: 43, // PLUS
    187: 43, // SHIFT + PLUS
}

export const NUMPAD_MAPPING = {
    96: keycodes.ZERO_KEY, // 0
    97: keycodes.ONE_KEY, // 1
    98: keycodes.TWO_KEY, // 2
    99: keycodes.THREE_KEY, // 3
    100: keycodes.FOUR_KEY, // 4
    101: keycodes.FIVE_KEY, // 5
    102: keycodes.SIX_KEY, // 6
    103: keycodes.SEVEN_KEY, // 7
    104: keycodes.EIGHT_KEY, // 8
    105: keycodes.NINE_KEY, // 9
    // 49: 97, // 1
}

export const LETTERS_MAPPING = [
    keycodes.A_KEY,
    keycodes.B_KEY,
    keycodes.C_KEY,
    keycodes.D_KEY,
    keycodes.E_KEY,
    keycodes.F_KEY,
    keycodes.G_KEY,
    keycodes.H_KEY,
    keycodes.I_KEY,
    keycodes.J_KEY,
    keycodes.K_KEY,
    keycodes.L_KEY,
    keycodes.M_KEY,
    keycodes.N_KEY,
    keycodes.O_KEY,
    keycodes.P_KEY,
    keycodes.Q_KEY,
    keycodes.R_KEY,
    keycodes.S_KEY,
    keycodes.T_KEY,
    keycodes.U_KEY,
    keycodes.V_KEY,
    keycodes.W_KEY,
    keycodes.X_KEY,
    keycodes.Y_KEY,
    keycodes.Z_KEY,
]

export function isPreventedKey(keyCode) {
    return (
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 97 && keyCode <= 122) ||
        keyCode === 190
    )
}

export default keycodes
