import { recsMultiLines } from '@nerus/framework/components/Formulario/Estrutura/Recs'

import { isRec } from '../../../util/rec'
import { recsPopup } from '../Estrutura/Recs'
import Row from './Row'

let measureCache = {}
export const measureText = (pText, pFontSize, pStyle) => {
    if (!measureCache[pFontSize]) {
        measureCache[pFontSize] = {}
    }

    if (measureCache[pFontSize][pText] === undefined) {
        let lDiv = document.createElement('div')

        document.body.appendChild(lDiv)

        if (pStyle != null) {
            lDiv.style = pStyle
        }
        lDiv.style.fontSize = '' + pFontSize + 'px'
        lDiv.style.position = 'absolute'
        lDiv.style.left = -1000
        lDiv.style.top = -1000

        lDiv.innerHTML = pText

        const realWidth = lDiv.clientWidth + 15

        document.body.removeChild(lDiv)
        lDiv = null

        measureCache[pFontSize][pText] = realWidth
    }

    return measureCache[pFontSize][pText]
}

/**
 * Classe para geração da estrutura do formulário
 * Com base nessa classe setaremos algumas propriedades
 * no componente Formulario:
 * - Uma coluna
 * - Abas
 * - Unir LabelWeb
 */
export default class Form {
    hasTab = false
    hasActiveElement = false
    isOneColumn = false
    columns = 0
    tabDepth = 0
    rows = {}
    columnWidths = {}
    boxWidths = {}
    inputWidths = {}
    lastRow = null
    relatedForm = null
    generalBtn = null

    /**
     * Podemos relacionar um form a outro
     */
    constructor(relatedForm = null) {
        this.relatedForm = relatedForm
    }

    /**
     * Retorna apenas a estrutura em forma de objeto
     */
    toObject = () => {
        let rows = {}
        Object.keys(this.rows).forEach(key => {
            let row = this.rows[key]
            rows[key] = row.toObject()
        })

        return {
            hasTab: this.hasTab,
            hasColumnTitle: this.hasColumnTitle,
            hasActiveElement: this.hasActiveElement,
            isOneColumn: this.isOneColumn,
            columns: this.columns,
            tabDepth: this.tabDepth,
            generalBtn: this.generalBtn,
            rows,
            columnWidths: this.columnWidths,
            boxWidths: this.boxWidths,
            inputWidths: this.inputWidths,
        }
    }

    /**
     * Retorna apenas um vetor com as rows do formulário
     */
    getRows = () => {
        return Object.values(this.rows)
    }

    /**
     * Adiciona uma row nova ou retorna ela caso já exista
     */
    push = row => {
        if (!this.rows[row.y]) {
            this.rows[row.y] = row
        }

        return this.rows[row.y]
    }

    /**
     * Retorna a row de um elemento
     */
    getRowForElement = element => {
        const isBotaoWeb = isRec(element, 'REC_PROMPT')
        const isColumnTitle =
            isRec(element, 'REC_SKIP') && element.attrib === 12

        if (this.rows[element.y]) {
            const existingRow = this.rows[element.y]
            const buttons = existingRow.elements.filter(el =>
                isRec(el, 'REC_PROMPT')
            )
            if (
                buttons &&
                buttons.length > 0 &&
                buttons.length === existingRow.elements.length
            ) {
                existingRow.tab = isBotaoWeb
            }
            if (isColumnTitle) {
                existingRow.columnTitle = true
            }
            return existingRow
        }

        /**
         * Caso a linha anterior seja uma aba e estamos inserindo
         * um BotaoWeb, retornamos a aba anterior em vez de criar
         * uma nova Row
         */
        const kRows = Object.keys(this.rows)
        const previousRow = this.rows[kRows.pop()]
        if (
            previousRow &&
            ((previousRow.tab && isBotaoWeb) ||
                (previousRow.columnTitle && isColumnTitle))
        ) {
            return previousRow
        }

        // Se não temos aba, criamos uma e retornamos ela
        const row = new Row(element.y, this)
        const buttons = row.elements.filter(el => isRec(el, 'REC_PROMPT'))
        const columnTitles = row.elements.filter(
            el => isRec(el, 'REC_SKIP') && el.attrib == 12
        )
        if (buttons && buttons.length > 0 && buttons.length === row.columns) {
            row.tab = isBotaoWeb
        }
        if (
            columnTitles &&
            columnTitles.length > 0 &&
            columnTitles.length === row.columns
        ) {
            row.columnTitle = isColumnTitle
        }
        return row
    }

    /**
     * Adiciona um elemento ao formulário
     * Esse método identifica a linha correta ou insere uma nova
     * e adiciona o elemento na linha
     */
    add = (element, isLast) => {
        const row = this.getRowForElement(element)
        element.orientation = 'horizontal'
        row.push(element, isLast)

        if (row.elements.length === 1 && isRec(element, 'REC_PROMPT')) {
            row.tab = true
        }

        if (
            row.elements.length === 1 &&
            isRec(element, 'REC_SKIP') &&
            element.attrib === 12
        ) {
            row.columnTitle = true
        }

        /**
         * Mantém um rastreio da quantidade de colunas
         * do formulário
         */
        if (row.columns > this.columns) {
            this.columns = row.columns

            if (this.relatedForm && this.columns > this.relatedForm.columns) {
                this.relatedForm.columns = this.columns
            }
        }

        /**
         * Se a linha é uma aba, setamos que o
         * formulário possui abas.
         */
        if (row.tab) {
            this.hasTab = true
        }

        if (row.columnTitle) {
            this.hasColumnTitle = true
        }

        this.push(row)
        return row
    }

    /**
     * Seta botões Gerais ativos quando necessário
     *
     * Especifica que o LabelWeb deve ser um header
     * quando está sozinho em uma linha
     */
    postProcess = () => {
        const ys = Object.keys(this.rows)
        const that = this
        let y = ys[0]
        let row = that.rows[y]
        let changeRow = false

        let fontSize = 14
        if (window.innerWidth <= 1024) {
            fontSize = 12
        }

        for (let i = 0; i < ys.length; i++) {
            if (changeRow) {
                y = ys[i]
                row = that.rows[y]
            }

            changeRow = true

            if (row) {
                if (row.elements[0] && isRec(row.elements[0], 'REC_SKIP')) {
                    const nexty = ys[i + 1]
                    const nextrow = that.rows[nexty]
                    if (nextrow && nextrow.elements[0]) {
                        if (
                            nextrow.elements.length === 1 &&
                            row.elements.length === 1 &&
                            nextrow.elements[0].isTitle &&
                            row.elements[0].isTitle
                        ) {
                            row.elements[0].lbl += ' ' + nextrow.elements[0].lbl
                            nextrow.elements.shift()
                            changeRow = false
                        }
                    }

                    if (
                        row.elements[0].attrib > 0 &&
                        row.elements[0].attrib !== 12
                    ) {
                        row.elements[0].header = true
                    } else {
                        if (
                            nextrow &&
                            nextrow.elements[0] &&
                            row.elements[0].isTitle &&
                            row.elements[0].attrib === 0
                        ) {
                            const label = row.elements[0]
                            row.elements[0] = nextrow.elements[0]
                            row.elements[0].origLbl = row.elements[0].lbl
                            row.elements[0].lbl = `${label.lbl}<br />${row.elements[0].lbl}`

                            nextrow.elements.shift()
                        }
                    }

                    if (!row.elements.length) {
                        delete that.rows[y]
                    }
                }

                row.elements.forEach((element, column) => {
                    if (!this.columnWidths[column]) {
                        this.columnWidths[column] = 0
                    }

                    if (!this.boxWidths[column]) {
                        this.boxWidths[column] = 0
                    }

                    if (!this.inputWidths[column]) {
                        this.inputWidths[column] = 0
                    }

                    let lWidth = 0
                    /**
                     * Se é um elemento que possui "label"
                     * Fazemos uma conta para otimizar a renderização do formulário
                     */
                    const biggerLengthSize = 5.5
                    const smallerLengthSize = 6.5
                    if (element.lbl && element.typeRec !== 'REC_SKIP') {
                        element.lbl = element.lbl.replace(/\s+/g, ' ').trim()
                        const lbl = element.origLbl || element.lbl
                        const lg = lbl.replace(/\s+/g, ' ').trim().length
                        lWidth = Math.round(
                            lg > 60
                                ? lg * biggerLengthSize
                                : lg * smallerLengthSize
                        )

                        if (element.lbl.search('<br />') > 0) {
                            lWidth = Math.round(lWidth / 2)
                        }

                        if (
                            !isRec(element, 'REC_PROMPT') &&
                            lWidth > this.columnWidths[column] &&
                            element.attrib !== 11
                        ) {
                            this.columnWidths[column] = lWidth
                        }
                    }

                    let boxWidth = Math.ceil((element.lsz || element.sz) * 8)
                    if (
                        !isRec(element, 'REC_PROMPT') &&
                        boxWidth > this.boxWidths[column] &&
                        element.attrib !== 11
                    ) {
                        this.boxWidths[column] = boxWidth
                    }

                    /**
                     * Calculo para definir a largura aproximada
                     * de um input baseado em testes experimentais.
                     * Valores podem precisar ser ajustados.
                     */
                    const inputSz = element.lsz || element.sz
                    if (isRec(element, 'REC_DATE')) {
                        element.inputWidth =
                            inputSz > 8 ? inputSz * 9 + 9 : inputSz * 11 + 9
                    } else if (isRec(element, 'REC_TIME')) {
                        element.inputWidth =
                            inputSz > 8 ? inputSz * 7 + 9 : inputSz * 9 + 9
                    } else if (isRec(element, 'REC_YM')) {
                        element.inputWidth =
                            inputSz > 8 ? inputSz * 9 + 9 : inputSz * 10 + 9
                    } else {
                        element.inputWidth = measureText(
                            '0'.repeat(inputSz),
                            fontSize,
                            'font-family: Frutiger, sans-serif'
                        )
                    }

                    // Regra especifica para STR_LINES
                    if (recsMultiLines.indexOf(element.typeRec) > -1) {
                        element.inputWidth = measureText(
                            '0'.repeat(inputSz),
                            fontSize,
                            'font-family: Frutiger'
                        )
                    }

                    if (recsPopup.indexOf(element.typeRec) > -1) {
                        element.inputWidth = 160

                        if (
                            element?.options?.data?.strList &&
                            element.options.data.strList[element.value.trim()]
                        ) {
                            element.inputWidth =
                                element.options.data.strList[
                                    element.value.trim()
                                ].length * 9.5
                        }
                    }

                    let inputWidth = element.inputWidth
                    // Regra especifica para STR_LINES
                    // if (recsMultiLines.indexOf(element.typeRec) > -1) {
                    inputWidth += 16
                    // }

                    if (
                        !isRec(element, 'REC_PROMPT') &&
                        inputWidth > this.inputWidths[column] &&
                        element.attrib !== 11
                    ) {
                        this.inputWidths[column] = inputWidth
                    }
                })
            }
        }

        for (let column = 0; column < this.columns; column++) {
            let boxMinWidth =
                this.inputWidths[column] + this.columnWidths[column]

            if (boxMinWidth > this.boxWidths[column]) {
                this.boxWidths[column] = boxMinWidth
            }
        }
    }

    /**
     * Aplicamos a regra de formulário de apenas uma coluna
     *
     * Re-estrutura o Formulário de forma a gerar
     * duas colunas
     */
    doOneColumn = () => {
        const form = this
        if (form.hasTab || (form.relatedForm && form.relatedForm.hasTab)) {
            return false
        }

        return false
    }
}
