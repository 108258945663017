import AbstractTextField from './AbstractTextField'

/**
 * Componente TextFieldString
 *
 * Rec: REC_STRING
 *
 * Renderiza um campo de texto
 */
export default class TextFieldString extends AbstractTextField {
    beforeSend = value => {
        let parsedValue = value
        const { sz } = this.props
        if (parsedValue?.length !== sz) {
            const missingLength = sz - parsedValue?.length
            parsedValue =
                parsedValue +
                ' '.repeat(
                    isNaN(missingLength) || missingLength <= 0
                        ? 0
                        : missingLength
                )
        }
        return parsedValue
    }
}
