import withStyles from '@material-ui/styles/withStyles'
import transformColor from '@nerus/framework/util/transformColor'
import {
    categoricalColorSchemeIds,
    categoricalColorSchemes,
    colorSchemeIds,
    colorSchemes,
} from '@nivo/colors'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'

import { blue, green, orange, purple, red, textColor } from '../../../../theme'
import BlocoTitulo from '../../components/BlocoTitulo'
import DashboardBox from '../../components/common/DashboardBox'
import Gauge from '../../components/Gauge'
import GraficoBarra from '../../components/GraficoBarra'
import GraficoBarraGrouped from '../../components/GraficoBarraGrouped'
import GraficoBarraHor from '../../components/GraficoBarraHor'
import GraficoBarraLinha from '../../components/GraficoBarraLinha'
import GraficoBarraStacked from '../../components/GraficoBarraStacked'
import GraficoLinha from '../../components/GraficoLinha'
import GraficoMultiplasLinhas from '../../components/GraficoMultiplasLinhas'
import GraficoPizza from '../../components/GraficoPizza'
import Grid from '../../components/Grid'
import Imagem from '../../components/Imagem'
import Map from '../../components/Map'
import Fulfillment from '../../components/O2/Fulfillment'
import PickingPacking from '../../components/O2/PickingAndPacking'
import ProductCicle from '../../components/O2/ProductCicle'

import {
    TYPE_FULFILLMENT,
    TYPE_GRAPH_BAR,
    TYPE_GRAPH_BAR_AND_LINE,
    TYPE_GRAPH_BAR_GROUPED,
    TYPE_GRAPH_BAR_HOR,
    TYPE_GRAPH_BAR_STACKED,
    TYPE_GRAPH_GAUGE,
    TYPE_GRAPH_LINE,
    TYPE_GRAPH_MAP,
    TYPE_GRAPH_MULTLINHAS,
    TYPE_GRAPH_PIZZA,
    TYPE_GRID,
    TYPE_IMAGE,
    TYPE_PICKINGPACKING,
    TYPE_PRODUCTCICLE,
    TYPE_TITLE_BLOCK,
} from '../Components/constants'
// TODO: Remover esse tipo de uso, preferencia pelos hooks normais
import withQueryReport from '../old/Reports/graphql/withQueryReport'

categoricalColorSchemes.NerusWeb = colorSchemes.NerusWeb = [
    red,
    orange,
    purple,
    blue,
    green,
    textColor,
]

if (
    typeof window !== 'undefined' &&
    window.location.pathname.indexOf('/o2') > -1
) {
    categoricalColorSchemes.O2Short = colorSchemes.O2Short = [
        '#F47560',
        '#00A7CE',
        '#97E3D5',
    ]

    categoricalColorSchemes.O2Blues = colorSchemes.O2Blues = [
        '#42BAD6',
        '#9EEDFF',
        '#47D6F7',
        '#9FD9E7',
        '#6BC3D7',
    ]

    if (colorSchemeIds.indexOf('O2Blues') === -1) {
        colorSchemeIds.unshift('O2Blues')
        categoricalColorSchemeIds.unshift('O2Blues')
        colorSchemeIds.unshift('O2Short')
        categoricalColorSchemeIds.unshift('O2Short')
    }
}

if (colorSchemeIds.indexOf('NerusWeb') === -1) {
    colorSchemeIds.unshift('NerusWeb')
    categoricalColorSchemeIds.unshift('NerusWeb')
}

class DashboardItem extends Component {
    state = {
        dados: '',
        query: '',
    }

    static propTypes = {
        dashboard: PropTypes.object,
        setDashboard: PropTypes.func,
        queryReport: PropTypes.object,
        classes: PropTypes.object,
        id: PropTypes.number,
        user_id: PropTypes.number,
        title: PropTypes.string,
        height: PropTypes.number,
        info: PropTypes.string,
        activeFilters: PropTypes.object,
        queries: PropTypes.arrayOf(
            PropTypes.shape({
                query: PropTypes.string,
            })
        ),
        order: PropTypes.number,
        type: PropTypes.number,
        size: PropTypes.number,
        colors: PropTypes.object,
        error: PropTypes.object,
        custom: PropTypes.object,
        axes: PropTypes.object,
        image: PropTypes.string,
        previewer: PropTypes.bool,
        isPainelO2: PropTypes.bool,
        config: PropTypes.bool,
        onConfig: PropTypes.func,
        onRemove: PropTypes.func,
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.queries !== this.props.queries ||
            prevProps.activeFilters !== this.props.activeFilters
        ) {
            this.props?.queryReport?.refetch?.({
                queries: this.props.queries.map(({ id, query }) => ({
                    id,
                    query: query ? query : '',
                })),
                filters: this.props.activeFilters || {},
                type: this.props.type,
            })
        }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo)
        this.setState({
            error,
        })
    }

    renderItem(param, extra = {}) {
        const {
            props: { queryReport, error: queryError },
        } = this

        let render = null
        let dados = queryReport?.queryReport ? queryReport.queryReport.data : ''
        let error =
            queryReport?.queryReport?.error ||
            queryReport?.error ||
            this.state.error
        const loading = queryReport?.queryReport
            ? queryReport.queryReport.loading
            : false

        if (dados?.type === 'Buffer') {
            dados = Buffer.from(dados).toString()
        }

        if (
            Array.isArray(dados) &&
            typeof dados !== 'string' &&
            dados[0]?.dados &&
            Array.isArray(dados[0].dados)
        ) {
            dados[0].dados = dados[0].dados.map(row => {
                Object.keys(row).forEach(key => {
                    const value = row[key]
                    if (value?.type === 'Buffer') {
                        row[key] = Buffer.from(value).toString()
                    }
                })

                return row
            })
        }

        try {
            switch (param) {
                // case TYPE_SEPARATOR: {
                //     // return null
                // }
                case TYPE_IMAGE: {
                    render = <Imagem {...extra} data={dados ? dados : []} />
                    break
                }
                case TYPE_TITLE_BLOCK:
                    render = (
                        <BlocoTitulo
                            {...extra}
                            value={!loading ? String(dados) : 'Carregando...'}
                        />
                    )
                    break
                case TYPE_GRAPH_BAR_AND_LINE: {
                    render = (
                        <GraficoBarraLinha
                            {...extra}
                            data={dados ? dados : []}
                        />
                    )
                    break
                }
                case TYPE_GRAPH_BAR: {
                    render = (
                        <GraficoBarra {...extra} data={dados ? dados : []} />
                    )
                    break
                }
                case TYPE_GRAPH_BAR_HOR: {
                    render = (
                        <GraficoBarraHor {...extra} data={dados ? dados : []} />
                    )
                    break
                }
                case TYPE_GRAPH_BAR_GROUPED: {
                    render = (
                        <GraficoBarraGrouped
                            {...extra}
                            data={dados ? dados : []}
                        />
                    )
                    break
                }
                case TYPE_GRAPH_BAR_STACKED: {
                    render = (
                        <GraficoBarraStacked
                            {...extra}
                            data={dados ? dados : []}
                        />
                    )
                    break
                }
                case TYPE_GRAPH_LINE: {
                    render = (
                        <GraficoLinha {...extra} data={dados ? dados : []} />
                    )
                    break
                }
                case TYPE_GRAPH_PIZZA: {
                    render = (
                        <GraficoPizza {...extra} data={dados ? dados : []} />
                    )
                    break
                }
                case TYPE_GRID: {
                    render = <Grid {...extra} data={dados ? dados : []} />
                    break
                }
                case TYPE_GRAPH_MULTLINHAS: {
                    render = (
                        <GraficoMultiplasLinhas
                            {...extra}
                            data={dados ? dados : []}
                        />
                    )
                    break
                }
                case TYPE_GRAPH_GAUGE: {
                    render = <Gauge {...extra} data={dados ? dados : []} />
                    break
                }
                case TYPE_GRAPH_MAP: {
                    render = <Map {...extra} data={dados ? dados : []} />
                    break
                }
                case TYPE_FULFILLMENT: {
                    render = (
                        <Fulfillment {...extra} data={dados ? dados : []} />
                    )
                    break
                }
                case TYPE_PICKINGPACKING: {
                    render = (
                        <PickingPacking {...extra} data={dados ? dados : []} />
                    )
                    break
                }
                case TYPE_PRODUCTCICLE: {
                    render = (
                        <ProductCicle {...extra} data={dados ? dados : []} />
                    )
                    break
                }
                default: {
                    render = null
                }
            }
        } catch (e) {
            error = e
        }

        if (queryError) {
            error = queryError
        }

        if (error) {
            let errorMessage
            try {
                errorMessage = error.graphQLErrors?.length
                    ? error.graphQLErrors[0].extensions?.exception?.sqlMessage
                    : error.networkError?.result?.errors
                    ? error.networkError?.result?.errors[0].message
                    : error.message
            } catch (e) {
                errorMessage = error.message
            }

            return (
                <span
                    style={{
                        color: 'red',
                        fontSize: 12,
                        display: 'block',
                        marginBottom: 19,
                    }}
                >
                    {errorMessage || error.sqlMessage}
                </span>
            )
        }

        return render
    }

    render() {
        const {
            props: { colors, info, type, onConfig, onRemove },
        } = this

        const footerColor = colors?.footer
            ? transformColor(colors.footer)
            : '#aaaaaa'

        const { classes, ...boxProps } = this.props

        return (
            <DashboardBox
                {...boxProps}
                onConfig={onConfig ? onConfig : null}
                onRemove={onRemove ? onRemove : null}
                hideBox={
                    [
                        TYPE_PICKINGPACKING,
                        TYPE_FULFILLMENT,
                        TYPE_PRODUCTCICLE,
                    ].indexOf(type) > -1
                }
            >
                {this.renderItem(type, boxProps)}

                {info ? (
                    <p className={classes.info} style={{ color: footerColor }}>
                        {info}
                    </p>
                ) : (
                    ''
                )}
            </DashboardBox>
        )
    }
}

export default withStyles(() => ({
    info: {
        fontSize: 11,
        position: 'absolute',
        bottom: 0,
    },
}))(withQueryReport(DashboardItem))
