import { alpha } from '@material-ui/core/styles'

export const styles = theme => {
    const primaryHover = {
        backgroundColor: theme.palette.primaryAction.light,
        color: theme.palette.contrastText.main,
    }

    const secondaryHover = {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.contrastText.main,
    }

    const dialogHover = {
        backgroundColor: theme.palette.secondaryAction.light,
        boxShadow: '0 1px 4px rgba(0,0,0,.3)',
        color: 'inherit',
    }

    return {
        root: {
            display: 'inline-block',
            marginRight: 5,
            padding: '3px 6px',
            fontSize: 12,
            color: theme.palette.textColor,
            backgroundColor: '#ddd',
            border: 'none',
            cursor: 'pointer',
            lineHeight: 1.5,
            fontWeight: 600,
            textAlign: 'center',
            borderRadius: 2,
            whiteSpace: 'nowrap',
            transition: 'all 0.25s linear',
            textTransform: 'inherit',
            boxShadow: '0 -1px rgba(0, 0, 0, 0.1) inset',
            '&:hover': {
                color: theme.palette.textColor,
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: 11,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 10,
            },
        },
        dialog: {
            borderRadius: 4,
            textTransform: 'none',
            boxShadow: '0 0 0 rgba(0,0,0,1)',
            transition: 'all 80ms linear',
            border: '1px solid #b9b6ad',
            backgroundColor: theme.palette.secondaryAction.main,
            '&:hover': dialogHover,
            '& .highlight': {
                color: 'inherit',
                textDecoration: 'underline',
            },
        },
        primary: {
            border: 'none',
            backgroundColor: theme.palette.primaryAction.main,
            color: theme.palette.contrastText.main,
            '&:hover, &:focus, &:active': primaryHover,
        },
        secondary: {
            border: 'none',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.contrastText.main,
            fontFamily: 'Conthrax, sans-serif !important',
            '&:hover, &:focus, &:active': secondaryHover,
        },
        primaryHover,
        secondaryHover,
        dialogHover,
        tab: {
            border: '1px solid #ccc',
            backgroundColor: '#f5f5f5',
            borderRadius: '2px 2px 0 0',
        },
        disabled: {
            color: 'rgba(35, 34, 34, 0.3)',
            backgroundColor: 'transparent !important',
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
        },
        disabledPrimary: {
            backgroundColor: theme.palette.disabledColor + ' !important',
            color: theme.palette.contrastText.main + ' !important',
            opacity: 1,
            cursor: 'not-allowed !important',
        },
        active: {
            color: '#232222',
            border: '1px solid #ccc !important',
            borderBottomColor: '#ffffff !important',
        },
        focusVisible: {
            boxShadow: 'none',
        },
        borderDefault: {},
        borderNone: {
            border: 'transparent !important',
        },
        sizeDefault: {},
        sizeMedium: {
            fontSize: 14,
            padding: '6px 12px',
            [theme.breakpoints.down('lg')]: {
                fontSize: 13,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 12,
            },
        },
        tabNew: {
            background: 'none',
            border: '1px solid transparent',
            boxShadow: 'none',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            position: 'relative',
            marginRight: 0,
            // zIndex: 1,
            bottom: -1,
            left: 10,
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.025) !important',
                color: theme.palette.common.black,
                borderBottomColor: 'rgba(0, 0, 0, 0.25)',
            },
            [theme.breakpoints.down('lg')]: {
                left: 5,
            },
        },
        tabNewActive: {
            background: theme.palette.common.white,
            borderColor: 'rgba(0, 0, 0, 0.25)',
            borderBottomColor: theme.palette.common.white,
            marginBottom: '-1px',
        },
        variantDefault: {},
        variantRound: {
            display: 'inline-flex',
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            borderRadius: theme.spacing(3),
            '& svg': {
                fontSize: 'inherit',
            },
        },
        variantRoundedtab: {
            borderRadius: theme.spacing(1, 1, 0, 0),
        },
        colorNone: {},
        colorDefault: {
            color: theme.palette.textColor,
            backgroundColor: '#ddd',
        },
        colorSuccess: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.contrastText.main,
            '&:hover,&:focus,&:active': {
                backgroundColor: theme.palette.success.dark,
                color: theme.palette.contrastText.main,
            },
        },
        colorInfo: {
            backgroundColor: theme.palette.blue.main,
            color: theme.palette.contrastText.main,
            '&:hover,&:focus,&:active': {
                backgroundColor: theme.palette.blue.dark,
                color: theme.palette.contrastText.main,
            },
        },
        colorWarn: {
            backgroundColor: theme.palette.orange.main,
            color: theme.palette.contrastText.main,
            '&:hover,&:focus,&:active': {
                backgroundColor: theme.palette.orange.dark,
                color: theme.palette.contrastText.main + ' !important',
            },
        },
        colorDanger: {
            backgroundColor: theme.palette.danger.main,
            color: theme.palette.contrastText.main,
            '&:hover,&:focus,&:active': {
                backgroundColor: theme.palette.danger.dark,
                color: theme.palette.contrastText.main,
            },
        },
        colorPurple: {
            backgroundColor: theme.palette.purple.main,
            color: theme.palette.contrastText.main,
            '&:hover,&:focus,&:active': {
                backgroundColor: theme.palette.purple.dark,
                color: theme.palette.contrastText.main,
            },
        },
        disabledColorDefault: {
            backgroundColor:
                alpha(theme.palette.common.black, 0.1) + ' !important',
            opacity: 1,
            cursor: 'not-allowed !important',
        },
        disabledColorSuccess: {
            backgroundColor: theme.palette.success.light,
            color: theme.palette.contrastText.main + ' !important',
            opacity: 0.5,
            cursor: 'not-allowed',
        },
        disabledColorInfo: {
            backgroundColor: theme.palette.blue.light,
            color: theme.palette.contrastText.main + ' !important',
            opacity: 0.5,
            cursor: 'not-allowed',
        },
        disabledColorWarn: {
            backgroundColor: theme.palette.orange.light,
            color: theme.palette.contrastText.main + ' !important',
            opacity: 0.5,
            cursor: 'not-allowed',
        },
        disabledColorDanger: {
            backgroundColor: theme.palette.danger.light,
            color: theme.palette.contrastText.main + ' !important',
            opacity: 0.5,
            cursor: 'not-allowed',
        },
        disabledColorPurple: {
            backgroundColor: theme.palette.purple.light,
            color: theme.palette.contrastText.main + ' !important',
            opacity: 0.5,
            cursor: 'not-allowed',
        },
    }
}
