import { withStyles } from '@material-ui/core/styles'
import { ResponsiveLine } from '@nivo/line'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'

import { initialProperties } from '../Dashboard/Actions/GraphConfig/defaults/line'
import GraphTooltip from '../Dashboard/Components/GraphTooltip'
import getGraphProps from './getGraphProps'

const styles = {
    graficoLinha: {
        width: '100%',
        height: '100%',
    },
}

class GraficoLinha extends Component {
    constructor(props) {
        super(props)
    }
    static propTypes = {
        axes: PropTypes.object,
        classes: PropTypes.object.isRequired,
        custom: PropTypes.object,
        data: PropTypes.array.isRequired,
    }
    render() {
        const {
            props: { classes, axes, data, custom = {} },
        } = this

        function tooltip(props) {
            const {
                point: {
                    data: { xFormatted, y },
                    serieColor,
                },
            } = props

            return (
                <GraphTooltip
                    color={serieColor}
                    value={y}
                    label={xFormatted}
                    format={axes?.y?.format}
                />
            )
        }

        if (data[0] && !data[0]?.id) {
            data[0].id = 'sample'
        }

        const graphProps = getGraphProps(
            initialProperties,
            axes,
            data,
            tooltip,
            custom
        )

        return (
            <div className={classes.graficoLinha}>
                <ResponsiveLine {...graphProps} />
            </div>
        )
    }
}

export default withStyles(styles)(GraficoLinha)
