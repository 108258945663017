export const styles = theme => ({
    root: {
        top: '100%',
        left: '50%',
        // width: '480px',
        position: 'fixed',
        zIndex: 1000000,
        marginTop: '-55px',
        borderRadius: '6px',
        marginLeft: '-200px',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.25)',
        '& svg': {
            transform: 'rotate(180deg)',
        },
    },
    text: {
        fontSize: '14px',
        minHeight: theme.spacing(3),
        fontWeight: 300,
        marginTop: 0,
        marginBottom: 0,
        lineHeight: '21px',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    textBig: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '24px',
        display: 'flex',
    },
})
