import EnhancedComponent from '@nerus/framework/common/EnhancedComponent'
import keycodes from '@nerus/framework/common/Keycodes'
import FieldType from '@nerus/framework/components/Formulario/Estrutura/FieldType'
import { Input } from '@nerus/framework/styled/Input'
import PropTypes from 'prop-types'
import React from 'react'

import { sendBuffer } from '../../../../Eac/EacActions'

const debug = require('debug')('nerus:useOverwrite')

export const RECS = [
    'REC_LONG',
    'REC_SHORT',
    'REC_F_LONG',
    'REC_F_SHORT',
    'REC_MONEY',
    'REC_DOUBLE',
    'REC_PERC',
    'REC_DQTTY',
    'REC_QTTY',
    'REC_QTTD',
    'REC_SHORT_WITH_HELP',
    'REC_DATE',
    'REC_YM',
    'REC_DATEYY',
    'REC_DATE_UTC',
    'REC_TIME',
]

class AbstractTextField extends EnhancedComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
        enabled: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
            .isRequired,
        widths: PropTypes.object,
        orientation: PropTypes.string,
        column: PropTypes.number,
        updateField: PropTypes.func,
        onKeyDown: PropTypes.func,
        typeRecMod: PropTypes.string,
        mascara: PropTypes.object,
        wsStop: PropTypes.bool,
        lbl: PropTypes.string,
        width: PropTypes.number,
        sz: PropTypes.number,
        componentId: PropTypes.number,
        desc: PropTypes.string,
        ws: PropTypes.object,
        onChange: PropTypes.func,
    }

    fieldType = null

    constructor(props) {
        super(props)
        this.state = {
            value: props.value,
        }
    }

    triggerUpdateField = value => () => {
        const { updateField, enabled } = this.props
        if (enabled) {
            updateField &&
                updateField(
                    this.props,
                    this.beforeSend ? this.beforeSend(value) : value
                )
        }
    }

    componentDidMount() {
        this.componentDidUpdate(this.props)
    }

    componentDidUpdate(prevProps) {
        const { value, enabled, activeValue } = this.props

        if (enabled && activeValue) {
            activeValue.current = this.beforeSend
                ? this.beforeSend(this.state.value)
                : this.state.value
        }

        if (prevProps.value !== value || prevProps.enabled !== enabled) {
            this.setState({ value }, this.triggerUpdateField(value))
        }
    }

    handleInputChange = (event, callback) => {
        event.persist && event.persist()

        const fieldType = this.getFieldType()
        const selectionStart = event.target ? event.target.selectionStart : 0
        const selectionEnd = event.target ? event.target.selectionEnd : 0

        let value = event.target.value
        if (fieldType && RECS.indexOf(fieldType.typeRec) === -1) {
            if (fieldType.formatter) {
                value = fieldType.formatter(value)
                debug(`apply formatter: ${value}`)
            }

            if (fieldType && fieldType.modifier) {
                value = fieldType.modifier(value)
                debug(`apply modifier: ${value}`)
            }
        }

        this.setState(
            {
                value,
            },
            () => {
                if (
                    fieldType &&
                    fieldType.shouldJump(value) &&
                    selectionStart === event.target.value.length &&
                    selectionEnd === event.target.value.length
                ) {
                    this.triggerKeyCode(keycodes.ENTER_KEY)
                }

                this.triggerUpdateField(value)()

                if (event && event.target) {
                    event.target.selectionStart = event.target.selectionEnd =
                        selectionStart
                }

                typeof callback === 'function' && callback(event)
            }
        )
    }

    getFieldType = () => {
        const { typeRecMod, typeRec } = this.props
        if (typeRecMod && !this.fieldType) {
            const fieldTypeClass = FieldType[typeRecMod]
            const defaultFieldTypeClass = FieldType['n']

            const fieldType = fieldTypeClass
                ? new fieldTypeClass(this.props)
                : new defaultFieldTypeClass(this.props)

            fieldType.typeRec = typeRec
            this.fieldType = fieldType
            return fieldType
        }
        return this.fieldType
    }

    triggerKeyCode = keyCode => {
        const { x, y, ws } = this.props

        const value = this.beforeSend
            ? this.beforeSend(this.state.value, { keyCode })
            : this.state.value

        ws.send(
            sendBuffer({
                x,
                y,
                key: keyCode,
                value,
            })
        )
    }

    render() {
        const { hasError, value } = this.state
        const {
            enabled,
            lbl,
            sz,
            x,
            y,
            inputWidth,
            labelWidth,
            componentId,
            InputProps,
            orientation = 'horizontal',
            dispatch,
            onKeyDown,
        } = this.props

        if (hasError) {
            return this.renderError()
        }

        const fieldType = this.getFieldType()
        const type = fieldType ? fieldType.type : 'text'

        return (
            <Input
                label={lbl}
                ref={this.createRef('input')}
                value={value}
                componentId={componentId}
                dispatch={dispatch}
                disabled={!enabled}
                position={{ x, y }}
                inputWidth={inputWidth}
                labelWidth={labelWidth}
                orientation={orientation}
                InputProps={InputProps}
                autoComplete="off"
                type={type}
                maxLength={sz}
                fieldType={fieldType}
                beforeSend={this.beforeSend}
                onChange={this.handleInputChange}
                dispatchToWs={true}
                onKeyDown={onKeyDown}
            />
        )
    }
}

export default AbstractTextField
