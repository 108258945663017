import { useCallback, useEffect, useRef } from 'react'

import getSelectionText from '../../util/getSelectionText'

const debug = require('debug')('nerus:useAutoFocus')

export default function useAutoFocus(
    ref,
    props,
    enabled = true,
    enabledBlur = true
) {
    const { disabled, open } = props
    const timeoutRef = useRef()

    const isActive = ref && ref.current && !props.disabled

    const focus = ref?.current?.focus.bind(ref.current)

    const doFocus = useCallback(() => {
        clearTimeout(timeoutRef.current)

        const shouldFocus =
            ref &&
            !disabled &&
            ref.current &&
            ref.current !== document.activeElement

        if (shouldFocus) {
            debug(`Focusing element ${ref.current.id}: ${ref.current.name}`)
        }

        if (focus && shouldFocus) {
            if (!enabled) {
                debug(`Hook is not enabled`)
                return
            }

            timeoutRef.current = setTimeout(() => {
                if (getSelectionText() === '') {
                    focus()
                }
            }, 10)
        }
    }, [ref, disabled, focus])

    const doBlur = useCallback(() => {
        if (!open && !disabled) {
            doFocus()
        }
    }, [open, disabled, doFocus])

    useEffect(() => {
        if (!open) {
            doFocus()
        }

        if (!disabled) {
            document.addEventListener('click', doBlur)
            if (enabledBlur && isActive && ref?.current) {
                ref.current.addEventListener('blur', doBlur)
            }
        }

        return () => {
            clearTimeout(timeoutRef.current)
            document.removeEventListener('blur', doBlur)
            document.removeEventListener('click', doBlur)
        }
    }, [disabled, enabled, enabledBlur, open, isActive])

    return ref
}
