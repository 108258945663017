import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/components/biometriaFacial'
import { connect } from 'react-redux'

import { withWS } from '../../Business/Websocket/Context'
import { getComponentById, isActiveComponent } from '../../Eac/EacReducer'
import BaseBiometriaFacial from './BaseBiometriaFacial'
import withFaceBiometry from './withFaceBiometry'

/**
 * Componente BiometriaFacial
 */
export class BiometriaFacial extends BaseBiometriaFacial {
    // Retrieve data from store as props
    static mapStateToProps = (state, ownProps) => {
        const componentData = getComponentById(ownProps.id, state).payload
        const {
            storeno,
            table,
            player,
            user,
            pswd,
            url,
            custno,
            cpf: identifier_code,
            document_type = 2,
            name,
            birthdate: birth_date,
            cep: postal_code,
            consultType,
        } = componentData

        const data = {
            storeno,
            config: { table, player, user, pswd, url },
            type: consultType === 0 ? 'creditRequest' : 'verify',
            request: {
                assessment_type: consultType,
                birth_date,
                custno,
                document_type,
                identifier_code,
                name,
                photo: '',
                postal_code,
            },
        }

        return {
            data,
            retType: componentData?.retType || 'BiometriaFacialRetorno',
            active:
                ownProps.active ||
                (ownProps.enabled === undefined
                    ? isActiveComponent(ownProps.id, state)
                    : ownProps.enabled),
        }
    }

    static baseOpts = {
        skip: true,
    }

    // Renderiza o wrapper
    render() {
        return this.renderComponent(this.state.forms)
    }
}

export default connect(BiometriaFacial.mapStateToProps)(
    withFaceBiometry(withStyles(styles)(withWS(BiometriaFacial)))
)
