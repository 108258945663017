import makeStyles from '@material-ui/core/styles/makeStyles'
import { styles } from '@nerus/styles/components/painelo2'
import React from 'react'

import DashboardRender from '../../../NerusIT/Dashboard/DashboardRender'
import Conexoes from './Conexoes'

const useStyles = makeStyles(styles, { name: 'PainelO2' })

export default function PainelO2() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <h2 className={classes.title}>Painel Nérus O2</h2>

            <div className={classes.content}>
                <DashboardRender showBase={false} isPainelO2={true} />
            </div>

            <Conexoes />
        </div>
    )
}
