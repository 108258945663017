import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import IconButton from './IconButton'
import SliderButton from './SliderButton'

export default class ButtonActions extends Component {
    static propTypes = {
        data: PropTypes.array,
        classes: PropTypes.object.isRequired,
        maxButtonsSlider: PropTypes.number,
        showMore: PropTypes.bool.isRequired,
        toggleShowMore: PropTypes.func.isRequired,
    }

    render() {
        const {
            data,
            classes,
            maxButtonsSlider,
            showMore,
            toggleShowMore,
        } = this.props

        if (!data || !data.length) {
            return null
        }

        return (
            <div
                key="grid-buttons"
                className={clsx({
                    [classes.buttons]: true,
                    [classes.buttonsShowMore]: showMore,
                    [classes.fewButtons]:
                        data.length < maxButtonsSlider || showMore,
                })}
            >
                <SliderButton
                    data={data}
                    classes={classes}
                    showMore={showMore}
                    maxButtonsSlider={maxButtonsSlider}
                />

                {data.length > maxButtonsSlider ? (
                    <IconButton
                        className={classes.showMoreButton}
                        tooltipPosition="top-end"
                        tooltip={showMore ? 'Esconder todos' : 'Mostrar todos'}
                        onClick={toggleShowMore}
                    />
                ) : null}
            </div>
        )
    }
}
