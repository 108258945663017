import * as PropTypes from 'prop-types'
import React from 'react'

import keycodes from '../../../../common/Keycodes'
import Label from '../../../../styled/Label'
import StyledToggle from '../../../../styled/Toggle'
import AbstractTextField from './AbstractTextField'

export default class Toogle extends AbstractTextField {
    static propTypes = {
        onSendValue: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        value: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
        widths: PropTypes.object,
        column: PropTypes.number,
    }

    processValueToToggle = value => {
        return value === '1' || value === 'S' || value === true
    }

    processValueToWs = value => {
        return value ? 'S' : 'N'
    }

    constructor(props) {
        super(props)
        this.state = {
            beforeSend: texto => {
                return this.processValueToWs(texto)
            },
        }
    }

    handleChange = (event, value) => {
        const {
            props: { onSendValue, x, y },
        } = this

        this.setState(
            {
                value,
            },
            () => {
                onSendValue &&
                    onSendValue({
                        x,
                        y,
                        value: this.processValueToWs(this.state.value),
                        key: keycodes.ENTER_KEY,
                    })
            }
        )
    }

    render() {
        const {
            labelWidth,
            value,
            lbl,
            enabled,
            index,
            onBlur,
            onFocus,
        } = this.props
        return (
            <StyledToggle
                label={<Label text={lbl} style={{ minWidth: labelWidth }} />}
                value={true}
                checked={this.processValueToToggle(value)}
                onChange={this.handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={!enabled}
                id={'field-' + index}
                tabIndex={index}
            />
        )
    }
}
