import { alpha, createTheme, darken, lighten } from '@material-ui/core/styles'

import { Conthrax, FiraCode, Frutiger } from './layout'

const primaryColor = '#2198D5'
const white = '#ffffff'
export const green = '#5cb75d'
export const red = '#a52a2a'
export const blue = '#00a7ce'
export const orange = '#cc8341'
export const purple = '#5e0d8b'
export const textColor = '#232222'
export const bodyColor = '#f8f8f8'

const theme = createTheme({
    userAgent:
        typeof navigator !== 'undefined'
            ? navigator.userAgent
            : 'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2228.0 Safari/537.36',
    typography: {
        fontFamily: 'Frutiger, sans-serif',
    },
    palette: {
        // cores do material
        primary: { dark: darken(primaryColor, 0.2), main: primaryColor },
        secondary: { main: primaryColor },

        // cores dos botões de ação (dialogo)
        primaryAction: {
            dark: darken('#007a5a', 0.2),
            main: '#007a5a',
            light: '#148567',
        },
        secondaryAction: {
            main: white,
            light: 'rgba(var(--sk_foreground_min,29,28,29),.04)',
        },

        // Cor usada no contraste (em cima da cor primaria)
        contrastText: { main: white },

        // simple used colors
        textColor,
        bodyColor,
        alternateTextColor: white,
        canvasColor: white,
        borderColor: red,
        disabledColor: '#c6c3c4',
        loginLabelColor: '#999999',
        orange: {
            light: lighten(orange, 0.35),
            main: orange,
            dark: darken(orange, 0.35),
        },
        blue: {
            light: lighten(blue, 0.2),
            main: blue,
            dark: darken(blue, 0.2),
        },
        purple: {
            light: lighten(purple, 0.2),
            main: purple,
            dark: darken(purple, 0.2),
        },
        danger: {
            light: lighten(red, 0.2),
            main: red,
            dark: darken(red, 0.2),
        },
        success: {
            light: lighten(green, 0.2),
            main: green,
            dark: darken(green, 0.2),
        },
        families: {
            monospace:
                '"monospace", "Monaco", "Menlo", "Andale Mono", "Lucida Sans Typewriter", "Droid Sans Mono", "Deja Vu Sans Mono", "Courier New", "Courier"',
            titles: '"Conthrax", sans-serif !important',
        },
    },
    sizing: {
        sidebar: 300,
        sidebarSmall: 220,
        sidebarPrincipalClosed: 64,
        sidebarClosed: 0,
        topBar: 76,
        topBarSmall: 56,
    },
    zIndex: {
        modal: 1503,
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [
                    Conthrax.bold,
                    Frutiger.bold,
                    Frutiger.light,
                    Frutiger.regular,
                    FiraCode.regular,
                ],
                'html, #root': {
                    height: '100%',
                },
                body: {
                    height: 'calc(100% - 56px)',
                    backgroundColor: bodyColor,
                },
                '#root': {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    position: 'fixed',
                },
                '.highlight': {
                    lineHeight: 1,
                    fontWeight: 600,
                    color: primaryColor,
                    '&.underlined': {
                        textDecoration: 'underline',
                    },
                    '&.disabled': {
                        color: primaryColor,
                        opacity: 0.5,
                    },
                },
                '::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                },
                '::-webkit-scrollbar': {
                    width: '4px',
                    backgroundColor: 'transparent',
                },
                '::-webkit-scrollbar-thumb': {
                    // backgroundColor: 'inherit',
                    backgroundColor: alpha('#000000', 0.15),
                    // '&:hover': {
                    //     backgroundColor: alpha('#000000', 0.15),
                    // },
                },
                '@keyframes rotate': {
                    '0%': {
                        transform: 'rotate(360deg)',
                    },
                    '100%': {
                        transform: 'rotate(1deg)',
                    },
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                color: '#232222',
                backgroundColor: '#c6c3c4',
                opacity: 0.9,
            },
        },
        MuiIconButton: {
            root: {
                color: '#232222',
            },
        },
        MuiFormControlLabel: {
            label: {
                cursor: 'text',
                color: '',
                fontSize: '14px',
                '&$disabled': {
                    color: '',
                },
            },
        },
        MuiInputBase: {
            root: {
                '&:hover': {
                    outline: 0,
                },
                '&$disabled': {
                    color: 'rgb(0, 0, 0, 0.87)',
                    cursor: 'text',
                },
            },
            input: {
                '&$disabled': {
                    cursor: 'text',
                },
            },
        },
        MuiInputLabel: {
            root: {
                cursor: 'text',
            },
            shrink: {
                transform: 'translate(0, 1.5px)',
                cursor: 'text',
            },
        },
        MuiFormLabel: {
            root: {
                cursor: 'text',
                color: '',
                fontSize: '14px',
                '&$disabled': {
                    color: '',
                },
            },
        },
        MuiMenuItem: {
            root: {
                minHeight: 32,
                paddingTop: 0,
                paddingBottom: 0,
                whiteSpace: 'pre',
            },
        },
        MuiListItemIcon: {
            root: {
                color: '#000000',
                minWidth: '36px',
            },
        },
        MuiListItemText: {
            root: {
                marginTop: 0,
                marginBottom: 0,
            },
            primary: {
                fontSize: 13,
            },
        },
        MuiTypography: {
            h2: {
                fontSize: '14px',
                fontWeight: 700,
                textTransform: 'uppercase',
                fontFamily: '"Conthrax", sans-serif',
            },
            h3: {
                fontSize: 18,
                fontWeight: 700,
                textTransform: 'uppercase',
            },
            h6: {
                marginTop: 0,
                fontSize: 16,
                fontWeight: 700,
                marginBottom: 8,
            },
            paragraph: {
                fontSize: 14,
                marginBottom: 0,
                textAlign: 'justify',
                color: 'rgba(35, 34, 34, 0.8)', // textColor 0.8 op
            },
        },
        MuiLink: {
            root: {
                '&:hover': {
                    color: primaryColor,
                },
            },
        },
        MuiSwitch: {
            input: {
                left: 0,
                width: '100%',
            },
            colorSecondary: {
                '&$checked': {
                    color: primaryColor + ' !important',
                },
            },
        },
        MuiButton: {
            root: {
                '&$disabled': {
                    color: 'rgb(0, 0, 0, 0.87)',
                    cursor: 'pointer',
                    pointerEvents: 'all',
                },
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: blue,
            },
        },
    },
})

theme.overrides.MuiListItemIcon.root['& svg'] = {
    [theme.breakpoints.down('md')]: {
        fontSize: '1.2rem',
    },
}

theme.overrides.MuiListItemText.primary[theme.breakpoints.down('lg')] = {
    fontSize: 12,
}

theme.overrides.MuiListItemText.primary[theme.breakpoints.down('md')] = {
    fontSize: 11,
}

theme.overrides.MuiMenuItem.root[theme.breakpoints.down('lg')] = {
    fontSize: 14,
}

theme.overrides.MuiMenuItem.root[theme.breakpoints.down('md')] = {
    fontSize: 12,
}

export default theme
