import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const USERS_QUERY = `
    query GetAllUserGroups {
        getAllUserGroups {
            no
            name
        }
    }
`

export default function useFetchGroups(options) {
    return useQuery(gql(USERS_QUERY), {
        displayName: 'fetchGroups',
        fetchPolicy: 'cache-and-network',
        ...options,
    })
}
