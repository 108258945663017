const getFormat = format => {
    switch (format) {
        case 'money':
            return function labelFormatCustom(value) {
                // eslint-disable-next-line no-case-declarations
                const formatterWithPrec = new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    useGrouping: true,
                })

                let parsedValue = parseFloat(
                    typeof value === 'object' ? value.value : value
                )
                parsedValue = formatterWithPrec.format(parsedValue)
                return parsedValue
            }
    }

    return function labelFormatCustom(value) {
        return value
    }
}

export default getFormat
